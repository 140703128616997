import React from "react";
import { RealEstateBrokerageResponse } from "@doczip/api-client";
import { Check } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import styled from "styled-components";
import LoadingCircle from "./LoadingCircle";
import { FetchState } from "@/interfaces/fetch";

const Container = styled.div`
  position: relative;

  width: 100%;
  min-height: 300px;
  margin-top: 12px;

  border: 1px solid #56678942;
`;

const Card = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #56678942;
    }
  }
`;

const Left = styled.div`
  flex-grow: 1;
  margin-right: 12px;

  text-align: left;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div``;
const Address = styled.div``;

const Owner = styled.div`
  width: 100px;
`;

const SelectedIconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 40px;
`;

interface BrokerageListProps {
  brokerageList: RealEstateBrokerageResponse[];
  brokerageListFetchState: FetchState;
  selectedBrokerage?: RealEstateBrokerageResponse;
  onSelectedBrokerageChange: (newBrokerage: RealEstateBrokerageResponse) => void;
}

export default function BrokerageList({
  brokerageList,
  brokerageListFetchState,
  selectedBrokerage,
  onSelectedBrokerageChange,
}: BrokerageListProps) {
  return (
    <Container>
      {brokerageListFetchState === "FETCHING" && <LoadingCircle position="absolute" />}
      {brokerageList.map(({ office_name, office_jibun_address, representative_name, registration_number, ...rest }) => (
        <Card
          onClick={() =>
            onSelectedBrokerageChange({
              office_name,
              office_jibun_address,
              representative_name,
              registration_number,
              ...rest,
            })
          }
        >
          <Left>
            <Title>{office_name}</Title>
            <Address>{office_jibun_address}</Address>
          </Left>

          <Right>
            <Owner>대표자: {representative_name}</Owner>
            <SelectedIconContainer>
              {selectedBrokerage?.registration_number === registration_number && <Check />}
            </SelectedIconContainer>
          </Right>
        </Card>
      ))}
    </Container>
  );
}
