import React from "react";
import { Warning as WarningIcon } from "@mui/icons-material";
import styled from "styled-components";
import { colors, lengths } from "@/styles/values";

const NotFoundBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100vh - ${lengths.headerHeight});
  min-height: calc((var(--vh, 1vh) * 100) - ${lengths.headerHeight});

  @media screen and (max-width: ${lengths.desktop}) {
    min-height: calc(100vh - ${lengths.mobileHeaderHeight});
    min-height: calc((var(--vh, 1vh) * 100) - ${lengths.mobileHeaderHeight});
  }
`;

const ContentBox = styled.div`
  position: relative;

  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const MessageBox = styled.div`
  padding: 24px 8px 80px 8px;

  color: ${colors.dark};
  font-weight: bold;
  font-size: 24px;
  line-height: 52px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

type Props = {};

const NotFound: React.FC<Props> = () => {
  return (
    <NotFoundBox>
      <ContentBox>
        <WarningIcon sx={{ fontSize: 60 }} />
        <MessageBox>요청하신 페이지를 찾을 수 없습니다.</MessageBox>
      </ContentBox>
    </NotFoundBox>
  );
};

export default NotFound;
