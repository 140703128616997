import { addressSlice } from "./slice";

const actions = addressSlice.actions;

export const {
  reset: resetLandingState,

  resetAddressList,
  addressListFetchRequest,
  addressListFetchSuccess,
  addressListFetchFailure,

  setBannerCollapse,
  setAddressSearchCategory,

  resetCorporationAddressList,
  corporationAddressListFetchRequest,
  corporationAddressListFetchSuccess,
  corporationAddressListFetchFailure,
} = actions;
