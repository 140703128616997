import { ChangeEvent, useEffect, useRef, useState } from "react";

const useContentEditable = (initialContent: string) => {
  const contentEditableRef = useRef<HTMLDivElement | null>(null);
  const [content, _setContent] = useState(initialContent);

  const onInput = (event: ChangeEvent<HTMLDivElement>) => {
    _setContent(event.target.innerText);
  };

  const setContent = (newContent: string) => {
    if (contentEditableRef.current) {
      contentEditableRef.current.innerText = newContent;
      _setContent(newContent);
    }
  };

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  return { content, setContent, onInput, contentEditableRef };
};

export default useContentEditable;
