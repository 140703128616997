import html2canvas from "html2canvas";
import { jsPDF as JsPdf } from "jspdf";
import { isInternetExplorer } from "./browser";

export async function getPdfFromViewContainer(viewContainer: HTMLElement): Promise<JsPdf> {
  const pages = viewContainer.getElementsByClassName("page-container");

  const pdf = new JsPdf("p", "mm", "a4");
  const imgWidth = pdf.internal.pageSize.getWidth();
  const imgHeight = pdf.internal.pageSize.getHeight();

  for (let i = 0; i < pages.length; i += 1) {
    // Force scroll content to top if the user is using IE11.
    const options = isInternetExplorer() ? { scale: 1.5 } : { scale: 1.5, scrollY: -viewContainer.scrollTop };
    if (isInternetExplorer()) {
      viewContainer.scrollTop = 0;
    }
    const canvas = await html2canvas(pages[i] as HTMLElement, options);
    pdf.addImage(canvas, "PNG", 0, 0, imgWidth, imgHeight);
    if (i < pages.length - 1) {
      pdf.addPage();
    }
  }

  return pdf;
}
