import React, { useState } from "react";
import { ReportRegisterLedger, RegisterLedgerOwnershipSummary } from "@doczip/api-client";
import styled from "styled-components";
import { Button } from "@/components/Mui";
import ReportOwnerAddressSearchModal from "@/components/reports/ReportOwnerAddressSearchModal";

const Container = styled.div``;

const ClickControlBox = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 120px;
    height: 46px;

    font-weight: 700;
    font-size: 14px;
  }
`;

type ReportOwnerAddressSearchModalOpenButtonProps = {
  as?: React.ReactNode;
  title: string;
  onConfirm: (checkedOwner: RegisterLedgerOwnershipSummary) => void;
  onCheckBeforeModalOpen?: () => Promise<boolean | undefined>;
  registerLedger: ReportRegisterLedger | undefined;
};

const ReportOwnerAddressSearchModalOpenButton = ({
  as,
  title,
  onConfirm,
  onCheckBeforeModalOpen,
  registerLedger,
}: ReportOwnerAddressSearchModalOpenButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onClick = async () => {
    if (onCheckBeforeModalOpen && (await onCheckBeforeModalOpen())) {
      setModalOpen((prev) => !prev);
    }
  };

  return (
    <Container>
      <ClickControlBox onClick={onClick}>
        {as ?? <StyledButton className="outlined">{title}</StyledButton>}
      </ClickControlBox>
      {modalOpen && (
        <ReportOwnerAddressSearchModal
          open={modalOpen}
          title={title}
          onClose={() => setModalOpen(false)}
          onConfirm={onConfirm}
          registerLedger={registerLedger}
        />
      )}
    </Container>
  );
};

export default ReportOwnerAddressSearchModalOpenButton;
