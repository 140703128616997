import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CommitIcon from "@mui/icons-material/Commit";
import InfoIcon from "@mui/icons-material/Info";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import ReportPriceChart, { ChartData } from "@/components/reports/ReportPriceChart";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";
import { colors, lengths } from "@/styles/values";
import { amountToKorean } from "@/utils/text";

const ReportPriceInfoContainer = styled.div``;

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

const PriceInfoText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceDescription = styled.p`
  margin: 0;
  padding: 0;

  color: #797979;
  font-weight: 700;
  font-size: 13px;
`;

const Price = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 400;
  font-size: 13px;
`;

const InfoTooltipButton = styled.div`
  margin-bottom: 16px;

  color: #dfe2e7;

  cursor: pointer;
`;

const TooltipInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;

  @media screen and (max-width: ${lengths.desktop}) {
    max-width: 200px;
  }
`;

const TooltipRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TooltipText = styled.p`
  margin: 0;
  padding: 0;

  text-align: start;
`;

type ReportPriceInfoProps = {
  chartData: ChartData;
  reportFetchState: FetchState;
};

const ReportPriceInfo = ({ chartData, reportFetchState }: ReportPriceInfoProps) => {
  const { kbResult, kabResult, tankerPriceList } = chartData;
  const headerContents =
    !kbResult && !kabResult && !tankerPriceList
      ? void 0
      : [
          <InfoTooltipButton data-tip data-for="info">
            <InfoIcon color="inherit" />
            <ReactTooltip id="info" place="left" border={true} borderColor="#DFE2E7" type="light" effect="solid">
              <TooltipInnerContent>
                {kabResult && (
                  <TooltipRow>
                    <CircleIcon style={{ fontSize: "16px", color: colors.green }} />
                    비슷한 매물 최근 실거래가: 3개월간 비슷한 평형 매물 실거래가 데이터
                  </TooltipRow>
                )}
                {kbResult && (
                  <TooltipRow>
                    <CommitIcon style={{ fontSize: "16px", marginTop: 3, color: colors.main }} />
                    <TooltipText>
                      <strong>부동산 시세:</strong> 실거래가 및 호가 기반 시세
                    </TooltipText>
                  </TooltipRow>
                )}
                {tankerPriceList && (
                  <TooltipRow>
                    <CommitIcon style={{ fontSize: "16px", marginTop: 3, color: colors.paperBlue }} />
                    <TooltipText>
                      <strong>AI 예측가:</strong> 닥집 AI가 예측하는 가격
                    </TooltipText>
                  </TooltipRow>
                )}
              </TooltipInnerContent>
            </ReactTooltip>
          </InfoTooltipButton>,
        ];

  return (
    <TitledWrapper
      title="가격 정보"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      headerNodeList={headerContents}
    >
      <ReportPriceInfoContainer>
        {["SUCCESS", "FAILURE"].includes(reportFetchState) ? (
          <>
            {!kbResult && !kabResult && !tankerPriceList ? (
              <PriceInfoText>🛎 가격 정보가 없습니다.</PriceInfoText>
            ) : (
              <>
                <PricesContainer>
                  {kabResult && kabResult.kab_price && kabResult.kab_price.mid_price && (
                    <PriceRow>
                      <PriceDescription>비슷한 매물 최근 실거래가</PriceDescription>
                      <Price>{amountToKorean(kabResult.kab_price.mid_price, 10000, "mixed")} 원</Price>
                    </PriceRow>
                  )}
                  {kbResult &&
                    kbResult.kb_apartment_prices.length > 0 &&
                    kbResult.kb_apartment_prices[kbResult.kb_apartment_prices.length - 1] &&
                    kbResult.kb_apartment_prices[kbResult.kb_apartment_prices.length - 1].mid_price && (
                      <PriceRow>
                        <PriceDescription>부동산 시세</PriceDescription>
                        <Price>
                          {amountToKorean(
                            kbResult.kb_apartment_prices[kbResult.kb_apartment_prices.length - 1].mid_price as number,
                            10000,
                            "mixed",
                          )}{" "}
                          원
                        </Price>
                      </PriceRow>
                    )}
                  {tankerPriceList && tankerPriceList.length > 0 && (
                    <PriceRow>
                      <PriceDescription>AI 예측가</PriceDescription>
                      <Price>{amountToKorean(tankerPriceList[0].tanker_50_price, 10000, "mixed")} 원</Price>
                    </PriceRow>
                  )}
                </PricesContainer>
                {(tankerPriceList || kbResult) && <ReportPriceChart chartData={chartData} />}
              </>
            )}
          </>
        ) : (
          <LoadingCircle />
        )}
      </ReportPriceInfoContainer>
    </TitledWrapper>
  );
};

export default ReportPriceInfo;
