import React from "react";
import { DocumentResponse, ResidentialManualResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Table from "@/components/paper/Table";
import { toNumberWithCommas } from "@/utils/amount";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const ManualBrokerageView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Table bmargin={10} columnSizes={[110, 170, 260]}>
      <Table row height={70}>
        <Box td rowSpan={4} font="label">
          ⑧ 중개보수 <br />
          및 실비 <br />
          의 금액과 <br />
          산출내역
        </Box>
        <Box td font="label">
          중개보수
        </Box>
        <Box td hpadding="normal" align="left">
          {body.commission_amount ? toNumberWithCommas(body.commission_amount) : ""}
        </Box>
        <Box td hpadding="normal" align="left" font="label" bborder="none">{`<산출내역>`}</Box>
      </Table>
      <Table row height={70}>
        <Box td font="label">
          실비
        </Box>
        <Box td hpadding="normal" align="left">
          {body.expense_amount ? toNumberWithCommas(body.expense_amount) : ""}
        </Box>
        <Box td rowSpan={2} hpadding="normal" align="left" valign="top">
          {`중개보수: ${body.comission_detail}`}
          <br />
          <br />
          {`실  비: ${body.expense_detail}`}
          <br />
        </Box>
      </Table>
      <Table row height={70}>
        <Box td font="label" rborder="normal">
          계
        </Box>
        <Box td hpadding="normal" align="left" rborder="normal">
          {body.total_amount ? toNumberWithCommas(body.total_amount) : ""}
        </Box>
      </Table>
      <Table row height={70}>
        <Box td font="label">
          지급시기
        </Box>
        <Box td hpadding="normal" align="left">
          {body.payment_due}
        </Box>
        <Box td hpadding="normal" align="left" tborder="none">
          ※ 중개보수는 시ㆍ도 조례로 정한 요율에 따르거나, 시ㆍ도 조례로 정한 요율한도에서 중개의뢰인과 개업공인중개사가
          서로 협의하여 결정하도록 한 요율에 따르며 부가가치세는 별도로 부과될 수 있습니다.
        </Box>
      </Table>
    </Table>
  );
};

export default ManualBrokerageView;
