import React from "react";
import { CircularProgress, CircularProgressProps } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "@/styles/values";

interface LoadingCircleProps extends Omit<CircularProgressProps, "color"> {
  position?: "relative" | "absolute";
  color?: React.CSSProperties["color"];
  disabledPadding?: boolean;
}

const LoadingCircleContainer = styled.div<LoadingCircleProps>`
  display: flex;
  justify-content: center;
  padding-top: ${({ disabledPadding }) => !disabledPadding && "16px"};
  padding-bottom: ${({ disabledPadding }) => !disabledPadding && "16px"};

  & .MuiCircularProgress-root {
    color: ${({ color }) => color ?? colors.main};
  }

  ${({ position }) =>
    position === "absolute" &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    `}
`;

export default function LoadingCircle({
  position = "relative",
  color,
  disabledPadding = false,
  size,
}: LoadingCircleProps) {
  return (
    <LoadingCircleContainer position={position} color={color} disabledPadding={disabledPadding}>
      <CircularProgress size={size} />
    </LoadingCircleContainer>
  );
}
