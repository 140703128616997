import { Listing } from "@vesta/api-client";
import {
  MolitResult,
  ReportBuildingLedger,
  ReportDeclaredPriceResult,
  ReportInfocareResult,
  ReportKABResult,
  ReportKBResult,
  ReportLandLedger,
  ReportLandUsingInfo,
  ReportNSDILandFeature,
  ReportRegisterLedger,
  ReportRequestResult,
  ReportTankerPriceResult,
} from "@blitz-core/api-client";
import { CorporationReportLogResponse, ReportLogResponse, ReportLogUserRelationResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { ReportState } from "@/redux/report/types";
import { makeAsyncStateResetReducer, makeTypedAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { getKeyListFromState, getTypedAsyncInitialState } from "@/utils/redux/state";
import { WithString } from "@/utils/redux/types";

const getAsyncInitialState = getTypedAsyncInitialState<ReportState>();
const makeAsyncReducer = makeTypedAsyncReducer<ReportState>();

const initialState: ReportState = {
  ...getAsyncInitialState("createReport"),
  ...getAsyncInitialState("createBulkReport"),
  ...getAsyncInitialState("report"),
  ...getAsyncInitialState("registerLedger"),
  ...getAsyncInitialState("buildingLedger"),
  ...getAsyncInitialState("landLedger"),
  ...getAsyncInitialState("landUsingInfo"),
  ...getAsyncInitialState("kabPrice"),
  ...getAsyncInitialState("kbPrice"),
  ...getAsyncInitialState("molitContract"),
  ...getAsyncInitialState("molitApartmentKindListings"),
  ...getAsyncInitialState("infocare"),
  ...getAsyncInitialState("nsdi"),
  ...getAsyncInitialState("tankerPrice"),
  ...getAsyncInitialState("declaredPrice"),
  ...getAsyncInitialState("randomReport"),
  ...getAsyncInitialState("reportReRequest"),
  ...getAsyncInitialState("memo"),
  checkedOwner: null,
};

export const REPORT_STATE_KEY_LIST = getKeyListFromState(initialState);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"createReport", void, ReportLogResponse>("createReport"),
    ...makeAsyncReducer<"createBulkReport", void, number>("createBulkReport"),
    ...makeAsyncReducer<"createCorporationReport", void, CorporationReportLogResponse>("createCorporationReport"),
    resetCreateBulkReportState: makeAsyncStateResetReducer(initialState, "createBulkReport"),
    ...makeAsyncReducer<"report", void, ReportLogResponse>("report"),
    ...makeAsyncReducer<"registerLedger", void, WithString<ReportRegisterLedger>>("registerLedger", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.registerLedger = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.registerLedgerFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"buildingLedger", void, WithString<ReportBuildingLedger>>("buildingLedger", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.buildingLedger = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.buildingLedgerFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"landLedger", void, WithString<ReportLandLedger>>("landLedger", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.landLedger = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.landLedgerFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"landUsingInfo", void, WithString<ReportLandUsingInfo>>("landUsingInfo", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.landUsingInfo = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.landUsingInfoFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"kabPrice", void, WithString<ReportKABResult>>("kabPrice", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.kabPrice = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.kabPriceFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    resetKabPriceState: makeAsyncStateResetReducer(initialState, "kabPrice"),
    ...makeAsyncReducer<"kbPrice", void, WithString<ReportKBResult>>("kbPrice", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.kbPrice = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.kbPriceFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    resetKbPriceState: makeAsyncStateResetReducer(initialState, "kbPrice"),
    ...makeAsyncReducer<"molitContract", void, WithString<MolitResult>>("molitContract", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.molitContract = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.molitContractFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"molitApartmentKindListings", void, WithString<Listing[]>>("molitApartmentKindListings", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.molitApartmentKindListings = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.molitApartmentKindListingsFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    resetMolitApartmentKindListingsState: makeAsyncStateResetReducer(initialState, "molitApartmentKindListings"),
    ...makeAsyncReducer<"infocare", void, WithString<ReportInfocareResult>>("infocare", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.infocare = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.infocareFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"nsdi", void, WithString<ReportNSDILandFeature>>("nsdi", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.nsdi = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.nsdiFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"tankerPrice", void, WithString<ReportTankerPriceResult[]>>("tankerPrice", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.tankerPrice = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.tankerPriceFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    resetTankerPriceState: makeAsyncStateResetReducer(initialState, "tankerPrice"),
    ...makeAsyncReducer<"declaredPrice", void, WithString<ReportDeclaredPriceResult>>("declaredPrice", {
      successReducer: (_state, { payload }) => {
        let EMPTY_VALUE: any;

        _state.declaredPrice = typeof payload === "string" ? EMPTY_VALUE : payload;
        _state.declaredPriceFetchState = typeof payload === "string" ? "FAILURE" : "SUCCESS";
      },
    }),
    ...makeAsyncReducer<"randomReport", void, ReportLogUserRelationResponse[]>("randomReport"),
    ...makeAsyncReducer<"reportReRequest", void, ReportRequestResult>("reportReRequest"),
    ...makeAsyncReducer("memo"),
    ...setValueReducerList(["checkedOwner"], initialState),
  },
});

export const reportReducer = reportSlice.reducer;
