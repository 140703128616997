import React from "react";
import {
  TradeContractResponse,
  JeonseContractResponse,
  RentContractResponse,
  DocumentResponse,
} from "@doczip/api-client";
import { differenceInMonths } from "date-fns";
import DateContainer from "@/components/paper/Date";
import { isJeonseContractBody, isRentContractBody, isTradeContractBody } from "@/utils/document";

type ContractResponse = TradeContractResponse | JeonseContractResponse | RentContractResponse;
type Props<T extends ContractResponse> = {
  document: DocumentResponse;
  body: T;
  index: number;
};

const ContractPhraseView = <T extends ContractResponse>({ document, body, index }: Props<T>) => {
  if (isJeonseContractBody(body) || isRentContractBody(body)) {
    switch (index) {
      case 1:
        return (
          <>
            [목적] 위 부동산의 임대차에 한하여 임대인과 임차인은 합의에 의하여 임차보증금 및 차임을 아래와 같이
            지불하기로 한다.
          </>
        );

      case 2:
        return (
          <>
            [존속기간］임대인은 위 부동산을 임대차 목적대로 사용할수 있는 상태로{" "}
            <DateContainer value={body.delivery_date} size="tiny" /> 까지 임차인에게 인도하며, 임대차 기간은
            인도일로부터 <DateContainer value={body.return_date} size="tiny" />{" "}
            {`(${differenceInMonths(new Date(body.return_date), new Date(body.delivery_date))}개월)`} 까지로 한다.
          </>
        );

      case 3:
        return (
          <>
            [용도변경 및 전대 등］임차인은 임대인의 동의없이 위 부동산의 용도나 구조를 변경하거나 전대,임차권 양도 또는
            담보제공을 하지 못하며 임대차 목적 이외의 용도로 사용할 수 없다.
          </>
        );

      case 4:
        return <>[계약의 해지］임차인이 제3조를 위반하였을때 임대인은 즉시 본 계약을 해지 할 수 있다.</>;

      case 5:
        return (
          <>
            [계약의 종료] 임대차 계약이 종료된 경우 임차인은 위 부동산을 원상으로 회복하여 임대인에게 반환한다. 이러한
            경우 임대인은 보증금을 임차인에게 반환하고, 연체 임대료 또는 손해배상금이 있을 때는 이들을 제하고 그 잔액을
            반환한다.
          </>
        );

      case 6:
        return (
          <>
            [계약의 해제] 임차인이 임대인에게 중도금(중도금이 없는때는 잔금)을 지불하기 전까지 임대인은 계약금의 배액을
            상환하고, 임차인은 계약금을 포기하고 이계약을 해제할 수 있다.
          </>
        );

      case 7:
        return (
          <>
            [채무불이행과 손해배상의 예정] 임대인 또는 임차인은 본 계약상의 내용에 대하여 불이행이 있을 경우 그 상대방은
            불이행 한자에 대하여 서면으로 최고하고 계약을 해제 할 수 있다. 이경우 계약 당사자는 계약해제에 따른
            손해배상을 각각 상대방에게 청구할 수 있으며, 손해배상에 대하여 별도의 약정이 없는 한 계약금을 손해배상의
            기준으로 본다.
          </>
        );

      case 8:
        return (
          <>
            [중개보수] 개업공인중개사는 임대인 또는 임차인의 본 계약 불이행에 대하여 책임을 지지않는다. 또한 중개보수는
            본계약 체결에 따라 계약 당사자 쌍방이 각각 지불하며, 개업공인 중개사의 고의나 과실없이 본 계약이 무효,취소
            또는 해제 되어도 중개보수는 지급한다. 공동중개인 경우에 매도인과 매수인은 자신이 중개 의뢰한
            개업공인중개사에게 각각 중개보수를 지급한다.
          </>
        );

      case 9:
        return (
          <>
            [중개대상물확인설명서 교부 등] 개업공인중개사는 중개대상물 확인설명서를 작성하고 업무보증관계증서 (공제증서
            등)사본을 첨부하여 거래당사자 쌍방에게 교부한다. ( 교부일자:{" "}
            <DateContainer value={body.manual_delivery_date} size="tiny" /> )
          </>
        );

      default:
        return <></>;
    }
  } else if (isTradeContractBody(body)) {
    switch (index) {
      case 1:
        return (
          <>[목적] 위 부동산의 매매에 대해여 매도인과 매수인은 합의에 의하여 매매대금을 아래와 같이 지불하기로 한다.</>
        );

      case 2:
        return (
          <>
            [소유권 이전 등］매도인은 매매대금의 잔금 수령과 동시에 매수인에계 소유권 이전등기에 필요한 모든 서류를
            교부하고 등기절차에 협력 하여야 하며, 위 부동산의 인도일은{" "}
            <DateContainer value={body.delivery_date} size="tiny" /> 로 한다.
          </>
        );

      case 3:
        return (
          <>
            [제한물권 등의 소멸］ 매도인은 위 부동산에 설정된 저당권,지상권,임차권 등 소유권의 행사를 제한하는 사유가
            있거나 제세공과금 기타 부담금의 미납 등이 있을때에는 잔금 수수일까지 그 권리의 하자 및 부담 등을 제거하여
            완전한 소유권을 매수인에게 이전한다. 다만, 승계하기로 합의하는 권리 및 금액은 그러하지 아니한다.
          </>
        );

      case 4:
        return (
          <>
            [지방세 등] 위 부동산에 관하여 발생한 수익의 귀속과 제세공과금 등의 부담은 위 부동산의 인도일을 기준으로
            하되, 지방세의 납부의무 및 납부책임은 지방세법의 규정에 의한다.
          </>
        );

      case 5:
        return (
          <>
            [계약의 해제] 매수인이 매도인에게 중도금(중도금이 없을때에는 잔금)을 지불하기전 까지 매도인은 계약금의
            배액을 상환하고, 매수인은 계약금을 포기하고 본 계약을 해제할 수있다.
          </>
        );

      case 6:
        return (
          <>
            [채무불이행과 손해배상의 예정] 매도인 또는 매수인은 본계약상의 내용에 대해여 불이행이 있을경우, 그상대방은
            불이행 한 자에 대하여 서면으로 최고하고 계약을 해제할 수 있다. 그리고 계약 당사자는 계약해제에 따른
            손해배상을 각각 상대방에게 청구할 수 있으며,손해배상에 대하여 별도의 약정이 없는 한 계약금을 손해배상의
            기준으로 본다.
          </>
        );

      case 7:
        return (
          <>
            [중개보수] 개업공인중개사는 매도인 또는 매수인의 본 계약 불이행에 대하여 책임을 지지않는다. 또한 중개보수는
            본계약 체결에 따라 계약 당사자 쌍방이 각각 지불하며, 개업공인 중개사의 고의나 과실없이 본 계약이 무효,취소
            또는 해제 되어도중개보수는 지급한다. 공동중개인 경우에 매도인과 매수인은 자신이 중개 의뢰한
            개업공인중개사에게 각각 중개보수를 지급한다.
          </>
        );

      case 8:
        return (
          <>
            [중개보수 외] 매도인 또는 매수인이 본 계약 이외의 업무를 의뢰한 경우, 이에 관한 보수는 중개보수와는 별도로
            지급하며 그 금액은 합의에 의한다.
          </>
        );

      case 9:
        return (
          <>
            [중개대상물확인설명서 교부 등] 개업공인중개사는 중개대상물 확인설명서를 작성하고 업무보증관계증서 (공제증서
            등)사본을 첨부하여 거래당사자 쌍방에게 교부한다. ( 교부일자:{" "}
            <DateContainer value={body.manual_delivery_date} size="tiny" /> )
          </>
        );

      default:
        return <></>;
    }
  }
  return <></>;
};

export default ContractPhraseView;
