import { createTheme } from "@mui/material/styles";
import { colors } from "./values";

const theme = createTheme({
  typography: {
    fontFamily: `"Noto Sans KR", "Noto Sans CJK", "Nanum Gothic", "Malgun Gothic", sans-serif`,
  },
  palette: {
    primary: {
      main: colors.main,
      contrastText: colors.white,
    },
  },
});

export default theme;
