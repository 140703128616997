import React, { useEffect, useState } from "react";
import { ReportLogResponse } from "@doczip/api-client";
import styled from "styled-components";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";

const ReportAddressInfoContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 12px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 13px;
`;

interface ReportAddressInfoProps {
  report: ReportLogResponse | null;
  reportFetchState: FetchState;
}

const ReportAddressInfo: React.FC<ReportAddressInfoProps> = ({ report, reportFetchState }) => {
  const [address, setAddress] = useState<string>();

  useEffect(() => {
    if (report && report?.registered_address && reportFetchState !== "FAILURE") {
      setAddress(report.registered_address);
    }
  }, [report, reportFetchState]);

  return (
    <TitledWrapper
      title="물건 주소"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
    >
      <ReportAddressInfoContainer>
        <TitleContainer>
          <Title>{address ?? "🏠 물건 주소 정보가 없습니다."}</Title>
        </TitleContainer>
      </ReportAddressInfoContainer>
    </TitledWrapper>
  );
};

export default ReportAddressInfo;
