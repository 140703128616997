import React from "react";
import { AgentResponse } from "@doczip/api-client";
import { Grid } from "@mui/material";
import TextField from "./TextField";

type Props = {
  value: AgentResponse;
  onChange: (people: AgentResponse) => void;
};

const AgentField: React.FC<Props> = ({ value, onChange }) => {
  const handleChange = <KT extends keyof AgentResponse>(k: KT, v: AgentResponse[KT]) => {
    onChange({
      ...value,
      [k]: v,
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <TextField
              size="small"
              label="사무소 소재지"
              value={value.address_expr}
              onChange={(e) => handleChange("address_expr", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <TextField
              size="small"
              label="사무소 명칭"
              value={value.agency_name}
              onChange={(e) => handleChange("agency_name", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              size="small"
              label="대표자 성명"
              value={value.agency_head_full_name}
              onChange={(e) => handleChange("agency_head_full_name", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="전화"
              value={value.phone_number}
              onChange={(e) => handleChange("phone_number", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="등록번호"
              value={value.registration_number}
              onChange={(e) => handleChange("registration_number", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="소속공인중개사 성명"
              value={value.full_name}
              onChange={(e) => handleChange("full_name", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AgentField;
