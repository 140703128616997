import React from "react";
import { DocumentResponse, ResidentialManualResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Bracket from "@/components/paper/Bracket";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const ManualDescriptionView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Table bmargin={10} columnSizes={[110, 90, 150, 170, 90, 150]}>
      <Table row height={35}>
        <Box td rowSpan={9} font="label">
          ① 대상물건 <br />의 표시
        </Box>
        <Box td rowSpan={3} font="label">
          토지
        </Box>
        <Box td>소재지</Box>
        <Box td colSpan={4} align="left" hpadding="small">
          {document.address_expr}
        </Box>
      </Table>
      <Table row height={35}>
        <Box td rowSpan={2}>
          면적(㎡)
        </Box>
        <Box td rowSpan={2}>
          {body.land_area}
        </Box>
        <Box td rowSpan={2}>
          지목
        </Box>
        <Box td>공부상 지목</Box>
        <Box td>{body.land_category}</Box>
      </Table>
      <Table row height={35}>
        <Box td>실제이용 상태</Box>
        <Box td>{body.land_category_actual}</Box>
      </Table>
      <Table row height={35}>
        <Box td rowSpan={6} font="label">
          건축물
        </Box>
        <Box td>전용면적(㎡)</Box>
        <Box td colSpan={2}>
          {body.building_exclusive_area}
        </Box>
        <Box td>대지지분(㎡)</Box>
        <Box td>{body.building_land_share}</Box>
      </Table>
      <Table row height={35}>
        <Box td rowSpan={2}>
          준공년도 <br />
          (증개축년도)
        </Box>
        <Box td rowSpan={2}>
          {body.building_completion_year}
        </Box>
        <Box td rowSpan={2}>
          용도
        </Box>
        <Box td>건축물대장상 용도</Box>
        <Box td>{body.building_purpose}</Box>
      </Table>
      <Table row height={35}>
        <Box td>실제 용도</Box>
        <Box td>{body.building_purpose_actual}</Box>
      </Table>
      <Table row height={35}>
        <Box td>구조</Box>
        <Box td>{body.building_structure}</Box>
        <Box td colSpan={2}>
          방향
        </Box>
        <Box td>
          {body.building_direction}
          <Space size="tiny" />
          <Bracket round>
            기준:
            <Space size="tiny" />
            <Space size="large">{body.building_direction_base}</Space>
          </Bracket>
        </Box>
      </Table>
      <Table row height={35}>
        <Box td>내진설계 적용여부</Box>
        <Box td>
          <EnumCheckboxGroup
            value={body.is_building_seismic}
            checkboxes={[
              ["적용", true],
              ["미적용", false],
            ]}
          />
        </Box>
        <Box td colSpan={2}>
          내진능력
        </Box>
        <Box td>{body.building_seismic_capacity}</Box>
      </Table>
      <Table row height={35}>
        <Box td>
          건축물대장상 <br />
          위반건축물 여부
        </Box>
        <Box td>
          <EnumCheckboxGroup
            value={body.is_building_illegal}
            checkboxes={[
              ["위반", true],
              ["적법", false],
            ]}
          />
        </Box>
        <Box td colSpan={2}>
          위반내용
        </Box>
        <Box td hpadding="small">
          {body.building_illegal_detail}
        </Box>
      </Table>
    </Table>
  );
};

export default ManualDescriptionView;
