import React from "react";
import { Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Button, Grid, IconButton } from "@mui/material";
// import { confirm } from "@/utils/dialog";
import FormLabel from "./FormLabel";

type InputProps<T> = {
  value: T;
  onChange: (value: T) => void;
};

type Props<T> = {
  label: string;
  value: T[];
  onChange: (value: T[]) => void;
  input: React.FC<InputProps<T>>;
  initFactory: () => T;
};

const ListField = <T extends object>(props: Props<T>) => {
  const Input = props.input;

  const handleItemChange = (index: number, item: T) => {
    const newValue = [...props.value];
    newValue[index] = item;
    props.onChange(newValue);
  };

  const handleAddItem = () => {
    props.onChange([...props.value, props.initFactory()]);
  };

  const handleRemoveItem = async (index: number) => {
    if (!window.confirm(`${props.label} ${index + 1} 항목을 삭제하시겠습니까?`)) {
      return;
    }

    const newValue = [...props.value];
    newValue.splice(index, 1);
    props.onChange(newValue);
  };

  const inputsContent = props.value.map((item, index) => {
    return (
      <Grid item sm={12} key={index} container>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item>
            <FormLabel>{`${props.label} ${index + 1}`}</FormLabel>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={() => handleRemoveItem(index)}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Input value={item} onChange={(i) => handleItemChange(index, i)} />
        </Grid>
      </Grid>
    );
  });

  const emptyMessage = <FormLabel>{`${props.label} 없음`}</FormLabel>;

  const content = props.value.length === 0 ? emptyMessage : inputsContent;

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} container spacing={1}>
        {content}
      </Grid>
      <Grid item sm={12}>
        <Button variant="text" startIcon={<AddIcon />} onClick={handleAddItem} fullWidth>
          {`${props.label} 추가`}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ListField;
