import React from "react";
import { UserType } from "@doczip/api-client";
import { useSelector } from "react-redux";
import FlatButton from "@/components/buttons/FlatButton";
import { MobileHideContainer, PostTitledOuterContainer } from "@/components/posts/common/PostLayout";
import PostModalOpenButton from "@/components/posts/PostModalOpenButton";
import TitledContainer from "@/components/TitledContainer";
import { getUserMe } from "@/redux/user/selectors";

const PostTitledContainer = () => {
  const user = useSelector(getUserMe);

  return (
    <MobileHideContainer>
      <PostTitledOuterContainer>
        <TitledContainer
          title="공지사항"
          titleFontSize={16}
          actions={
            user?.user_type === UserType.Admin && <PostModalOpenButton as={<FlatButton>공지 작성</FlatButton>} />
          }
        />
      </PostTitledOuterContainer>
    </MobileHideContainer>
  );
};

export default PostTitledContainer;
