import { subscriptionSlice } from "./slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = subscriptionSlice.actions;

export const {
  reset: resetSubscriptionState,
  resetSubscriptionListState,
  resetSubscriptionRefundState,
  resetSubscriptionCancelState,
} = actions;

export const subscriptionList = mergeAsyncAction(actions, "subscriptionList");

export const cancelSubscription = mergeAsyncAction(actions, "cancelSubscription");

export const refundSubscription = mergeAsyncAction(actions, "refundSubscription");

export const subscribe = mergeAsyncAction(actions, "subscribe");
