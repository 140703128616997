import React from "react";
import { ReportLogUserRelationResponse } from "@doczip/api-client";
import styled from "styled-components";
import RegisterLedgerCard from "./RegisterLedgerCard";
import LoadingCircle from "@/components/LoadingCircle";
import { FetchState } from "@/interfaces/fetch";

const RandomRegisterLedgerContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EmojiContainer = styled.div``;

const Emoji = styled.span``;

const Text = styled.span`
  margin-left: 0.375rem;

  font-weight: 500;
  font-size: 0.8125rem;
`;

const RegisterLedgerContainer = styled.div`
  margin-top: 1.5625rem;
`;

const RegisterLedgerList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RegisterLedgerItem = styled.li`
  width: 18.9375rem;
  height: 12.5rem;

  transform: translateY(0);

  transition: transform 0.2s linear;

  &:hover {
    transform: translateY(-10px);
  }
`;

interface RandomRegisterLedgerProps {
  randomReport: ReportLogUserRelationResponse[] | null;
  randomReportFetchState: FetchState;
}

const RandomRegisterLedger: React.FC<RandomRegisterLedgerProps> = ({ randomReport, randomReportFetchState }) => {
  return (
    <RandomRegisterLedgerContainer>
      <TextContainer>
        <EmojiContainer>
          <Emoji>😎</Emoji>
        </EmojiContainer>

        <Text>무작위 등기부등본 공개! 검색 한 번에 이렇게 바로 문서작성과 발급이 된다고?</Text>
      </TextContainer>

      <RegisterLedgerContainer>
        {randomReport && randomReportFetchState === "SUCCESS" ? (
          <RegisterLedgerList>
            {randomReport.map((report, index) => (
              <RegisterLedgerItem key={index}>
                <RegisterLedgerCard report={report} />
              </RegisterLedgerItem>
            ))}
          </RegisterLedgerList>
        ) : (
          <LoadingCircle />
        )}
      </RegisterLedgerContainer>
    </RandomRegisterLedgerContainer>
  );
};

export default RandomRegisterLedger;
