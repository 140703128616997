import React from "react";
import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  font-size: 14px;

  list-style-type: none;

  & li {
    padding: 0 8px;
  }

  & li:first-of-type {
    padding-left: 0;
  }

  & li:last-of-type {
    padding-right: 0;
  }

  & li::after {
    position: absolute;
    right: 0;
    bottom: 10%;

    width: 1px;
    height: 75%;

    background: #dfe2e7;

    content: "";
  }

  & li:last-of-type::after {
    background: none !important;

    content: "";
  }
`;

const Item = styled.li`
  position: relative;

  color: #333;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
`;

type Props = {
  items: Array<string | null>;
};

const BarSeparator = ({ items }: Props) => {
  return <Container>{items.map((item, index) => item !== null && <Item key={index}>{item}</Item>)}</Container>;
};

export default BarSeparator;
