import React from "react";
import Space from "./Space";

type Props = {
  size?: React.ComponentProps<typeof Space>["size"];
  value: string | undefined;
};

const Date: React.FC<Props> = ({ size, value }) => {
  if (typeof value === "undefined") {
    return <></>;
  }
  const tokens = value.split("-");
  if (tokens.length !== 3) {
    return (
      <Space align="right" size={size || "large"}>
        올바른 일자를 입력해 주세요
      </Space>
    );
  }
  const year = parseInt(tokens[0], 10);
  const month = parseInt(tokens[1], 10);
  const day = parseInt(tokens[2], 10);
  return (
    <>
      <Space align="right" size={size || "large"}>
        {year}
      </Space>
      년
      <Space size="tiny" />
      <Space align="right" size={size || "large"}>
        {month}
      </Space>
      월
      <Space size="tiny" />
      <Space align="right" size={size || "large"}>
        {day}
      </Space>
      일
    </>
  );
};

export default Date;
