import React, { useEffect, useState } from "react";
import { CreateCorporationReportLogRequest, RegisterAddressAddress, UserResponse } from "@doczip/api-client";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AddressList from "@/components/AddressListWithSearch/AddressList";
import AddressSearch from "@/components/AddressListWithSearch/AddressSearch";
import Modal from "@/components/Modal";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";
import {
  getAddressSearchCategory,
  getCorporationAddressList,
  getCorporationAddressListFetchState,
} from "@/redux/address/selectors";
import { BulkReportRequestPayload, ReportRequestPayload } from "@/redux/report/types";
import { event } from "@/utils/analytics";
import { getSpecificParameter } from "@/utils/query";

const Container = styled.div`
  display: block;
`;

const PrefixContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EmojiContainer = styled.div``;

const Emoji = styled.span``;

const Text = styled.span`
  margin-left: 6px;

  font-weight: 500;
  font-size: 13px;
`;

interface FocusedProps {
  focused?: boolean;
}

interface AddressSearchFormInnerContainerProps
  extends FocusedProps,
    Pick<AddressListWithSearchProps, "disableBackgroundColor"> {}

const AddressSearchInnerContainer = styled.div<AddressSearchFormInnerContainerProps>`
  position: relative;
  z-index: 3;

  width: 100%;
  margin-top: 20px;
  padding: ${({ focused }) => focused && "46px 50px"};

  background-color: ${({ disableBackgroundColor }) => (disableBackgroundColor ? "transparent" : "#ffffff")};
  box-shadow: ${({ focused }) => focused && "0px 0px 10px rgba(0, 0, 0, 0.1)"};
  opacity: 100;

  transition: padding 0.15s linear, box-shadow 0.15s linear;
`;

const FooterContainer = styled.div`
  padding-top: 20px;
`;

const FooterText = styled.span`
  color: #000;
  font-weight: 400;
  font-size: 13px;
`;

const HyperLinkText = styled.span`
  font-weight: 700;
  text-decoration-line: underline;

  cursor: pointer;
`;

const ModalInnerContainer = styled.div`
  z-index: 500;

  display: flex;
  flex-direction: column;
  min-width: min(856px, 90vw);
  padding: 24px;

  background-color: rgb(255, 255, 255);
`;

const ModalCloseButton = styled.button`
  display: inline-block;
  width: 100%;
  padding: 10px 0;

  color: rgb(51, 51, 51);
  font-weight: 700;
  font-size: 13px;

  background: none;
  border: none;
  outline: inherit;
  cursor: pointer;
`;

type AddressListWithSearchProps = {
  userMe: UserResponse | null;
  addressList: RegisterAddressAddress[] | null;
  addressListFetchState: FetchState;
  reportFetchState: FetchState;
  textFieldPlaceholder?: string;
  focusedTextFieldPlaceholder?: string;
  subButtonText?: string;
  focusedSubButtonText?: string;
  withDefaultPrefix?: boolean;
  withAnimation?: boolean;
  disableBackgroundColor?: boolean;
  multiSelect?: boolean;
  listForm?: "normal" | "modal";
  getAddressListByKeyword: (keyword: string) => void;
  createReport: (payload: ReportRequestPayload) => void;
  createBulkReport: (payload: BulkReportRequestPayload) => void;
  createCorporationReport: (payload: CreateCorporationReportLogRequest) => void;
  isCorporationSearch: boolean;
  setIsCorporationSearch: (value: boolean) => void;
};

const AddressListWithSearch = ({
  userMe,
  addressList,
  addressListFetchState,
  reportFetchState,
  textFieldPlaceholder,
  focusedTextFieldPlaceholder,
  subButtonText,
  focusedSubButtonText,
  withDefaultPrefix = false,
  multiSelect = false,
  withAnimation = false,
  disableBackgroundColor = false,
  listForm = "normal",
  getAddressListByKeyword,
  createReport,
  createBulkReport,
  createCorporationReport,
  isCorporationSearch,
  setIsCorporationSearch,
}: AddressListWithSearchProps) => {
  const address = getSpecificParameter(window.location.search, "address");
  const [multi, setMulti] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const isListForm = listForm === "normal";

  const addressSearchCategory = useSelector(getAddressSearchCategory);
  const corporationAddressList = useSelector(getCorporationAddressList);
  const corporationAddressListFetchState = useSelector(getCorporationAddressListFetchState);

  useEffect(() => {
    if (address) {
      setModalOpen(true);
    }
  }, []);

  useEffect(() => {
    setMulti(false);
  }, [addressSearchCategory]);

  const onSearchClick = () => {
    switch (listForm) {
      case "normal":
        break;
      case "modal":
        setModalOpen(true);
        break;
      default:
        break;
    }
  };

  const getSearchFocused = (focused: boolean) => {
    setSearchFocused(focused);
  };

  const onCreateReport = (payload: RegisterAddressAddress | RegisterAddressAddress[] | undefined) => {
    if (payload) {
      if (Array.isArray(payload)) {
        const bulkReport = payload.map((item) => {
          return {
            registered_address: item.address,
            registered_pin_number: item.pin_number,
            registered_type: item.register_type,
            is_paid: false,
          };
        });
        event("create_bluk_report");
        createBulkReport({ reportRequestList: bulkReport });
      } else {
        const report = {
          registeredAddress: payload.address,
          registeredPinNumber: payload.pin_number,
          registeredType: payload.register_type,
          isPaid: false,
        };
        event("create_report");
        createReport(report);
      }
    }
  };

  const onCreateCorporationReport = (payload: CreateCorporationReportLogRequest) => {
    createCorporationReport(payload);
  };

  const footer = (
    <FooterContainer>
      {!(addressSearchCategory === "corporation") && multiSelect && (
        <FooterText>
          {!multi ? (
            <>
              한 번에 여러 주소 열람을 원하시나요?{" "}
              <HyperLinkText onClick={() => setMulti(true)}>다중 발급 바로가기</HyperLinkText>
            </>
          ) : (
            <>
              여러 주소를 한 번에 열람합니다.{" "}
              <HyperLinkText onClick={() => setMulti(false)}>단일 발급 돌아가기</HyperLinkText>
            </>
          )}
        </FooterText>
      )}
    </FooterContainer>
  );

  const list = (
    <>
      {isListForm ? (
        <>
          <AddressList
            userMe={userMe}
            addressList={addressList}
            addressListFetchState={addressListFetchState}
            reportFetchState={reportFetchState}
            multi={multi}
            handleCreateReport={onCreateReport}
            handleCorporationReport={onCreateCorporationReport}
            corporationAddressList={corporationAddressList}
            corporationAddressListFetchState={corporationAddressListFetchState}
          />
          {addressListFetchState !== "READY" && footer}
        </>
      ) : (
        <Modal open={modalOpen} handleClose={() => setModalOpen(false)} hideAcceptButton hideCancelButton>
          <ModalInnerContainer>
            <TitledWrapper
              title="📌 대상 물건 주소/법인명을 입력해주세요."
              titleStyle={{ size: 13, weight: 400, bottomGap: 20 }}
            >
              <AddressSearch
                reportFetchState={reportFetchState}
                getAddressListByKeyword={getAddressListByKeyword}
                focusOnMounted
                textFieldPlaceholder={textFieldPlaceholder}
                focusedTextFieldPlaceholder={focusedTextFieldPlaceholder}
                subButtonText={subButtonText}
                focusedSubButtonText={focusedSubButtonText}
                onFocused={getSearchFocused}
                isCorporationSearch={isCorporationSearch}
                setIsCorporationSearch={setIsCorporationSearch}
              />
              <AddressList
                userMe={userMe}
                addressList={addressList}
                addressListFetchState={addressListFetchState}
                reportFetchState={reportFetchState}
                multi={multi}
                handleCreateReport={onCreateReport}
                handleCorporationReport={onCreateCorporationReport}
                corporationAddressList={corporationAddressList}
                corporationAddressListFetchState={corporationAddressListFetchState}
              />
            </TitledWrapper>
            {footer}
            <ModalCloseButton onClick={() => setModalOpen(false)}>닫기</ModalCloseButton>
          </ModalInnerContainer>
        </Modal>
      )}
    </>
  );

  return (
    <Container>
      {withDefaultPrefix && (
        <PrefixContainer>
          <EmojiContainer>
            <Emoji>🤭</Emoji>
          </EmojiContainer>

          <Text>쉿! 조회한 사실은 회원님만 알 수 있어요!</Text>
        </PrefixContainer>
      )}
      <AddressSearchInnerContainer
        focused={withAnimation && searchFocused}
        disableBackgroundColor={disableBackgroundColor}
      >
        <AddressSearch
          reportFetchState={reportFetchState}
          disableSearch={!isListForm && true}
          handleSearchClick={onSearchClick}
          getAddressListByKeyword={getAddressListByKeyword}
          textFieldPlaceholder={textFieldPlaceholder}
          focusedTextFieldPlaceholder={focusedTextFieldPlaceholder}
          subButtonText={subButtonText}
          focusedSubButtonText={focusedSubButtonText}
          onFocused={getSearchFocused}
          isCorporationSearch={isCorporationSearch}
          setIsCorporationSearch={setIsCorporationSearch}
        />
        {list}
        {isListForm}
      </AddressSearchInnerContainer>
    </Container>
  );
};

export default AddressListWithSearch;
