import { ReportBuildingLedger, ReportLandUsingInfo, ReportRegisterLedger } from "@blitz-core/api-client";
import {
  RefundableAmountResponse,
  ReportLogResponse,
  TransactionResponse,
  ReportLogLimitedResponse,
  ReportLogCountResponse,
  CountResponse,
} from "@doczip/api-client";
import axios from "axios";
import {
  reportLogList,
  deleteReportLog,
  reportLogTrashList,
  restoreReportLog,
  transactionList,
  refundableAmount,
  refund,
  registerLedger,
  buildingLedger,
  landUsingInfo,
  issuedReportLogList,
  requestReportPermission,
  reportLogCount,
  issuedReportLogCount,
} from "./actions";
import { blitzCoreReportApi, reportApi, transactionApi } from "@/client";
import { AMOUNT_PER_PAGE } from "@/interfaces/pages";
import { IssuedReportLogListFetchRequestPayload } from "@/redux/logs/types";
import { GenericThunkAction, makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";
import { WithString } from "@/utils/redux/types";

export const fetchMoreReportLogListThunk = makeFetchMoreListThunkAction<ReportLogResponse, string | void>(
  reportLogList,
  {
    pageSelectFunction: ({ logs: { reportLogListPage, reportLogListTotalPage } }) => ({
      page: reportLogListPage,
      totalPage: reportLogListTotalPage,
    }),
    getResponseListFunction: (
      {
        page,
        state: {
          logs: { searchType },
        },
      },
      // NOTE: "query" is address or memo
      // the "query" is determined by "searchType" in logs reducer
      query,
    ) =>
      reportApi.getReportLogs(
        searchType === "address" ? query || void 0 : void 0,
        searchType === "memo" ? query || void 0 : void 0,
        page * AMOUNT_PER_PAGE,
        AMOUNT_PER_PAGE,
        false,
      ),
  },
);

export const fetchMoreIssuedReportLogListThunk = makeFetchMoreListThunkAction<
  ReportLogLimitedResponse,
  IssuedReportLogListFetchRequestPayload
>(issuedReportLogList, {
  pageSelectFunction: ({ logs: { issuedReportLogListPage, issuedReportLogListTotalPage } }) => ({
    page: issuedReportLogListPage,
    totalPage: issuedReportLogListTotalPage,
  }),
  getResponseListFunction: (
    {
      page,
      state: {
        logs: { searchType },
      },
    },
    { registeredAddress, ownerName, ownerIdentityCode, ownershipTargetStakeholder, mortgageTargetStakeholder },
  ) =>
    reportApi.getIssuedReportLogs(
      registeredAddress,
      ownerName,
      ownerIdentityCode,
      ownershipTargetStakeholder,
      mortgageTargetStakeholder,
      page * AMOUNT_PER_PAGE,
      AMOUNT_PER_PAGE,
    ),
});

export function requestReportPermissionThunk(reportId: number): GenericThunkAction {
  return async (dispatch) => {
    dispatch(requestReportPermission.fetchRequest());

    try {
      reportApi.requestPermissionToViewReportLog({
        report_log_id: reportId,
      });

      dispatch(requestReportPermission.fetchSuccess());
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.data.detail === "이미 추가된 리포트 입니다.") {
          dispatch(requestReportPermission.fetchSuccess());
          return;
        }

        dispatch(requestReportPermission.fetchFailure(e));
        return;
      }

      throw e;
    }
  };
}

export const fetchMoreReportLogTrashListThunk = makeFetchMoreListThunkAction<ReportLogResponse, number | void>(
  reportLogTrashList,
  {
    pageSelectFunction: ({ logs: { reportLogTrashListPage, reportLogTrashListTotalPage } }) => ({
      page: reportLogTrashListPage,
      totalPage: reportLogTrashListTotalPage,
    }),
    getResponseListFunction: ({ page }, forcePage) =>
      reportApi.getReportLogs(void 0, void 0, (forcePage ?? page) * AMOUNT_PER_PAGE, AMOUNT_PER_PAGE, true),
  },
);

export const deleteReportLogThunk = makeFetchThunkAction<void, number>(deleteReportLog, {
  getResponseFunction: (_, reportId) => reportApi.deleteReportLog(reportId),
  successSnackbarMessage: "열람내역을 삭제하였습니다",
});

export const restoreReportLogThunk = makeFetchThunkAction<void, number>(restoreReportLog, {
  getResponseFunction: (_, reportId) => reportApi.undeleteReportLog(reportId),
  successSnackbarMessage: "열람내역을 복구하였습니다",
});

export const fetchMoreTransactionListThunk = makeFetchMoreListThunkAction<TransactionResponse, void>(transactionList, {
  pageSelectFunction: ({ logs: { transactionListPage, transactionListTotalPage } }) => ({
    page: transactionListPage,
    totalPage: transactionListTotalPage,
  }),
  getResponseListFunction: ({ page }) => transactionApi.getTransactions(page * AMOUNT_PER_PAGE, AMOUNT_PER_PAGE, "me"),
});

export const fetchRefundableAmountThunk = makeFetchThunkAction<RefundableAmountResponse, string>(refundableAmount, {
  getResponseFunction: (_, transactionId) => transactionApi.getRefundableAmount(transactionId),
});

export const refundThunk = makeFetchThunkAction<void, { id: string; amount: number; reason?: string }>(refund, {
  getResponseFunction: (_, { id, amount, reason }) => transactionApi.refund(id, { amount, reason }),
  successSnackbarMessage: "환불 하였습니다",
  useErrorSnackbar: true,
});

export const registerLedgerThunk = makeFetchThunkAction<WithString<ReportRegisterLedger>, number>(registerLedger, {
  getResponseFunction: (_, reportId) => blitzCoreReportApi.reportRegisterLedger(reportId),
  useApiPolling: true,
  getKeepPollingFunction: ({ logs: { reportId } }, payload) => payload === reportId,
});

export const buildingLedgerThunk = makeFetchThunkAction<WithString<ReportBuildingLedger>, number>(buildingLedger, {
  getResponseFunction: (_, reportId) => blitzCoreReportApi.reportBuildingLedger(reportId),
  useApiPolling: true,
  getKeepPollingFunction: ({ logs: { reportId } }, payload) => payload === reportId,
});

export const landUsingInfoThunk = makeFetchThunkAction<WithString<ReportLandUsingInfo>, number>(landUsingInfo, {
  getResponseFunction: (_, reportId) => blitzCoreReportApi.reportLandUsingInfo(reportId),
  useApiPolling: true,
  getKeepPollingFunction: ({ logs: { reportId } }, payload) => payload === reportId,
});

export const reportLogCountFetchThunk = makeFetchThunkAction<ReportLogCountResponse, void>(reportLogCount, {
  getResponseFunction: () => reportApi.getReportLogCount(),
});

export const issuedReportLogCountFetchThunk = makeFetchThunkAction<
  CountResponse,
  { ownername: string; ownerIdentityCode?: string }
>(issuedReportLogCount, {
  getResponseFunction: (_, { ownername, ownerIdentityCode }) =>
    reportApi.getIssuedReportLogsCount(ownername, ownerIdentityCode),
});
