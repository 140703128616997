import React, { useEffect } from "react";
import type { Layout as LayoutOptions } from "@/containers/RootContainer";
import { set } from "@/utils/setter";

type Props = LayoutOptions;

const Layout: React.FC<Props> = (props) => {
  useEffect(() => {
    set(props, "root-layout");

    return () => {
      set({}, "root-layout");
    };
  }, [props.columnless, props.headerContent, props.footerless]);

  return <></>;
};

export default Layout;
