import React from "react";
import styled from "styled-components";
import DashboardEmoji from "@/assets/icons/dashboard-emoji.svg";
import DashboardCloudImage from "@/assets/images/dashboard/dashboard-background-cloud.png";
import DashboardHomeImage from "@/assets/images/dashboard/dashboard-background-home.png";
import TitledWrapper from "@/components/TitledWrapper";
import AddressListWithSearchContainer from "@/containers/AddressListWithSearchContainer";

const HeaderText = styled.span`
  color: #ff8f17;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.5px;
`;

const DashboardAddressListWithSearchContainer = styled.div`
  position: relative;

  width: 100%;
  margin: 24px 0 44px;

  background-color: #ff8f171a;
`;

const InnerContainer = styled.div`
  position: relative;
  z-index: 2;

  padding: 34px 36px;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;

  width: 380px;
  height: 100%;
`;

const BackgroundImageInnerContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

interface CloudImageProps {
  width: number;
  height: number;
}

const CloudImage = styled.div<CloudImageProps>`
  position: absolute;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  background-image: url(${DashboardCloudImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.cloud-image-1 {
    top: 27px;
    left: 0;
  }
  &.cloud-image-2 {
    top: 6px;
    right: 28px;
  }
`;

const HomeImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 305px;
  height: 180px;

  background-image: url(${DashboardHomeImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: translateX(-50%);
`;

const DividedFrame = styled.div`
  display: flex;
`;

const Frame = styled.div`
  &:first-of-type {
    width: 60%;
  }
  &:last-of-type {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 40%;
  }
`;

const DescriptionContainer = styled.div`
  margin-bottom: 20px;
`;

const Description = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.5px;
`;

const InquiryFactContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 10px;

  background-color: #fff;
  border-radius: 30px;
`;

const InquiryFactEmoji = styled.img`
  margin-right: 6px;
`;

const InquiryFact = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.5px;
`;

const DashboardAddressListWithSearch = () => {
  return (
    <DashboardAddressListWithSearchContainer>
      <BackgroundImageContainer>
        <BackgroundImageInnerContainer>
          <CloudImage width={150} height={80} className="cloud-image-1" />
          <CloudImage width={80} height={40} className="cloud-image-2" />
          <HomeImage />
        </BackgroundImageInnerContainer>
      </BackgroundImageContainer>

      <InnerContainer>
        <DividedFrame>
          <Frame>
            <DescriptionContainer>
              <Description>
                주소 입력하면 한 번에 해당 주소지 건물에 대한 소유주 정보, 대출정보, 불법건축물 여부를 안내드립니다.{" "}
                <br />
                일일이 건축물대장, 등기부등본을 떼보지 않아도 필요한 기능만 쏙쏙 제공해주는 정말 간편하고 스마트한
                서비스를 만나보세요.
              </Description>
            </DescriptionContainer>
            <TitledWrapper
              title="검색할 등기"
              titleStyle={{ size: 15, weight: 700 }}
              headerNodeList={[<HeaderText>공부서류 확인하고 거래 문서 작성까지!</HeaderText>]}
            >
              <AddressListWithSearchContainer multiSelect listForm="modal" disableBackgroundColor />
            </TitledWrapper>
          </Frame>
          <Frame>
            <InquiryFactContainer>
              <InquiryFactEmoji src={DashboardEmoji} />
              <InquiryFact>쉿! 조회한 사실은 회원님만 알 수 있어요!</InquiryFact>
            </InquiryFactContainer>
          </Frame>
        </DividedFrame>
      </InnerContainer>
    </DashboardAddressListWithSearchContainer>
  );
};

export default DashboardAddressListWithSearch;
