import { IconButton } from "@mui/material";
import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";

const TextFieldInnerButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: 10px;
    right: 10px;

    width: 64px;
    height: 32px;
    padding: 0;

    font-size: 12px;
  }
`;

export default TextFieldInnerButton;

export const TextFieldPlainInnerButton = styled(TextFieldInnerButton)`
  &.MuiButtonBase-root {
    color: #797979;

    background-color: #f2f2f2;

    &.Mui-disabled {
      color: #797979;

      background-color: #d6d6d6;
    }
  }
`;

export const TextFieldMultiInnerButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;

  .MuiButtonBase-root {
    &:not(:last-child) {
      margin-right: 8px;
    }

    position: relative;
    top: unset;
    right: unset;
  }
`;

export const TextFieldInnerIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: 4px;
    right: 4px;

    display: flex;
  }
`;
