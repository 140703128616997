import React, { useCallback, useEffect, useState } from "react";
import { TransactionResponse, TransactionType } from "@doczip/api-client";
import commaNumber from "comma-number";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CardButton from "./buttons/CardButton";
import ConfirmButton from "./buttons/ConfirmButton";
import PrimaryButton from "./buttons/PrimaryButton";
import Card from "./Card";
import InfiniteScrollList from "./InfiniteScrollList";
import LoadingCircle from "./LoadingCircle";
import Modal from "./Modal";
import { FetchState } from "@/interfaces/fetch";
import { resetRefundState } from "@/redux/logs/actions";
import { getRefundableAmountState, getRefundFetchState } from "@/redux/logs/selectors";
import { fetchRefundableAmountThunk, refundThunk } from "@/redux/logs/thunk";
import { toKoreanDate } from "@/utils/datetime";
import { formatTransactionType } from "@/utils/transaction";

const CardActionContainer = styled.div`
  display: flex;
  align-items: center;

  > div,
  > button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const Amount = styled.div`
  color: #797979;
  font-weight: 500;
  font-size: 12px;
`;

const StyledModal = styled(Modal)`
  > div {
    min-height: unset;
    padding-top: 0;
  }
`;

const CancelButton = styled(ConfirmButton)`
  &.MuiButtonBase-root {
    width: 50%;
  }
`;

const RefundButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    width: 50%;
  }
`;

const RefundableAmountContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  margin-top: -12px;
  margin-bottom: 12px;

  color: #797979;
  font-weight: 400;
  font-size: 13px;
`;

interface TransactionProps {
  transaction: TransactionResponse;
}

export default function Transaction({
  transaction: { ticket_amount, amount, id, transaction_type, created_datetime },
}: TransactionProps) {
  const dispatch = useDispatch();
  const { value: refundableAmount, fetchState: refundableAmountFetchState } = useSelector(getRefundableAmountState);
  const refundFetchState = useSelector(getRefundFetchState);
  const [open, setOpen] = useState(false);

  const handleRefund = useCallback(() => {
    if (refundFetchState === "FETCHING" || !refundableAmount) {
      return;
    }

    dispatch(refundThunk({ id: id.toString(), amount: refundableAmount.amount }));
  }, [id, refundableAmount, refundFetchState]);

  const handleRefundModalOpen = () => {
    dispatch(resetRefundState());
    dispatch(fetchRefundableAmountThunk(id.toString()));
    setOpen(true);
  };

  const handleRefundModalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (refundFetchState !== "SUCCESS") {
      return;
    }

    handleRefundModalClose();
  }, [refundFetchState]);

  return (
    <>
      <Card
        title={`다운로드권 ${Math.abs(ticket_amount)}회 ${formatTransactionType(transaction_type)}`}
        subTitle={toKoreanDate(created_datetime)}
        actions={
          transaction_type === TransactionType.Charge && (
            <CardActionContainer>
              <Amount>{Math.abs(amount)}원</Amount> <CardButton onClick={handleRefundModalOpen}>환불 요청</CardButton>
            </CardActionContainer>
          )
        }
      />
      <StyledModal
        titleAlign="center"
        open={open}
        title="다운로드권 환불"
        handleClose={handleRefundModalClose}
        cancelButton={<CancelButton onClick={handleRefundModalClose}>취소</CancelButton>}
        acceptButton={
          <RefundButton onClick={handleRefund} disabled={!refundableAmount?.amount || refundFetchState === "FETCHING"}>
            환불
          </RefundButton>
        }
      >
        <RefundableAmountContainer>
          {refundableAmountFetchState === "FETCHING" && <LoadingCircle />}
          {refundableAmount && <>환불하실 수 있는 금액은 {commaNumber(refundableAmount.amount)}원 입니다.</>}
        </RefundableAmountContainer>
      </StyledModal>
    </>
  );
}

const TransactionListContainer = styled.div`
  width: 100%;

  > a {
    display: block;
  }

  > div,
  > a {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
`;

interface TransactionListProps {
  list: TransactionResponse[] | null;
  fetchState: FetchState;
  page: number | null;
  totalPage: number | null;
  onLoadMore: () => void;
}

export function TransactionList({ list, ...props }: TransactionListProps) {
  return (
    <TransactionListContainer>
      <InfiniteScrollList
        list={list}
        renderItem={(transaction, key) => <Transaction key={key} transaction={transaction} />}
        {...props}
      />
    </TransactionListContainer>
  );
}
