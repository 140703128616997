import { ResourceEnum } from "@blitz-core/api-client";

export type CurrentUsingResource = "registerLedger" | "buildingLedger" | "landUsingInfo" | "landLedger";

export function getReportReRequestResource(resource: CurrentUsingResource) {
  switch (resource) {
    case "registerLedger":
      return [ResourceEnum.RegisterLedger];
    case "buildingLedger":
      return [ResourceEnum.BuildingLedger];
    case "landUsingInfo":
      return [ResourceEnum.LandUsingInfo];
    case "landLedger":
      return [ResourceEnum.LandLedger];
  }
}
