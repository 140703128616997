import React, { useCallback, useMemo } from "react";
import { ReportLogResponse } from "@doczip/api-client";
import copy from "clipboard-copy";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import LoadingButton from "@/components/buttons/LoadingButton";
import SaleModalOpenButton from "@/components/sales/SaleModalOpenButton";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";
import { openSuccessSnackbar } from "@/redux/snackbar/actions";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 23px 34px;

  background-color: #fff;
`;

const StyledLoadingButton = styled(LoadingButton)`
  &.MuiLoadingButton-root {
    width: 100%;
    height: 39px;

    color: #ff8f17;
    font-weight: 700;
    font-size: 13px;

    background-color: #fff;
    border: 1px solid #ff8f17;

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

interface ReportShowFooterProps {
  reportId: number;
  report: ReportLogResponse | null;
  reportFetchState: FetchState;
}

const ReportShare = ({ reportId, report, reportFetchState }: ReportShowFooterProps) => {
  const dispatch = useDispatch();

  const address = useMemo(() => {
    if (!report || !report?.registered_address) {
      return "";
    }

    return report.registered_address;
  }, [report, reportFetchState]);

  const accessCode = useMemo(() => {
    if (!report || !report?.access_code) {
      return;
    }

    return report.access_code;
  }, [report, reportFetchState]);

  const handleCopy = useCallback(async () => {
    await copy(`${window.location.origin}/reports/${reportId}?code=${accessCode}`);
    dispatch(openSuccessSnackbar("복사되었습니다!"));
  }, [accessCode]);

  return (
    <TitledWrapper
      title="공유하기"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
    >
      <ContentContainer>
        <StyledLoadingButton onClick={handleCopy}>다른 사람들에게 등기부등본 링크 공유하기</StyledLoadingButton>
        <SaleModalOpenButton
          as="닥집 플랫폼에 이 부동산 매물 공유하기"
          saleValues={{ address, id: reportId }}
          withLoading
        />
      </ContentContainer>
    </TitledWrapper>
  );
};

export default ReportShare;
