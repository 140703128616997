import React, { useCallback, useState } from "react";
import { CorporationReportLogResponse } from "@doczip/api-client";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as TrashIcon } from "@/assets/icons/trash.svg";
import Card from "@/components/Card";
import CorporationReportLogDocumentsDownloadModal from "@/components/coporation/CorporationReportLogDocumentsDownloadModal";
import { HoverUnderlineLink } from "@/components/Link";
import StyledButton from "@/components/StyledButton";
import { getDeleteReportLogFetchState } from "@/redux/logs/selectors";
import { deleteReportLogThunk } from "@/redux/logs/thunk";
import { lengths } from "@/styles/values";
import { toKoreanDate } from "@/utils/datetime";
import { getUrl } from "@/utils/routes";

const ActionsContainer = styled.div`
  display: flex;

  > div,
  > button,
  > a {
    &:not(:last-child) {
      margin-right: 12px;
    }

    &.revisit-button {
      display: none;
    }

    @media screen and (max-width: ${lengths.desktop}) {
      display: none;

      &.recover-button,
      &.revisit-button,
      &.delete-button {
        display: flex;
      }

      &.revisit-button {
        width: 52px;
      }
    }
  }
`;

type Props = {
  log: CorporationReportLogResponse;
};

const CorporationReportLog = ({ log }: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const deleteReportLogFetchState = useSelector(getDeleteReportLogFetchState);

  const handleDownloadModalOpen = () => {
    setOpen(true);
  };

  const handleDownloadModalClose = () => {
    setOpen(false);
  };

  const handleDeleteReportLog = useCallback(() => {
    if (deleteReportLogFetchState === "FETCHING") {
      return;
    }

    dispatch(deleteReportLogThunk(log.id));
  }, [log.id, deleteReportLogFetchState]);

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const actions = (
    <ActionsContainer onClick={stopPropagation}>
      <StyledButton bold outlined height="36px" fontSize="12px" onClick={handleDownloadModalOpen}>
        다운로드
      </StyledButton>
      <IconButton
        className="delete-button"
        onClick={handleDeleteReportLog}
        disabled={deleteReportLogFetchState === "FETCHING"}
        sx={{
          width: "36px",
          height: "36px",
        }}
      >
        <TrashIcon />
      </IconButton>
    </ActionsContainer>
  );

  return (
    <>
      <CorporationReportLogDocumentsDownloadModal
        reportId={log.provider_id}
        open={open}
        handleClose={handleDownloadModalClose}
      />
      <Card
        title={
          <HoverUnderlineLink
            href={getUrl("reportsDetail", { params: { reportId: log.id }, query: { type: "corporation" } })}
          >
            {log.company_name}
          </HoverUnderlineLink>
        }
        subTitle={toKoreanDate(log.created_datetime)}
        actions={actions}
      />
    </>
  );
};

export default CorporationReportLog;
