import { SubscriptionType } from "@doczip/api-client";
import { RegisterLedgerOwnershipSummary } from "blitz-core-api/dist";

export function formatSubscriptionType(subscriptionType: SubscriptionType | undefined) {
  switch (subscriptionType) {
    case SubscriptionType.OwnersclubBasic: {
      return "회원";
    }
    default:
      return void 0;
  }
}

export function checkOwnerIsIndividual(ownerInfo: RegisterLedgerOwnershipSummary): boolean {
  if (ownerInfo.identity_code) {
    const identityCodes = ownerInfo.identity_code.split("-");
    if (identityCodes[1] && identityCodes[1].includes("*")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
