import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "./Modal";
import { CardPrimaryButton } from "@/components/buttons/CardButton";
import Card from "@/components/Card";
import CardButtonContainer from "@/components/CardButtonContainer";
import { resetSubscriptionRefundState, resetSubscriptionCancelState } from "@/redux/subscription/actions";
import { getRefundSubscriptionFetchState, getCancelSubscriptionFetchState } from "@/redux/subscription/selectors";
import { cancelSubscriptionThunk, refundSubscriptionThunk } from "@/redux/subscription/thunk";
import { getUserMe } from "@/redux/user/selectors";
import { fetchUserMeThunk } from "@/redux/user/thunk";

const ModalDescriptionContainer = styled.div`
  margin-bottom: 16px;
`;

const ModalDescription = styled.div`
  text-align: center;

  &:not(:last-child()) {
    margin-bottom: 4px;
  }
`;

interface SubscriptionInfoCardProps {}

export default function SubscriptionInfoCard(props: SubscriptionInfoCardProps) {
  const userMe = useSelector(getUserMe);
  const dispatch = useDispatch();
  const subscriptionRefundFetchState = useSelector(getRefundSubscriptionFetchState);
  const subscriptionCancelFetchState = useSelector(getCancelSubscriptionFetchState);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [refundModalOpen, setRefundModalOpen] = useState(false);

  const subscriptionId = useMemo(() => {
    return userMe?.subscription?.id;
  }, [userMe]);

  const toggleCancelModalOpen = useCallback(() => {
    setCancelModalOpen((open) => !open);
  }, []);

  const toggleRefundModalOpen = useCallback(() => {
    setRefundModalOpen((open) => !open);
  }, []);

  const confirmSubscriptionCancel = useCallback(() => {
    if (subscriptionCancelFetchState === "FETCHING" || !subscriptionId) {
      return;
    }

    dispatch(cancelSubscriptionThunk(subscriptionId));
  }, [subscriptionId, subscriptionCancelFetchState]);

  const confirmSubscriptionRefund = useCallback(() => {
    if (subscriptionRefundFetchState === "FETCHING" || !subscriptionId) {
      return;
    }

    dispatch(refundSubscriptionThunk(subscriptionId));
  }, [subscriptionId, subscriptionRefundFetchState]);

  useEffect(() => {
    if (subscriptionCancelFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetSubscriptionCancelState());
    dispatch(fetchUserMeThunk({}));
  }, [subscriptionCancelFetchState]);

  useEffect(() => {
    if (subscriptionRefundFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetSubscriptionRefundState());
    dispatch(fetchUserMeThunk({}));
  }, [subscriptionRefundFetchState]);

  if (!userMe?.subscription || userMe.subscription.refunded_datetime) {
    return null;
  }

  return (
    <>
      <Modal
        open={cancelModalOpen}
        handleClose={toggleCancelModalOpen}
        acceptButtonText="구독 취소"
        handleAccept={confirmSubscriptionCancel}
        title="정기결제 구독 취소"
        titleAlign="center"
      >
        <ModalDescriptionContainer>
          <ModalDescription>
            멤버십을 해지하시면 사용하지 않은 회원용 무료 다운로드권이 모두 사라집니다.
          </ModalDescription>
          <ModalDescription>정말 해지하시겠습니까?</ModalDescription>
        </ModalDescriptionContainer>
      </Modal>
      <Modal
        open={refundModalOpen}
        handleClose={toggleRefundModalOpen}
        acceptButtonText="환불 요청"
        handleAccept={confirmSubscriptionRefund}
        title="정기결제 구독 환불"
        titleAlign="center"
      >
        <ModalDescriptionContainer>
          <ModalDescription>
            멤버십을 해지하시면 사용하지 않은 회원용 무료 다운로드권이 모두 사라집니다.
          </ModalDescription>
          <ModalDescription>정말 해지하시겠습니까?</ModalDescription>
        </ModalDescriptionContainer>
      </Modal>
      <Card
        title={`정기결제${!userMe.subscription.cancelled_datetime ? " 구독중" : ""}`}
        actions={
          <CardButtonContainer>
            {userMe.subscription.cancelled_datetime ? (
              <CardPrimaryButton onClick={toggleRefundModalOpen}>구독 환불</CardPrimaryButton>
            ) : (
              <CardPrimaryButton onClick={toggleCancelModalOpen}>구독 취소</CardPrimaryButton>
            )}
          </CardButtonContainer>
        }
      />
    </>
  );
}
