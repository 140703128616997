import React from "react";
import { SubscriptionType } from "@doczip/api-client";
import comma from "comma-number";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import BenefitsOneImage from "@/assets/images/owners-club/benefits-one.png";
import BenefitsTwoImage from "@/assets/images/owners-club/benefits-two.png";
import BuildingsImage from "@/assets/images/owners-club/buildings.png";
import CityImage from "@/assets/images/owners-club/city.png";
import Layout from "@/components/Layout";
import StyledButton from "@/components/StyledButton";
import { loadLoginState } from "@/redux/auth/functions";
import { getUserMe } from "@/redux/user/selectors";
import { colors, lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-right: 30px;
  padding-bottom: 85px;
  padding-left: 30px;

  @media screen and (max-width: ${lengths.tablet}) {
    padding-top: 45px;
    padding-bottom: 55px;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 20px;
`;

const BuildingsContainer = styled.div`
  position: relative;

  margin-bottom: 24px;
`;

const BuildingsImg = styled.img`
  width: 100%;
  max-width: 576px;
  height: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;

  @media screen and (max-width: ${lengths.tablet}) {
    font-size: 24px;
  }
`;

const TooltipContainer = styled.div<{ top?: string; left?: string }>`
  position: absolute;
  ${({ top, left }) => css`
    ${top && `top: ${top};`}
    ${left && `left: ${left};`}
  `}

  white-space: nowrap;

  transform: translate(-50%, -50%);
`;

const TooltipContent = styled.div`
  color: ${colors.white};
  font-weight: 500;
  font-size: 12px;
`;

const TooltipBox = styled.div<{ position?: "left" | "right" }>`
  padding: 12px;

  background-color: ${colors.dark};
  border-radius: 8px;

  @media screen and (max-width: ${lengths.narrowContainerWidth}) {
    transform: translateX(calc(${({ position }) => (position === "right" ? "" : "-")}1 * 30px));
  }

  @media screen and (max-width: ${lengths.tablet}) {
    transform: translateX(calc(${({ position }) => (position === "right" ? "" : "-")}1 * calc(100vw / 10)));
  }
`;

const TooltipArrow = styled.div<{ position?: "left" | "right" }>`
  position: absolute;
  bottom: -5px;
  ${({ position = "left" }) => position && `${position}: 10px;`}

  width: 10px;
  height: 10px;

  background-color: ${colors.dark};
  transform: rotate(45deg);

  content: "";
`;

interface TooltipProps {
  children?: React.ReactNode;
  top?: string;
  left?: string;
  arrowPosition?: "left" | "right";
}

function Tooltip({ children, top, left, arrowPosition }: TooltipProps) {
  return (
    <TooltipContainer top={top} left={left}>
      <TooltipContent>
        <TooltipBox position={arrowPosition}>{children}</TooltipBox>
        <TooltipArrow position={arrowPosition} />
      </TooltipContent>
    </TooltipContainer>
  );
}

const FreeTrialButton = styled(StyledButton)`
  width: 100%;
  max-width: 384px;
  height: 60px;
  margin-right: 20px;
  margin-left: 20px;

  font-size: 18px;

  @media screen and (max-width: ${lengths.tablet}) {
    max-width: 194px;
    height: 45px;

    font-size: 14px;
  }
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-right: 30px;
  padding-bottom: 120px;
  padding-left: 30px;

  background-color: ${colors.white};

  > div,
  > button {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: ${lengths.tablet}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const BenefitsTitle = styled(Title)`
  margin-bottom: 60px;

  @media screen and (max-width: ${lengths.tablet}) {
    margin-bottom: 40px;

    font-size: 20px;
  }
`;

const BenefitsCardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 794px;
  padding-top: 30px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-left: 30px;

  background-color: #f6f6f6;

  > div,
  > img {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: ${lengths.tablet}) {
    flex-direction: column;

    > div,
    > img {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
`;

const BenefitsCardIcon = styled.img`
  width: 64px;
  height: auto;
`;

const BenefitsCardTitleContainer = styled.div``;

const BenefitsCardTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const BenefitsCardDescription = styled.div`
  margin-top: 12px;

  font-weight: 400;
  font-size: 14px;
`;

interface BenefitsCardProps {
  icon?: string;
  title?: string;
  description?: string;
}

function BenefitsCard({ icon, title, description }: BenefitsCardProps) {
  return (
    <BenefitsCardContainer>
      <BenefitsCardIcon src={icon} />
      <BenefitsCardTitleContainer>
        <BenefitsCardTitle>{title}</BenefitsCardTitle>
        <BenefitsCardDescription>{description}</BenefitsCardDescription>
      </BenefitsCardTitleContainer>
    </BenefitsCardContainer>
  );
}

const BenefitsNote = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-right: 30px;
  padding-left: 30px;

  @media screen and (max-width: ${lengths.tablet}) {
    padding-top: 40px;
  }
`;

const PriceTitle = styled(Title)`
  margin-bottom: 60px;

  @media screen and (max-width: ${lengths.tablet}) {
    margin-bottom: 40px;

    font-size: 20px;
  }
`;

const MembershipCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 794px;
  margin-bottom: 100px;
  padding-top: 30px;
  padding-right: 100px;
  padding-bottom: 30px;
  padding-left: 100px;

  background-color: ${colors.white};

  @media screen and (max-width: ${lengths.narrowContainerWidth}) {
    margin-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
  }
`;

const MembershipCardTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  color: #3d3d3d;
`;

const MembershipTitle = styled.div`
  font-weight: 700;
  font-size: 30px;

  @media screen and (max-width: ${lengths.tablet}) {
    font-size: 20px;
  }
`;

const MembershipBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media screen and (max-width: ${lengths.tablet}) {
    width: 100%;
  }
`;

const MembershipBenefit = styled.div``;

const MembershipCardBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${lengths.tablet}) {
    flex-direction: column;

    font-size: 14px;
  }
`;

const Price = styled.div`
  color: ${colors.dark};
  font-weight: 700;
  font-size: 30px;
  white-space: nowrap;

  @media screen and (max-width: ${lengths.tablet}) {
    font-size: 20px;
  }
`;

const PerMonth = styled.span`
  font-size: 16px;

  @media screen and (max-width: ${lengths.tablet}) {
    font-size: 13px;
  }
`;

const StartLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 20px;
  padding-right: 24px;
  padding-left: 24px;

  color: ${colors.white};
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;

  background-color: ${colors.main};
  cursor: pointer;

  @media screen and (max-width: ${lengths.tablet}) {
    width: 100%;
  }
`;

const CityImg = styled.img`
  width: 100%;
  max-width: 540px;
  height: auto;
  margin-top: 100px;

  @media screen and (max-width: ${lengths.tablet}) {
    margin-top: 60px;
  }
`;

const AdminButton = styled.a`
  display: inline-block;
  margin: 16px 0;

  font-size: 14px;
  text-decoration: underline;

  cursor: pointer;
`;

export default function OwnersClubLandingPage() {
  const user = useSelector(getUserMe);
  const isLoggedIn = loadLoginState() === "SUCCESS";

  const startSubscriptionButton = (
    <>
      <FreeTrialButton to={getUrl("userRoot", { query: { subscribe_modal: "open" } })} dark bold>
        멤버십 가입하기
      </FreeTrialButton>
      {user?.is_admin && (
        <Link to={getUrl("reportsIssued")}>
          <AdminButton>(관리자 전용) 닥집 보유 등기부 검색 페이지로 이동</AdminButton>
        </Link>
      )}
    </>
  );

  if (user?.subscription?.subscription_type === SubscriptionType.OwnersclubBasic) {
    return <Navigate to={getUrl("reportsIssued")} />;
  }

  return (
    <Container>
      <Layout columnless={!isLoggedIn} />
      <BannerContainer>
        <Logo src="/images/logo.svg" />
        <TitleContainer>
          <SubTitle>등기 무료 검색하기 기능을 무제한으로 사용하세요!</SubTitle>
          <Title>오너스클럽</Title>
        </TitleContainer>
        <BuildingsContainer>
          <BuildingsImg src={BuildingsImage} />
          <Tooltip top="30%" left="15%" arrowPosition="right">
            🏗 불법건축물이 아닙니다.
          </Tooltip>
          <Tooltip top="15%" left="90%">
            👦🏻 소유주는 96년생 우영우입니다.
          </Tooltip>
        </BuildingsContainer>
        {startSubscriptionButton}
      </BannerContainer>

      <BenefitsContainer>
        <BenefitsTitle>오너스클럽 혜택</BenefitsTitle>
        <BenefitsCard
          icon={BenefitsOneImage}
          title="혜택 1. 추가 비용 없는 검색 및 열람"
          description="오너스클럽 멤버는 누구나 추가 비용 없이 닥집에서 이전에 열람된 등기부등본을 무제한으로 검색 및 1일 최대 30건 열람할 수 있습니다."
        />
        <BenefitsCard
          icon={BenefitsTwoImage}
          title="2. 신규 열람을 위한 다운로드권 매달 5건 제공"
          description="실시간으로 공적장부 6종을 신규 열람하고 거래 문서까지 작성할 수 있는 다운로드권을 매달 5건 추가 제공합니다. 해당 다운로드권은 오너스클럽 가입 상태일 때만 사용 가능합니다."
        />
        <BenefitsNote>*등기부별 발급일자가 다를 수 있으며, 이로 발생한 문제는 멤버십 회원에게 귀속됩니다.</BenefitsNote>
      </BenefitsContainer>

      <PriceContainer>
        <PriceTitle>오너스 클럽 멤버십 구성</PriceTitle>
        <MembershipCard>
          <MembershipCardTopContainer>
            <MembershipTitle>오너스 BASIC</MembershipTitle>
            <Price>
              {comma(3900)}원<PerMonth>/월</PerMonth>
            </Price>
          </MembershipCardTopContainer>
          <MembershipCardBottomContainer>
            <MembershipBenefitsContainer>
              <MembershipBenefit>● 닥집 보유 등기부등본 검색 및 열람</MembershipBenefit>
              <MembershipBenefit>● 신규열람 5건/월</MembershipBenefit>
            </MembershipBenefitsContainer>
            <Link to={getUrl("userRoot", { query: { subscribe_modal: "open" } })}>
              <StartLabel>시작하기</StartLabel>
            </Link>
          </MembershipCardBottomContainer>
        </MembershipCard>
        {startSubscriptionButton}
        <CityImg src={CityImage} />
      </PriceContainer>
    </Container>
  );
}
