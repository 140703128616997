import React, { useCallback, useMemo, useState } from "react";
import { ReportLogResponse } from "@doczip/api-client";
import copy from "clipboard-copy";
import styled from "styled-components";
import Map from "@/components/Map";
import TitledWrapper from "@/components/TitledWrapper";
import WithTooltip from "@/components/WithTooltip";
import NaverMapLoader from "@/containers/NaverMapLoader";
import { FetchState } from "@/interfaces/fetch";

const Container = styled.div``;

const HeaderNodeContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img``;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const Text = styled.span`
  color: #797979;
  font-weight: 700;
  font-size: 12px;
`;

interface ReportMapProps {
  report: ReportLogResponse | null;
  reportFetchState: FetchState;
}

const ReportMap: React.FC<ReportMapProps> = ({ report, reportFetchState }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const coordinates = useMemo(() => {
    if (!report || !report?.wgs84_latitude || !report?.wgs84_longitude) {
      return;
    }

    const { wgs84_latitude, wgs84_longitude } = report;

    return {
      latitude: wgs84_latitude.toString(),
      longitude: wgs84_longitude.toString(),
    };
  }, [report, reportFetchState]);

  const handleCopy = useCallback(async () => {
    try {
      await copy(report?.registered_address ?? "");
      setTooltipOpen(true);
    } catch {
      setTooltipOpen(false);
    }

    setTimeout(() => setTooltipOpen(false), 1500);
  }, [report, reportFetchState]);

  if (!coordinates?.latitude || !coordinates?.longitude) {
    return null;
  }

  return (
    <TitledWrapper
      title="지도"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      headerNodeList={[
        <HeaderNodeContainer>
          <IconContainer>
            <Icon src="/images/link.svg" />
          </IconContainer>
          <TextContainer>
            <WithTooltip
              open={tooltipOpen}
              onClose={setTooltipOpen}
              title="복사되었습니다!"
              type="click"
              placement="top"
            >
              <Text onClick={handleCopy}>주소 복사</Text>
            </WithTooltip>
          </TextContainer>
        </HeaderNodeContainer>,
      ]}
    >
      <Container>
        <Map latitude={coordinates.latitude} longitude={coordinates.longitude} />
      </Container>
    </TitledWrapper>
  );
};

const ReportMapWithNaverMapLoader: React.FC<ReportMapProps> = ({ report, reportFetchState }) => {
  return (
    <NaverMapLoader>
      <ReportMap report={report} reportFetchState={reportFetchState} />
    </NaverMapLoader>
  );
};

export default ReportMapWithNaverMapLoader;
