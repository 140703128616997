import React, { useEffect, useState } from "react";
import { MaintenanceScheduleRequest, PostRequest, PostResponse, PostType } from "@doczip/api-client";
import { FormControlLabel, Checkbox, Radio, RadioGroup } from "@mui/material";
import { useFormik } from "formik";
import styled from "styled-components";
import { StyledReactQuill } from "@/components/editor/TextEditor";
import Modal from "@/components/Modal";
import { TextField } from "@/components/Mui";
import RangeSelect, { Range } from "@/components/posts/common/RangeSelect";
import useWindowSize from "@/hooks/useWindowSize";
import { lengths } from "@/styles/values";
import { getTodayDateString, getTomorrowDateString } from "@/utils/datetime";

const Form = styled.form``;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;
  }
`;

const RadioTitle = styled.div`
  padding-right: 1rem;
`;

const SharedOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (min-width: ${lengths.desktop}) {
    flex-direction: column;
  }
`;

const DatesContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const DateTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    background-color: white;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px;

  @media screen and (max-width: ${lengths.desktop}) {
    padding: 20px 0;
  }
`;

const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormLabelsContainer = styled.div`
  display: flex;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 640px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
  }
`;

type PostNewFormErrors = Partial<Record<keyof PostRequest, string>>;

const validate = (values: PostRequest) => {
  const errors: PostNewFormErrors = {};
  if (!values.title) {
    errors.title = "제목을 입력해주세요.";
  }
  if (values.dashboard_pin_start_datetime && values.dashboard_pin_end_datetime) {
    if (new Date(values.dashboard_pin_start_datetime) > new Date(values.dashboard_pin_end_datetime)) {
      errors.dashboard_pin_start_datetime = "시작일이 마감일부터 클 수 없습니다.";
    }
  }
  if (values.maintenance_schedule && values.post_type === PostType.Maintenance) {
    if (
      !values.maintenance_schedule.start_date &&
      !values.maintenance_schedule.end_date &&
      !values.maintenance_schedule.start_weekday &&
      !values.maintenance_schedule.end_weekday
    ) {
      errors.maintenance_schedule = "점검 시작, 종료일 또는 요일을 반드시 선택해야 합니다.";
    }
  }

  return errors;
};

type PostNewProps = {
  savePost: (postRequest: PostRequest) => void;
  open: boolean;
  handleClose: () => void;
  post?: PostResponse | null;
};

const PostNew: React.FC<PostNewProps> = ({ savePost, open, handleClose, post }) => {
  const { width } = useWindowSize();
  const [isDashboardPin, setIsDashboardPin] = useState(false);
  const [isRegular, setIsRegular] = useState(false);
  const mobile = width > 768;

  useEffect(() => {
    if (post) {
      let newMaintenanceSchedule: MaintenanceScheduleRequest | undefined = void 0;
      if (post.maintenance_schedule && post.post_type === PostType.Maintenance) {
        if (post.maintenance_schedule.start_weekday !== void 0) {
          setIsRegular(true);
          newMaintenanceSchedule = {
            start_date: void 0,
            end_date: void 0,
            start_weekday: post.maintenance_schedule.start_weekday,
            end_weekday: post.maintenance_schedule.end_weekday,
            start_time: post.maintenance_schedule.start_time,
            end_time: post.maintenance_schedule.end_time,
          };
        } else if (post.maintenance_schedule.start_datetime) {
          newMaintenanceSchedule = {
            start_date: post.maintenance_schedule.start_datetime
              ? post.maintenance_schedule.start_datetime.substring(0, 10)
              : void 0,
            end_date: post.maintenance_schedule.end_datetime
              ? post.maintenance_schedule.end_datetime.substring(0, 10)
              : void 0,
            start_weekday: void 0,
            end_weekday: void 0,
            start_time: post.maintenance_schedule.start_time,
            end_time: post.maintenance_schedule.end_time,
          };
        }
      }
      formik.resetForm({
        values: {
          ...(post as any as PostRequest),
          dashboard_pin_start_datetime: post.dashboard_pin_start_datetime
            ? post.dashboard_pin_start_datetime.substring(0, 16)
            : void 0,
          dashboard_pin_end_datetime: post.dashboard_pin_end_datetime
            ? post.dashboard_pin_end_datetime.substring(0, 16)
            : void 0,
          maintenance_schedule: newMaintenanceSchedule,
        },
      });
      if (post.dashboard_pin_start_datetime !== void 0) {
        setIsDashboardPin(true);
      }
    }
  }, [post]);

  const initialValues: PostRequest = {
    post_type: PostType.Notice,
    title: "",
    content: "",
    summery: "",
    dashboard_pin_start_datetime: getTodayDateString(),
    dashboard_pin_end_datetime: getTomorrowDateString(),
    top_fixed: false,
    hidden: false,
    maintenance_schedule: {
      start_time: "09:00",
      end_time: "18:00",
      start_weekday: void 0,
      end_weekday: void 0,
      start_date: void 0,
      end_date: void 0,
    },
  };

  const formik = useFormik<PostRequest>({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: (values) => {
      let newMaintenanceSchedule: MaintenanceScheduleRequest | undefined = void 0;

      if (values && values.maintenance_schedule && values.post_type === PostType.Maintenance) {
        if (isRegular) {
          newMaintenanceSchedule = {
            start_date: void 0,
            end_date: void 0,
            start_weekday: values.maintenance_schedule.start_weekday,
            end_weekday: values.maintenance_schedule.end_weekday,
            start_time: values.maintenance_schedule.start_time,
            end_time: values.maintenance_schedule.end_time,
          };
        } else if (!isRegular) {
          newMaintenanceSchedule = {
            start_date: values.maintenance_schedule.start_date
              ? values.maintenance_schedule.start_date.substring(0, 10)
              : void 0,
            end_date: values.maintenance_schedule.end_date
              ? values.maintenance_schedule.end_date.substring(0, 10)
              : void 0,
            start_weekday: void 0,
            end_weekday: void 0,
            start_time: values.maintenance_schedule.start_time,
            end_time: values.maintenance_schedule.end_time,
          };
        }
      }

      const newValues: any = {
        ...(values as PostRequest),
        dashboard_pin_start_datetime: isDashboardPin ? values.dashboard_pin_start_datetime + ":00Z" : void 0,
        dashboard_pin_end_datetime: isDashboardPin ? values.dashboard_pin_end_datetime + ":00Z" : void 0,
        maintenance_schedule: newMaintenanceSchedule,
      };
      delete newValues.created_datetime;

      savePost(newValues);
      handleClose();
    },
  });

  const handleRangeSelect = <T,>(rangeValue: Range<T> | null) => {
    if (rangeValue) {
      const { start, end } = rangeValue;
      formik.setFieldValue("maintenance_schedule.start_weekday", start);
      formik.setFieldValue("maintenance_schedule.end_weekday", end);
    }
  };

  const noticeSet = (
    <SettingContainer>
      <FormControlLabel
        control={
          <Checkbox
            checked={isDashboardPin}
            value={isDashboardPin}
            onChange={() => setIsDashboardPin((prev) => !prev)}
          />
        }
        label="대시보드 핀"
      />
      {isDashboardPin && (
        <DatesContainer>
          <DateTextField
            id="dashboard_pin_start_datetime"
            name="dashboard_pin_start_datetime"
            label="대시보드 핀 시작일"
            type="datetime-local"
            value={formik.values.dashboard_pin_start_datetime}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(formik.errors.dashboard_pin_start_datetime)}
            helperText={formik.errors.dashboard_pin_start_datetime}
          />
          <DateTextField
            id="dashboard_pin_end_datetime"
            name="dashboard_pin_end_datetime"
            label="대시보드 핀 마감일"
            type="datetime-local"
            value={formik.values.dashboard_pin_end_datetime}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(formik.errors.dashboard_pin_start_datetime)}
            helperText={formik.errors.dashboard_pin_start_datetime}
          />
        </DatesContainer>
      )}
    </SettingContainer>
  );

  const maintenanceSet = (
    <>
      <RadioTitle>점검 종류:</RadioTitle>
      <FormLabelsContainer>
        <FormControlLabel
          checked={isRegular === false}
          value={false}
          onChange={() => setIsRegular(false)}
          control={<Radio checked={isRegular === false} />}
          label="비정기 점검"
        />
        <FormControlLabel
          checked={isRegular === true}
          value={true}
          onChange={() => setIsRegular(true)}
          control={<Radio checked={isRegular === true} />}
          label="정기 점검"
        />
      </FormLabelsContainer>
      <DatesContainer>
        <TextField
          id="maintenance_schedule.start_time"
          label="점검 시작 시간"
          type="time"
          value={formik.values.maintenance_schedule?.start_time}
          onChange={formik.handleChange}
          sx={{ width: 150 }}
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(formik.errors.maintenance_schedule)}
          helperText={formik.errors.maintenance_schedule}
        />
        <TextField
          id="maintenance_schedule.end_time"
          label="점검 종료 시간"
          type="time"
          value={formik.values.maintenance_schedule?.end_time}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 150 }}
          error={Boolean(formik.errors.maintenance_schedule)}
          helperText={formik.errors.maintenance_schedule}
        />
      </DatesContainer>
      {!isRegular ? (
        <SettingContainer>
          <DatesContainer>
            <TextField
              id="maintenance_schedule.start_date"
              label="점검 시작일"
              type="date"
              value={formik.values.maintenance_schedule?.start_date || ""}
              onChange={formik.handleChange}
              sx={{ width: 150 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="maintenance_schedule.end_date"
              label="점검 종료일"
              type="date"
              value={formik.values.maintenance_schedule?.end_date || ""}
              onChange={formik.handleChange}
              sx={{ width: 150 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DatesContainer>
        </SettingContainer>
      ) : (
        <RangeSelect
          defaultValue={
            formik.values.maintenance_schedule?.start_weekday && formik.values.maintenance_schedule?.end_weekday
              ? {
                  start: formik.values.maintenance_schedule?.start_weekday,
                  end: formik.values.maintenance_schedule?.end_weekday,
                }
              : void 0
          }
          options={[
            { title: "월", value: 0 },
            { title: "화", value: 1 },
            { title: "수", value: 2 },
            { title: "목", value: 3 },
            { title: "금", value: 4 },
            { title: "토", value: 5 },
            { title: "일", value: 6 },
          ]}
          onClick={handleRangeSelect}
        />
      )}
    </>
  );

  const sharedSet = (
    <>
      <StyledRadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={formik.values.post_type}
        onChange={(e, value) => formik.setFieldValue("post_type", value)}
      >
        <RadioTitle>기본 양식:</RadioTitle>
        <FormLabelsContainer>
          <FormControlLabel disabled={post !== void 0} value={PostType.Notice} control={<Radio />} label="서비스" />
          <FormControlLabel
            disabled={post !== void 0}
            value={PostType.Maintenance}
            control={<Radio />}
            label="점검 일정"
          />
        </FormLabelsContainer>
      </StyledRadioGroup>
      {formik.values.post_type === PostType.Notice && noticeSet}
      {formik.values.post_type === PostType.Maintenance && maintenanceSet}
      <SharedOptionsContainer>
        <FormControlLabel
          id="top_fixed"
          name="top_fixed"
          control={<Checkbox checked={formik.values.top_fixed} />}
          value={formik.values.top_fixed}
          onChange={formik.handleChange}
          label={mobile ? "[중요] 핀 설정(항상 상단에 표시됨)" : "[중요]"}
        />
        <FormControlLabel
          id="hidden"
          name="hidden"
          control={<Checkbox checked={formik.values.hidden} />}
          value={formik.values.hidden}
          onChange={formik.handleChange}
          label={mobile ? "일반 유저에게 숨기기(관리자만 열람이 가능함)" : "숨기기"}
        />
      </SharedOptionsContainer>
    </>
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Modal
        open={open}
        handleClose={handleClose}
        acceptButtonText="등록"
        handleAccept={formik.submitForm}
        title="공지 작성하기"
        titleAlign="center"
      >
        <InnerContainer>
          <SettingContainer>{sharedSet}</SettingContainer>
          <FormContainer>
            <TextField
              {...formik.getFieldProps("title")}
              placeholder="제목을 입력해주세요."
              error={Boolean(formik.errors.title)}
              helperText={formik.errors.title}
            />
            <StyledReactQuill
              id="content"
              theme="snow"
              placeholder="내용을 입력해주세요."
              value={formik.values.content}
              onChange={(content, delta, source, editor) => formik.setFieldValue("content", editor.getHTML())}
            />
          </FormContainer>
        </InnerContainer>
      </Modal>
    </Form>
  );
};

export default PostNew;
