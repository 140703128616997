import { UserResponse } from "@doczip/api-client";
import { add, format } from "date-fns";
/* eslint-disable import/no-unresolved */
import { RequestPayParams, RequestPayResponse } from "iamport-typings";
import Configs from "@/configs";
import { DOWNLOAD_TICKET_PRICE_WITH_VAT } from "@/interfaces/downloadTicketPrice";
import { PaymentMethod } from "@/interfaces/paymentMethod";
import { getPaymentMethodValue } from "@/utils/paymentMethod";

export interface PaymentFetchRequestPaylod {
  price: number;
  user: UserResponse;
  method: PaymentMethod;
  name?: string;
  isPaymentTestAccount?: boolean;
  paymentType?: "NORMAL" | "SUBSCRIPTION";
  customerUid?: string;
  isUpgrade?: boolean;
}

export async function requestPayment(payload: PaymentFetchRequestPaylod) {
  const {
    price,
    name,
    user: { id: userId, username, detail },
    method,
    isPaymentTestAccount,
    paymentType = "NORMAL",
    customerUid,
    isUpgrade,
  } = payload;

  const { email, name: detailName, phone_number } = detail ?? {};

  const { IMP } = window;

  if (!IMP) {
    throw new Error("결제모듈이 로드되지 않았습니다.");
  }

  IMP.init(Configs.DOCZIP_IAMPORT_UID);

  const now = Date.now();

  const orderUid =
    paymentType === "NORMAL" ? `${username}-${format(now, "YMdH")}-${now}-${price}` : `subf-${userId}-basic-${now}`;

  const subscriptionText = isUpgrade ? "오너스클럽 업그레이드" : "오너스클럽 정기결제";

  const itemName =
    paymentType === "NORMAL" ? `${Math.floor(price / DOWNLOAD_TICKET_PRICE_WITH_VAT)}회 다운로드권` : subscriptionText;

  let impResponse;

  let pg = "danal_tpay";

  if (isPaymentTestAccount) {
    pg = `${pg}.9810030929`;
  }

  if (paymentType === "NORMAL") {
    pg = method === "Transfer" ? `danal_tpay.A010014475` : `danal_tpay.A010014781`;
  }

  let period: object;

  if (paymentType === "SUBSCRIPTION" && !isUpgrade) {
    period = {
      from: format(now, "YMMdd"),
      to: format(add(now, { months: 1 }), "YMMdd"),
    };
  }

  try {
    impResponse = await new Promise<RequestPayResponse>((resolve, reject) => {
      IMP.request_pay(
        {
          pg,
          pay_method: getPaymentMethodValue(method),
          merchant_uid: orderUid,
          name: itemName,
          amount: price,
          buyer_email: email,
          buyer_name: name ?? detailName,
          buyer_tel: phone_number ?? "",
          biz_num: "211-87-09238",
          customer_uid: customerUid,
          period,
        } as RequestPayParams,
        (rsp) => (rsp.success ? resolve(rsp) : reject(rsp)),
      );
    });
  } catch (e: unknown) {
    if (typeof e === "undefined") {
      return;
    }

    if (e instanceof Error) {
      throw e;
    }

    throw new Error((e as RequestPayResponse)?.error_msg ?? "예상하지 못한 오류가 발생하였습니다.");
  }

  if (impResponse.imp_uid === null) {
    throw new Error("결제 정보를 받아오지 못하였습니다.");
  }

  return impResponse.imp_uid;
}
