import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Dialog from "@/components/Dialog";
import useWindowSize from "@/hooks/useWindowSize";
import { lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Invisible = styled.div`
  width: 0;
  height: 0;
`;

type Props = {};

const DocuemntViewportWarningDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [dismissed, setDismissed] = useState(false);

  return (
    <Invisible>
      {!dismissed && width < parseInt(lengths.desktop, 10) && (
        <Dialog
          title="PC버전을 이용해주세요"
          content="부동산 계약서, 중개대상물 확인설명서는 PC 버전으로 편리하게 작성하세요."
          onConfirm={() => setDismissed(true)}
          onClose={() => navigate(getUrl("dashboard"))}
          confirmText="그래도 보기"
          closeText="메인으로"
          narrow
        />
      )}
    </Invisible>
  );
};

export default DocuemntViewportWarningDialog;
