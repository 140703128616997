import React, { useState } from "react";
import { DocumentResponse } from "@doczip/api-client";
import styled from "styled-components";
import DocumentForm from "./DocumentForm";
import DocumentHeader from "./DocumentHeader";
import DocumentMessage from "./DocumentMessage";
import DocumentView from "./DocumentView";
import DocumentViewportWarningDialog from "./DocumentViewportWarningDialog";
import Layout from "@/components/Layout";
import ReportDialogContainer from "@/containers/ReportDialogContainer";
import { lengths } from "@/styles/values";

const DocumentEditBox = styled.div`
  position: relative;

  width: calc(100% - 16px);
  max-width: ${lengths.containerWidth};
  margin: 0 auto;
  padding: 30px 0;

  @media screen and (max-width: ${lengths.desktop}) {
    padding: 15px 0;
  }
`;

const DocumentFormBox = styled.div`
  width: calc(50% - 10px);

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100vw;
  }
`;

const DocumentViewBox = styled.div`
  position: fixed;
  top: calc(${lengths.headerHeight} + ${lengths.documentHeaderHeight} + 30px);
  right: max(calc((100vw - ${lengths.containerWidth}) / 2), 8px);

  width: min(calc((100vw / 2) - 18px), calc((${lengths.containerWidth} / 2) - 10px));
  height: calc(100vh - ${lengths.headerHeight} - ${lengths.documentHeaderHeight} - 60px);
  height: calc((var(--vh, 1vh) * 100) - ${lengths.headerHeight} - ${lengths.documentHeaderHeight} - 60px);

  @media screen and (max-width: ${lengths.desktop}) {
    right: auto;
    left: calc(100vw + 1px);
  }
`;

type Props = {
  busy?: boolean;
  saved?: boolean;
  saveError?: boolean;
  document: DocumentResponse;
  onChange: (document: DocumentResponse) => void;
  onSave: () => void;
  onSetRenderPdf: (v: (title?: string) => void) => void;
  onRenderPdf: () => void;
};

const DocumentEdit: React.FC<Props> = (props) => {
  const [downloadingReport, setDownloadingReport] = useState(false);

  const handleChangeTitle = (title: string) => {
    props.onChange({
      ...props.document,
      title,
    });
  };

  const handleDownloadReport = async () => {
    if (props.document.report_log) {
      setDownloadingReport(true);
    }
  };

  const body =
    props.document.trade_contract ||
    props.document.jeonse_contract ||
    props.document.rent_contract ||
    props.document.residential_manual ||
    null;

  return (
    <>
      <DocumentViewportWarningDialog />
      <Layout columnless footerless />
      {downloadingReport && props.document.report_log && (
        <ReportDialogContainer
          providerId={props.document.report_log.provider_id}
          onClose={() => setDownloadingReport(false)}
        />
      )}
      {body ? (
        <>
          <DocumentHeader
            title={props.document.title}
            manual={Boolean(props.document.residential_manual)}
            saved={props.saved}
            onChangeTitle={handleChangeTitle}
            onSave={() => props.onSave()}
            onDownloadReport={handleDownloadReport}
            onDownload={() => props.onRenderPdf()}
            reportDisabled={!props.document.report_log}
          />
          <DocumentEditBox>
            <DocumentFormBox>
              <DocumentForm document={props.document} onChange={(v) => props.onChange(v)} />
            </DocumentFormBox>
            <DocumentViewBox>
              <DocumentView document={props.document} onReady={(v) => props.onSetRenderPdf(v)} />
            </DocumentViewBox>
          </DocumentEditBox>
        </>
      ) : (
        <DocumentMessage channel>
          요청하신 문서 내용을 찾을 수 없습니다. 문제가 계속 발생할 시 상담원에게 문의해주세요.
        </DocumentMessage>
      )}
    </>
  );
};

export default DocumentEdit;
