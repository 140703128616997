import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import FormLabel from "./FormLabel";
import TextField from "./TextField";
import Pad from "@/components/Pad";
import { colors } from "@/styles/values";

function toRadioValue(value: any) {
  if (value === true) {
    return "__TRUE";
  } else if (value === false) {
    return "__FALSE";
  } else if (typeof value === "undefined") {
    return "__UNDEFINED";
  } else {
    return value;
  }
}

function toValue(value: any) {
  if (value === "__TRUE") {
    return true;
  } else if (value === "__FALSE") {
    return false;
  } else if (value === "__UNDEFINED") {
    return void 0;
  } else {
    return value;
  }
}

type Option<T> = [string, T];

type Props<T> = {
  label?: string;
  options: Array<Option<T>>;
  value: T;
  detail?: string;
  onChange: (value: T, detail?: string) => void;
};

const EnumField = <T,>({ label, options, value, onChange, detail }: Props<T>) => {
  const detailed = typeof detail !== "undefined";
  const handleChange = (value: any) => {
    if (detailed && value !== options[options.length - 1][1]) {
      onChange(toValue(value) as unknown as T, "");
    } else {
      onChange(toValue(value) as unknown as T);
    }
  };

  const labelContent = label ? <FormLabel>{label}</FormLabel> : <></>;

  const radioStyle = { "&.Mui-checked": { color: colors.main } };

  const optionsContent = options.map((option, i) => (
    <FormControlLabel key={i} value={toRadioValue(option[1])} control={<Radio sx={radioStyle} />} label={option[0]} />
  ));

  const getDetailContent = () => {
    if (detailed && value === options[options.length - 1][1]) {
      return (
        <Pad>
          <TextField
            variant="outlined"
            size="small"
            value={detail}
            onChange={(e) => onChange(value, e.target.value)}
            placeholder="상세 내용"
            fullWidth
          />
        </Pad>
      );
    }
    return <></>;
  };

  return (
    <>
      {labelContent}
      <RadioGroup row value={toRadioValue(value)} onChange={(e) => handleChange(e.target.value)}>
        {optionsContent}
        {getDetailContent()}
      </RadioGroup>
    </>
  );
};

export default EnumField;
