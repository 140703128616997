import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  Select as MuiSelect,
  ButtonBase as MuiButton,
} from "@mui/material";
import styled from "styled-components";
import { colors, lengths } from "@/styles/values";

export const Autocomplete = styled(MuiAutocomplete)`
  & .MuiOutlinedInput-root {
    width: 100%;
    height: 53px;
    padding: 14px 47px 14px 13px;

    background-color: #fff;

    transition: background-color 0.15s linear;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
    }

    &.Mui-focused fieldset {
      border-color: ${colors.main};
    }

    &:hover fieldset {
      border-color: ${colors.main};
    }

    @media screen and (max-width: ${lengths.desktop}) {
      & .MuiOutlinedInput-root {
        &::placeholder {
          font-weight: 400;
          font-size: 13px;
        }
      }
    }
  }
`;

export const TextField = styled(MuiTextField)`
  > .MuiInputBase-root {
    height: 100%;

    border-radius: 0;

    > .MuiInputBase-input {
      padding-right: 20px;
      padding-left: 20px;

      font-weight: 400;
      font-size: 14px;
    }
  }

  & .MuiOutlinedInput-root {
    padding-right: none;
    padding-left: none;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
    }

    &.Mui-focused fieldset {
      border-color: ${colors.main};
    }
    &:hover fieldset {
      border-color: ${colors.main};
    }

    @media screen and (max-width: ${lengths.desktop}) {
      &::placeholder {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
`;

export const Select = styled(MuiSelect)`
  & .MuiOutlinedInput-root {
    width: 100%;
    height: 100%;

    background-color: #fff;
    border-radius: 0;

    transition: background-color 0.15s linear;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
    }

    &.Mui-focused fieldset {
      border-color: ${colors.main};
    }
    &:hover fieldset {
      border-color: ${colors.main};
    }
  }

  @media screen and (max-width: ${lengths.desktop}) {
    & .MuiOutlinedInput-root {
      &::placeholder {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
`;

export const Button = styled(MuiButton)`
  &.MuiButtonBase-root {
    height: 48px;
    padding-right: 24px;
    padding-left: 24px;

    color: #fff;
    font-weight: 700;
    font-size: 14px;

    background-color: ${colors.main};

    &.Mui-disabled {
      color: #797979;

      background-color: #f2f2f2;
    }

    &.outlined {
      color: ${colors.main};

      background-color: ${colors.white};
      border: 1px solid ${colors.main};
    }

    &.default {
      color: ${colors.black};

      background-color: ${colors.white};
      border: 1px solid #cbcaca;
    }
  }
`;
