import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DownloadTicketCard from "@/components/DownloadTicketCard";
import TitledContainer from "@/components/TitledContainer";
import { TransactionList } from "@/components/Transaction";
import UserTransactionListLayout, {
  UserTransactionListLayoutInnerContainer,
} from "@/components/users/transaction/UserTransactionListLayout";
import UserCommonLayout from "@/components/users/UserCommonLayout";
import { resetRefundState, resetTransactionListState } from "@/redux/logs/actions";
import { getRefundFetchState, getTransactionListState } from "@/redux/logs/selectors";
import { fetchMoreTransactionListThunk } from "@/redux/logs/thunk";

interface UserTransactionListContainerProps {}

export default function UserTransactionListContainer(props: UserTransactionListContainerProps) {
  const {
    value: transactionList,
    fetchState: transactionListFetchState,
    page: transactionListPage,
    totalPage: transactionListTotalPage,
  } = useSelector(getTransactionListState);
  const refundFetchState = useSelector(getRefundFetchState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (refundFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetRefundState());
    dispatch(resetTransactionListState());
    dispatch(fetchMoreTransactionListThunk());
  }, [refundFetchState]);

  const handleLoadMoreTransactionList = useCallback(() => {
    if (transactionListFetchState === "FETCHING") {
      return;
    }

    dispatch(fetchMoreTransactionListThunk());
  }, [transactionListFetchState]);

  return (
    <UserCommonLayout>
      <UserTransactionListLayout>
        <UserTransactionListLayoutInnerContainer>
          <DownloadTicketCard hideLogListButton />
          <TitledContainer title="열람 및 결제 내역">
            <TransactionList
              list={transactionList}
              fetchState={transactionListFetchState}
              page={transactionListPage}
              totalPage={transactionListTotalPage}
              onLoadMore={handleLoadMoreTransactionList}
            />
          </TitledContainer>
        </UserTransactionListLayoutInnerContainer>
      </UserTransactionListLayout>
    </UserCommonLayout>
  );
}
