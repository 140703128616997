import Configs from "../configs";

export function init() {
  if (typeof Configs.DOCZIP_CHTALK_PLUGIN_KEY === "undefined") {
    // Show an error message when there is no key provided.
    // eslint-disable-next-line no-console
    console.error("No Channel.io plugin key is provided.");
  }

  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  const rawCode = `
    (function() {
      var w = window;
      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function(){})("ChannelIO script included twice.");
      }
      var ch = function() {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function(args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        s.charset = "UTF-8";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === "complete") {
        l();
      } else if (window.attachEvent) {
        window.attachEvent("onload", l);
      } else {
        window.addEventListener("DOMContentLoaded", l, false);
        window.addEventListener("load", l, false);
      }
    })();
    ChannelIO("boot", {
      "pluginKey": "${Configs.DOCZIP_CHTALK_PLUGIN_KEY}"
    });
  `;
  scriptElement.innerHTML = rawCode;
  document.body.appendChild(scriptElement);
}

export function showMessenger() {
  (window as any).ChannelIO("showMessenger");
}
