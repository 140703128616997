import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import Footer from "./Footer";
import SideColumn from "./SideColumn";
import HeaderContainer from "@/containers/HeaderContainer";
import { lengths } from "@/styles/values";

const BodyBox = styled.div`
  min-width: ${lengths.sm};
  padding-left: ${lengths.sideColumnWidth};

  &.columnless {
    padding-left: 0;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    padding-left: 0;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  min-height: calc(100vh - ${lengths.headerHeight} - ${lengths.footerHeight});
  min-height: calc((var(--vh, 1vh) * 100) - ${lengths.headerHeight} - ${lengths.footerHeight});

  @media screen and (max-width: ${lengths.desktop}) {
    min-height: calc(100vh - ${lengths.mobileHeaderHeight} - ${lengths.mobileFooterHeight});
    min-height: calc((var(--vh, 1vh) * 100) - ${lengths.mobileHeaderHeight} - ${lengths.mobileFooterHeight});
  }
`;

type Props = {
  columnless?: boolean;
  footerless?: boolean;
  headerContent?: React.ReactNode;
  isBorderLeftInFooter?: boolean;
  mobileTitle?: React.ReactNode;
  onMobileBackClick?: () => void;
};

const Root: React.FC<Props> = (props) => {
  const { columnless, footerless, headerContent, isBorderLeftInFooter, mobileTitle, onMobileBackClick, children } =
    props;

  return (
    <>
      {!columnless && <SideColumn />}
      <BodyBox className={classnames({ columnless })}>
        <HeaderContainer
          logo={columnless}
          fullWidth={!columnless}
          padded={!columnless}
          mobileTitle={mobileTitle}
          onMobileBackClick={onMobileBackClick}
        >
          {headerContent}
        </HeaderContainer>
        <ContentBox>{children}</ContentBox>
        {!footerless && <Footer fullWidth={!columnless} borderLeft={isBorderLeftInFooter} />}
      </BodyBox>
    </>
  );
};

export default Root;
