import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;

  font-size: 13px;

  background: #fff;
`;

const Title = styled.div`
  margin-bottom: 4px;

  font-weight: 700;
`;

const Description = styled.div`
  font-weight: 400;
`;

interface RowProps {
  bold?: boolean;
}

const Row = styled.div<RowProps>`
  margin-bottom: 4px;

  color: ${(props) => (props.bold ? "rgba(0, 0, 0, 0.7)" : "inherit")};
  font-weight: ${(props) => (props.bold ? 600 : "normal")};
`;

interface TermsProps {
  title: string;
  description: string | string[];
  bold?: boolean;
}

export default function PaymentTerms({ title, description, bold }: TermsProps) {
  return (
    <>
      <Title>{title}</Title>
      <Description>
        {typeof description === "string" ? (
          <Row bold={bold}>{description}</Row>
        ) : (
          description.map((row, i) => (
            <Row key={i} bold={bold}>
              {row}
            </Row>
          ))
        )}
      </Description>
    </>
  );
}

interface ChargeDownloadTicketTermsProps {
  className?: string;
}

export function ChargeDownloadTicketTerms({ className }: ChargeDownloadTicketTermsProps) {
  return (
    <Container className={className}>
      <PaymentTerms
        title="이용 안내"
        description={["· 잔여 다운로드권에 대해 홈페이지를 통해 환불 처리가 가능합니다."]}
      />
      <PaymentTerms
        title="환불 방법"
        description="· ‘잔여 다운로드권’ 페이지 내 ‘환불요청’ 버튼을 눌러주시면 고객센터로 연결되며, 담당자가 환불 처리를도와드리고 있습니다."
      />
      <PaymentTerms
        title="소요기간"
        description={[
          "· 환불 및 카드결제 취소 시 '카드매출취소'로만 취소되며 즉시~영업일 기준 최대 5일 소요됩니다.",
          "· 충전 후 사용 내역이 없는 경우, 충전일로부터 최대 7일 내 충전 취소가 가능합니다.",
          "· 충전 취소 시, 건축물 유형에 따른 조회 불가 사유가 확인되면 환불을 진행합니다.",
        ]}
      />
    </Container>
  );
}

interface SubscribeTermsProps {
  className?: string;
}

export function SubscribeTerms({ className }: SubscribeTermsProps) {
  return (
    <Container className={className}>
      <PaymentTerms
        title="이용 안내"
        description={[
          "· 오너스클럽 회원은 닥집에서 이전에 열람된 등기부등본을 자유롭게 검색 및 열람하실 수 있습니다. (1일 최대 30건)",
          ". 매달 신규 열람이 가능한 다운로드권 5건 제공해드립니다. (오너스클럽 해지 및 환불 시 제공된 다운로드권 5건 소멸)",
          `· 마지막 결제 후 7일 이내, 구독 갱신일을 기준으로 등기부등본 다운로드권 미사용 시 홈페이지를 통해 환불 처리가 가능합니다.`,
        ]}
        bold
      />
      <PaymentTerms
        title="환불 방법"
        description="· '오너스클럽 정기결제 내역' 페이지 내 '환불' 버튼을 눌러주시면 환불 진행 가능합니다. 문제 발생 시 화면 오른쪽 아래 문의 버튼을 눌러 고객센터에 문의해주세요."
      />
      <PaymentTerms
        title="소요기간"
        description={["· 환불 요청 시, 선택한 결제 방법에 따라 즉시~영업일 기준 최대 5일 소요됩니다."]}
      />
    </Container>
  );
}
