import styled from "styled-components";
import UserCommonLayout from "./UserCommonLayout";

const UserShowLayout = styled(UserCommonLayout)`
  > div:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export default UserShowLayout;
