import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserMe } from "@/redux/user/selectors";
import { init as initAnalytics, pageview, setUserId } from "@/utils/analytics";
import { init as initChannel } from "@/utils/channel";

type Props = {};

export default function MonitorContainer(props: Props) {
  const location = useLocation();
  const userMe = useSelector(getUserMe);

  useEffect(() => {
    // Initialize dynamic global dependencies.
    initChannel();
    initAnalytics();
  }, []);

  useEffect(() => {
    // Send pageview events to GA when the location changes.
    pageview(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Tell dependencies about the session holder.
    setUserId(userMe?.id || null);
  }, [userMe?.id]);

  return <></>;
}
