import React from "react";
import { DocumentResponse, HeatingFuelTypeEnum, ResidentialManualResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Bracket from "@/components/paper/Bracket";
import Checkbox from "@/components/paper/Checkbox";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const ManualBuildingView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Table bmargin={10} columnSizes={[110, 110, 90, 190, 45, 100]}>
      <Table row height={45}>
        <Box td rowSpan={10} font="label">
          ⑩ 내부·
          <br />
          외부 시설물
          <br />
          의 상태
          <br />
          (건축물)
        </Box>
        <Box td rowSpan={2} font="label">
          수도
        </Box>
        <Box td>파손 여부</Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_water_damage}
            checkboxes={[
              ["없음", false],
              ["있음", true],
            ]}
            detailValue={body.water_damage_detail}
            detailLabel="위치:"
            detailWidth="10em"
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>용수량</Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_water_shortage}
            checkboxes={[
              ["정상", false],
              ["부족함", true],
            ]}
            detailValue={body.water_shortage_detail}
            detailLabel="위치:"
            detailWidth="10em"
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td font="label">
          전기
        </Box>
        <Box td>공급상태</Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_electricity_damage}
            checkboxes={[
              ["정상", false],
              ["교체 필요", true],
            ]}
            detailValue={body.electricity_damage_detail}
            detailLabel="교체할 부분:"
            detailWidth="10em"
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td font="label">
          가스(취사용)
        </Box>
        <Box td>공급방식</Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_lng_for_kitchen}
            checkboxes={[
              ["도시가스", true],
              ["그 밖의 방식", false],
            ]}
            detailValue={body.kitchen_fuel_detail}
            detailWidth="10em"
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td font="label">
          소방
        </Box>
        <Box td>
          단독경보형
          <br />
          감지기
        </Box>
        <Box td hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_fire_detector}
            checkboxes={[
              ["없음", false],
              ["있음", true],
            ]}
          />
          <Bracket round>수량:&nbsp;&nbsp;{body.fire_detector_count || ""}&nbsp;&nbsp;개</Bracket>
        </Box>
        <Box td colSpan={3} hpadding="normal" align="left">
          ※ 「화재예방, 소방시설 설치․유지 및 안전관리에 관한 법률」 제8조 및 같은 법 시행령 제13조에 따른 주택용
          소방시설로서 아파트(주택으로 사용하는 층수가 5개층 이상인 주택을 말한다)를 제외한 주택의 경우만 작성합니다.
        </Box>
      </Table>
      <Table row height={45}>
        <Box td rowSpan={2} font="label">
          난방방식 및
          <br />
          연료공급
        </Box>
        <Box td>공급방식</Box>
        <Box td colSpan={2} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_heating_individual}
            checkboxes={[
              ["중앙공급", false],
              ["개별공급", true],
            ]}
          />
        </Box>
        <Box td>시설작동</Box>
        <Box td hpadding="normal" align="left" font="small">
          <EnumCheckboxGroup
            value={body.is_heating_damage}
            checkboxes={[
              ["정상", false],
              ["수선 필요", true],
            ]}
            detailValue={body.heating_damage_detail}
          />
          <br />
          개별 공급인 경우 사용연한
          <Space size="tiny" />
          <Bracket round size="large" align="center">
            {body.heater_age > 0 ? body.heater_age : ""}
          </Bracket>
          <Space size="tiny" />
          <Checkbox label="확인 불가" value={body.heater_age === 0} />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>종류</Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.heating_fuel_type}
            checkboxes={[
              ["도시가스", HeatingFuelTypeEnum.NaturalGas],
              ["기름", HeatingFuelTypeEnum.Kerosene],
              ["프로판가스", HeatingFuelTypeEnum.ProphaneGas],
              ["연탄", HeatingFuelTypeEnum.Brequet],
              ["그 밖의 종류", HeatingFuelTypeEnum.Others],
            ]}
            detailValue={body.heating_fuel_type_detail}
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td font="label">
          승강기
        </Box>
        <Box td colSpan={5} hpadding="normal" align="left">
          <EnumCheckboxGroup value={body.is_elevator} checkboxes={[["있음", true]]} />
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_elevator_damage}
              checkboxes={[
                ["양호", false],
                ["불량", true],
              ]}
            />
          </Bracket>
          <Space size="large" />
          <EnumCheckboxGroup value={body.is_elevator} checkboxes={[["없음", false]]} />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td font="label">
          배수
        </Box>
        <Box td colSpan={5} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_drainage_damage}
            checkboxes={[
              ["정상", false],
              ["수선 필요", true],
            ]}
            detailValue={body.drainage_damage_detail}
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td font="label">
          그 밖의
          <br />
          시설물
        </Box>
        <Box td colSpan={5} hpadding="normal" align="left">
          {body.other_facilities}
        </Box>
      </Table>
    </Table>
  );
};

export default ManualBuildingView;
