import React, { useEffect, useState } from "react";
import {
  DocumentResponse,
  DocumentTypeEnum,
  JeonseContractResponse,
  RentContractResponse,
  ResidentialManualResponse,
  TradeContractResponse,
} from "@doczip/api-client";
import ContractView from "./common/ContractView";
import ManualView from "./common/ManualView";
import ScaledFrame from "./ScaledFrame";
import { alert } from "@/utils/dialog";
import { getPdfFromViewContainer } from "@/utils/pdf";

type Props = {
  document: DocumentResponse;
  onReady?: (renderPdf: (title?: string) => void) => void;
};

const DocumentView: React.FC<Props> = ({ document, onReady }) => {
  const [title, setTitle] = useState<string | undefined>();
  const [rendering, setRendering] = useState(false);
  const [container, setContainer] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const savePdf = async () => {
    if (container?.current) {
      try {
        const pdf = await getPdfFromViewContainer(container.current);
        pdf.save(`${title || "Document"}.pdf`);
      } catch (err) {
        await alert("PDF 생성에 실패했습니다.");
      } finally {
        setRendering(false);
      }
    }
  };

  useEffect(() => {
    if (onReady) {
      const render = (title?: string) => {
        setTitle(title);
        setRendering(true);
      };
      onReady(render);
    }
  }, []);

  useEffect(() => {
    if (rendering) {
      savePdf();
    }
  }, [rendering]);

  return (
    <ScaledFrame
      viewWidth={1132}
      maxScaleWidth={1500}
      onReady={(container) => setContainer(container)}
      printing={rendering}
    >
      {document.document_type === DocumentTypeEnum.ResidentialManual ? (
        <ManualView document={document} body={document.residential_manual as ResidentialManualResponse} />
      ) : (
        <ContractView
          document={document}
          body={
            (document.trade_contract || document.jeonse_contract || document.rent_contract) as
              | TradeContractResponse
              | JeonseContractResponse
              | RentContractResponse
          }
        />
      )}
    </ScaledFrame>
  );
};

export default DocumentView;
