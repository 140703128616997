import React from "react";
import { DocumentResponse, ResidentialManualResponse } from "@doczip/api-client";
import ManualBrokerageSignView from "./ManualBrokerageSignView";
import ManualBrokerageView from "./ManualBrokerageView";
import ManualBuildingView from "./ManualBuildingView";
import ManualDescriptionView from "./ManualDescriptionView";
import ManualHeaderView from "./ManualHeaderView";
import ManualLandUseView from "./ManualLandUseView";
import ManualLegalView from "./ManualLegalView";
import ManualOtherBasicView from "./ManualOtherBasicView";
import ManualOtherDetailView from "./ManualOtherDetailView";
import ManualSiteView from "./ManualSiteView";
import ManualUnrecordedLegalView from "./ManualUnrecordedLegalView";
import Paper from "@/components/Paper";
import Box from "@/components/paper/Box";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const ManualView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Paper container>
      <Paper>
        <ManualHeaderView document={document} body={body} />
        <Box font="sectionTitle" bmargin={10}>
          Ⅰ. 개업공인중개사 기본 확인사항
        </Box>
        {/* 1-1 */}
        <ManualDescriptionView document={document} body={body} />
        {/* 1-2 */}
        <ManualLegalView document={document} body={body} />
        {/* 1-3 */}
        <ManualLandUseView document={document} body={body} />
      </Paper>
      <Paper>
        {/* 1-4 */}
        <ManualSiteView document={document} body={body} />
        {/* 1-5 - 1-8 */}
        <ManualOtherBasicView document={document} body={body} />
        <Box font="sectionTitle">Ⅱ. 개업공인중개사 세부 확인사항</Box>
        {/* 2-9 */}
        <ManualUnrecordedLegalView document={document} body={body} />
        {/* 2-10 */}
        <ManualBuildingView document={document} body={body} />
      </Paper>
      <Paper flexible>
        {/* 2-11 - 2-12 */}
        <ManualOtherDetailView document={document} body={body} />
        <Box font="sectionTitle" bmargin={10}>
          Ⅲ. 중개보수 등에 관한 사항
        </Box>
        {/* 3 */}
        <ManualBrokerageView document={document} body={body} />
        {/* Signs */}
        <ManualBrokerageSignView document={document} body={body} />
      </Paper>
    </Paper>
  );
};

export default ManualView;
