import { blitzCoreAddressApi, corporationApi } from "@/client";
import {
  addressListFetchFailure,
  addressListFetchRequest,
  addressListFetchSuccess,
  corporationAddressListFetchFailure,
  corporationAddressListFetchRequest,
  corporationAddressListFetchSuccess,
} from "@/redux/address/actions";
import { AddressListFetchRequestPayload } from "@/redux/address/types";
import { CommonThunkAction } from "@/redux/types";
import { handleAxiosError } from "@/redux/util";

export const addressListFetchThunk = ({ keyword }: AddressListFetchRequestPayload): CommonThunkAction => {
  return async (dispatch) => {
    dispatch(addressListFetchRequest());

    try {
      const {
        data: { addresses },
      } = await blitzCoreAddressApi.addressGetRegisterAddress(keyword, "ALL", "PRESENT");

      dispatch(addressListFetchSuccess(addresses ?? []));
    } catch (e) {
      handleAxiosError(e, dispatch, addressListFetchFailure, { useErrorSnackbar: true });
    }
  };
};

export const corporationAddressListFetchThunk = (keyword: string): CommonThunkAction => {
  return async (dispatch) => {
    dispatch(corporationAddressListFetchRequest());

    try {
      const { data: addresses } = await corporationApi.searchCorporation(keyword);

      dispatch(corporationAddressListFetchSuccess(addresses ?? []));
    } catch (e) {
      handleAxiosError(e, dispatch, corporationAddressListFetchFailure, { useErrorSnackbar: true });
    }
  };
};
