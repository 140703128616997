import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import SaleShow from "@/components/sales/SaleShow";
import { resetSaleState } from "@/redux/sales/actions";
import { getDeleteSaleFetchState, getSale, getSaleFetchState, getUpdateSaleFetchState } from "@/redux/sales/selectors";
import { deleteSaleThunk, fetchSaleThunk } from "@/redux/sales/thunk";
import { getUserMe } from "@/redux/user/selectors";
import { confirm } from "@/utils/dialog";

const SaleShowContainer = () => {
  const { saleId } = useParams();
  const sale = useSelector(getSale);
  const user = useSelector(getUserMe);
  const saleShowFetchState = useSelector(getSaleFetchState);
  const updateSaleFetchState = useSelector(getUpdateSaleFetchState);
  const deleteSaleFetchState = useSelector(getDeleteSaleFetchState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (saleId) {
      dispatch(fetchSaleThunk({ saleId: Number(saleId) }));
    }
  }, [saleId]);

  useEffect(() => {
    if (updateSaleFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetSaleState());
    if (saleId) {
      dispatch(fetchSaleThunk({ saleId: Number(saleId) }));
    }
  }, [updateSaleFetchState]);

  const handleDeleteSale = useCallback(async () => {
    if (deleteSaleFetchState === "FETCHING") {
      return;
    }
    if (sale) {
      if (await confirm("정말로 삭제하시겠습니까?")) {
        dispatch(deleteSaleThunk(sale.id));
      }
    }
  }, [sale?.id, deleteSaleFetchState]);

  return (
    <SaleShow
      sale={sale}
      user={user}
      onDeleteSale={handleDeleteSale}
      deleteSaleFetchState={deleteSaleFetchState}
      fetchState={saleShowFetchState}
    />
  );
};

export default SaleShowContainer;
