import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import TextFieldInnerButton from "@/components/buttons/TextFieldInnerButton";
import TextField, { TextFieldWithInnerButton } from "@/components/TextField";
import TitledContainer from "@/components/TitledContainer";
import { changePasswordThunk } from "@/redux/user/thunk";
import { passwordSchema } from "@/utils/validator";

interface ChangePasswordField {
  password: string;
  newPassword: string;
}

const ChangePasswordSchema = yup.object<ChangePasswordField>({
  password: yup.string().required("기존 비밀번호를 입력해주세요"),
  newPassword: passwordSchema,
});

export default function ChangePassword() {
  const dispatch = useDispatch();

  const {
    handleSubmit: handleChangePasswordNumberSubmit,
    getFieldProps: getChangePasswordNumberFieldProps,
    errors: changePasswordErrors,
  } = useFormik<ChangePasswordField>({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: ({ password, newPassword }) => {
      dispatch(
        changePasswordThunk({
          currentPassword: password,
          newPassword,
        }),
      );
    },
  });

  return (
    <form onSubmit={handleChangePasswordNumberSubmit}>
      <TitledContainer title="비밀번호" />
      <TextField
        fullWidth
        label="기존 비밀번호"
        type="password"
        error={!!changePasswordErrors.password}
        helperText={changePasswordErrors.password}
        {...getChangePasswordNumberFieldProps("password")}
        sx={{ marginBottom: "16px" }}
      />
      <TextFieldWithInnerButton
        label="새로운 비밀번호"
        type="password"
        error={!!changePasswordErrors.newPassword}
        helperText={changePasswordErrors.newPassword}
        {...getChangePasswordNumberFieldProps("newPassword")}
        innerButton={
          <TextFieldInnerButton
            type="submit"
            disabled={!!changePasswordErrors.password || !!changePasswordErrors.newPassword}
          >
            수정
          </TextFieldInnerButton>
        }
      />
    </form>
  );
}
