import React, { useState } from "react";
import { TradeType } from "@doczip/api-client";
import styled from "styled-components";
import Dropdown from "@/components/dropdown";
import { colors } from "@/styles/values";
import { convertTradeTypeToString } from "@/utils/sales";

const options = [
  { label: "매매", type: TradeType.Deal },
  { label: "전세", type: TradeType.Tnant },
  { label: "월세", type: TradeType.Rent },
];

const SelectTriggerButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  color: ${colors.black};
  font-weight: 400;
  font-size: 14px;

  background-color: ${colors.white};
  cursor: pointer;
`;

const SelectMenuButton = styled.div`
  width: 100%;
  margin: 0;
  padding: 5px 10px;

  color: ${colors.black};
  font-weight: 400;
  font-size: 14px;

  &:hover {
    background-color: ${colors.lighterGray};
  }
`;

type TradeTypeSelectProps = {
  onChange?: (type: TradeType) => void;
  defaultValue?: TradeType;
};

const TradeTypeSelect = ({ onChange, defaultValue }: TradeTypeSelectProps) => {
  const [type, setType] = useState<TradeType>(defaultValue ?? options[0].type);

  const handleChange = (type: TradeType) => {
    setType(type);
    if (onChange) {
      onChange(type);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Trigger
        as={<SelectTriggerButton>{convertTradeTypeToString(type)}</SelectTriggerButton>}
        hasUpDownArrow
      />
      <Dropdown.Menu fullWidth>
        {options.map((option, index) => (
          <Dropdown.MenuItem onClick={() => handleChange(option.type)} key={index}>
            <SelectMenuButton>{option.label}</SelectMenuButton>
          </Dropdown.MenuItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TradeTypeSelect;
