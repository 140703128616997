import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as DoczipCoinIcon } from "@/assets/icons/dcoin.svg";
import AmountText from "@/components/AmountText";
import CardButton, { CardPrimaryButton } from "@/components/buttons/CardButton";
import Card from "@/components/Card";
import CardButtonContainer from "@/components/CardButtonContainer";
import Link from "@/components/Link";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";

interface DownloadTicketCardProps {
  hideChargeButton?: boolean;
  hideLogListButton?: boolean;
  hideLeadingIcon?: boolean;
}

export default function DownloadTicketCard({
  hideChargeButton,
  hideLogListButton,
  hideLeadingIcon,
}: DownloadTicketCardProps) {
  const userMe = useSelector(getUserMe);

  return (
    <Card
      leadingIcon={hideLeadingIcon ? null : <DoczipCoinIcon />}
      title="다운로드권"
      subTitle={<AmountText>{userMe?.ticket_amount}개</AmountText>}
      actions={
        <CardButtonContainer>
          {!hideChargeButton && (
            <Link href={getUrl("userTransactionsNew")}>
              <CardPrimaryButton>결제하기</CardPrimaryButton>
            </Link>
          )}
          {!hideLogListButton && (
            <Link href={getUrl("userTransactionsRoot")}>
              <CardButton>결제내역</CardButton>
            </Link>
          )}
        </CardButtonContainer>
      }
    />
  );
}
