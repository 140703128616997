import React from "react";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import styled from "styled-components";
import { toYmd } from "@/utils/datetime";

const DateFieldContainer = styled.div`
  position: relative;

  display: inline-block;
  width: 100%;
  height: 2.5rem;

  & input {
    position: relative;

    width: 100%;
    height: 2.5rem;
    padding: 0 14px;

    font-size: 1rem;
    line-height: 2.5rem;

    border: 1px solid #ccc;
    border-radius: 4px;

    &:hover {
      border-color: #000;
    }
  }
`;

const Label = styled.div`
  position: absolute;
  top: -0.45rem;
  left: 0.5rem;
  z-index: 1;

  padding: 0 0.3rem;

  color: #999;
  font-size: 0.75rem;

  background-color: #fff;
  cursor: default;
`;

type Props = {
  label?: string;
  value: string;
  onChange: (value: string) => void;
};

const DateField: React.FC<Props> = React.memo((props) => {
  const handleChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    props.onChange(toYmd(date, { useIsoFormat: true }));
  };

  const label = props.label ? <Label>{props.label}</Label> : <></>;

  return (
    <DateFieldContainer>
      {label}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          value={props.value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
    </DateFieldContainer>
  );
});

export default DateField;
