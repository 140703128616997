import React from "react";
import { TernaryConditionEnum } from "@doczip/api-client";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

type Props = {
  label: string;
  value: TernaryConditionEnum;
  onChange: (value: TernaryConditionEnum) => void;
  options?: [string, string, string];
};

const TernaryField: React.FC<Props> = ({ label, value, onChange, options }) => {
  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={(e) => onChange(e.target.value as TernaryConditionEnum)}
        label={label}
      >
        <MenuItem value={TernaryConditionEnum.Bad}>{options ? options[0] : "나쁨"}</MenuItem>
        <MenuItem value={TernaryConditionEnum.Normal}>{options ? options[1] : "보통"}</MenuItem>
        <MenuItem value={TernaryConditionEnum.Good}>{options ? options[2] : "좋음"}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default TernaryField;
