import { corporationSlice } from "@/redux/corporation/slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = corporationSlice.actions;

export const {
  reset: resetCorporationState,
  resetCorporationReportLogState,
  resetCorporationReportLogListState,
  resetCreateCorporationReportLogState,
} = actions;

export const corporationReportLog = mergeAsyncAction(actions, "corporationReportLog");

export const corporationReportLogList = mergeAsyncAction(actions, "corporationReportLogList");

export const createCorporationReportLog = mergeAsyncAction(actions, "createCorporationReportLog");
