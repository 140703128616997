import * as VestaApi from "@vesta/api-client";
import * as blitzCoreApi from "@blitz-core/api-client";
import * as client from "@doczip/api-client";
import Configs from "../configs";
import { axios } from "./auth";

const config = new client.Configuration({
  basePath: Configs.DOCZIP_REST_API_URL,
});

const blitzCoreConfig = new client.Configuration({
  basePath: `${Configs.DOCZIP_REST_API_URL}/blitz-core-api`,
});

const vestaConfig = new client.Configuration({
  basePath: `${Configs.DOCZIP_REST_API_URL}/vesta`,
});

export const addressApi = new client.AddressApi(config, void 0, axios);
export const commonApi = new client.CommonApi(config, void 0, axios);
export const userApi = new client.UserApi(config, void 0, axios);
export const oauth2Api = new client.Oauth2Api(config, void 0, axios);
export const documentApi = new client.DocumentApi(config, void 0, axios);
export const reportApi = new client.ReportApi(config, void 0, axios);
export const corporationApi = new client.CorporationApi(config, void 0, axios);
export const verificationApi = new client.VerificationApi(config, void 0, axios);
export const blitzCoreApiApi = new client.BlitzCoreApiApi(config, void 0, axios);
export const transactionApi = new client.TransactionApi(config, void 0, axios);
export const realtorApi = new client.RealtorApi(config, void 0, axios);
export const postApi = new client.PostApi(config, void 0, axios);
export const saleApi = new client.SaleApi(config, void 0, axios);
export const subscriptionApi = new client.SubscriptionApi(config, void 0, axios);
export const saleCommentApi = new client.SaleCommentApi(config, void 0, axios);

export const blitzCoreCommonApi = new blitzCoreApi.CommonApi(blitzCoreConfig, void 0, axios);
export const blitzCoreReportApi = new blitzCoreApi.ReportApi(blitzCoreConfig, void 0, axios);
export const blitzCoreSearchApi = new blitzCoreApi.SearchApi(blitzCoreConfig, void 0, axios);
export const blitzCoreCorporationApi = new blitzCoreApi.CorporationApi(blitzCoreConfig, void 0, axios);
export const blitzCoreAddressApi = new blitzCoreApi.AddressApi(blitzCoreConfig, void 0, axios);
export const blitzCoreRegulatedRegionApi = new blitzCoreApi.RegulatedRegionApi(blitzCoreConfig, void 0, axios);

export const vestaListingApi = new VestaApi.ListingApi(vestaConfig, void 0, axios);
