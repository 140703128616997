import React from "react";
import Container from "./paper/Container";
import FlexiblePage from "./paper/FlexiblePage";
import Page from "./paper/Page";

type Props = {
  container?: boolean;
  flexible?: boolean;
};

const Paper: React.FC<Props> = ({ children, container, flexible }) => {
  if (container) {
    return <Container>{children}</Container>;
  } else if (flexible && Array.isArray(children)) {
    return <FlexiblePage>{children}</FlexiblePage>;
  } else {
    return <Page>{children}</Page>;
  }
};

export default Paper;
