import { CreateSaleRequest, SaleLimitedResponse, SaleResponse } from "@doczip/api-client";
import { saleApi } from "@/client";
import { createSale, deleteSale, limitedSaleList, sale, updateSale } from "@/redux/sales/actions";
import { SaleFetchRequestPayload, UpdateSalePayload } from "@/redux/sales/types";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

const AMOUNT_PER_PAGE = 10 as const;

export const fetchMoreLimitedSaleListThunk = makeFetchMoreListThunkAction<SaleLimitedResponse, number | void>(
  limitedSaleList,
  {
    pageSelectFunction: ({ sales: { limitedSaleListPage, limitedSaleListTotalPage } }) => ({
      page: limitedSaleListPage,
      totalPage: limitedSaleListTotalPage,
    }),
    getResponseListFunction: ({ page }, forcePage) =>
      saleApi.getSales(void 0, (forcePage ?? page) * AMOUNT_PER_PAGE, AMOUNT_PER_PAGE),
  },
);

export const fetchSaleThunk = makeFetchThunkAction<SaleResponse, SaleFetchRequestPayload>(sale, {
  getResponseFunction: (_, { saleId }) => saleApi.getSale(saleId),
});

export const deleteSaleThunk = makeFetchThunkAction<void, number>(deleteSale, {
  getResponseFunction: (_, saleId) => saleApi.deleteSale(saleId),
  successSnackbarMessage: "매물 삭제가 정상적으로 완료되었습니다.",
});

export const createSaleThunk = makeFetchThunkAction<void, CreateSaleRequest>(createSale, {
  getResponseFunction: (_, sale) => saleApi.createSale(sale),
  successSnackbarMessage: "매물이 정상적으로 등록되었습니다.",
});

export const updateSaleThunk = makeFetchThunkAction<void, UpdateSalePayload>(updateSale, {
  getResponseFunction: (_, { id, updateSaleRequest }) => saleApi.updateSale(id, updateSaleRequest),
  successSnackbarMessage: "매물이 정상적으로 업데이트되었습니다.",
});
