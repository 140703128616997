import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import debounce from "lodash.debounce";

const delay = 250;

type Props = React.ComponentProps<typeof MuiTextField> & {
  safeNumber?: boolean;
};

type State = {
  value: React.ComponentProps<typeof MuiTextField>["value"];
};

export default class TextField extends React.Component<Props> {
  state: State = {
    value: this.props.value,
  };

  forwardChange?: Props["onChange"] & {
    cancel(): void;
    flush(): void;
  };

  constructor(props: Props) {
    super(props);
    this.setForwardChange();
  }

  componentDidUpdate(prevProps: Props) {
    this.setForwardChange();
  }

  componentWillUnmount() {
    if (this.forwardChange) {
      this.forwardChange.flush();
    }
  }

  setForwardChange() {
    if (this.props.onChange) {
      this.forwardChange = debounce(this.props.onChange, delay);
    }
  }

  handleChange: Props["onChange"] = (e) => {
    if (this.props.safeNumber && Number.isNaN(parseFloat(e.target.value))) {
      // Force value to be 0 instead of NaN.
      this.setState({ value: 0 });
      if (this.forwardChange) {
        e.target.value = "0";
        this.forwardChange(e);
      }
    } else if (this.props.safeNumber && e.target.value.startsWith("0") && !e.target.value.startsWith("0.")) {
      // Trim useless 0 in the head.
      const value = e.target.value.slice(1);
      this.setState({ value });
      if (this.forwardChange) {
        e.target.value = value;
        this.forwardChange(e);
      }
    } else {
      this.setState({ value: e.target.value });
      if (this.forwardChange) {
        this.forwardChange(e);
      }
    }
  };

  render() {
    const { safeNumber, ...props } = {
      ...this.props,
      value: this.state.value,
      onChange: this.handleChange,
      inputProps: {
        ...(this.props.multiline ? {} : { maxLength: 128 }),
        ...(this.props.inputProps || {}),
      },
    };

    return <MuiTextField {...props} />;
  }
}
