import React from "react";
import CorporationShowContainer from "@/containers/corporation/CorporationShowContainer";
import ReportShowContainer from "@/containers/reports/ReportShowContainer";
import useQuery from "@/hooks/useQuery";

export default function ReportPage() {
  const qeury = useQuery();
  const type = qeury.get("type");

  return <>{type && type === "corporation" ? <CorporationShowContainer /> : <ReportShowContainer />}</>;
}
