import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ReportBuildingInfo from "@/components/reports/ReportBuildingInfo";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportBuildingInfoContainerProps {}

const ReportBuildingInfoContainer: React.FC<ReportBuildingInfoContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  const isLand = report && report.registered_type === "LAND";

  const buildingLedger = useMemo(() => {
    if (!report || !report?.report || !report?.report?.building_ledger) {
      return;
    }

    const {
      report: { building_ledger },
    } = report;

    return building_ledger;
  }, [report, reportFetchState]);

  return (
    <ReportBuildingInfo buildingLedger={buildingLedger} reportFetchState={isLand ? "FAILURE" : reportFetchState} />
  );
};

export default ReportBuildingInfoContainer;
