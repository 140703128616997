import React from "react";
import commaNumber from "comma-number";
import styled from "styled-components";
import {
  DOWNLOAD_TICKET_PRICE,
  DOWNLOAD_TICKET_PRICE_WITH_VAT,
  DOWNLOAD_TICKET_VAT,
} from "@/interfaces/downloadTicketPrice";
import { PaymentMethod } from "@/interfaces/paymentMethod";
import { lengths } from "@/styles/values";
import { formatPaymentMethod } from "@/utils/paymentMethod";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-right: 48px;
  padding-bottom: 30px;
  padding-left: 48px;
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 640px;

  > div,
  > form {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

interface UserNewTansactionLayoutProps {
  children?: React.ReactNode;
}

export default function UserNewTansactionLayout({ children }: UserNewTansactionLayoutProps) {
  return (
    <Container>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
}

export const UserNewTransactionContentContainer = styled.form`
  padding-top: 28px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-left: 30px;

  background-color: #fff;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    padding-top: 24px;
    padding-right: 20px;
    padding-bottom: 16px;
    padding-left: 20px;

    > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const PriceLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Price = styled.div``;

const Label = styled.div`
  font-weight: 500;
`;

interface PriceLabelProps {
  amount: number;
}

export function PriceLabel({ amount }: PriceLabelProps) {
  return (
    <PriceLabelContainer>
      <Label>{amount}회 다운로드권</Label>
      <Price>{commaNumber(amount * DOWNLOAD_TICKET_PRICE_WITH_VAT)} 원</Price>
    </PriceLabelContainer>
  );
}

const PaymentMethodLabelContainer = styled.div``;

interface PaymentMethodLabelProps {
  selected?: boolean;
  method: PaymentMethod;
}

export function PaymentMethodLabel({ selected, method }: PaymentMethodLabelProps) {
  const methodText = formatPaymentMethod(method);

  return (
    <PaymentMethodLabelContainer>{selected ? <Label>{methodText}</Label> : methodText}</PaymentMethodLabelContainer>
  );
}

export const TotalPriceContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

interface TotalPriceProps {
  amount?: number;
}

export function TotalPrice({ amount }: TotalPriceProps) {
  if (typeof amount === "undefined") {
    return null;
  }

  return <TotalPriceContainer>{commaNumber(amount * DOWNLOAD_TICKET_PRICE_WITH_VAT)} 원</TotalPriceContainer>;
}

const SubPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;

  font-weight: 400;
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const SubPriceTitle = styled.div``;

const SubPricePriceContainer = styled.div``;

interface SubPriceProps {
  title: string;
  amount?: number;
  isTax?: boolean;
}

export function SubPrice({ title, amount, isTax }: SubPriceProps) {
  if (typeof amount === "undefined") {
    return null;
  }

  return (
    <SubPriceContainer>
      <SubPriceTitle>{title}</SubPriceTitle>
      <SubPricePriceContainer>
        {commaNumber(amount * (isTax ? DOWNLOAD_TICKET_VAT : DOWNLOAD_TICKET_PRICE))}
      </SubPricePriceContainer>
    </SubPriceContainer>
  );
}

export const TransferNameContainerDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;

  font-weight: 400;
  font-size: 13px;
`;

export const TransferNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
