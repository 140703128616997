import React from "react";
import { Warning as WarningIcon } from "@mui/icons-material";
import styled from "styled-components";
import StyledButton from "@/components/StyledButton";
import { colors, lengths } from "@/styles/values";
import { showMessenger } from "@/utils/channel";
import { getUrl } from "@/utils/routes";

const DocumentMessageBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100vh - ${lengths.headerHeight});
  min-height: calc((var(--vh, 1vh) * 100) - ${lengths.headerHeight});

  @media screen and (max-width: ${lengths.desktop}) {
    min-height: calc(100vh - ${lengths.mobileHeaderHeight});
    min-height: calc((var(--vh, 1vh) * 100) - ${lengths.mobileHeaderHeight});
  }
`;

const ContentBox = styled.div`
  position: relative;

  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  word-break: keep-all;
`;

const MessageBox = styled.div`
  padding: 24px 8px 24px 8px;

  color: ${colors.dark};
  font-weight: bold;
  font-size: 24px;
  line-height: 52px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 8px 40px 8px;
`;

type Props = {
  refresh?: boolean;
  channel?: boolean;
};

const DocumentMessage: React.FC<Props> = (props) => {
  const handleShowMessenger = () => {
    showMessenger();
  };

  return (
    <DocumentMessageBox>
      <ContentBox>
        <WarningIcon sx={{ fontSize: 60 }} />
        <MessageBox>{props.children}</MessageBox>
        <ButtonsBox>
          <StyledButton gray to={getUrl("documentsRoot")}>
            목록으로
          </StyledButton>
          {props.refresh && (
            <StyledButton gray onClick={() => window.location.reload()}>
              새로고침
            </StyledButton>
          )}
          {props.channel && (
            <StyledButton gray onClick={handleShowMessenger}>
              채널톡 문의
            </StyledButton>
          )}
        </ButtonsBox>
      </ContentBox>
    </DocumentMessageBox>
  );
};

export default DocumentMessage;
