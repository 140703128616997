import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SubscriptionType } from "@doczip/api-client";
import { IconButton } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { ko } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import FlatButton from "./buttons/FlatButton";
import InfiniteScrollList from "./InfiniteScrollList";
import { NoSearchResult, NoReportLogResult } from "./NoResult";
import ReportDocumentPopup from "./ReportList/ReportDocumentPopup";
import ReportLogDocumentsDownloadModal from "./ReportLogDocumentsDownloadModal";
import { ReactComponent as TrashIcon } from "@/assets/icons/trash.svg";
import Card from "@/components/Card";
import Dialog from "@/components/Dialog";
import Link, { HoverUnderlineLink } from "@/components/Link";
import SaleModalOpenButton from "@/components/sales/SaleModalOpenButton";
import StyledButton from "@/components/StyledButton";
import { FetchState } from "@/interfaces/fetch";
import {
  resetRequestReportPermissionState,
  setOwnersClubDialogOpen,
  setRequestReportPermissionId,
} from "@/redux/logs/actions";
import {
  getDeleteReportLogFetchState,
  getOwnersClubDialogOpen,
  getRequestReportPermissionFetchState,
  getRequestReportPermissionId,
  getRestoreReportLogFetchState,
} from "@/redux/logs/selectors";
import { deleteReportLogThunk, requestReportPermissionThunk, restoreReportLogThunk } from "@/redux/logs/thunk";
import { getUserMe } from "@/redux/user/selectors";
import { lengths } from "@/styles/values";
import { event } from "@/utils/analytics";
import { toKoreanDate } from "@/utils/datetime";
import { getUrl } from "@/utils/routes";

const ActionsContainer = styled.div`
  display: flex;

  > div,
  > button,
  > a {
    &:not(:last-child) {
      margin-right: 12px;
    }

    &.revisit-button {
      display: none;
    }

    @media screen and (max-width: ${lengths.desktop}) {
      display: none;

      &.recover-button,
      &.revisit-button,
      &.delete-button {
        display: flex;
      }

      &.revisit-button {
        width: 52px;
      }
    }
  }
`;

const HoverUnderlineAnchor = styled.a`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DistanceFromNowChip = styled.span`
  margin-right: 8px;
  padding-right: 4px;
  padding-bottom: 2px;
  padding-left: 4px;

  color: #718096;
  font-weight: 400;
  font-size: 12px;

  background-color: #f2f2f2;
  border-radius: 4px;
`;

interface ReportLogLike {
  registered_address: string;
  created_datetime: string;
  id: number;
  provider_id: number;
  is_pending?: boolean;
}

interface ReportLogProps {
  reportLog: ReportLogLike;
  isDeleted?: boolean;
  isIssued?: boolean;
}

export default function ReportLog({
  reportLog: { registered_address, created_datetime, id, provider_id, is_pending },
  isDeleted,
  isIssued,
}: ReportLogProps) {
  const dispatch = useDispatch();
  const deleteReportLogFetchState = useSelector(getDeleteReportLogFetchState);
  const restoreReportLogFetchState = useSelector(getRestoreReportLogFetchState);
  const requestReportPermissionFetchState = useSelector(getRequestReportPermissionFetchState);
  const requestReportPermissionId = useSelector(getRequestReportPermissionId);
  const userMe = useSelector(getUserMe);
  const [open, setOpen] = useState(false);
  const [documentPopupOpen, setDocumentPopupOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDocumentPopup = () => {
    setDocumentPopupOpen(!documentPopupOpen);
  };

  const handleDeleteReportLog = useCallback(() => {
    if (deleteReportLogFetchState === "FETCHING") {
      return;
    }

    dispatch(deleteReportLogThunk(id));
  }, [id, deleteReportLogFetchState]);

  const handleRestoreReportLog = useCallback(() => {
    if (restoreReportLogFetchState === "FETCHING") {
      return;
    }

    dispatch(restoreReportLogThunk(id));
  }, [id, restoreReportLogFetchState]);

  const handleDownloadModalOpen = () => {
    setOpen(true);
  };

  const handleDownloadModalClose = () => {
    setOpen(false);
  };

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    if (
      requestReportPermissionFetchState !== "SUCCESS" ||
      (requestReportPermissionId !== null && requestReportPermissionId !== id)
    ) {
      return;
    }

    dispatch(resetRequestReportPermissionState());

    navigate(getUrl("reportsDetail", { params: { reportId: id }, query: { type: "normal" } }));
  }, [requestReportPermissionFetchState, requestReportPermissionId, id]);

  const handlePreview = useCallback(() => {
    if (
      requestReportPermissionFetchState === "FETCHING" ||
      (requestReportPermissionId !== null && requestReportPermissionId !== id)
    ) {
      return;
    }
    if (userMe?.subscription?.subscription_type !== SubscriptionType.OwnersclubBasic && !userMe?.is_admin) {
      dispatch(setOwnersClubDialogOpen(true));
      return;
    }

    event("request_issued_report");

    dispatch(setRequestReportPermissionId(id));
    dispatch(requestReportPermissionThunk(id));
  }, [requestReportPermissionFetchState, requestReportPermissionId, id]);

  const actions = useMemo(() => {
    if (isIssued) {
      return (
        <ActionsContainer onClick={stopPropagation}>
          <StyledButton
            disabled={requestReportPermissionFetchState === "FETCHING"}
            bold
            outlined
            height="36px"
            fontSize="12px"
            onClick={handlePreview}
          >
            열람하기
          </StyledButton>
        </ActionsContainer>
      );
    }

    if (isDeleted) {
      return (
        <ActionsContainer onClick={stopPropagation}>
          <StyledButton bold outlined height="36px" fontSize="12px" onClick={handleRestoreReportLog}>
            복구
          </StyledButton>
        </ActionsContainer>
      );
    }

    return (
      <ActionsContainer onClick={stopPropagation}>
        <StyledButton bold outlined height="36px" fontSize="12px" onClick={handleDownloadModalOpen}>
          다운로드
        </StyledButton>
        <StyledButton bold outlined height="36px" fontSize="12px" onClick={toggleDocumentPopup}>
          문서 작성
        </StyledButton>
        <SaleModalOpenButton
          as={
            <StyledButton bold outlined height="36px" fontSize="12px">
              매물 공유
            </StyledButton>
          }
          saleValues={{ address: registered_address, id }}
        />
        <IconButton
          className="delete-button"
          onClick={handleDeleteReportLog}
          disabled={deleteReportLogFetchState === "FETCHING"}
          sx={{
            width: "36px",
            height: "36px",
          }}
        >
          <TrashIcon />
        </IconButton>
      </ActionsContainer>
    );
  }, [isDeleted, isIssued]);

  const subTitle = useMemo(() => {
    const dateTime = toKoreanDate(created_datetime);

    if (!isIssued) {
      return dateTime;
    }

    return (
      <>
        <DistanceFromNowChip>
          {formatDistanceToNowStrict(new Date(created_datetime), { addSuffix: true, locale: ko })} 열람건
        </DistanceFromNowChip>
        {dateTime}
      </>
    );
  }, [isIssued, created_datetime]);

  return (
    <>
      <ReportDocumentPopup
        open={documentPopupOpen}
        handleClose={toggleDocumentPopup}
        title="문서 작성"
        titleAlign="center"
        reportLogId={id}
      />
      <Card
        title={
          isIssued ? (
            <HoverUnderlineAnchor onClick={handlePreview}>{registered_address}</HoverUnderlineAnchor>
          ) : (
            <HoverUnderlineLink href={getUrl("reportsDetail", { params: { reportId: id }, query: { type: "normal" } })}>
              {registered_address}
            </HoverUnderlineLink>
          )
        }
        subTitle={subTitle}
        actions={actions}
        loading={is_pending}
      />
      <ReportLogDocumentsDownloadModal reportId={provider_id} open={open} handleClose={handleDownloadModalClose} />
    </>
  );
}

const ReportLogListContainer = styled.div`
  width: 100%;

  > a {
    display: block;
  }

  > div,
  > a {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
`;

const ShowAllButton = styled(FlatButton)`
  &.MuiButtonBase-root {
    margin-top: 30px;
  }
`;

const ReportLogListOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface ReportLogListProps {
  list: ReportLogLike[] | null;
  page: number | null;
  totalPage: number | null;
  fetchState: FetchState;
  showRecentOnly?: boolean;
  disableInitialize?: boolean;
  onLoadMore?: () => void;
  reportLogProps?: Omit<ReportLogProps, "reportLog">;
  hideShowAllButton?: boolean;
  noResult?: React.ReactNode;
  query?: string;
}

export function ReportLogList({
  reportLogProps,
  showRecentOnly,
  list,
  hideShowAllButton = true,
  query,
  noResult,
  ...props
}: ReportLogListProps) {
  const slicedList = useMemo(() => {
    if (!list || !list.length || !showRecentOnly) {
      return list;
    }

    return list.slice(0, 5);
  }, [list, showRecentOnly]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ownersClubDialogOpen = useSelector(getOwnersClubDialogOpen);

  const handleOwnersClubDialogClose = () => {
    dispatch(setOwnersClubDialogOpen(false));
  };
  const handleOwnersClubDialogConfirm = () => {
    navigate(getUrl("userRoot", { query: { subscribe_modal: "open" } }));
    dispatch(setOwnersClubDialogOpen(false));
  };

  return (
    <>
      {ownersClubDialogOpen && (
        <Dialog
          title="오너스클럽 회원으로 가입해주세요."
          content="오너스클럽 회원으로 가입하면 열람이 가능합니다. 가입 후 열람해주세요."
          closeText="닫기"
          onClose={handleOwnersClubDialogClose}
          confirmText="가입하기"
          onConfirm={handleOwnersClubDialogConfirm}
        />
      )}
      <ReportLogListOuterContainer>
        <ReportLogListContainer>
          {props.fetchState === "SUCCESS" &&
            !list?.length &&
            (noResult ?? (query ? <NoSearchResult /> : <NoReportLogResult />))}
          <InfiniteScrollList
            list={slicedList}
            renderItem={(log, key) => <ReportLog key={key} reportLog={log} {...reportLogProps} />}
            disableInitialize
            {...props}
          />
        </ReportLogListContainer>
        {!hideShowAllButton && (
          <Link href={getUrl("reportsRoot")}>
            <ShowAllButton>내역 모두 보기</ShowAllButton>
          </Link>
        )}
      </ReportLogListOuterContainer>
    </>
  );
}
