import React from "react";
import MeasureBox from "./MeasureBox";
import Page, { innerHeight } from "./Page";

type State = {
  childHeights: Record<string, number>;
};
type Props = {
  container?: boolean;
  flexible?: boolean;
};

class FlexiblePage extends React.Component<Props, State> {
  state: State = {
    childHeights: {},
  };

  constructor(props: Props) {
    super(props);
    this.initChildHeights();
  }

  componentDidUpdate(prevProps: React.PropsWithChildren<Props>) {
    const prevChildrenCount = (prevProps.children as React.ReactNode[]).length;
    const childrenCount = (this.props.children as React.ReactNode[]).length;

    if (prevChildrenCount !== childrenCount) {
      this.initChildHeights();
    }
  }

  initChildHeights = () => {
    const children = this.props.children as React.ReactNode[];
    this.setState({
      childHeights: children.reduce<State["childHeights"]>((acc, curr, i) => ({ ...acc, [`${i}`]: 0 }), {}),
    });
  };

  getChildHeight = (i: number) => {
    try {
      return this.state.childHeights[`${i}`];
    } catch (err) {
      return 0;
    }
  };

  setChildHeight = (i: number, height: number) => {
    this.setState((prevState) => ({
      childHeights: {
        ...prevState.childHeights,
        [`${i}`]: height,
      },
    }));
  };

  render() {
    const children = this.props.children as React.ReactNode[];
    const pages: React.ReactNode[] = [];
    let bufferBoxes: React.ReactNode[] = [];
    let bufferHeight = 0;

    // Dynamically build pages here.
    children.forEach((child, i) => {
      const height = this.getChildHeight(i);
      const isOversized = height > innerHeight;
      const isOverflow = bufferHeight + height > innerHeight;

      if (!(isOversized && bufferBoxes.length === 0) && isOverflow) {
        // If the buffer overflows with the child,
        // wrap current buffer contents as a new page.
        pages.push(<Page key={pages.length}>{bufferBoxes}</Page>);
        bufferBoxes = [];
        bufferHeight = 0;
      }

      // Stuff the child to the buffer, wrapped with MeasureBox to observe its rendered height.
      bufferBoxes.push(
        <MeasureBox key={i} onMeasure={(height) => this.setChildHeight(i, height)}>
          {child}
        </MeasureBox>,
      );
      bufferHeight += height;
    });
    if (bufferBoxes) {
      pages.push(<Page key={pages.length}>{bufferBoxes}</Page>);
    }

    return <>{pages}</>;
  }
}

export default FlexiblePage;
