import styled from "styled-components";
import TitledContainer from "../TitledContainer";

const UserEditLayout = styled.div`
  > div,
  > form {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export default UserEditLayout;

export const UserEditFormLayout = styled.form`
  > button {
    margin-top: 40px;
  }
`;

export const UserEditTitledContainer = styled(TitledContainer)`
  > div {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
