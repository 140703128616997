import React, { useMemo } from "react";
import { ReportLogResponse } from "@doczip/api-client";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as yup from "yup";
import LoadingButton from "@/components/buttons/LoadingButton";
import TextField from "@/components/TextField";
import { FetchState } from "@/interfaces/fetch";
import { memoFetchThunk } from "@/redux/report/thunk";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    textarea {
      padding: 0 !important;
    }
  }
`;

const Error = styled.span`
  margin-top: 5px;

  color: #d32f2f;
  font-size: 12px;
`;

const SubmitButton = styled(LoadingButton)`
  &.MuiLoadingButton-root {
    width: 100%;
    height: 39px;
    margin-top: 10px;

    color: #ff8f17;
    font-weight: 700;
    font-size: 13px;

    background-color: #fff;
    border: 1px solid #ff8f17;

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
  }
`;

interface ReportMemoFormInField {
  memo: string;
}

const ReportMemoFormValidationSchema = yup.object<ReportMemoFormInField>({
  memo: yup.string().max(500, "500자 이하로 입력해주세요.").required("메모를 입력해주세요."),
});

interface ReportMemoEditFormProps {
  report: ReportLogResponse | null;
  reportFetchState: FetchState;
}

const ReportMemoEditForm: React.FC<ReportMemoEditFormProps> = ({ report, reportFetchState }) => {
  const dispatch = useDispatch();

  const currentMemo = useMemo(() => {
    if (!report || !report?.memo) {
      return;
    }

    return report.memo;
  }, [report, reportFetchState]);

  const id = useMemo(() => {
    if (!report || !report?.id) {
      return;
    }

    return report.id;
  }, [report, reportFetchState]);

  // NOTE: "dirty" means initial value and current value is incorrect
  const { values, handleChange, submitForm, errors, dirty } = useFormik<ReportMemoFormInField>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      memo: currentMemo ?? "",
    },
    validationSchema: ReportMemoFormValidationSchema,
    onSubmit: ({ memo }) => {
      if (id && memo) {
        dispatch(memoFetchThunk({ id, memo }));
      }
    },
  });

  return (
    <Container>
      <StyledTextField
        name="memo"
        placeholder="메모를 입력해주세요."
        value={values.memo}
        onChange={handleChange}
        error={!!errors.memo}
        fullWidth
        multiline
      />
      {!!errors.memo && <Error>{errors.memo}</Error>}
      {dirty && <SubmitButton onClick={submitForm}>저장</SubmitButton>}
    </Container>
  );
};

export default ReportMemoEditForm;
