import React from "react";
import { AgentResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";

type Props = {
  value: AgentResponse;
  bmargin?: number;
};

const ContractAgentView: React.FC<Props> = ({ value, bmargin }) => {
  return (
    <Table bmargin={bmargin} columnSizes={[110, 110, 150, 110, 150, 110, 80]}>
      <Table row height={35}>
        <Box td rowSpan={3} font="label" align="center" backgroundColor="gray">
          개업
          <br />
          공인중개사
        </Box>
        <Box td align="center" backgroundColor="gray">
          사무소 소재지
        </Box>
        <Box td colSpan={6} hpadding="small" align="left">
          {value.address_expr}
        </Box>
      </Table>
      <Table row height={35}>
        <Box td align="center" backgroundColor="gray">
          사무소 명칭
        </Box>
        <Box td colSpan={3} hpadding="small" align="left">
          {value.agency_name}
        </Box>
        <Box td align="center" backgroundColor="gray">
          대표자
        </Box>
        <Box td align="center" font="small">
          서명및날인
        </Box>
        <Box td font="label" align="left" hpadding="small">
          <Space minWidth="6em" align="center" color="lighterGray">
            {value.agency_head_full_name}
          </Space>
          (인)
        </Box>
      </Table>
      <Table row height={35}>
        <Box td align="center" backgroundColor="gray">
          전화번호
        </Box>
        <Box td hpadding="small" align="left">
          {value.phone_number}
        </Box>
        <Box td align="center" backgroundColor="gray">
          등록번호
        </Box>
        <Box td hpadding="small" align="left">
          {value.registration_number}
        </Box>
        <Box td align="center" backgroundColor="gray">
          소속공인중개사
        </Box>
        <Box td align="center" font="small">
          서명및날인
        </Box>
        <Box td font="label" align="left" hpadding="small">
          <Space minWidth="6em" align="center" color="lighterGray">
            {value.full_name}
          </Space>
          (인)
        </Box>
      </Table>
    </Table>
  );
};

export default ContractAgentView;
