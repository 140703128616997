import React, { useMemo } from "react";
import { PostLimitedResponse } from "@doczip/api-client";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MobileLandingVisual from "./MobileLandingVisual";
import Carousel from "@/components/Carousel";
import { CarouselCardProp } from "@/components/CarouselCard";
import LoadingCircle from "@/components/LoadingCircle";
import OwnersClubModal from "@/components/OwnersClubModal";
import TitledWrapper from "@/components/TitledWrapper";
import AddressListWithSearchContainer from "@/containers/AddressListWithSearchContainer";
import { FetchState } from "@/interfaces/fetch";
import { lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const MobileLandingContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${lengths.headerHeight});
  min-height: 600px;
  padding: 24px 24px 32px;

  background-color: #fff;
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const VisualContainer = styled.div`
  margin-top: 20px;
`;

const SearchFormContainer = styled.div`
  margin-top: 24px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
`;

const TextContainerDivider = styled.div`
  width: 1px;
  height: 14px;
  margin: 0 15px;

  background-color: #d9d9d9;
`;

const Text = styled.span`
  color: #797979;
  font-weight: 400;
  font-size: 13px;
`;

interface MobileLandingProps {
  bannerCollapse?: boolean;
  posts: PostLimitedResponse[] | null;
  postsFetchState: FetchState;
}

const MobileLanding: React.FC<MobileLandingProps> = ({ bannerCollapse, posts, postsFetchState }) => {
  const navigate = useNavigate();
  const onTextClick = (path: string) => navigate(path);

  const slideList = useMemo(() => {
    if (!posts) {
      return null;
    }

    const list: CarouselCardProp[] = posts.map(({ id, title }) => ({
      descriptions: [title],
      href: getUrl("postsDetail", { params: { postId: id } }),
    }));

    return list;
  }, [posts, postsFetchState]);

  return (
    <MobileLandingContainer>
      <OwnersClubModal />
      <CarouselContainer>{slideList ? <Carousel slideList={slideList} /> : <LoadingCircle />}</CarouselContainer>

      <VisualContainer>
        <MobileLandingVisual bannerCollapse={bannerCollapse} />
      </VisualContainer>

      <SearchFormContainer>
        <TitledWrapper title="주소" titleStyle={{ size: 15, weight: 700 }}>
          <AddressListWithSearchContainer multiSelect />
        </TitledWrapper>
      </SearchFormContainer>

      <TextContainer>
        <Text onClick={() => onTextClick(getUrl("documentsNewContract"))}>부동산 문서 작성</Text>
        <TextContainerDivider />
        <Text onClick={() => onTextClick(getUrl("userVerifyRealtor"))}>공인중개사 인증</Text>
      </TextContainer>
    </MobileLandingContainer>
  );
};

export default MobileLanding;
