import React from "react";
import styled from "styled-components";
import LoadingCircle from "./LoadingCircle";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  margin: 0 !important;
  padding: 0 !important;

  background-color: #00000060;
`;

export default Backdrop;

export function BackdropWithLoadingCircle() {
  return (
    <Backdrop>
      <LoadingCircle position="absolute" />
    </Backdrop>
  );
}
