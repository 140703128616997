import React from "react";
import { RegisterAddressAddress } from "@doczip/api-client";
import { TextField } from "@mui/material";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import StyledButton from "@/components/StyledButton";
import { colors } from "@/styles/values";

const DocumentAddressInputBox = styled.div`
  padding: 0;
`;

const InputRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const InputBox = styled.div`
  flex: 1 1 auto;
  max-width: calc(100% - 119px - 17px);

  & .MuiInputBase-root {
    height: 48px;

    border-radius: 0;
    outline: none;
  }

  & .MuiInputBase-input {
    height: 48px;

    border-radius: 0;
  }
`;

const SearchButtonBox = styled.div`
  flex: 0 0 auto;
  width: 119px;
`;

const AddressesBox = styled.div`
  margin-top: 16px;

  border: 1px solid #e3e3e3;
`;

const AddressRow = styled.a`
  display: block;
  padding: 12px 18px;

  color: ${colors.black};
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.5px;

  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;

  &:hover {
    background-color: #e3e3e3;
  }

  &.active {
    font-weight: bold;

    background-color: #e3e3e3;
  }
`;

type Props = {
  busy?: boolean;
  error?: boolean;
  addresses: RegisterAddressAddress[];
  onSearch: () => void;
  inputValue: string;
  onInputChange: (inputValue: string) => void;
  value?: RegisterAddressAddress;
  onChange: (address: RegisterAddressAddress) => void;
};

const DocumentAddressInput: React.FC<Props> = (props) => {
  const addressesContent = (props.busy || props.addresses.length > 0) && (
    <AddressesBox>
      {props.busy && <LoadingCircle />}
      {props.addresses.map((address) => {
        return (
          <AddressRow
            className={props.value?.pin_number === address.pin_number ? "active" : ""}
            key={address.pin_number}
            onClick={() => props.onChange(address)}
          >
            {address.register_type}
            <br />
            {address.address}
          </AddressRow>
        );
      })}
    </AddressesBox>
  );

  return (
    <DocumentAddressInputBox>
      <InputRow>
        <InputBox>
          <TextField
            value={props.inputValue}
            onChange={(e) => props.onInputChange(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && props.onSearch()}
            placeholder="도로명 주소, 건물명 또는 지번 입력"
            fullWidth
          />
        </InputBox>
        <SearchButtonBox>
          <StyledButton onClick={() => props.onSearch()} fullWidth bold height="48px">
            검색하기
          </StyledButton>
        </SearchButtonBox>
      </InputRow>
      {addressesContent}
    </DocumentAddressInputBox>
  );
};

export default DocumentAddressInput;
