import React, { useCallback, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router";
import styled from "styled-components";
import * as yup from "yup";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CenteredLayout from "@/components/CenteredLayout";
import Layout from "@/components/Layout";
import SignUpTerms from "@/components/signIn/SignUpTerms";
import TextField, { TextFieldWithInnerButton } from "@/components/TextField";
import IamportLoader from "@/containers/IamportLoader";
import { resetAuthState } from "@/redux/auth/actions";
import {
  getLoginFetchState,
  getSelfCertification,
  getSelfCertificationFetchState,
  getUserExists,
  getUserExistsFetchState,
} from "@/redux/auth/selectors";
import { fetchUserExistsThunk, selfCertificationThunk, signUpThunk } from "@/redux/auth/thunk";
import { openTextSnackbar } from "@/redux/snackbar/actions";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";
import { nameSchema, passwordSchema, phoneNumberSchema } from "@/utils/validator";

const FormContainer = styled.form`
  width: 100%;
`;

const NoticeText = styled.span`
  color: #ff4d4d;
  font-weight: 600;
`;

interface UserExistsCheckFormField {
  phoneNumber: string;
}

const userExistsCheckFormSchema = yup.object<UserExistsCheckFormField>({
  phoneNumber: phoneNumberSchema,
});

interface SignUpFormField extends UserExistsCheckFormField {
  password: string;
  impUid: string;
  name?: string;
  termsChecked: boolean;
}

const signUpFormSchema = yup.object<SignUpFormField>({
  phoneNumber: phoneNumberSchema,
  password: passwordSchema,
  impUid: yup.string().required("본인인증을 해 주세요"),
  name: nameSchema.notRequired(),
  termsChecked: yup.boolean().required(),
});

interface SignUpProps {}

function SignUp(props: SignUpProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userExists = useSelector(getUserExists);
  const userExistsFetchState = useSelector(getUserExistsFetchState);
  const loginFetchState = useSelector(getLoginFetchState);
  const selfCertification = useSelector(getSelfCertification);
  const selfCertificationFetchState = useSelector(getSelfCertificationFetchState);

  useEffect(() => {
    dispatch(resetAuthState());
  }, []);

  useEffect(() => {
    if (loginFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("root"));
  }, [loginFetchState]);

  const checkUserExists = useCallback(
    (phoneNumber: string) => {
      if (userExistsFetchState === "FETCHING") {
        return;
      }

      dispatch(fetchUserExistsThunk(phoneNumber));
    },
    [userExistsFetchState],
  );

  const validationSchema = useMemo(() => {
    if (userExists === null) {
      return userExistsCheckFormSchema;
    }

    return signUpFormSchema;
  }, [userExists]);

  const { handleSubmit, getFieldProps, errors, values, validateField, setFieldValue } = useFormik<SignUpFormField>({
    validationSchema,
    initialValues: {
      phoneNumber: "",
      password: "",
      impUid: "",
      termsChecked: false,
    },
    onSubmit: (formField) => {
      if (userExists === null) {
        checkUserExists(formField.phoneNumber);
        return;
      }

      if (userExists.exists) {
        dispatch(openTextSnackbar("이미 가입된 계정입니다."));
        return;
      }

      dispatch(signUpThunk(formField));
    },
  });

  useEffect(() => {
    setFieldValue("impUid", selfCertification ?? "");
  }, [selfCertification]);

  const handleSelfCertification = useCallback(() => {
    if (selfCertificationFetchState === "FETCHING") {
      return;
    }

    if (values.phoneNumber.length === 0) {
      validateField("phoneNumber");
      return;
    }

    dispatch(selfCertificationThunk(values.phoneNumber));
  }, [selfCertificationFetchState, values]);

  const handleTermsCheck = (newValue: boolean) => setFieldValue("termsChecked", newValue);

  return (
    <CenteredLayout
      title="회원가입"
      description={[
        "닥집에 오신 것을 환영합니다.",
        "권리관계 확인으로 다양한 부동산 정보를 확인해보세요!",
        <NoticeText>현재 닥집 무료 등기부등본 열람권 이벤트는 종료되었습니다.</NoticeText>,
      ]}
    >
      <FormContainer onSubmit={handleSubmit}>
        <TextFieldWithInnerButton
          fullWidth
          placeholder="휴대폰 번호"
          {...getFieldProps("phoneNumber")}
          helperText={errors.impUid ?? errors.phoneNumber}
          error={typeof (errors.impUid ?? errors.phoneNumber) !== "undefined"}
          innerButtonChildren="본인인증"
          onInnerButtonClick={handleSelfCertification}
        />
        <TextField
          fullWidth
          sx={{ mb: "16px" }}
          placeholder="비밀번호"
          {...getFieldProps("password")}
          helperText={errors.password}
          error={typeof errors.password !== "undefined"}
          type="password"
        />
        <TextField
          fullWidth
          sx={{ mb: "20px" }}
          placeholder="성함 (선택)"
          {...getFieldProps("name")}
          helperText={errors.name}
          error={typeof errors.name !== "undefined"}
        />
        <SignUpTerms checked={values.termsChecked} onChecked={handleTermsCheck} />
        <PrimaryButton fullWidth type="submit" sx={{ mt: "44px" }} disabled={!values.termsChecked}>
          회원가입
        </PrimaryButton>
      </FormContainer>
    </CenteredLayout>
  );
}

interface SignUpWithIamportLoaderProps {}

export default function SignUpWithIamportLoader(props: SignUpWithIamportLoaderProps) {
  const userMe = useSelector(getUserMe);

  if (userMe !== null) {
    return <Navigate to={getUrl("root")} />;
  }

  return (
    <IamportLoader>
      <Layout columnless />
      <SignUp />
    </IamportLoader>
  );
}
