const ie = Boolean(/MSIE \d|Trident.*rv:/.test(navigator.userAgent));
const mobile = Boolean(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

export function isInternetExplorer(): boolean {
  return ie;
}

export function isMobile(): boolean {
  return mobile;
}

export function isMobileWidth(): boolean {
  return true;
}
