import { SALES_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getLimitedSaleList,
  getLimitedSaleListFetchState,
  getLimitedSaleListPage,
  getLimitedSaleListTotalCount,
  getLimitedSaleListTotalPage,

  getSale,
  getSaleFetchState,

  getDeleteSaleFetchState,
  getCreateSaleFetchState,
  getUpdateSaleFetchState,
} = makeSubStateSelectorMap("sales", SALES_STATE_KEY_LIST);
