import React from "react";
import SaleLayout from "@/components/sales/common/SaleLayout";
import SaleListContainer from "@/containers/sales/SaleListContainer";
import SaleTitledContainer from "@/containers/sales/SaleTitledContainer";

const SaleListPage = () => {
  return (
    <SaleLayout>
      <SaleTitledContainer />
      <SaleListContainer />
    </SaleLayout>
  );
};

export default SaleListPage;
