import React, { useMemo } from "react";
import { RegisterLedgerOwnershipSummary, ReportRegisterLedger } from "@blitz-core/api-client";
import { ButtonBase } from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import AddressCard from "@/components/reports/ReportOwnerAddressSearchModal/AddressCard";
import { setCheckedOwner } from "@/redux/report/actions";

const List = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
`;

const Item = styled.li`
  padding: 12px 0;

  &:not(:first-of-type) {
    border-top: 1px solid #dfe2e7;
  }
`;

const ButtonContainer = styled(ButtonBase)``;

interface AddressListProps {
  registerLedger?: ReportRegisterLedger;
}

const AddressList: React.FC<AddressListProps> = ({ registerLedger }) => {
  const dispatch = useDispatch();

  const ownershipSummaries = useMemo(() => {
    if (!registerLedger || !registerLedger?.ownership_summaries) {
      return;
    }

    return registerLedger.ownership_summaries;
  }, [registerLedger]);

  const handleClick = (summary: RegisterLedgerOwnershipSummary) => {
    dispatch(setCheckedOwner(summary));
  };

  return (
    <List>
      {ownershipSummaries?.length ? (
        ownershipSummaries.map((ownerSummary, index) => (
          <Item key={index}>
            <ButtonContainer onClick={() => handleClick(ownerSummary)}>
              <AddressCard ownerSummary={ownerSummary} />
            </ButtonContainer>
          </Item>
        ))
      ) : (
        <LoadingCircle />
      )}
    </List>
  );
};

export default AddressList;
