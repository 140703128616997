import { PostLimitedResponse, PostResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { PostsState } from "./types";
import { makeAsyncStateResetReducer, makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getAsyncListInitialState, getKeyListFromState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const makeAsyncReducer = makeTypedAsyncReducer<PostsState>();

const initialState: PostsState = {
  ...getAsyncListInitialState<PostsState, "limitedPostList">("limitedPostList"),
  ...getAsyncListInitialState<PostsState, "post">("post"),
  deletePostFetchState: "READY",
  createPostFetchState: "READY",
  updatePostFetchState: "READY",
};

export const POSTS_STATE_KEY_LIST = getKeyListFromState(initialState);

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"limitedPostList", void, ListFetchSuccessPayload<PostLimitedResponse>>("limitedPostList", {
      useListSuccessReducer: true,
      successReducer: (_state, { payload: { list, nextPage, totalPage, totalCount } }) => {
        _state.limitedPostList = [...(_state.limitedPostList ?? []), ...list];
        _state.limitedPostListFetchState = "SUCCESS";
        _state.limitedPostListPage = nextPage;
        _state.limitedPostListTotalPage = totalPage;
        _state.limitedPostListTotalCount = totalCount;
      },
    }),
    resetLimitedPostListState: makeAsyncStateResetReducer(initialState, ["limitedPostList"], { isList: true }),
    ...makeAsyncReducer<"post", void, PostResponse>("post"),
    resetPostState: makeAsyncStateResetReducer(initialState, "post"),
    ...makeAsyncReducer("deletePost"),
    ...makeAsyncReducer("createPost"),
    ...makeAsyncReducer("updatePost"),
  },
});

export const PostsReducer = postsSlice.reducer;
