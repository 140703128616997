import React, { useEffect, useState } from "react";
import { RegisteredType } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Autocomplete, TextField } from "@/components/Mui";
import { ReportInfo } from "@/components/sales/common/SaleType";
import { getReportLogListState } from "@/redux/logs/selectors";
import { fetchMoreReportLogListThunk } from "@/redux/logs/thunk";
import { colors } from "@/styles/values";

const Paper = styled.div`
  background-color: ${colors.white};
  border-right: 1px solid ${colors.lighterGray};
  border-bottom: 1px solid ${colors.lighterGray};
  border-left: 1px solid ${colors.lighterGray};
`;

const Item = styled.li`
  padding: 3px 9px;

  font-size: 13px;

  cursor: pointer;
`;

const HelperText = styled.p`
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;

  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
`;

type ReportSelectProps = {
  defaultValue?: ReportInfo;
  value?: ReportInfo;
  handleChange?: (reportInfo: ReportInfo | null) => void;
  error?: boolean;
  helperText?: string;
};

const CustomPaper = (props: any) => {
  return <Paper elevation={8} {...props} />;
};

const ReportSelect = ({ handleChange, value, defaultValue, error, helperText }: ReportSelectProps) => {
  const dispatch = useDispatch();
  const { value: reportList } = useSelector(getReportLogListState);
  const [list, setList] = useState<ReportInfo[]>([]);

  const handleReportChange = (value: any) => {
    if (handleChange && value) {
      handleChange(value as ReportInfo);
    }
  };

  useEffect(() => {
    dispatch(fetchMoreReportLogListThunk());
  }, []);

  useEffect(() => {
    if (reportList) {
      const results = reportList.filter((item) => item.registered_type !== RegisteredType.Land);
      const newList: ReportInfo[] = [];
      results.forEach((report) => newList.push({ address: report.registered_address, id: report.id }));
      setList(newList);
    }
  }, [reportList]);

  return (
    <div>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        onChange={(e, v) => handleReportChange(v)}
        disabled={defaultValue !== void 0}
        options={list ?? []}
        PaperComponent={CustomPaper}
        defaultValue={defaultValue}
        value={value}
        getOptionLabel={(report: any) => report.address}
        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
        noOptionsText="아직 열람하신 주소가 없습니다. 공부 열람 서비스를 먼저 이용해주세요."
        renderOption={(props, report: any) => {
          const { address, id } = report as ReportInfo;
          return (
            <Item {...props} key={id}>
              {address}
            </Item>
          );
        }}
        renderInput={(params) => <TextField {...params} placeholder="최근 열람 내역에서 주소 선택" />}
      />
      {error && helperText && <HelperText>{helperText}</HelperText>}
    </div>
  );
};

export default ReportSelect;
