import styled from "styled-components";
import { lengths } from "@/styles/values";

const UserCommonLayout = styled.div`
  padding-top: 30px;
  padding-right: 48px;
  padding-bottom: 30px;
  padding-left: 48px;

  @media screen and (max-width: ${lengths.desktop}) {
    padding-top: 12px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
  }
`;

export default UserCommonLayout;
