import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { colors } from "@/styles/values";

export const StyledReactQuill = styled(ReactQuill)`
  & .ql-container {
    border: 0;
  }

  & .ql-editor {
    height: 217px;
    padding: 16.5px 20px;
    overflow: auto;

    font-weight: 400;
    font-size: 14px;

    background-color: white;

    * {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    border: 1px solid #ccc;

    &:hover {
      border-color: ${colors.main};
    }

    &:focus {
      padding: 15.5px 19px;

      border: 2px solid ${colors.main};
    }

    &.ql-blank::before {
      padding-left: 6px;

      color: #a9a9a9;
      font-size: 14px;
      font-style: normal;
    }
  }

  & .ql-toolbar {
    display: none;
  }
`;
