import { ActionCreatorWithPayload, AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { openErrorSnackbar } from "./snackbar/actions";
import { RootState } from "./types";

export function handleAxiosError(
  e: unknown,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  failureAction: ActionCreatorWithPayload<Error, string>,
  { useErrorSnackbar, errorSnackbarMessage }: { useErrorSnackbar?: boolean; errorSnackbarMessage?: string } = {},
) {
  if (axios.isAxiosError(e) && e.response && useErrorSnackbar) {
    dispatch(openErrorSnackbar(errorSnackbarMessage ?? e.response.data.detail));
  }

  if (e instanceof Error) {
    dispatch(failureAction(e));
  } else {
    throw e;
  }
}
