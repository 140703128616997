import { REPORT_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getCreateReport,
  getCreateReportFetchState,

  getCreateBulkReport,
  getCreateBulkReportFetchState,

  getReport,
  getReportFetchState,

  getRegisterLedger,
  getRegisterLedgerFetchState,

  getBuildingLedger,
  getBuildingLedgerFetchState,

  getLandLedger,
  getLandLedgerFetchState,

  getLandUsingInfo,
  getLandUsingInfoFetchState,

  getKabPrice,
  getKabPriceFetchState,

  getKbPrice,
  getKbPriceFetchState,

  getMolitContract,
  getMolitContractFetchState,

  getMolitApartmentKindListings,
  getMolitApartmentKindListingsFetchState,

  getInfocare,
  getInfocareFetchState,

  getNsdi,
  getNsdiFetchState,

  getTankerPrice,
  getTankerPriceFetchState,

  getDeclaredPrice,
  getDeclaredPriceFetchState,

  getRandomReport,
  getRandomReportFetchState,

  getReportReRequest,
  getReportReRequestFetchState,

  getCheckedOwner,

  getMemoFetchState,
} = makeSubStateSelectorMap("report", REPORT_STATE_KEY_LIST);
