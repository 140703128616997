import React from "react";
import { CreateSaleRequest, SaleResponse } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ReportInfo } from "@/components/sales/common/SaleType";
import SaleNew from "@/components/sales/SaleNew";
import { createSaleThunk, updateSaleThunk } from "@/redux/sales/thunk";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";

type SaleNewModalModalProps = {
  open: boolean;
  handleClose: () => void;
  saleValues?: SaleResponse | ReportInfo;
};

const SaleNewModal = ({ open, handleClose, saleValues }: SaleNewModalModalProps) => {
  const user = useSelector(getUserMe);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveSale = async (saleRequest: CreateSaleRequest) => {
    const createSale = () => {
      navigate(getUrl("salesRoot"));
      dispatch(createSaleThunk(saleRequest));
    };

    if (saleValues) {
      if ("address" in saleValues) {
        createSale();
      } else {
        dispatch(updateSaleThunk({ id: saleValues.id, updateSaleRequest: saleRequest }));
      }
    } else {
      createSale();
    }
  };

  return <SaleNew user={user} open={open} handleClose={handleClose} saveSale={saveSale} saleValues={saleValues} />;
};

export default SaleNewModal;
