import { CORPORATION_STATE_KEY_LIST } from "@/redux/corporation/slice";
import {
  makeSubStateSelectorMap,
  makeAsyncListStateSelectorMap,
  makeAsyncStateSelectorMap,
} from "@/utils/redux/selector";

export const {
  getCorporationReportLogFetchState,
  getCorporationReportLogListFetchState,
  getCreateCorporationReportLog,
  getCreateCorporationReportLogFetchState,
} = makeSubStateSelectorMap("corporation", CORPORATION_STATE_KEY_LIST);

export const { getCorporationReportLogState } = makeAsyncStateSelectorMap("corporation", ["corporationReportLog"]);

export const { getCorporationReportLogListState } = makeAsyncListStateSelectorMap("corporation", [
  "corporationReportLogList",
]);
