import { RegisterLedgerOwnershipExtra } from "@blitz-core/api-client";

interface LoanData {
  order: number;
  subOrder: number;
  amount: number;
  type: "TNANT" | "MORTAGE";
}

export function getLoanTotal(registerLedgerSecondaryList: RegisterLedgerOwnershipExtra[]) {
  return registerLedgerSecondaryList
    .filter(
      ({ order_number, register_info, purpose }) =>
        typeof order_number !== "undefined" && typeof register_info !== "undefined" && typeof purpose !== "undefined",
    )
    .reduce<LoanData[]>((prev, { register_info, order_number, purpose }) => {
      const amountMatch = register_info!.match(/금(\d|\s|,)+원/);
      const amount = amountMatch ? parseInt(amountMatch[0].replace(/\D/g, ""), 10) : null;

      if (amount === null || Number.isNaN(amount)) {
        return prev;
      }

      const [rawOrder, rawSubOrder] = order_number!.split("-");

      const order = parseInt(rawOrder, 10);
      const subOrder = typeof rawSubOrder !== "undefined" ? parseInt(rawSubOrder, 10) : -1;
      const loanType = purpose!.replace(/\s/g, "").includes("전세") ? "TNANT" : "MORTAGE";
      const oldIndex = prev.findIndex((secondary) => secondary.order === order);

      const newData: LoanData = {
        order,
        subOrder,
        amount,
        type: loanType,
      };

      if (oldIndex === -1) {
        return [...prev, newData];
      }

      if (oldIndex !== -1 && prev[oldIndex].subOrder > subOrder) {
        return prev;
      }

      prev[oldIndex] = newData;

      return prev;
    }, [])
    .reduce(
      (prev, { amount, type }) =>
        type === "TNANT"
          ? { ...prev, tnantCount: prev.tnantCount + 1, tnantTotal: prev.tnantTotal + amount }
          : { ...prev, mortageCount: prev.mortageCount + 1, mortageTotal: prev.mortageTotal + amount },
      {
        tnantCount: 0,
        tnantTotal: 0,
        mortageCount: 0,
        mortageTotal: 0,
      },
    );
}
