import React from "react";
import styled, { css } from "styled-components";
import useWindowSize from "@/hooks/useWindowSize";
import { lengths, lengthsInNumbers } from "@/styles/values";

const MobileOnlyContainer = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  @media screen and (min-width: ${lengths.desktop}) {
    display: none;
  }
`;

interface MobileOnlyProps {
  children: React.ReactNode;
  useContainer?: boolean;
  fullWidth?: boolean;
  className?: string;
}

export default function MobileOnly({ children, useContainer, ...props }: MobileOnlyProps) {
  const windowSize = useWindowSize();

  if (useContainer) {
    return <MobileOnlyContainer {...props}>{children}</MobileOnlyContainer>;
  }

  if (windowSize.width >= lengthsInNumbers.desktop) {
    return null;
  }

  return <>{children}</>;
}
