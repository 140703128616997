import styled, { css } from "styled-components";
import { lengths } from "@/styles/values";

const FlexContainer = styled.div<{ colNumber?: number; gap?: number; verticalGap?: number; transationWidth?: string }>`
  display: flex;
  flex-direction: row;

  ${({ transationWidth = lengths.desktop, colNumber = 3, gap = 20, verticalGap = 10 }) => css`
    > div {
      width: calc(${100 / colNumber}% - ${colNumber > 1 ? (gap * (colNumber - 1)) / colNumber : 0}px);

      &:not(:last-child) {
        margin-right: ${gap}px;
      }
    }

    @media screen and (max-width: ${transationWidth}) {
      flex-direction: column;
      width: 100%;

      > div {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: ${verticalGap}px;
        }
      }
    }
  `}
`;

export default FlexContainer;
