import { PaymentMethod } from "@/interfaces/paymentMethod";

export function getPaymentMethodValue(method: PaymentMethod) {
  switch (method) {
    case "CardWithoutAuth":
    case "Card": {
      return "card";
    }
    case "Transfer": {
      return "trans";
    }
    case "VirtualAccountTransfer": {
      return "vbank";
    }
  }
}

export function formatPaymentMethod(method: PaymentMethod) {
  switch (method) {
    case "CardWithoutAuth":
    case "Card": {
      return "카드결제";
    }
    case "Transfer": {
      return "실시간 계좌이체";
    }
    case "VirtualAccountTransfer": {
      return "가상계좌 결제";
    }
  }
}
