import { LOGS_STATE_KEY_LIST } from "./slice";
import {
  makeSubStateSelectorMap,
  makeAsyncListStateSelectorMap,
  makeAsyncStateSelectorMap,
} from "@/utils/redux/selector";

export const {
  getDeleteReportLogFetchState,
  getRestoreReportLogFetchState,

  getRefundFetchState,

  getReportId,

  getRequestReportPermissionFetchState,
  getRequestReportPermissionId,

  getSearchType,
  getExtraSearchType,

  getOwnersClubDialogOpen,

  getSearchCategory,
} = makeSubStateSelectorMap("logs", LOGS_STATE_KEY_LIST);

export const {
  getBuildingLedgerState,
  getLandUsingInfoState,
  getRefundableAmountState,
  getRegisterLedgerState,
  getReportLogCountState,
  getIssuedReportLogCountState,
} = makeAsyncStateSelectorMap("logs", [
  "refundableAmount",
  "buildingLedger",
  "landUsingInfo",
  "registerLedger",
  "reportLogCount",
  "issuedReportLogCount",
]);

export const {
  getReportLogListState,
  getReportLogTrashListState,
  getIssuedReportLogListState,
  getTransactionListState,
} = makeAsyncListStateSelectorMap("logs", [
  "reportLogList",
  "reportLogTrashList",
  "issuedReportLogList",
  "transactionList",
]);
