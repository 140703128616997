import styled from "styled-components";
import { colors, lengths } from "@/styles/values";

export const SearchQueryResultContainer = styled.span`
  margin-left: 4px;

  color: ${colors.main};
  font-weight: 400;
  font-size: 13px;
`;

export const AddressSearchContainer = styled.form`
  display: flex;
  margin-bottom: 30px;

  .MuiInputBase-root {
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;

    background-color: #fff;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    margin-bottom: 0;
  }

  > div,
  > a,
  > button {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const SearchTypeSelectContainer = styled.div`
  margin-bottom: 16px;
`;

export const SearchCategorySelectContainer = styled.div``;
