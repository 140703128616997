import React, { useCallback, useEffect, useMemo, useState } from "react";
import copy from "clipboard-copy";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LoadingButton from "@/components/buttons/LoadingButton";
import DocumentDownloadList from "@/components/DocumentDownloadList";
import Map from "@/components/Map";
import NotFound from "@/components/NotFound";
import ReportLoadingDialog from "@/components/reports/ReportLoadingDialog";
import TitledWrapper from "@/components/TitledWrapper";
import WithTooltip from "@/components/WithTooltip";
import NaverMapLoader from "@/containers/NaverMapLoader";
import { resetCreateCorporationReportLogState } from "@/redux/corporation/actions";
import { getCorporationReportLogFetchState, getCorporationReportLogState } from "@/redux/corporation/selectors";
import { fetchCorporationReportLogThunk } from "@/redux/corporation/thunk";
import { openSuccessSnackbar } from "@/redux/snackbar/actions";
import { lengths } from "@/styles/values";
import { makeUrlList } from "@/utils/list";
import { getCoordinates } from "@/utils/map";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  > div {
    max-width: 640px;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: ${lengths.desktop}) {
    padding: 24px 24px 40px;
  }
`;

const ReportAddressInfoContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 12px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 13px;
`;

const ExecutiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Executive = styled.span`
  display: flex;
  gap: 8px;

  font-weight: 500;
  font-size: 14px;
`;

const ExecutivePosition = styled.div`
  padding: 2px;

  font-weight: 400;
  font-size: 12px;

  background-color: beige;
  border-radius: 5px;
`;

const ExecutiveNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const ReportInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ReportInfo = styled.span`
  display: flex;
  gap: 8px;

  font-weight: 500;
  font-size: 14px;
`;

const ReportInfoNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const HeaderNodeContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img``;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const Text = styled.span`
  color: #797979;
  font-weight: 700;
  font-size: 12px;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

const StyledLoadingButton = styled(LoadingButton)`
  &.MuiLoadingButton-root {
    width: 100%;
    height: 39px;

    color: #ff8f17;
    font-weight: 700;
    font-size: 13px;

    background-color: #fff;
    border: 1px solid #ff8f17;

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

const ShareButtonContainer = styled.div`
  padding: 23px 34px;
`;

const CorporationShowContainer = () => {
  const dispatch = useDispatch();

  const { value: corporationReport } = useSelector(getCorporationReportLogState);
  const corporationFetchState = useSelector(getCorporationReportLogFetchState);

  const { reportId: rawReportId } = useParams();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [coordinates, setCoordinates] = useState<{ latitude: string; longitude: string } | null>();

  const [reportId, setReportId] = useState<number>();
  const [providerId, setProviderId] = useState<number>();

  useEffect(() => {
    if (!rawReportId) {
      return;
    } else {
      setReportId(parseInt(rawReportId, 10));
    }

    dispatch(fetchCorporationReportLogThunk({ reportId: Number(rawReportId) }));
  }, [rawReportId]);

  useEffect(() => {
    if (corporationReport && corporationReport?.provider_id) {
      setProviderId(corporationReport.provider_id);
    }

    if (corporationReport && corporationFetchState === "SUCCESS") {
      dispatch(resetCreateCorporationReportLogState());
    }
  }, [corporationReport, corporationFetchState]);

  useEffect(() => {
    async function getCoords(address: string) {
      const datas = await getCoordinates(address);
      if (datas) {
        setCoordinates({ latitude: datas[1], longitude: datas[0] });
      }
    }
    if (corporationReport?.corporation_ledger?.corporation_point) {
      getCoords(corporationReport?.corporation_ledger?.corporation_point);
    }
  }, [corporationReport?.corporation_ledger?.corporation_point]);

  const handleCopy = useCallback(async () => {
    try {
      await copy(corporationReport?.corporation_ledger?.corporation_point ?? "");
      setTooltipOpen(true);
    } catch {
      setTooltipOpen(false);
    }

    setTimeout(() => setTooltipOpen(false), 1500);
  }, [corporationReport]);

  const handleUrlCopy = useCallback(async () => {
    if (corporationReport) {
      await copy(
        `${window.location.origin}/reports/${reportId}?type=corporation&code=${corporationReport.access_code}`,
      );
      dispatch(openSuccessSnackbar("복사되었습니다!"));
    }
  }, [corporationReport]);

  const getDocumentList = useMemo(() => {
    return (
      <DocumentDownloadList
        type="corporation"
        reportId={corporationReport?.provider_id ?? null}
        title="법인등기부등본"
        fetchState={corporationReport?.corporation_ledger?.corporation_ledger_url ? "SUCCESS" : "FAILURE"}
        urls={makeUrlList(corporationReport?.corporation_ledger?.corporation_ledger_url)}
        resource="registerLedger"
      />
    );
  }, [corporationReport?.corporation_ledger?.corporation_ledger_url]);

  if (corporationFetchState === "READY" || corporationFetchState === "FETCHING") {
    return <ReportLoadingDialog />;
  }
  if (!reportId || !providerId) {
    return <NotFound />;
  }

  return (
    <Container>
      <TitledWrapper
        title="상호명"
        titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
        backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      >
        <ReportAddressInfoContainer>
          <TitleContainer>
            <Title>
              {corporationReport?.corporation_ledger?.company_name
                ? `🏢 ${corporationReport?.corporation_ledger?.company_name}`
                : `🏢 ${corporationReport?.company_name}` ?? "🏢 상호 정보가 없습니다."}
            </Title>
          </TitleContainer>
        </ReportAddressInfoContainer>
      </TitledWrapper>
      <TitledWrapper
        title="본점 주소"
        titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
        backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      >
        <ReportAddressInfoContainer>
          <TitleContainer>
            <Title>{corporationReport?.corporation_ledger?.corporation_point ?? "🏠 본점 정보가 없습니다."}</Title>
          </TitleContainer>
        </ReportAddressInfoContainer>
      </TitledWrapper>
      <TitledWrapper
        title="임원 정보"
        titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
        backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      >
        <ReportAddressInfoContainer>
          <ExecutiveContainer>
            {corporationReport?.corporation_ledger?.executive &&
            corporationReport?.corporation_ledger?.executive.length > 0 ? (
              corporationReport?.corporation_ledger?.executive.map((item) => (
                <Executive>
                  <ExecutivePosition>{item.position}</ExecutivePosition>
                  {item.name}
                  <ExecutiveNumber>{item.number}</ExecutiveNumber>
                </Executive>
              ))
            ) : (
              <Title>👦🏻 임원 정보가 없습니다.</Title>
            )}
          </ExecutiveContainer>
        </ReportAddressInfoContainer>
      </TitledWrapper>
      <TitledWrapper
        title="등기 정보"
        titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
        backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      >
        <ReportAddressInfoContainer>
          <ReportInfoContainer>
            {corporationReport?.corporation_ledger ? (
              <>
                <ReportInfo>
                  고유번호: <ReportInfoNumber>{corporationReport?.corporation_ledger?.company_name}</ReportInfoNumber>
                </ReportInfo>
                <ReportInfo>
                  등록번호:{" "}
                  <ReportInfoNumber>{corporationReport?.corporation_ledger?.register_number}</ReportInfoNumber>
                </ReportInfo>
              </>
            ) : (
              <Title>📃 등기 정보가 없습니다.</Title>
            )}
          </ReportInfoContainer>
        </ReportAddressInfoContainer>
      </TitledWrapper>
      {coordinates && coordinates.latitude && coordinates.longitude ? (
        <NaverMapLoader>
          <TitledWrapper
            title="지도"
            titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
            backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
            headerNodeList={[
              <HeaderNodeContainer>
                <IconContainer>
                  <Icon src="/images/link.svg" />
                </IconContainer>
                <TextContainer>
                  <WithTooltip
                    open={tooltipOpen}
                    onClose={setTooltipOpen}
                    title="복사되었습니다!"
                    type="click"
                    placement="top"
                  >
                    <Text onClick={handleCopy}>주소 복사</Text>
                  </WithTooltip>
                </TextContainer>
              </HeaderNodeContainer>,
            ]}
          >
            <Map latitude={coordinates.latitude} longitude={coordinates.longitude} />
          </TitledWrapper>
        </NaverMapLoader>
      ) : (
        <></>
      )}
      <TitledWrapper
        title="공문서 열람 및 다운로드"
        titleStyle={{
          size: 15,
          weight: 700,
          color: "#333333",
          align: "center",
          letterSpacing: "-0.5px",
          bottomGap: 22,
        }}
        backgroundStyle={{ color: "#ffffff", padding: "36px 0 46px" }}
      >
        <div>
          <ButtonGroupContainer>{getDocumentList}</ButtonGroupContainer>
        </div>
      </TitledWrapper>
      <TitledWrapper
        title="공유하기"
        titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
        backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      >
        <ShareButtonContainer>
          <StyledLoadingButton onClick={handleUrlCopy}>다른 사람들에게 등기부등본 링크 공유하기</StyledLoadingButton>
        </ShareButtonContainer>
      </TitledWrapper>
    </Container>
  );
};

export default CorporationShowContainer;
