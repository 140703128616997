import { ButtonBaseProps } from "@mui/material";
import styled from "styled-components";
import FlatButton from "./FlatButton";
import { colors } from "@/styles/values";

interface PrimaryButtonProps extends ButtonBaseProps {
  fullWidth?: boolean;
}

const PrimaryButton = styled(FlatButton)<PrimaryButtonProps>`
  &.MuiButtonBase-root {
    height: 48px;
    padding-right: 24px;
    padding-left: 24px;

    color: #fff;
    font-weight: 700;
    font-size: 14px;

    background-color: ${colors.main};

    &.Mui-disabled {
      color: #797979;

      background-color: #f2f2f2;
    }

    ${({ fullWidth }) => fullWidth && "width: 100%;"}

    &.outlined {
      color: ${colors.main};

      background-color: ${colors.white};
      border: 1px solid ${colors.main};
    }
  }
`;

export default PrimaryButton;
