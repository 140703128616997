import React from "react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";
import styled from "styled-components";
import { colors } from "@/styles/values";

const ChevronImg = styled.img``;

const ChevronButton = styled(ButtonBase)<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  .MuiTouchRipple-rippleVisible {
    color: ${colors.main};
  }

  &.MuiButtonBase-root {
    border-radius: 16px;

    &:hover {
      background-color: ${colors.main}19;
    }
  }

  > img {
    transform: rotate(${({ open }) => (open ? -90 : 90)}deg);
  }
`;

interface ChevronProps extends ButtonBaseProps {
  open?: boolean;
}

export default function Chevron({ open, ...props }: ChevronProps) {
  return (
    <ChevronButton open={open} {...props}>
      <ChevronImg src="/images/chevron-right.svg" />
    </ChevronButton>
  );
}
