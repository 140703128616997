import React from "react";
import LoadingCircle from "@/components/LoadingCircle";
import Configs from "@/configs";
import { loadScript } from "@/utils/script";

type Props = {};

class NaverMapLoader extends React.Component<Props> {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    await loadScript(
      "naver-map",
      `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${Configs.DOCZIP_NAVER_CLIENT_ID}`,
    );
    this.setState({ ready: true });
  }

  render() {
    const { ready } = this.state;
    const { children } = this.props;
    return ready ? children : <LoadingCircle />;
  }
}

export default NaverMapLoader;
