import { Report } from "@blitz-core/api-client";
import { RealEstateBrokerageResponse, ReportLogResponse, UserType } from "@doczip/api-client";
import { requestSelfCertification } from "../auth/functions";
import {
  reportLogDeleteFetch,
  reportLogDocumentFetch,
  reportLogListFetch,
  userMe,
  changePhoneNumber,
  changePassword,
  editUser,
  realtorBrokerageList,
  verifyRealtor,
  verifyIdentity,
} from "./actions";
import { blitzCoreReportApi, realtorApi, reportApi, userApi, verificationApi } from "@/client";
import {
  ReportLogDeleteRequestPayload,
  ReportLogDocumentRequestPayload,
  ReportLogListRequestPayload,
} from "@/redux/user/types";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

export const fetchUserMeThunk = makeFetchThunkAction(userMe, {
  getResponseFunction: () => userApi.getUser("me"),
});

export const fetchMoreReportLogListThunk = makeFetchMoreListThunkAction<ReportLogResponse, ReportLogListRequestPayload>(
  reportLogListFetch,
  {
    pageSelectFunction: ({ user }) => ({
      page: user.reportLogListPage,
      totalPage: user.reportLogListTotalPage,
    }),
    getResponseListFunction: ({ page }, { registeredAddress, deleted }) =>
      reportApi.getReportLogs(registeredAddress, void 0, page * 10, 10, deleted),
  },
);

export const fetchReportLogDocumentThunk = makeFetchThunkAction<Report, ReportLogDocumentRequestPayload>(
  reportLogDocumentFetch,
  {
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportGet(reportId),
  },
);

export const deleteReportLogThunk = makeFetchThunkAction<void, ReportLogDeleteRequestPayload>(reportLogDeleteFetch, {
  getResponseFunction: (_, { id }) => reportApi.deleteReportLog(id),
});

export const changePhoneNumberThunk = makeFetchThunkAction<
  void,
  {
    currentPhoneNumber: string;
    newPhoneNumber: string;
    verificationCode: string;
  }
>(changePhoneNumber, {
  getResponseFunction: (_, { currentPhoneNumber, newPhoneNumber, verificationCode }) =>
    userApi.changePhoneNumber("me", {
      current_phone_number: currentPhoneNumber,
      new_phone_number: newPhoneNumber,
      new_phone_number_verification_code: verificationCode,
    }),
  useErrorSnackbar: true,
  successSnackbarMessage: "전화번호를 변경하였습니다",
});

export const changePasswordThunk = makeFetchThunkAction<
  void,
  {
    currentPassword: string;
    newPassword: string;
  }
>(changePassword, {
  getResponseFunction: (_, { currentPassword, newPassword }) =>
    userApi.changePassword("me", { current_password: currentPassword, new_password: newPassword }),
  useErrorSnackbar: true,
  successSnackbarMessage: "비밀번호를 변경하였습니다",
});

export const editUserThunk = makeFetchThunkAction<
  void,
  {
    email?: string;
    name?: string;
    nickname?: string;
  }
>(editUser, {
  getResponseFunction: (_, { email = "", name = "", nickname }) =>
    userApi.updateUser("me", { user_type: UserType.User, email, name, nickname }),
  useErrorSnackbar: true,
  successSnackbarMessage: "유저 정보를 수정하였습니다",
});

export const sendVerificationCodeThunk = makeFetchThunkAction<void, string>(editUser, {
  getResponseFunction: (_, phoneNumber) =>
    verificationApi.sendPhoneNumberVerificationCode({ phone_number: phoneNumber }),
  useErrorSnackbar: true,
  successSnackbarMessage: "인증문자를 발송하였습니다",
});

export const realtorListFetchThunk = makeFetchThunkAction<
  RealEstateBrokerageResponse[],
  { sido: string; sigungu: string; officeName?: string }
>(realtorBrokerageList, {
  getResponseFunction: (_, { sido, sigungu, officeName }) =>
    realtorApi.getRealEstateBrokerages(sido, sigungu, officeName),
  useErrorSnackbar: true,
});

export const verifyRealtorThunk = makeFetchThunkAction<
  void,
  { sigungu?: string; registerationNumber: string; systemRegisterationNumber?: string }
>(verifyRealtor, {
  getResponseFunction: (_, { sigungu, registerationNumber, systemRegisterationNumber }) =>
    userApi.verifyRealtor("me", {
      registration_number: registerationNumber,
      system_registration_number: systemRegisterationNumber,
      sigungu_code: sigungu,
    }),
  useErrorSnackbar: true,
  successSnackbarMessage: "공인중개사인증 되었습니다!",
});

export const verifyIdentityThunk = makeFetchThunkAction<void, string>(verifyIdentity, {
  getResponseFunction: async (_, phoneNumber) => {
    const impUid = await requestSelfCertification(phoneNumber);
    return userApi.verifyIdentity("me", {
      identity_imp_uid: impUid,
    });
  },
  useErrorSnackbar: true,
  successSnackbarMessage: "실명인증 되었습니다!",
});
