import {
  DocumentResponse,
  JeonseContractResponse,
  RentContractResponse,
  TradeContractResponse,
} from "@doczip/api-client";

export function cloneResponse(document: DocumentResponse) {
  const cloned = {
    ...document,
    owners: [...document.owners],
    consumers: [...document.consumers],
    agents: [...document.agents],
  };
  if (document.trade_contract) {
    cloned.trade_contract = { ...document.trade_contract };
  } else if (document.jeonse_contract) {
    cloned.jeonse_contract = { ...document.jeonse_contract };
  } else if (document.rent_contract) {
    cloned.rent_contract = { ...document.rent_contract };
  } else if (document.residential_manual) {
    cloned.residential_manual = { ...document.residential_manual };
  }
  return cloned;
}

// Type guards

export function isTradeContractBody(
  body: TradeContractResponse | JeonseContractResponse | RentContractResponse,
): body is TradeContractResponse {
  return typeof (body as JeonseContractResponse).portion_to_lease === "undefined";
}

export function isJeonseContractBody(
  body: TradeContractResponse | JeonseContractResponse | RentContractResponse,
): body is JeonseContractResponse {
  return !isTradeContractBody(body) && typeof (body as RentContractResponse).rent_payment_day_of_month === "undefined";
}

export function isRentContractBody(
  body: TradeContractResponse | JeonseContractResponse | RentContractResponse,
): body is RentContractResponse {
  return !isTradeContractBody(body) && !isJeonseContractBody(body);
}
