import React, { useMemo } from "react";
import { CorporationReportLogResponse } from "@doczip/api-client";
import styled from "styled-components";
import FlatButton from "@/components/buttons/FlatButton";
import CorporationReportLog from "@/components/coporation/CorporationReportLog";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import Link from "@/components/Link";
import { NoReportLogResult, NoSearchResult } from "@/components/NoResult";
import { FetchState } from "@/interfaces/fetch";
import { getUrl } from "@/utils/routes";

const ReportLogListContainer = styled.div`
  width: 100%;

  > a {
    display: block;
  }

  > div,
  > a {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
`;

const ShowAllButton = styled(FlatButton)`
  &.MuiButtonBase-root {
    margin-top: 30px;
  }
`;

const ReportLogListOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface ReportLogListProps {
  list: CorporationReportLogResponse[] | null;
  page: number | null;
  totalPage: number | null;
  fetchState: FetchState;
  showRecentOnly?: boolean;
  disableInitialize?: boolean;
  onLoadMore?: () => void;
  hideShowAllButton?: boolean;
  noResult?: React.ReactNode;
  query?: string;
}

export function CorporationReportLogList({
  showRecentOnly,
  list,
  hideShowAllButton = true,
  query,
  noResult,
  ...props
}: ReportLogListProps) {
  const slicedList = useMemo(() => {
    if (!list || !list.length || !showRecentOnly) {
      return list;
    }

    return list.slice(0, 5);
  }, [list, showRecentOnly]);

  return (
    <>
      <ReportLogListOuterContainer>
        <ReportLogListContainer>
          {props.fetchState === "SUCCESS" &&
            !list?.length &&
            (noResult ?? (query ? <NoSearchResult /> : <NoReportLogResult />))}
          <InfiniteScrollList
            list={slicedList}
            renderItem={(log) => <CorporationReportLog key={log.id} log={log} />}
            disableInitialize
            {...props}
          />
        </ReportLogListContainer>
        {!hideShowAllButton && (
          <Link href={getUrl("reportsRoot")}>
            <ShowAllButton>내역 모두 보기</ShowAllButton>
          </Link>
        )}
      </ReportLogListOuterContainer>
    </>
  );
}
