import React, { useMemo } from "react";
import { ReportKABResult, ReportKBResult, ReportTankerPriceResult } from "@doczip/api-client";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from "recharts";
import styled from "styled-components";
import useWindowSize from "@/hooks/useWindowSize";
import { colors } from "@/styles/values";
import { RegenerateChartDataByTimestamp } from "@/utils/chart";
import { toYmd, format } from "@/utils/datetime";
import { amountToKorean } from "@/utils/text";

const ChartBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 225px;

  & .recharts-default-legend {
    height: 30px;
    overflow-y: overlay;
  }

  & .recharts-legend-item-text {
    font-size: 12px;
  }
`;

const TooltipBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 180px;
  min-height: 54px;
  padding: 10px 10px;

  background-color: ${colors.white};
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
`;

const TooltipRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;

  color: ${colors.dark};
  font-size: 12px;
`;

const Title = styled.div`
  font-weight: bold;
`;

const TooltipSubtext = styled.div`
  color: #666;
  font-weight: bold;
  font-size: 13px;
`;

type TooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: number;
};

const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <TooltipBox>
        <TooltipSubtext>{label ? toYmd(label) : ""}</TooltipSubtext>
        {payload
          .slice()
          .reverse()
          .map((datum: any, i) => {
            return (
              <TooltipRow key={i}>
                <Title>{datum.name}</Title>
                <div>{`${amountToKorean(datum.value, 10_000, "mixed")}원`}</div>
              </TooltipRow>
            );
          })}
      </TooltipBox>
    );
  }

  return null;
};

export type ChartData = {
  kbResult: ReportKBResult | null;
  kabResult: ReportKABResult | null;
  tankerPriceList: ReportTankerPriceResult[] | null;
};

type ReportPriceChartProps = {
  chartData: {
    kbResult: ReportKBResult | null;
    kabResult: ReportKABResult | null;
    tankerPriceList: ReportTankerPriceResult[] | null;
  };
};

export default function ReportPriceChart({ chartData }: ReportPriceChartProps) {
  const { tankerPriceList, kbResult, kabResult } = chartData;
  const tankerStartDate = tankerPriceList && tankerPriceList[tankerPriceList.length - 1].tanker_predict_date;
  const tankerEndDate = tankerPriceList && tankerPriceList[0].tanker_predict_date;
  const kbStartDate =
    kbResult && kbResult.kb_apartment_prices.length > 0 && kbResult.kb_apartment_prices[0].evaluation_date;
  const kbEndDate =
    kbResult &&
    kbResult.kb_apartment_prices.length > 0 &&
    kbResult.kb_apartment_prices[kbResult.kb_apartment_prices.length - 1].evaluation_date;

  const startDate = tankerStartDate && kbStartDate && kbStartDate > tankerStartDate ? kbStartDate : tankerStartDate;
  const endDate = kbEndDate && !tankerEndDate ? kbEndDate : tankerEndDate;

  const data = useMemo(() => {
    const data: Array<Record<string, number>> = [];

    if (startDate && endDate) {
      if (tankerPriceList && tankerPriceList.length > 0) {
        tankerPriceList.forEach((price, i) => {
          if (startDate <= price.tanker_predict_date && price.tanker_predict_date <= endDate) {
            const timestamp = new Date(price.tanker_predict_date).valueOf();
            data.push({
              timestamp,
              tanker50: price.tanker_50_price,
            });
          }
        });
      }

      if (kbResult && kbResult.kb_apartment_prices.length > 0) {
        kbResult.kb_apartment_prices.forEach((price, i) => {
          if (startDate <= price.evaluation_date && price.evaluation_date <= endDate) {
            const timestamp = new Date(price.evaluation_date).valueOf();
            data.push({
              timestamp,
              kbMiddle: price.mid_price || kbResult.kb_apartment_prices[i - 1].mid_price || 0,
            });
          }
        });
        if (kbEndDate !== endDate) {
          const timestamp = tankerEndDate ? new Date(tankerEndDate).valueOf() : 0;
          data.push({
            timestamp,
            kbMiddle: kbResult.kb_apartment_prices[kbResult.kb_apartment_prices.length - 1].mid_price || 0,
          });
        }
      }

      if (kabResult && kabResult.kab_ho_price && kabResult.kab_ho_price.mid_price) {
        if (startDate <= kabResult.kab_ho_price.evaluation_date && kabResult.kab_ho_price.evaluation_date <= endDate) {
          const timestamp = new Date(kabResult.kab_ho_price.evaluation_date).valueOf();
          data.push({
            timestamp,
            kabMiddle: kabResult.kab_ho_price.mid_price,
          });
        }
      }
    }

    const newData = RegenerateChartDataByTimestamp(data);
    return newData;
  }, [tankerPriceList, kbResult, kabResult, startDate, endDate, kbEndDate, tankerEndDate]);

  const { width } = useWindowSize();

  return (
    <ChartBox>
      <LineChart width={Math.min(580, width - 100)} height={220} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={["dataMin", "dataMax"]}
          dataKey="timestamp"
          name="날짜"
          tickFormatter={(value) => format(value, "yy.MM.dd.")}
          tick={{ fontSize: "12px", color: "#666" }}
        />
        <YAxis
          name="예측가"
          tickFormatter={(value) => amountToKorean(value, 10000, "mixed")}
          tick={{ fontSize: "11px", color: "#666" }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          name="AI 예측가"
          type="monotone"
          dataKey="tanker50"
          dot={false}
          stroke={colors.paperBlue}
          strokeWidth={2}
          connectNulls
        />
        <Line
          name="부동산 시세"
          type="monotone"
          dataKey="kbMiddle"
          dot={false}
          stroke={colors.main}
          strokeWidth={2}
          connectNulls
        />
        <Line
          name="실거래가"
          type="monotone"
          dataKey="kabMiddle"
          stroke={colors.green}
          strokeWidth={2}
          connectNulls
          dot={{ stroke: colors.green, strokeWidth: 2 }}
        />
      </LineChart>
    </ChartBox>
  );
}
