import { Listing } from "@vesta/api-client";
import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import DefaultThumbnail1 from "../../assets/images/default_thumbnail1.png";
import DefaultThumbnail2 from "../../assets/images/default_thumbnail2.png";
import DefaultThumbnail3 from "../../assets/images/default_thumbnail3.png";
import DefaultThumbnail4 from "../../assets/images/default_thumbnail4.png";
import DoczipMark from "../../assets/images/doczip_mark.svg";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import LoadingCircle from "@/components/LoadingCircle";
import MarkEmbed from "@/components/sales/common/MarkEmbed";
import TitledWrapper from "@/components/TitledWrapper";
import useWindowSize from "@/hooks/useWindowSize";
import { FetchState } from "@/interfaces/fetch";
import { colors, lengths, lengthsInNumbers } from "@/styles/values";
import { formatFloorType, formatTradeType } from "@/utils/address";
import { amountToKorean } from "@/utils/text";

const InfoTooltipButton = styled.div`
  margin-bottom: 16px;

  color: #dfe2e7;

  cursor: pointer;
`;

const TooltipInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;

  @media screen and (max-width: ${lengths.desktop}) {
    max-width: 200px;
  }
`;

const MolitOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const MolitListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border: 1px solid #f2f2f2;

  @media screen and (max-width: ${lengths.desktop}) {
    gap: 16px;
    width: 267px;

    border: none;
  }
`;

const MolitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 7px 9px;

  border-bottom: 1px solid #f2f2f2;

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;
    gap: 12px;
    padding-top: 14px;
    padding-right: 13px;
    padding-bottom: 11px;
    padding-left: 13px;

    border: 1px solid #f2f2f2;
    cursor: pointer;
  }

  &:last-of-type {
    @media screen and (min-width: ${lengths.desktop}) {
      border-bottom: none;
    }
  }
`;

const MolitMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
`;

const MolitInnerContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 40px;

  @media screen and (max-width: ${lengths.desktop}) {
    align-items: center;
    height: auto;
  }
`;

const MolitImg = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 8px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 48px;
    height: 48px;
  }
`;

const MolitTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  height: 40px;

  @media screen and (max-width: ${lengths.desktop}) {
    height: auto;
  }
`;

const MolitDetailContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const MolitTitle = styled.div`
  margin: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const MolitText = styled.span`
  position: relative;

  padding-right: 4px;

  font-weight: 500;
  font-size: 12px;
  line-height: 10px;

  &::after {
    position: absolute;
    top: 10%;
    right: 0;

    width: 1px;
    height: 95%;

    background-color: ${colors.black};

    content: "";
  }

  &:last-of-type::after {
    content: none;
  }
`;

const MolitHighlight = styled(MolitText)`
  color: #ff8f17;
  font-weight: 700;
`;

const Description = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;
`;

const Achor = styled.a`
  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
  }
`;

const OpenLinkButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    width: 40px;
    height: 30px;

    font-weight: 500;
    font-size: 11px;

    @media screen and (max-width: ${lengths.desktop}) {
      width: 100%;
      height: 28px;
    }
  }
`;

const MoreListButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 38px;

    color: #797979;
    font-weight: 500;
    font-size: 12px;

    background-color: inherit;
    border: 1px solid #dfe2e7;

    @media screen and (max-width: ${lengths.desktop}) {
      width: 267px;
    }
  }
`;

type ReportMolitListProps = {
  list: Listing[] | null;
  reportFetchState: FetchState;
};

const DefaultThumbnail = [DefaultThumbnail1, DefaultThumbnail2, DefaultThumbnail3, DefaultThumbnail4];
const ReportMolitList = ({ list, reportFetchState }: ReportMolitListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const headerContents = [
    <InfoTooltipButton data-tip data-for="molit-info">
      <InfoIcon color="inherit" />
      <ReactTooltip id="molit-info" place="left" type="dark" effect="solid">
        <TooltipInnerContent>
          해당 매물과 비슷한 조건의 실제 매물로, ‘보기' 클릭시 실제 부동산 매물 페이지가 열립니다.
        </TooltipInnerContent>
      </ReactTooltip>
    </InfoTooltipButton>,
  ];

  const handleOpenLinkClick = (link: string) => {
    if (width < lengthsInNumbers.desktop) {
      window.open(link, "_blank");
    }
  };

  const mainContents = (
    <>
      {list && list.length > 0 ? (
        <MolitOuterContainer>
          <MolitListContainer>
            {list.slice(0, !isOpen ? 2 : 5).map((item, index) => (
              <MolitContainer onClick={() => handleOpenLinkClick(item.source_permlink)}>
                {width > lengthsInNumbers.desktop ? (
                  <MolitInnerContainer>
                    {item.image_url ? (
                      <MolitImg src={item.image_url} />
                    ) : (
                      <MarkEmbed markSrc={DoczipMark} markWitdh="14.4px" markHeight="14.4px">
                        <MolitImg src={DefaultThumbnail[index % 4]} />
                      </MarkEmbed>
                    )}
                    <MolitTextContainer>
                      <MolitTitle>
                        {item.street_address} {item.title} {item.floor_type && formatFloorType(item.floor_type) + "층"}
                      </MolitTitle>
                      <MolitDetailContainer>
                        <MolitHighlight>
                          {formatTradeType(item.trade_type)} {amountToKorean(item.asking_price, 10_000, "mixed")} 원
                        </MolitHighlight>
                        <MolitText>{item.supply_area + "㎡ 공용"}</MolitText>
                        <MolitText>{item.private_area + "㎡ 전용"}</MolitText>
                        {item.floor_type && item.floor && (
                          <MolitText>
                            {formatFloorType(item.floor_type) + "/"}
                            {item.floor + "층"}
                          </MolitText>
                        )}
                      </MolitDetailContainer>
                    </MolitTextContainer>
                  </MolitInnerContainer>
                ) : (
                  <MolitMobileContainer>
                    <MolitInnerContainer>
                      {item.image_url ? (
                        <MolitImg src={item.image_url} />
                      ) : (
                        <MarkEmbed markSrc={DoczipMark} markWitdh="14.4px" markHeight="14.4px">
                          <MolitImg src={DefaultThumbnail[index % 4]} />
                        </MarkEmbed>
                      )}
                      <MolitTitle>
                        {item.street_address} {item.title} {item.floor_type && formatFloorType(item.floor_type) + "층"}
                      </MolitTitle>
                    </MolitInnerContainer>
                    <>
                      <MolitHighlight>
                        {formatTradeType(item.trade_type)} {amountToKorean(item.asking_price, 10_000, "mixed")} 원
                      </MolitHighlight>
                    </>
                    <MolitDetailContainer>
                      <MolitText>{item.supply_area + "㎡ 공용"}</MolitText>
                      <MolitText>{item.private_area + "㎡ 전용"}</MolitText>
                      {item.floor_type && item.floor && (
                        <MolitText>
                          {formatFloorType(item.floor_type) + "/"}
                          {item.floor + "층"}
                        </MolitText>
                      )}
                    </MolitDetailContainer>
                  </MolitMobileContainer>
                )}
                <Achor href={item.source_permlink} target="_blank" rel="noreferrer">
                  <OpenLinkButton className="outlined">보기</OpenLinkButton>
                </Achor>
              </MolitContainer>
            ))}
          </MolitListContainer>
          <MoreListButton onClick={toggleOpen}>{!isOpen ? "매물 더 보기" : "매물 덜 보기"}</MoreListButton>
        </MolitOuterContainer>
      ) : (
        <Description>🏢 주변 비슷한 매물 정보가 없습니다.</Description>
      )}
    </>
  );

  return (
    <TitledWrapper
      title="주변 비슷한 매물"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      headerNodeList={headerContents}
    >
      {["SUCCESS", "FAILURE"].includes(reportFetchState) ? <>{mainContents}</> : <LoadingCircle />}
    </TitledWrapper>
  );
};
export default ReportMolitList;
