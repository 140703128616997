import React, { useEffect } from "react";
import { PostType } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Layout from "@/components/Layout";
import DesktopLandingContainer from "@/containers/landing/DesktopLandingContainer";
import MobileLandingContainer from "@/containers/landing/MobileLandingContainer";
import useWindowSize from "@/hooks/useWindowSize";
import { resetLimitedPostListState } from "@/redux/posts/actions";
import { fetchMoreLimitedPostListThunk } from "@/redux/posts/thunk";
import { getRandomReportFetchState } from "@/redux/report/selectors";
import { randomReportFetchThunk } from "@/redux/report/thunk";
import { getUrl } from "@/utils/routes";

const MobileHeaderContentContainer = styled.div``;

const MobileHeaderContent = styled.span`
  color: #323232;
  font-weight: 700;
  font-size: 16px;
`;

type Props = {};

const LandingContainer: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const randomReportFetchState = useSelector(getRandomReportFetchState);

  useEffect(() => {
    dispatch(resetLimitedPostListState());
  }, []);

  useEffect(() => {
    if (randomReportFetchState === "READY") {
      dispatch(randomReportFetchThunk());
    }
  }, [randomReportFetchState]);

  useEffect(() => {
    if (pathname === getUrl("dashboard")) {
      dispatch(fetchMoreLimitedPostListThunk({ postTypes: [PostType.Notice, PostType.Maintenance] }));
    } else {
      dispatch(fetchMoreLimitedPostListThunk({ postTypes: [PostType.Notice, PostType.Maintenance] }));
    }
  }, [pathname]);

  return (
    <>
      <Layout
        columnless
        headerContent={
          width < 1190 && (
            <MobileHeaderContentContainer>
              <MobileHeaderContent>공부서류 간편발급 서비스, 닥집</MobileHeaderContent>
            </MobileHeaderContentContainer>
          )
        }
      />
      {width > 1190 ? <DesktopLandingContainer /> : <MobileLandingContainer />}
    </>
  );
};

export default LandingContainer;
