import React from "react";
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import styled, { css } from "styled-components";
import TextFieldInnerButton from "@/components/buttons/TextFieldInnerButton";
import { colors } from "@/styles/values";

const TextField = styled(MuiTextField)`
  > .MuiInputBase-root {
    border-radius: 0;

    &.Mui-focused fieldset {
      border-color: ${colors.main};
    }

    &:hover fieldset {
      border-color: ${colors.main};
    }

    > .MuiInputBase-input {
      padding-right: 20px;
      padding-left: 20px;

      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export default TextField;

const TextFieldWithInnerButtonContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  margin-bottom: 16px;
`;

const StyledTextField = styled(TextField)<{ innerButtonWidth?: number }>`
  ${({ innerButtonWidth }) =>
    innerButtonWidth &&
    css`
      .MuiInputBase-root {
        .MuiInputBase-input {
          padding-right: ${innerButtonWidth + 8}px;
        }
      }
    `}
`;

type TextFieldWithInnerButtonProps = MuiTextFieldProps & {
  hideInnerButton?: boolean;
  innerButton?: React.ReactNode;
  innerButtonChildren?: React.ReactNode;
  innerButtonWidth?: number;
  onInnerButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function TextFieldWithInnerButton({
  hideInnerButton,
  innerButtonChildren,
  onInnerButtonClick,
  innerButton = <TextFieldInnerButton onClick={onInnerButtonClick}>{innerButtonChildren}</TextFieldInnerButton>,
  className,
  ...props
}: TextFieldWithInnerButtonProps) {
  return (
    <TextFieldWithInnerButtonContainer fullWidth={props.fullWidth} className={className}>
      <StyledTextField {...props} />
      {!hideInnerButton && innerButton}
    </TextFieldWithInnerButtonContainer>
  );
}
