import React, { useLayoutEffect, useRef, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";
import { colors } from "@/styles/values";

const Container = styled.div`
  position: relative;

  width: 100%;
`;

const SlideButton = styled.button`
  position: absolute;
  top: calc(50% - 15px);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  background-color: ${colors.white};
  border: 1px solid ${colors.lighterGray};
  border-radius: 15px;
  cursor: pointer;
`;

const ForwardButton = styled(SlideButton)`
  right: -15px;
`;

const BackButton = styled(SlideButton)`
  left: -15px;
`;

const SlideContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  overflow: scroll;

  scroll-behavior: smooth;
`;

type SlideProps = {
  children: React.ReactNode | React.ReactNode[];
};

const Slide = ({ children }: SlideProps) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const [showForward, setShowForward] = useState(false);
  const [showBack, setShowBack] = useState(false);

  useLayoutEffect(() => {
    if (slideRef.current) {
      if (
        !(slideRef.current.scrollWidth - 70 < slideRef.current.scrollLeft + slideRef.current.clientWidth) &&
        slideRef.current.scrollWidth > slideRef.current.offsetWidth
      ) {
        setShowForward(true);
      }
    }
  });

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollLeft > 70) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }

    if (e.currentTarget.scrollWidth - 70 < e.currentTarget.scrollLeft + e.currentTarget.clientWidth) {
      setShowForward(false);
    } else {
      setShowForward(true);
    }
  };

  const handleArrowClick = (arrowType: "front" | "back") => {
    if (slideRef && slideRef.current) {
      if (arrowType === "front") {
        slideRef.current.scrollLeft += slideRef.current.offsetWidth - 140;
      } else if (arrowType === "back") {
        slideRef.current.scrollLeft -= slideRef.current.offsetWidth - 140;
      }
    }
  };

  return (
    <Container>
      {showBack && (
        <BackButton onClick={() => handleArrowClick("back")}>
          <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
        </BackButton>
      )}
      <SlideContainer onScroll={handleScroll} ref={slideRef}>
        {children}
      </SlideContainer>
      {showForward && (
        <ForwardButton onClick={() => handleArrowClick("front")}>
          <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
        </ForwardButton>
      )}
    </Container>
  );
};

export default Slide;
