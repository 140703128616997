import { userSlice } from "./slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = userSlice.actions;

export const { reset: resetUserState, resetUserMeState, resetReportLogList, resetReportLogDocuments } = actions;

export const userMe = mergeAsyncAction(actions, "userMe");

export const reportLogListFetch = mergeAsyncAction(actions, "reportLogList");

export const reportLogDocumentFetch = mergeAsyncAction(actions, "reportLogDocument");

export const reportLogDeleteFetch = mergeAsyncAction(actions, "reportLogDelete");

export const changePhoneNumber = mergeAsyncAction(actions, "changePhoneNumber");

export const changePassword = mergeAsyncAction(actions, "changePassword");

export const editUser = mergeAsyncAction(actions, "editUser");

export const verificationCode = mergeAsyncAction(actions, "verificationCode");

export const realtorBrokerageList = mergeAsyncAction(actions, "realtorBrokerageList");

export const verifyRealtor = mergeAsyncAction(actions, "verifyRealtor");

export const verifyIdentity = mergeAsyncAction(actions, "verifyIdentity");
