import React, { useCallback, useEffect, useState } from "react";
import { RealEstateBrokerageResponse } from "@doczip/api-client";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import regionNameCodeJson from "@/assets/data/region_name_code.json";
import BrokerageList from "@/components/BrokerageList";
import ConfirmButton from "@/components/buttons/ConfirmButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CenteredLayout from "@/components/CenteredLayout";
import TextField from "@/components/TextField";
import { SkipLink } from "@/components/users/identify/identifyLayout";
import { SidoSigunguSelectorContainer, RealtorSearchContainer } from "@/components/users/identify/realtorLayout";
import {
  getRealtorBrokerageList,
  getRealtorBrokerageListFetchState,
  getVerifyRealtorFetchState,
} from "@/redux/user/selectors";
import { realtorListFetchThunk, verifyRealtorThunk } from "@/redux/user/thunk";
import { getRegionLevelArray, RegionOptionType } from "@/utils/getRegionLevelArray";
import { getUrl } from "@/utils/routes";

interface RealtorVerifyField {
  sido?: RegionOptionType;
  sigungu?: RegionOptionType;
  officeName?: string;
  selectedBroakerage?: RealEstateBrokerageResponse;
}

interface UserVerifyRealtorContainerProps {}

export default function UserVerifyRealtorContainer(props: UserVerifyRealtorContainerProps) {
  const sidoList = getRegionLevelArray({ regionLevel: "level_1", regionNameCodeJson });
  const [sigunguList, setSigunguList] = useState<RegionOptionType[] | null>([]);
  const dispatch = useDispatch();
  const realtorBrokerageList = useSelector(getRealtorBrokerageList);
  const realtorBrokerageListFetchState = useSelector(getRealtorBrokerageListFetchState);
  const verifyRealtorFetchState = useSelector(getVerifyRealtorFetchState);
  const navigate = useNavigate();

  useEffect(() => {
    if (verifyRealtorFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("userRoot"));
  }, [verifyRealtorFetchState]);

  const { values, initialValues, setFieldValue, getFieldProps, handleSubmit, submitForm } =
    useFormik<RealtorVerifyField>({
      initialValues: {},
      onSubmit: ({ sigungu, selectedBroakerage }) => {
        if (!selectedBroakerage) {
          return;
        }

        dispatch(
          verifyRealtorThunk({
            sigungu: sigungu?.code,
            systemRegisterationNumber: selectedBroakerage.system_registration_number,
            registerationNumber: selectedBroakerage.registration_number,
          }),
        );
      },
    });

  const handleBrokerageListFetch = useCallback(() => {
    if (!values.sido || !values.sigungu) {
      return;
    }

    dispatch(
      realtorListFetchThunk({
        sido: values.sido!.code,
        sigungu: values.sigungu!.code,
        officeName: values.officeName,
      }),
    );
  }, [values]);

  useEffect(() => {
    setFieldValue("sigungu", void 0);
    if (!values.sido) {
      setSigunguList(null);
      return;
    }

    if (values.sido) {
      const newSidoLabel = values.sido.label;
      const regionArray: RegionOptionType[] = getRegionLevelArray({
        regionLevel: "level_2",
        regionNameCodeJson,
        options: {
          prevRegionCode: values.sido.code,
        },
      });
      const newRegionArray = values.sido
        ? regionArray.map((region) => {
            return {
              label: region.label.replace(newSidoLabel + " ", ""),
              code: region.code,
            };
          })
        : null;
      setSigunguList(newRegionArray);
    }
  }, [values.sido]);

  const handleSelectedBrokerageChange = (newBrokerage: RealEstateBrokerageResponse) => {
    if (values.selectedBroakerage?.registration_number === newBrokerage.registration_number) {
      setFieldValue("selectedBroakerage", void 0);
      return;
    }

    setFieldValue("selectedBroakerage", newBrokerage);
  };

  return (
    <CenteredLayout title="공인중개사 인증">
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <SidoSigunguSelectorContainer>
          <Autocomplete
            id="sido"
            style={{ flex: "1" }}
            disablePortal
            options={sidoList}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(e, value) => {
              setFieldValue("sido", value !== null ? value : initialValues.sido);
            }}
            noOptionsText="검색 결과 없음"
            renderInput={(params) => <TextField {...params} label="시/도" />}
          />
          <Autocomplete
            id="sigungu"
            style={{ flex: "1" }}
            disablePortal
            options={sigunguList || []}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(e, value) => {
              setFieldValue("sigungu", value !== null ? value : initialValues.sigungu);
            }}
            noOptionsText="검색 결과 없음"
            renderInput={(params) => <TextField {...params} label="시/군/구" />}
          />
        </SidoSigunguSelectorContainer>
        <RealtorSearchContainer>
          <TextField {...getFieldProps("officeName")} fullWidth label="중개사무소 상호명" />
          <PrimaryButton onClick={handleBrokerageListFetch}>검색</PrimaryButton>
        </RealtorSearchContainer>
        {realtorBrokerageListFetchState !== "READY" && (
          <BrokerageList
            brokerageList={realtorBrokerageList ?? []}
            brokerageListFetchState={realtorBrokerageListFetchState}
            selectedBrokerage={values.selectedBroakerage}
            onSelectedBrokerageChange={handleSelectedBrokerageChange}
          />
        )}
        <ConfirmButton
          onClick={submitForm}
          disabled={!values.selectedBroakerage}
          sx={{ marginTop: "36px", marginBottom: "24px" }}
        >
          인증하기
        </ConfirmButton>
      </form>
      <SkipLink href={getUrl("userRoot")}>다음에</SkipLink>
    </CenteredLayout>
  );
}
