import React from "react";
import styled from "styled-components";
import { innerWidth } from "./Page";

const Container = styled.div`
  width: ${innerWidth};
  margin: 0;
  padding: 0;

  border: 0;
`;

type Props = {
  onMeasure: (height: number) => void;
};

type State = {
  height: number;
};

export default class MeasureBox extends React.Component<Props> {
  private container: React.RefObject<HTMLDivElement>;
  private interval: NodeJS.Timeout | null;

  state: State = {
    height: 0,
  };

  constructor(props: Props) {
    super(props);
    this.container = React.createRef();
    this.updateSize();
    this.interval = setInterval(() => {
      this.updateSize();
    }, 500);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  updateSize = () => {
    if (this.container.current) {
      const height = this.container.current.offsetHeight;
      if (height !== this.state.height) {
        this.setState({ height });
        this.props.onMeasure(height);
      }
    }
  };

  render() {
    return <Container ref={this.container}>{this.props.children}</Container>;
  }
}
