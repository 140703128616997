import { POSTS_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getLimitedPostList,
  getLimitedPostListFetchState,
  getLimitedPostListPage,
  getLimitedPostListTotalCount,
  getLimitedPostListTotalPage,

  getPost,
  getPostFetchState,

  getDeletePostFetchState,
  getCreatePostFetchState,
  getUpdatePostFetchState,
} = makeSubStateSelectorMap("posts", POSTS_STATE_KEY_LIST);
