import React, { useState } from "react";
import { UserResponse } from "@doczip/api-client";
import styled from "styled-components";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { SaveCommentProps } from "@/components/sales/type";
import useContentEditable from "@/hooks/useContentEditable";
import { alert } from "@/utils/dialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Username = styled.span`
  color: #797979;
  font-weight: 700;
  font-size: 12px;

  &.me {
    padding: 3px 5px;

    color: white;

    background-color: #888887;
    border-radius: 10px;
  }
`;

const Menu = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const MenuItem = styled.button`
  padding: 10px 16px;

  color: #606060;
  font-weight: 500;
  font-size: 14px;

  background-color: inherit;
  border: none;
  cursor: pointer;
`;

const SubmitButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    height: 38px;
    padding: 10px 16px;

    font-weight: 500;
    font-size: 14px;
  }
`;

const EditableDiv = styled.div`
  padding: 4px 0;

  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  text-align: left;

  border-bottom: 1px solid #909091;
  outline: 0 solid transparent;
  opacity: 0.5;

  &:empty::before {
    color: #8e8e8e;

    content: attr(placeholder);
  }

  &:focus::before {
    content: "";
  }
  &:focus {
    border-color: black;
    opacity: 1;
  }
`;

type SaleCommentInputProps = {
  id: number;
  user: UserResponse | null;
  defaultValue?: string;
  placeholder?: string;
  onSaveComment: (props: SaveCommentProps) => void;
  reply?: boolean;
};

const SaleCommentInput = ({
  id,
  user,
  defaultValue,
  placeholder = "댓글 달기...",
  onSaveComment,
  reply,
}: SaleCommentInputProps) => {
  const { content, setContent, contentEditableRef, onInput } = useContentEditable(defaultValue ?? "");

  const [showMenu, setShowMenu] = useState(false);

  const createComment = async () => {
    if (content === "") {
      await alert("최소한 한 글자 이상은 작성해야 합니다.");
    } else if (reply) {
      try {
        onSaveComment({ actionType: "CREATE", commentProps: { commentType: "REPLY", id, content } });
      } catch (error) {
        // 삭제된 댓글에는 답글이 달리지 않음
      }
    } else {
      onSaveComment({ actionType: "CREATE", commentProps: { commentType: "COMMENT", id, content } });
    }
  };

  const updateComment = async () => {
    if (content === "") {
      await alert("최소한 한 글자 이상은 작성해야 합니다.");
    } else if (reply) {
      try {
        onSaveComment({ actionType: "UPDATE", commentProps: { commentType: "REPLY", id, content } });
      } catch (error) {
        // 삭제된 댓글에는 답글이 달리지 않음
      }
    } else {
      onSaveComment({ actionType: "UPDATE", commentProps: { commentType: "COMMENT", id, content } });
    }
  };

  const saveComment = () => {
    if (defaultValue) {
      updateComment();
    } else {
      createComment();
    }
  };

  const handleCloseMenu = () => {
    setContent("");
    setShowMenu(false);
  };

  const username = user?.nickname || "일반유저(" + user?.username + ")";

  return (
    <Container>
      <Header>
        <Username className="me">{username}</Username>
      </Header>
      <EditableDiv
        placeholder={placeholder}
        contentEditable
        ref={contentEditableRef}
        onInput={onInput}
        onFocus={() => setShowMenu(true)}
      />
      {showMenu && (
        <Menu>
          <MenuItem onClick={handleCloseMenu}>취소</MenuItem>
          <SubmitButton disabled={content === ""} onClick={saveComment}>
            등록
          </SubmitButton>
        </Menu>
      )}
    </Container>
  );
};

export default SaleCommentInput;
