import { RegisterAddressAddress } from "@blitz-core/api-client";
import { CorporationIrosAddress } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { AddressState } from "@/redux/address/types";
import { makeTypedAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { getAsyncListInitialState, getKeyListFromState } from "@/utils/redux/state";

const makeAsyncReducer = makeTypedAsyncReducer<AddressState>();

const initialState: AddressState = {
  ...getAsyncListInitialState<AddressState, "addressList">("addressList"),
  ...getAsyncListInitialState<AddressState, "corporationAddressList">("corporationAddressList"),
  bannerCollapse: false,
  addressSearchCategory: "normal",
};

export const ADDRESS_STATE_KEY_LIST = getKeyListFromState(initialState);

export const addressSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    reset: () => initialState,
    resetAddressList: (state) => ({
      ...state,
      addressList: initialState.addressList,
      addressListFetchState: initialState.addressListFetchState,
    }),
    ...makeAsyncReducer<"addressList", void, RegisterAddressAddress[]>("addressList"),
    ...makeAsyncReducer<"corporationAddressList", void, CorporationIrosAddress[]>("corporationAddressList"),
    resetCorporationAddressList: (state) => ({
      ...state,
      corporationAddressList: initialState.corporationAddressList,
      corporationAddressListFetchState: initialState.corporationAddressListFetchState,
    }),
    ...setValueReducerList<AddressState, ["bannerCollapse", "addressSearchCategory"]>([
      "bannerCollapse",
      "addressSearchCategory",
    ]),
  },
});

export const addressReducer = addressSlice.reducer;
