import { CheckUserExistsResponse } from "@doczip/api-client";
import { openSuccessSnackbar, openErrorSnackbar } from "../snackbar/actions";
import { CommonThunkAction } from "../types";
import { resetUserMeState } from "../user/actions";
import { fetchUserMeThunk } from "../user/thunk";
import { login, resetAuthState, resetPassword, selfCertification, signUp, userExists } from "./actions";
import { requestSelfCertification } from "./functions";
import { oauth2Api, saveToken, setAuthToken, userApi } from "@/client";
import { GenericThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

export const fetchUserExistsThunk = makeFetchThunkAction<CheckUserExistsResponse, string>(userExists, {
  getResponseFunction: async (_state, payload) => userApi.checkUserExists({ phone_number: payload }),
});

interface LoginThunkPayload {
  phoneNumber: string;
  password: string;
}

export const loginThunk = makeFetchThunkAction<void, LoginThunkPayload>(login, {
  getResponseFunction: async ({ dispatch }, { phoneNumber, password }) => {
    const response = await oauth2Api.generateToken(phoneNumber, password);

    const {
      data: { access_token: token },
    } = response;

    saveToken(token);
    setAuthToken(token);

    dispatch(fetchUserMeThunk({}));

    return { ...response, data: void 0 };
  },
  useErrorSnackbar: true,
});

export function logoutThunk(): CommonThunkAction {
  return (dispatch) => {
    dispatch(resetUserMeState());
    dispatch(resetAuthState());
  };
}

interface SignUpThunkPayload extends LoginThunkPayload {
  impUid: string;
  name?: string;
}

export const signUpThunk = makeFetchThunkAction<void, SignUpThunkPayload>(signUp, {
  getResponseFunction: async ({ dispatch }, { phoneNumber, password, impUid, name }) => {
    const response = await userApi.createUser({
      phone_number: phoneNumber,
      password,
      identity_imp_uid: impUid,
      name,
    });

    dispatch(loginThunk({ phoneNumber, password }));

    return response;
  },
  successSnackbarMessage: "회원가입 하였습니다.",
  useErrorSnackbar: true,
});

export function selfCertificationThunk(phoneNumber: string): GenericThunkAction {
  return async (dispatch) => {
    dispatch(selfCertification.fetchRequest());
    try {
      const impUid = await requestSelfCertification(phoneNumber);

      dispatch(selfCertification.fetchSuccess(impUid));
      dispatch(openSuccessSnackbar("본인인증을 완료하였습니다."));
    } catch (e) {
      if (e instanceof Error) {
        dispatch(openErrorSnackbar(e.message));
        dispatch(selfCertification.fetchFailure(e));
      } else {
        throw e;
      }
    }
  };
}

interface ResetPasswordThunkPayload {
  phoneNumber: string;
  password: string;
  verificationCode?: string;
  impUid?: string;
}

export const resetPasswordThunk = makeFetchThunkAction<void, ResetPasswordThunkPayload>(resetPassword, {
  getResponseFunction: async (_, { phoneNumber, password, verificationCode, impUid }) =>
    userApi.resetPassword({
      phone_number: phoneNumber,
      phone_number_verification_code: verificationCode,
      password,
      identity_imp_uid: impUid,
    }),
  successSnackbarMessage: "비밀번호를 재설정 하였습니다.",
  useErrorSnackbar: true,
});
