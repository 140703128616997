import React from "react";
import styled from "@emotion/styled";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import CarouselCard, { CarouselCardProp } from "@/components/CarouselCard";

const StyledCarousel = styled(ResponsiveCarousel)`
  width: 100%;
  overflow: hidden;

  perspective: 1px;

  touch-action: pan-x pinch-zoom;

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

export interface CarouselProps {
  slideList?: CarouselCardProp[];
}

const Carousel: React.FC<CarouselProps> = ({ slideList }) => {
  return (
    <StyledCarousel
      axis="vertical"
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      infiniteLoop
      dynamicHeight
      autoPlay
      emulateTouch
    >
      {[...(slideList ?? [])].map((props, id) => (
        <CarouselCard key={id} {...props} />
      ))}
    </StyledCarousel>
  );
};

export default Carousel;
