import { ChargeRequestResult, UserResponse } from "@doczip/api-client";
import { openErrorSnackbar } from "../snackbar/actions";
import { charge } from "./actions";
import { requestPayment } from "./function";
import { transactionApi } from "@/client";
import { PaymentMethod } from "@/interfaces/paymentMethod";
import { makeFetchThunkAction } from "@/utils/redux/thunk";

export const chargeThunk = makeFetchThunkAction<
  ChargeRequestResult,
  {
    price: number;
    method: PaymentMethod;
    name?: string;
    user: UserResponse;
  }
>(charge, {
  getResponseFunction: async ({ dispatch }, { price, method, name, user }) => {
    let impUid;
    try {
      impUid = await requestPayment({
        user,
        price,
        method,
        name,
      });
    } catch (e) {
      if (e instanceof Error) {
        dispatch(openErrorSnackbar(e.message));
      }

      throw e;
    }

    return transactionApi.createChargeTransaction(price, impUid, "me");
  },
});
