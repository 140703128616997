import React from "react";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;

  background-color: #fff;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 13px;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    padding: 11.5px 24.5px;

    color: ${colors.main};
    font-weight: 700;
    font-size: 13px;

    border: 1px solid ${colors.main};
  }
`;

interface ReportListNoResultPlaceholderProps {}

const ReportListNoResultPlaceholder: React.FC<ReportListNoResultPlaceholderProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Container>
      <InnerContainer>
        <TextContainer>
          <Text>📂 아직 열람 내역이 없습니다.</Text>
          <Text>위 주소 검색을 통해 물건의 정보를 알아보세요!</Text>
        </TextContainer>

        <ButtonContainer>
          <Button onClick={() => navigate(getUrl("userTransactionsNew"))}>다운로드권 결제</Button>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
};

export default ReportListNoResultPlaceholder;
