import React from "react";
import styled from "styled-components";
import Layout from "@/components/Layout";
import { colors, lengths } from "@/styles/values";

export const OuterContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - ${lengths.headerHeight} - ${lengths.footerHeight});
  margin: 0 auto;
  padding: 50px 40px;

  background-color: ${colors.white};
  border-left: 1px solid #dfe2e7;

  @media screen and (max-width: ${lengths.desktop}) {
    padding: 0;
  }
`;

export const InnerContainer = styled.div`
  max-width: 817px;
  margin: 0 auto;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
    max-width: 100%;
  }
`;

type PostLayoutProps = {
  children: React.ReactNode;
};

const PostLayout = ({ children }: PostLayoutProps) => {
  return (
    <OuterContainer>
      <InnerContainer>
        <Layout isBorderLeftInFooter />
        {children}
      </InnerContainer>
    </OuterContainer>
  );
};

export default PostLayout;

export const MobileHideContainer = styled.div`
  display: none;

  @media screen and (min-width: ${lengths.desktop}) {
    display: block;
  }
`;

export const PostNewContainer = styled.div`
  width: 100%;
  min-width: 320px;
  max-width: 817px;
  min-height: 420px;
  padding: 10px 24px;

  background-color: ${colors.white};

  @media screen and (max-width: ${lengths.desktop}) {
    padding: 5px 5px;
  }
`;

export const PostTitledOuterContainer = styled.div`
  padding-bottom: 16px;
`;
