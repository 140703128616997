import React from "react";
import { UserResponse } from "@doczip/api-client";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import TextField from "@/components/TextField";
import { UserEditFormLayout, UserEditTitledContainer } from "@/components/users/UserEditLayout";
import { editUserThunk } from "@/redux/user/thunk";
import { emailSchema, nameSchema, nicknameSchema } from "@/utils/validator";

interface EditUserField {
  email?: string;
  name?: string;
  nickname?: string;
}

const EditUserSchema = yup.object<EditUserField>({
  email: emailSchema.notRequired(),
  name: nameSchema.notRequired(),
  nickname: nicknameSchema.notRequired(),
});

interface UserDetailEditProps {
  userMe: UserResponse | null;
}

export default function UserDetailEdit({ userMe }: UserDetailEditProps) {
  const dispatch = useDispatch();

  const {
    handleSubmit: handleEditUserSubmit,
    getFieldProps: getEditUserFieldProps,
    errors: editUserErros,
  } = useFormik<EditUserField>({
    initialValues: {
      email: userMe?.detail?.email,
      name: userMe?.detail?.name,
      nickname: userMe?.nickname,
    },
    validationSchema: EditUserSchema,
    onSubmit: (field) => {
      dispatch(editUserThunk(field));
    },
  });

  return (
    <UserEditFormLayout onSubmit={handleEditUserSubmit}>
      <UserEditTitledContainer title="기타정보">
        <TextField
          helperText={editUserErros.email}
          error={!!editUserErros.email}
          {...getEditUserFieldProps("email")}
          label="이메일 주소"
          fullWidth
        />
        <TextField
          helperText={editUserErros.name}
          error={!!editUserErros.name}
          {...getEditUserFieldProps("name")}
          label="성함"
          fullWidth
        />
        <TextField
          helperText={editUserErros.nickname}
          error={!!editUserErros.nickname}
          {...getEditUserFieldProps("nickname")}
          label="닉네임"
          fullWidth
        />
      </UserEditTitledContainer>
      <PrimaryButton type="submit" fullWidth>
        변경사항 저장
      </PrimaryButton>
    </UserEditFormLayout>
  );
}
