import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CenteredLayout from "@/components/CenteredLayout";
import Layout from "@/components/Layout";
import { UnderLinedLink } from "@/components/Link";
import { FormContainer, StyledTextFieldInnerButton } from "@/components/signIn/resetPasswordLayout";
import { ResetPasswordFooterContainer } from "@/components/signIn/SignInFooter";
import TextField, { TextFieldWithInnerButton } from "@/components/TextField";
import { resetAuthState } from "@/redux/auth/actions";
import { getLoginFetchState, getResetPasswordFetchState } from "@/redux/auth/selectors";
import { resetPasswordThunk } from "@/redux/auth/thunk";
import { getVerificationCodeFetchState } from "@/redux/user/selectors";
import { sendVerificationCodeThunk } from "@/redux/user/thunk";
import { getUrl } from "@/utils/routes";
import { passwordSchema, phoneNumberSchema } from "@/utils/validator";

interface ResetPasswordField {
  phoneNumber: string;
  password: string;
  verificationCode: string;
}

const resetPasswordSchema = yup.object<ResetPasswordField>({
  phoneNumber: phoneNumberSchema,
  password: passwordSchema,
  verificationCode: yup.string().required("본인인증 번호를 입력 해 주세요"),
});

interface ResetPasswordContainerProps {}

export default function ResetPasswordContainer(props: ResetPasswordContainerProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verificationCodeFetchState = useSelector(getVerificationCodeFetchState);
  const loginFetchState = useSelector(getLoginFetchState);
  const resetPasswordFetchState = useSelector(getResetPasswordFetchState);

  useEffect(() => {
    dispatch(resetAuthState());
  }, []);

  useEffect(() => {
    if (loginFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("root"));
  }, [loginFetchState]);

  useEffect(() => {
    if (resetPasswordFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("login"));
  }, [resetPasswordFetchState]);

  const { handleSubmit, getFieldProps, errors, values } = useFormik<ResetPasswordField>({
    validationSchema: resetPasswordSchema,
    initialValues: {
      phoneNumber: "",
      password: "",
      verificationCode: "",
    },
    onSubmit: (formField) => {
      dispatch(resetPasswordThunk(formField));
    },
  });

  const handleVerificationCodeSend = useCallback(() => {
    if (verificationCodeFetchState === "FETCHING") {
      return;
    }

    dispatch(sendVerificationCodeThunk(values.phoneNumber));
  }, [verificationCodeFetchState, values]);

  return (
    <>
      <CenteredLayout
        title="비밀번호 재설정"
        description={["닥집에 오신 것을 환영합니다.", "권리관계 확인으로 다양한 부동산 정보를 확인해보세요!"]}
      >
        <FormContainer onSubmit={handleSubmit}>
          <TextFieldWithInnerButton
            fullWidth
            placeholder="휴대폰 번호"
            {...getFieldProps("phoneNumber")}
            helperText={errors.phoneNumber}
            error={typeof errors.phoneNumber !== "undefined"}
            innerButton={
              <StyledTextFieldInnerButton
                disabled={!!errors.phoneNumber || !values.phoneNumber}
                onClick={handleVerificationCodeSend}
              >
                인증번호 발송
              </StyledTextFieldInnerButton>
            }
          />
          <TextField
            fullWidth
            sx={{ mb: "16px" }}
            placeholder="인증번호"
            {...getFieldProps("verificationCode")}
            helperText={errors.verificationCode}
            error={typeof errors.verificationCode !== "undefined"}
          />
          <TextField
            fullWidth
            sx={{ mb: "16px" }}
            placeholder="비밀번호"
            {...getFieldProps("password")}
            helperText={errors.password}
            error={typeof errors.password !== "undefined"}
            type="password"
          />
          <PrimaryButton fullWidth type="submit">
            비밀번호 재설정
          </PrimaryButton>
        </FormContainer>
        <ResetPasswordFooterContainer>
          <UnderLinedLink href={getUrl("resetPasswordBySelfCertification")}>
            휴대폰 번호가 바뀌어서 기존 번호로 인증할 수가 없으신가요?
          </UnderLinedLink>
        </ResetPasswordFooterContainer>
      </CenteredLayout>
      <Layout columnless />
    </>
  );
}
