import React, { useCallback } from "react";
import { ClickAwayListener } from "@mui/base";
import { Tooltip, TooltipProps } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;

  & .MuiTooltip-tooltip {
    margin-bottom: 5px !important;
    padding: 6px 16px;

    font-weight: 400;
    font-size: 13px;

    background-color: #3a3a3c;
    border-radius: 0;
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface WithTooltipProps extends Omit<TooltipProps, "onClose" | "leaveDelay"> {
  type?: "click" | "hover";
  onClose?: (open: boolean) => void;
}

const WithTooltip: React.FC<WithTooltipProps> = ({ open, title, onClose, type, placement, children }) => {
  const handleOnClose = useCallback(() => {
    if (!onClose) {
      return;
    }

    return onClose(false);
  }, [open, onClose]);

  if (type === "click") {
    return (
      <Container>
        <ClickAwayListener onClickAway={handleOnClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            onClose={handleOnClose}
            title={title}
            placement={placement}
            disableHoverListener
          >
            <ChildrenContainer>{children}</ChildrenContainer>
          </Tooltip>
        </ClickAwayListener>
      </Container>
    );
  }

  return (
    <Container>
      <Tooltip open={open} onClose={handleOnClose} title={title} placement={placement}>
        <ChildrenContainer>{children}</ChildrenContainer>
      </Tooltip>
    </Container>
  );
};

export default WithTooltip;
