import styled from "styled-components";

const UserSubscriptionListLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const UserSubscriptionListLayoutInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 640px;
`;

export default UserSubscriptionListLayout;
