import React, { useEffect, useState } from "react";
import { blitzCoreApiApi } from "@/client";
import ReportDialog from "@/components/ReportDialog";
import { ReportResponse } from "@/interfaces/report";

type Props = {
  providerId: number;
  onClose: () => void;
};

const ReportDialogContainer: React.FC<Props> = (props) => {
  const [report, setReport] = useState<ReportResponse>();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);

  const fetchReport = async () => {
    setBusy(true);
    setError(false);
    try {
      const response = await blitzCoreApiApi.blitzCoreRelayApi(`reports/${props.providerId}`);
      setReport(response.data);
    } catch (err) {
      setError(true);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, [props.providerId]);

  return <ReportDialog busy={busy} error={error} report={report} onClose={() => props.onClose()} />;
};

export default ReportDialogContainer;
