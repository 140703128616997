import React from "react";
import { PersonResponse, PersonRegistrationTypeEnum } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Date from "@/components/paper/Date";
import Table from "@/components/paper/Table";
import { getDateStringFromRegistrationNumber, getNameLabel } from "@/utils/registration";

type Props = {
  title: React.ReactNode;
  value: PersonResponse;
};

const ManualPersonView: React.FC<Props> = ({ title, value }) => {
  const getIdLabel = (): React.ReactNode => {
    if (value.registration_type === PersonRegistrationTypeEnum.Corporation) {
      return "법인등록번호";
    } else if (value.registration_type === PersonRegistrationTypeEnum.Alien) {
      return "외국인등록번호";
    } else if (getDateStringFromRegistrationNumber(value.registration_number) !== null) {
      return "등록번호";
    } else {
      return "생년월일";
    }
  };
  const getIdContent = (): React.ReactNode => {
    if (value.registration_type === PersonRegistrationTypeEnum.Corporation) {
      return value.registration_number;
    } else {
      const dateString = getDateStringFromRegistrationNumber(value.registration_number);
      if (dateString) {
        return <Date value={dateString} />;
      } else {
        return value.registration_number;
      }
    }
  };
  return (
    <>
      <Table row height={55}>
        <Box td rowSpan={2} font="label">
          {title}
        </Box>
        <Box td>주소</Box>
        <Box td hpadding="normal" align="left">
          {value.address_expr}
        </Box>
        <Box td>{getNameLabel(value.registration_type)}</Box>
        <Box td hpadding="normal" align="left" signHere="서명 또는 날인">
          {value.full_name}
        </Box>
      </Table>
      <Table row height={55}>
        <Box td>{getIdLabel()}</Box>
        <Box td hpadding="normal" align="left">
          {getIdContent()}
        </Box>
        <Box td>전화번호</Box>
        <Box td hpadding="normal" align="left">
          {value.phone_number}
        </Box>
      </Table>
    </>
  );
};

export default ManualPersonView;
