import axios, { AxiosResponse } from "axios";
import Configs from "@/configs";

interface NaverMapAddress {
  x: string;
  y: string;
}

interface NaverMapResponse {
  addresses: NaverMapAddress[];
}

export async function getCoordinates(address: string): Promise<[string, string] | null> {
  const base_url = "/map-geocode/v2/geocode";

  const headers = {
    "X-NCP-APIGW-API-KEY-ID": Configs.DOCZIP_NAVER_CLIENT_ID,
    "X-NCP-APIGW-API-KEY": Configs.DOCZIP_NAVER_CLIENT_SECRET,
  };

  const params = {
    query: address,
  };

  try {
    const response: AxiosResponse<NaverMapResponse> = await axios.get(base_url, { headers, params });
    const data = response.data;

    if (data.addresses && data.addresses.length > 0) {
      const location: [string, string] = [data.addresses[0].x, data.addresses[0].y];
      return location;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
