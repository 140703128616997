import React, { useEffect, useState } from "react";
import {
  DocumentResponse,
  DocumentTypeEnum,
  PostLimitedResponse,
  PostType,
  RegisterAddressAddress,
  ReportLogResponse,
} from "@doczip/api-client";
import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { documentApi, postApi, reportApi } from "@/client";
import DocumentNew from "@/components/documents/DocumentNew";
import { getUserMe } from "@/redux/user/selectors";
import { fetchUserMeThunk } from "@/redux/user/thunk";
import { event } from "@/utils/analytics";
import { confirm } from "@/utils/dialog";
import { getRegisteredType } from "@/utils/enum";
import { getUrl } from "@/utils/routes";
import { sleep } from "@/utils/time";

type Props = {};

const DocumentNewContainer: React.FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userMe = useSelector(getUserMe);
  const search = parse(location.search);
  const manual = location.pathname.endsWith("/new-manual");
  const reportLogId = search.report ? parseInt(search.report as string, 10) : void 0;
  const [tradeType, setTradeType] = useState("trade");
  const [reportType, setReportType] = useState(reportLogId ? "reuse" : "new");
  const [amount, setAmount] = useState<number>();
  const [rent, setRent] = useState<number>();
  const [address, setAddress] = useState<RegisterAddressAddress>();
  const [reportLog, setReportLog] = useState<ReportLogResponse>();
  const [posts, setPosts] = useState<PostLimitedResponse[]>([]);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [document, setDocument] = useState<DocumentResponse>();
  const [createError, setCreateError] = useState(false);

  const fetchReportLog = async () => {
    try {
      const response = await reportApi.getReportLog(reportLogId as number);
      setReportLog(response.data);
    } catch (err) {
      setError(true);
    }
  };

  const fetchPosts = async () => {
    try {
      const response = await postApi.getPosts(0, 20, [PostType.Maintenance], void 0, true);
      setPosts(response.data);
    } catch (err) {
      // Do nothing.
    }
  };

  const handleReuseReportDelete = async () => {
    if (await confirm("자동완성에 사용할 공부를 선택하기 위해 내 열람 내역 페이지로 이동하시겠습니까?")) {
      navigate(getUrl("reportsRoot"));
    } else {
      setReportType("new");
    }
  };

  useEffect(() => {
    if (reportLogId) {
      fetchReportLog();
    }
  }, [reportLogId]);

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (!reportLogId && reportType === "reuse") {
      handleReuseReportDelete();
    }
  }, [reportLogId, reportType]);

  const handleTradeTypeChange = (tradeType: string) => {
    setTradeType(tradeType);
    if (tradeType !== "rent") {
      setRent(void 0);
    }
  };
  const handleCreate = async () => {
    let documentType = DocumentTypeEnum.ResidentialManual;
    if (!manual) {
      if (tradeType === "trade") {
        documentType = DocumentTypeEnum.TradeContract;
      } else if (tradeType === "jeonse") {
        documentType = DocumentTypeEnum.JeonseContract;
      } else {
        documentType = DocumentTypeEnum.RentContract;
      }
    }
    try {
      if (reportType === "reuse" && reportLog) {
        setBusy(true);
        event("create_document");
        const response = await documentApi.createDocument({
          document_type: documentType,
          address_expr: reportLog.registered_address,
          amount,
          rent,
          is_trade: tradeType === "trade",
          report_log_id: reportLog.id,
        });
        setDocument(response.data);
      } else if (reportType === "blank") {
        setBusy(true);
        event("create_document");
        const response = await documentApi.createDocument({
          document_type: documentType,
          address_expr: address?.address,
          amount,
          rent,
          is_trade: tradeType === "trade",
        });
        setDocument(response.data);
      } else if (reportType === "new" && address) {
        let pay = false;
        if (userMe && userMe.free_ledger_count > 0) {
          if (
            !(await confirm(
              `등기사항전부증명서 열람에 무료 다운로드권 1개가 사용됩니다. 현재 잔여 무료 다운로드권: ${userMe.free_ledger_count}개`,
              { title: "무료 다운로드권이 사용됩니다.", confirmText: "진행하기", closeText: "닫기" },
            ))
          ) {
            return;
          }
        } else if (userMe && userMe.ticket_amount > 0) {
          if (
            !(await confirm(
              `등기사항전부증명서 열람에 다운로드권 1개가 사용됩니다. 현재 잔여 다운로드권: ${userMe.ticket_amount}개`,
              { title: "다운로드권이 사용됩니다.", confirmText: "진행하기", closeText: "닫기" },
            ))
          ) {
            return;
          }
          pay = true;
        } else {
          if (
            await confirm(`다운로드권을 충전 후 열람해주세요. 현재 잔여 다운로드권: 0개`, {
              title: "다운로드권이 부족합니다.",
              confirmText: "다운로드권 충전",
              closeText: "닫기",
            })
          ) {
            navigate(getUrl("userTransactionsNew"));
          }
          return;
        }
        setBusy(true);
        event("create_report");
        // Request a paid report.
        const reportResponse = await reportApi.createReportLog({
          registered_address: address.address,
          registered_pin_number: address.pin_number,
          registered_type: getRegisteredType(address.register_type),
          is_paid: pay,
        });
        const createdReportLog = reportResponse.data;
        // Wait until the report is ready.
        const now = Date.now() / 1000;
        let reportLog: ReportLogResponse | void = void 0;
        while (Date.now() / 1000 < now + 300) {
          // Wait 3 seconds,
          await sleep(3000);
          // and check if the report is ready.
          const reportGetResponse = await reportApi.getReportLog(createdReportLog.id);
          reportLog = reportGetResponse.data;
          if (!reportLog.is_pending) {
            // Break from the loop when ready.
            break;
          }
        }
        if (!reportLog || reportLog.is_pending) {
          // Report is not ready for 5 minutes. Throw an error.
          throw Error("Report request takes too long.");
        }
        // Finally create a document.
        event("create_document");
        const response = await documentApi.createDocument({
          document_type: documentType,
          address_expr: address.address,
          amount,
          rent,
          is_trade: tradeType === "trade",
          report_log_id: createdReportLog.id,
        });
        setDocument(response.data);
        // Update the user object.
        dispatch(fetchUserMeThunk({}));
      }
    } catch (err) {
      setCreateError(true);
    } finally {
      setBusy(false);
    }
  };

  if (document) {
    navigate(getUrl("documentsDetailEdit", { params: { documentId: document.id } }));
  }

  return (
    <DocumentNew
      busy={busy}
      error={error}
      createError={createError}
      clearCreateError={() => setCreateError(false)}
      manual={manual}
      reportLogId={reportLogId}
      reportLog={reportLog}
      posts={posts || []}
      tradeType={tradeType}
      onTradeTypeChange={handleTradeTypeChange}
      reportType={reportType}
      onReportTypeChange={(v) => setReportType(v)}
      amount={amount}
      onAmountChange={(v) => setAmount(v)}
      rent={rent}
      onRentChange={(v) => setRent(v)}
      address={address}
      onAddressChange={(v) => setAddress(v)}
      onCreate={handleCreate}
    />
  );
};

export default DocumentNewContainer;
