import { authSlice } from "./slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = authSlice.actions;

export const { reset: resetAuthState } = actions;

export const userExists = mergeAsyncAction(actions, "userExists");

export const login = mergeAsyncAction(actions, "login");

export const signUp = mergeAsyncAction(actions, "signUp");

export const selfCertification = mergeAsyncAction(actions, "selfCertification");

export const resetPassword = mergeAsyncAction(actions, "resetPassword");
