import React, { useEffect, useMemo, useRef, useState } from "react";
import { InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { AddressSearchInField, AddressSearchSchema } from "./schema";
import SearchIcon from "@/assets/icons/search-icon.svg";
import LoadingButton from "@/components/buttons/LoadingButton";
import Switch from "@/components/Switch";
import { TextFieldWithInnerButton } from "@/components/TextField";
import { FetchState } from "@/interfaces/fetch";
import { resetAddressList, resetCorporationAddressList, setAddressSearchCategory } from "@/redux/address/actions";
import { getAddressSearchCategory } from "@/redux/address/selectors";
import { colors, lengths } from "@/styles/values";
import { event } from "@/utils/analytics";

const FormContainer = styled.form`
  display: flex;
  gap: 12px;
`;

const SearchField = styled(TextFieldWithInnerButton)`
  width: 85%;
  margin-bottom: 0;

  & .MuiFormControl-root {
    height: 48px;

    border: none !important;
  }
  & .MuiOutlinedInput-root {
    height: 100%;

    background-color: #fff;

    transition: background-color 0.15s linear;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.main};
  }

  @media screen and (max-width: ${lengths.desktop}) {
    & .MuiOutlinedInput-input {
      padding-left: 0 !important;

      &::placeholder {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
`;

const TextFieldIcon = styled.img``;

const SubButtonContainer = styled.div`
  width: 15%;
  height: 48px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 25%;
  }
`;

const TextFieldSubButton = styled(LoadingButton)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 100%;
    max-height: 48px;

    color: #fff;
    font-weight: 600;
    font-size: 14px;

    background-color: ${colors.main};
  }

  &.MuiLoadingButton-root {
    &:hover {
      color: #fff;

      background-color: ${colors.main};
    }
  }
`;

type AddressSearchProps = {
  reportFetchState: FetchState;
  disableSearch?: boolean;
  handleSearchClick?: () => void;
  getAddressListByKeyword: (keyword: string) => void;
  focusOnMounted?: boolean;
  textFieldPlaceholder?: string;
  focusedTextFieldPlaceholder?: string;
  subButtonText?: string;
  focusedSubButtonText?: string;
  onFocused: (focused: boolean) => void;
  isCorporationSearch: boolean;
  setIsCorporationSearch: (value: boolean) => void;
};

const AddressSearch = ({
  reportFetchState,
  disableSearch = false,
  handleSearchClick,
  getAddressListByKeyword,
  focusOnMounted,
  textFieldPlaceholder = "도로명 주소, 건물명 또는 지번 입력",
  focusedTextFieldPlaceholder = "도로명 주소, 건물명 또는 지번 입력",
  subButtonText = "검색",
  focusedSubButtonText = "검색",
  onFocused,
  isCorporationSearch,
  setIsCorporationSearch,
}: AddressSearchProps) => {
  const [focused, setFocused] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const addressSearchCategory = useSelector(getAddressSearchCategory);

  const dispatch = useDispatch();

  const formik = useFormik<AddressSearchInField>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      address: searchParams.get("address") ?? "",
    },
    validationSchema: addressSearchCategory === "normal" && AddressSearchSchema,
    onSubmit: ({ address }) => {
      if (!address) {
        return;
      }

      setSearchParams({ address: formik.values.address });

      event("search_address");

      getAddressListByKeyword(address);
    },
  });

  useEffect(() => {
    if (onFocused) {
      onFocused(focused);
    }
  }, [focused]);

  useEffect(() => {
    if (formik.values.address.length) {
      setFocused(true);
    }
  }, [formik.values.address]);

  useEffect(() => {
    if (focusOnMounted && textFieldRef && textFieldRef?.current) {
      textFieldRef.current.focus();
    }
  }, [focusOnMounted, textFieldRef]);

  const inputPlaceholder = useMemo(() => {
    if (!isCorporationSearch) {
      if (focused) {
        return focusedTextFieldPlaceholder;
      }

      return textFieldPlaceholder;
    }

    return "법인명 입력";
  }, [isCorporationSearch, focusedTextFieldPlaceholder, textFieldPlaceholder]);

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;

    dispatch(resetAddressList());
    dispatch(resetCorporationAddressList());

    if (checked) {
      dispatch(setAddressSearchCategory("corporation"));
    } else {
      dispatch(setAddressSearchCategory("normal"));
    }

    setIsCorporationSearch(checked);
  };

  const onFocus = () => setFocused(true);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!formik.values.address.length) {
      setFocused(false);
    }
    formik.handleBlur(event);
  };

  const handleSubmitForm = () => {
    formik.submitForm();
  };

  const onClick = () => {
    if (handleSearchClick) {
      handleSearchClick();
    }
  };

  return (
    <>
      {!disableSearch && (
        <Switch
          label={{ front: "부동산 등기부등본", back: "법인 등기부등본" }}
          gap={{ marginBottom: "14px" }}
          checkedValue={isCorporationSearch}
          onChange={onSwitchChange}
        />
      )}
      <FormContainer onSubmit={formik.handleSubmit}>
        <SearchField
          inputRef={textFieldRef}
          type="text"
          name="address"
          placeholder={inputPlaceholder}
          value={formik.values.address}
          onChange={formik.handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          error={!!formik.errors.address}
          helperText={formik.errors.address}
          onClick={onClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextFieldIcon src={SearchIcon} />
              </InputAdornment>
            ),
          }}
          focused={focused}
          style={{ border: `1px solid ${colors.main}`, marginBottom: "0 auto" }}
          hideInnerButton
          fullWidth
        />
        <SubButtonContainer>
          <TextFieldSubButton
            onClick={!disableSearch ? handleSubmitForm : void 0}
            loading={reportFetchState === "FETCHING"}
            loadingPosition="center"
          >
            {focused ? focusedSubButtonText : subButtonText}
          </TextFieldSubButton>
        </SubButtonContainer>
      </FormContainer>
    </>
  );
};

export default AddressSearch;
