import React from "react";
import styled from "styled-components";
import Bracket from "./Bracket";
import Space from "./Space";

const Placeholder = styled.div`
  display: inline-block;
  min-width: 0.4em;
`;

const Check = styled.div`
  display: inline-block;
  min-width: 0.4em;

  &::before {
    display: inline-block;
    width: 0.4em;
    max-height: 1em;

    font-weight: bold;
    font-size: 1em;
    line-height: 1em;
    text-align: center;

    content: "✓";
  }
`;

type Props = {
  value: boolean;
  label: React.ReactNode;
};

const Checkbox: React.FC<Props> = ({ value, label }) => {
  return (
    <>
      <Bracket align="center">{value ? <Check /> : <Placeholder />}</Bracket>
      <Space size="tiny" />
      {label}
    </>
  );
};

export default Checkbox;
