import React from "react";
import { DocumentResponse, ResidentialManualResponse, TernaryConditionEnum } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const OtherDetailView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <>
      <Table bmargin={10} columnSizes={[110, 110, 90]}>
        <Table row height={45}>
          <Box td rowSpan={4} font="label">
            ⑪ 벽면 및<br />
            도배상태
          </Box>
          <Box td rowSpan={2} font="label">
            벽면
          </Box>
          <Box td>균열</Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.is_wall_crack}
              checkboxes={[
                ["없음", false],
                ["있음", true],
              ]}
              detailValue={body.wall_crack_detail}
              detailLabel="위치:"
              detailWidth="10em"
            />
          </Box>
        </Table>
        <Table row height={45}>
          <Box td>누수</Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.is_wall_leakage}
              checkboxes={[
                ["없음", false],
                ["있음", true],
              ]}
              detailValue={body.wall_leakage_detail}
              detailLabel="위치:"
              detailWidth="10em"
            />
          </Box>
        </Table>
        <Table row height={45}>
          <Box td font="label">
            바닥면
          </Box>
          <Box td colSpan={2} hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.flooring_condition}
              checkboxes={[
                ["깨끗함", TernaryConditionEnum.Good],
                ["보통임", TernaryConditionEnum.Normal],
                ["수리 필요", TernaryConditionEnum.Bad],
              ]}
              detailValue={body.flooring_detail}
              detailLabel="위치:"
              detailWidth="10em"
            />
          </Box>
        </Table>
        <Table row height={45}>
          <Box td font="label">
            도배
          </Box>
          <Box td colSpan={2} hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.wallpaper_condition}
              checkboxes={[
                ["깨끗함", TernaryConditionEnum.Good],
                ["보통임", TernaryConditionEnum.Normal],
                ["도배 필요", TernaryConditionEnum.Bad],
              ]}
            />
          </Box>
        </Table>
      </Table>

      <Table bmargin={30} columnSizes={[110, 100, 330, 100]}>
        <Table row height={45}>
          <Box td rowSpan={9} font="label">
            ⑫ 환경
            <br />
            조건
          </Box>
          <Box td font="label">
            일조량
          </Box>
          <Box td colSpan={3} hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.sunlight}
              checkboxes={[
                ["풍부함", TernaryConditionEnum.Good],
                ["보통임", TernaryConditionEnum.Normal],
                ["불충분", TernaryConditionEnum.Bad],
              ]}
              detailValue={body.sunlight_detail}
              detailLabel="이유: "
              detailWidth="10em"
            />
          </Box>
        </Table>
        <Table row height={45}>
          <Box td font="label">
            소음
          </Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.noise}
              checkboxes={[
                ["미미함", TernaryConditionEnum.Good],
                ["보통임", TernaryConditionEnum.Normal],
                ["심한 편임", TernaryConditionEnum.Bad],
              ]}
            />
          </Box>
          <Box td font="label">
            진동
          </Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.vibration}
              checkboxes={[
                ["미미함", TernaryConditionEnum.Good],
                ["보통임", TernaryConditionEnum.Normal],
                ["심한 편임", TernaryConditionEnum.Bad],
              ]}
            />
          </Box>
        </Table>
      </Table>
    </>
  );
};

export default OtherDetailView;
