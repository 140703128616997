import React from "react";
import { DocumentResponse, DocumentTypeEnum } from "@doczip/api-client";
import { Grid } from "@mui/material";
import AmountField from "./AmountField";
import FormLabel from "./FormLabel";
import TextField from "./TextField";

type Props = {
  document: DocumentResponse;
  onChange: (document: DocumentResponse) => void;
};

const CommonForm: React.FC<Props> = ({ document, onChange }) => {
  const handleChange = <KT extends keyof DocumentResponse>(key: KT, value: DocumentResponse[KT]) => {
    onChange({
      ...document,
      [key]: value,
    });
  };

  const trade = document.document_type === DocumentTypeEnum.TradeContract;

  return (
    <>
      <FormLabel section>거래대상물 정보</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <TextField
              size="small"
              label="소재지"
              value={document.address_expr}
              onChange={(e) => handleChange("address_expr", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={trade ? 12 : 6}>
            <AmountField
              size="small"
              label={trade ? "매매대금" : "보증금"}
              value={document.amount}
              onChange={(e) => handleChange("amount", parseInt(e.target.value, 10))}
              safeNumber
              inputProps={{ step: 10000 }}
              fullWidth
            />
          </Grid>
          {trade ? (
            <></>
          ) : (
            <Grid item sm={6}>
              <AmountField
                size="small"
                label="월세"
                value={document.rent}
                onChange={(e) => handleChange("rent", parseInt(e.target.value, 10))}
                safeNumber
                inputProps={{ step: 10000 }}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CommonForm;
