import React, { useCallback, useState } from "react";
import { Collapse } from "@mui/material";
import styled from "styled-components";
import { UnderLinedLink } from "../Link";
import Checkbox, { CheckboxFormControlLabel } from "@/components/Checkbox";
import Chevron from "@/components/Chevron";
import useWindowSize from "@/hooks/useWindowSize";
import { lengthsInNumbers } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  font-size: 12px;
`;

const StyledCheckbox = styled(Checkbox)`
  align-self: flex-start;

  &.MuiCheckbox-root {
    margin-top: -7.5px;
    margin-right: -1px;
    margin-bottom: -9px;
    margin-left: -9px;
  }
`;

const Row = styled.div`
  align-items: center;
  padding-left: 24px;
`;

const Required = styled.span`
  font-size: 12px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
`;

const StyledChevron = styled(Chevron)`
  &.MuiButtonBase-root {
    margin: -9px;
  }
`;

interface SignUpTermsProps {
  checked?: boolean;
  onChecked: (newValue: boolean) => void;
}

export default function SignUpTerms({ checked, onChecked }: SignUpTermsProps) {
  const [open, setOpen] = useState(true);

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  const required = <Required>(필수)</Required>;

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => onChecked(event.target.checked);

  const { width } = useWindowSize();

  if (width < lengthsInNumbers.desktop) {
    return (
      <Container>
        <Title>
          <CheckboxFormControlLabel
            control={<StyledCheckbox onChange={handleCheck} />}
            label={
              <>
                닥집{" "}
                <UnderLinedLink href={getUrl("staticDetail", { params: { staticId: "terms" } })}>
                  서비스 이용약관
                </UnderLinedLink>
                ,{" "}
                <UnderLinedLink href={getUrl("staticDetail", { params: { staticId: "privacy" } })}>
                  개인정보 처리방침
                </UnderLinedLink>
                ,{" "}
                <UnderLinedLink href={getUrl("staticDetail", { params: { staticId: "privacy" } })}>
                  개인정보 처리위탁약관
                </UnderLinedLink>
                에 동의합니다. {required}
              </>
            }
            checked={checked}
          />
        </Title>
      </Container>
    );
  }
  return (
    <Container>
      <Title>
        <CheckboxFormControlLabel
          control={<StyledCheckbox onChange={handleCheck} />}
          label={<>{required} 닥집 서비스 이용약관에 전체 동의합니다.</>}
          checked={checked}
        />
        <StyledChevron open={open} onClick={toggleOpen} />
      </Title>
      <Collapse in={open}>
        <Row>
          {required}{" "}
          <UnderLinedLink href={getUrl("staticDetail", { params: { staticId: "terms" } })}>
            서비스 이용약관
          </UnderLinedLink>{" "}
          에 동의
        </Row>
        <Row>
          {required}{" "}
          <UnderLinedLink href={getUrl("staticDetail", { params: { staticId: "privacy" } })}>
            개인정보 처리방침
          </UnderLinedLink>{" "}
          에 동의
        </Row>
        <Row>
          {required}{" "}
          <UnderLinedLink href={getUrl("staticDetail", { params: { staticId: "privacy" } })}>
            개인정보 처리위탁약관
          </UnderLinedLink>{" "}
          에 동의
        </Row>
      </Collapse>
    </Container>
  );
}

export function NoAbusingTerms({ checked, onChecked }: SignUpTermsProps) {
  const required = <Required>(필수)</Required>;

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => onChecked(event.target.checked);

  return (
    <Container style={{ marginTop: "16px" }}>
      <Title>
        <CheckboxFormControlLabel
          control={<StyledCheckbox onChange={handleCheck} />}
          label={
            <>
              무료 열람권을 중복 사용하기 위해 가입과 탈퇴하는 악용 사례가 있어, 가입시 무료 지급되는 등기부등본
              열람권을 사용하시면 사용일로부터 1년 간 탈퇴가 제한됩니다. 동의하시면 체크해주세요. {required}
            </>
          }
          checked={checked}
        />
      </Title>
    </Container>
  );
}
