import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SaleGradientSlider from "@/components/sales/SaleGradientSlider";
import {
  getLimitedSaleList,
  getLimitedSaleListFetchState,
  getLimitedSaleListPage,
  getLimitedSaleListTotalPage,
} from "@/redux/sales/selectors";
import { fetchMoreLimitedSaleListThunk } from "@/redux/sales/thunk";

const SaleGradientSliderContainer = () => {
  const limitedSaleList = useSelector(getLimitedSaleList);
  const limitedSaleListFetchState = useSelector(getLimitedSaleListFetchState);
  const limitedSaleListPage = useSelector(getLimitedSaleListPage);
  const limitedSaleListTotalPage = useSelector(getLimitedSaleListTotalPage);

  const dispatch = useDispatch();

  const handleLoadMoreLimitedSaleList = () => {
    dispatch(fetchMoreLimitedSaleListThunk());
  };

  return (
    <SaleGradientSlider
      list={limitedSaleList}
      fetchState={limitedSaleListFetchState}
      page={limitedSaleListPage}
      totalPage={limitedSaleListTotalPage}
      onLoadMore={handleLoadMoreLimitedSaleList}
    />
  );
};

export default SaleGradientSliderContainer;
