import React from "react";
import styled from "styled-components";
import { colors } from "@/styles/values";

type Border = "default" | "normal" | "dark" | "heavy" | "none";
type Color = "default" | "blue" | "gray";
type Props = {
  backgroundColor?: Color;
  font?: "default" | "header" | "title" | "smallTitle" | "subtitle" | "sectionTitle" | "label" | "small" | "tiny";
  align?: "left" | "right" | "center";
  valign?: "top" | "bottom";
  color?: Color;
  hpadding?: "none" | "small" | "normal" | "large";
  inline?: boolean;
  inlineBlock?: boolean;
  td?: boolean;
  width?: number;
  height?: number;
  bmargin?: number;
  hborder?: Border;
  tborder?: Border;
  bborder?: Border;
  lborder?: Border;
  rborder?: Border;
  colSpan?: number;
  rowSpan?: number;
  pre?: boolean;
  signHere?: string;
};

const fontSizeMap: Record<NonNullable<Props["font"]>, string> = {
  default: "14px",
  header: "16px",
  title: "27px",
  smallTitle: "25px",
  subtitle: "21px",
  sectionTitle: "21px",
  label: "17px",
  small: "12px",
  tiny: "10px",
};

const fontWeightMap: Record<NonNullable<Props["font"]>, "normal" | "bold"> = {
  default: "normal",
  header: "normal",
  title: "bold",
  smallTitle: "bold",
  subtitle: "normal",
  sectionTitle: "normal",
  label: "normal",
  small: "normal",
  tiny: "normal",
};

const paddingMap: Record<NonNullable<Props["hpadding"]>, string> = {
  none: "0",
  small: "0.5em",
  normal: "1em",
  large: "2em",
};

const colorMap: Record<NonNullable<Props["color"]>, string> = {
  default: colors.black,
  gray: colors.paperGray,
  blue: colors.paperBlue,
};

const borderMap: Record<Border, string> = {
  default: "0",
  normal: `1px solid ${colors.gray}`,
  dark: `1px solid ${colors.black}`,
  heavy: `4px solid ${colors.gray}`,
  none: "1px solid transparent",
};

const SignHere = styled.div`
  position: absolute;
  right: 2px;
  bottom: 2px;

  color: ${colors.gray};
  font-size: 12px;
`;

const Box: React.FC<Props> = (props) => {
  const getBorderStyle = (key: keyof React.CSSProperties, borderType?: Border): React.CSSProperties => {
    if (typeof borderType === "undefined" || borderType === "default") {
      return {};
    } else {
      return { [key]: borderMap[borderType] };
    }
  };
  const displayStyle = props.inlineBlock ? { display: "inline-block" } : {};
  const borderTopStyle = getBorderStyle("borderTop", props.tborder);
  const borderBottomStyle = getBorderStyle("borderBottom", props.bborder);
  const borderLeftStyle = getBorderStyle("borderLeft", props.lborder || props.hborder);
  const borderRightStyle = getBorderStyle("borderRight", props.rborder || props.hborder);
  const style: React.CSSProperties = {
    fontSize: fontSizeMap[props.font || "default"],
    fontWeight: fontWeightMap[props.font || "default"],
    textAlign: props.align || "inherit",
    verticalAlign: props.valign || void 0,
    backgroundColor: props.backgroundColor ? colorMap[props.backgroundColor] : void 0,
    color: props.color ? colorMap[props.color] : void 0,
    paddingLeft: paddingMap[props.hpadding || "none"],
    paddingRight: paddingMap[props.hpadding || "none"],
    width: props.width ? `${0.1 * props.width}%` : "auto",
    height: props.height ? `${props.height}px` : "auto",
    marginBottom: props.bmargin ? `${props.bmargin}px` : "0",
    whiteSpace: props.pre ? "pre-wrap" : void 0,
    position: props.signHere ? "relative" : void 0,
    ...displayStyle,
    ...borderTopStyle,
    ...borderBottomStyle,
    ...borderLeftStyle,
    ...borderRightStyle,
  };

  const signMarker = props.signHere ? <SignHere>{`(${props.signHere})`}</SignHere> : <></>;

  if (props.inline) {
    return (
      <span style={style}>
        {props.children}
        {signMarker}
      </span>
    );
  } else if (props.td) {
    return (
      <td style={style} colSpan={props.colSpan} rowSpan={props.rowSpan}>
        {props.children}
        {signMarker}
      </td>
    );
  } else {
    return (
      <div style={style}>
        {props.children}
        {signMarker}
      </div>
    );
  }
};

export default Box;
