import React from "react";
import styled, { CSSProperties } from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import { isInternetExplorer } from "@/utils/browser";

const fillParent = `
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  ${fillParent}
`;

const DocumentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  overflow-y: auto;

  border: 0;
`;

type Props = {
  viewWidth: number;
  maxScaleWidth: number;
  printing?: boolean;
  onReady?: (container: React.RefObject<HTMLDivElement>) => void;
};

type State = {
  width: number;
  height: number;
};

export default class ScaledFrame extends React.Component<Props> {
  private container: React.RefObject<HTMLDivElement>;

  state: State = {
    width: 600,
    height: 1000,
  };

  constructor(props: Props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);
    this.updateSize();
    if (this.props.onReady) {
      this.props.onReady(this.container);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize);
  }

  updateSize = () => {
    const container = this.container.current;
    if (container !== null) {
      const width = container.offsetWidth;
      const height = container.offsetHeight;
      const newState: Partial<State> = {};
      if (width !== this.state.width) {
        newState.width = width;
      }
      if (height !== this.state.height) {
        newState.height = height;
      }
      this.setState(newState);
    }
  };

  getScaledStyle = () => {
    // Build the style object for the container element that shows the documents.
    // This component scales the content with CSS transform.

    const { width, height } = this.state;
    const { viewWidth, maxScaleWidth } = this.props;
    let virtualWidth = viewWidth;
    if (width > maxScaleWidth) {
      virtualWidth = viewWidth * (width / maxScaleWidth);
    }

    const scale = width / virtualWidth;

    return isInternetExplorer()
      ? {
          transform: `scale(${scale})`,
          transformOrigin: `0 0`,
          width: `${virtualWidth}px`,
          height: `${virtualWidth * (height / width)}px`,
        }
      : {
          transform: `scale(${scale})`,
          transformOrigin: `0 0`,
          MozTransform: `scale(${scale})`,
          MozTransformOrigin: `0 0`,
          OTransform: `scale(${scale})`,
          OTransformOrigin: `0 0`,
          WebkitTransform: `scale(${scale})`,
          WebkitTransformOrigin: `0 0`,
          width: `${virtualWidth}px`,
          height: `${virtualWidth * (height / width)}px`,
        };
  };

  render() {
    const printStyle = {
      position: "fixed",
      left: "101vw",
    };
    return (
      <Container ref={this.container}>
        {this.props.printing ? <LoadingCircle /> : <></>}
        <DocumentContainer style={(this.props.printing ? printStyle : this.getScaledStyle()) as CSSProperties}>
          {this.props.children}
        </DocumentContainer>
      </Container>
    );
  }
}
