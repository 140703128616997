import React from "react";
import { useDispatch } from "react-redux";
import BorderSelect from "@/components/posts/common/BorderSelect ";
import { setSearchCategory } from "@/redux/logs/actions";
import { SearchCategoriesType } from "@/redux/logs/types";

interface ReportLogCategorySelectProps {}

export default function ReportLogCategorySelect(props: ReportLogCategorySelectProps) {
  const dispatch = useDispatch();

  const changeSearchCategory = (category: SearchCategoriesType) => {
    dispatch(setSearchCategory(category));
  };

  const searchCategoryList = [
    {
      title: "부동산",
      key: "normal",
      handleClick: changeSearchCategory,
    },
    {
      title: "법인",
      key: "corporation",
      handleClick: changeSearchCategory,
    },
  ];

  return <BorderSelect options={searchCategoryList} defaultOption={searchCategoryList[0]} />;
}
