import React, { useEffect } from "react";
import { SubscriptionType } from "@doczip/api-client";
import { Modal } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { SubscribeTerms } from "./PaymentTerms";
import Checkbox, { CheckboxFormControlLabel } from "@/components/Checkbox";
import { Button } from "@/components/Mui";
import Select from "@/components/Select";
import { PaymentMethodLabel } from "@/components/users/transaction/UserNewTansactionLayout";
import IamportLoader from "@/containers/IamportLoader";
import useUserData from "@/hooks/useUserData";
import { PaymentMethod } from "@/interfaces/paymentMethod";
import { getSubscribeFetchState } from "@/redux/subscription/selectors";
import { subscribeThunk } from "@/redux/subscription/thunk";
import { fetchUserMeThunk } from "@/redux/user/thunk";
import { getUrl } from "@/utils/routes";

const OuterContaienr = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: calc(100vw - 50px);
  height: 100%;
  max-height: calc(100vh - 50px);

  transform: translate(-50%, -50%);
`;

const InnerContainer = styled.form`
  width: 100%;
  max-width: 540px;
  max-height: 100%;
  padding-top: 24px;
  padding-right: 20px;
  padding-bottom: 26px;
  padding-left: 20px;
  overflow-y: auto;

  background-color: #fff;
`;

const Title = styled.div`
  margin-bottom: 8px;

  color: #333;
  font-weight: 600;
  font-size: 16px;
`;

const Description = styled.div`
  margin-bottom: 16px;

  color: #797979;
  font-weight: 400;
  font-size: 13px;
`;

const HighlightPhrase = styled.span`
  font-weight: bold;
`;

const PriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;

  border: 1px solid #ff8f17;
`;

const Price = styled.div`
  color: #333;
  font-weight: 700;
  font-size: 14px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;

  background-color: #dfe2e7;
`;

const SubTitle = styled.div`
  margin-bottom: 8px;

  color: #333;
  font-weight: 700;
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 32px;
`;

const CloseButton = styled(Button)`
  &.MuiButtonBase-root {
    color: #797979;

    background-color: #f2f2f2;
  }
`;

const StyledSubscribeTerms = styled(SubscribeTerms)`
  margin-top: 32px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
`;

const PAYMENT_METHOD_OPTION_LIST: PaymentMethod[] = ["Card"];

interface SubscribeField {
  method: PaymentMethod;
  subscriptionType: SubscriptionType;
  termsAgree?: boolean;
}

interface SubscribeModalProps {
  open?: boolean;
  onClose?: () => void;
}

const SubscibeText = {
  normal: {
    title: "정기 간편결제 신청하기",
    description: [
      "혜택1. 닥집 보유 등기부에서 법인, 부동산 등기부등본 1일 최대 30건 검색 가능",
      "혜택2. 매달 법인, 부동산 등기부등본 신규 다운로드권 5건 제공",
    ],
    price: "3,900/월",
    acceptButtonText: "결제 진행",
  },
  promotion: {
    title: "무료로 오너스클럽 업그레이드하기",
    description: [
      "혜택1. 1월 31일까지 오너스클럽을 업그레이드 하면 열람권 5건(3900원 상당)을 무료로 지급해드립니다.",
      "혜택2. 소유주 이름 및 주민번호로 보유 부동산 검색하기 기능 제공",
      "혜택3. 여러개의 등기부 등본 한번의 클릭으로 발급 가능",
      "혜택4. 법인 등기부등본 및 부동산 등기부등본 열람권 월 5건 제공",
    ],
    price: "💸 업그레이드 비용은 무료입니다! 💸",
    acceptButtonText: "지금 업그레이드하기",
  },
};

function SubscribeModal({ open = false, onClose }: SubscribeModalProps) {
  const dispatch = useDispatch();
  const { user, isTanker } = useUserData();
  const subscribeFetchState = useSelector(getSubscribeFetchState);
  const navigate = useNavigate();

  useEffect(() => {
    if (subscribeFetchState !== "SUCCESS") {
      return;
    }

    if (onClose) {
      onClose();
    }

    dispatch(fetchUserMeThunk({}));

    navigate(getUrl("userSubscriptions"));
  }, [onClose, subscribeFetchState]);

  const { handleSubmit, values, setFieldValue } = useFormik<SubscribeField>({
    initialValues: {
      method: "Card",
      subscriptionType: SubscriptionType.OwnersclubBasic,
    },
    onSubmit: ({ termsAgree, ...fields }) => {
      if (!termsAgree || !user) {
        return;
      }

      dispatch(
        subscribeThunk({
          ...fields,
          isUpgrade: isTanker,
          user,
        }),
      );
    },
  });

  const handlePaymnetMethodChange = (method: PaymentMethod) => {
    setFieldValue("method", method);
  };

  const handleTermsCheck = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setFieldValue("termsAgree", checked);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <OuterContaienr onClick={onClose}>
        <InnerContainer
          onClick={(event) => {
            event.stopPropagation();
          }}
          onSubmit={handleSubmit}
        >
          <Title>{isTanker ? SubscibeText.promotion.title : SubscibeText.normal.title}</Title>
          <Description>
            {isTanker
              ? SubscibeText.promotion.description.map((item, index) => (
                  <p key={index}>
                    <HighlightPhrase>{item}</HighlightPhrase>
                  </p>
                ))
              : SubscibeText.normal.description.map((item, index) => (
                  <p key={index}>
                    <HighlightPhrase>{item}</HighlightPhrase>
                  </p>
                ))}
          </Description>
          <Description>
            오너스클럽 멤버십 서비스를 월 3,900원 정기결제로 이용하실 수 있습니다. 환불의 경우 마지막 정기결제 후 7일
            이내, 해당 기간동안 등기부 열람을 사용하지 않은 경우 가능합니다.
          </Description>
          <PriceBox>
            <Price>{isTanker ? SubscibeText.promotion.price : SubscibeText.normal.price}</Price>
          </PriceBox>
          <Divider />
          <SubTitle>결제수단 선택</SubTitle>
          <Select
            value={values.method}
            optionList={PAYMENT_METHOD_OPTION_LIST}
            onChange={handlePaymnetMethodChange}
            renderOption={(method, selected) => <PaymentMethodLabel selected={selected} method={method} />}
          />
          <CheckboxFormControlLabel
            style={{ marginTop: "24px" }}
            control={<Checkbox sx={{ marginLeft: "-6px", marginTop: "-10px", marginBottom: "-10px" }} />}
            label="아래 이용 안내 및 결제 진행에 동의합니다."
            checked={values.termsAgree}
            onChange={handleTermsCheck}
          />
          <StyledSubscribeTerms />
          <ButtonGroup>
            <CloseButton onClick={onClose} type="button" sx={{ width: "100px", marginRight: "10px" }}>
              닫기
            </CloseButton>
            <Button type="submit" disabled={!values.termsAgree} sx={{ width: "100%" }}>
              {isTanker ? SubscibeText.promotion.acceptButtonText : SubscibeText.normal.acceptButtonText}
            </Button>
          </ButtonGroup>
        </InnerContainer>
      </OuterContaienr>
    </Modal>
  );
}

export default function SubscribeModalWithIamportLoader(props: SubscribeModalProps) {
  return (
    <IamportLoader>
      <SubscribeModal {...props} />
    </IamportLoader>
  );
}
