import React from "react";
import { ButtonBase, Modal as MuiModal, ModalProps as MuiModalProps } from "@mui/material";
import styled, { css } from "styled-components";

const StyledModal = styled(({ ...props }: MuiModalProps) => <MuiModal {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ModalContainerProps {
  withBorder?: boolean;
  fit?: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
  min-width: 328px;
  min-height: ${({ fit }) => (fit ? 0 : 224)}px;
  max-height: 90vh;
  padding: 24px;

  background-color: #fff;

  > div {
    width: 100%;
  }

  ${({ withBorder }) =>
    withBorder
      ? css`
          border: 1px solid #0000004d;
          border-radius: 5px;
        `
      : css`
          border: none;
        `}
`;

interface AlignProps {
  align?: string;
}

const IconContainer = styled.div<AlignProps>`
  display: flex;
  justify-content: ${({ align }) => align};
`;

const TitleContainer = styled.div<AlignProps>`
  margin: 27px 0 24px;

  text-align: ${({ align }) => align};
`;

const Title = styled.span`
  color: #000;
  font-weight: 700;
  font-size: 18px;
`;

const DescriptionsContainer = styled.div<AlignProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  margin-bottom: 24px;
`;

const Description = styled.span`
  color: #000;
  font-weight: 500;
  font-size: 16px;
`;

const ChildrenContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0 8px;
  justify-content: space-between;
`;

interface ButtonProps {
  buttonType?: "cancel" | "accept";
}

const Button = styled(ButtonBase)<ButtonProps>`
  &.MuiButtonBase-root {
    width: 100%;
    height: 42px;

    ${({ buttonType }) =>
      buttonType === "cancel" &&
      css`
        color: rgba(21, 25, 32, 0.5);
        font-weight: 600;
        font-size: 14px;

        border: 1px solid #56678942;
      `}
    ${({ buttonType }) =>
      buttonType === "accept" &&
      css`
        color: #fff;
        font-weight: 600;
        font-size: 14px;

        background-color: #ff8f17;
        border: none;
      `}
  }
`;

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept?: () => void;
  handleInnerClick?: () => void;
  leadingIcon?: React.ReactNode;
  iconAlign?: "left" | "center" | "right";
  title?: string;
  titleAlign?: React.CSSProperties["textAlign"];
  descriptions?: string[];
  descriptionsAlign?: React.CSSProperties["alignItems"];
  cancelButtonText?: string;
  acceptButtonText?: string;
  hideAcceptButton?: boolean;
  hideCancelButton?: boolean;
  acceptButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  className?: string;
  withBorder?: boolean;
  fit?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  handleClose,
  handleAccept,
  handleInnerClick,
  leadingIcon,
  iconAlign,
  title,
  titleAlign = "left",
  descriptions,
  descriptionsAlign = "flex-start",
  cancelButtonText = "닫기",
  acceptButtonText = "확인",
  hideAcceptButton,
  hideCancelButton,
  acceptButton,
  cancelButton,
  withBorder = false,
  fit = false,
  ...props
}) => {
  const onAcceptButtonClick = () => {
    if (handleAccept) {
      handleAccept();
    }
    handleClose();
  };

  return (
    <StyledModal open={open} onClose={handleClose} {...props}>
      <ModalContainer onClick={handleInnerClick && handleInnerClick} withBorder={withBorder} fit={fit}>
        {leadingIcon && <IconContainer align={iconAlign}>{leadingIcon}</IconContainer>}
        {title && (
          <TitleContainer align={titleAlign}>
            <Title>{title}</Title>
          </TitleContainer>
        )}
        {descriptions?.length && (
          <DescriptionsContainer align={descriptionsAlign}>
            {descriptions.map((description, index) => (
              <Description key={index}>{description}</Description>
            ))}
          </DescriptionsContainer>
        )}
        {children && <ChildrenContainer>{children}</ChildrenContainer>}
        <ButtonContainer>
          {!hideCancelButton &&
            (cancelButton ?? (
              <Button buttonType="cancel" onClick={handleClose}>
                {cancelButtonText}
              </Button>
            ))}
          {!hideAcceptButton &&
            (acceptButton ?? (
              <Button buttonType="accept" onClick={onAcceptButtonClick}>
                {acceptButtonText}
              </Button>
            ))}
        </ButtonContainer>
      </ModalContainer>
    </StyledModal>
  );
};

export default Modal;
