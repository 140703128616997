import { SaleLimitedResponse, SaleResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { SalesState } from "./types";
import { makeAsyncStateResetReducer, makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState, getTypedAsyncListInitialState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const getAsyncListInitialState = getTypedAsyncListInitialState<SalesState>();
const makeAsyncReducer = makeTypedAsyncReducer<SalesState>();

const initialState: SalesState = {
  ...getAsyncListInitialState("limitedSaleList"),
  ...getAsyncListInitialState("sale"),
  deleteSaleFetchState: "READY",
  createSaleFetchState: "READY",
  updateSaleFetchState: "READY",
};

export const SALES_STATE_KEY_LIST = getKeyListFromState(initialState);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"limitedSaleList", void, ListFetchSuccessPayload<SaleLimitedResponse>>("limitedSaleList", {
      useListSuccessReducer: true,
      successReducer: (_state, { payload: { list, nextPage, totalPage, totalCount } }) => {
        _state.limitedSaleList = [...(_state.limitedSaleList ?? []), ...list];
        _state.limitedSaleListFetchState = "SUCCESS";
        _state.limitedSaleListPage = nextPage;
        _state.limitedSaleListTotalPage = totalPage;
        _state.limitedSaleListTotalCount = totalCount;
      },
    }),
    resetLimitedSaleListState: makeAsyncStateResetReducer(initialState, "limitedSaleList", { isList: true }),
    ...makeAsyncReducer<"sale", void, SaleResponse>("sale"),
    resetSaleState: (state) => ({
      ...state,
      sale: initialState.sale,
      saleFetchState: initialState.saleFetchState,
    }),
    ...makeAsyncReducer("deleteSale"),
    ...makeAsyncReducer("createSale"),
    ...makeAsyncReducer("updateSale"),
  },
});

export const salesReducer = salesSlice.reducer;
