import React from "react";
import styled from "styled-components";

interface SubTitleProps {
  collapse?: boolean;
}

const HomeTitleContainer = styled.div<SubTitleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 252.8px;

  & div {
    text-align: ${({ collapse }) => (collapse ? "left" : "center")};
  }
`;
const SubTitle = styled.div<SubTitleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 60px;

  color: #797979;

  transition: 500ms;

  & div {
    font-weight: ${({ collapse }) => (collapse ? 500 : 400)};
    font-size: 13px;
  }
`;
const SubTitleRow = styled.div`
  font-weight: 700;
`;

interface HomeTitleProps {
  className?: string;
  collapse?: boolean;
}

const HomeTitle: React.FC<HomeTitleProps> = ({ className, collapse }) => {
  return (
    <HomeTitleContainer className={className} collapse={collapse}>
      <SubTitle collapse={collapse}>
        {collapse ? (
          <>
            <SubTitleRow>대상 물건의 주소를 입력해주세요.</SubTitleRow>
          </>
        ) : (
          <>
            <SubTitleRow>주소 입력 한 번으로 소유주 정보,</SubTitleRow>
            <SubTitleRow>대출 정보, 불법건축물 여부를 알아보자!</SubTitleRow>
          </>
        )}
      </SubTitle>
    </HomeTitleContainer>
  );
};

export default HomeTitle;
