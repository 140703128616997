import React from "react";
import { DocumentResponse, ResidentialManualResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Bracket from "@/components/paper/Bracket";
import CheckboxGroup from "@/components/paper/CheckboxGroup";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const HeaderView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <>
      <Box font="header">
        ⬛ 공인중개사법 시행규칙 [별지 제20호서식] <Box inline color="blue">{`<개정 2021. 12. 31.>`}</Box>
      </Box>
      <Box font="title" align="center">
        중개대상물 확인 · 설명서[Ⅰ]
        <Box inline font="smallTitle">
          (주거용 건축물)
        </Box>
      </Box>
      <Box font="subtitle" align="center" bmargin={30}>
        <Bracket round>
          <Box inlineBlock hpadding="normal">
            <EnumCheckboxGroup
              value={body.is_complex_housing}
              checkboxes={[
                ["단독주택", false],
                ["공동주택", true],
              ]}
            />
            <Space />
            <EnumCheckboxGroup
              value={body.is_trade}
              checkboxes={[
                ["매매 · 교환", true],
                ["임대", false],
              ]}
            />
          </Box>
        </Bracket>
      </Box>

      <Table bmargin={15} columnSizes={[160, 150]}>
        <Table row height={55}>
          <Box td rowSpan={2} font="label">
            확인 · 설명 <br />
            자료
          </Box>
          <Box td font="label">
            확인 · 설명 <br />
            근거자료 등
          </Box>
          <Box td align="left" hpadding="normal">
            <CheckboxGroup
              checkboxes={[
                ["등기권리증", body.is_material_title_deed],
                ["등기사항증명서", body.is_material_registration],
                ["토지대장", body.is_material_land_ledger],
                ["건축물대장", body.is_material_building_ledger],
                ["지적도", body.is_material_cadastral_map],
              ]}
            />
            <br />
            <CheckboxGroup
              checkboxes={[
                ["임야도", body.is_material_forest_and_field_map],
                ["토지이용계획확인서", body.is_material_land_use_plan],
                ["그 밖의 자료", body.is_material_others],
              ]}
              detailValue={body.material_others_detail}
            />
          </Box>
        </Table>
        <Table row height={70}>
          <Box td font="label">
            대상물건의 <br />
            상태에 관한 <br />
            자료요구 사항
          </Box>
          <Box td align="left" hpadding="small" pre>
            {body.request_for_verification}
          </Box>
        </Table>
      </Table>

      <Table bmargin={10} vborder="heavy" columnSizes={[160]}>
        <Table row height={35}>
          <Box td colSpan={2} font="label" backgroundColor="gray">
            유의사항
          </Box>
        </Table>
        <Table row height={55}>
          <Box td font="label">
            개업공인중개사의 <br />
            확인 · 설명 의무
          </Box>
          <Box td align="left" hpadding="small">
            개업공인중개사는 중개대상물에 관한 권리를 취득하려는 중개의뢰인에게 성실·정확하게 설명하고, 토지대장 등본,
            등기사항증명서 등 설명의 근거자료를 제시해야 합니다.
          </Box>
        </Table>
        <Table row height={55}>
          <Box td font="label">
            실제 거래가격 <br />
            신고
          </Box>
          <Box td align="left" hpadding="small">
            「부동산 거래신고 등에 관한 법률」 제3조 및 같은 법 시행령 별표 1 제1호마목에 따른 실제 거래가격은 매수인이
            매수한 부동산을 양도하는 경우 「소득세법」 제97조제1항 및 제7항과 같은 법 시행령 제163조제11항제2호에 따라
            취득 당시의 실제 거래가액으로 보아 양도차익이 계산될 수 있음을 유의하시기 바랍니다.
          </Box>
        </Table>
      </Table>
    </>
  );
};

export default HeaderView;
