import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoResult from "@/components/NoResult";
import { ReportLogList } from "@/components/ReportLog";
import TitledContainer from "@/components/TitledContainer";
import UserCommonLayout from "@/components/users/UserCommonLayout";
import { resetReportLogListState, resetReportLogTrashListState, resetReportActionState } from "@/redux/logs/actions";
import { getReportLogTrashListState, getRestoreReportLogFetchState } from "@/redux/logs/selectors";
import { fetchMoreReportLogTrashListThunk } from "@/redux/logs/thunk";

interface UserReportTrashListContainerProps {}

export default function UserReportTrashListContainer(props: UserReportTrashListContainerProps) {
  const {
    value: reportLogTrashList,
    fetchState: reportLogTrashListFetchState,
    page: reportLogTrashListPage,
    totalPage: reportLogTrashListTotalPage,
  } = useSelector(getReportLogTrashListState);
  const restoreReportLogFetchState = useSelector(getRestoreReportLogFetchState);

  const dispatch = useDispatch();

  const handleLoadMoreReportLogList = useCallback(() => {
    if (reportLogTrashListFetchState === "FETCHING") {
      return;
    }

    dispatch(fetchMoreReportLogTrashListThunk());
  }, [reportLogTrashListFetchState]);

  const handleResetAndLoadReportLogList = () => {
    dispatch(resetReportLogTrashListState());
    dispatch(resetReportActionState());

    if (reportLogTrashListFetchState === "FETCHING") {
      return;
    }

    dispatch(resetReportLogListState());
    dispatch(fetchMoreReportLogTrashListThunk());
  };

  useEffect(() => {
    handleResetAndLoadReportLogList();
  }, []);

  useEffect(() => {
    if (restoreReportLogFetchState !== "SUCCESS") {
      return;
    }

    handleResetAndLoadReportLogList();
  }, [restoreReportLogFetchState]);

  return (
    <UserCommonLayout>
      <TitledContainer title="삭제내역">
        <ReportLogList
          list={reportLogTrashList}
          fetchState={reportLogTrashListFetchState}
          page={reportLogTrashListPage}
          totalPage={reportLogTrashListTotalPage}
          onLoadMore={handleLoadMoreReportLogList}
          disableInitialize
          reportLogProps={{ isDeleted: true }}
          noResult={<NoResult description="삭제 내역이 없습니다." />}
        />
      </TitledContainer>
    </UserCommonLayout>
  );
}
