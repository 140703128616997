import { useState } from "react";
import { CreateSaleRequest, SaleImageSetResponse, TradeType } from "@doczip/api-client";
import { useFormik } from "formik";
import { removeHTMLTagInString } from "@/utils/sales";

export interface SaleNewFormValues {
  [index: string]: any;
  title?: string;
  report_id: number | null;
  trade_type: TradeType;
  price: number;
  rent_price?: number;
  room_count?: number;
  bathroom_count?: number;
  monthly_management_fee?: number;
  available_for_immediate_move_in: boolean;
  permission_date?: string;
  construct_date?: string;
  completion_certificate_date?: string;
  supply_area?: number;
  exclusive_area?: number;
  floor?: number;
  total_floor_count?: number;
  descriptions: string;
  contact_phone_number: string;
  image_set_ids: number[];
}

type SaleNewFormErrors = Partial<Record<keyof SaleNewFormValues, string>>;

const validate = async (values: SaleNewFormValues) => {
  const errors: SaleNewFormErrors = {};
  if (!values.report_id) {
    errors.report_id = "주소를 반드시 선택해야 합니다.";
  }

  if (values.price === 0) {
    errors.price = "가격 정보를 입력해주셔야 합니다.";
  }

  if (values.trade_type === TradeType.Rent) {
    if (!values.rent_price || values.rent_price <= 0) {
      errors.rent_price = "월세 가격 정보를 입력해주셔야 합니다.";
    }
  }

  if (removeHTMLTagInString(values.descriptions).trim().length < 5) {
    errors.descriptions = "설명란에 최소 5글자 이상 적어주셔야 합니다.";
  }

  return errors;
};

type useSaleProp = {
  initialValues: SaleNewFormValues;
  saveSale: (createSaleRequest: CreateSaleRequest) => void;
};

const useSale = ({ initialValues, saveSale }: useSaleProp) => {
  const [imageSet, setImageSet] = useState<SaleImageSetResponse[]>([]);

  const { values, getFieldProps, setFieldValue, submitForm, handleSubmit, errors, handleBlur, resetForm } =
    useFormik<SaleNewFormValues>({
      initialValues,
      validate,
      onSubmit: () => {
        if (values.report_id) {
          const newValues = {
            report_id: values.report_id,
            trade_type: values.trade_type,
            price: values.price,
            rent_price: values.rent_price,
            available_for_immediate_move_in: values.available_for_immediate_move_in,
            descriptions: values.descriptions,
            contact_phone_number: values.contact_phone_number,
            image_set_ids: imageSet.map((image) => image.id),
          };
          saveSale(newValues);
        }
      },
    });

  return {
    values,
    getFieldProps,
    setFieldValue,
    submitForm,
    handleSubmit,
    errors,
    handleBlur,
    resetForm,
    imageSet,
    setImageSet,
  };
};

export default useSale;
