import React from "react";
import styled from "styled-components";

const Container = styled.div`
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type TitleProps = {
  titleFontSize?: number;
};

const Title = styled.div<TitleProps>`
  display: flex;
  align-items: center;

  color: #333;
  font-weight: 700;
  font-size: ${(props) => (props.titleFontSize ? props.titleFontSize + "px" : "14px")};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ChildrenContainer = styled.div`
  width: 100%;
`;

interface TitledContainerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
  titleFontSize?: number;
}

export default function TitledContainer({ title, actions, titleFontSize, ...props }: TitledContainerProps) {
  return (
    <Container>
      {(title || actions) && (
        <TitleRow>
          <Title titleFontSize={titleFontSize}>{title}</Title>
          <Actions>{actions}</Actions>
        </TitleRow>
      )}
      <ChildrenContainer {...props} />
    </Container>
  );
}
