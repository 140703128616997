"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Vesta API
 * Vesta API
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.ReportApi = exports.ReportApiFactory = exports.ReportApiFp = exports.ReportApiAxiosParamCreator = exports.PostApi = exports.PostApiFactory = exports.PostApiFp = exports.PostApiAxiosParamCreator = exports.MolitApi = exports.MolitApiFactory = exports.MolitApiFp = exports.MolitApiAxiosParamCreator = exports.ListingApi = exports.ListingApiFactory = exports.ListingApiFp = exports.ListingApiAxiosParamCreator = exports.CommonApi = exports.CommonApiFactory = exports.CommonApiFp = exports.CommonApiAxiosParamCreator = exports.ApartmentIndexApi = exports.ApartmentIndexApiFactory = exports.ApartmentIndexApiFp = exports.ApartmentIndexApiAxiosParamCreator = exports.AddressApi = exports.AddressApiFactory = exports.AddressApiFp = exports.AddressApiAxiosParamCreator = exports.SchoolDetailGenderDivisionEnum = exports.RegisterAddressTypeEnum = exports.PostListingDataTradeTypeEnum = exports.PostListingDataProviderEnum = exports.PostListingDataFloorTypeEnum = exports.PostListingDataBuildingTypeEnum = exports.MolitApartmentAddressApartmentTypeEnum = exports.MeProviderEnum = exports.ListingTransportTransportationKindEnum = exports.ListingRequestMolitApartmentApartmentTypeEnum = exports.ListingRequestTradeTypeEnum = exports.ListingRequestRoomCountEnum = exports.ListingRequestFeatureTagsEnum = exports.ListingRequestBathroomCountEnum = exports.ListingRequestApartmentDirectionEnum = exports.ListingTradeTypeEnum = exports.ListingProviderEnum = exports.ListingFloorTypeEnum = exports.ListingFeatureTagsEnum = exports.ListingBuildingTypeEnum = exports.HakgudoDetailSchoolTypeEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var HakgudoDetailSchoolTypeEnum;
(function (HakgudoDetailSchoolTypeEnum) {
    HakgudoDetailSchoolTypeEnum["Elementary"] = "ELEMENTARY";
    HakgudoDetailSchoolTypeEnum["Middle"] = "MIDDLE";
    HakgudoDetailSchoolTypeEnum["High"] = "HIGH";
    HakgudoDetailSchoolTypeEnum["Special"] = "SPECIAL";
    HakgudoDetailSchoolTypeEnum["Other"] = "OTHER";
})(HakgudoDetailSchoolTypeEnum = exports.HakgudoDetailSchoolTypeEnum || (exports.HakgudoDetailSchoolTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingBuildingTypeEnum;
(function (ListingBuildingTypeEnum) {
    ListingBuildingTypeEnum["APARTMENT"] = "APARTMENT";
    ListingBuildingTypeEnum["OFFICETEL"] = "OFFICETEL";
    ListingBuildingTypeEnum["VILLA"] = "VILLA";
})(ListingBuildingTypeEnum = exports.ListingBuildingTypeEnum || (exports.ListingBuildingTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingFeatureTagsEnum;
(function (ListingFeatureTagsEnum) {
    ListingFeatureTagsEnum["ALL_REPAIRED"] = "ALL_REPAIRED";
    ListingFeatureTagsEnum["IMMEDIATE_MOVE_IN"] = "IMMEDIATE_MOVE_IN";
    ListingFeatureTagsEnum["QUICK_SALE"] = "QUICK_SALE";
    ListingFeatureTagsEnum["EXPANDABLE"] = "EXPANDABLE";
    ListingFeatureTagsEnum["RECONSTRUCTION"] = "RECONSTRUCTION";
    ListingFeatureTagsEnum["PARKING_ALIVABLE"] = "PARKING_ALIVABLE";
    ListingFeatureTagsEnum["TOP_FLOOR"] = "TOP_FLOOR";
    ListingFeatureTagsEnum["ROYAL_HO"] = "ROYAL_HO";
    ListingFeatureTagsEnum["FIRST_FLOOR"] = "FIRST_FLOOR";
    ListingFeatureTagsEnum["LOANABLE"] = "LOANABLE";
    ListingFeatureTagsEnum["TENANT_OCCUPIED"] = "TENANT_OCCUPIED";
    ListingFeatureTagsEnum["PETS_ALLOWED"] = "PETS_ALLOWED";
    ListingFeatureTagsEnum["MOVE_IN_POSSIBLE"] = "MOVE_IN_POSSIBLE";
    ListingFeatureTagsEnum["MOVE_IN_CONSULTATION"] = "MOVE_IN_CONSULTATION";
})(ListingFeatureTagsEnum = exports.ListingFeatureTagsEnum || (exports.ListingFeatureTagsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingFloorTypeEnum;
(function (ListingFloorTypeEnum) {
    ListingFloorTypeEnum["HIGH"] = "HIGH";
    ListingFloorTypeEnum["MIDDLE"] = "MIDDLE";
    ListingFloorTypeEnum["LOW"] = "LOW";
})(ListingFloorTypeEnum = exports.ListingFloorTypeEnum || (exports.ListingFloorTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingProviderEnum;
(function (ListingProviderEnum) {
    ListingProviderEnum["NAVER"] = "NAVER";
})(ListingProviderEnum = exports.ListingProviderEnum || (exports.ListingProviderEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingTradeTypeEnum;
(function (ListingTradeTypeEnum) {
    ListingTradeTypeEnum["DEAL"] = "DEAL";
    ListingTradeTypeEnum["TNANT"] = "TNANT";
    ListingTradeTypeEnum["RENT"] = "RENT";
})(ListingTradeTypeEnum = exports.ListingTradeTypeEnum || (exports.ListingTradeTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingRequestApartmentDirectionEnum;
(function (ListingRequestApartmentDirectionEnum) {
    ListingRequestApartmentDirectionEnum["SOUTHWARD"] = "SOUTHWARD";
    ListingRequestApartmentDirectionEnum["SOUTHEASTWARD"] = "SOUTHEASTWARD";
    ListingRequestApartmentDirectionEnum["SOUTHWESTWARD"] = "SOUTHWESTWARD";
    ListingRequestApartmentDirectionEnum["EASTWARD"] = "EASTWARD";
    ListingRequestApartmentDirectionEnum["WESTWARD"] = "WESTWARD";
    ListingRequestApartmentDirectionEnum["NORTHWARD"] = "NORTHWARD";
    ListingRequestApartmentDirectionEnum["NORTHEASTWARD"] = "NORTHEASTWARD";
    ListingRequestApartmentDirectionEnum["NORTHWESTWARD"] = "NORTHWESTWARD";
    ListingRequestApartmentDirectionEnum["UNKNOWN"] = "UNKNOWN";
})(ListingRequestApartmentDirectionEnum = exports.ListingRequestApartmentDirectionEnum || (exports.ListingRequestApartmentDirectionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingRequestBathroomCountEnum;
(function (ListingRequestBathroomCountEnum) {
    ListingRequestBathroomCountEnum["ONE"] = "ONE";
    ListingRequestBathroomCountEnum["TWO"] = "TWO";
    ListingRequestBathroomCountEnum["THREE"] = "THREE";
    ListingRequestBathroomCountEnum["FOUR_OR_MORE"] = "FOUR_OR_MORE";
})(ListingRequestBathroomCountEnum = exports.ListingRequestBathroomCountEnum || (exports.ListingRequestBathroomCountEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingRequestFeatureTagsEnum;
(function (ListingRequestFeatureTagsEnum) {
    ListingRequestFeatureTagsEnum["ALL_REPAIRED"] = "ALL_REPAIRED";
    ListingRequestFeatureTagsEnum["IMMEDIATE_MOVE_IN"] = "IMMEDIATE_MOVE_IN";
    ListingRequestFeatureTagsEnum["QUICK_SALE"] = "QUICK_SALE";
    ListingRequestFeatureTagsEnum["EXPANDABLE"] = "EXPANDABLE";
    ListingRequestFeatureTagsEnum["RECONSTRUCTION"] = "RECONSTRUCTION";
    ListingRequestFeatureTagsEnum["PARKING_ALIVABLE"] = "PARKING_ALIVABLE";
    ListingRequestFeatureTagsEnum["TOP_FLOOR"] = "TOP_FLOOR";
    ListingRequestFeatureTagsEnum["ROYAL_HO"] = "ROYAL_HO";
    ListingRequestFeatureTagsEnum["FIRST_FLOOR"] = "FIRST_FLOOR";
    ListingRequestFeatureTagsEnum["LOANABLE"] = "LOANABLE";
    ListingRequestFeatureTagsEnum["TENANT_OCCUPIED"] = "TENANT_OCCUPIED";
    ListingRequestFeatureTagsEnum["PETS_ALLOWED"] = "PETS_ALLOWED";
    ListingRequestFeatureTagsEnum["MOVE_IN_POSSIBLE"] = "MOVE_IN_POSSIBLE";
    ListingRequestFeatureTagsEnum["MOVE_IN_CONSULTATION"] = "MOVE_IN_CONSULTATION";
})(ListingRequestFeatureTagsEnum = exports.ListingRequestFeatureTagsEnum || (exports.ListingRequestFeatureTagsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingRequestRoomCountEnum;
(function (ListingRequestRoomCountEnum) {
    ListingRequestRoomCountEnum["ONE"] = "ONE";
    ListingRequestRoomCountEnum["TWO"] = "TWO";
    ListingRequestRoomCountEnum["THREE"] = "THREE";
    ListingRequestRoomCountEnum["FOUR_OR_MORE"] = "FOUR_OR_MORE";
})(ListingRequestRoomCountEnum = exports.ListingRequestRoomCountEnum || (exports.ListingRequestRoomCountEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingRequestTradeTypeEnum;
(function (ListingRequestTradeTypeEnum) {
    ListingRequestTradeTypeEnum["DEAL"] = "DEAL";
    ListingRequestTradeTypeEnum["TNANT"] = "TNANT";
    ListingRequestTradeTypeEnum["RENT"] = "RENT";
})(ListingRequestTradeTypeEnum = exports.ListingRequestTradeTypeEnum || (exports.ListingRequestTradeTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingRequestMolitApartmentApartmentTypeEnum;
(function (ListingRequestMolitApartmentApartmentTypeEnum) {
    ListingRequestMolitApartmentApartmentTypeEnum["UNKNOWN"] = "UNKNOWN";
    ListingRequestMolitApartmentApartmentTypeEnum["APARTMENT"] = "APARTMENT";
    ListingRequestMolitApartmentApartmentTypeEnum["OFFICETEL"] = "OFFICETEL";
    ListingRequestMolitApartmentApartmentTypeEnum["TOWN_HOUSE"] = "TOWN_HOUSE";
})(ListingRequestMolitApartmentApartmentTypeEnum = exports.ListingRequestMolitApartmentApartmentTypeEnum || (exports.ListingRequestMolitApartmentApartmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListingTransportTransportationKindEnum;
(function (ListingTransportTransportationKindEnum) {
    ListingTransportTransportationKindEnum["PUBLIC_TRANSPORTATION"] = "PUBLIC_TRANSPORTATION";
    ListingTransportTransportationKindEnum["SUBWAY"] = "SUBWAY";
    ListingTransportTransportationKindEnum["BUS"] = "BUS";
    ListingTransportTransportationKindEnum["CAR"] = "CAR";
})(ListingTransportTransportationKindEnum = exports.ListingTransportTransportationKindEnum || (exports.ListingTransportTransportationKindEnum = {}));
/**
    * @export
    * @enum {string}
    */
var MeProviderEnum;
(function (MeProviderEnum) {
    MeProviderEnum["ZIPZIP"] = "ZIPZIP";
    MeProviderEnum["NAVER"] = "NAVER";
    MeProviderEnum["KAKAO"] = "KAKAO";
})(MeProviderEnum = exports.MeProviderEnum || (exports.MeProviderEnum = {}));
/**
    * @export
    * @enum {string}
    */
var MolitApartmentAddressApartmentTypeEnum;
(function (MolitApartmentAddressApartmentTypeEnum) {
    MolitApartmentAddressApartmentTypeEnum["UNKNOWN"] = "UNKNOWN";
    MolitApartmentAddressApartmentTypeEnum["APARTMENT"] = "APARTMENT";
    MolitApartmentAddressApartmentTypeEnum["OFFICETEL"] = "OFFICETEL";
    MolitApartmentAddressApartmentTypeEnum["TOWN_HOUSE"] = "TOWN_HOUSE";
})(MolitApartmentAddressApartmentTypeEnum = exports.MolitApartmentAddressApartmentTypeEnum || (exports.MolitApartmentAddressApartmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PostListingDataBuildingTypeEnum;
(function (PostListingDataBuildingTypeEnum) {
    PostListingDataBuildingTypeEnum["APARTMENT"] = "APARTMENT";
    PostListingDataBuildingTypeEnum["OFFICETEL"] = "OFFICETEL";
    PostListingDataBuildingTypeEnum["VILLA"] = "VILLA";
})(PostListingDataBuildingTypeEnum = exports.PostListingDataBuildingTypeEnum || (exports.PostListingDataBuildingTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PostListingDataFloorTypeEnum;
(function (PostListingDataFloorTypeEnum) {
    PostListingDataFloorTypeEnum["HIGH"] = "HIGH";
    PostListingDataFloorTypeEnum["MIDDLE"] = "MIDDLE";
    PostListingDataFloorTypeEnum["LOW"] = "LOW";
})(PostListingDataFloorTypeEnum = exports.PostListingDataFloorTypeEnum || (exports.PostListingDataFloorTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PostListingDataProviderEnum;
(function (PostListingDataProviderEnum) {
    PostListingDataProviderEnum["NAVER"] = "NAVER";
})(PostListingDataProviderEnum = exports.PostListingDataProviderEnum || (exports.PostListingDataProviderEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PostListingDataTradeTypeEnum;
(function (PostListingDataTradeTypeEnum) {
    PostListingDataTradeTypeEnum["DEAL"] = "DEAL";
    PostListingDataTradeTypeEnum["TNANT"] = "TNANT";
    PostListingDataTradeTypeEnum["RENT"] = "RENT";
})(PostListingDataTradeTypeEnum = exports.PostListingDataTradeTypeEnum || (exports.PostListingDataTradeTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RegisterAddressTypeEnum;
(function (RegisterAddressTypeEnum) {
    RegisterAddressTypeEnum["ALL"] = "ALL";
    RegisterAddressTypeEnum["BUILDING"] = "BUILDING";
    RegisterAddressTypeEnum["COMPLEX"] = "COMPLEX";
    RegisterAddressTypeEnum["LAND"] = "LAND";
})(RegisterAddressTypeEnum = exports.RegisterAddressTypeEnum || (exports.RegisterAddressTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SchoolDetailGenderDivisionEnum;
(function (SchoolDetailGenderDivisionEnum) {
    SchoolDetailGenderDivisionEnum["Boy"] = "BOY";
    SchoolDetailGenderDivisionEnum["Girl"] = "GIRL";
    SchoolDetailGenderDivisionEnum["Mixed"] = "MIXED";
})(SchoolDetailGenderDivisionEnum = exports.SchoolDetailGenderDivisionEnum || (exports.SchoolDetailGenderDivisionEnum = {}));
/**
 * AddressApi - axios parameter creator
 * @export
 */
exports.AddressApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 국토부 아파트와 전용면적 정보를 검색합니다.
         * @param {string} registerAddress 등기 주소
         * @param {string} registerPinNumber 등기 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressMolitApartmentAddressesIndex: function (registerAddress, registerPinNumber, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'registerAddress' is not null or undefined
                    common_1.assertParamExists('addressMolitApartmentAddressesIndex', 'registerAddress', registerAddress);
                    // verify required parameter 'registerPinNumber' is not null or undefined
                    common_1.assertParamExists('addressMolitApartmentAddressesIndex', 'registerPinNumber', registerPinNumber);
                    localVarPath = "/addresses/molit-apartment";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (registerAddress !== undefined) {
                        localVarQueryParameter['register_address'] = registerAddress;
                    }
                    if (registerPinNumber !== undefined) {
                        localVarQueryParameter['register_pin_number'] = registerPinNumber;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 등기소에서 주소 및 등기고유번호를 검색합니다.
         * @param {string} address 검색할 주소
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressRegisterAddressesIndex: function (address, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'address' is not null or undefined
                    common_1.assertParamExists('addressRegisterAddressesIndex', 'address', address);
                    localVarPath = "/addresses/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (address !== undefined) {
                        localVarQueryParameter['address'] = address;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AddressApi - functional programming interface
 * @export
 */
exports.AddressApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AddressApiAxiosParamCreator(configuration);
    return {
        /**
         * 국토부 아파트와 전용면적 정보를 검색합니다.
         * @param {string} registerAddress 등기 주소
         * @param {string} registerPinNumber 등기 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressMolitApartmentAddressesIndex: function (registerAddress, registerPinNumber, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addressMolitApartmentAddressesIndex(registerAddress, registerPinNumber, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 등기소에서 주소 및 등기고유번호를 검색합니다.
         * @param {string} address 검색할 주소
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressRegisterAddressesIndex: function (address, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addressRegisterAddressesIndex(address, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AddressApi - factory interface
 * @export
 */
exports.AddressApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AddressApiFp(configuration);
    return {
        /**
         * 국토부 아파트와 전용면적 정보를 검색합니다.
         * @param {string} registerAddress 등기 주소
         * @param {string} registerPinNumber 등기 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressMolitApartmentAddressesIndex: function (registerAddress, registerPinNumber, options) {
            return localVarFp.addressMolitApartmentAddressesIndex(registerAddress, registerPinNumber, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 등기소에서 주소 및 등기고유번호를 검색합니다.
         * @param {string} address 검색할 주소
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressRegisterAddressesIndex: function (address, options) {
            return localVarFp.addressRegisterAddressesIndex(address, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
var AddressApi = /** @class */ (function (_super) {
    __extends(AddressApi, _super);
    function AddressApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 국토부 아파트와 전용면적 정보를 검색합니다.
     * @param {string} registerAddress 등기 주소
     * @param {string} registerPinNumber 등기 번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    AddressApi.prototype.addressMolitApartmentAddressesIndex = function (registerAddress, registerPinNumber, options) {
        var _this = this;
        return exports.AddressApiFp(this.configuration).addressMolitApartmentAddressesIndex(registerAddress, registerPinNumber, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 등기소에서 주소 및 등기고유번호를 검색합니다.
     * @param {string} address 검색할 주소
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    AddressApi.prototype.addressRegisterAddressesIndex = function (address, options) {
        var _this = this;
        return exports.AddressApiFp(this.configuration).addressRegisterAddressesIndex(address, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AddressApi;
}(base_1.BaseAPI));
exports.AddressApi = AddressApi;
/**
 * ApartmentIndexApi - axios parameter creator
 * @export
 */
exports.ApartmentIndexApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 아파트 지수의 모든 일자 데이터를 반환합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexChartIndices: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/apartment-index/chart-indices";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 아파트 지수를 주 단위로 반환합니다.
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexIndices: function (nextKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/apartment-index/indices";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 가장 최신의 아파트 지수를 반환합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexLatestIndex: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/apartment-index/latest-index";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ApartmentIndexApi - functional programming interface
 * @export
 */
exports.ApartmentIndexApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ApartmentIndexApiAxiosParamCreator(configuration);
    return {
        /**
         * 아파트 지수의 모든 일자 데이터를 반환합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexChartIndices: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.apartmentIndexApartmentIndexChartIndices(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 아파트 지수를 주 단위로 반환합니다.
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexIndices: function (nextKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.apartmentIndexApartmentIndexIndices(nextKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가장 최신의 아파트 지수를 반환합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexLatestIndex: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.apartmentIndexApartmentIndexLatestIndex(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ApartmentIndexApi - factory interface
 * @export
 */
exports.ApartmentIndexApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ApartmentIndexApiFp(configuration);
    return {
        /**
         * 아파트 지수의 모든 일자 데이터를 반환합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexChartIndices: function (options) {
            return localVarFp.apartmentIndexApartmentIndexChartIndices(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 아파트 지수를 주 단위로 반환합니다.
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexIndices: function (nextKey, options) {
            return localVarFp.apartmentIndexApartmentIndexIndices(nextKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가장 최신의 아파트 지수를 반환합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apartmentIndexApartmentIndexLatestIndex: function (options) {
            return localVarFp.apartmentIndexApartmentIndexLatestIndex(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ApartmentIndexApi - object-oriented interface
 * @export
 * @class ApartmentIndexApi
 * @extends {BaseAPI}
 */
var ApartmentIndexApi = /** @class */ (function (_super) {
    __extends(ApartmentIndexApi, _super);
    function ApartmentIndexApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 아파트 지수의 모든 일자 데이터를 반환합니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentIndexApi
     */
    ApartmentIndexApi.prototype.apartmentIndexApartmentIndexChartIndices = function (options) {
        var _this = this;
        return exports.ApartmentIndexApiFp(this.configuration).apartmentIndexApartmentIndexChartIndices(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 아파트 지수를 주 단위로 반환합니다.
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentIndexApi
     */
    ApartmentIndexApi.prototype.apartmentIndexApartmentIndexIndices = function (nextKey, options) {
        var _this = this;
        return exports.ApartmentIndexApiFp(this.configuration).apartmentIndexApartmentIndexIndices(nextKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가장 최신의 아파트 지수를 반환합니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentIndexApi
     */
    ApartmentIndexApi.prototype.apartmentIndexApartmentIndexLatestIndex = function (options) {
        var _this = this;
        return exports.ApartmentIndexApiFp(this.configuration).apartmentIndexApartmentIndexLatestIndex(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ApartmentIndexApi;
}(base_1.BaseAPI));
exports.ApartmentIndexApi = ApartmentIndexApi;
/**
 * CommonApi - axios parameter creator
 * @export
 */
exports.CommonApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 기본 Index 페이지 입니다
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMain: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 교통정보 도착지 리스트를 제공합니다.
         * @summary TransportDestinationListApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonTransportDestinationsIndex: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/transport-destinations";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 모든 구역의 최신 스탯을 반환합니다.
         * @summary GudongStatListApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gudongGetAllStat: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/gudong-stats";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 구역의 최신 스탯을 반환합니다.
         * @summary GudongStatApi
         * @param {string} regionCode 원하는 region의 code값
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gudongGetStat: function (regionCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'regionCode' is not null or undefined
                    common_1.assertParamExists('gudongGetStat', 'regionCode', regionCode);
                    localVarPath = "/gudong-stats/{region_code}"
                        .replace("{" + "region_code" + "}", encodeURIComponent(String(regionCode)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 지역 리스트를 반환합니다.
         * @summary LevelOneRegionListApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionLevelOneRegionsIndex: function (serviceName, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'serviceName' is not null or undefined
                    common_1.assertParamExists('regionLevelOneRegionsIndex', 'serviceName', serviceName);
                    localVarPath = "/level-one-regions";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (serviceName !== undefined) {
                        localVarQueryParameter['service_name'] = serviceName;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 레벨1 지역 코드 하위의 지역 리스트를 반환합니다.
         * @summary LevelTwoRegionListApi
         * @param {string} levelOneRegionCode 레벨1 지역 코드
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionLevelTwoRegionsIndex: function (levelOneRegionCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'levelOneRegionCode' is not null or undefined
                    common_1.assertParamExists('regionLevelTwoRegionsIndex', 'levelOneRegionCode', levelOneRegionCode);
                    localVarPath = "/level-one-regions/{level_one_region_code}/level-two-regions"
                        .replace("{" + "level_one_region_code" + "}", encodeURIComponent(String(levelOneRegionCode)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CommonApi - functional programming interface
 * @export
 */
exports.CommonApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CommonApiAxiosParamCreator(configuration);
    return {
        /**
         * 기본 Index 페이지 입니다
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMain: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.commonMain(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 교통정보 도착지 리스트를 제공합니다.
         * @summary TransportDestinationListApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonTransportDestinationsIndex: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.commonTransportDestinationsIndex(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 모든 구역의 최신 스탯을 반환합니다.
         * @summary GudongStatListApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gudongGetAllStat: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gudongGetAllStat(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구역의 최신 스탯을 반환합니다.
         * @summary GudongStatApi
         * @param {string} regionCode 원하는 region의 code값
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gudongGetStat: function (regionCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gudongGetStat(regionCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 지역 리스트를 반환합니다.
         * @summary LevelOneRegionListApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionLevelOneRegionsIndex: function (serviceName, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.regionLevelOneRegionsIndex(serviceName, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 레벨1 지역 코드 하위의 지역 리스트를 반환합니다.
         * @summary LevelTwoRegionListApi
         * @param {string} levelOneRegionCode 레벨1 지역 코드
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionLevelTwoRegionsIndex: function (levelOneRegionCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.regionLevelTwoRegionsIndex(levelOneRegionCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CommonApi - factory interface
 * @export
 */
exports.CommonApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CommonApiFp(configuration);
    return {
        /**
         * 기본 Index 페이지 입니다
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMain: function (options) {
            return localVarFp.commonMain(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 교통정보 도착지 리스트를 제공합니다.
         * @summary TransportDestinationListApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonTransportDestinationsIndex: function (options) {
            return localVarFp.commonTransportDestinationsIndex(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 모든 구역의 최신 스탯을 반환합니다.
         * @summary GudongStatListApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gudongGetAllStat: function (options) {
            return localVarFp.gudongGetAllStat(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구역의 최신 스탯을 반환합니다.
         * @summary GudongStatApi
         * @param {string} regionCode 원하는 region의 code값
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gudongGetStat: function (regionCode, options) {
            return localVarFp.gudongGetStat(regionCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 지역 리스트를 반환합니다.
         * @summary LevelOneRegionListApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionLevelOneRegionsIndex: function (serviceName, options) {
            return localVarFp.regionLevelOneRegionsIndex(serviceName, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 레벨1 지역 코드 하위의 지역 리스트를 반환합니다.
         * @summary LevelTwoRegionListApi
         * @param {string} levelOneRegionCode 레벨1 지역 코드
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionLevelTwoRegionsIndex: function (levelOneRegionCode, options) {
            return localVarFp.regionLevelTwoRegionsIndex(levelOneRegionCode, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
var CommonApi = /** @class */ (function (_super) {
    __extends(CommonApi, _super);
    function CommonApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 기본 Index 페이지 입니다
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.commonMain = function (options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).commonMain(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 교통정보 도착지 리스트를 제공합니다.
     * @summary TransportDestinationListApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.commonTransportDestinationsIndex = function (options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).commonTransportDestinationsIndex(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 모든 구역의 최신 스탯을 반환합니다.
     * @summary GudongStatListApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.gudongGetAllStat = function (options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).gudongGetAllStat(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구역의 최신 스탯을 반환합니다.
     * @summary GudongStatApi
     * @param {string} regionCode 원하는 region의 code값
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.gudongGetStat = function (regionCode, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).gudongGetStat(regionCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 지역 리스트를 반환합니다.
     * @summary LevelOneRegionListApi
     * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.regionLevelOneRegionsIndex = function (serviceName, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).regionLevelOneRegionsIndex(serviceName, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 레벨1 지역 코드 하위의 지역 리스트를 반환합니다.
     * @summary LevelTwoRegionListApi
     * @param {string} levelOneRegionCode 레벨1 지역 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.regionLevelTwoRegionsIndex = function (levelOneRegionCode, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).regionLevelTwoRegionsIndex(levelOneRegionCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CommonApi;
}(base_1.BaseAPI));
exports.CommonApi = CommonApi;
/**
 * ListingApi - axios parameter creator
 * @export
 */
exports.ListingApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 매물 상담요청을 등록합니다.
         * @summary ListingConsultationRequestCreateApi
         * @param {string} tradeType 거래 종류
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingConsultationRequestsCreate: function (tradeType, requestDetail, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tradeType' is not null or undefined
                    common_1.assertParamExists('listingListingConsultationRequestsCreate', 'tradeType', tradeType);
                    localVarPath = "/listing-consultation-requests";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                    if (tradeType !== undefined) {
                        localVarFormParams.append('trade_type', tradeType);
                    }
                    if (requestDetail !== undefined) {
                        localVarFormParams.append('request_detail', requestDetail);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 매물 그룹의 내의 매물 리스트를 제공합니다.
         * @summary ListingGroupListingListApi
         * @param {number} listingGroupId Listing Group의 ID
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingGroupListingsIndex: function (listingGroupId, tradeType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingGroupId' is not null or undefined
                    common_1.assertParamExists('listingListingGroupListingsIndex', 'listingGroupId', listingGroupId);
                    // verify required parameter 'tradeType' is not null or undefined
                    common_1.assertParamExists('listingListingGroupListingsIndex', 'tradeType', tradeType);
                    localVarPath = "/listing-groups/{listing_group_id}/listings"
                        .replace("{" + "listing_group_id" + "}", encodeURIComponent(String(listingGroupId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (tradeType !== undefined) {
                        localVarQueryParameter['trade_type'] = tradeType;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 매물 그룹의 리스트를 제공합니다.  --- **Note**  Q) 왜 가격 범위는 [이상, 미만)이 아니라 (초과, 이하] 인가요?  범위 쿼리에 대한 구현은 [이상, 미만)이 일반적입니다. 하지만 가격 정보의 경우, 특정 가격을 포함한 아래 범위를 찾는 경우가 많을 것 같아 (초과, 이하]로 구현합니다.  예를 들면, 15억 초과 아파트에 대한 규제로 인해 15억 이하의 매물만 보고싶은 요구사항 등이 있습니다.  ---
         * @summary ListingGroupListApi
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} buildingType 건물 종류
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
         * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
         * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
         * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
         * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
         * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
         * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
         * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
         * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
         * @param {number} [destinationId] 목적지 ID
         * @param {number} [minDuration] 최소 출근시간 (초과)
         * @param {number} [maxDuration] 최대 출근시간 (이하)
         * @param {number} [minHouseholdCount] 최소 세대수 (이상)
         * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
         * @param {number} [minBuiltYear] 최소 준공년도 (이상)
         * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
         * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
         * @param {Array<'TENANT_OCCUPIED' | 'MOVE_IN_CONSULTATION' | 'MOVE_IN_POSSIBLE'>} [featureTag] 매물 특징
         * @param {number} [minGapPrice] 최소 갭 가격 (이상)
         * @param {number} [maxGapPrice] 최대 갭 가격 (미만)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingGroupsIndex: function (tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tradeType' is not null or undefined
                    common_1.assertParamExists('listingListingGroupsIndex', 'tradeType', tradeType);
                    // verify required parameter 'buildingType' is not null or undefined
                    common_1.assertParamExists('listingListingGroupsIndex', 'buildingType', buildingType);
                    localVarPath = "/listing-groups";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    if (minAskingPrice !== undefined) {
                        localVarQueryParameter['min_asking_price'] = minAskingPrice;
                    }
                    if (maxAskingPrice !== undefined) {
                        localVarQueryParameter['max_asking_price'] = maxAskingPrice;
                    }
                    if (minAskingRentPrice !== undefined) {
                        localVarQueryParameter['min_asking_rent_price'] = minAskingRentPrice;
                    }
                    if (maxAskingRentPrice !== undefined) {
                        localVarQueryParameter['max_asking_rent_price'] = maxAskingRentPrice;
                    }
                    if (minSupplyArea !== undefined) {
                        localVarQueryParameter['min_supply_area'] = minSupplyArea;
                    }
                    if (maxSupplyArea !== undefined) {
                        localVarQueryParameter['max_supply_area'] = maxSupplyArea;
                    }
                    if (levelOneRegionCode) {
                        localVarQueryParameter['level_one_region_code'] = levelOneRegionCode;
                    }
                    if (levelTwoRegionCode) {
                        localVarQueryParameter['level_two_region_code'] = levelTwoRegionCode;
                    }
                    if (transportationKind !== undefined) {
                        localVarQueryParameter['transportation_kind'] = transportationKind;
                    }
                    if (destinationId !== undefined) {
                        localVarQueryParameter['destination_id'] = destinationId;
                    }
                    if (minDuration !== undefined) {
                        localVarQueryParameter['min_duration'] = minDuration;
                    }
                    if (maxDuration !== undefined) {
                        localVarQueryParameter['max_duration'] = maxDuration;
                    }
                    if (tradeType !== undefined) {
                        localVarQueryParameter['trade_type'] = tradeType;
                    }
                    if (buildingType) {
                        localVarQueryParameter['building_type'] = buildingType;
                    }
                    if (minHouseholdCount !== undefined) {
                        localVarQueryParameter['min_household_count'] = minHouseholdCount;
                    }
                    if (maxHouseholdCount !== undefined) {
                        localVarQueryParameter['max_household_count'] = maxHouseholdCount;
                    }
                    if (minBuiltYear !== undefined) {
                        localVarQueryParameter['min_built_year'] = minBuiltYear;
                    }
                    if (maxBuiltYear !== undefined) {
                        localVarQueryParameter['max_built_year'] = maxBuiltYear;
                    }
                    if (floorType) {
                        localVarQueryParameter['floor_type'] = floorType;
                    }
                    if (featureTag) {
                        localVarQueryParameter['feature_tag'] = featureTag;
                    }
                    if (minGapPrice !== undefined) {
                        localVarQueryParameter['min_gap_price'] = minGapPrice;
                    }
                    if (maxGapPrice !== undefined) {
                        localVarQueryParameter['max_gap_price'] = maxGapPrice;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * `listing_id`에 해당하는구문을 제공합니다.
         * @summary ListingPhraseListApi
         * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingParsePhrase: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingParsePhrase', 'listingId', listingId);
                    localVarPath = "/listings/{listing_id}/listing-phrases"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 요청 매물을 등록합니다.
         * @summary ListingRequestCreateApi
         * @param {string} registerAddress 등기주소
         * @param {string} tradeType 거래 종류
         * @param {number} askingPrice 희망 가격
         * @param {string} [molitApartmentKindUuid] 국토부 아파트 전용면적 UUID
         * @param {number} [floor] 층
         * @param {number} [askingRentPrice] 희망 월세 가격
         * @param {string} [apartmentDirection] 아파트 방향
         * @param {Array<string>} [featureTags] 매물특징 Tag
         * @param {string} [roomCount] 방 개수
         * @param {string} [bathroomCount] 욕실 개수
         * @param {string} [description] 상세설명
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {Array<any>} [images]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsCreate: function (registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'registerAddress' is not null or undefined
                    common_1.assertParamExists('listingListingRequestsCreate', 'registerAddress', registerAddress);
                    // verify required parameter 'tradeType' is not null or undefined
                    common_1.assertParamExists('listingListingRequestsCreate', 'tradeType', tradeType);
                    // verify required parameter 'askingPrice' is not null or undefined
                    common_1.assertParamExists('listingListingRequestsCreate', 'askingPrice', askingPrice);
                    localVarPath = "/listing-requests";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                    if (registerAddress !== undefined) {
                        localVarFormParams.append('register_address', registerAddress);
                    }
                    if (molitApartmentKindUuid !== undefined) {
                        localVarFormParams.append('molit_apartment_kind_uuid', new Blob([JSON.stringify(molitApartmentKindUuid)], { type: "application/json", }));
                    }
                    if (tradeType !== undefined) {
                        localVarFormParams.append('trade_type', tradeType);
                    }
                    if (floor !== undefined) {
                        localVarFormParams.append('floor', floor);
                    }
                    if (askingPrice !== undefined) {
                        localVarFormParams.append('asking_price', askingPrice);
                    }
                    if (askingRentPrice !== undefined) {
                        localVarFormParams.append('asking_rent_price', askingRentPrice);
                    }
                    if (apartmentDirection !== undefined) {
                        localVarFormParams.append('apartment_direction', apartmentDirection);
                    }
                    if (featureTags) {
                        localVarFormParams.append('feature_tags', featureTags.join(base_1.COLLECTION_FORMATS.csv));
                    }
                    if (roomCount !== undefined) {
                        localVarFormParams.append('room_count', roomCount);
                    }
                    if (bathroomCount !== undefined) {
                        localVarFormParams.append('bathroom_count', bathroomCount);
                    }
                    if (description !== undefined) {
                        localVarFormParams.append('description', description);
                    }
                    if (requestDetail !== undefined) {
                        localVarFormParams.append('request_detail', requestDetail);
                    }
                    if (images) {
                        images.forEach(function (element) {
                            localVarFormParams.append('images', element);
                        });
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 요청 매물을 삭제합니다.
         * @summary ListingRequestDeleteApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsDelete: function (listingRequestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingRequestId' is not null or undefined
                    common_1.assertParamExists('listingListingRequestsDelete', 'listingRequestId', listingRequestId);
                    localVarPath = "/listing-requests/{listing_request_id}"
                        .replace("{" + "listing_request_id" + "}", encodeURIComponent(String(listingRequestId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 요청 매물을 반환합니다.
         * @summary ListingRequestApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsGet: function (listingRequestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingRequestId' is not null or undefined
                    common_1.assertParamExists('listingListingRequestsGet', 'listingRequestId', listingRequestId);
                    localVarPath = "/listing-requests/{listing_request_id}"
                        .replace("{" + "listing_request_id" + "}", encodeURIComponent(String(listingRequestId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 요청 매물 통계를 반환합니다.
         * @summary ListingRequestStatisticsApi
         * @param {string} [startDatetime] 통계 시작 날짜
         * @param {string} [endDatetime] 통계 종료 날짜
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsStatistics: function (startDatetime, endDatetime, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/listing-requests/statistics";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (startDatetime !== undefined) {
                        localVarQueryParameter['start_datetime'] = (startDatetime instanceof Date) ?
                            startDatetime.toISOString() :
                            startDatetime;
                    }
                    if (endDatetime !== undefined) {
                        localVarQueryParameter['end_datetime'] = (endDatetime instanceof Date) ?
                            endDatetime.toISOString() :
                            endDatetime;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 요청 매물을 수정합니다.
         * @summary ListingRequestUpdateApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {string} [tradeType] 거래 종류
         * @param {number} [floor] 층
         * @param {boolean} [deleteFloor] 층 삭제
         * @param {number} [askingPrice] 희망 가격
         * @param {number} [askingRentPrice] 희망 월세 가격
         * @param {string} [apartmentDirection] 아파트 방향
         * @param {boolean} [deleteApartmentDirection] 아파트 방향 삭제
         * @param {Array<string>} [featureTags] 매물특징 Tag
         * @param {boolean} [deleteFeatureTags] 매물특징 Tag 삭제
         * @param {string} [roomCount] 방 개수
         * @param {boolean} [deleteRoomCount] 방 개수 삭제
         * @param {string} [bathroomCount] 욕실 개수
         * @param {boolean} [deleteBathroomCount] 욕실 개수 삭제
         * @param {string} [description] 상세설명
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {Array<any>} [addImages]
         * @param {Array<number>} [deleteImageSetIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsUpdate: function (listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingRequestId' is not null or undefined
                    common_1.assertParamExists('listingListingRequestsUpdate', 'listingRequestId', listingRequestId);
                    localVarPath = "/listing-requests/{listing_request_id}"
                        .replace("{" + "listing_request_id" + "}", encodeURIComponent(String(listingRequestId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                    if (tradeType !== undefined) {
                        localVarFormParams.append('trade_type', tradeType);
                    }
                    if (floor !== undefined) {
                        localVarFormParams.append('floor', floor);
                    }
                    if (deleteFloor !== undefined) {
                        localVarFormParams.append('delete_floor', deleteFloor);
                    }
                    if (askingPrice !== undefined) {
                        localVarFormParams.append('asking_price', askingPrice);
                    }
                    if (askingRentPrice !== undefined) {
                        localVarFormParams.append('asking_rent_price', askingRentPrice);
                    }
                    if (apartmentDirection !== undefined) {
                        localVarFormParams.append('apartment_direction', apartmentDirection);
                    }
                    if (deleteApartmentDirection !== undefined) {
                        localVarFormParams.append('delete_apartment_direction', deleteApartmentDirection);
                    }
                    if (featureTags) {
                        localVarFormParams.append('feature_tags', featureTags.join(base_1.COLLECTION_FORMATS.csv));
                    }
                    if (deleteFeatureTags !== undefined) {
                        localVarFormParams.append('delete_feature_tags', deleteFeatureTags);
                    }
                    if (roomCount !== undefined) {
                        localVarFormParams.append('room_count', roomCount);
                    }
                    if (deleteRoomCount !== undefined) {
                        localVarFormParams.append('delete_room_count', deleteRoomCount);
                    }
                    if (bathroomCount !== undefined) {
                        localVarFormParams.append('bathroom_count', bathroomCount);
                    }
                    if (deleteBathroomCount !== undefined) {
                        localVarFormParams.append('delete_bathroom_count', deleteBathroomCount);
                    }
                    if (description !== undefined) {
                        localVarFormParams.append('description', description);
                    }
                    if (requestDetail !== undefined) {
                        localVarFormParams.append('request_detail', requestDetail);
                    }
                    if (addImages) {
                        addImages.forEach(function (element) {
                            localVarFormParams.append('add_images', element);
                        });
                    }
                    if (deleteImageSetIds) {
                        localVarFormParams.append('delete_image_set_ids', deleteImageSetIds.join(base_1.COLLECTION_FORMATS.csv));
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 b시세 정보의 리스트를 반환합니다.
         * @summary ListingBPriceListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsBpricesIndex: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsBpricesIndex', 'listingId', listingId);
                    localVarPath = "/listings/{listing_id}/bprices"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingDealContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsDealContractsIndex: function (listingId, kind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsDealContractsIndex', 'listingId', listingId);
                    // verify required parameter 'kind' is not null or undefined
                    common_1.assertParamExists('listingListingsDealContractsIndex', 'kind', kind);
                    localVarPath = "/listings/{listing_id}/deal-contracts"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (kind !== undefined) {
                        localVarQueryParameter['kind'] = kind;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 과거 예측가 정보의 리스트를 반환합니다.
         * @summary ListingEstimatedMonthlyPriceListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'HIGH' | 'MIDDLE' | 'LOW'} floorType 층 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsEstimatedMonthlyPrices: function (listingId, floorType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsEstimatedMonthlyPrices', 'listingId', listingId);
                    // verify required parameter 'floorType' is not null or undefined
                    common_1.assertParamExists('listingListingsEstimatedMonthlyPrices', 'floorType', floorType);
                    localVarPath = "/listings/{listing_id}/estimated-monthly-prices"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (floorType !== undefined) {
                        localVarQueryParameter['floor_type'] = floorType;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물을 제공합니다.
         * @summary ListingApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsGet: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsGet', 'listingId', listingId);
                    localVarPath = "/listings/{listing_id}"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * `listing_id`에 해당하는 학구도 정보를 제공합니다.
         * @summary ListingHakgudoApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsHakgudo: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsHakgudo', 'listingId', listingId);
                    localVarPath = "/listings/{listing_id}/hakgudos"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물의 정보를 매물이 등록된 사이트에서 업데이트 합니다.
         * @summary ListingRefreshApi
         * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsRefresh: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsRefresh', 'listingId', listingId);
                    localVarPath = "/listings/{listing_id}/refresh"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingRentContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsRentContractsIndex: function (listingId, kind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsRentContractsIndex', 'listingId', listingId);
                    // verify required parameter 'kind' is not null or undefined
                    common_1.assertParamExists('listingListingsRentContractsIndex', 'kind', kind);
                    localVarPath = "/listings/{listing_id}/rent-contracts"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (kind !== undefined) {
                        localVarQueryParameter['kind'] = kind;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingTnantContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsTnantContractsIndex: function (listingId, kind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('listingListingsTnantContractsIndex', 'listingId', listingId);
                    // verify required parameter 'kind' is not null or undefined
                    common_1.assertParamExists('listingListingsTnantContractsIndex', 'kind', kind);
                    localVarPath = "/listings/{listing_id}/tnant-contracts"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (kind !== undefined) {
                        localVarQueryParameter['kind'] = kind;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * molit apartment kind 내 매물 리스트를 제공합니다.
         * @summary MolitApartmentKindListingListApi
         * @param {number} molitApartmentKindId Molit Apartment Kind의 ID
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingMolitApartmentKindListingsIndex: function (molitApartmentKindId, tradeType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'molitApartmentKindId' is not null or undefined
                    common_1.assertParamExists('listingMolitApartmentKindListingsIndex', 'molitApartmentKindId', molitApartmentKindId);
                    // verify required parameter 'tradeType' is not null or undefined
                    common_1.assertParamExists('listingMolitApartmentKindListingsIndex', 'tradeType', tradeType);
                    localVarPath = "/molit-apartment-kinds/{molit_apartment_kind_id}/listings"
                        .replace("{" + "molit_apartment_kind_id" + "}", encodeURIComponent(String(molitApartmentKindId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (tradeType !== undefined) {
                        localVarQueryParameter['trade_type'] = tradeType;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ListingApi - functional programming interface
 * @export
 */
exports.ListingApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ListingApiAxiosParamCreator(configuration);
    return {
        /**
         * 매물 상담요청을 등록합니다.
         * @summary ListingConsultationRequestCreateApi
         * @param {string} tradeType 거래 종류
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingConsultationRequestsCreate: function (tradeType, requestDetail, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingConsultationRequestsCreate(tradeType, requestDetail, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 매물 그룹의 내의 매물 리스트를 제공합니다.
         * @summary ListingGroupListingListApi
         * @param {number} listingGroupId Listing Group의 ID
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingGroupListingsIndex: function (listingGroupId, tradeType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingGroupListingsIndex(listingGroupId, tradeType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 매물 그룹의 리스트를 제공합니다.  --- **Note**  Q) 왜 가격 범위는 [이상, 미만)이 아니라 (초과, 이하] 인가요?  범위 쿼리에 대한 구현은 [이상, 미만)이 일반적입니다. 하지만 가격 정보의 경우, 특정 가격을 포함한 아래 범위를 찾는 경우가 많을 것 같아 (초과, 이하]로 구현합니다.  예를 들면, 15억 초과 아파트에 대한 규제로 인해 15억 이하의 매물만 보고싶은 요구사항 등이 있습니다.  ---
         * @summary ListingGroupListApi
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} buildingType 건물 종류
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
         * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
         * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
         * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
         * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
         * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
         * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
         * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
         * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
         * @param {number} [destinationId] 목적지 ID
         * @param {number} [minDuration] 최소 출근시간 (초과)
         * @param {number} [maxDuration] 최대 출근시간 (이하)
         * @param {number} [minHouseholdCount] 최소 세대수 (이상)
         * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
         * @param {number} [minBuiltYear] 최소 준공년도 (이상)
         * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
         * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
         * @param {Array<'TENANT_OCCUPIED' | 'MOVE_IN_CONSULTATION' | 'MOVE_IN_POSSIBLE'>} [featureTag] 매물 특징
         * @param {number} [minGapPrice] 최소 갭 가격 (이상)
         * @param {number} [maxGapPrice] 최대 갭 가격 (미만)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingGroupsIndex: function (tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingGroupsIndex(tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * `listing_id`에 해당하는구문을 제공합니다.
         * @summary ListingPhraseListApi
         * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingParsePhrase: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingParsePhrase(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 요청 매물을 등록합니다.
         * @summary ListingRequestCreateApi
         * @param {string} registerAddress 등기주소
         * @param {string} tradeType 거래 종류
         * @param {number} askingPrice 희망 가격
         * @param {string} [molitApartmentKindUuid] 국토부 아파트 전용면적 UUID
         * @param {number} [floor] 층
         * @param {number} [askingRentPrice] 희망 월세 가격
         * @param {string} [apartmentDirection] 아파트 방향
         * @param {Array<string>} [featureTags] 매물특징 Tag
         * @param {string} [roomCount] 방 개수
         * @param {string} [bathroomCount] 욕실 개수
         * @param {string} [description] 상세설명
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {Array<any>} [images]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsCreate: function (registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingRequestsCreate(registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 요청 매물을 삭제합니다.
         * @summary ListingRequestDeleteApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsDelete: function (listingRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingRequestsDelete(listingRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 요청 매물을 반환합니다.
         * @summary ListingRequestApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsGet: function (listingRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingRequestsGet(listingRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 요청 매물 통계를 반환합니다.
         * @summary ListingRequestStatisticsApi
         * @param {string} [startDatetime] 통계 시작 날짜
         * @param {string} [endDatetime] 통계 종료 날짜
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsStatistics: function (startDatetime, endDatetime, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingRequestsStatistics(startDatetime, endDatetime, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 요청 매물을 수정합니다.
         * @summary ListingRequestUpdateApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {string} [tradeType] 거래 종류
         * @param {number} [floor] 층
         * @param {boolean} [deleteFloor] 층 삭제
         * @param {number} [askingPrice] 희망 가격
         * @param {number} [askingRentPrice] 희망 월세 가격
         * @param {string} [apartmentDirection] 아파트 방향
         * @param {boolean} [deleteApartmentDirection] 아파트 방향 삭제
         * @param {Array<string>} [featureTags] 매물특징 Tag
         * @param {boolean} [deleteFeatureTags] 매물특징 Tag 삭제
         * @param {string} [roomCount] 방 개수
         * @param {boolean} [deleteRoomCount] 방 개수 삭제
         * @param {string} [bathroomCount] 욕실 개수
         * @param {boolean} [deleteBathroomCount] 욕실 개수 삭제
         * @param {string} [description] 상세설명
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {Array<any>} [addImages]
         * @param {Array<number>} [deleteImageSetIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsUpdate: function (listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingRequestsUpdate(listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 b시세 정보의 리스트를 반환합니다.
         * @summary ListingBPriceListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsBpricesIndex: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsBpricesIndex(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingDealContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsDealContractsIndex: function (listingId, kind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsDealContractsIndex(listingId, kind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 과거 예측가 정보의 리스트를 반환합니다.
         * @summary ListingEstimatedMonthlyPriceListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'HIGH' | 'MIDDLE' | 'LOW'} floorType 층 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsEstimatedMonthlyPrices: function (listingId, floorType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsEstimatedMonthlyPrices(listingId, floorType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물을 제공합니다.
         * @summary ListingApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * `listing_id`에 해당하는 학구도 정보를 제공합니다.
         * @summary ListingHakgudoApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsHakgudo: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsHakgudo(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물의 정보를 매물이 등록된 사이트에서 업데이트 합니다.
         * @summary ListingRefreshApi
         * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsRefresh: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsRefresh(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingRentContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsRentContractsIndex: function (listingId, kind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsRentContractsIndex(listingId, kind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `listing_id`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingTnantContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsTnantContractsIndex: function (listingId, kind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingListingsTnantContractsIndex(listingId, kind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * molit apartment kind 내 매물 리스트를 제공합니다.
         * @summary MolitApartmentKindListingListApi
         * @param {number} molitApartmentKindId Molit Apartment Kind의 ID
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingMolitApartmentKindListingsIndex: function (molitApartmentKindId, tradeType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listingMolitApartmentKindListingsIndex(molitApartmentKindId, tradeType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ListingApi - factory interface
 * @export
 */
exports.ListingApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ListingApiFp(configuration);
    return {
        /**
         * 매물 상담요청을 등록합니다.
         * @summary ListingConsultationRequestCreateApi
         * @param {string} tradeType 거래 종류
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingConsultationRequestsCreate: function (tradeType, requestDetail, options) {
            return localVarFp.listingListingConsultationRequestsCreate(tradeType, requestDetail, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 매물 그룹의 내의 매물 리스트를 제공합니다.
         * @summary ListingGroupListingListApi
         * @param {number} listingGroupId Listing Group의 ID
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingGroupListingsIndex: function (listingGroupId, tradeType, options) {
            return localVarFp.listingListingGroupListingsIndex(listingGroupId, tradeType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 매물 그룹의 리스트를 제공합니다.  --- **Note**  Q) 왜 가격 범위는 [이상, 미만)이 아니라 (초과, 이하] 인가요?  범위 쿼리에 대한 구현은 [이상, 미만)이 일반적입니다. 하지만 가격 정보의 경우, 특정 가격을 포함한 아래 범위를 찾는 경우가 많을 것 같아 (초과, 이하]로 구현합니다.  예를 들면, 15억 초과 아파트에 대한 규제로 인해 15억 이하의 매물만 보고싶은 요구사항 등이 있습니다.  ---
         * @summary ListingGroupListApi
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} buildingType 건물 종류
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
         * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
         * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
         * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
         * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
         * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
         * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
         * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
         * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
         * @param {number} [destinationId] 목적지 ID
         * @param {number} [minDuration] 최소 출근시간 (초과)
         * @param {number} [maxDuration] 최대 출근시간 (이하)
         * @param {number} [minHouseholdCount] 최소 세대수 (이상)
         * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
         * @param {number} [minBuiltYear] 최소 준공년도 (이상)
         * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
         * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
         * @param {Array<'TENANT_OCCUPIED' | 'MOVE_IN_CONSULTATION' | 'MOVE_IN_POSSIBLE'>} [featureTag] 매물 특징
         * @param {number} [minGapPrice] 최소 갭 가격 (이상)
         * @param {number} [maxGapPrice] 최대 갭 가격 (미만)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingGroupsIndex: function (tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options) {
            return localVarFp.listingListingGroupsIndex(tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * `listing_id`에 해당하는구문을 제공합니다.
         * @summary ListingPhraseListApi
         * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingParsePhrase: function (listingId, options) {
            return localVarFp.listingListingParsePhrase(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 요청 매물을 등록합니다.
         * @summary ListingRequestCreateApi
         * @param {string} registerAddress 등기주소
         * @param {string} tradeType 거래 종류
         * @param {number} askingPrice 희망 가격
         * @param {string} [molitApartmentKindUuid] 국토부 아파트 전용면적 UUID
         * @param {number} [floor] 층
         * @param {number} [askingRentPrice] 희망 월세 가격
         * @param {string} [apartmentDirection] 아파트 방향
         * @param {Array<string>} [featureTags] 매물특징 Tag
         * @param {string} [roomCount] 방 개수
         * @param {string} [bathroomCount] 욕실 개수
         * @param {string} [description] 상세설명
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {Array<any>} [images]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsCreate: function (registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options) {
            return localVarFp.listingListingRequestsCreate(registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 요청 매물을 삭제합니다.
         * @summary ListingRequestDeleteApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsDelete: function (listingRequestId, options) {
            return localVarFp.listingListingRequestsDelete(listingRequestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 요청 매물을 반환합니다.
         * @summary ListingRequestApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsGet: function (listingRequestId, options) {
            return localVarFp.listingListingRequestsGet(listingRequestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 요청 매물 통계를 반환합니다.
         * @summary ListingRequestStatisticsApi
         * @param {string} [startDatetime] 통계 시작 날짜
         * @param {string} [endDatetime] 통계 종료 날짜
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsStatistics: function (startDatetime, endDatetime, options) {
            return localVarFp.listingListingRequestsStatistics(startDatetime, endDatetime, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 요청 매물을 수정합니다.
         * @summary ListingRequestUpdateApi
         * @param {number} listingRequestId 가져올 Listing Request의 ID
         * @param {string} [tradeType] 거래 종류
         * @param {number} [floor] 층
         * @param {boolean} [deleteFloor] 층 삭제
         * @param {number} [askingPrice] 희망 가격
         * @param {number} [askingRentPrice] 희망 월세 가격
         * @param {string} [apartmentDirection] 아파트 방향
         * @param {boolean} [deleteApartmentDirection] 아파트 방향 삭제
         * @param {Array<string>} [featureTags] 매물특징 Tag
         * @param {boolean} [deleteFeatureTags] 매물특징 Tag 삭제
         * @param {string} [roomCount] 방 개수
         * @param {boolean} [deleteRoomCount] 방 개수 삭제
         * @param {string} [bathroomCount] 욕실 개수
         * @param {boolean} [deleteBathroomCount] 욕실 개수 삭제
         * @param {string} [description] 상세설명
         * @param {string} [requestDetail] 중계사 요청사항
         * @param {Array<any>} [addImages]
         * @param {Array<number>} [deleteImageSetIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingRequestsUpdate: function (listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options) {
            return localVarFp.listingListingRequestsUpdate(listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `listing_id`에 해당하는 b시세 정보의 리스트를 반환합니다.
         * @summary ListingBPriceListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsBpricesIndex: function (listingId, options) {
            return localVarFp.listingListingsBpricesIndex(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `listing_id`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingDealContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsDealContractsIndex: function (listingId, kind, options) {
            return localVarFp.listingListingsDealContractsIndex(listingId, kind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `listing_id`에 해당하는 과거 예측가 정보의 리스트를 반환합니다.
         * @summary ListingEstimatedMonthlyPriceListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'HIGH' | 'MIDDLE' | 'LOW'} floorType 층 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsEstimatedMonthlyPrices: function (listingId, floorType, options) {
            return localVarFp.listingListingsEstimatedMonthlyPrices(listingId, floorType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * `listing_id`에 해당하는 매물을 제공합니다.
         * @summary ListingApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsGet: function (listingId, options) {
            return localVarFp.listingListingsGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * `listing_id`에 해당하는 학구도 정보를 제공합니다.
         * @summary ListingHakgudoApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsHakgudo: function (listingId, options) {
            return localVarFp.listingListingsHakgudo(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * `listing_id`에 해당하는 매물의 정보를 매물이 등록된 사이트에서 업데이트 합니다.
         * @summary ListingRefreshApi
         * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsRefresh: function (listingId, options) {
            return localVarFp.listingListingsRefresh(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `listing_id`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingRentContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsRentContractsIndex: function (listingId, kind, options) {
            return localVarFp.listingListingsRentContractsIndex(listingId, kind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `listing_id`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
         * @summary ListingTnantContractListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingListingsTnantContractsIndex: function (listingId, kind, options) {
            return localVarFp.listingListingsTnantContractsIndex(listingId, kind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * molit apartment kind 내 매물 리스트를 제공합니다.
         * @summary MolitApartmentKindListingListApi
         * @param {number} molitApartmentKindId Molit Apartment Kind의 ID
         * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingMolitApartmentKindListingsIndex: function (molitApartmentKindId, tradeType, options) {
            return localVarFp.listingMolitApartmentKindListingsIndex(molitApartmentKindId, tradeType, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ListingApi - object-oriented interface
 * @export
 * @class ListingApi
 * @extends {BaseAPI}
 */
var ListingApi = /** @class */ (function (_super) {
    __extends(ListingApi, _super);
    function ListingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 매물 상담요청을 등록합니다.
     * @summary ListingConsultationRequestCreateApi
     * @param {string} tradeType 거래 종류
     * @param {string} [requestDetail] 중계사 요청사항
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingConsultationRequestsCreate = function (tradeType, requestDetail, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingConsultationRequestsCreate(tradeType, requestDetail, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 매물 그룹의 내의 매물 리스트를 제공합니다.
     * @summary ListingGroupListingListApi
     * @param {number} listingGroupId Listing Group의 ID
     * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingGroupListingsIndex = function (listingGroupId, tradeType, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingGroupListingsIndex(listingGroupId, tradeType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 매물 그룹의 리스트를 제공합니다.  --- **Note**  Q) 왜 가격 범위는 [이상, 미만)이 아니라 (초과, 이하] 인가요?  범위 쿼리에 대한 구현은 [이상, 미만)이 일반적입니다. 하지만 가격 정보의 경우, 특정 가격을 포함한 아래 범위를 찾는 경우가 많을 것 같아 (초과, 이하]로 구현합니다.  예를 들면, 15억 초과 아파트에 대한 규제로 인해 15억 이하의 매물만 보고싶은 요구사항 등이 있습니다.  ---
     * @summary ListingGroupListApi
     * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
     * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} buildingType 건물 종류
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
     * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
     * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
     * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
     * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
     * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
     * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
     * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
     * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
     * @param {number} [destinationId] 목적지 ID
     * @param {number} [minDuration] 최소 출근시간 (초과)
     * @param {number} [maxDuration] 최대 출근시간 (이하)
     * @param {number} [minHouseholdCount] 최소 세대수 (이상)
     * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
     * @param {number} [minBuiltYear] 최소 준공년도 (이상)
     * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
     * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
     * @param {Array<'TENANT_OCCUPIED' | 'MOVE_IN_CONSULTATION' | 'MOVE_IN_POSSIBLE'>} [featureTag] 매물 특징
     * @param {number} [minGapPrice] 최소 갭 가격 (이상)
     * @param {number} [maxGapPrice] 최대 갭 가격 (미만)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingGroupsIndex = function (tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingGroupsIndex(tradeType, buildingType, nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, featureTag, minGapPrice, maxGapPrice, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * `listing_id`에 해당하는구문을 제공합니다.
     * @summary ListingPhraseListApi
     * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingParsePhrase = function (listingId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingParsePhrase(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 요청 매물을 등록합니다.
     * @summary ListingRequestCreateApi
     * @param {string} registerAddress 등기주소
     * @param {string} tradeType 거래 종류
     * @param {number} askingPrice 희망 가격
     * @param {string} [molitApartmentKindUuid] 국토부 아파트 전용면적 UUID
     * @param {number} [floor] 층
     * @param {number} [askingRentPrice] 희망 월세 가격
     * @param {string} [apartmentDirection] 아파트 방향
     * @param {Array<string>} [featureTags] 매물특징 Tag
     * @param {string} [roomCount] 방 개수
     * @param {string} [bathroomCount] 욕실 개수
     * @param {string} [description] 상세설명
     * @param {string} [requestDetail] 중계사 요청사항
     * @param {Array<any>} [images]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingRequestsCreate = function (registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingRequestsCreate(registerAddress, tradeType, askingPrice, molitApartmentKindUuid, floor, askingRentPrice, apartmentDirection, featureTags, roomCount, bathroomCount, description, requestDetail, images, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 요청 매물을 삭제합니다.
     * @summary ListingRequestDeleteApi
     * @param {number} listingRequestId 가져올 Listing Request의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingRequestsDelete = function (listingRequestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingRequestsDelete(listingRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 요청 매물을 반환합니다.
     * @summary ListingRequestApi
     * @param {number} listingRequestId 가져올 Listing Request의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingRequestsGet = function (listingRequestId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingRequestsGet(listingRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 요청 매물 통계를 반환합니다.
     * @summary ListingRequestStatisticsApi
     * @param {string} [startDatetime] 통계 시작 날짜
     * @param {string} [endDatetime] 통계 종료 날짜
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingRequestsStatistics = function (startDatetime, endDatetime, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingRequestsStatistics(startDatetime, endDatetime, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 요청 매물을 수정합니다.
     * @summary ListingRequestUpdateApi
     * @param {number} listingRequestId 가져올 Listing Request의 ID
     * @param {string} [tradeType] 거래 종류
     * @param {number} [floor] 층
     * @param {boolean} [deleteFloor] 층 삭제
     * @param {number} [askingPrice] 희망 가격
     * @param {number} [askingRentPrice] 희망 월세 가격
     * @param {string} [apartmentDirection] 아파트 방향
     * @param {boolean} [deleteApartmentDirection] 아파트 방향 삭제
     * @param {Array<string>} [featureTags] 매물특징 Tag
     * @param {boolean} [deleteFeatureTags] 매물특징 Tag 삭제
     * @param {string} [roomCount] 방 개수
     * @param {boolean} [deleteRoomCount] 방 개수 삭제
     * @param {string} [bathroomCount] 욕실 개수
     * @param {boolean} [deleteBathroomCount] 욕실 개수 삭제
     * @param {string} [description] 상세설명
     * @param {string} [requestDetail] 중계사 요청사항
     * @param {Array<any>} [addImages]
     * @param {Array<number>} [deleteImageSetIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingRequestsUpdate = function (listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingRequestsUpdate(listingRequestId, tradeType, floor, deleteFloor, askingPrice, askingRentPrice, apartmentDirection, deleteApartmentDirection, featureTags, deleteFeatureTags, roomCount, deleteRoomCount, bathroomCount, deleteBathroomCount, description, requestDetail, addImages, deleteImageSetIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `listing_id`에 해당하는 b시세 정보의 리스트를 반환합니다.
     * @summary ListingBPriceListApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsBpricesIndex = function (listingId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsBpricesIndex(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `listing_id`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
     * @summary ListingDealContractListApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsDealContractsIndex = function (listingId, kind, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsDealContractsIndex(listingId, kind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `listing_id`에 해당하는 과거 예측가 정보의 리스트를 반환합니다.
     * @summary ListingEstimatedMonthlyPriceListApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {'HIGH' | 'MIDDLE' | 'LOW'} floorType 층 종류
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsEstimatedMonthlyPrices = function (listingId, floorType, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsEstimatedMonthlyPrices(listingId, floorType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * `listing_id`에 해당하는 매물을 제공합니다.
     * @summary ListingApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsGet = function (listingId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * `listing_id`에 해당하는 학구도 정보를 제공합니다.
     * @summary ListingHakgudoApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsHakgudo = function (listingId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsHakgudo(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * `listing_id`에 해당하는 매물의 정보를 매물이 등록된 사이트에서 업데이트 합니다.
     * @summary ListingRefreshApi
     * @param {number} listingId 가져올 구문에 해당하는 listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsRefresh = function (listingId, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsRefresh(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `listing_id`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
     * @summary ListingRentContractListApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsRentContractsIndex = function (listingId, kind, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsRentContractsIndex(listingId, kind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `listing_id`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
     * @summary ListingTnantContractListApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingListingsTnantContractsIndex = function (listingId, kind, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingListingsTnantContractsIndex(listingId, kind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * molit apartment kind 내 매물 리스트를 제공합니다.
     * @summary MolitApartmentKindListingListApi
     * @param {number} molitApartmentKindId Molit Apartment Kind의 ID
     * @param {'DEAL' | 'TNANT' | 'RENT'} tradeType 거래 종류
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    ListingApi.prototype.listingMolitApartmentKindListingsIndex = function (molitApartmentKindId, tradeType, options) {
        var _this = this;
        return exports.ListingApiFp(this.configuration).listingMolitApartmentKindListingsIndex(molitApartmentKindId, tradeType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ListingApi;
}(base_1.BaseAPI));
exports.ListingApi = ListingApi;
/**
 * MolitApi - axios parameter creator
 * @export
 */
exports.MolitApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitDealContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitDealContractsIndex: function (molitApartmentKindUuid, kind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'molitApartmentKindUuid' is not null or undefined
                    common_1.assertParamExists('molitDealContractsIndex', 'molitApartmentKindUuid', molitApartmentKindUuid);
                    // verify required parameter 'kind' is not null or undefined
                    common_1.assertParamExists('molitDealContractsIndex', 'kind', kind);
                    localVarPath = "/molit/{molit_apartment_kind_uuid}/deal-contracts"
                        .replace("{" + "molit_apartment_kind_uuid" + "}", encodeURIComponent(String(molitApartmentKindUuid)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (kind !== undefined) {
                        localVarQueryParameter['kind'] = kind;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitRentContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitRentContractsIndex: function (molitApartmentKindUuid, kind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'molitApartmentKindUuid' is not null or undefined
                    common_1.assertParamExists('molitRentContractsIndex', 'molitApartmentKindUuid', molitApartmentKindUuid);
                    // verify required parameter 'kind' is not null or undefined
                    common_1.assertParamExists('molitRentContractsIndex', 'kind', kind);
                    localVarPath = "/molit/{molit_apartment_kind_uuid}/rent-contracts"
                        .replace("{" + "molit_apartment_kind_uuid" + "}", encodeURIComponent(String(molitApartmentKindUuid)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (kind !== undefined) {
                        localVarQueryParameter['kind'] = kind;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitTnantContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitTnantContractsIndex: function (molitApartmentKindUuid, kind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'molitApartmentKindUuid' is not null or undefined
                    common_1.assertParamExists('molitTnantContractsIndex', 'molitApartmentKindUuid', molitApartmentKindUuid);
                    // verify required parameter 'kind' is not null or undefined
                    common_1.assertParamExists('molitTnantContractsIndex', 'kind', kind);
                    localVarPath = "/molit/{molit_apartment_kind_uuid}/tnant-contracts"
                        .replace("{" + "molit_apartment_kind_uuid" + "}", encodeURIComponent(String(molitApartmentKindUuid)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (kind !== undefined) {
                        localVarQueryParameter['kind'] = kind;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * MolitApi - functional programming interface
 * @export
 */
exports.MolitApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.MolitApiAxiosParamCreator(configuration);
    return {
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitDealContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitDealContractsIndex: function (molitApartmentKindUuid, kind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.molitDealContractsIndex(molitApartmentKindUuid, kind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitRentContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitRentContractsIndex: function (molitApartmentKindUuid, kind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.molitRentContractsIndex(molitApartmentKindUuid, kind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitTnantContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitTnantContractsIndex: function (molitApartmentKindUuid, kind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.molitTnantContractsIndex(molitApartmentKindUuid, kind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * MolitApi - factory interface
 * @export
 */
exports.MolitApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.MolitApiFp(configuration);
    return {
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitDealContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitDealContractsIndex: function (molitApartmentKindUuid, kind, options) {
            return localVarFp.molitDealContractsIndex(molitApartmentKindUuid, kind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitRentContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitRentContractsIndex: function (molitApartmentKindUuid, kind, options) {
            return localVarFp.molitRentContractsIndex(molitApartmentKindUuid, kind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 `molit_apartment_kind_uuid`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
         * @summary MolitTnantContractListApi
         * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
         * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molitTnantContractsIndex: function (molitApartmentKindUuid, kind, options) {
            return localVarFp.molitTnantContractsIndex(molitApartmentKindUuid, kind, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * MolitApi - object-oriented interface
 * @export
 * @class MolitApi
 * @extends {BaseAPI}
 */
var MolitApi = /** @class */ (function (_super) {
    __extends(MolitApi, _super);
    function MolitApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 주어진 `molit_apartment_kind_uuid`에 해당하는 매매 실거래가 정보의 리스트를 반환합니다.
     * @summary MolitDealContractListApi
     * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
     * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MolitApi
     */
    MolitApi.prototype.molitDealContractsIndex = function (molitApartmentKindUuid, kind, options) {
        var _this = this;
        return exports.MolitApiFp(this.configuration).molitDealContractsIndex(molitApartmentKindUuid, kind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `molit_apartment_kind_uuid`에 해당하는 월세 실거래가 정보의 리스트를 반환합니다.
     * @summary MolitRentContractListApi
     * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
     * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MolitApi
     */
    MolitApi.prototype.molitRentContractsIndex = function (molitApartmentKindUuid, kind, options) {
        var _this = this;
        return exports.MolitApiFp(this.configuration).molitRentContractsIndex(molitApartmentKindUuid, kind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 `molit_apartment_kind_uuid`에 해당하는 전세 실거래가 정보의 리스트를 반환합니다.
     * @summary MolitTnantContractListApi
     * @param {string} molitApartmentKindUuid 가져올 MolitApartmentKind의 UUID
     * @param {'latest' | 'month' | 'graph'} kind  &#x60;latest&#x60; : 최근 20개의 거래 사례를 시간의 역순으로 반환  &#x60;month&#x60; : 가격의 변동성을 기록하기 위한 최근 31일간의 거래사례를 순서 상관없이 반환  &#x60;graph&#x60; : 그래프 생성을 위한 1년간의 거래 사례를 시간 순서 상관 없이 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MolitApi
     */
    MolitApi.prototype.molitTnantContractsIndex = function (molitApartmentKindUuid, kind, options) {
        var _this = this;
        return exports.MolitApiFp(this.configuration).molitTnantContractsIndex(molitApartmentKindUuid, kind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MolitApi;
}(base_1.BaseAPI));
exports.MolitApi = MolitApi;
/**
 * PostApi - axios parameter creator
 * @export
 */
exports.PostApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 글에 댓글을 작성합니다.
         * @summary CommentAddApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} content 댓글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsAdd: function (postId, content, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postId' is not null or undefined
                    common_1.assertParamExists('postCommentsAdd', 'postId', postId);
                    // verify required parameter 'content' is not null or undefined
                    common_1.assertParamExists('postCommentsAdd', 'content', content);
                    localVarPath = "/posts/{post_id}/comments"
                        .replace("{" + "post_id" + "}", encodeURIComponent(String(postId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (content !== undefined) {
                        localVarFormParams.set('content', content);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신이 작성한 글의 댓글을 삭제합니다.
         * @summary CommentDeleteApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsDelete: function (commentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'commentId' is not null or undefined
                    common_1.assertParamExists('postCommentsDelete', 'commentId', commentId);
                    localVarPath = "/comments/{comment_id}"
                        .replace("{" + "comment_id" + "}", encodeURIComponent(String(commentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 글에 작성된 댓글을 제공합니다.
         * @summary CommentApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsGet: function (commentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'commentId' is not null or undefined
                    common_1.assertParamExists('postCommentsGet', 'commentId', commentId);
                    localVarPath = "/comments/{comment_id}"
                        .replace("{" + "comment_id" + "}", encodeURIComponent(String(commentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 글에 작성된 댓글들을 제공합니다.
         * @summary CommentListApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsIndex: function (postId, nextKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postId' is not null or undefined
                    common_1.assertParamExists('postCommentsIndex', 'postId', postId);
                    localVarPath = "/posts/{post_id}/comments"
                        .replace("{" + "post_id" + "}", encodeURIComponent(String(postId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신이 작성한 글의 댓글을 수정합니다.
         * @summary CommentUpdateApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {string} [content] 댓글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsUpdate: function (commentId, content, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'commentId' is not null or undefined
                    common_1.assertParamExists('postCommentsUpdate', 'commentId', commentId);
                    localVarPath = "/comments/{comment_id}"
                        .replace("{" + "comment_id" + "}", encodeURIComponent(String(commentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (content !== undefined) {
                        localVarFormParams.set('content', content);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 매물의 아파트에 글을 작성합니다.
         * @summary PostAddApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {string} content 글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsAdd: function (listingId, content, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('postPostsAdd', 'listingId', listingId);
                    // verify required parameter 'content' is not null or undefined
                    common_1.assertParamExists('postPostsAdd', 'content', content);
                    localVarPath = "/listings/{listing_id}/posts"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (content !== undefined) {
                        localVarFormParams.set('content', content);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 모든 매물에 작성된 글을 제공합니다.
         * @summary PostAllApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
         * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
         * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
         * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
         * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
         * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
         * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
         * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
         * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
         * @param {number} [destinationId] 목적지 ID
         * @param {number} [minDuration] 최소 출근시간 (초과)
         * @param {number} [maxDuration] 최대 출근시간 (이하)
         * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
         * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} [buildingType] 건물 종류
         * @param {number} [minHouseholdCount] 최소 세대수 (이상)
         * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
         * @param {number} [minBuiltYear] 최소 준공년도 (이상)
         * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
         * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsAllIndex: function (nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/posts";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    if (minAskingPrice !== undefined) {
                        localVarQueryParameter['min_asking_price'] = minAskingPrice;
                    }
                    if (maxAskingPrice !== undefined) {
                        localVarQueryParameter['max_asking_price'] = maxAskingPrice;
                    }
                    if (minAskingRentPrice !== undefined) {
                        localVarQueryParameter['min_asking_rent_price'] = minAskingRentPrice;
                    }
                    if (maxAskingRentPrice !== undefined) {
                        localVarQueryParameter['max_asking_rent_price'] = maxAskingRentPrice;
                    }
                    if (minSupplyArea !== undefined) {
                        localVarQueryParameter['min_supply_area'] = minSupplyArea;
                    }
                    if (maxSupplyArea !== undefined) {
                        localVarQueryParameter['max_supply_area'] = maxSupplyArea;
                    }
                    if (levelOneRegionCode) {
                        localVarQueryParameter['level_one_region_code'] = levelOneRegionCode;
                    }
                    if (levelTwoRegionCode) {
                        localVarQueryParameter['level_two_region_code'] = levelTwoRegionCode;
                    }
                    if (transportationKind !== undefined) {
                        localVarQueryParameter['transportation_kind'] = transportationKind;
                    }
                    if (destinationId !== undefined) {
                        localVarQueryParameter['destination_id'] = destinationId;
                    }
                    if (minDuration !== undefined) {
                        localVarQueryParameter['min_duration'] = minDuration;
                    }
                    if (maxDuration !== undefined) {
                        localVarQueryParameter['max_duration'] = maxDuration;
                    }
                    if (tradeType !== undefined) {
                        localVarQueryParameter['trade_type'] = tradeType;
                    }
                    if (buildingType) {
                        localVarQueryParameter['building_type'] = buildingType;
                    }
                    if (minHouseholdCount !== undefined) {
                        localVarQueryParameter['min_household_count'] = minHouseholdCount;
                    }
                    if (maxHouseholdCount !== undefined) {
                        localVarQueryParameter['max_household_count'] = maxHouseholdCount;
                    }
                    if (minBuiltYear !== undefined) {
                        localVarQueryParameter['min_built_year'] = minBuiltYear;
                    }
                    if (maxBuiltYear !== undefined) {
                        localVarQueryParameter['max_built_year'] = maxBuiltYear;
                    }
                    if (floorType) {
                        localVarQueryParameter['floor_type'] = floorType;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 등록된 매물톡 개수를 반환합니다.
         * @summary PostCountApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsCount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/posts/count";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신이 매물의 아파트에 작성한 글을 삭제합니다.
         * @summary PostDeleteApi
         * @param {number} postId 가져올 Post의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsDelete: function (postId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postId' is not null or undefined
                    common_1.assertParamExists('postPostsDelete', 'postId', postId);
                    localVarPath = "/posts/{post_id}"
                        .replace("{" + "post_id" + "}", encodeURIComponent(String(postId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 매물의 아파트에 작성된 글을 제공합니다.
         * @summary PostApi
         * @param {number} postId 가져올 Post의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsGet: function (postId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postId' is not null or undefined
                    common_1.assertParamExists('postPostsGet', 'postId', postId);
                    localVarPath = "/posts/{post_id}"
                        .replace("{" + "post_id" + "}", encodeURIComponent(String(postId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 매물의 아파트에 작성된 글들을 제공합니다.
         * @summary PostListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsIndex: function (listingId, nextKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('postPostsIndex', 'listingId', listingId);
                    localVarPath = "/listings/{listing_id}/posts"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신이 매물의 아파트에 작성한 글을 수정합니다.
         * @summary PostUpdateApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} [content] 글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsUpdate: function (postId, content, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postId' is not null or undefined
                    common_1.assertParamExists('postPostsUpdate', 'postId', postId);
                    localVarPath = "/posts/{post_id}"
                        .replace("{" + "post_id" + "}", encodeURIComponent(String(postId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (content !== undefined) {
                        localVarFormParams.set('content', content);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PostApi - functional programming interface
 * @export
 */
exports.PostApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PostApiAxiosParamCreator(configuration);
    return {
        /**
         * 글에 댓글을 작성합니다.
         * @summary CommentAddApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} content 댓글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsAdd: function (postId, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postCommentsAdd(postId, content, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신이 작성한 글의 댓글을 삭제합니다.
         * @summary CommentDeleteApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsDelete: function (commentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postCommentsDelete(commentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글에 작성된 댓글을 제공합니다.
         * @summary CommentApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsGet: function (commentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postCommentsGet(commentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글에 작성된 댓글들을 제공합니다.
         * @summary CommentListApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsIndex: function (postId, nextKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postCommentsIndex(postId, nextKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신이 작성한 글의 댓글을 수정합니다.
         * @summary CommentUpdateApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {string} [content] 댓글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsUpdate: function (commentId, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postCommentsUpdate(commentId, content, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 매물의 아파트에 글을 작성합니다.
         * @summary PostAddApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {string} content 글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsAdd: function (listingId, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsAdd(listingId, content, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 모든 매물에 작성된 글을 제공합니다.
         * @summary PostAllApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
         * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
         * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
         * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
         * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
         * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
         * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
         * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
         * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
         * @param {number} [destinationId] 목적지 ID
         * @param {number} [minDuration] 최소 출근시간 (초과)
         * @param {number} [maxDuration] 최대 출근시간 (이하)
         * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
         * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} [buildingType] 건물 종류
         * @param {number} [minHouseholdCount] 최소 세대수 (이상)
         * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
         * @param {number} [minBuiltYear] 최소 준공년도 (이상)
         * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
         * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsAllIndex: function (nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsAllIndex(nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 등록된 매물톡 개수를 반환합니다.
         * @summary PostCountApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsCount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신이 매물의 아파트에 작성한 글을 삭제합니다.
         * @summary PostDeleteApi
         * @param {number} postId 가져올 Post의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsDelete: function (postId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsDelete(postId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 매물의 아파트에 작성된 글을 제공합니다.
         * @summary PostApi
         * @param {number} postId 가져올 Post의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsGet: function (postId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsGet(postId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 매물의 아파트에 작성된 글들을 제공합니다.
         * @summary PostListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsIndex: function (listingId, nextKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsIndex(listingId, nextKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신이 매물의 아파트에 작성한 글을 수정합니다.
         * @summary PostUpdateApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} [content] 글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsUpdate: function (postId, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPostsUpdate(postId, content, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PostApi - factory interface
 * @export
 */
exports.PostApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PostApiFp(configuration);
    return {
        /**
         * 글에 댓글을 작성합니다.
         * @summary CommentAddApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} content 댓글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsAdd: function (postId, content, options) {
            return localVarFp.postCommentsAdd(postId, content, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신이 작성한 글의 댓글을 삭제합니다.
         * @summary CommentDeleteApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsDelete: function (commentId, options) {
            return localVarFp.postCommentsDelete(commentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글에 작성된 댓글을 제공합니다.
         * @summary CommentApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsGet: function (commentId, options) {
            return localVarFp.postCommentsGet(commentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글에 작성된 댓글들을 제공합니다.
         * @summary CommentListApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsIndex: function (postId, nextKey, options) {
            return localVarFp.postCommentsIndex(postId, nextKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신이 작성한 글의 댓글을 수정합니다.
         * @summary CommentUpdateApi
         * @param {number} commentId 가져올 Comment의 ID
         * @param {string} [content] 댓글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentsUpdate: function (commentId, content, options) {
            return localVarFp.postCommentsUpdate(commentId, content, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 매물의 아파트에 글을 작성합니다.
         * @summary PostAddApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {string} content 글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsAdd: function (listingId, content, options) {
            return localVarFp.postPostsAdd(listingId, content, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 모든 매물에 작성된 글을 제공합니다.
         * @summary PostAllApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
         * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
         * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
         * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
         * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
         * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
         * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
         * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
         * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
         * @param {number} [destinationId] 목적지 ID
         * @param {number} [minDuration] 최소 출근시간 (초과)
         * @param {number} [maxDuration] 최대 출근시간 (이하)
         * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
         * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} [buildingType] 건물 종류
         * @param {number} [minHouseholdCount] 최소 세대수 (이상)
         * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
         * @param {number} [minBuiltYear] 최소 준공년도 (이상)
         * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
         * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsAllIndex: function (nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options) {
            return localVarFp.postPostsAllIndex(nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 등록된 매물톡 개수를 반환합니다.
         * @summary PostCountApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsCount: function (options) {
            return localVarFp.postPostsCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신이 매물의 아파트에 작성한 글을 삭제합니다.
         * @summary PostDeleteApi
         * @param {number} postId 가져올 Post의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsDelete: function (postId, options) {
            return localVarFp.postPostsDelete(postId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 매물의 아파트에 작성된 글을 제공합니다.
         * @summary PostApi
         * @param {number} postId 가져올 Post의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsGet: function (postId, options) {
            return localVarFp.postPostsGet(postId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 매물의 아파트에 작성된 글들을 제공합니다.
         * @summary PostListApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsIndex: function (listingId, nextKey, options) {
            return localVarFp.postPostsIndex(listingId, nextKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신이 매물의 아파트에 작성한 글을 수정합니다.
         * @summary PostUpdateApi
         * @param {number} postId 가져올 Post의 ID
         * @param {string} [content] 글 내용
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostsUpdate: function (postId, content, options) {
            return localVarFp.postPostsUpdate(postId, content, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
var PostApi = /** @class */ (function (_super) {
    __extends(PostApi, _super);
    function PostApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 글에 댓글을 작성합니다.
     * @summary CommentAddApi
     * @param {number} postId 가져올 Post의 ID
     * @param {string} content 댓글 내용
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postCommentsAdd = function (postId, content, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postCommentsAdd(postId, content, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신이 작성한 글의 댓글을 삭제합니다.
     * @summary CommentDeleteApi
     * @param {number} commentId 가져올 Comment의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postCommentsDelete = function (commentId, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postCommentsDelete(commentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글에 작성된 댓글을 제공합니다.
     * @summary CommentApi
     * @param {number} commentId 가져올 Comment의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postCommentsGet = function (commentId, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postCommentsGet(commentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글에 작성된 댓글들을 제공합니다.
     * @summary CommentListApi
     * @param {number} postId 가져올 Post의 ID
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postCommentsIndex = function (postId, nextKey, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postCommentsIndex(postId, nextKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신이 작성한 글의 댓글을 수정합니다.
     * @summary CommentUpdateApi
     * @param {number} commentId 가져올 Comment의 ID
     * @param {string} [content] 댓글 내용
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postCommentsUpdate = function (commentId, content, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postCommentsUpdate(commentId, content, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 매물의 아파트에 글을 작성합니다.
     * @summary PostAddApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {string} content 글 내용
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsAdd = function (listingId, content, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsAdd(listingId, content, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 모든 매물에 작성된 글을 제공합니다.
     * @summary PostAllApi
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {number} [minAskingPrice] 최소 매물 가격 (초과)
     * @param {number} [maxAskingPrice] 최대 매물 가격 (이하)
     * @param {number} [minAskingRentPrice] 최소 매물 월세 가격 (초과)
     * @param {number} [maxAskingRentPrice] 최대 매물 월세 가격 (이하)
     * @param {number} [minSupplyArea] 최소 공급면적 (단위 m^2, 이상)
     * @param {number} [maxSupplyArea] 최대 공급면적 (단위 m^2, 미만)
     * @param {Array<string>} [levelOneRegionCode] 검색할 대상 레벨1 지역 코드
     * @param {Array<string>} [levelTwoRegionCode] 검색할 대상 레벨2 지역 코드
     * @param {'PUBLIC_TRANSPORTATION' | 'SUBWAY' | 'BUS' | 'CAR'} [transportationKind] 교통수단
     * @param {number} [destinationId] 목적지 ID
     * @param {number} [minDuration] 최소 출근시간 (초과)
     * @param {number} [maxDuration] 최대 출근시간 (이하)
     * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
     * @param {Array<'APARTMENT' | 'OFFICETEL' | 'VILLA'>} [buildingType] 건물 종류
     * @param {number} [minHouseholdCount] 최소 세대수 (이상)
     * @param {number} [maxHouseholdCount] 최대 세대수 (이하)
     * @param {number} [minBuiltYear] 최소 준공년도 (이상)
     * @param {number} [maxBuiltYear] 최대 준공년도 (이하)
     * @param {Array<'HIGH' | 'MIDDLE' | 'LOW'>} [floorType] 층 종류
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsAllIndex = function (nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsAllIndex(nextKey, minAskingPrice, maxAskingPrice, minAskingRentPrice, maxAskingRentPrice, minSupplyArea, maxSupplyArea, levelOneRegionCode, levelTwoRegionCode, transportationKind, destinationId, minDuration, maxDuration, tradeType, buildingType, minHouseholdCount, maxHouseholdCount, minBuiltYear, maxBuiltYear, floorType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 등록된 매물톡 개수를 반환합니다.
     * @summary PostCountApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsCount = function (options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신이 매물의 아파트에 작성한 글을 삭제합니다.
     * @summary PostDeleteApi
     * @param {number} postId 가져올 Post의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsDelete = function (postId, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsDelete(postId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 매물의 아파트에 작성된 글을 제공합니다.
     * @summary PostApi
     * @param {number} postId 가져올 Post의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsGet = function (postId, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsGet(postId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 매물의 아파트에 작성된 글들을 제공합니다.
     * @summary PostListApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsIndex = function (listingId, nextKey, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsIndex(listingId, nextKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신이 매물의 아파트에 작성한 글을 수정합니다.
     * @summary PostUpdateApi
     * @param {number} postId 가져올 Post의 ID
     * @param {string} [content] 글 내용
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.postPostsUpdate = function (postId, content, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).postPostsUpdate(postId, content, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PostApi;
}(base_1.BaseAPI));
exports.PostApi = PostApi;
/**
 * ReportApi - axios parameter creator
 * @export
 */
exports.ReportApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * B 가격을 반환합니다.
         * @summary ReportBPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBPriceGet: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportBPriceGet', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/b-price"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 공시가격을 반환합니다.
         * @summary ReportDeclaredPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDeclaredPriceGet: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportDeclaredPriceGet', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/declared-price"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 국토부 실거래가 목록을 반환합니다.
         * @summary ReportTankerPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMolitContractIndex: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportMolitContractIndex', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/molit-contracts"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 등기 주소에 대한 부동산 정보를 요청합니다.
         * @summary ReportRequest
         * @param {string} registerAddress 등기 주소
         * @param {string} registerPinNumber 등기 번호
         * @param {string} registerType 등기 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequest: function (registerAddress, registerPinNumber, registerType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'registerAddress' is not null or undefined
                    common_1.assertParamExists('reportRequest', 'registerAddress', registerAddress);
                    // verify required parameter 'registerPinNumber' is not null or undefined
                    common_1.assertParamExists('reportRequest', 'registerPinNumber', registerPinNumber);
                    // verify required parameter 'registerType' is not null or undefined
                    common_1.assertParamExists('reportRequest', 'registerType', registerType);
                    localVarPath = "/reports/request";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (registerAddress !== undefined) {
                        localVarFormParams.set('register_address', registerAddress);
                    }
                    if (registerPinNumber !== undefined) {
                        localVarFormParams.set('register_pin_number', registerPinNumber);
                    }
                    if (registerType !== undefined) {
                        localVarFormParams.set('register_type', registerType);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 탱커 예측 가격 목록을 반환합니다.
         * @summary ReportTankerPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTankerPriceIndex: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportTankerPriceIndex', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/tanker-prices"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ReportApi - functional programming interface
 * @export
 */
exports.ReportApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ReportApiAxiosParamCreator(configuration);
    return {
        /**
         * B 가격을 반환합니다.
         * @summary ReportBPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBPriceGet: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportBPriceGet(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 공시가격을 반환합니다.
         * @summary ReportDeclaredPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDeclaredPriceGet: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportDeclaredPriceGet(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 국토부 실거래가 목록을 반환합니다.
         * @summary ReportTankerPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMolitContractIndex: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportMolitContractIndex(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 등기 주소에 대한 부동산 정보를 요청합니다.
         * @summary ReportRequest
         * @param {string} registerAddress 등기 주소
         * @param {string} registerPinNumber 등기 번호
         * @param {string} registerType 등기 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequest: function (registerAddress, registerPinNumber, registerType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportRequest(registerAddress, registerPinNumber, registerType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 탱커 예측 가격 목록을 반환합니다.
         * @summary ReportTankerPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTankerPriceIndex: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportTankerPriceIndex(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ReportApi - factory interface
 * @export
 */
exports.ReportApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ReportApiFp(configuration);
    return {
        /**
         * B 가격을 반환합니다.
         * @summary ReportBPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBPriceGet: function (reportId, options) {
            return localVarFp.reportBPriceGet(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 공시가격을 반환합니다.
         * @summary ReportDeclaredPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDeclaredPriceGet: function (reportId, options) {
            return localVarFp.reportDeclaredPriceGet(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 국토부 실거래가 목록을 반환합니다.
         * @summary ReportTankerPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMolitContractIndex: function (reportId, options) {
            return localVarFp.reportMolitContractIndex(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 등기 주소에 대한 부동산 정보를 요청합니다.
         * @summary ReportRequest
         * @param {string} registerAddress 등기 주소
         * @param {string} registerPinNumber 등기 번호
         * @param {string} registerType 등기 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequest: function (registerAddress, registerPinNumber, registerType, options) {
            return localVarFp.reportRequest(registerAddress, registerPinNumber, registerType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 탱커 예측 가격 목록을 반환합니다.
         * @summary ReportTankerPrice
         * @param {string} reportId 요청 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTankerPriceIndex: function (reportId, options) {
            return localVarFp.reportTankerPriceIndex(reportId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
var ReportApi = /** @class */ (function (_super) {
    __extends(ReportApi, _super);
    function ReportApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * B 가격을 반환합니다.
     * @summary ReportBPrice
     * @param {string} reportId 요청 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportBPriceGet = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportBPriceGet(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 공시가격을 반환합니다.
     * @summary ReportDeclaredPrice
     * @param {string} reportId 요청 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportDeclaredPriceGet = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportDeclaredPriceGet(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 국토부 실거래가 목록을 반환합니다.
     * @summary ReportTankerPrice
     * @param {string} reportId 요청 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportMolitContractIndex = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportMolitContractIndex(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 등기 주소에 대한 부동산 정보를 요청합니다.
     * @summary ReportRequest
     * @param {string} registerAddress 등기 주소
     * @param {string} registerPinNumber 등기 번호
     * @param {string} registerType 등기 종류
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportRequest = function (registerAddress, registerPinNumber, registerType, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportRequest(registerAddress, registerPinNumber, registerType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 탱커 예측 가격 목록을 반환합니다.
     * @summary ReportTankerPrice
     * @param {string} reportId 요청 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportTankerPriceIndex = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportTankerPriceIndex(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ReportApi;
}(base_1.BaseAPI));
exports.ReportApi = ReportApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 비밀번호 초기화를 위한 인증 번호를 발송 합니다.
         * @summary UserPasswordResetCodeApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {string} phoneNumber 휴대전화 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetCode: function (serviceName, phoneNumber, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'serviceName' is not null or undefined
                    common_1.assertParamExists('userPasswordResetCode', 'serviceName', serviceName);
                    // verify required parameter 'phoneNumber' is not null or undefined
                    common_1.assertParamExists('userPasswordResetCode', 'phoneNumber', phoneNumber);
                    localVarPath = "/users/password-reset-code";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (serviceName !== undefined) {
                        localVarQueryParameter['service_name'] = serviceName;
                    }
                    if (phoneNumber !== undefined) {
                        localVarFormParams.set('phone_number', phoneNumber);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 비밀번호 초기화 인증 번호를 통해 토큰을 발급합니다.
         * @summary UserPasswordResetTokenByCodeApi
         * @param {string} phoneNumber 인증을 수행한 전화번호
         * @param {string} code 인증 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetTokenByCode: function (phoneNumber, code, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'phoneNumber' is not null or undefined
                    common_1.assertParamExists('userPasswordResetTokenByCode', 'phoneNumber', phoneNumber);
                    // verify required parameter 'code' is not null or undefined
                    common_1.assertParamExists('userPasswordResetTokenByCode', 'code', code);
                    localVarPath = "/users/password-reset-token-by-code";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (phoneNumber !== undefined) {
                        localVarFormParams.set('phone_number', phoneNumber);
                    }
                    if (code !== undefined) {
                        localVarFormParams.set('code', code);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 비밀번호 초기화를 진행합니다.
         * @summary UserResetPasswordApi
         * @param {string} token 인증 토큰
         * @param {string} password 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: function (token, password, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('userResetPassword', 'token', token);
                    // verify required parameter 'password' is not null or undefined
                    common_1.assertParamExists('userResetPassword', 'password', password);
                    localVarPath = "/users/reset-password";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (token !== undefined) {
                        localVarFormParams.set('token', token);
                    }
                    if (password !== undefined) {
                        localVarFormParams.set('password', password);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 새로운 유저를 생성합니다.
         * @summary UserAddApi
         * @param {string} phoneNumber 휴대전화 번호
         * @param {string} phoneNumberVerificationToken 전화번호 인증 토큰
         * @param {string} provider 계정 제공자
         * @param {boolean} agreePrivacyPolicy 개인정보 수집 및 이용 동의
         * @param {string} [name] 이름
         * @param {string} [password] 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserCreate: function (phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'phoneNumber' is not null or undefined
                    common_1.assertParamExists('userUserCreate', 'phoneNumber', phoneNumber);
                    // verify required parameter 'phoneNumberVerificationToken' is not null or undefined
                    common_1.assertParamExists('userUserCreate', 'phoneNumberVerificationToken', phoneNumberVerificationToken);
                    // verify required parameter 'provider' is not null or undefined
                    common_1.assertParamExists('userUserCreate', 'provider', provider);
                    // verify required parameter 'agreePrivacyPolicy' is not null or undefined
                    common_1.assertParamExists('userUserCreate', 'agreePrivacyPolicy', agreePrivacyPolicy);
                    localVarPath = "/users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (name !== undefined) {
                        localVarFormParams.set('name', name);
                    }
                    if (phoneNumber !== undefined) {
                        localVarFormParams.set('phone_number', phoneNumber);
                    }
                    if (phoneNumberVerificationToken !== undefined) {
                        localVarFormParams.set('phone_number_verification_token', phoneNumberVerificationToken);
                    }
                    if (provider !== undefined) {
                        localVarFormParams.set('provider', provider);
                    }
                    if (password !== undefined) {
                        localVarFormParams.set('password', password);
                    }
                    if (agreePrivacyPolicy !== undefined) {
                        localVarFormParams.set('agree_privacy_policy', agreePrivacyPolicy);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 탈퇴를 진행합니다.
         * @summary UserMeDeleteApi
         * @param {string} [reason] 탈퇴 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeDelete: function (reason, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users/me";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (reason !== undefined) {
                        localVarFormParams.set('reason', reason);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 내 정보를 제공합니다.
         * @summary UserMeApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users/me";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물을 관심매물로 저장합니다.
         * @summary UserMeFavoriteAddApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesAdd: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('userUserMeListingFavoritesAdd', 'listingId', listingId);
                    localVarPath = "/users/me/listing-favorites/{listing_id}"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신의 관심매물 개수를 반환합니다.
         * @summary UserMeFavoriteCountApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesCount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users/me/listing-favorites/count";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물을 관심매물에서 삭제합니다.
         * @summary UserMeFavoriteDeleteApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesDelete: function (listingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    common_1.assertParamExists('userUserMeListingFavoritesDelete', 'listingId', listingId);
                    localVarPath = "/users/me/listing-favorites/{listing_id}"
                        .replace("{" + "listing_id" + "}", encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신의 관심매물 리스트를 최신지정순으로 제공합니다.
         * @summary UserMeFavoriteListApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesIndex: function (nextKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users/me/listing-favorites";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 자신의 요청 매물 리스트를 반환합니다.
         * @summary UserMeListingRequestListApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingRequestsIndex: function (nextKey, tradeType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users/me/listing-requests";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (nextKey !== undefined) {
                        localVarQueryParameter['next_key'] = nextKey;
                    }
                    if (tradeType !== undefined) {
                        localVarQueryParameter['trade_type'] = tradeType;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 내 비밀번호를 업데이트 합니다.
         * @summary UserMePasswordUpdateApi
         * @param {string} password 새 비밀번호
         * @param {string} [oldPassword] 이전 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMePasswordUpdate: function (password, oldPassword, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'password' is not null or undefined
                    common_1.assertParamExists('userUserMePasswordUpdate', 'password', password);
                    localVarPath = "/users/me/password";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (oldPassword !== undefined) {
                        localVarFormParams.set('old_password', oldPassword);
                    }
                    if (password !== undefined) {
                        localVarFormParams.set('password', password);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 내 정보를 업데이트 합니다.
         * @summary UserMeUpdateApi
         * @param {string} [nickname] 닉네임
         * @param {boolean} [agreePrivacyPolicy] 개인정보 수집 및 이용 동의
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeUpdate: function (nickname, agreePrivacyPolicy, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users/me";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (nickname !== undefined) {
                        localVarFormParams.set('nickname', nickname);
                    }
                    if (agreePrivacyPolicy !== undefined) {
                        localVarFormParams.set('agree_privacy_policy', agreePrivacyPolicy);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 전화번호 인증 번호를 발송합니다.
         * @summary UserPhoneNumberVerificationCodeApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {string} phoneNumber 인증을 수행할 전화번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationPhoneNumberVerificationCode: function (serviceName, phoneNumber, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'serviceName' is not null or undefined
                    common_1.assertParamExists('verificationPhoneNumberVerificationCode', 'serviceName', serviceName);
                    // verify required parameter 'phoneNumber' is not null or undefined
                    common_1.assertParamExists('verificationPhoneNumberVerificationCode', 'phoneNumber', phoneNumber);
                    localVarPath = "/users/phone-number-verification-code";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (serviceName !== undefined) {
                        localVarQueryParameter['service_name'] = serviceName;
                    }
                    if (phoneNumber !== undefined) {
                        localVarFormParams.set('phone_number', phoneNumber);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 전화번호 인증 번호 일치 여부를 확인하고 토큰을 발급합니다.
         * @summary UserPhoneNumberVerificationTokenByCodeApi
         * @param {string} phoneNumber 인증을 수행한 전화번호
         * @param {string} code 인증 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationPhoneNumberVerificationTokenByCode: function (phoneNumber, code, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'phoneNumber' is not null or undefined
                    common_1.assertParamExists('verificationPhoneNumberVerificationTokenByCode', 'phoneNumber', phoneNumber);
                    // verify required parameter 'code' is not null or undefined
                    common_1.assertParamExists('verificationPhoneNumberVerificationTokenByCode', 'code', code);
                    localVarPath = "/users/phone-number-verification-token-by-code";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (phoneNumber !== undefined) {
                        localVarFormParams.set('phone_number', phoneNumber);
                    }
                    if (code !== undefined) {
                        localVarFormParams.set('code', code);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         * 비밀번호 초기화를 위한 인증 번호를 발송 합니다.
         * @summary UserPasswordResetCodeApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {string} phoneNumber 휴대전화 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetCode: function (serviceName, phoneNumber, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPasswordResetCode(serviceName, phoneNumber, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 비밀번호 초기화 인증 번호를 통해 토큰을 발급합니다.
         * @summary UserPasswordResetTokenByCodeApi
         * @param {string} phoneNumber 인증을 수행한 전화번호
         * @param {string} code 인증 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetTokenByCode: function (phoneNumber, code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPasswordResetTokenByCode(phoneNumber, code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 비밀번호 초기화를 진행합니다.
         * @summary UserResetPasswordApi
         * @param {string} token 인증 토큰
         * @param {string} password 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: function (token, password, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userResetPassword(token, password, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 새로운 유저를 생성합니다.
         * @summary UserAddApi
         * @param {string} phoneNumber 휴대전화 번호
         * @param {string} phoneNumberVerificationToken 전화번호 인증 토큰
         * @param {string} provider 계정 제공자
         * @param {boolean} agreePrivacyPolicy 개인정보 수집 및 이용 동의
         * @param {string} [name] 이름
         * @param {string} [password] 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserCreate: function (phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserCreate(phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 탈퇴를 진행합니다.
         * @summary UserMeDeleteApi
         * @param {string} [reason] 탈퇴 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeDelete: function (reason, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeDelete(reason, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 내 정보를 제공합니다.
         * @summary UserMeApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물을 관심매물로 저장합니다.
         * @summary UserMeFavoriteAddApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesAdd: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeListingFavoritesAdd(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신의 관심매물 개수를 반환합니다.
         * @summary UserMeFavoriteCountApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeListingFavoritesCount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * `listing_id`에 해당하는 매물을 관심매물에서 삭제합니다.
         * @summary UserMeFavoriteDeleteApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesDelete: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeListingFavoritesDelete(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신의 관심매물 리스트를 최신지정순으로 제공합니다.
         * @summary UserMeFavoriteListApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesIndex: function (nextKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeListingFavoritesIndex(nextKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 자신의 요청 매물 리스트를 반환합니다.
         * @summary UserMeListingRequestListApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingRequestsIndex: function (nextKey, tradeType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeListingRequestsIndex(nextKey, tradeType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 내 비밀번호를 업데이트 합니다.
         * @summary UserMePasswordUpdateApi
         * @param {string} password 새 비밀번호
         * @param {string} [oldPassword] 이전 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMePasswordUpdate: function (password, oldPassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMePasswordUpdate(password, oldPassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 내 정보를 업데이트 합니다.
         * @summary UserMeUpdateApi
         * @param {string} [nickname] 닉네임
         * @param {boolean} [agreePrivacyPolicy] 개인정보 수집 및 이용 동의
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeUpdate: function (nickname, agreePrivacyPolicy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userUserMeUpdate(nickname, agreePrivacyPolicy, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 전화번호 인증 번호를 발송합니다.
         * @summary UserPhoneNumberVerificationCodeApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {string} phoneNumber 인증을 수행할 전화번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationPhoneNumberVerificationCode: function (serviceName, phoneNumber, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verificationPhoneNumberVerificationCode(serviceName, phoneNumber, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 전화번호 인증 번호 일치 여부를 확인하고 토큰을 발급합니다.
         * @summary UserPhoneNumberVerificationTokenByCodeApi
         * @param {string} phoneNumber 인증을 수행한 전화번호
         * @param {string} code 인증 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationPhoneNumberVerificationTokenByCode: function (phoneNumber, code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verificationPhoneNumberVerificationTokenByCode(phoneNumber, code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         * 비밀번호 초기화를 위한 인증 번호를 발송 합니다.
         * @summary UserPasswordResetCodeApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {string} phoneNumber 휴대전화 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetCode: function (serviceName, phoneNumber, options) {
            return localVarFp.userPasswordResetCode(serviceName, phoneNumber, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 비밀번호 초기화 인증 번호를 통해 토큰을 발급합니다.
         * @summary UserPasswordResetTokenByCodeApi
         * @param {string} phoneNumber 인증을 수행한 전화번호
         * @param {string} code 인증 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetTokenByCode: function (phoneNumber, code, options) {
            return localVarFp.userPasswordResetTokenByCode(phoneNumber, code, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 비밀번호 초기화를 진행합니다.
         * @summary UserResetPasswordApi
         * @param {string} token 인증 토큰
         * @param {string} password 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: function (token, password, options) {
            return localVarFp.userResetPassword(token, password, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 새로운 유저를 생성합니다.
         * @summary UserAddApi
         * @param {string} phoneNumber 휴대전화 번호
         * @param {string} phoneNumberVerificationToken 전화번호 인증 토큰
         * @param {string} provider 계정 제공자
         * @param {boolean} agreePrivacyPolicy 개인정보 수집 및 이용 동의
         * @param {string} [name] 이름
         * @param {string} [password] 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserCreate: function (phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options) {
            return localVarFp.userUserCreate(phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 탈퇴를 진행합니다.
         * @summary UserMeDeleteApi
         * @param {string} [reason] 탈퇴 사유
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeDelete: function (reason, options) {
            return localVarFp.userUserMeDelete(reason, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 내 정보를 제공합니다.
         * @summary UserMeApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeGet: function (options) {
            return localVarFp.userUserMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * `listing_id`에 해당하는 매물을 관심매물로 저장합니다.
         * @summary UserMeFavoriteAddApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesAdd: function (listingId, options) {
            return localVarFp.userUserMeListingFavoritesAdd(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신의 관심매물 개수를 반환합니다.
         * @summary UserMeFavoriteCountApi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesCount: function (options) {
            return localVarFp.userUserMeListingFavoritesCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * `listing_id`에 해당하는 매물을 관심매물에서 삭제합니다.
         * @summary UserMeFavoriteDeleteApi
         * @param {number} listingId 가져올 Listing의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesDelete: function (listingId, options) {
            return localVarFp.userUserMeListingFavoritesDelete(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신의 관심매물 리스트를 최신지정순으로 제공합니다.
         * @summary UserMeFavoriteListApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingFavoritesIndex: function (nextKey, options) {
            return localVarFp.userUserMeListingFavoritesIndex(nextKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 자신의 요청 매물 리스트를 반환합니다.
         * @summary UserMeListingRequestListApi
         * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
         * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeListingRequestsIndex: function (nextKey, tradeType, options) {
            return localVarFp.userUserMeListingRequestsIndex(nextKey, tradeType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 내 비밀번호를 업데이트 합니다.
         * @summary UserMePasswordUpdateApi
         * @param {string} password 새 비밀번호
         * @param {string} [oldPassword] 이전 비밀번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMePasswordUpdate: function (password, oldPassword, options) {
            return localVarFp.userUserMePasswordUpdate(password, oldPassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 내 정보를 업데이트 합니다.
         * @summary UserMeUpdateApi
         * @param {string} [nickname] 닉네임
         * @param {boolean} [agreePrivacyPolicy] 개인정보 수집 및 이용 동의
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserMeUpdate: function (nickname, agreePrivacyPolicy, options) {
            return localVarFp.userUserMeUpdate(nickname, agreePrivacyPolicy, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 전화번호 인증 번호를 발송합니다.
         * @summary UserPhoneNumberVerificationCodeApi
         * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
         * @param {string} phoneNumber 인증을 수행할 전화번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationPhoneNumberVerificationCode: function (serviceName, phoneNumber, options) {
            return localVarFp.verificationPhoneNumberVerificationCode(serviceName, phoneNumber, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 전화번호 인증 번호 일치 여부를 확인하고 토큰을 발급합니다.
         * @summary UserPhoneNumberVerificationTokenByCodeApi
         * @param {string} phoneNumber 인증을 수행한 전화번호
         * @param {string} code 인증 번호
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationPhoneNumberVerificationTokenByCode: function (phoneNumber, code, options) {
            return localVarFp.verificationPhoneNumberVerificationTokenByCode(phoneNumber, code, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 비밀번호 초기화를 위한 인증 번호를 발송 합니다.
     * @summary UserPasswordResetCodeApi
     * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
     * @param {string} phoneNumber 휴대전화 번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userPasswordResetCode = function (serviceName, phoneNumber, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userPasswordResetCode(serviceName, phoneNumber, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 비밀번호 초기화 인증 번호를 통해 토큰을 발급합니다.
     * @summary UserPasswordResetTokenByCodeApi
     * @param {string} phoneNumber 인증을 수행한 전화번호
     * @param {string} code 인증 번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userPasswordResetTokenByCode = function (phoneNumber, code, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userPasswordResetTokenByCode(phoneNumber, code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 비밀번호 초기화를 진행합니다.
     * @summary UserResetPasswordApi
     * @param {string} token 인증 토큰
     * @param {string} password 비밀번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userResetPassword = function (token, password, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userResetPassword(token, password, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 새로운 유저를 생성합니다.
     * @summary UserAddApi
     * @param {string} phoneNumber 휴대전화 번호
     * @param {string} phoneNumberVerificationToken 전화번호 인증 토큰
     * @param {string} provider 계정 제공자
     * @param {boolean} agreePrivacyPolicy 개인정보 수집 및 이용 동의
     * @param {string} [name] 이름
     * @param {string} [password] 비밀번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserCreate = function (phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserCreate(phoneNumber, phoneNumberVerificationToken, provider, agreePrivacyPolicy, name, password, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 탈퇴를 진행합니다.
     * @summary UserMeDeleteApi
     * @param {string} [reason] 탈퇴 사유
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeDelete = function (reason, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeDelete(reason, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 내 정보를 제공합니다.
     * @summary UserMeApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeGet = function (options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * `listing_id`에 해당하는 매물을 관심매물로 저장합니다.
     * @summary UserMeFavoriteAddApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeListingFavoritesAdd = function (listingId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeListingFavoritesAdd(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신의 관심매물 개수를 반환합니다.
     * @summary UserMeFavoriteCountApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeListingFavoritesCount = function (options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeListingFavoritesCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * `listing_id`에 해당하는 매물을 관심매물에서 삭제합니다.
     * @summary UserMeFavoriteDeleteApi
     * @param {number} listingId 가져올 Listing의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeListingFavoritesDelete = function (listingId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeListingFavoritesDelete(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신의 관심매물 리스트를 최신지정순으로 제공합니다.
     * @summary UserMeFavoriteListApi
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeListingFavoritesIndex = function (nextKey, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeListingFavoritesIndex(nextKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 자신의 요청 매물 리스트를 반환합니다.
     * @summary UserMeListingRequestListApi
     * @param {string} [nextKey] 다음 리스트를 가져오기 위한 key
     * @param {'DEAL' | 'TNANT' | 'RENT'} [tradeType] 거래 종류
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeListingRequestsIndex = function (nextKey, tradeType, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeListingRequestsIndex(nextKey, tradeType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 내 비밀번호를 업데이트 합니다.
     * @summary UserMePasswordUpdateApi
     * @param {string} password 새 비밀번호
     * @param {string} [oldPassword] 이전 비밀번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMePasswordUpdate = function (password, oldPassword, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMePasswordUpdate(password, oldPassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 내 정보를 업데이트 합니다.
     * @summary UserMeUpdateApi
     * @param {string} [nickname] 닉네임
     * @param {boolean} [agreePrivacyPolicy] 개인정보 수집 및 이용 동의
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.userUserMeUpdate = function (nickname, agreePrivacyPolicy, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).userUserMeUpdate(nickname, agreePrivacyPolicy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 전화번호 인증 번호를 발송합니다.
     * @summary UserPhoneNumberVerificationCodeApi
     * @param {'VESTA' | 'GAPFINDER'} serviceName 서비스명
     * @param {string} phoneNumber 인증을 수행할 전화번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.verificationPhoneNumberVerificationCode = function (serviceName, phoneNumber, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).verificationPhoneNumberVerificationCode(serviceName, phoneNumber, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 전화번호 인증 번호 일치 여부를 확인하고 토큰을 발급합니다.
     * @summary UserPhoneNumberVerificationTokenByCodeApi
     * @param {string} phoneNumber 인증을 수행한 전화번호
     * @param {string} code 인증 번호
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.verificationPhoneNumberVerificationTokenByCode = function (phoneNumber, code, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).verificationPhoneNumberVerificationTokenByCode(phoneNumber, code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
