import React, { useRef, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Modal as MuiModal } from "@mui/material";
import styled from "styled-components";
import useRefOutSideClick from "@/hooks/useRefOutSideClick";
import { colors, lengths } from "@/styles/values";

const StyledModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
`;

const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const ImageSlideContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ArrowButton = styled.button`
  margin: 0;
  padding: 30px;

  background-color: inherit;
  border: none;

  &.hide {
    visibility: hidden;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    padding: 0;
  }

  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.1px;
  right: -1px;

  padding: 0;

  color: ${colors.white};
  font: inherit;

  background: none;
  border: none;
  outline: inherit;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;

  @media screen and (max-width: ${lengths.desktop}) {
    min-width: 303px;
    max-width: 85vw;
  }
`;

const Image = styled.img`
  max-width: 600px;
  height: auto;

  @media screen and (max-width: ${lengths.desktop}) {
    min-width: 303px;
    max-width: 85vw;
  }
`;

type ImageSlideModalProps = {
  open: boolean;
  handleClose: () => void;
  sources: string[];
  defaultSourceNum?: number;
};

const ImageSlideModal = ({ open, handleClose, sources, defaultSourceNum = 0 }: ImageSlideModalProps) => {
  const max = sources.length === 0 ? 0 : sources.length - 1;
  const [sourceNum, setSourceNum] = useState(defaultSourceNum);

  const slideRef = useRef(null);
  useRefOutSideClick({ ref: slideRef, onClick: handleClose });

  const handleArrowClick = (arrowType: "front" | "back") => {
    if (arrowType === "front") {
      setSourceNum((prev) => prev + 1);
    } else if (arrowType === "back") {
      setSourceNum((prev) => prev - 1);
    }
  };

  return (
    <>
      {sources.length > 0 ? (
        <StyledModal open={open}>
          <Container>
            <ImageSlideContainer ref={slideRef}>
              <ArrowButton onClick={() => handleArrowClick("back")} disabled={sourceNum === 0}>
                <ArrowBackIosNewIcon
                  sx={{ color: sourceNum === 0 ? colors.lightGray : colors.white }}
                  fontSize="large"
                />
              </ArrowButton>
              <ImageContainer>
                <Image src={sources[sourceNum]} />
              </ImageContainer>
              <ArrowButton onClick={() => handleArrowClick("front")} disabled={sourceNum === max}>
                <ArrowForwardIosIcon
                  sx={{ color: sourceNum === max ? colors.lightGray : colors.white }}
                  fontSize="large"
                />
              </ArrowButton>
            </ImageSlideContainer>
            <CloseButton onClick={handleClose}>
              <CloseIcon fontSize="large" color="inherit" />
            </CloseButton>
          </Container>
        </StyledModal>
      ) : null}
    </>
  );
};

export default ImageSlideModal;
