import React, { useState } from "react";
import { UserResponse } from "@doczip/api-client";
import classnames from "classnames";
import { decamelizeKeys } from "humps";
import { parse } from "query-string";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import MobileNavigation from "./MobileNavigation";
import StyledButton from "./StyledButton";
import { colors, depths, lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const HeaderBox = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${depths.header};

  width: 100vw;
  min-width: ${lengths.sm};
  height: ${lengths.headerHeight};
  overflow: hidden;

  color: #555;

  background-color: ${colors.white};
  border-bottom: 1px solid #eee;

  &.padded {
    left: ${lengths.sideColumnWidth};

    width: calc(100vw - ${lengths.sideColumnWidth});
    min-width: calc(${lengths.sm} - ${lengths.sideColumnWidth});
  }

  @media screen and (max-width: ${lengths.desktop}) {
    left: 0;

    width: 100vw;
    min-width: ${lengths.sm};
    height: ${lengths.mobileHeaderHeight};

    &.padded {
      left: 0;

      width: 100vw;
      min-width: ${lengths.sm};
    }
  }
`;

const HeaderPlaceHolder = styled.div`
  position: relative;

  width: 100vw;
  min-width: ${lengths.sm};
  height: ${lengths.headerHeight};

  @media screen and (max-width: ${lengths.desktop}) {
    height: ${lengths.mobileHeaderHeight};
  }
`;

const HeaderInnerBox = styled.div`
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: min(${lengths.containerWidth}, calc(100vw - 96px));
  height: 100%;
  margin: 0 auto;
  padding: 0;

  &.full-width {
    width: 100%;
    padding: 0 48px;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
    padding: 0 24px;

    &.full-width {
      width: 100%;
      padding: 0 24px;
    }
  }
`;

const HeaderCornerBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
`;

const Logo = styled.div`
  display: none;
  width: 120px;
  height: 23.5px;
  margin-right: 28px;

  background-image: url("/images/logo.svg");
  background-size: 120px 23.5px;

  .logo & {
    display: block;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 12px;

    background-image: url("/images/logo-small.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16.44px 16px;
    border: 1px solid #f2f2f2;
    border-radius: 50%;

    &.mobile-back {
      background-image: url("/images/back.svg");
      background-size: 16px 16px;
    }
  }
`;

export const TextButton = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 28px;

  color: ${colors.dark};
  font-size: 14px;

  transition: all 0.2s ease;

  &.right {
    margin-right: 0;
    margin-left: 28px;
  }

  &:hover {
    color: ${colors.dark}99;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const MobileTitle = styled.div`
  display: none;

  @media screen and (max-width: ${lengths.desktop}) {
    display: block;

    color: ${colors.dark};
    font-weight: 700;
    font-size: 16px;
  }
`;

const MobileMenuButton = styled.a`
  display: none;

  cursor: pointer;

  @media screen and (max-width: ${lengths.desktop}) {
    display: block;
    width: 40px;
    height: 40px;

    background-image: url("/images/hamburger.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    border: 1px solid #f2f2f2;
    border-radius: 50%;
  }
`;

const DesktopOnlyBox = styled.div`
  @media screen and (max-width: ${lengths.desktop}) {
    display: none !important;
  }
`;

type Props = {
  logo?: boolean;
  fullWidth?: boolean;
  padded?: boolean;
  user?: UserResponse;
  mobileTitle?: React.ReactNode;
  onMobileBackClick?: () => void;
};

const Header: React.FC<Props> = (props) => {
  const location = useLocation();
  const search = parse(location.search);
  const reportLogId = search.report ? parseInt(search.report as string, 10) : void 0;
  const [navigating, setNavigating] = useState(false);
  const { logo, fullWidth, padded } = props;

  const documentQuery = reportLogId ? { report: reportLogId } : void 0;

  const defaultContents = (
    <>
      <TextButton as={Link} to={getUrl("dashboard")}>
        대시보드
      </TextButton>
      <TextButton as={Link} to={getUrl("documentsNewContract", { query: documentQuery })}>
        계약서
      </TextButton>
      <TextButton as={Link} to={getUrl("documentsNewManual", { query: documentQuery })}>
        중개대상물 확인설명서
      </TextButton>
      <MobileTitle>{props.mobileTitle || "공부서류 간편발급 서비스, 닥집"}</MobileTitle>
    </>
  );

  const sessionContents = props.user ? (
    <>
      <TextButton className="right" as={Link} to={getUrl("userRoot")}>
        내 계정
      </TextButton>
      <TextButton className="right" as={Link} to={getUrl("logout")}>
        로그아웃
      </TextButton>
    </>
  ) : (
    <DesktopOnlyBox>
      <StyledButton bold to={getUrl("login")}>
        로그인/회원가입
      </StyledButton>
    </DesktopOnlyBox>
  );

  const logoButton = props.onMobileBackClick ? <Logo className="mobile-back" /> : <Logo />;

  const linkedLogo = props.user ? (
    <Link to={getUrl("dashboard")}>{logoButton}</Link>
  ) : (
    <Link to={getUrl("root")}>{logoButton}</Link>
  );

  return (
    <>
      {navigating && <MobileNavigation onClose={() => setNavigating(false)} />}
      <HeaderPlaceHolder />
      <HeaderBox className={classnames({ padded })}>
        <HeaderInnerBox className={classnames(decamelizeKeys({ logo, fullWidth }, { separator: "-" }))}>
          <HeaderCornerBox>
            {linkedLogo}
            {props.children || defaultContents}
          </HeaderCornerBox>
          <HeaderCornerBox>
            {sessionContents}
            <MobileMenuButton onClick={() => setNavigating(true)} />
          </HeaderCornerBox>
        </HeaderInnerBox>
      </HeaderBox>
    </>
  );
};

export default Header;
