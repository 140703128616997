import React from "react";
import {
  DocumentResponse,
  DocumentTypeEnum,
  JeonseContractResponse,
  RentContractResponse,
  ResidentialManualResponse,
  TradeContractResponse,
} from "@doczip/api-client";
import styled from "styled-components";
import CommonForm from "./common/CommonForm";
import CommonPartiesForm from "./common/CommonPartiesForm";
import ContractForm from "./common/ContractForm";
import ManualForm from "./common/ManualForm";
import { colors } from "@/styles/values";

const PaddedContainer = styled.div`
  padding: 1rem 1rem;

  background-color: ${colors.white};
`;

type Props = {
  document: DocumentResponse;
  onChange: (document: DocumentResponse) => void;
};

const DocumentForm: React.FC<Props> = ({ document, onChange }) => {
  const handleContractChange = (body: TradeContractResponse | JeonseContractResponse | RentContractResponse) => {
    if (typeof (body as RentContractResponse).rent_payment_day_of_month !== "undefined") {
      onChange({ ...document, rent_contract: body as RentContractResponse });
    } else if (typeof (body as JeonseContractResponse).portion_to_lease !== "undefined") {
      onChange({ ...document, jeonse_contract: body as JeonseContractResponse });
    } else {
      onChange({ ...document, trade_contract: body as TradeContractResponse });
    }
  };

  const handleManualChange = (body: ResidentialManualResponse) => {
    onChange({ ...document, residential_manual: body });
  };

  const bodyForm =
    document.document_type === DocumentTypeEnum.ResidentialManual ? (
      <ManualForm body={document.residential_manual as ResidentialManualResponse} onChange={handleManualChange} />
    ) : (
      <ContractForm
        body={
          (document.trade_contract || document.jeonse_contract || document.rent_contract) as
            | TradeContractResponse
            | JeonseContractResponse
            | RentContractResponse
        }
        onChange={handleContractChange}
      />
    );

  // const detailsForm =
  //   document.document_type === DocumentTypeEnum.ResidentialManual ? (
  //     <ManualDetailsForm
  //       body={document.residential_manual as ResidentialManualResponse}
  //       onChange={handleManualChange}
  //     />
  //   ) : (
  //     <ContractDetailsForm
  //       body={
  //         (document.trade_contract || document.jeonse_contract || document.rent_contract) as
  //           | TradeContractResponse
  //           | JeonseContractResponse
  //           | RentContractResponse
  //       }
  //       onChange={handleContractChange}
  //     />
  //   );

  return (
    <PaddedContainer>
      <CommonForm document={document} onChange={onChange} />
      {bodyForm}
      <CommonPartiesForm document={document} onChange={onChange} />
      {/* <Collapsible label="상세 내용">{detailsForm}</Collapsible> */}
    </PaddedContainer>
  );
};

export default DocumentForm;
