import { ADDRESS_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getAddressList,
  getAddressListFetchState,
  getBannerCollapse,
  getAddressSearchCategory,
  getCorporationAddressList,
  getCorporationAddressListFetchState,
} = makeSubStateSelectorMap("address", ADDRESS_STATE_KEY_LIST);
