import { useEffect, useState } from "react";
import throttle from "lodash.throttle";

function getWindowSize() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const onResize = () => setWindowSize(getWindowSize());
  const throttledOnResize = throttle(onResize, 600);

  useEffect(() => {
    window.addEventListener("resize", throttledOnResize);
    return () => window.removeEventListener("resize", throttledOnResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
