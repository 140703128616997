import { combineReducers } from "@reduxjs/toolkit";
import { addressReducer as address } from "@/redux/address/slice";
import { authReducer as auth } from "@/redux/auth/slice";
import { corporationReducer as corporation } from "@/redux/corporation/slice";
import { logsReducer as logs } from "@/redux/logs/slice";
import { PostsReducer as posts } from "@/redux/posts/slice";
import { reportReducer as report } from "@/redux/report/slice";
import { saleCommentReducer as saleComment } from "@/redux/sale-comment/slice";
import { salesReducer as sales } from "@/redux/sales/slice";
import { snackbarReducer as snackbar } from "@/redux/snackbar/slice";
import { subscriptionReducer as subscription } from "@/redux/subscription/slice";
import { transactionReducer as transaction } from "@/redux/transaction/slice";
import { userReducer as user } from "@/redux/user/slice";

export const rootReducerMap = {
  auth,
  address,
  logs,
  transaction,
  posts,
  report,
  saleComment,
  sales,
  user,
  snackbar,
  subscription,
  corporation,
};

const rootReducer = combineReducers(rootReducerMap);

export default rootReducer;
