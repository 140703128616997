import React from "react";
import styled from "styled-components";
import NavigationContainer from "@/containers/NavigationContainer";
import { colors, depths, lengths } from "@/styles/values";

const ShadeBox = styled.div`
  display: none;

  @media screen and (max-width: ${lengths.desktop}) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${depths.overlayShade};

    display: block;
    width: 100vw;
    min-width: ${lengths.sm};
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));

    background-color: ${colors.black}aa;
    cursor: pointer;
  }
`;

const CloseIcon = styled.div`
  display: none;

  @media screen and (max-width: ${lengths.desktop}) {
    position: fixed;
    top: 68px;
    right: 322.41px;
    z-index: ${depths.overlay};

    display: block;
    width: 15.59px;
    height: 15.59px;

    background-image: url("/images/close-white.svg");
    background-size: 100% 100%;
    cursor: pointer;
  }
`;

const MobileNavigationBox = styled.div`
  display: none;

  @media screen and (max-width: ${lengths.desktop}) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: ${depths.overlay};

    display: block;
    width: 300px;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));

    background-color: ${colors.white};
  }
`;

type Props = {
  onClose: () => void;
};

const MobileNavigation: React.FC<Props> = (props) => {
  return (
    <>
      <ShadeBox onClick={() => props.onClose()} />
      <CloseIcon onClick={() => props.onClose()} />
      <MobileNavigationBox>
        <NavigationContainer onClick={() => props.onClose()} />
      </MobileNavigationBox>
    </>
  );
};

export default MobileNavigation;
