import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import styled from "styled-components";
import CarouselCard, { CarouselCardProp } from "@/components/dashboard/common/CarouselCard";

const StyledCarousel = styled(ResponsiveCarousel)`
  width: 100%;
  padding: 4px 0;
  overflow: hidden;

  perspective: 1px;

  touch-action: pan-x pinch-zoom;

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const StyleButton = styled.button`
  position: absolute;
  top: 12.25px;
  z-index: 1;

  margin: 0;
  padding: 0;

  color: #dfe2e7;

  background-color: inherit;
  border: none;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

const PrevButton = styled(StyleButton)`
  left: 15px;
`;

const NextButton = styled(StyleButton)`
  right: 15px;
`;

interface CarouselProps {
  slideList?: CarouselCardProp[];
}

const Carousel = ({ slideList }: CarouselProps) => {
  return (
    <StyledCarousel
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      infiniteLoop
      dynamicHeight
      autoPlay
      emulateTouch
      renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
        hasPrev && (
          <PrevButton type="button" onClick={clickHandler}>
            <ArrowBackIosNewIcon style={{ fontSize: "14px", color: "inherit" }} />
          </PrevButton>
        )
      }
      renderArrowNext={(clickHandler, hasNext, labelNext) =>
        hasNext && (
          <NextButton type="button" onClick={clickHandler}>
            <ArrowForwardIosIcon style={{ fontSize: "14px", color: "inherit" }} />
          </NextButton>
        )
      }
    >
      {[...(slideList ?? [])].map((props, id) => (
        <CarouselCard key={id} {...props} />
      ))}
    </StyledCarousel>
  );
};

export default Carousel;
