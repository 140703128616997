import React from "react";
import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const LoadingButtonContainer = styled(MuiLoadingButton)`
  &.MuiButton-root {
    border-radius: 0;
    box-shadow: none;
  }

  &.MuiLoadingButton-loadingIndicator {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
const EmptyStartIcon = styled.p`
  display: inline-block;
  margin: 0;
  padding: 0;
`;

interface LoadingButtonProps extends MuiLoadingButtonProps {}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  children,
  loading,
  loadingPosition = "start",
  loadingIndicator,
  ...props
}) => {
  return (
    <LoadingButtonContainer
      {...props}
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={<EmptyStartIcon />}
      loadingIndicator={loadingIndicator ?? <CircularProgress color="inherit" size={16} />}
    >
      {children}
    </LoadingButtonContainer>
  );
};

export default LoadingButton;
