import React from "react";
import FlatButton from "@/components/buttons/FlatButton";
import { SaleTitledOuterContainer } from "@/components/sales/common/SaleLayout";
import SaleModalOpenButton from "@/components/sales/SaleModalOpenButton";
import SaleSearch from "@/components/sales/SaleSearch";
import TitledContainer from "@/components/TitledContainer";

const SaleTitledContainer = () => {
  return (
    <SaleTitledOuterContainer paddingBottom={6}>
      <TitledContainer
        title="매물 공유 게시판"
        titleFontSize={16}
        actions={
          <>
            <SaleSearch />
            <SaleModalOpenButton as={<FlatButton className="outlined">매물 공유</FlatButton>} />
          </>
        }
      />
    </SaleTitledOuterContainer>
  );
};

export default SaleTitledContainer;
