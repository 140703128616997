import React from "react";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import styled from "styled-components";
import { ReactComponent as CheckboxCheckedIcon } from "@/assets/icons/checkbox-checked.svg";
import { ReactComponent as CheckboxIcon } from "@/assets/icons/checkbox.svg";
import { colors } from "@/styles/values";

const StyledMuiCheckbox = styled(MuiCheckbox)`
  .MuiTouchRipple-rippleVisible {
    color: ${colors.main};
  }

  &.MuiCheckbox-root:hover {
    background-color: ${colors.main}19;
  }
`;

interface CheckboxProps extends MuiCheckboxProps {}

export default function Checkbox(props: CheckboxProps) {
  return <StyledMuiCheckbox icon={<CheckboxIcon />} checkedIcon={<CheckboxCheckedIcon />} {...props} />;
}

export const CheckboxFormControlLabel = styled(MuiFormControlLabel)`
  &.MuiFormControlLabel-root {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;
