import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styled from "styled-components";
import { colors } from "@/styles/values";

const OptionContainer = styled.div`
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .MuiTypography-root {
    display: flex;
    flex-grow: 1;

    color: #333;
    font-weight: 400;
    font-size: 14px;
  }
`;

const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    margin-top: -10px;
    margin-right: -2;
    margin-bottom: -10px;
    margin-left: -10px;

    &.Mui-checked {
      color: ${colors.main};
    }
  }
`;

interface OptionProps<OptionType> {
  children?: React.ReactNode;
  value: OptionType;
}

function Option<OptionType>({ children, value }: OptionProps<OptionType>) {
  return (
    <OptionContainer>
      <StyledFormControlLabel value={value} control={<StyledRadio />} label={children} />
    </OptionContainer>
  );
}

const SelectContainer = styled.div``;

interface SelectProps<OptionType> {
  className?: string;
  optionList: OptionType[];
  value?: OptionType;
  onChange: (newValue: OptionType) => void;
  renderOption?: (value: OptionType, selected: boolean) => React.ReactNode;
  newValueToOptionType?: (newValue: string) => OptionType;
}

export default function Select<OptionType>({
  className,
  value,
  optionList,
  renderOption,
  onChange,
  newValueToOptionType,
}: SelectProps<OptionType>) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(newValueToOptionType ? newValueToOptionType(value) : (value as unknown as OptionType));
  };

  return (
    <SelectContainer className={className}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {optionList.map((option, index) => (
          <Option value={option} key={index}>
            {renderOption ? renderOption(option, typeof value !== "undefined" && option === value) : option}
          </Option>
        ))}
      </RadioGroup>
    </SelectContainer>
  );
}
