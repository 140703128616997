import { RegisteredType } from "@doczip/api-client";

export function getRegisteredType(type: string) {
  switch (type) {
    case "집합건물":
      return RegisteredType.Complex;
    case "건물":
      return RegisteredType.Single;
    case "토지":
      return RegisteredType.Land;
    default:
      return RegisteredType.Complex;
  }
}

export function getRegisteredTypeString(type: RegisteredType) {
  switch (type) {
    case RegisteredType.Complex:
      return "집합건물";
    case RegisteredType.Single:
      return "건물";
    case RegisteredType.Land:
      return "토지";
    default:
      return "-";
  }
}
