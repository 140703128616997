import React from "react";
import styled from "styled-components";
import LandingVisualImage1 from "@/assets/images/landing/landing-visual-image-1.svg";
import LandingVisualImage2 from "@/assets/images/landing/landing-visual-image-2.svg";

const LandingVisualContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

interface VisualStyleProps {
  imageSrc?: string;
}

const VisualStyle = styled.div<VisualStyleProps>`
  position: absolute;

  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const DashboardVisual = styled(({ ...props }) => <VisualStyle {...props} />)`
  top: 7px;
  right: 0;

  width: 567px;
  height: 540px;
`;

const LockVisual = styled(({ ...props }) => <VisualStyle {...props} />)`
  top: 90%;
  left: 15%;

  width: 258.6px;
  height: 318px;

  transform: translate(-15%, -90%);
`;

interface LandingVisualProps {}

const LandingVisual: React.FC<LandingVisualProps> = () => {
  return (
    <LandingVisualContainer>
      <DashboardVisual imageSrc={LandingVisualImage2} />
      <LockVisual imageSrc={LandingVisualImage1} />
    </LandingVisualContainer>
  );
};

export default LandingVisual;
