"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Blitz API
 * Blitz API
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchApi = exports.SearchApiFactory = exports.SearchApiFp = exports.SearchApiAxiosParamCreator = exports.ReportApi = exports.ReportApiFactory = exports.ReportApiFp = exports.ReportApiAxiosParamCreator = exports.RegulatedRegionApi = exports.RegulatedRegionApiFactory = exports.RegulatedRegionApiFp = exports.RegulatedRegionApiAxiosParamCreator = exports.CorporationApi = exports.CorporationApiFactory = exports.CorporationApiFp = exports.CorporationApiAxiosParamCreator = exports.CommonApi = exports.CommonApiFactory = exports.CommonApiFp = exports.CommonApiAxiosParamCreator = exports.AddressApi = exports.AddressApiFactory = exports.AddressApiFp = exports.AddressApiAxiosParamCreator = exports.ResourceEnum = exports.RegulatedRegionRegulationTypeEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var RegulatedRegionRegulationTypeEnum;
(function (RegulatedRegionRegulationTypeEnum) {
    RegulatedRegionRegulationTypeEnum["SPECULATIVE"] = "SPECULATIVE";
    RegulatedRegionRegulationTypeEnum["OVERHEATED"] = "OVERHEATED";
    RegulatedRegionRegulationTypeEnum["ADJUSTMENT_REQUIRED"] = "ADJUSTMENT_REQUIRED";
})(RegulatedRegionRegulationTypeEnum = exports.RegulatedRegionRegulationTypeEnum || (exports.RegulatedRegionRegulationTypeEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ResourceEnum;
(function (ResourceEnum) {
    ResourceEnum["RegisterLedger"] = "register-ledger";
    ResourceEnum["RegisterLedgerTogepi"] = "register-ledger-togepi";
    ResourceEnum["BuildingLedger"] = "building-ledger";
    ResourceEnum["LandLedger"] = "land-ledger";
    ResourceEnum["LandUsingInfo"] = "land-using-info";
    ResourceEnum["Molit"] = "molit";
    ResourceEnum["Kb"] = "kb";
    ResourceEnum["Nsdi"] = "nsdi";
    ResourceEnum["Infocare"] = "infocare";
    ResourceEnum["TankerPrice"] = "tanker-price";
    ResourceEnum["Kab"] = "kab";
    ResourceEnum["DeclaredPrice"] = "declared-price";
})(ResourceEnum = exports.ResourceEnum || (exports.ResourceEnum = {}));
/**
 * AddressApi - axios parameter creator
 * @export
 */
exports.AddressApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *  주어진 키워드로 등기소에서 주소 및 등기고유번호를 검색합니다.   입력하는 주소는 도로명/지번을 가리지 않으나, 가급적 `/addresses/standard` API로 먼저 도로명주소를 검색하고 여기에 동/호수를 붙여 상세주소를 검색하는 것을 권장합니다.   **단, 토지등기의 경우 반드시 지번주소로 검색해야 결과를 얻을 수 있습니다.**   이 API는 폐지된 등기주소는 검색하지 않습니다.
         * @param {string} keyword 검색할 주소 키워드
         * @param {'ALL' | 'BUILDING' | 'COMPLEX' | 'LAND'} [mode] 검색 모드
         * @param {'ALL' | 'PRESENT' | 'CLOSED'} [status] 이 등기부의 현행 상황
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetRegisterAddress: function (keyword, mode, status, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'keyword' is not null or undefined
                    common_1.assertParamExists('addressGetRegisterAddress', 'keyword', keyword);
                    localVarPath = "/addresses/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (keyword !== undefined) {
                        localVarQueryParameter['keyword'] = keyword;
                    }
                    if (mode !== undefined) {
                        localVarQueryParameter['mode'] = mode;
                    }
                    if (status !== undefined) {
                        localVarQueryParameter['status'] = status;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 주어진 키워드로 도로명주소 데이터베이스에서 표준 주소를 검색합니다. 입력하는 주소는 도로명/지번을 가리지 않습니다.
         * @param {string} keyword 검색할 주소 키워드
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetStandardAddress: function (keyword, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'keyword' is not null or undefined
                    common_1.assertParamExists('addressGetStandardAddress', 'keyword', keyword);
                    localVarPath = "/addresses/standard";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (keyword !== undefined) {
                        localVarQueryParameter['keyword'] = keyword;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AddressApi - functional programming interface
 * @export
 */
exports.AddressApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AddressApiAxiosParamCreator(configuration);
    return {
        /**
         *  주어진 키워드로 등기소에서 주소 및 등기고유번호를 검색합니다.   입력하는 주소는 도로명/지번을 가리지 않으나, 가급적 `/addresses/standard` API로 먼저 도로명주소를 검색하고 여기에 동/호수를 붙여 상세주소를 검색하는 것을 권장합니다.   **단, 토지등기의 경우 반드시 지번주소로 검색해야 결과를 얻을 수 있습니다.**   이 API는 폐지된 등기주소는 검색하지 않습니다.
         * @param {string} keyword 검색할 주소 키워드
         * @param {'ALL' | 'BUILDING' | 'COMPLEX' | 'LAND'} [mode] 검색 모드
         * @param {'ALL' | 'PRESENT' | 'CLOSED'} [status] 이 등기부의 현행 상황
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetRegisterAddress: function (keyword, mode, status, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addressGetRegisterAddress(keyword, mode, status, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주어진 키워드로 도로명주소 데이터베이스에서 표준 주소를 검색합니다. 입력하는 주소는 도로명/지번을 가리지 않습니다.
         * @param {string} keyword 검색할 주소 키워드
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetStandardAddress: function (keyword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addressGetStandardAddress(keyword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AddressApi - factory interface
 * @export
 */
exports.AddressApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AddressApiFp(configuration);
    return {
        /**
         *  주어진 키워드로 등기소에서 주소 및 등기고유번호를 검색합니다.   입력하는 주소는 도로명/지번을 가리지 않으나, 가급적 `/addresses/standard` API로 먼저 도로명주소를 검색하고 여기에 동/호수를 붙여 상세주소를 검색하는 것을 권장합니다.   **단, 토지등기의 경우 반드시 지번주소로 검색해야 결과를 얻을 수 있습니다.**   이 API는 폐지된 등기주소는 검색하지 않습니다.
         * @param {string} keyword 검색할 주소 키워드
         * @param {'ALL' | 'BUILDING' | 'COMPLEX' | 'LAND'} [mode] 검색 모드
         * @param {'ALL' | 'PRESENT' | 'CLOSED'} [status] 이 등기부의 현행 상황
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetRegisterAddress: function (keyword, mode, status, options) {
            return localVarFp.addressGetRegisterAddress(keyword, mode, status, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주어진 키워드로 도로명주소 데이터베이스에서 표준 주소를 검색합니다. 입력하는 주소는 도로명/지번을 가리지 않습니다.
         * @param {string} keyword 검색할 주소 키워드
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetStandardAddress: function (keyword, options) {
            return localVarFp.addressGetStandardAddress(keyword, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
var AddressApi = /** @class */ (function (_super) {
    __extends(AddressApi, _super);
    function AddressApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *  주어진 키워드로 등기소에서 주소 및 등기고유번호를 검색합니다.   입력하는 주소는 도로명/지번을 가리지 않으나, 가급적 `/addresses/standard` API로 먼저 도로명주소를 검색하고 여기에 동/호수를 붙여 상세주소를 검색하는 것을 권장합니다.   **단, 토지등기의 경우 반드시 지번주소로 검색해야 결과를 얻을 수 있습니다.**   이 API는 폐지된 등기주소는 검색하지 않습니다.
     * @param {string} keyword 검색할 주소 키워드
     * @param {'ALL' | 'BUILDING' | 'COMPLEX' | 'LAND'} [mode] 검색 모드
     * @param {'ALL' | 'PRESENT' | 'CLOSED'} [status] 이 등기부의 현행 상황
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    AddressApi.prototype.addressGetRegisterAddress = function (keyword, mode, status, options) {
        var _this = this;
        return exports.AddressApiFp(this.configuration).addressGetRegisterAddress(keyword, mode, status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주어진 키워드로 도로명주소 데이터베이스에서 표준 주소를 검색합니다. 입력하는 주소는 도로명/지번을 가리지 않습니다.
     * @param {string} keyword 검색할 주소 키워드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    AddressApi.prototype.addressGetStandardAddress = function (keyword, options) {
        var _this = this;
        return exports.AddressApiFp(this.configuration).addressGetStandardAddress(keyword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AddressApi;
}(base_1.BaseAPI));
exports.AddressApi = AddressApi;
/**
 * CommonApi - axios parameter creator
 * @export
 */
exports.CommonApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 기본 Index 페이지 입니다
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMain: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CommonApi - functional programming interface
 * @export
 */
exports.CommonApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CommonApiAxiosParamCreator(configuration);
    return {
        /**
         * 기본 Index 페이지 입니다
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMain: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.commonMain(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CommonApi - factory interface
 * @export
 */
exports.CommonApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CommonApiFp(configuration);
    return {
        /**
         * 기본 Index 페이지 입니다
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMain: function (options) {
            return localVarFp.commonMain(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
var CommonApi = /** @class */ (function (_super) {
    __extends(CommonApi, _super);
    function CommonApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 기본 Index 페이지 입니다
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.commonMain = function (options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).commonMain(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CommonApi;
}(base_1.BaseAPI));
exports.CommonApi = CommonApi;
/**
 * CorporationApi - axios parameter creator
 * @export
 */
exports.CorporationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Corporation Re Request
         * @param {number} reportId
         * @param {string} [requesterIp]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationReRequest: function (reportId, requesterIp, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reportId' is not null or undefined
                            common_1.assertParamExists('corporationReRequest', 'reportId', reportId);
                            localVarPath = "/corporation/report-re-corporation-request";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            // authentication api_key required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication api_key required
                            _a.sent();
                            if (reportId !== undefined) {
                                localVarFormParams.set('report_id', reportId);
                            }
                            if (requesterIp !== undefined) {
                                localVarFormParams.set('requester_ip', requesterIp);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * CorporationApi - functional programming interface
 * @export
 */
exports.CorporationApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CorporationApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Corporation Re Request
         * @param {number} reportId
         * @param {string} [requesterIp]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationReRequest: function (reportId, requesterIp, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.corporationReRequest(reportId, requesterIp, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CorporationApi - factory interface
 * @export
 */
exports.CorporationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CorporationApiFp(configuration);
    return {
        /**
         *
         * @summary Corporation Re Request
         * @param {number} reportId
         * @param {string} [requesterIp]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationReRequest: function (reportId, requesterIp, options) {
            return localVarFp.corporationReRequest(reportId, requesterIp, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CorporationApi - object-oriented interface
 * @export
 * @class CorporationApi
 * @extends {BaseAPI}
 */
var CorporationApi = /** @class */ (function (_super) {
    __extends(CorporationApi, _super);
    function CorporationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Corporation Re Request
     * @param {number} reportId
     * @param {string} [requesterIp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationApi
     */
    CorporationApi.prototype.corporationReRequest = function (reportId, requesterIp, options) {
        var _this = this;
        return exports.CorporationApiFp(this.configuration).corporationReRequest(reportId, requesterIp, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CorporationApi;
}(base_1.BaseAPI));
exports.CorporationApi = CorporationApi;
/**
 * RegulatedRegionApi - axios parameter creator
 * @export
 */
exports.RegulatedRegionApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 입력한 PNU에 따라 투기 규제 내역을 삭제합니다.
         * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionDelete: function (sidoCode, gugunCode, dongliCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'sidoCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionDelete', 'sidoCode', sidoCode);
                    // verify required parameter 'gugunCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionDelete', 'gugunCode', gugunCode);
                    // verify required parameter 'dongliCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionDelete', 'dongliCode', dongliCode);
                    localVarPath = "/regulated-regions";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (sidoCode !== undefined) {
                        localVarQueryParameter['sido_code'] = sidoCode;
                    }
                    if (gugunCode !== undefined) {
                        localVarQueryParameter['gugun_code'] = gugunCode;
                    }
                    if (dongliCode !== undefined) {
                        localVarQueryParameter['dongli_code'] = dongliCode;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 입력한 PNU에 따라 투기 규제 상황을 검색합니다.
         * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionIndex: function (sidoCode, gugunCode, dongliCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'sidoCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionIndex', 'sidoCode', sidoCode);
                    // verify required parameter 'gugunCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionIndex', 'gugunCode', gugunCode);
                    // verify required parameter 'dongliCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionIndex', 'dongliCode', dongliCode);
                    localVarPath = "/regulated-regions";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (sidoCode !== undefined) {
                        localVarQueryParameter['sido_code'] = sidoCode;
                    }
                    if (gugunCode !== undefined) {
                        localVarQueryParameter['gugun_code'] = gugunCode;
                    }
                    if (dongliCode !== undefined) {
                        localVarQueryParameter['dongli_code'] = dongliCode;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *  입력한 PNU의 규제 상황을 업데이트합니다.   regulation_cleared_date를 입력하면, 해당 규제는 입력한 일자에 해제되었다는 의미가 됩니다.   이 API는 과거 규제 내역을 보존하지 않습니다. PNU 패턴에 해당하는 region이 데이터베이스에 있으면, 해당 region의 attribute를 주어진 값으로 덮어씁니다.   기록을 완전히 삭제하려는 경우, delete API를 참조하세요.
         * @param {string} sidoCode 법정 시군구 코드 중 \\\&quot;시도\\\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \\\&quot;구군\\\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} regulationType 규제 종류입니다. SPECULATIVE: 투기지역, OVERHEATED: 투기과열지구, ADJUSTMENT_REQUIRED: 조정대상지역
         * @param {string} [regulationSetDate] 이 규제가 설정된 날짜입니다.
         * @param {string} [regulationClearedDate] 이 규제가 설정된 날짜입니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionUpdate: function (sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'sidoCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionUpdate', 'sidoCode', sidoCode);
                    // verify required parameter 'gugunCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionUpdate', 'gugunCode', gugunCode);
                    // verify required parameter 'dongliCode' is not null or undefined
                    common_1.assertParamExists('regulatedRegionUpdate', 'dongliCode', dongliCode);
                    // verify required parameter 'regulationType' is not null or undefined
                    common_1.assertParamExists('regulatedRegionUpdate', 'regulationType', regulationType);
                    localVarPath = "/regulated-regions";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (sidoCode !== undefined) {
                        localVarFormParams.set('sido_code', sidoCode);
                    }
                    if (gugunCode !== undefined) {
                        localVarFormParams.set('gugun_code', gugunCode);
                    }
                    if (dongliCode !== undefined) {
                        localVarFormParams.set('dongli_code', dongliCode);
                    }
                    if (regulationType !== undefined) {
                        localVarFormParams.set('regulation_type', regulationType);
                    }
                    if (regulationSetDate !== undefined) {
                        localVarFormParams.set('regulation_set_date', regulationSetDate);
                    }
                    if (regulationClearedDate !== undefined) {
                        localVarFormParams.set('regulation_cleared_date', regulationClearedDate);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * RegulatedRegionApi - functional programming interface
 * @export
 */
exports.RegulatedRegionApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.RegulatedRegionApiAxiosParamCreator(configuration);
    return {
        /**
         * 입력한 PNU에 따라 투기 규제 내역을 삭제합니다.
         * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionDelete: function (sidoCode, gugunCode, dongliCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.regulatedRegionDelete(sidoCode, gugunCode, dongliCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 입력한 PNU에 따라 투기 규제 상황을 검색합니다.
         * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionIndex: function (sidoCode, gugunCode, dongliCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.regulatedRegionIndex(sidoCode, gugunCode, dongliCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *  입력한 PNU의 규제 상황을 업데이트합니다.   regulation_cleared_date를 입력하면, 해당 규제는 입력한 일자에 해제되었다는 의미가 됩니다.   이 API는 과거 규제 내역을 보존하지 않습니다. PNU 패턴에 해당하는 region이 데이터베이스에 있으면, 해당 region의 attribute를 주어진 값으로 덮어씁니다.   기록을 완전히 삭제하려는 경우, delete API를 참조하세요.
         * @param {string} sidoCode 법정 시군구 코드 중 \\\&quot;시도\\\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \\\&quot;구군\\\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} regulationType 규제 종류입니다. SPECULATIVE: 투기지역, OVERHEATED: 투기과열지구, ADJUSTMENT_REQUIRED: 조정대상지역
         * @param {string} [regulationSetDate] 이 규제가 설정된 날짜입니다.
         * @param {string} [regulationClearedDate] 이 규제가 설정된 날짜입니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionUpdate: function (sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.regulatedRegionUpdate(sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * RegulatedRegionApi - factory interface
 * @export
 */
exports.RegulatedRegionApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.RegulatedRegionApiFp(configuration);
    return {
        /**
         * 입력한 PNU에 따라 투기 규제 내역을 삭제합니다.
         * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionDelete: function (sidoCode, gugunCode, dongliCode, options) {
            return localVarFp.regulatedRegionDelete(sidoCode, gugunCode, dongliCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 입력한 PNU에 따라 투기 규제 상황을 검색합니다.
         * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionIndex: function (sidoCode, gugunCode, dongliCode, options) {
            return localVarFp.regulatedRegionIndex(sidoCode, gugunCode, dongliCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *  입력한 PNU의 규제 상황을 업데이트합니다.   regulation_cleared_date를 입력하면, 해당 규제는 입력한 일자에 해제되었다는 의미가 됩니다.   이 API는 과거 규제 내역을 보존하지 않습니다. PNU 패턴에 해당하는 region이 데이터베이스에 있으면, 해당 region의 attribute를 주어진 값으로 덮어씁니다.   기록을 완전히 삭제하려는 경우, delete API를 참조하세요.
         * @param {string} sidoCode 법정 시군구 코드 중 \\\&quot;시도\\\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} gugunCode 법정 시군구 코드 중 \\\&quot;구군\\\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
         * @param {string} regulationType 규제 종류입니다. SPECULATIVE: 투기지역, OVERHEATED: 투기과열지구, ADJUSTMENT_REQUIRED: 조정대상지역
         * @param {string} [regulationSetDate] 이 규제가 설정된 날짜입니다.
         * @param {string} [regulationClearedDate] 이 규제가 설정된 날짜입니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatedRegionUpdate: function (sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options) {
            return localVarFp.regulatedRegionUpdate(sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * RegulatedRegionApi - object-oriented interface
 * @export
 * @class RegulatedRegionApi
 * @extends {BaseAPI}
 */
var RegulatedRegionApi = /** @class */ (function (_super) {
    __extends(RegulatedRegionApi, _super);
    function RegulatedRegionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 입력한 PNU에 따라 투기 규제 내역을 삭제합니다.
     * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatedRegionApi
     */
    RegulatedRegionApi.prototype.regulatedRegionDelete = function (sidoCode, gugunCode, dongliCode, options) {
        var _this = this;
        return exports.RegulatedRegionApiFp(this.configuration).regulatedRegionDelete(sidoCode, gugunCode, dongliCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 입력한 PNU에 따라 투기 규제 상황을 검색합니다.
     * @param {string} sidoCode 법정 시군구 코드 중 \&quot;시도\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} gugunCode 법정 시군구 코드 중 \&quot;구군\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatedRegionApi
     */
    RegulatedRegionApi.prototype.regulatedRegionIndex = function (sidoCode, gugunCode, dongliCode, options) {
        var _this = this;
        return exports.RegulatedRegionApiFp(this.configuration).regulatedRegionIndex(sidoCode, gugunCode, dongliCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *  입력한 PNU의 규제 상황을 업데이트합니다.   regulation_cleared_date를 입력하면, 해당 규제는 입력한 일자에 해제되었다는 의미가 됩니다.   이 API는 과거 규제 내역을 보존하지 않습니다. PNU 패턴에 해당하는 region이 데이터베이스에 있으면, 해당 region의 attribute를 주어진 값으로 덮어씁니다.   기록을 완전히 삭제하려는 경우, delete API를 참조하세요.
     * @param {string} sidoCode 법정 시군구 코드 중 \\\&quot;시도\\\&quot; 에 해당하는 부분의 2자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} gugunCode 법정 시군구 코드 중 \\\&quot;구군\\\&quot; 에 해당하는 부분의 3자리 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} dongliCode 5자리 법정동 코드입니다. wildcard(*) 혹은 부분적 underbar(_)를 허용합니다.
     * @param {string} regulationType 규제 종류입니다. SPECULATIVE: 투기지역, OVERHEATED: 투기과열지구, ADJUSTMENT_REQUIRED: 조정대상지역
     * @param {string} [regulationSetDate] 이 규제가 설정된 날짜입니다.
     * @param {string} [regulationClearedDate] 이 규제가 설정된 날짜입니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatedRegionApi
     */
    RegulatedRegionApi.prototype.regulatedRegionUpdate = function (sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options) {
        var _this = this;
        return exports.RegulatedRegionApiFp(this.configuration).regulatedRegionUpdate(sidoCode, gugunCode, dongliCode, regulationType, regulationSetDate, regulationClearedDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return RegulatedRegionApi;
}(base_1.BaseAPI));
exports.RegulatedRegionApi = RegulatedRegionApi;
/**
 * ReportApi - axios parameter creator
 * @export
 */
exports.ReportApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 건축물대장 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBuildingLedger: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportBuildingLedger', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/building-ledger"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 공시가격 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDeclaredPrice: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportDeclaredPrice', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/declared-price"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 정보 처리 결과를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGet: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportGet', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * INFOCARE 낙찰가율 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportInfocare: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportInfocare', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/infocare"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * KAB 호별 시세 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportKabPrice: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportKabPrice', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/kab-price"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * KB 건물시세 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportKbPrice: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportKbPrice', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/kb-price"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 토지대장 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLandLedger: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportLandLedger', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/land-ledger"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 토지이용계획원 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLandUsingInfo: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportLandUsingInfo', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/land-using-info"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * MOLIT 실거래가 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMolitContract: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportMolitContract', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/molit-contract"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * NSDI 토지 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportNsdi: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportNsdi', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/nsdi"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 부동산 정보를 재요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  report id 값으로 각 리소스의 결과를 요청하면 재요청 결과를 조회할 수 있습니다.
         * @param {number} reportId 대상 report id
         * @param {Array<ResourceEnum>} requestingResources
         * @param {string} [requesterIp] 요청자 아이피
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReRequest: function (reportId, requestingResources, requesterIp, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reportId' is not null or undefined
                            common_1.assertParamExists('reportReRequest', 'reportId', reportId);
                            // verify required parameter 'requestingResources' is not null or undefined
                            common_1.assertParamExists('reportReRequest', 'requestingResources', requestingResources);
                            localVarPath = "/reports/report-re-request";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            // authentication api_key required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication api_key required
                            _a.sent();
                            if (reportId !== undefined) {
                                localVarFormParams.set('report_id', reportId);
                            }
                            if (requestingResources) {
                                localVarFormParams.set('requesting_resources', requestingResources.join(base_1.COLLECTION_FORMATS.csv));
                            }
                            if (requesterIp !== undefined) {
                                localVarFormParams.set('requester_ip', requesterIp);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 등기부등본 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRegisterLedger: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportRegisterLedger', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/register-ledger"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 심사 결과를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportDocument: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportReportDocument', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/report-document"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 부동산 정보를 요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  Response의 report id 값으로 `/reports/<report_id>` 를 poll하면 결과를 조회할 수 있습니다.
         * @param {string} registeredType 대상 부동산의 등기 종류
         * @param {string} registeredPinNumber 대상 부동산의 등기부등본 코드
         * @param {string} registeredAddress 대상 부동산의 등기부등본 기준 주소
         * @param {Array<ResourceEnum>} requestingResources
         * @param {string} [requesterIp] 요청자 아이피
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequest: function (registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'registeredType' is not null or undefined
                            common_1.assertParamExists('reportRequest', 'registeredType', registeredType);
                            // verify required parameter 'registeredPinNumber' is not null or undefined
                            common_1.assertParamExists('reportRequest', 'registeredPinNumber', registeredPinNumber);
                            // verify required parameter 'registeredAddress' is not null or undefined
                            common_1.assertParamExists('reportRequest', 'registeredAddress', registeredAddress);
                            // verify required parameter 'requestingResources' is not null or undefined
                            common_1.assertParamExists('reportRequest', 'requestingResources', requestingResources);
                            localVarPath = "/reports/report-request";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            // authentication api_key required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication api_key required
                            _a.sent();
                            if (registeredType !== undefined) {
                                localVarFormParams.set('registered_type', registeredType);
                            }
                            if (registeredPinNumber !== undefined) {
                                localVarFormParams.set('registered_pin_number', registeredPinNumber);
                            }
                            if (registeredAddress !== undefined) {
                                localVarFormParams.set('registered_address', registeredAddress);
                            }
                            if (requestingResources) {
                                localVarFormParams.set('requesting_resources', requestingResources.join(base_1.COLLECTION_FORMATS.csv));
                            }
                            if (requesterIp !== undefined) {
                                localVarFormParams.set('requester_ip', requesterIp);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 요청 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestInfo: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportRequestInfo', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/request-info"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 탱커 예측가 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTankerPrice: function (reportId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'reportId' is not null or undefined
                    common_1.assertParamExists('reportTankerPrice', 'reportId', reportId);
                    localVarPath = "/reports/{report_id}/tanker-price"
                        .replace("{" + "report_id" + "}", encodeURIComponent(String(reportId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ReportApi - functional programming interface
 * @export
 */
exports.ReportApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ReportApiAxiosParamCreator(configuration);
    return {
        /**
         * 건축물대장 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBuildingLedger: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportBuildingLedger(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 공시가격 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDeclaredPrice: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportDeclaredPrice(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 정보 처리 결과를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGet: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportGet(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * INFOCARE 낙찰가율 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportInfocare: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportInfocare(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * KAB 호별 시세 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportKabPrice: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportKabPrice(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * KB 건물시세 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportKbPrice: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportKbPrice(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 토지대장 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLandLedger: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportLandLedger(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 토지이용계획원 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLandUsingInfo: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportLandUsingInfo(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * MOLIT 실거래가 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMolitContract: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportMolitContract(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * NSDI 토지 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportNsdi: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportNsdi(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 부동산 정보를 재요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  report id 값으로 각 리소스의 결과를 요청하면 재요청 결과를 조회할 수 있습니다.
         * @param {number} reportId 대상 report id
         * @param {Array<ResourceEnum>} requestingResources
         * @param {string} [requesterIp] 요청자 아이피
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReRequest: function (reportId, requestingResources, requesterIp, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportReRequest(reportId, requestingResources, requesterIp, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 등기부등본 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRegisterLedger: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportRegisterLedger(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 심사 결과를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportDocument: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportReportDocument(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 부동산 정보를 요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  Response의 report id 값으로 `/reports/<report_id>` 를 poll하면 결과를 조회할 수 있습니다.
         * @param {string} registeredType 대상 부동산의 등기 종류
         * @param {string} registeredPinNumber 대상 부동산의 등기부등본 코드
         * @param {string} registeredAddress 대상 부동산의 등기부등본 기준 주소
         * @param {Array<ResourceEnum>} requestingResources
         * @param {string} [requesterIp] 요청자 아이피
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequest: function (registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportRequest(registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 요청 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestInfo: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportRequestInfo(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 탱커 예측가 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTankerPrice: function (reportId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reportTankerPrice(reportId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ReportApi - factory interface
 * @export
 */
exports.ReportApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ReportApiFp(configuration);
    return {
        /**
         * 건축물대장 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBuildingLedger: function (reportId, options) {
            return localVarFp.reportBuildingLedger(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 공시가격 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDeclaredPrice: function (reportId, options) {
            return localVarFp.reportDeclaredPrice(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 정보 처리 결과를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGet: function (reportId, options) {
            return localVarFp.reportGet(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * INFOCARE 낙찰가율 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportInfocare: function (reportId, options) {
            return localVarFp.reportInfocare(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * KAB 호별 시세 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportKabPrice: function (reportId, options) {
            return localVarFp.reportKabPrice(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * KB 건물시세 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportKbPrice: function (reportId, options) {
            return localVarFp.reportKbPrice(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 토지대장 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLandLedger: function (reportId, options) {
            return localVarFp.reportLandLedger(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 토지이용계획원 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLandUsingInfo: function (reportId, options) {
            return localVarFp.reportLandUsingInfo(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * MOLIT 실거래가 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportMolitContract: function (reportId, options) {
            return localVarFp.reportMolitContract(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * NSDI 토지 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportNsdi: function (reportId, options) {
            return localVarFp.reportNsdi(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 부동산 정보를 재요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  report id 값으로 각 리소스의 결과를 요청하면 재요청 결과를 조회할 수 있습니다.
         * @param {number} reportId 대상 report id
         * @param {Array<ResourceEnum>} requestingResources
         * @param {string} [requesterIp] 요청자 아이피
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReRequest: function (reportId, requestingResources, requesterIp, options) {
            return localVarFp.reportReRequest(reportId, requestingResources, requesterIp, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 등기부등본 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRegisterLedger: function (reportId, options) {
            return localVarFp.reportRegisterLedger(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 심사 결과를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportReportDocument: function (reportId, options) {
            return localVarFp.reportReportDocument(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 부동산 정보를 요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  Response의 report id 값으로 `/reports/<report_id>` 를 poll하면 결과를 조회할 수 있습니다.
         * @param {string} registeredType 대상 부동산의 등기 종류
         * @param {string} registeredPinNumber 대상 부동산의 등기부등본 코드
         * @param {string} registeredAddress 대상 부동산의 등기부등본 기준 주소
         * @param {Array<ResourceEnum>} requestingResources
         * @param {string} [requesterIp] 요청자 아이피
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequest: function (registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options) {
            return localVarFp.reportRequest(registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 요청 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestInfo: function (reportId, options) {
            return localVarFp.reportRequestInfo(reportId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 탱커 예측가 정보를 받습니다.
         * @param {number} reportId Report의 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTankerPrice: function (reportId, options) {
            return localVarFp.reportTankerPrice(reportId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
var ReportApi = /** @class */ (function (_super) {
    __extends(ReportApi, _super);
    function ReportApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 건축물대장 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportBuildingLedger = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportBuildingLedger(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 공시가격 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportDeclaredPrice = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportDeclaredPrice(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 정보 처리 결과를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportGet = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportGet(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * INFOCARE 낙찰가율 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportInfocare = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportInfocare(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * KAB 호별 시세 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportKabPrice = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportKabPrice(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * KB 건물시세 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportKbPrice = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportKbPrice(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 토지대장 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportLandLedger = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportLandLedger(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 토지이용계획원 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportLandUsingInfo = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportLandUsingInfo(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * MOLIT 실거래가 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportMolitContract = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportMolitContract(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * NSDI 토지 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportNsdi = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportNsdi(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 부동산 정보를 재요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  report id 값으로 각 리소스의 결과를 요청하면 재요청 결과를 조회할 수 있습니다.
     * @param {number} reportId 대상 report id
     * @param {Array<ResourceEnum>} requestingResources
     * @param {string} [requesterIp] 요청자 아이피
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportReRequest = function (reportId, requestingResources, requesterIp, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportReRequest(reportId, requestingResources, requesterIp, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 등기부등본 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportRegisterLedger = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportRegisterLedger(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 심사 결과를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportReportDocument = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportReportDocument(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 부동산 정보를 요청합니다. 처리 시간이 길기에 결과는 비동기로 알려줍니다.  Response의 report id 값으로 `/reports/<report_id>` 를 poll하면 결과를 조회할 수 있습니다.
     * @param {string} registeredType 대상 부동산의 등기 종류
     * @param {string} registeredPinNumber 대상 부동산의 등기부등본 코드
     * @param {string} registeredAddress 대상 부동산의 등기부등본 기준 주소
     * @param {Array<ResourceEnum>} requestingResources
     * @param {string} [requesterIp] 요청자 아이피
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportRequest = function (registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportRequest(registeredType, registeredPinNumber, registeredAddress, requestingResources, requesterIp, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 요청 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportRequestInfo = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportRequestInfo(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 탱커 예측가 정보를 받습니다.
     * @param {number} reportId Report의 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    ReportApi.prototype.reportTankerPrice = function (reportId, options) {
        var _this = this;
        return exports.ReportApiFp(this.configuration).reportTankerPrice(reportId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ReportApi;
}(base_1.BaseAPI));
exports.ReportApi = ReportApi;
/**
 * SearchApi - axios parameter creator
 * @export
 */
exports.SearchApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 건축물대장 검색 및 연결된 KB / Molit 정보를 반환합니다
         * @param {string} registerPinNumber
         * @param {string} registerAddress
         * @param {'B' | 'C'} registeredType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetMatchingAndComplexInformation: function (registerPinNumber, registerAddress, registeredType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'registerPinNumber' is not null or undefined
                    common_1.assertParamExists('searchGetMatchingAndComplexInformation', 'registerPinNumber', registerPinNumber);
                    // verify required parameter 'registerAddress' is not null or undefined
                    common_1.assertParamExists('searchGetMatchingAndComplexInformation', 'registerAddress', registerAddress);
                    // verify required parameter 'registeredType' is not null or undefined
                    common_1.assertParamExists('searchGetMatchingAndComplexInformation', 'registeredType', registeredType);
                    localVarPath = "/search";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (registerPinNumber !== undefined) {
                        localVarQueryParameter['register_pin_number'] = registerPinNumber;
                    }
                    if (registerAddress !== undefined) {
                        localVarQueryParameter['register_address'] = registerAddress;
                    }
                    if (registeredType !== undefined) {
                        localVarQueryParameter['registered_type'] = registeredType;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * SearchApi - functional programming interface
 * @export
 */
exports.SearchApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SearchApiAxiosParamCreator(configuration);
    return {
        /**
         * 건축물대장 검색 및 연결된 KB / Molit 정보를 반환합니다
         * @param {string} registerPinNumber
         * @param {string} registerAddress
         * @param {'B' | 'C'} registeredType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetMatchingAndComplexInformation: function (registerPinNumber, registerAddress, registeredType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchGetMatchingAndComplexInformation(registerPinNumber, registerAddress, registeredType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SearchApi - factory interface
 * @export
 */
exports.SearchApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SearchApiFp(configuration);
    return {
        /**
         * 건축물대장 검색 및 연결된 KB / Molit 정보를 반환합니다
         * @param {string} registerPinNumber
         * @param {string} registerAddress
         * @param {'B' | 'C'} registeredType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetMatchingAndComplexInformation: function (registerPinNumber, registerAddress, registeredType, options) {
            return localVarFp.searchGetMatchingAndComplexInformation(registerPinNumber, registerAddress, registeredType, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
var SearchApi = /** @class */ (function (_super) {
    __extends(SearchApi, _super);
    function SearchApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 건축물대장 검색 및 연결된 KB / Molit 정보를 반환합니다
     * @param {string} registerPinNumber
     * @param {string} registerAddress
     * @param {'B' | 'C'} registeredType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    SearchApi.prototype.searchGetMatchingAndComplexInformation = function (registerPinNumber, registerAddress, registeredType, options) {
        var _this = this;
        return exports.SearchApiFp(this.configuration).searchGetMatchingAndComplexInformation(registerPinNumber, registerAddress, registeredType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SearchApi;
}(base_1.BaseAPI));
exports.SearchApi = SearchApi;
