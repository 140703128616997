import styled from "styled-components";

const CardButtonContainer = styled.div`
  display: flex;

  > div,
  > button,
  > a {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  > .MuiButtonBase-root,
  > div > .MuiButtonBase-root,
  > a > .MuiButtonBase-root {
    height: 36px;
    padding-right: 12px;
    padding-left: 12px;

    font-weight: 700;
    font-size: 13px;
  }
`;

export default CardButtonContainer;
