import React, { useEffect } from "react";
import { PostType } from "@doczip/api-client";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DashboardAddressListWithSearch from "@/components/dashboard/DashboardAddressListWithSearch";
import Layout from "@/components/Layout";
import OwnersClubModal from "@/components/OwnersClubModal";
import { SaleTitledOuterContainer } from "@/components/sales/common/SaleLayout";
import TitledContainer from "@/components/TitledContainer";
import DashboardCarouselContainer from "@/containers/dashboard/DashboardCarouselContainer";
import ReportListContainer from "@/containers/dashboard/ReportListContainer";
import LandingContainer from "@/containers/landing/LandingContainer";
import OwnersClubFreeTrialBannerContainer from "@/containers/OwnersClubFreeTrialBannerContainer";
import SaleGradientSlideContainer from "@/containers/sales/SaleGradientSlideContainer";
import useWindowSize from "@/hooks/useWindowSize";
import { resetAddressList, resetCorporationAddressList } from "@/redux/address/actions";
import { resetLimitedPostListState } from "@/redux/posts/actions";
import { fetchMoreLimitedPostListThunk } from "@/redux/posts/thunk";
import { lengthsInNumbers } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const DashboardInnerContainer = styled.div`
  padding: 27px 48px;
`;

type Props = {};

const DashboardContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  useEffect(() => {
    dispatch(resetLimitedPostListState());
    dispatch(resetAddressList());
    dispatch(resetCorporationAddressList());
    dispatch(fetchMoreLimitedPostListThunk({ postTypes: [PostType.Notice, PostType.Maintenance] }));
  }, []);

  if (width < lengthsInNumbers.desktop) {
    return <LandingContainer />;
  }

  return (
    <>
      <Layout />
      <OwnersClubModal />
      <DashboardInnerContainer>
        <DashboardCarouselContainer />
        <DashboardAddressListWithSearch />
        <SaleTitledOuterContainer paddingBottom={30}>
          <TitledContainer
            title="최근 등록 매물"
            titleFontSize={14}
            actions={
              <Link to={getUrl("salesRoot")}>
                <div style={{ fontWeight: 400, fontSize: 13 }}>전체보기</div>
              </Link>
            }
          />
          <SaleGradientSlideContainer />
        </SaleTitledOuterContainer>
        <OwnersClubFreeTrialBannerContainer />
        <ReportListContainer />
      </DashboardInnerContainer>
    </>
  );
};

export default DashboardContainer;
