import React, { useMemo } from "react";
import { PostLimitedResponse } from "@doczip/api-client";
import styled from "styled-components";
import Carousel from "@/components/dashboard/common/Carousel";
import { CarouselCardProp } from "@/components/dashboard/common/CarouselCard";
import LoadingCircle from "@/components/LoadingCircle";
import { FetchState } from "@/interfaces/fetch";
import { getUrl } from "@/utils/routes";

const DashboardCarouselContainer = styled.div`
  width: 100%;
`;

interface DashboardCarouselProps {
  posts: PostLimitedResponse[] | null;
  postsFetchState: FetchState;
}

const DashboardCarousel: React.FC<DashboardCarouselProps> = ({ posts, postsFetchState }) => {
  const slideList = useMemo(() => {
    if (!posts) {
      return null;
    }

    const list: CarouselCardProp[] = posts.map(({ id, title }) => ({
      descriptions: [title],
      href: getUrl("postsDetail", { params: { postId: id } }),
    }));

    return list;
  }, [posts, postsFetchState]);

  return (
    <DashboardCarouselContainer>
      {slideList ? <Carousel slideList={slideList} /> : <LoadingCircle />}
    </DashboardCarouselContainer>
  );
};

export default DashboardCarousel;
