import { openDB, DBSchema } from "idb";

// IndexedDB 스키마 정의
interface MyDB extends DBSchema {
  reports: {
    key: string;
    value: { report_id: string; hideModal: boolean };
  };
}

// IndexedDB를 초기화하는 함수
export async function initDB() {
  const db = await openDB<MyDB>("doczip-database", 1, {
    upgrade(db) {
      db.createObjectStore("reports", { keyPath: "report_id" });
    },
  });
  return db;
}

// 데이터 저장 함수
export async function saveReport(report_id: string, hideModal: boolean) {
  const db = await initDB();
  await db.put("reports", { report_id, hideModal });
}

// 데이터 조회 함수
export async function getReport(report_id: string) {
  const db = await initDB();
  return await db.get("reports", report_id);
}

// 데이터 삭제 함수
export async function deleteReport(report_id: string) {
  const db = await initDB();
  await db.delete("reports", report_id);
}

// 전체 데이터 조회 함수
export async function getAllReports() {
  const db = await initDB();
  return await db.getAll("reports");
}
