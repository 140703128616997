import React, { useState } from "react";
import { SaleResponse } from "@doczip/api-client";
import styled from "styled-components";
import LoadingButton from "@/components/buttons/LoadingButton";
import { Button } from "@/components/Mui";
import { ReportInfo } from "@/components/sales/common/SaleType";
import SaleNewModal from "@/containers/sales/SaleNewModal";

interface ContainerProps {
  withLoading?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: ${({ withLoading }) => (withLoading ? "100%" : "auto")};
  margin-top: ${({ withLoading }) => (withLoading ? 8 : 0)}px;
`;

const ClickControlBox = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 120px;
    height: 46px;

    font-weight: 700;
    font-size: 14px;
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  &.MuiLoadingButton-root {
    width: 100%;
    height: 39px;

    color: #ff8f17;
    font-weight: 700;
    font-size: 13px;

    background-color: #fff;
    border: 1px solid #ff8f17;

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

interface SaleModalOpenButtonProps extends ContainerProps {
  as?: React.ReactNode | string;
  saleValues?: SaleResponse | ReportInfo;
}

const SaleModalOpenButton: React.FC<SaleModalOpenButtonProps> = ({ as, saleValues, withLoading = false }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Container withLoading={withLoading}>
      {withLoading ? (
        <StyledLoadingButton onClick={() => setModalOpen(true)}>{as ?? "매물 공유"}</StyledLoadingButton>
      ) : (
        <ClickControlBox onClick={() => setModalOpen(true)}>
          {as ?? <StyledButton className="outlined">매물 공유</StyledButton>}
        </ClickControlBox>
      )}
      {modalOpen && <SaleNewModal open={modalOpen} handleClose={() => setModalOpen(false)} saleValues={saleValues} />}
    </Container>
  );
};

export default SaleModalOpenButton;
