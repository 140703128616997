import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";
import ReportExclamationMark from "@/assets/icons/report-exclamation-mark.svg";
import FlatButton from "@/components/buttons/FlatButton";
import LoadingButton from "@/components/buttons/LoadingButton";
import LoadingCircle from "@/components/LoadingCircle";
import { FetchState } from "@/interfaces/fetch";
import { AddressSearchCategory } from "@/redux/address/types";
import { reportReRequestFetchThunk } from "@/redux/report/thunk";
import { CurrentUsingResource } from "@/redux/report/util";

const ButtonContainer = styled.div`
  position: relative;

  & .__react_component_tooltip {
    max-width: min(90vw, 375px) !important;

    word-break: keep-all;
  }
`;

const RedDotContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 3;

  display: flex;
  align-content: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  background-color: #c91116;
  border-radius: 50%;
`;

const RedDotImage = styled.img`
  display: inline-block;
  width: 4px;
  height: 15px;
`;

const ReRequestText = styled.span`
  color: #797979;
  font-weight: 400;
  font-size: 11px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 13px;
`;

const ToastContentContainer = styled.div`
  display: flex;
  align-content: center;
`;

const ToastText = styled.span`
  margin-left: 8px;

  color: #fff;
  font-weight: 500;
  font-size: 13px;
`;

const MiniButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

type ReportDownloadButtonProps = {
  onClick?: () => void;
  title: string | React.ReactNode;
  disabled?: boolean;
  withDisabledStyle?: boolean;
  loading?: boolean;
  tooltipId?: string;
};

const StyledLoadingButton = styled(LoadingButton)<Pick<ReportDownloadButtonProps, "disabled" | "withDisabledStyle">>`
  &.MuiLoadingButton-root {
    width: 238px;
    height: 39px;

    color: ${({ disabled }) => (disabled ? "#797979" : "#FF8F17")};
    font-weight: 700;
    font-size: 13px;

    background-color: ${({ disabled }) => (disabled ? "#f2f2f2" : "#ffffff")};
    border: 1px solid ${({ disabled }) => (disabled ? "transparent" : "#FF8F17")};

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
  }
  ${({ withDisabledStyle }) =>
    withDisabledStyle &&
    css`
      /* stylelint-disable */
      &.MuiLoadingButton-root {
        color: #797979 !important;

        background-color: #f2f2f2 !important;
        border: none;
        cursor: pointer !important;

        pointer-events: auto !important;
      }
      /* stylelint-enable */
    `}
`;

export const ReportDownloadButton: React.FC<ReportDownloadButtonProps> = ({
  onClick,
  title,
  disabled,
  withDisabledStyle = false,
  loading,
  tooltipId,
}) => {
  const tooltipProps = tooltipId
    ? {
        "data-tip": true,
        "data-for": tooltipId,
      }
    : {};

  return (
    <StyledLoadingButton
      variant="contained"
      disableElevation
      onClick={onClick}
      disabled={disabled}
      withDisabledStyle={withDisabledStyle}
      loading={loading}
      fullWidth
      {...tooltipProps}
    >
      {title}
    </StyledLoadingButton>
  );
};

const issuanceSiteInfo: {
  [x in Exclude<CurrentUsingResource, "registerLedger">]: Array<{
    name: string;
    url: string;
    notice?: string;
  }>;
} = {
  buildingLedger: [
    { name: "정부24", url: "https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=15000000098" },
    {
      name: "세움터",
      url: "https://cloud.eais.go.kr/moct/bci/aza01/BCIAZA01F02?returnUrl=%2Fbci%2FBCIAAA02L01",
    },
  ],
  landLedger: [{ name: "정부24", url: "https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=13100000026" }],
  landUsingInfo: [
    {
      name: "정부24",
      url: "https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A09005&CappBizCD=15000000013",
      notice: "발급 수수료 1000원",
    },
    {
      name: "토지이음",
      url: "https://www.eum.go.kr/web/ar/lu/luLandDet.jsp",
      notice: "열람 수수료 무료",
    },
  ],
};

type DocumentDownloadListProps = {
  type?: AddressSearchCategory;
  reportId: number | null;
  title: string;
  urls?: string[];
  fetchState: FetchState;
  disabledMessage?: string;
  disabled?: boolean;
  resource: CurrentUsingResource;
  onClick?: () => void;
};

const DocumentDownloadList: React.FC<DocumentDownloadListProps> = ({
  type = "normal",
  reportId,
  title,
  urls,
  fetchState,
  disabled,
  disabledMessage,
  resource,
  onClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();

  // 재발급 실패 시 url에 추가되는 query 가져오기
  const issuance = searchParams.get("issuance");

  function getIssuanceSiteInfo(value: string | null) {
    if (resource === value) {
      switch (resource) {
        case "buildingLedger":
          return issuanceSiteInfo.buildingLedger;
        case "landLedger":
          return issuanceSiteInfo.landLedger;
        case "landUsingInfo":
          return issuanceSiteInfo.landUsingInfo;
        default:
          return void 0;
      }
    } else {
      return void 0;
    }
  }

  const isIssuanceSiteInfo = getIssuanceSiteInfo(issuance) !== void 0;
  const tooltipMesagge = isIssuanceSiteInfo
    ? "아래 문서 제공 사이트를 방문하여 확인해주시길 바랍니다."
    : `문서 제공 사이트의 점검 또는 일시적인 문제, 혹은 해당 주소의 공문서 없음 등의 이유로 발급이
실패할 수 있습니다.`;

  const openUrl = (url: string) => {
    return () => window.open(url);
  };

  const reportReRequest = () => {
    if (reportId) {
      toast(
        <ToastContentContainer>
          <LoadingCircle size={16} disabledPadding />
          <ToastText>재요청 진행중입니다.</ToastText>
        </ToastContentContainer>,
        {
          position: "bottom-center",
          theme: "dark",
          hideProgressBar: true,
          autoClose: 1500,
          onOpen: () =>
            dispatch(
              reportReRequestFetchThunk({
                type,
                reportId,
                resource,
              }),
            ),
          onClose: () => {
            const defaultSearch = `?issuance=${resource}`;
            const search = type === "corporation" ? defaultSearch + "&type=corporation" : defaultSearch;
            navigate({
              pathname: `/reports/${params.reportId}`,
              search,
            });
            window.location.reload();
          },
        },
      );
    }
  };

  if (typeof urls === "string") {
    urls = [urls];
  }

  if (fetchState === "FAILURE") {
    const tooltipId = `fail-info-${title.replace(/ /g, "_")}`;
    return (
      <ButtonContainer>
        <RedDotContainer>
          <RedDotImage src={ReportExclamationMark} />
        </RedDotContainer>

        <ReportDownloadButton
          tooltipId={tooltipId}
          title={
            <ReRequestText>
              <Title>{title}</Title> <span>{isIssuanceSiteInfo ? "(발급처 링크 확인)" : "(클릭시 재요청)"}</span>
            </ReRequestText>
          }
          onClick={isIssuanceSiteInfo ? () => {} : () => reportReRequest()}
          withDisabledStyle
        />
        {isIssuanceSiteInfo && (
          <MiniButtonContainer>
            {getIssuanceSiteInfo(issuance)?.map((item) => (
              <>
                <FlatButton
                  data-tip="true"
                  data-for={`info-${item.notice}`}
                  onClick={() => {
                    window.open(item.url, "_blank");
                  }}
                >
                  {item.name}
                </FlatButton>
                {item.notice && (
                  <ReactTooltip id={`info-${item.notice}`} effect="solid">
                    <span>{item.notice}</span>
                  </ReactTooltip>
                )}
              </>
            ))}
          </MiniButtonContainer>
        )}
        <ReactTooltip id={tooltipId} effect="solid">
          <span>{tooltipMesagge}</span>
        </ReactTooltip>
      </ButtonContainer>
    );
  }

  if (fetchState === "FETCHING") {
    return <ReportDownloadButton title={title} disabled loading />;
  }

  if (disabled || !urls || urls.length === 0) {
    return <ReportDownloadButton title={title} disabled />;
  }

  const isMultiple = urls.length > 1;

  let tooltipId = "";
  let tooltipMessage = "";

  const generateTooltipId = (title: string) => {
    return `success-info-${title.replace(/ /g, "_")}`;
  };

  switch (title) {
    case "건축물대장":
      tooltipMessage = "일반건축물대장은 해당 건물만 표시하고, 집합건축물대장은 “호수” 단위로 표시합니다.";
      tooltipId = generateTooltipId(title);
      break;
    case "건축물대장 표제부":
      tooltipMessage = "집합건축물대장에서 “동” 단위로 표시합니다.";
      tooltipId = generateTooltipId(title);
      break;
    case "건축물대장 총괄":
      tooltipMessage = "신청하신 지번 주소(토지 기준)에 있는 모든 건물을 표시합니다.";
      tooltipId = generateTooltipId(title);
      break;
    default:
      tooltipMessage = "";
  }

  return (
    <>
      {urls.map((url, index) => (
        <>
          <ReportDownloadButton
            tooltipId={tooltipId && tooltipId}
            key={`${title}_${index}`}
            onClick={onClick ?? openUrl(url)}
            title={`${title}${isMultiple ? ` ${index + 1}` : ""}`}
          />
          <ReactTooltip id={tooltipId} effect="solid">
            <span>{tooltipMessage}</span>
          </ReactTooltip>
        </>
      ))}
    </>
  );
};

export default DocumentDownloadList;
