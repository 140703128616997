import React from "react";
import { PostType } from "@doczip/api-client";
import { useNavigate } from "react-router-dom";
import BorderSelect from "@/components/posts/common/BorderSelect ";
import useQuery from "@/hooks/useQuery";
import { getUrl } from "@/utils/routes";

const PostTypeSelect = () => {
  const navigator = useNavigate();
  const query = useQuery();

  const getParms = () => {
    const type = query.get("type");

    if (type) {
      const index = postTypeList.findIndex((postType) => (postType.key[0] as string) === type);
      return postTypeList[index];
    } else {
      return postTypeList[0];
    }
  };

  const changePostType = (postTypes: PostType[]) => {
    const query = postTypes.join(",");
    navigator(getUrl("postsRoot", { query: query ? { type: query } : {} }));
  };

  const postTypeList = [
    {
      title: "전체",
      key: [],
      handleClick: changePostType,
    },
    {
      title: "서비스",
      key: [PostType.Notice],
      handleClick: changePostType,
    },
    {
      title: "점검 일정",
      key: [PostType.Maintenance],
      handleClick: changePostType,
    },
  ];

  return <BorderSelect options={postTypeList} defaultOption={getParms()} />;
};

export default PostTypeSelect;
