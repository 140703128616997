import { numToKorean } from "num-to-korean";

const ABSOLUTE_URL_PATTERN = /^\w+:\/\//;

export function capitalize<T extends string>(text: T): Capitalize<T> {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}` as Capitalize<T>;
}

export function padding(text: string, pad: string, length: number, trailing = false): string {
  if (text.length >= length || pad === "") {
    return text;
  }
  const repeat = Math.ceil((length - text.length) / pad.length);
  const fullPad = pad.repeat(repeat);

  if (trailing) {
    return (text + fullPad).substring(0, length);
  } else {
    const concated = fullPad + text;
    return concated.substring(concated.length - length, length);
  }
}

export function zeroPadding(n: number, length: number) {
  return padding(n.toString(), "0", length, false);
}

export function amountToKorean(n: number, cutoff?: number, mode?: string) {
  const number = cutoff ? Math.floor(n / cutoff) * cutoff : n;
  return numToKorean(number, mode || "spacing").replace(/,/g, "");
}

export function isAbsoluteUrl(s: string) {
  return ABSOLUTE_URL_PATTERN.test(s);
}
