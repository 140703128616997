import { CheckUserExistsResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { loadLoginState } from "./functions";
import { AuthState } from "./types";
import { clearToken, removeAuthToken } from "@/client";
import { makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getTypedAsyncInitialState, getKeyListFromState } from "@/utils/redux/state";

const getAsyncInitialState = getTypedAsyncInitialState<AuthState>();
const makeAsyncReducer = makeTypedAsyncReducer<AuthState>();

const initialState: AuthState = {
  ...getAsyncInitialState("userExists"),
  ...getAsyncInitialState("signUp"),
  ...getAsyncInitialState("selfCertification"),
  loginFetchState: loadLoginState(),
  resetPasswordFetchState: "READY",
};

export const AUTH_STATE_KEY_LIST = getKeyListFromState(initialState);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => {
      clearToken();
      removeAuthToken();
      return {
        ...initialState,
        loginFetchState: "READY",
      } as const;
    },
    ...makeAsyncReducer<"userExists", void, CheckUserExistsResponse>("userExists"),
    ...makeAsyncReducer("login"),
    ...makeAsyncReducer("signUp"),
    ...makeAsyncReducer<"selfCertification", void, string>("selfCertification"),
    ...makeAsyncReducer("resetPassword"),
  },
});

export const authReducer = authSlice.reducer;
