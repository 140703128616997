import styled from "styled-components";

const SignInFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;

  font-size: 14px;
  text-align: center;

  > a,
  > div {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export default SignInFooter;

export const SignInFooterRow = styled.div``;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 14px;

  background-color: #d9d9d9;
`;

export const ResetPasswordFooterContainer = styled.div`
  margin-top: 12px;

  white-space: nowrap;
  text-align: center;

  > a {
    color: #797979;
    font-weight: 400;
    font-size: 13px;
  }
`;
