import React, { useMemo } from "react";
import { ReportBuildingLedger } from "@blitz-core/api-client";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";

const ReportBuildingInfoContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const WarningText = styled.span`
  color: #f00;
  font-weight: 700;
`;

const BuildingInfoContent = styled.div``;

const BuildingInfoTextList = styled.ul``;

const BuildingInfoTextItem = styled.li``;

const BuildingInfoText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;
`;

interface ReportBuildingInfoProps {
  buildingLedger?: ReportBuildingLedger;
  reportFetchState: FetchState;
}

const ReportBuildingInfo: React.FC<ReportBuildingInfoProps> = ({ buildingLedger, reportFetchState }) => {
  const buildingLedgerInfo = useMemo(() => {
    if (
      !buildingLedger ||
      (!buildingLedger.index &&
        !buildingLedger.complex &&
        !(buildingLedger.primaries && buildingLedger.primaries.length))
    ) {
      return;
    }

    const violated =
      buildingLedger.index?.violated ||
      buildingLedger.complex?.violated ||
      buildingLedger.primaries?.some(({ violated }) => violated);

    const location =
      buildingLedger.index?.location ||
      buildingLedger.complex?.location ||
      buildingLedger.primaries?.find(({ location }) => location)?.location;

    if (typeof violated === "undefined" && typeof location === "undefined") {
      return;
    }

    return {
      violated,
      location,
    };
  }, [buildingLedger]);

  const buildingDescription = useMemo(() => {
    if (!buildingLedgerInfo || reportFetchState === "FAILURE") {
      return ["🏗 건물정보가 없습니다."];
    }

    const { violated, location } = buildingLedgerInfo;

    let violationText: React.ReactNode[] = [];

    if (typeof violated !== "undefined") {
      violationText = violated
        ? [
            <>
              🏗 <WarningText>주의!</WarningText> 위반건축물 기록을 대장에서 한번 더 확인해주세요!
            </>,
          ]
        : [`🏗 불법건축물이 아닙니다. 안전하다고 볼 수 있습니다.`];
    }

    let locationText: React.ReactNode[] = [];

    if (typeof location !== "undefined") {
      locationText = [`📋 지역은 ${location}입니다.`];
    }

    return [...violationText, ...locationText];
  }, [buildingLedgerInfo, reportFetchState]);

  return (
    <TitledWrapper
      title="건물 정보"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
    >
      <ReportBuildingInfoContainer>
        {["SUCCESS", "FAILURE"].includes(reportFetchState) ? (
          <BuildingInfoContent>
            <BuildingInfoTextList>
              {buildingDescription.map((value, index) => (
                <BuildingInfoTextItem key={index}>
                  <BuildingInfoText>{value}</BuildingInfoText>
                </BuildingInfoTextItem>
              ))}
            </BuildingInfoTextList>
          </BuildingInfoContent>
        ) : (
          <LoadingCircle />
        )}
      </ReportBuildingInfoContainer>
    </TitledWrapper>
  );
};

export default ReportBuildingInfo;
