import React, { useState } from "react";
import { SaleLimitedResponse } from "@doczip/api-client";
import { useNavigate } from "react-router";
import styled from "styled-components";
import DefaultThumbnail1 from "../../assets/images/default_thumbnail1.png";
import DefaultThumbnail2 from "../../assets/images/default_thumbnail2.png";
import DefaultThumbnail3 from "../../assets/images/default_thumbnail3.png";
import DefaultThumbnail4 from "../../assets/images/default_thumbnail4.png";
import DoczipMark from "../../assets/images/doczip_mark.svg";
import BarSeparator from "@/components/sales/common/BarSeparator";
import MarkEmbed from "@/components/sales/common/MarkEmbed";
import Configs from "@/configs";
import useWindowSize from "@/hooks/useWindowSize";
import { getUrl } from "@/utils/routes";
import { addSubfix, convertTradeTypeToString, numberToKorean } from "@/utils/sales";

const Container = styled.div`
  position: relative;

  display: flex;
  gap: 18px;
  width: 398px;
  min-width: 398px;
  height: 150px;
  padding: 19px;

  background-color: white;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  @media screen and (max-width: 864px) {
    min-width: 100%;

    box-shadow: none;
  }

  cursor: pointer;

  transition: all ease 0.2s;

  &:hover {
    box-shadow: 0 20px 30px -3px rgb(0 0 0 / 0.1), 0 8px 12px -4px rgb(0 0 0 / 0.1);
    transform: scale(1.02);
  }
`;

const StyledBorderBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 19px;

  display: none;
  width: calc(100% - 38px);
  height: 1px;

  background-color: #dfe2e7;

  @media screen and (max-width: 864px) {
    display: inline-block;
  }
`;

const Thumbnail = styled.img`
  width: 112px;
  min-width: 112px;
  height: 112px;

  border-radius: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #333;
  font-weight: 500;
  font-size: 14px;
`;

const Address = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 12px;
`;

const Price = styled.div`
  color: #333;
  font-weight: 700;
  font-size: 14px;
`;

type Props = {
  sale: SaleLimitedResponse;
};

const DefaultThumbnail = [DefaultThumbnail1, DefaultThumbnail2, DefaultThumbnail3, DefaultThumbnail4];
const SaleListItem = ({ sale }: Props) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(
    sale.image_sets.length > 0 ? Configs.DOCZIP_REST_API_URL + sale.image_sets[0].thumbnail_image_path : void 0,
  );

  const cutLengthOverText = (txt: string, len: number = 37, lastTxt: string = "...") => {
    if (txt.length > len) {
      txt = txt.substring(0, len) + lastTxt;
    }
    return txt;
  };

  const barStringItems = [
    sale.supply_area ? addSubfix(Math.floor(sale.supply_area), "㎡ 공용") : null,
    sale.exclusive_area ? addSubfix(Math.floor(sale.exclusive_area), "㎡ 전용") : null,
    sale.floor ? addSubfix(sale.floor, "층") : null,
  ];

  return (
    <Container onClick={() => navigate(getUrl("salesDetail", { params: { saleId: sale.id } }))}>
      {imgSrc ? (
        <Thumbnail src={imgSrc} onError={() => setImgSrc(DefaultThumbnail[sale.id % 4])} />
      ) : (
        <MarkEmbed markSrc={DoczipMark}>
          <Thumbnail src={DefaultThumbnail[sale.id % 4]} />
        </MarkEmbed>
      )}
      <InfoContainer>
        <Title>{cutLengthOverText(sale.report_log.registered_address, width > 445 ? 37 : 25)}</Title>
        <BarSeparator items={barStringItems} />
        <Address>{cutLengthOverText(sale.report_log.registered_address, width > 445 ? 40 : 28)}</Address>
        <Price>
          {convertTradeTypeToString(sale.trade_type)} {numberToKorean(sale.price)}
          {sale.rent_price && "/" + numberToKorean(sale.rent_price)} 원
        </Price>
      </InfoContainer>
      <StyledBorderBottom />
    </Container>
  );
};

export default SaleListItem;
