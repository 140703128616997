import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavigationContainer from "@/containers/NavigationContainer";
import { colors, depths, lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const SideColumnBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${depths.header};

  width: ${lengths.sideColumnWidth};
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  padding-top: ${lengths.headerHeight};

  background-color: ${colors.white};

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const LogoBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${lengths.headerHeight};
  padding-left: 25px;

  border-bottom: 1px solid #eee;
`;

const Logo = styled.div`
  display: block;
  width: 120px;
  height: 23.5px;
  margin-right: 28px;

  background-image: url("/images/logo.svg");
  background-size: 120px 23.5px;
`;

type Props = {};

const SideColumn: React.FC<Props> = () => {
  return (
    <SideColumnBox>
      <LogoBox>
        <Link to={getUrl("dashboard")}>
          <Logo />
        </Link>
      </LogoBox>
      <NavigationContainer />
    </SideColumnBox>
  );
};

export default SideColumn;
