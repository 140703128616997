import * as yup from "yup";

type EnumLike<K extends string> = { [KK in K]: any };

function getEnumTestOptions<K extends string, T extends EnumLike<K>>(
  enumLike: T,
  message?: yup.TestOptionsMessage,
): yup.TestOptions {
  return {
    name: "enum",
    message,
    test<T extends yup.TestContext>(this: T, value?: any) {
      return typeof value === "undefined" || value === null || (typeof value === "string" && value in enumLike);
    },
  };
}

export function enumSchema<K extends string, T extends EnumLike<K>>(
  enumLike: T,
  message: yup.TestOptionsMessage = `\${path} is invalid enum key`,
) {
  return yup.mixed<keyof T>().test(getEnumTestOptions(enumLike, message));
}

const isStartedRegularNumber = /^010/;
const phoneNumberRegex = /^010?\d{3,4}?\d{4}$/;
const passwordRegex =
  /(?=.*[0-9a-zA-Z]{1})(?=.*[a-zA-Z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{1})(?=.*[0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{1})/;
const idRegex = /^(?=.*[a-z])[a-z0-9]{6,32}$/;
const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const phoneNumberSchema = yup
  .string()
  .matches(isStartedRegularNumber, "010 으로 시작하는 휴대폰 번호를 입력해주세요.")
  .matches(phoneNumberRegex, "올바른 형식의 휴대폰 번호가 아닙니다.")
  .required("휴대폰 번호를 입력해주세요.");

export const passwordSchema = yup
  .string()
  .min(6, "비밀번호는 최소 6자 이상이어야 합니다.")
  .max(32, "비밀번호는 최대 32자 이하여야 합니다.")
  .matches(passwordRegex, "비밀번호는 영문자, 숫자, 특수문자 중 두가지 이상으로 구성되어야 합니다.")
  .required("비밀번호를 입력해주세요.");

export const idSchema = yup
  .string()
  .matches(idRegex, "아이디는 6자 이상, 32자 이하의 영문, 숫자 조합이어야 합니다.")
  .required("아이디를 입력해주세요.");

export const nicknameSchema = yup
  .string()
  .matches(/^[가-힣a-zA-Z0-9]{2,15}$/, "닉네임은 2자 이상, 15자 이하의 한글 또는 영문이어야 합니다.")
  .required("닉네임을 입력해주세요.");

export const emailSchema = yup
  .string()
  .matches(emailRegex, "이메일형식으로 작성되어야 합니다.")
  .required("이메일을 입력해주세요.");

export const nameSchema = yup
  .string()
  .max(32, "이름은 최대 32자까지 작성 가능합니다.")
  .required("이름을 입력해주세요.");
