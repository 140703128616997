import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@/styles/values";

const CarouselCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;

  color: #333;
  font-weight: 400;
  font-size: 13px;

  background-color: ${colors.white};
  cursor: pointer;
`;

export interface CarouselCardProp {
  descriptions?: React.ReactNode[];
  href?: string;
  onClick?: () => void;
}

const CarouselCard = ({ descriptions, href, onClick }: CarouselCardProp) => {
  const navigate = useNavigate();

  const handleClick = React.useMemo(() => {
    if (onClick) {
      return onClick;
    }

    if (href) {
      return () => {
        navigate(href);
      };
    }
  }, [navigate, href, onClick]);

  return <CarouselCardContainer onClick={handleClick}>{descriptions}</CarouselCardContainer>;
};

export default CarouselCard;
