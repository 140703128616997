import React, { useMemo } from "react";
import { RegisterLedgerOwnershipSummary } from "@blitz-core/api-client";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { Checkbox as MuiCheckbox } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getCheckedOwner } from "@/redux/report/selectors";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div``;

const Checkbox = styled(MuiCheckbox)``;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-align: left;
`;

const OwnerInfo = styled.span`
  margin-bottom: 5px;

  color: #333;
  font-weight: 500;
  font-size: 14px;
`;

const Address = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 13px;
`;

interface AddressCardProps {
  ownerSummary: RegisterLedgerOwnershipSummary;
}

const AddressCard: React.FC<AddressCardProps> = ({ ownerSummary }) => {
  const checkedOwner = useSelector(getCheckedOwner);

  const ownerInfo = useMemo(() => {
    if (!ownerSummary || !ownerSummary?.owner || !ownerSummary?.identity_code || !ownerSummary?.ownership) {
      return;
    }

    const { owner, identity_code, ownership } = ownerSummary;

    const ownerName = owner?.split("(")[0];
    const ownerBirthDate = identity_code?.split("-")[1] === "*******" ? `${identity_code?.slice(0, 2)}년생` : "";

    return `${ownerBirthDate} ${ownerName} (${ownership})`;
  }, [ownerSummary]);

  const address = useMemo(() => {
    if (!ownerSummary || !ownerSummary?.address) {
      return;
    }

    return ownerSummary.address;
  }, [ownerSummary]);

  return (
    <Container>
      <CheckboxContainer>
        <Checkbox
          checked={checkedOwner?.owner === ownerSummary.owner}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<RadioButtonChecked />}
        />
      </CheckboxContainer>

      <TextContainer>
        <OwnerInfo>{ownerInfo}</OwnerInfo>
        <Address>{address}</Address>
      </TextContainer>
    </Container>
  );
};

export default AddressCard;
