export function setItemWithExpiry(key: string, value: string, ttl?: number) {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + (ttl ?? 24 * 60 * 60 * 1000),
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    // 만료 시간이 지났다면 값 제거
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
