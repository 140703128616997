import React, { useLayoutEffect, useRef, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";

const Container = styled.div`
  position: relative;

  width: 100%;
`;

const Gradient = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  width: 115px;
  height: 150px;
`;

const FrontGradient = styled(Gradient)`
  top: 0;
  right: 0;
  z-index: 10;

  justify-content: flex-end;

  background: linear-gradient(to right, rgba(0, 0, 0, 0) 5%, white);
`;

const BackGradient = styled(Gradient)`
  top: 0;
  left: 0;
  z-index: 10;

  justify-content: flex-start;

  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0 5%, white);
`;

const SlideButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 25px;

  background-color: inherit;
  border: none;
  border-radius: 15px;
  cursor: pointer;
`;

const ForwardButton = styled(SlideButton)``;

const BackButton = styled(SlideButton)``;

const SlideContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  padding: 10px 0 20px 0;
  overflow: scroll;

  scroll-behavior: smooth;
`;

type GradientSlideProps = {
  children: React.ReactNode | React.ReactNode[];
};

const GradientSlide = ({ children }: GradientSlideProps) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const [showForward, setShowForward] = useState(false);
  const [showBack, setShowBack] = useState(false);

  useLayoutEffect(() => {
    if (slideRef.current) {
      if (
        !(slideRef.current.scrollWidth - 70 < slideRef.current.scrollLeft + slideRef.current.clientWidth) &&
        slideRef.current.scrollWidth > slideRef.current.offsetWidth
      ) {
        setShowForward(true);
      }
    }
  });

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollLeft > 70) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }

    if (e.currentTarget.scrollWidth - 70 < e.currentTarget.scrollLeft + e.currentTarget.clientWidth) {
      setShowForward(false);
    } else {
      setShowForward(true);
    }
  };

  const handleArrowClick = (arrowType: "front" | "back") => {
    if (slideRef && slideRef.current) {
      if (arrowType === "front") {
        slideRef.current.scrollLeft += slideRef.current.offsetWidth - 140;
      } else if (arrowType === "back") {
        slideRef.current.scrollLeft -= slideRef.current.offsetWidth - 140;
      }
    }
  };

  return (
    <Container>
      {showBack && (
        <BackGradient>
          <BackButton onClick={() => handleArrowClick("back")}>
            <ArrowBackIosNewIcon sx={{ color: "#797979", fontSize: "18px" }} />
          </BackButton>
        </BackGradient>
      )}
      <SlideContainer onScroll={handleScroll} ref={slideRef}>
        {children}
      </SlideContainer>
      {showForward && (
        <FrontGradient>
          <ForwardButton onClick={() => handleArrowClick("front")}>
            <ArrowForwardIosIcon sx={{ color: "#797979", fontSize: "18px" }} />
          </ForwardButton>
        </FrontGradient>
      )}
    </Container>
  );
};

export default GradientSlide;
