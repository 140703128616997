import React from "react";
import styled from "styled-components";

const LandingAboutContainer = styled.div`
  margin-bottom: 3.4375rem;
`;

const FeatureText = styled.span`
  color: #4a3aff;
  font-weight: 700;
  font-size: 1.125rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.0625rem;
`;

const Title = styled.span`
  color: #081a51;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 4.735rem;
`;

const SubTitle = styled.span`
  color: #ff8f17;
  font-weight: 500;
  font-size: 1.5rem;
`;

const Description = styled.span`
  color: #25273fb3;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
`;

const LandingAbout: React.FC<{}> = () => {
  return (
    <LandingAboutContainer>
      <FeatureText>주소 입력 한 번으로 간편하게</FeatureText>

      <TitleContainer>
        <Title>공부서류 퀵-열람, DOCZIP</Title>
        <SubTitle>공부서류 확인하고 거래 문서 작성까지!</SubTitle>
      </TitleContainer>

      <Description>
        주소 입력하면 한 번에 해당 주소지 건물에 대한 소유주 정보, 대출정보, 불법건축물 여부를 안내드립니다. 일일이
        건축물대장, 등기부등본을 떼보지 않아도 필요한 기능만 쏙쏙 제공해주는 정말 간편하고 스마트한 서비스를 만나보세요.
      </Description>
    </LandingAboutContainer>
  );
};

export default LandingAbout;
