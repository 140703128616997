import React from "react";
import styled from "styled-components";
import { colors } from "@/styles/values";

const PaperContainerBox = styled.div`
  position: relative;

  width: 100%;
  margin: 0;
  padding: 0;

  font-family: "Spoqa Han Sans Neo", "Noto Sans KR", "Noto Sans CJK", sans-serif;

  background-color: ${colors.lighterGray};

  & * {
    box-sizing: border-box;
  }
`;

const PaperContainerInnerBox = styled.div`
  width: 100%;
  padding: 16px 0;
`;

const Container: React.FC = ({ children }) => {
  return (
    <PaperContainerBox>
      <PaperContainerInnerBox>{children}</PaperContainerInnerBox>
    </PaperContainerBox>
  );
};

export default Container;
