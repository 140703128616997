import React from "react";
import styled from "styled-components";
import Box from "./Box";

const TableContainer = styled.table`
  width: 100%;

  text-align: center;
  page-break-inside: auto;

  table-layout: fixed;
  border-top: 1px solid #666;
  border-collapse: collapse;

  & td {
    vertical-align: center;

    border-right: 1px solid #666;
    border-bottom: 1px solid #666;
  }

  & td:last-of-type {
    border-right: 0;
  }
`;

const TableRow = styled.tr`
  page-break-after: auto;
  page-break-inside: avoid;
`;

const HiddenRow = styled.tr`
  & td {
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;

    border: 0 !important;
  }
`;

type Border = "default" | "normal" | "dark" | "heavy" | "none";

type Props = {
  columnSizes?: number[];
  row?: boolean;
  height?: number;
  vborder?: Border;
  tborder?: Border;
  bborder?: Border;
  bmargin?: number;
};

const borderMap: Record<Border, string> = {
  default: "0",
  normal: "1px solid #666",
  dark: "1px solid #000",
  heavy: "4px solid #666",
  none: "1px solid transparent",
};

const Table: React.FC<Props> = (props) => {
  if (props.row) {
    const isBox = (node: React.ReactElement<any>) => {
      return node.type === Box;
    };
    if (Array.isArray(props.children)) {
      const newChildren = props.children.map((child, i): React.ReactNode => {
        if (React.isValidElement(child) && isBox(child)) {
          if (
            typeof (child as React.ReactElement).props.rowSpan === "undefined" ||
            (child as React.ReactElement).props.rowSpan === 1
          ) {
            return React.cloneElement(child, { key: i, ...(props.height ? { height: props.height } : {}) });
          }
        }
        return child;
      });
      return <TableRow>{newChildren}</TableRow>;
    } else if (React.isValidElement(props.children) && isBox(props.children)) {
      const newChild = React.cloneElement(props.children, {
        ...(props.height ? { height: props.height } : {}),
      });
      return <TableRow>{newChild}</TableRow>;
    }
    return <TableRow>{props.children}</TableRow>;
  }

  const getHiddenRow = () => {
    if (typeof props.columnSizes === "undefined") {
      return <></>;
    }
    const columns = props.columnSizes.map((width, i) => <Box td key={i} width={width} />);
    return (
      <HiddenRow>
        {columns}
        <Box td />
      </HiddenRow>
    );
  };
  const getBorderStyle = (key: keyof React.CSSProperties, borderType?: Border): React.CSSProperties => {
    if (typeof borderType === "undefined" || borderType === "default") {
      return {};
    } else {
      return { [key]: borderMap[borderType] };
    }
  };
  const borderTopStyle = getBorderStyle("borderTop", props.tborder || props.vborder);
  const borderBottomStyle = getBorderStyle("borderBottom", props.bborder || props.vborder);
  const style: React.CSSProperties = {
    ...borderTopStyle,
    ...borderBottomStyle,
    marginBottom: `${props.bmargin}px` || "0",
  };
  return (
    <TableContainer style={style}>
      <tbody>
        {getHiddenRow()}
        {props.children}
      </tbody>
    </TableContainer>
  );
};

export default Table;
