import React from "react";
import { DocumentResponse, ResidentialManualResponse, PersonResponse } from "@doczip/api-client";
import ManualAgentView from "./ManualAgentView";
import ManualPersonView from "./ManualPersonView";
import Box from "@/components/paper/Box";
import Date from "@/components/paper/Date";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const ManualBrokerageSignView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  const getPeople = (title: string, people: PersonResponse[]): React.ReactNode[] =>
    people.map((person, i) => <ManualPersonView key={i} title={title} value={person} />);
  const ownerLabel = body.is_trade ? "매도인" : "임대인";
  const consumerLabel = body.is_trade ? "매수인" : "임차인";
  const getOwners = (): React.ReactNode[] => getPeople(ownerLabel, document.owners || []);
  const getConsumers = (): React.ReactNode[] => getPeople(consumerLabel, document.consumers || []);
  const getAgents = (): React.ReactNode[] =>
    (document.agents || []).map((agent, i) => <ManualAgentView key={i} value={agent} />);

  return (
    <Table bmargin={10} columnSizes={[110, 130, 330, 150]}>
      <Table row height={130}>
        <Box td colSpan={5} font="label" align="left">
          <Box inlineBlock>
            「공인중개사법」 제25조제3항 및 제30조제5항에 따라 거래당사자는 개업공인중개사로부터 위 중개대상물에 관한
            확인ᆞ설명 및 손해배상책임의 보장에 관한 설명을 듣고, 같은 법 시행령 제21조제3항에 따른 본 확인ᆞ설명서와 같은
            법 시행령 제24조제2항에 따른 손해배상책임 보장 증명서류(사본 또는 전자문서)를 수령합니다.
          </Box>
          <br />
          <Box inlineBlock width={1000} align="right">
            <Date value={body.delivery_date} />
          </Box>
        </Box>
      </Table>
      {getOwners()}
      {getConsumers()}
      {getAgents()}
    </Table>
  );
};

export default ManualBrokerageSignView;
