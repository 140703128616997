import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingCircle from "@/components/LoadingCircle";
import PostShow from "@/components/posts/PostShow";
import { resetPostState } from "@/redux/posts/actions";
import { getPost, getPostFetchState, getUpdatePostFetchState } from "@/redux/posts/selectors";
import { deletePostThunk, fetchPostThunk } from "@/redux/posts/thunk";
import { confirm } from "@/utils/dialog";
import { getUrl } from "@/utils/routes";

type Props = {};

const PostShowContainer: React.FC<Props> = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const post = useSelector(getPost);
  const postFetchState = useSelector(getPostFetchState);
  const updatePostFetchState = useSelector(getUpdatePostFetchState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (postId) {
      dispatch(fetchPostThunk(Number(postId)));
    }
  }, [postId]);

  useEffect(() => {
    if (updatePostFetchState !== "SUCCESS") {
      return;
    }

    if (postId) {
      dispatch(resetPostState());
      dispatch(fetchPostThunk(Number(postId)));
    }
  }, [updatePostFetchState]);

  const handleDelete = async () => {
    if (await confirm("정말로 삭제하시겠습니까?")) {
      if (postId) {
        dispatch(deletePostThunk(Number(postId)));
        navigate(getUrl("postsRoot"));
      }
    }
  };

  return (
    <>{post ? <PostShow post={post} fetchState={postFetchState} handleDelete={handleDelete} /> : <LoadingCircle />}</>
  );
};

export default PostShowContainer;
