import styled from "styled-components";

export const SidoSigunguSelectorContainer = styled.div`
  display: flex;
  width: 100%;

  > div:not(:last-child) {
    margin-right: 12px;
  }
`;

export const RealtorSearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;

  > button {
    height: 100%;
    margin-left: 8px;
  }
`;
