import React from "react";
import { UserResponse } from "@doczip/api-client";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import TextFieldInnerButton, {
  TextFieldPlainInnerButton,
  TextFieldMultiInnerButtonContainer,
} from "@/components/buttons/TextFieldInnerButton";
import { TextFieldWithInnerButton } from "@/components/TextField";
import TitledContainer from "@/components/TitledContainer";
import { changePhoneNumberThunk, sendVerificationCodeThunk } from "@/redux/user/thunk";
import { phoneNumberSchema } from "@/utils/validator";

interface ChangePhoneNumberField {
  phoneNumber: string;
  verificationCode: string;
}

const ChangePhoneNumberSchema = yup.object<ChangePhoneNumberField>({
  phoneNumber: phoneNumberSchema,
  verificationCode: yup.string().required("본인인증을 해 주세요"),
});

interface ChangePhoneNumberProps {
  userMe: UserResponse | null;
}

export default function ChangePhoneNumber({ userMe }: ChangePhoneNumberProps) {
  const dispatch = useDispatch();

  const {
    handleSubmit: handleChangePhoneNumberSubmit,
    getFieldProps: getChangePhoneNumberFieldProps,
    values: changePhoneNumberValues,
    errors: changePhoneNumberErrors,
  } = useFormik<ChangePhoneNumberField>({
    initialValues: {
      phoneNumber: userMe?.detail?.phone_number?.replaceAll("-", "") ?? "",
      verificationCode: "",
    },
    validationSchema: ChangePhoneNumberSchema,
    onSubmit: ({ phoneNumber, verificationCode }) => {
      dispatch(
        changePhoneNumberThunk({
          currentPhoneNumber: userMe?.detail?.phone_number ?? "",
          newPhoneNumber: phoneNumber,
          verificationCode,
        }),
      );
    },
  });

  const handleSendVerificationCode = () => {
    if (!changePhoneNumberValues.phoneNumber) {
      return;
    }

    dispatch(sendVerificationCodeThunk(changePhoneNumberValues.phoneNumber));
  };

  return (
    <form onSubmit={handleChangePhoneNumberSubmit}>
      <TitledContainer title="휴대폰 번호" />
      <TextFieldWithInnerButton
        label="휴대폰 번호"
        helperText={changePhoneNumberErrors.phoneNumber}
        error={!!changePhoneNumberErrors.phoneNumber}
        {...getChangePhoneNumberFieldProps("phoneNumber")}
        innerButton={
          <TextFieldMultiInnerButtonContainer>
            <TextFieldPlainInnerButton type="button" onClick={handleSendVerificationCode}>
              인증
            </TextFieldPlainInnerButton>
          </TextFieldMultiInnerButtonContainer>
        }
      />
      <TextFieldWithInnerButton
        label="인증번호"
        helperText={changePhoneNumberErrors.verificationCode}
        error={!!changePhoneNumberErrors.verificationCode}
        {...getChangePhoneNumberFieldProps("verificationCode")}
        innerButton={
          <TextFieldMultiInnerButtonContainer>
            <TextFieldInnerButton
              type="submit"
              disabled={!changePhoneNumberValues.verificationCode || !!changePhoneNumberErrors.verificationCode}
            >
              수정
            </TextFieldInnerButton>
          </TextFieldMultiInnerButtonContainer>
        }
      />
    </form>
  );
}
