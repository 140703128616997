import styled from "styled-components";
import TextFieldInnerButton from "../buttons/TextFieldInnerButton";

export const FormContainer = styled.form`
  width: 100%;
`;

export const StyledTextFieldInnerButton = styled(TextFieldInnerButton)`
  &.MuiButtonBase-root {
    width: unset;
    padding-right: 10px;
    padding-left: 10px;
  }
`;
