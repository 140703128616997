import React from "react";
import TextField from "./TextField";
import { amountToKorean } from "@/utils/text";

type Props = React.ComponentProps<typeof TextField>;

const AmountField: React.FC<Props> = (props) => {
  const amountProps: Props = {
    type: "number",
    ...props,
    helperText: `${amountToKorean(parseInt(`${props.value}`, 10))}원`,
  };
  // @ts-ignore: Ignore errors caused from our custom TextField.
  return <TextField {...amountProps} />;
};

export default AmountField;
