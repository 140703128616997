import React from "react";
import { DocumentResponse, ResidentialManualResponse, ManagementTypeEnum } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Bracket from "@/components/paper/Bracket";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";
import { toNumberWithCommas } from "@/utils/amount";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const OtherBasicView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <>
      <Table bmargin={10} columnSizes={[110, 110, 260, 110]}>
        <Table row height={45}>
          <Box td font="label">
            ⑤ 관리에
            <br />
            관한사항
          </Box>
          <Box td font="label">
            경비실
          </Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.is_management_office}
              checkboxes={[
                ["있음", true],
                ["없음", false],
              ]}
            />
          </Box>
          <Box td>관리주체</Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.management_type}
              checkboxes={[
                ["위탁관리", ManagementTypeEnum.Public],
                ["자체관리", ManagementTypeEnum.Private],
                ["그 밖의 유형", ManagementTypeEnum.Others],
              ]}
            />
          </Box>
        </Table>
      </Table>

      <Table bmargin={10} columnSizes={[220]}>
        <Table row height={45}>
          <Box td font="label">
            ⑥ 비선호시설(1km이내)
          </Box>
          <Box td hpadding="small" align="left">
            <EnumCheckboxGroup
              value={body.is_undesirables}
              checkboxes={[
                ["없음", false],
                ["있음", true],
              ]}
              spaceSize="large"
            />
            <Space size="tiny" />
            <Bracket round>
              종류 및 위치:
              <Space size="tiny" />
              <Space minWidth="20em">{body.undesirables_detail}</Space>
            </Bracket>
          </Box>
        </Table>
      </Table>

      <Table bmargin={10} columnSizes={[160, 190, 190, 190]}>
        <Table row height={45}>
          <Box td rowSpan={2} font="label">
            ⑦ 거래예정금액 등
          </Box>
          <Box td font="label">
            거래예정금액
          </Box>
          <Box td colSpan={3} hpadding="normal" align="left">
            {toNumberWithCommas(document.amount)}
          </Box>
        </Table>
        <Table row height={45}>
          <Box td font="label">
            개별공시지가(㎡당)
          </Box>
          <Box td hpadding="normal" align="left">
            {toNumberWithCommas(body.declared_land_price)}
          </Box>
          <Box td font="label">
            건물(주택)공시가격
          </Box>
          <Box td hpadding="normal" align="left">
            {toNumberWithCommas(body.declared_building_price)}
          </Box>
        </Table>
      </Table>

      <Table bmargin={30} columnSizes={[160, 110, 150, 150, 150, 150]}>
        <Table row height={45}>
          <Box td rowSpan={2} font="label">
            ⑧ 취득 시 부담할
            <br />
            조세의 종류 및 세율
          </Box>
          <Box td font="label">
            취득세
          </Box>
          <Box td hpadding="normal">
            <Space size="large">{`${body.acquisition_tax} %`}</Space>
          </Box>
          <Box td font="label">
            농어촌특별세
          </Box>
          <Box td hpadding="normal">
            <Space size="large">{`${body.rural_special_tax} %`}</Space>
          </Box>
          <Box td font="label">
            지방교육세
          </Box>
          <Box td hpadding="normal">
            <Space size="large">{`${body.local_education_tax} %`}</Space>
          </Box>
        </Table>
        <Table row height={25}>
          <Box td colSpan={6} font="label" hpadding="normal" align="left">
            ※ 재산세는 6월 1일 기준 대상물건 소유자가 납세의무를 부담
          </Box>
        </Table>
      </Table>
    </>
  );
};

export default OtherBasicView;
