import styled from "styled-components";
import { colors, lengths } from "@/styles/values";

export const AddressSearchContainer = styled.form`
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-right: 36px;
  padding-bottom: 30px;
  padding-left: 36px;

  background-color: #ff8f1716;

  @media screen and (max-width: ${lengths.tablet}) {
    margin-bottom: 0;
    padding: 0;

    background-color: transparent;
  }
`;

export const TextFieldContainer = styled.div`
  display: flex;

  .MuiInputBase-root {
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;

    background-color: #fff;
  }

  > div,
  > a,
  > button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;
    margin-bottom: 12px;

    > div,
    > a,
    > button {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;

export const CheckCreatedDateContainer = styled.div`
  color: ${colors.main};
  font-weight: 700;
  font-size: 13px;
`;

export const SearchTypeContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;
