import React from "react";
import Dialog from "@/components/Dialog";

const SETTER_KEY = `DOCZIP_SHARED_DIALOG_SETTER`;
const STORE_KEY = `DOCZIP_SHARED_DIALOG_STORE`;

type DialogProps = React.ComponentProps<typeof Dialog>;
export type Options = {
  title?: string;
  placeholder?: string;
  confirmText?: string;
  closeText?: string;
  wide?: boolean;
};

function setDialogProps(props: DialogProps | null) {
  const setter = (window as any)[SETTER_KEY] as ((props: DialogProps | null) => void) | undefined;
  if (setter) {
    setter(props);
    (window as any)[STORE_KEY] = void 0;
  } else {
    (window as any)[STORE_KEY] = props;
  }
}

export function alert(message: React.ReactNode, options?: Options) {
  return new Promise<void>((resolve) => {
    setDialogProps({
      title: options?.title || "알림",
      content: message,
      confirmText: options?.confirmText,
      narrow: true,
      onClose: () => {
        setDialogProps(null);
        resolve();
      },
    });
  });
}

export function confirm(message: React.ReactNode, options?: Options) {
  return new Promise<boolean>((resolve) => {
    setDialogProps({
      title: options?.title || "알림",
      content: message,
      confirmText: options?.confirmText,
      closeText: options?.closeText,
      narrow: !options?.wide,
      onConfirm: () => {
        setDialogProps(null);
        resolve(true);
      },
      onClose: () => {
        setDialogProps(null);
        resolve(false);
      },
    });
  });
}

export function prompt(message: React.ReactNode, options?: Options) {
  return new Promise<string | null>((resolve) => {
    setDialogProps({
      title: options?.title || "알림",
      placeholder: options?.placeholder,
      content: message,
      confirmText: options?.confirmText,
      closeText: options?.closeText,
      prompt: true,
      wide: true,
      onConfirm: (value) => {
        setDialogProps(null);
        resolve(value || "");
      },
      onClose: () => {
        setDialogProps(null);
        resolve(null);
      },
    });
  });
}
