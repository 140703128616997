import React from "react";
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from "@mui/material";
import styled, { css } from "styled-components";

interface ContainerProps {
  gap?: Gap;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  ${({ gap }) =>
    gap &&
    css`
      margin-top: ${gap?.marginTop};
      margin-right: ${gap?.marginRight};
      margin-bottom: ${gap?.marginBottom};
      margin-left: ${gap?.marginLeft};
    `}
`;

const LabelContainer = styled.div``;

interface StyledLabelProps {
  bold?: boolean;
}

const Label = styled.span<StyledLabelProps>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 13px;
`;

const SwitchContainer = styled.div``;

export interface LabelProps {
  front?: string;
  back: string;
}

type PixelType = `${string}px`;

interface Gap {
  marginTop?: PixelType;
  marginLeft?: PixelType;
  marginRight?: PixelType;
  marginBottom?: PixelType;
}

export interface SwitchProps extends MuiSwitchProps {
  label: LabelProps;
  gap?: Gap;
  checkedValue?: boolean;
}

const Switch = ({ label, gap, checkedValue, defaultChecked, onChange }: SwitchProps) => {
  return (
    <Container gap={gap}>
      {label?.front && (
        <LabelContainer>
          <Label bold={!checkedValue}>{label.front}</Label>
        </LabelContainer>
      )}
      <SwitchContainer>
        <MuiSwitch defaultChecked={defaultChecked} onChange={onChange} />
      </SwitchContainer>
      <LabelContainer>
        <Label bold={checkedValue}>{label.back}</Label>
      </LabelContainer>
    </Container>
  );
};

export default Switch;
