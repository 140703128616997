import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CorporationIrosAddress,
  CreateCorporationReportLogRequest,
  RegisterAddressAddress,
  UserResponse,
} from "@doczip/api-client";
import { Check } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingButton from "@/components/buttons/LoadingButton";
import Checkbox from "@/components/Checkbox";
import Dialog from "@/components/Dialog";
import LoadingCircle from "@/components/LoadingCircle";
import { FetchState } from "@/interfaces/fetch";
import { getAddressSearchCategory } from "@/redux/address/selectors";
import { formatRegisterType } from "@/utils/address";
import { getSpecificParameter } from "@/utils/query";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  width: 100%;
  height: 268px;
  overflow-y: scroll;

  border: 1px solid rgb(227, 227, 227);
`;

const AddressListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
  height: 100%;
`;

const NotFoundText = styled.span`
  color: #000;
  font-size: 16px;
`;

const List = styled.ul``;

const Item = styled.li`
  border-bottom: 1px solid #e3e3e3;
`;

const ItemButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 16px;
  }
`;

const CheckItemButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 14px 16px;
  }
`;

const ItemInnerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AddressBuildingType = styled.span`
  font-weight: 400;
  font-size: 13px;
`;

const Address = styled.span`
  color: #171725;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
`;

const ItemInnerIconContainer = styled.div``;

const ItemInnerIcon = styled.div``;

const ButtonContainer = styled.div`
  width: 100%;
  height: 48px;
  margin-top: 16px;
`;

const Button = styled(LoadingButton)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 100%;

    color: #fff;
    font-weight: 700;
    font-size: 14px;

    background-color: #ff8f17;

    &.Mui-disabled {
      color: #797979;

      background-color: #f6f6f6;
    }
  }

  &.MuiLoadingButton-root {
    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
  }
`;

const ListHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
`;

const ListHeaderButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: auto;
    padding: 6px 13px;

    color: #333;
    font-weight: 400;
    font-size: 12px;

    background-color: #f2f2f2;
  }
`;

const FlexBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const ListHeaderText = styled.span`
  font-weight: 400;
  font-size: 13px;
`;

const OkText = styled.span`
  color: #00a730;
`;

const WarningText = styled.span`
  color: #fb6945;
`;

type AddressListProps = {
  userMe: UserResponse | null;
  addressList: RegisterAddressAddress[] | null;
  addressListFetchState: FetchState;
  corporationAddressList?: CorporationIrosAddress[] | null;
  corporationAddressListFetchState?: FetchState;
  reportFetchState: FetchState;
  multi?: boolean;
  handleCreateReport: (payload: RegisterAddressAddress | RegisterAddressAddress[] | undefined) => void;
  handleCorporationReport: (payload: CreateCorporationReportLogRequest) => void;
};

const AddressList = ({
  userMe,
  addressList,
  addressListFetchState,
  corporationAddressList,
  corporationAddressListFetchState,
  reportFetchState,
  multi,
  handleCreateReport,
  handleCorporationReport,
}: AddressListProps) => {
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState<RegisterAddressAddress>();
  const [selectedCompany, setSelectedCompany] = useState<CorporationIrosAddress>();
  const [selectedAddresses, setSelectedAddresses] = useState<RegisterAddressAddress[]>([]);
  const [showChargeDialog, setShowChargeDialog] = useState(false);
  const [showDeductDialog, setShowDeductDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const address = getSpecificParameter(window.location.search, "address");

  const addressSearchCategory = useSelector(getAddressSearchCategory);

  const submitButtonDisabled = useMemo(() => {
    if (addressSearchCategory === "normal") {
      return !selectedAddress && selectedAddresses.length === 0;
    }

    return !selectedCompany;
  }, [addressSearchCategory, selectedAddress, selectedCompany, selectedAddresses]);

  const submitDisabled = useMemo(() => {
    if (addressSearchCategory === "normal") {
      return !selectedAddress && !(selectedAddresses.length > 0);
    }

    return !selectedCompany;
  }, [addressSearchCategory, selectedAddress, selectedAddresses, selectedCompany]);

  useEffect(() => {
    setSelectedAddress(void 0);
    setSelectedAddresses([]);
    setShowOnlySelected(false);
  }, [multi]);

  useEffect(() => {
    setSelectedAddress(void 0);
    setSelectedCompany(void 0);
    setSelectedAddresses([]);
  }, [addressSearchCategory]);

  const onItemClick = (address: RegisterAddressAddress) => {
    const data = selectedAddresses.find((selectedAddress) => selectedAddress.pin_number === address.pin_number);

    if (data) {
      setSelectedAddresses(
        selectedAddresses.filter((selectedAddress) => selectedAddress.pin_number !== data.pin_number),
      );
    } else {
      setSelectedAddresses(selectedAddresses.concat(address));
    }
  };

  const onButtonClick = () => {
    if (!userMe) {
      navigate(getUrl("dashboard", { query: { address } }));
      return;
    }
    setShowDeductDialog(true);
  };

  const handleSubmit = useCallback(() => {
    if (!userMe) {
      navigate(getUrl("dashboard", { query: { address } }));
      return;
    }

    if (submitDisabled) {
      return;
    }

    if (
      selectedAddresses.length < userMe.ticket_amount ||
      selectedAddresses.length < userMe.free_ledger_count ||
      userMe.is_admin
    ) {
      switch (multi) {
        case true:
          handleCreateReport(selectedAddresses);
          break;
        case false:
        default:
          if (addressSearchCategory === "corporation") {
            handleCorporationReport({
              company_name: selectedCompany!.company_name,
              company_number: selectedCompany!.company_number,
              iros_code: selectedCompany!.iros_code,
              corporation_code: selectedCompany!.corporation_code,
              corporation_point_code: selectedCompany!.corporation_point_code,
              status_code: selectedCompany!.status_code,
              is_paid: false,
            });
            return;
          }
          handleCreateReport(selectedAddress);
          break;
      }
    } else {
      setShowChargeDialog(true);
    }
  }, [submitDisabled, selectedAddresses, addressSearchCategory, selectedCompany]);

  const MultiItem = (
    addressData: RegisterAddressAddress,
    address: string,
    register_type: string,
    pin_number: string,
  ) => {
    return (
      <CheckItemButton onClick={() => onItemClick(addressData)}>
        <Checkbox
          checked={selectedAddresses.find((selectedAddress) => selectedAddress.pin_number === pin_number) !== void 0}
        />
        <ItemInnerTextContainer>
          <AddressBuildingType>{formatRegisterType(register_type)}</AddressBuildingType>
          <Address>{address}</Address>
        </ItemInnerTextContainer>
      </CheckItemButton>
    );
  };

  const SingleItem = (
    addressData: RegisterAddressAddress,
    address: string,
    register_type: string,
    pin_number: string,
  ) => {
    return (
      <ItemButton onClick={() => setSelectedAddress(addressData)}>
        <ItemInnerTextContainer>
          <AddressBuildingType>{formatRegisterType(register_type)}</AddressBuildingType>
          <Address>{address}</Address>
        </ItemInnerTextContainer>

        {selectedAddress?.pin_number === pin_number && (
          <ItemInnerIconContainer>
            <ItemInnerIcon>
              <Check sx={{ color: "green" }} />
            </ItemInnerIcon>
          </ItemInnerIconContainer>
        )}
      </ItemButton>
    );
  };

  const content = useMemo(() => {
    if (addressListFetchState === "READY" || addressListFetchState === "FETCHING") {
      return (
        <ContentContainer>
          <StyledLoadingCircle />
        </ContentContainer>
      );
    }
    if (!addressList?.length) {
      return (
        <ContentContainer>
          <NotFoundText>검색 결과가 없습니다</NotFoundText>
        </ContentContainer>
      );
    }

    return (
      <List>
        {!showOnlySelected &&
          addressList.map((addressData, index) => {
            const { address, register_type, pin_number } = addressData;
            return (
              <Item key={index}>
                {multi
                  ? MultiItem(addressData, address, register_type, pin_number)
                  : SingleItem(addressData, address, register_type, pin_number)}
              </Item>
            );
          })}
        {showOnlySelected &&
          selectedAddresses.map((addressData, index) => {
            const { address, register_type, pin_number } = addressData;
            return <Item key={index}>{MultiItem(addressData, address, register_type, pin_number)}</Item>;
          })}
      </List>
    );
  }, [addressList, addressListFetchState, selectedAddress, selectedAddresses, multi, showOnlySelected]);

  const corporationContent = useMemo(() => {
    if (corporationAddressListFetchState === "READY" || corporationAddressListFetchState === "FETCHING") {
      return (
        <ContentContainer>
          <StyledLoadingCircle />
        </ContentContainer>
      );
    }
    if (!corporationAddressList?.length) {
      return (
        <ContentContainer>
          <NotFoundText>법인명 검색 결과가 없습니다</NotFoundText>
        </ContentContainer>
      );
    }

    return (
      <List>
        {corporationAddressList.map((company, index) => (
          <Item key={index}>
            <ItemButton onClick={() => setSelectedCompany(company)}>
              <ItemInnerTextContainer>
                [{company.corporation_point}] {company.company_name}
                <Address>
                  {company.status_code !== "1" ? (
                    <WarningText>{company.status} - </WarningText>
                  ) : (
                    <OkText>{company.status} - </OkText>
                  )}{" "}
                  <AddressBuildingType>{company.iros_name}</AddressBuildingType>
                </Address>
              </ItemInnerTextContainer>

              {selectedCompany?.company_number === company.company_number && (
                <ItemInnerIconContainer>
                  <ItemInnerIcon>
                    <Check sx={{ color: "green" }} />
                  </ItemInnerIcon>
                </ItemInnerIconContainer>
              )}
            </ItemButton>
          </Item>
        ))}
      </List>
    );
  }, [addressSearchCategory, corporationAddressList, corporationAddressListFetchState, selectedCompany]);

  const header = multi && (
    <ListHeaderContainer>
      <ListHeaderText>
        다운로드권 <strong>{selectedAddresses.length}건</strong>이 차감됩니다.
      </ListHeaderText>
      <FlexBox>
        <ListHeaderButton onClick={() => setShowOnlySelected((prev) => !prev)}>
          {showOnlySelected ? "검색 결과 보기" : "선택한 주소 보기"}
        </ListHeaderButton>
        <ListHeaderButton onClick={() => setShowResetDialog(true)}>초기화</ListHeaderButton>
      </FlexBox>
    </ListHeaderContainer>
  );

  const dialog = showChargeDialog && (
    <Dialog
      title="다운로드권 결제가 필요합니다."
      content="사용 횟수를 모두 소진하였습니다. 다운로드권을 결제해주세요."
      onConfirm={() => navigate(getUrl("userTransactionsNew"))}
      onClose={() => setShowChargeDialog(false)}
      confirmText="다운로드권 결제"
      closeText="닫기"
    />
  );

  const deductDialog = showDeductDialog && (
    <Dialog
      title="다운로드권이 차감됩니다."
      content={
        multi ? (
          <div>
            {`보유하고 계신 다운로드권 "${selectedAddresses.length}개"가 차감 될 예정입니다. 계속 진행하시겠습니까?`}
            <h3>선택한 주소지 목록</h3>
            {selectedAddresses.map((selectedAddress, index) => (
              <div>
                {index + 1}. {selectedAddress.address}
              </div>
            ))}
          </div>
        ) : (
          <div>
            {`보유하고 계신 다운로드권 "1개"가 차감 될 예정입니다. 계속 진행하시겠습니까? `}
            <h3>{`선택한 ${addressSearchCategory === "normal" ? "주소지" : "법인"}`}</h3>
            {addressSearchCategory === "normal" ? selectedAddress?.address : selectedCompany?.company_name}
          </div>
        )
      }
      onConfirm={() => {
        setShowDeductDialog(false);
        handleSubmit();
      }}
      onClose={() => setShowDeductDialog(false)}
      confirmText="계속"
      closeText="닫기"
    />
  );

  const resetDialog = showResetDialog && (
    <Dialog
      title="정말로 초기화 시키겠습니까?"
      content="지금까지 선택한 주소들이 전부 초기화됩니다."
      onConfirm={() => {
        setSelectedAddresses([]);
        setShowResetDialog(false);
      }}
      onClose={() => setShowResetDialog(false)}
      confirmText="초기화"
      closeText="닫기"
    />
  );

  return (
    <>
      {(addressListFetchState !== "READY" || corporationAddressListFetchState !== "READY") && (
        <AddressListContainer>
          <>
            {dialog}
            {deductDialog}
            {resetDialog}
            {header}
            <Container>{addressSearchCategory === "normal" ? content : corporationContent}</Container>
            <ButtonContainer>
              <Button
                disabled={submitButtonDisabled}
                onClick={onButtonClick}
                loading={reportFetchState === "FETCHING"}
                loadingPosition="center"
              >
                {reportFetchState !== "FETCHING" && "알아보기"}
              </Button>
            </ButtonContainer>
          </>
        </AddressListContainer>
      )}
    </>
  );
};

export default AddressList;
