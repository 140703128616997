import React, { createContext, useContext, useRef, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiModal from "@mui/material/Modal";
import styled from "styled-components";
import useRefOutSideClick from "@/hooks/useRefOutSideClick";
import { colors, lengths } from "@/styles/values";

const DropdownContainer = styled.div`
  position: relative;

  display: inline-block;
`;

const DropdownLabel = styled.div`
  margin-bottom: 0.25rem;

  font-weight: 500rem;
  font-size: 1rem;
`;

const DropdownTrigger = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  color: ${colors.black};

  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 1;
  right: 80%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;

  background-color: ${colors.white};
  background-clip: padding-box;
  border: 1px solid ${colors.lighterGray};
  transform: translate3d(none, none, none);

  @media screen and (max-width: ${lengths.desktop}px) {
    right: 1px;
  }

  &.fullWidth {
    width: 100%;
  }
`;

const DropdownModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropdownMenuItem = styled.div`
  cursor: pointer;
`;

type ContextProps = {
  isOpen: boolean;
  toggle: () => void;
  handleClose: () => void;
};

const DropdownContext = createContext<ContextProps | null>(null);

type DropdownProp = {
  label?: string;
  value?: string[];
  onChange?: () => void;
  children: React.ReactNode;
};

const Dropdown = ({ label, children }: DropdownProp) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = React.useCallback(() => setIsOpen((oldOn) => !oldOn), []);
  const handleClose = React.useCallback(() => setIsOpen(false), []);
  const contextValue = React.useMemo(() => ({ isOpen, toggle, handleClose }), [isOpen]);

  return (
    <DropdownContext.Provider value={contextValue}>
      <DropdownContainer>
        {label && <DropdownLabel>{label}</DropdownLabel>}
        {children}
      </DropdownContainer>
    </DropdownContext.Provider>
  );
};

function useDropdownContext() {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error(`Dropdown compound components cannot be rendered outside the Dropdown component`);
  }
  return context;
}

type TirggerProps = {
  as?: React.ReactNode;
  hasUpDownArrow?: boolean;
};

function Trigger({ as, hasUpDownArrow }: TirggerProps) {
  const { toggle, isOpen } = useDropdownContext();
  return as ? (
    <DropdownTrigger onClick={toggle}>
      {as}
      {hasUpDownArrow && <>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
    </DropdownTrigger>
  ) : (
    <DropdownTrigger onClick={toggle}>
      선택하기
      {hasUpDownArrow && <>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
    </DropdownTrigger>
  );
}

type Menu = {
  fullWidth?: boolean;
  children: React.ReactNode;
};

function Menu({ fullWidth, children }: Menu) {
  const menuRef = useRef<HTMLDivElement>(null);

  const { isOpen, handleClose } = useDropdownContext();

  useRefOutSideClick({ ref: menuRef, onClick: handleClose });

  return isOpen ? (
    <DropdownMenu ref={menuRef} className={fullWidth ? "fullWidth" : ""}>
      {children}
    </DropdownMenu>
  ) : null;
}

type ModalProps = {
  children: React.ReactNode;
};

function Modal({ children }: ModalProps) {
  const { isOpen, handleClose } = useDropdownContext();
  return isOpen ? (
    <DropdownModal open={isOpen} onClose={handleClose}>
      <div>{children}</div>
    </DropdownModal>
  ) : null;
}

type MenuItem = {
  onClick?: () => void;
  children?: React.ReactNode | JSX.Element | string;
};

function MenuItem({ onClick, children }: MenuItem) {
  const { handleClose } = useDropdownContext();
  return (
    <DropdownMenuItem
      onClick={() => {
        if (onClick) {
          onClick();
        }
        handleClose();
      }}
    >
      {children}
    </DropdownMenuItem>
  );
}

Dropdown.Trigger = Trigger;
Dropdown.Menu = Menu;
Dropdown.MenuItem = MenuItem;
Dropdown.Modal = Modal;

export default Dropdown;
