import React, { useEffect } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import StyledButton from "@/components/StyledButton";
import { getReportLogCountState } from "@/redux/logs/selectors";
import { reportLogCountFetchThunk } from "@/redux/logs/thunk";
import { colors } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 16px;
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;

  background: ${colors.white};

  > div,
  > img,
  > button {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 13px;
`;

const FreeTrialButton = styled(StyledButton)`
  width: 184px;
`;

const RealTimeCounterContainer = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: 16px;
  }
`;

const CounterNumber = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const CounterTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 132px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;

  color: ${colors.dark};

  border: 1px solid #dfe2e7;
`;

interface CounterProps {
  title: string;
  count: number;
}

function Counter({ title, count }: CounterProps) {
  return (
    <CounterContainer>
      <CounterNumber>
        <CountUp end={count} />
      </CounterNumber>
      <CounterTitle>{title}</CounterTitle>
    </CounterContainer>
  );
}

interface OwnersClubFreeTrialBannerProps {
  className?: string;
}

export default function OwnersClubFreeTrialBanner({ className }: OwnersClubFreeTrialBannerProps) {
  const dispatch = useDispatch();
  const { value: reportLogCount } = useSelector(getReportLogCountState);

  useEffect(() => {
    dispatch(reportLogCountFetchThunk());
  }, []);

  return (
    <Container className={className}>
      <RealTimeCounterContainer>
        <Counter count={reportLogCount?.today_issued_count ?? 0} title="실시간 등기부 발급 수" />
        <Counter count={reportLogCount?.total_issued_count ?? 0} title="총 등기부 발급 수" />
      </RealTimeCounterContainer>
      <Main>
        <Title>오너스클럽 가입시 닥집 보유 등기 무료열람!</Title>
        <Description>월 3,900원에 이 모든 등기부를 열람 가능!</Description>
      </Main>
      <FreeTrialButton to={getUrl("ownersClub")} dark bold fontSize="14px" height="48px">
        가입하기
      </FreeTrialButton>
    </Container>
  );
}
