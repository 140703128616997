import React, { useEffect, useState } from "react";
import { SaleLimitedResponse } from "@doczip/api-client";
import Alert from "@mui/material/Alert";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import SaleListItem from "./SaleListItem";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { FetchState } from "@/interfaces/fetch";

const SaleListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 864px) {
    gap: 0;
  }
`;

const StyledAlert = styled(Alert)`
  width: 100%;
`;

export type Sale = {
  trade_type?: string;
  imageUrl?: string;
  title: string;
  supply_area: number;
  exclusive_area: number;
  floor: number;
  address: string;
  price: number;
  rent_price: number;
  id: number;
};

type SaleListProps = {
  list: SaleLimitedResponse[] | null;
  fetchState: FetchState;
  page?: number | null;
  totalPage?: number | null;
  onLoadMore?: () => void;
};

const SaleList = ({ list, fetchState, page, totalPage, onLoadMore }: SaleListProps) => {
  const [searchParams] = useSearchParams();
  const [saleList, setSaleList] = useState(list);
  const query = searchParams.get("q");

  useEffect(() => {
    if (list && list?.length > 0 && query) {
      const filterdList = list?.filter((item) => item.report_log.registered_address.includes(query));
      setSaleList(filterdList);
    } else {
      setSaleList(list);
    }
  }, [query, list?.length]);

  return (
    <SaleListContainer>
      {fetchState === "SUCCESS" && saleList && saleList.length === 0 && (
        <StyledAlert severity="warning">아직 공유된 매물이 없습니다.</StyledAlert>
      )}
      <InfiniteScrollList
        list={saleList}
        renderItem={(sale) => <SaleListItem key={sale.id} sale={sale} />}
        fetchState={fetchState}
        page={page || null}
        totalPage={totalPage || null}
        onLoadMore={onLoadMore}
      />
    </SaleListContainer>
  );
};

export default SaleList;
