import React from "react";
import { ButtonBase } from "@mui/material";
import styled from "styled-components";
import { ReportDownloadButton } from "@/components/DocumentDownloadList";
import Modal from "@/components/Modal";

const ModalCloseButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 40px;
    margin-top: 20px;

    color: #333;
    font-weight: 700;
    font-size: 13px;

    background-color: #f2f2f2;
  }
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;

  > button {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;

interface Props {
  reportId: number;
  open?: boolean;
  handleClose: () => void;
}

const CorporationReportLogDocumentsDownloadModal = ({ reportId, open = false, handleClose }: Props) => {
  const handleModalClose = () => {
    handleClose();
  };

  return (
    <Modal
      titleAlign="center"
      open={open}
      title="공문서 다운로드"
      hideAcceptButton
      handleClose={handleModalClose}
      cancelButton={<ModalCloseButton onClick={handleModalClose}>닫기</ModalCloseButton>}
    >
      <ButtonGroupContainer>
        <ReportDownloadButton title="법인등기부등본" onClick={() => {}} />
      </ButtonGroupContainer>
    </Modal>
  );
};

export default CorporationReportLogDocumentsDownloadModal;
