import React from "react";
import { DocumentResponse, ResidentialManualResponse, ManagementTypeEnum } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import CheckboxGroup from "@/components/paper/CheckboxGroup";
import Date from "@/components/paper/Date";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const LegalView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Table bmargin={10} columnSizes={[110, 55, 55, 100, 270, 100]}>
      <Table row height={35}>
        <Box td rowSpan={8} font="label">
          ② 권리관계
        </Box>
        <Box td rowSpan={3} colSpan={2} font="label">
          등기부 <br />
          기재사항
        </Box>
        <Box td colSpan={2}>
          소유권에 관한 사항
        </Box>
        <Box td colSpan={2}>
          소유권 외의 권리사항
        </Box>
      </Table>
      <Table row height={90}>
        <Box td>토지</Box>
        <Box td hpadding="normal" font="tiny" pre>
          {body.land_ownership}
        </Box>
        <Box td>토지</Box>
        <Box td hpadding="normal" font="tiny" pre>
          {body.land_other_rights}
        </Box>
      </Table>
      <Table row height={90}>
        <Box td>건축물</Box>
        <Box td hpadding="normal" font="tiny" pre>
          {body.building_ownership}
        </Box>
        <Box td>건축물</Box>
        <Box td hpadding="normal" font="tiny" pre>
          {body.building_other_rights}
        </Box>
      </Table>
      <Table row height={35}>
        <Box td rowSpan={3} font="label">
          민간 <br />
          임대 <br />
          등록 <br />
          여부
        </Box>
        <Box td rowSpan={2}>
          등록
        </Box>
        <Box td colSpan={4} hpadding="normal" align="left" height={35}>
          <EnumCheckboxGroup
            value={body.registered_rent_type}
            checkboxes={[
              ["장기일반민간임대주택", ManagementTypeEnum.Private],
              ["공공지원민간임대주택", ManagementTypeEnum.Public],
              ["그 밖의 유형", ManagementTypeEnum.Others],
            ]}
            detailValue={body.registered_rent_type_detail}
          />
        </Box>
      </Table>
      <Table row height={35}>
        <Box td>임대의무기간</Box>
        <Box td hpadding="normal" align="left">
          {body.registered_rent_mandatory_period}
        </Box>
        <Box td>임대개시일</Box>
        <Box td hpadding="normal" align="left">
          {body.registered_rent_type !== ManagementTypeEnum.NotAvailable ? (
            <Date value={body.registered_rent_start_date} />
          ) : (
            <></>
          )}
        </Box>
      </Table>
      <Table row height={35}>
        <Box td>미등록</Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <CheckboxGroup
            checkboxes={[["해당 사항 없음", body.registered_rent_type === ManagementTypeEnum.NotAvailable]]}
          />
        </Box>
      </Table>
      <Table row height={35}>
        <Box td colSpan={2} font="small">
          계약갱신 요구권 <br />
          행사여부
        </Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_rent_renewed}
            checkboxes={[
              ["확인 (확인서류 첨부)", true],
              ["미확인", false],
              ["해당 없음", void 0],
            ]}
          />
        </Box>
      </Table>
      <Table row height={35}>
        <Box td colSpan={2} font="small">
          다가구주택 <br />
          확인서류 제출여부
        </Box>
        <Box td colSpan={4} hpadding="normal" align="left">
          <EnumCheckboxGroup
            value={body.is_multi_family_housing}
            checkboxes={[
              ["확인 (확인서류 첨부)", true],
              ["미확인", false],
              ["해당 없음", void 0],
            ]}
          />
        </Box>
      </Table>
    </Table>
  );
};

export default LegalView;
