import React from "react";
import { DocumentResponse, ResidentialManualResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Checkbox from "@/components/paper/Checkbox";
import CheckboxGroup from "@/components/paper/CheckboxGroup";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const LandUseView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  const omitForRent = (content: string | number | undefined | null, suffix?: string): React.ReactNode => {
    const text = content === null || typeof content === "undefined" ? "" : `${content}`;
    if (text.trim()) {
      return `${text}${suffix || ""}`;
    } else if (!body.is_trade) {
      return `생략 (임대차)`;
    } else {
      return ``;
    }
  };
  return (
    <Table bmargin={10} columnSizes={[110, 90, 150, 150, 90, 150, 130]}>
      <Table row height={35}>
        <Box td rowSpan={6} font="label">
          ③ 토지이용
          <br />
          계획,
          <br />
          공법상 이용
          <br />
          제한 및 거래
          <br />
          규제에 관한
          <br />
          사항 (토지)
        </Box>
        <Box td rowSpan={3}>
          지역·지구
        </Box>
        <Box td>용도지역</Box>
        <Box td colSpan={3}>
          {omitForRent(body.use_area_plan)}
        </Box>
        <Box td>건폐율 상한</Box>
        <Box td>용적율 상한</Box>
      </Table>
      <Table row height={35}>
        <Box td>용도지구</Box>
        <Box td colSpan={3}>
          {omitForRent(body.use_district_plan)}
        </Box>
        <Box td rowSpan={2}>
          <Space size="large">{omitForRent(body.building_ratio_limit, "%")}</Space>
        </Box>
        <Box td rowSpan={2}>
          <Space size="large">{omitForRent(body.floor_area_ratio_limit, "%")}</Space>
        </Box>
      </Table>
      <Table row height={35}>
        <Box td>용도구역</Box>
        <Box td colSpan={3} rborder="normal">
          {omitForRent(body.use_zone_plan)}
        </Box>
      </Table>
      <Table row height={35}>
        <Box td rowSpan={2}>
          도시·군계획
          <br />
          시설
        </Box>
        <Box td rowSpan={2}>
          <span className="pre-wrap">{omitForRent(body.city_gun_plan)}</span>
        </Box>
        <Box td>허가·신고 구역 여부</Box>
        <Box td colSpan={4} align="left" hpadding="normal">
          <Checkbox label="토지거래허가구역" value={body.is_transaction_permit_zone} />
        </Box>
      </Table>
      <Table row height={35}>
        <Box td>투기지역 여부</Box>
        <Box td colSpan={4} align="left" hpadding="normal">
          <CheckboxGroup
            checkboxes={[
              ["토지투기지역", body.is_land_speculation_area],
              ["주택투기지역", body.is_housing_speculation_area],
              ["투기과열지구", body.is_speculation_overheated],
            ]}
          />
        </Box>
      </Table>
      <Table row height={35}>
        <Box td colSpan={2}>
          지구단위계획구역
          <br />그 밖의 도시·군관리계획
        </Box>
        <Box td colSpan={2} align="left" hpadding="normal">
          <span className="pre-wrap">{omitForRent(body.district_unit_planning_area)}</span>
        </Box>
        <Box td>
          그 밖의 이용제한
          <br />및 거래규제사항
        </Box>
        <Box td colSpan={2} align="left" hpadding="normal">
          <span className="pre-wrap">{omitForRent(body.other_use_restrictions)}</span>
        </Box>
      </Table>
    </Table>
  );
};

export default LandUseView;
