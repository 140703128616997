import { ListingTradeTypeEnum } from "@vesta/api-client";

export function formatRegisterType(registerType: string) {
  switch (registerType) {
    case "BUILDING": {
      return "일반건물";
    }
    case "COMPLEX": {
      return "집합건물";
    }
    case "LAND": {
      return "대지";
    }
  }
}

export function formatFloorType(floorType: string) {
  switch (floorType) {
    case "LOW": {
      return "저";
    }
    case "MIDDLE": {
      return "중";
    }
    case "HIGH": {
      return "고";
    }
  }
}

export function formatTradeType(tradeType: ListingTradeTypeEnum) {
  switch (tradeType) {
    case ListingTradeTypeEnum.DEAL: {
      return "매매";
    }
    case ListingTradeTypeEnum.TNANT: {
      return "전세";
    }
    case ListingTradeTypeEnum.RENT: {
      return "월세";
    }
  }
}
