import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import styled from "styled-components";
import { TextField, Button } from "@/components/Mui";

type StyleProps = {
  height?: number;
};

const Container = styled.div<StyleProps>`
  position: relative;

  width: 100%;

  & .MuiFormControl-root {
    width: 100%;
    height: ${(props) => (props.height ? props.height + "px" : "46px")};
  }
`;

const InnerButton = styled(Button)<StyleProps>`
  &.MuiButtonBase-root {
    position: absolute;
    top: ${(props) => (props.height ? (props.height - props.height * 0.695) / 2 + "px" : "7px")};
    right: 7px;

    width: 55px;
    height: ${(props) => (props.height ? props.height * 0.695 + "px" : "32px")};
    padding: 0;

    font-size: 12px;
  }
`;

type Props = {
  innerButtonChildren?: React.ReactNode;
  hideSearchIcon?: boolean;
  placeholder?: string;
  onSubmitClick: (keyword: string) => void;
  onKeywordChange?: (keyword: string) => void;
  boxHeight?: number;
};

const Search = ({
  innerButtonChildren,
  hideSearchIcon,
  placeholder = "키워드를 입력해주세요",
  onSubmitClick,
  onKeywordChange,
  boxHeight,
}: Props) => {
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (onKeywordChange) {
      onKeywordChange(keyword);
    }
  }, [keyword]);

  const defaultAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <InnerButton disabled={keyword.trim() === ""} onClick={() => onSubmitClick(keyword)} height={boxHeight}>
          {innerButtonChildren || "검색"}
        </InnerButton>
      </InputAdornment>
    ),
  };

  const adornment = !hideSearchIcon
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        ...defaultAdornment,
      }
    : defaultAdornment;

  const handleKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      onSubmitClick(keyword);
    }
  };

  return (
    <Container height={boxHeight}>
      <TextField
        fullWidth
        value={keyword}
        placeholder={placeholder}
        onChange={(e) => setKeyword(e.target.value)}
        InputProps={adornment}
        onKeyPress={handleKeypress}
      />
    </Container>
  );
};

export default Search;
