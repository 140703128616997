import React, { useEffect, useState } from "react";
import { DocumentResponse } from "@doczip/api-client";
import { useParams } from "react-router";
import { documentApi } from "@/client";
import DocumentEdit from "@/components/documents/DocumentEdit";
import LoadingCircle from "@/components/LoadingCircle";
import NotFound from "@/components/NotFound";
import { usePrompt } from "@/hooks/usePrompt";
import { isMobile } from "@/utils/browser";
import { alert, confirm } from "@/utils/dialog";

type RenderPdf = (title?: string) => void;
type Props = {};

// Renderer function is stored in this object instead of a React state,
// since the store cannot handle function objects without randomly calling them.
// WARNING: This needs to be re-implemented if multiple instances of
// DocumentEditContainer(s) are required to be rendered at the same time.
const renderer = {} as Record<string, RenderPdf>;

const DocumentEditContainer: React.FC<Props> = () => {
  const params = useParams();
  const documentId = params.documentId ? parseInt(params.documentId as string, 10) : void 0;
  const [document, setDocument] = useState<DocumentResponse>();
  const [saved, setSaved] = useState(true);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(!saved);

  const fetchDocument = async () => {
    setBusy(true);
    setError(false);
    try {
      const response = await documentApi.getDocument(documentId as number);
      setDocument(response.data);
      setSaved(true);
    } catch (err) {
      setError(true);
    } finally {
      setBusy(false);
    }
  };

  const handleNavigation = async () => {
    if (await confirm("저장되지 않은 변경 사항이 있습니다. 저장하지 않고 나가시겠습니까?")) {
      confirmNavigation();
    } else {
      cancelNavigation();
    }
  };

  useEffect(() => {
    if (showPrompt) {
      handleNavigation();
    }
  }, [showPrompt]);

  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      if (saved) {
        delete e.returnValue; // Make sure to unload.
      } else {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    fetchDocument();
  }, [params.documentId]);

  const handleChange = (doc: DocumentResponse) => {
    setSaved(false);
    setDocument(doc);
  };

  const handleSave = async () => {
    setBusy(true);
    try {
      const { user, report_log, ...update } = document as DocumentResponse;
      await documentApi.updateDocument(documentId as number, update);
      setSaved(true);
    } catch (err) {
      setSaveError(true);
    } finally {
      setBusy(false);
    }
  };

  const handleSetRenderPdf = (renderPdf: RenderPdf) => {
    renderer.renderPdf = renderPdf;
  };

  const handleRenderPdf = async () => {
    if (isMobile()) {
      await alert(
        "모바일 기기에서는 문서 다운로드 기능이 불안정할 수 있습니다. 문제가 계속해서 발생할 경우 컴퓨터에서 시도해주세요.",
      );
    }
    if (renderer.renderPdf) {
      renderer.renderPdf(document?.title);
    } else {
      await alert("인쇄에 실패했습니다. 잠시 후 다시 시도해주세요.");
    }
  };

  if (error) {
    return <NotFound />;
  } else if (!document) {
    return <LoadingCircle position="absolute" />;
  } else {
    return (
      <DocumentEdit
        saved={saved}
        busy={busy}
        saveError={saveError}
        document={document}
        onChange={handleChange}
        onSave={handleSave}
        onSetRenderPdf={handleSetRenderPdf}
        onRenderPdf={handleRenderPdf}
      />
    );
  }
};

export default DocumentEditContainer;
