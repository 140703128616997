import React from "react";
import { FormControlLabel, Grid, Checkbox } from "@mui/material";
import TextField from "./TextField";
import { colors } from "@/styles/values";

type Props = {
  label: string;
  values: [string, number, boolean];
  onChange: (name: string, time: number, byCar: boolean) => void;
};

const InfraField: React.FC<Props> = ({ label, values, onChange }) => {
  const checkStyle = { "&.Mui-checked": { color: colors.main } };

  return (
    <Grid item sm={12} container spacing={1}>
      <Grid item sm={6}>
        <TextField
          size="small"
          label={`인접 ${label}`}
          value={values[0]}
          onChange={(e) => onChange(e.target.value, values[1], values[2])}
          fullWidth
        />
      </Grid>
      <Grid item sm={3}>
        <TextField
          type="number"
          size="small"
          label="소요시간 (분)"
          value={values[1]}
          onChange={(e) => onChange(values[0], parseInt(e.target.value, 10), values[2])}
          fullWidth
          safeNumber
        />
      </Grid>
      <Grid item sm={3}>
        <FormControlLabel
          control={
            <Checkbox
              sx={checkStyle}
              checked={values[2]}
              onChange={(e) => onChange(values[0], values[1], e.target.checked)}
            />
          }
          label="차량 기준"
        />
      </Grid>
    </Grid>
  );
};

export default InfraField;
