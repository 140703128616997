import React from "react";
import { DocumentResponse, ResidentialManualResponse, ManagementTypeEnum } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Bracket from "@/components/paper/Bracket";
import EnumCheckboxGroup from "@/components/paper/EnumCheckboxGroup";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const SiteView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Table bmargin={10} columnSizes={[110, 100, 150, 260, 100]}>
      <Table row height={45}>
        <Box td rowSpan={9} font="label">
          ④ 입지조건
        </Box>
        <Box td>
          도로와의
          <br />
          관계
        </Box>
        <Box td colSpan={2} hpadding="small" align="left">
          <Bracket round size="tiny">{`${body.primary_road_width || " "} m`}</Bracket>
          {body.secondary_road_width ? (
            <>
              {" x "}
              <Bracket round size="tiny">{`${body.secondary_road_width || " "} m`}</Bracket>
            </>
          ) : (
            <></>
          )}
          <Space size="tiny" />
          도로에 접함
          <Space size="tiny" />
          <EnumCheckboxGroup
            value={body.is_road_paved}
            checkboxes={[
              ["포장", true],
              ["비포장", false],
            ]}
          />
        </Box>
        <Box td>접근성</Box>
        <Box td hpadding="small" align="left">
          <EnumCheckboxGroup
            value={body.is_accessible}
            checkboxes={[
              ["용이함", true],
              ["불편함", false],
            ]}
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td rowSpan={2}>
          대중교통
        </Box>
        <Box td>버스</Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.bus_stop_name}
          </Bracket>
          정류장,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_bus_stop_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.bus_stop_time} 분`}</Space>
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>지하철</Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.subway_station_name}
          </Bracket>
          역,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_subway_station_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.subway_station_time} 분`}</Space>
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>주차장</Box>
        <Box td colSpan={4} hpadding="small" align="left">
          <EnumCheckboxGroup
            value={body.parking_type}
            checkboxes={[
              ["없음", ManagementTypeEnum.NotAvailable],
              ["전용주차시설", ManagementTypeEnum.Private],
              ["공동주차시설", ManagementTypeEnum.Public],
              ["그 밖의 주차시설", ManagementTypeEnum.Others],
            ]}
            detailValue={body.parking_type_detail}
          />
        </Box>
      </Table>
      <Table row height={45}>
        <Box td rowSpan={3}>
          교육시설
        </Box>
        <Box td>초등학교</Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.elementary_school_name}
          </Bracket>
          ,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_elementary_school_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.elementary_school_time} 분`}</Space>
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>중학교</Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.middle_school_name}
          </Bracket>
          ,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_middle_school_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.middle_school_time} 분`}</Space>
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>고등학교</Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.high_school_name}
          </Bracket>
          ,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_high_school_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.high_school_time} 분`}</Space>
        </Box>
      </Table>
      <Table row height={45}>
        <Box td rowSpan={2}>
          판매 및
          <br />
          의료시설
        </Box>
        <Box td>
          백화점 및
          <br />
          할인매장
        </Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.store_name}
          </Bracket>
          ,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_store_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.store_time} 분`}</Space>
        </Box>
      </Table>
      <Table row height={45}>
        <Box td>종합의료시설</Box>
        <Box td colSpan={3} hpadding="small" align="left">
          <Bracket round size="huge">
            {body.hospital_name}
          </Bracket>
          ,
          <Space size="tiny" />
          소요시간:
          <Space size="tiny" />
          <Bracket round>
            <EnumCheckboxGroup
              value={body.is_hospital_time_by_car}
              checkboxes={[
                ["도보", false],
                ["차량", true],
              ]}
              spaceSize="tiny"
            />
          </Bracket>
          <Space size="tiny" />
          약
          <Space size="tiny" />
          <Space size="large">{`${body.hospital_time} 분`}</Space>
        </Box>
      </Table>
    </Table>
  );
};

export default SiteView;
