import { SubscriptionResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { SubscriptionState } from "./types";
import { makeStateResetReducer, makeAsyncStateResetReducer, makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState, getTypedAsyncListInitialState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const makeAsyncReducer = makeTypedAsyncReducer<SubscriptionState>();
const getAsyncListInitialState = getTypedAsyncListInitialState<SubscriptionState>();

const initialState: SubscriptionState = {
  ...getAsyncListInitialState("subscriptionList"),
  subscribeFetchState: "READY",
  cancelSubscriptionFetchState: "READY",
  refundSubscriptionFetchState: "READY",
};

export const SUBSCRIPTION_STATE_KEY_LIST = getKeyListFromState(initialState);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"subscriptionList", void, ListFetchSuccessPayload<SubscriptionResponse>>("subscriptionList", {
      useListSuccessReducer: true,
    }),
    resetSubscriptionListState: makeAsyncStateResetReducer(initialState, "subscriptionList", { isList: true }),
    ...makeAsyncReducer<"subscribe", void, SubscriptionResponse>("subscribe"),
    ...makeAsyncReducer("cancelSubscription"),
    resetSubscriptionCancelState: makeStateResetReducer(initialState, "cancelSubscriptionFetchState"),
    ...makeAsyncReducer("refundSubscription"),
    resetSubscriptionRefundState: makeStateResetReducer(initialState, "refundSubscriptionFetchState"),
  },
});

export const subscriptionReducer = subscriptionSlice.reducer;
