import React from "react";
import styled, { css } from "styled-components";
import HomeIcon from "./HomeIcon";
import HomeTitle from "./HomeTitle";

const MobileLandingVisualContainer = styled.div``;

interface CollapseProps {
  collapse?: boolean;
}

const InnerContainer = styled.div<CollapseProps>`
  position: relative;

  width: 100%;

  transition: 500ms;

  ${({ collapse }) =>
    collapse
      ? css`
          height: 160px;

          @media screen and (max-width: 374px) {
            height: 144px;
          }
        `
      : css`
          height: 230px;
        `}

  & > div {
    position: absolute;

    transition: 500ms;
  }
`;

const StyledHomeTitle = styled(HomeTitle)<CollapseProps>`
  transform: translateX(-50%);

  ${({ collapse }) =>
    collapse
      ? css`
          top: 52px;
          left: calc(50% + 88px);

          @media screen and (max-width: 374px) {
            top: 48px;
            left: calc(50% + 80px);

            transform: translateX(-50%) scale(0.95);
          }
        `
      : css`
          top: 0;
          left: 50%;
        `};
`;

const StyledHomeIcon = styled(HomeIcon)<CollapseProps>`
  ${({ collapse }) =>
    collapse
      ? css`
          top: 8px;
          left: calc(50% - 120px);

          transform: translateX(-50%) scale(0.7);

          @media screen and (max-width: 374px) {
            top: 4px;
            left: calc(50% - 104px);

            transform: translateX(-50%) scale(0.6);
          }

          & div {
            border-radius: 100%;

            animation-name: unset;
          }
        `
      : css`
          top: 65px;
          left: 50%;

          transform: translateX(-50%);
        `};
`;

const InquiryFactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 3px 17px;

  background-color: #f2f2f2;
  border-radius: 15px;
`;

const InquiryFactInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InquiryFactEmoji = styled.div``;

const InquiryFactText = styled.span`
  margin-left: 10px;

  color: #333;
  font-weight: 500;
  font-size: 13px;
`;

interface MobileLandingVisual {
  bannerCollapse?: boolean;
}

const MobileLandingVisual: React.FC<MobileLandingVisual> = ({ bannerCollapse }) => {
  return (
    <MobileLandingVisualContainer>
      <InnerContainer collapse={bannerCollapse}>
        <StyledHomeTitle collapse={bannerCollapse} />
        <StyledHomeIcon collapse={bannerCollapse} />
      </InnerContainer>

      <InquiryFactContainer>
        <InquiryFactInnerContainer>
          <InquiryFactEmoji>🤭</InquiryFactEmoji>
          <InquiryFactText>쉿! 조회한 사실은 회원님만 알 수 있어요!</InquiryFactText>
        </InquiryFactInnerContainer>
      </InquiryFactContainer>
    </MobileLandingVisualContainer>
  );
};

export default MobileLandingVisual;
