import React, { useState } from "react";
import { Report, ReportBuildingLedger, ReportLandUsingInfo, ReportRegisterLedger } from "@blitz-core/api-client";
import { ReportLogResponse } from "@doczip/api-client";
import { ButtonBase } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ReportDocumentDownloadPopup from "./ReportDocumentDownloadPopup";
import ReportDocumentPopup from "./ReportDocumentPopup";
import TrashCanIcon from "@/assets/icons/trash-can.svg";
import SaleModalOpenButton from "@/components/sales/SaleModalOpenButton";
import StyledButton from "@/components/StyledButton";
import { FetchState } from "@/interfaces/fetch";
import { buildingLedgerFetchThunk, landUsingInfoFetchThunk, registerLedgerFetchThunk } from "@/redux/report/thunk";
import { resetReportLogDocuments, resetReportLogList } from "@/redux/user/actions";
import { deleteReportLogThunk } from "@/redux/user/thunk";
import { toKoreanDate } from "@/utils/datetime";
import { getUrl } from "@/utils/routes";

const ReportItemContainer = styled.div`
  padding: 13px 15px;

  background-color: #fff;

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #dfe2e7;
  }
`;

const ReportItemInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Address = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Date = styled.span`
  color: #718096;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

const ButtonListContainer = styled.div``;

const ButtonList = styled.ul`
  display: flex;
`;

const ButtonItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-of-type) {
    margin-left: 12px;
  }
`;

const IconButton = styled(ButtonBase)``;

const Icon = styled.img`
  display: inline-block;
`;

interface ReportItemProps {
  reportLog: ReportLogResponse;
  reportLogDocument?: Report | null;
  registerLedger: ReportRegisterLedger | null;
  registerLedgerFetchState: FetchState;
  buildingLedger: ReportBuildingLedger | null;
  buildingLedgerFetchState: FetchState;
  landUsingInfo: ReportLandUsingInfo | null;
  landUsingInfoFetchState: FetchState;
}

const ReportItem: React.FC<ReportItemProps> = ({
  reportLog,
  reportLogDocument,
  registerLedger,
  registerLedgerFetchState,
  buildingLedger,
  buildingLedgerFetchState,
  landUsingInfo,
  landUsingInfoFetchState,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [documentDownloadPopupOpen, setDocumentDownloadPopupOpen] = useState(false);
  const [documentPopupOpen, setDocumentPopupOpen] = useState(false);
  // const [saleNewModalOpen, setSaleNewModalOpen] = useState(false);
  const { id, registered_address, created_datetime, provider_id } = reportLog;

  const toggleDocumentDownloadPopup = () => {
    setDocumentDownloadPopupOpen(!documentDownloadPopupOpen);
  };
  const toggleDocumentPopup = () => {
    setDocumentPopupOpen(!documentPopupOpen);
  };
  const onAddressClick = (path: string) => {
    navigate(path);
  };
  const fetchDocuments = () => {
    dispatch(registerLedgerFetchThunk({ reportId: provider_id }));
    dispatch(buildingLedgerFetchThunk({ reportId: provider_id }));
    dispatch(landUsingInfoFetchThunk({ reportId: provider_id }));
  };
  const deleteReportLog = () => {
    dispatch(deleteReportLogThunk({ id }));
    dispatch(resetReportLogList());
  };
  const popupUnMounted = () => {
    dispatch(resetReportLogDocuments());
  };

  return (
    <>
      <ReportDocumentDownloadPopup
        reportId={reportLog.id}
        open={documentDownloadPopupOpen}
        handleClose={toggleDocumentDownloadPopup}
        onMounted={fetchDocuments}
        onUnMounted={popupUnMounted}
        title="공문서 다운로드"
        titleAlign="center"
        registerLedger={registerLedger}
        registerLedgerFetchState={registerLedgerFetchState}
        buildingLedger={buildingLedger}
        buildingLedgerFetchState={buildingLedgerFetchState}
        landUsingInfo={landUsingInfo}
        landUsingInfoFetchState={landUsingInfoFetchState}
      />
      <ReportDocumentPopup
        open={documentPopupOpen}
        handleClose={toggleDocumentPopup}
        title="문서 작성"
        titleAlign="center"
        reportLogId={reportLog.id}
      />
      <ReportItemContainer>
        <ReportItemInnerContainer>
          <TextContainer>
            <Address
              onClick={() =>
                onAddressClick(
                  getUrl("reportsDetail", { params: { reportId: reportLog.id }, query: { type: "normal" } }),
                )
              }
            >
              {registered_address}
            </Address>
            <Date>{toKoreanDate(created_datetime)}</Date>
          </TextContainer>
          <ButtonListContainer>
            <ButtonList>
              <ButtonItem>
                <StyledButton bold outlined height="36px" fontSize="12px" onClick={toggleDocumentDownloadPopup}>
                  다운로드
                </StyledButton>
              </ButtonItem>
              <ButtonItem>
                <StyledButton bold outlined height="36px" fontSize="12px" onClick={toggleDocumentPopup}>
                  문서 작성
                </StyledButton>
              </ButtonItem>
              <ButtonItem>
                <SaleModalOpenButton
                  as={
                    <StyledButton bold outlined height="36px" fontSize="12px">
                      매물 공유
                    </StyledButton>
                  }
                  saleValues={{ address: reportLog.registered_address, id: reportLog.id }}
                />
              </ButtonItem>
              <ButtonItem>
                <IconButton onClick={deleteReportLog}>
                  <Icon src={TrashCanIcon} />
                </IconButton>
              </ButtonItem>
            </ButtonList>
          </ButtonListContainer>
        </ReportItemInnerContainer>
      </ReportItemContainer>
    </>
  );
};

export default ReportItem;
