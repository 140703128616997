import React from "react";
import SaleLayout from "@/components/sales/common/SaleLayout";
import SaleShowContainer from "@/containers/sales/SaleShowContainer";

const SaleShowPage = () => {
  return (
    <SaleLayout saleShow>
      <SaleShowContainer />
    </SaleLayout>
  );
};

export default SaleShowPage;
