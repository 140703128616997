import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { colors } from "@/styles/values";

const StaticDocumentBox = styled.div`
  width: 100%;

  background-color: ${colors.white};
`;

const StaticDocumentInnerBox = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 8px;

  & h1 {
    font-size: 32px;
  }
`;

type Props = {
  content: string;
};

const StaticDocument: React.FC<Props> = (props) => {
  return (
    <StaticDocumentBox>
      <StaticDocumentInnerBox>
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </StaticDocumentInnerBox>
    </StaticDocumentBox>
  );
};

export default StaticDocument;
