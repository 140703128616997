import React from "react";
import {
  DocumentResponse,
  TradeContractResponse,
  JeonseContractResponse,
  RentContractResponse,
} from "@doczip/api-client";
import ContractAgentView from "./ContractAgentView";
import ContractPersonView from "./ContractPersonView";
import ContractPhraseView from "./ContractPhraseView";
import Paper from "@/components/Paper";
import Box from "@/components/paper/Box";
import Date from "@/components/paper/Date";
import Space from "@/components/paper/Space";
import Table from "@/components/paper/Table";
import { toAmount } from "@/utils/amount";
import { isJeonseContractBody, isRentContractBody, isTradeContractBody } from "@/utils/document";

type ContractResponse = TradeContractResponse | JeonseContractResponse | RentContractResponse;
type Props<T extends ContractResponse> = {
  document: DocumentResponse;
  body: T;
};

const ContractView = <T extends ContractResponse>({ document, body }: Props<T>) => {
  const getTypeString = () => {
    if (isTradeContractBody(body)) {
      return "매매";
    } else if (isJeonseContractBody(body)) {
      return "전세";
    } else {
      return "월세";
    }
  };

  const getContractString = () => {
    if (isTradeContractBody(body)) {
      return "매매";
    } else {
      return "임대차";
    }
  };

  const getAmountString = () => {
    if (isTradeContractBody(body)) {
      return "매매대금";
    } else {
      return "보증금";
    }
  };

  const getOwnerString = () => {
    if (isTradeContractBody(body)) {
      return "매도인";
    } else {
      return "임대인";
    }
  };

  const getConsumerString = () => {
    if (isTradeContractBody(body)) {
      return "매수인";
    } else {
      return "임차인";
    }
  };

  const getPortionRow = () => {
    if (isJeonseContractBody(body) || isRentContractBody(body)) {
      return (
        <Table row height={30}>
          <Box td align="center" backgroundColor="gray">
            임대할 부분
          </Box>
          <Box td colSpan={6} hpadding="small" align="left">
            {body.portion_to_lease}
          </Box>
          <Box td align="center" backgroundColor="gray">
            면적
          </Box>
          <Box td hpadding="small" align="left">
            {body.portion_to_lease_area}㎡
          </Box>
        </Table>
      );
    }
    return <></>;
  };

  return (
    <Paper container>
      <Paper flexible>
        <Box font="title" align="center" bmargin={30}>{`부동산 ${getTypeString()} 계약서`}</Box>
        <Box font="label" bmargin={10}>
          {`${getOwnerString()}과 ${getConsumerString()}`} 쌍방은 아래 표시 부동산에 관하여 다음 계약 내용과 같이{" "}
          {`${getContractString()}계약을`} 체결한다.
        </Box>
        <Box font="label" bmargin={10}>
          1. 부동산의 표시
        </Box>
        <Table bmargin={30} columnSizes={[110, 100, 100, 100, 100, 100, 100, 100]}>
          <Table row height={30}>
            <Box td align="center" backgroundColor="gray">
              소재지
            </Box>
            <Box td colSpan={8} hpadding="small" align="left">
              {document.address_expr}
            </Box>
          </Table>
          <Table row height={30}>
            <Box td align="center" backgroundColor="gray">
              토지
            </Box>
            <Box td align="center" backgroundColor="gray">
              지목
            </Box>
            <Box td hpadding="small" align="left">
              {body.land_category}
            </Box>
            <Box td align="center" backgroundColor="gray">
              면적
            </Box>
            <Box td hpadding="small" align="left">
              {body.land_area}㎡
            </Box>
            <Box td align="center" backgroundColor="gray">
              대지권 종류
            </Box>
            <Box td hpadding="small" align="left">
              {body.right_of_use_type}
            </Box>
            <Box td align="center" backgroundColor="gray">
              대지권 비율
            </Box>
            <Box td hpadding="small" align="left">
              {body.right_of_use_ratio_denominator} 분의 {body.right_of_use_ratio_numerator}
            </Box>
          </Table>
          <Table row height={30}>
            <Box td align="center" backgroundColor="gray">
              건물
            </Box>
            <Box td align="center" backgroundColor="gray">
              구조
            </Box>
            <Box td colSpan={2} hpadding="small" align="left">
              {body.building_structure}
            </Box>
            <Box td align="center" backgroundColor="gray">
              용도
            </Box>
            <Box td colSpan={2} hpadding="small" align="left">
              {body.building_purpose}
            </Box>
            <Box td align="center" backgroundColor="gray">
              면적
            </Box>
            <Box td hpadding="small" align="left">
              {body.building_area}㎡
            </Box>
          </Table>
          {getPortionRow()}
        </Table>
        <Box font="label" bmargin={10}>
          2. 계약 내용
        </Box>
        <Box font="small" bmargin={10}>
          제1조 <ContractPhraseView document={document} body={body} index={1} />
        </Box>
        <Table bmargin={10} columnSizes={[110]}>
          <Table row height={30}>
            <Box td align="center" backgroundColor="gray">
              {getAmountString()}
            </Box>
            <Box td hpadding="small" align="left">
              {`금 ${toAmount(document.amount || "")}`}
            </Box>
          </Table>
          <Table row height={30}>
            <Box td align="center" backgroundColor="gray">
              계약금
            </Box>
            <Box td hpadding="small" align="left">
              <Space size="huge">{`금 ${toAmount(body.deposit_amount || "")}`}</Space>
              {body.show_deposit_receipt ? (
                <>
                  <Space size="tiny" />
                  은 계약시에 지불하고 영수함
                  <Space size="tiny" />
                  ※ 영수자
                  <Space size="tiny" />
                  <Space size="huge" align="center">
                    {body.deposit_recipient}
                  </Space>
                  <Space size="tiny" />
                  (인)
                </>
              ) : (
                <></>
              )}
            </Box>
          </Table>
          {body.interim_deposit_amounts.map((amount, i) => {
            const date = body.interim_deposit_dates[i];
            return (
              <Table key={i} row height={30}>
                <Box td align="center" backgroundColor="gray">
                  {`${i + 1}차 중도금`}
                </Box>
                <Box td hpadding="small" align="left">
                  <Space size="huge">{`금 ${toAmount(amount || "")}`}</Space>
                  <Space size="tiny" />
                  은 <Date value={date} size="tiny" />에 지불한다.
                </Box>
              </Table>
            );
          })}
          {body.balance_amount > 0 ? (
            <Table row height={30}>
              <Box td align="center" backgroundColor="gray">
                잔금
              </Box>
              <Box td hpadding="small" align="left">
                <Space size="huge">{`금 ${toAmount(body.balance_amount || "")}`}</Space>
                <Space size="tiny" />
                은 <Date value={body.balance_payment_date} size="tiny" />에 지불한다.
              </Box>
            </Table>
          ) : (
            <></>
          )}
          {document.rent > 0 && isRentContractBody(body) && (
            <Table row height={30}>
              <Box td align="center" backgroundColor="gray">
                차임
              </Box>
              <Box td hpadding="small" align="left">
                <Space size="huge">{`금 ${toAmount(document.rent || "")}`}</Space>
                <Space size="tiny" />은 매월 {body.rent_payment_day_of_month}에 지불한다.
              </Box>
            </Table>
          )}
        </Table>
        {[2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
          <Box key={i} font="small" bmargin={10}>
            {`제${i}조`} <ContractPhraseView document={document} body={body} index={i} />
          </Box>
        ))}
        <Box font="label" bmargin={10}>
          [특약사항]
        </Box>
        <Box font="default" bmargin={10}>
          <pre>{body.special_agreements}</pre>
        </Box>
        {document.owners.map((owner, i) => (
          <ContractPersonView key={i} bmargin={0} title={getOwnerString()} value={owner} />
        ))}
        {document.consumers.map((consumer, i) => (
          <ContractPersonView key={i} bmargin={0} title={getConsumerString()} value={consumer} />
        ))}
        {document.agents.map((agent, i) => (
          <ContractAgentView key={i} bmargin={0} value={agent} />
        ))}
      </Paper>
    </Paper>
  );
};

export default ContractView;
