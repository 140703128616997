import React from "react";
import Bracket from "./Bracket";
import Checkbox from "./Checkbox";
import Space from "./Space";
import type { Size } from "@/interfaces/style";

type Checkbox<T> = [string, T]; // [label, value]

type Props<T> = {
  value: T;
  checkboxes: Array<Checkbox<T>>;
  spaceSize?: Size;
  detailLabel?: string;
  detailValue?: string;
  detailWidth?: React.CSSProperties["minWidth"];
};

const EnumCheckboxGroup = <T extends unknown>(props: Props<T>) => {
  const contents = [];
  for (const checkbox of props.checkboxes) {
    contents.push(<Checkbox key={contents.length} label={checkbox[0]} value={props.value === checkbox[1]} />);
    contents.push(<Space key={contents.length} size={props.spaceSize} />);
  }
  contents.pop();
  if (typeof props.detailValue !== "undefined") {
    contents.push(<Space key={contents.length} size="tiny" />);
    contents.push(
      <Bracket round key={contents.length}>
        {props.detailLabel}
        <Space size="tiny" />
        <Space size="huge" minWidth={props.detailWidth || void 0}>
          {props.detailValue}
        </Space>
      </Bracket>,
    );
  }
  return <>{contents}</>;
};

export default EnumCheckboxGroup;
