import React, { useCallback, useEffect, useState } from "react";
import LoadingCircle from "@/components/LoadingCircle";
import { FetchState } from "@/interfaces/fetch";
import { useIntersect } from "@/utils/hooks";

interface InfiniteScrollListProps<ItemType> {
  list: ItemType[] | null;
  fetchState: FetchState;
  page: number | null;
  totalPage: number | null;
  renderItem: (item: ItemType, key: string | number) => React.ReactNode;
  onLoadMore?: () => void;
  noResultPlaceHolder?: React.ReactNode;
  disableInitialize?: boolean;
  loadMoreButton?: React.ReactNode;
  loadingCircle?: React.ReactNode;
  disableIntersectListner?: boolean;
}

export default function InfiniteScrollList<ItemType>({
  list = [],
  fetchState,
  page,
  totalPage,
  renderItem,
  onLoadMore,
  noResultPlaceHolder = null,
  disableInitialize,
  loadMoreButton,
  loadingCircle,
  disableIntersectListner,
}: InfiniteScrollListProps<ItemType>) {
  const [ref, entry] = useIntersect<HTMLDivElement>({
    root: null,
  });

  const [requestCheck, setRequestCheck] = useState(false);

  const handleLoadMore = useCallback(() => {
    if (fetchState === "FETCHING" || !onLoadMore) {
      return;
    }

    onLoadMore();
  }, [fetchState, onLoadMore]);

  useEffect(() => {
    if (entry && entry.isIntersecting && fetchState !== "FETCHING" && !requestCheck) {
      handleLoadMore();
      setRequestCheck(true);
    }
    if (!entry || !entry.isIntersecting) {
      setRequestCheck(false);
    }
  }, [entry, handleLoadMore, fetchState, requestCheck]);

  useEffect(() => {
    if (fetchState !== "READY" || disableInitialize) {
      return;
    }

    handleLoadMore();
  }, [fetchState, disableInitialize]);

  if (!list && (fetchState === "READY" || fetchState === "FETCHING")) {
    return <>{loadingCircle ?? <LoadingCircle position="absolute" />}</>;
  }

  return (
    <>
      {list && list.map((item, i) => renderItem(item, i))}
      {(page ?? 1) <= (totalPage ?? 1) && !disableIntersectListner && <div ref={ref} />}
      {fetchState === "FETCHING" && (loadingCircle ?? <LoadingCircle />)}
      {fetchState === "SUCCESS" && !(list && list.length) && noResultPlaceHolder}
      {loadMoreButton}
    </>
  );
}
