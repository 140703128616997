import { CorporationReportLogResponse, CreateCorporationReportLogRequest } from "@doczip/api-client";
import { corporationReportLog, corporationReportLogList, createCorporationReportLog } from "./actions";
import { corporationApi } from "@/client";
import { AMOUNT_PER_PAGE } from "@/interfaces/pages";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

export const createCorporationReportThunk = makeFetchThunkAction<
  CorporationReportLogResponse,
  CreateCorporationReportLogRequest
>(createCorporationReportLog, {
  getResponseFunction: (
    _,
    { company_name, company_number, iros_code, corporation_code, corporation_point_code, status_code, is_paid },
  ) =>
    corporationApi.createCorporationReportLog({
      company_name,
      company_number,
      iros_code,
      corporation_code,
      corporation_point_code,
      status_code,
      is_paid,
    }),
  useErrorSnackbar: true,
});

export const fetchCorporationReportLogThunk = makeFetchThunkAction<
  CorporationReportLogResponse,
  {
    reportId: number;
    code?: string;
  }
>(corporationReportLog, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId, code }) => corporationApi.getCorporationReportLog(reportId, code),
});

export const fetchMoreCorporationReportLogListThunk = makeFetchMoreListThunkAction<
  CorporationReportLogResponse,
  {
    companyName?: string;
    memo?: string;
  }
>(corporationReportLogList, {
  pageSelectFunction: ({ corporation: { corporationReportLogListPage, corporationReportLogListTotalPage } }) => ({
    page: corporationReportLogListPage,
    totalPage: corporationReportLogListTotalPage,
  }),
  getResponseListFunction: ({ page }, { companyName, memo }) =>
    corporationApi.getCorporationReportLogs(companyName, memo, page * AMOUNT_PER_PAGE, AMOUNT_PER_PAGE, false, "me"),
});
