import { CorporationReportLogResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { CorporationState } from "@/redux/corporation/types";
import { makeAsyncStateResetReducer, makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState, getTypedAsyncInitialState, getTypedAsyncListInitialState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const getAsyncInitialState = getTypedAsyncInitialState<CorporationState>();
const getAsyncListInitialState = getTypedAsyncListInitialState<CorporationState>();
const makeAsyncReducer = makeTypedAsyncReducer<CorporationState>();

const initialState: CorporationState = {
  ...getAsyncListInitialState("corporationReportLogList"),
  ...getAsyncInitialState("corporationReportLog"),
  ...getAsyncInitialState("createCorporationReportLog"),
  corporationReportLogListFetchState: "READY",
  corporationReportLogFetchState: "READY",
  createCorporationReportLogFetchState: "READY",
};

export const CORPORATION_STATE_KEY_LIST = getKeyListFromState(initialState);

export const corporationSlice = createSlice({
  name: "corporation",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"corporationReportLogList", void, ListFetchSuccessPayload<CorporationReportLogResponse>>(
      "corporationReportLogList",
      {
        useListSuccessReducer: true,
      },
    ),
    resetCorporationReportLogListState: makeAsyncStateResetReducer(initialState, "corporationReportLogList", {
      isList: true,
    }),
    ...makeAsyncReducer<"corporationReportLog", void, CorporationReportLogResponse>("corporationReportLog"),
    resetCorporationReportLogState: makeAsyncStateResetReducer(initialState, "corporationReportLog", {
      extraStateKey: "corporationReportLogFetchState",
    }),
    ...makeAsyncReducer<"createCorporationReportLog", void, CorporationReportLogResponse>("createCorporationReportLog"),
    resetCreateCorporationReportLogState: makeAsyncStateResetReducer(initialState, "createCorporationReportLog", {
      extraStateKey: "createCorporationReportLogFetchState",
    }),
  },
});

export const corporationReducer = corporationSlice.reducer;
