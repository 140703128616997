import { ChargeRequestResult } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { TransactionState } from "./types";
import { makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const makeAsyncReducer = makeTypedAsyncReducer<TransactionState>();

const initialState: TransactionState = {
  chargeFetchState: "READY",
};

export const TRANSACTION_STATE_KEY_LIST = getKeyListFromState(initialState);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"charge", void, ChargeRequestResult>("charge"),
  },
});

export const transactionReducer = transactionSlice.reducer;
