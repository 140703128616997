import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackdropWithLoadingCircle } from "@/components/Backdrop";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CenteredLayout from "@/components/CenteredLayout";
import { DescriptionContainer, BoldText, SkipLink } from "@/components/users/identify/identifyLayout";
import IamportLoader from "@/containers/IamportLoader";
import { getUserMe, getVerifyIdentityFetchState } from "@/redux/user/selectors";
import { verifyIdentityThunk } from "@/redux/user/thunk";
import { getUrl } from "@/utils/routes";

interface UserVerifyIdentityContainerProps {}

function UserVerifyIdentityContainer(props: UserVerifyIdentityContainerProps) {
  const dispatch = useDispatch();
  const userMe = useSelector(getUserMe);
  const verifyIdentityFetchState = useSelector(getVerifyIdentityFetchState);

  const handleVerify = useCallback(() => {
    const phoneNumber = userMe?.detail?.phone_number;

    if (!phoneNumber || verifyIdentityFetchState === "FETCHING") {
      return;
    }

    dispatch(verifyIdentityThunk(phoneNumber));
  }, [userMe, verifyIdentityFetchState]);

  return (
    <CenteredLayout title="추가 인증">
      {verifyIdentityFetchState === "FETCHING" && <BackdropWithLoadingCircle />}
      <DescriptionContainer>
        <BoldText>실명인증</BoldText> 하시겠어요?
      </DescriptionContainer>
      <PrimaryButton
        onClick={handleVerify}
        disabled={verifyIdentityFetchState === "FETCHING"}
        fullWidth
        sx={{
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        실명 인증
      </PrimaryButton>
      <SkipLink href={getUrl("userRoot")}>다음에</SkipLink>
    </CenteredLayout>
  );
}

export default function UserVerifyIdentityContainerWithIamportLoader(props: UserVerifyIdentityContainerProps) {
  return (
    <IamportLoader>
      <UserVerifyIdentityContainer {...props} />
    </IamportLoader>
  );
}
