export function makeUrlList(...urlList: Array<string | string[] | null | undefined>) {
  return urlList.reduce<string[]>((list, url) => {
    if (typeof url === "undefined" || url === null) {
      return list;
    } else if (typeof url === "object") {
      return [...list, ...url];
    } else {
      return [...list, url];
    }
  }, []);
}
