import React, { useEffect, useState } from "react";
import styled from "styled-components";

const GridBox = styled.div`
  display: inline-flex;
  width: 100%;
  min-width: 300px;
  max-width: 400px;

  border: 1px solid #dfe2e7;
`;

type GridItemProps = {
  active?: boolean;
  included?: boolean;
};

const GridItem = styled.button<GridItemProps>`
  flex: 1;
  padding: 0.45rem 0;

  font-size: 1rem;

  background-color: ${({ active, included }) => {
    if (active) {
      return "#FF8F17";
    } else if (included) {
      return "#fef6e7";
    } else {
      return "#ffffff";
    }
  }};
  border: 0;
  border-right: 1px solid #dfe2e7;

  &:last-of-type {
    border-right: 0;
  }
  cursor: pointer;
`;

export type Range<T> = {
  start: T;
  end: T;
};

type RangeSelectType<T> = {
  defaultValue?: InnerRangeType;
  options: Array<{ title: string; value: T }>;
  onClick: (value: Range<T> | null) => void;
};

type InnerRangeType = {
  start: number;
  end: number;
};

const RangeSelect = <T,>({ defaultValue, options, onClick }: RangeSelectType<T>) => {
  const [range, setRange] = useState<InnerRangeType | null>(null);
  const [isStart, setIsStart] = useState(true);

  useEffect(() => {
    if (defaultValue) {
      setRange(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (range !== null) {
      onClick({ start: options[range.start].value, end: options[range.end].value });
    } else {
      onClick(null);
    }
  }, [range]);

  const handleRangeClick = (newSelect: number) => {
    if (range === null) {
      setRange({ start: newSelect, end: newSelect });
      return;
    }
    if (range.start === range.end && range.start === newSelect) {
      setIsStart(true);
      setRange(null);
      return;
    }
    if (newSelect === range.start || newSelect === range.end) {
      setIsStart(false);
      setRange({
        start: newSelect,
        end: newSelect,
      });
      return;
    }
    if (isStart) {
      setIsStart(false);
      setRange({
        ...range,
        end: newSelect,
      });
    } else {
      setIsStart(true);
      setRange({
        ...range,
        start: newSelect,
      });
    }
  };

  const checkIsActive = (index: number) => {
    if (!range) {
      return false;
    }
    if (index === range.start || index === range.end) {
      return true;
    }
  };

  const checkIsInclude = (index: number) => {
    if (!range) {
      return false;
    }

    if (range.start > range.end) {
      if (index > range.start) {
        return true;
      }
      if (index < range.end) {
        return true;
      }
    }

    if (range.start < range.end) {
      if (index > range.start && index < range.end) {
        return true;
      }
    }

    return false;
  };

  return (
    <GridBox>
      {options.map((option, index) => (
        <GridItem
          key={index}
          value={index}
          type="button"
          active={checkIsActive(index) === true || false}
          included={checkIsInclude(index) === true || false}
          onClick={() => handleRangeClick(index)}
        >
          {option.title}
        </GridItem>
      ))}
    </GridBox>
  );
};

export default RangeSelect;
