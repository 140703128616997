import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SharedScroller: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return <></>;
};

export default SharedScroller;
