import React from "react";
import styled from "styled-components";

const FormLabelBox = styled.div`
  width: 100%;
  padding: 0.5rem 0;

  font-size: 0.8rem;

  &.section {
    padding: 1.5rem 0;

    font-size: 1rem;
  }
`;

type Props = {
  section?: boolean;
};

const FormLabel: React.FC<Props> = (props) => {
  const section = props.section ? "section" : "";
  const className = `${section}`;
  return <FormLabelBox className={className}>{props.children}</FormLabelBox>;
};

export default FormLabel;
