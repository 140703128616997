import React from "react";
import styled from "styled-components";
import LockIcon from "@/assets/icons/lock-icon.svg";

const HomeIconContainer = styled.div`
  width: 152px;
  height: 152px;

  &,
  & div,
  & img {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 500ms;
  }
`;
const OuterContainer = styled.div`
  width: 146px;
  height: 146px;

  background: white;
  border-radius: 52px;
  box-shadow: 0 6px 15px 0 rgba(224, 121, 25, 0.23);

  animation-name: breath;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  @keyframes breath {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0.8);
    }
  }
`;
const InnerContainer = styled.div`
  width: 97px;
  height: 97px;

  background: #ff8f17;
  border-radius: 34px;
  box-shadow: 0 6px 15px 0 rgba(255, 102, 0, 0.46);
`;
const LockIconImage = styled.img``;

interface HomeIconProps {
  className?: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({ className }) => {
  return (
    <HomeIconContainer className={className}>
      <OuterContainer>
        <InnerContainer>
          <LockIconImage src={LockIcon} />
        </InnerContainer>
      </OuterContainer>
    </HomeIconContainer>
  );
};

export default HomeIcon;
