import React from "react";
import { PostType } from "@doczip/api-client";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 12px;

  font-size: 14px;

  border-top: 1px solid #dfe2e7;

  &:first-child {
    border-top: 0;
  }

  &.topFixed {
    background-color: #f2f2f2;
  }

  &.unClickable {
    cursor: auto;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;

    font-size: 12px;
  }

  cursor: pointer;
`;

const Order = styled.div`
  flex: 0.5 44px;
  padding-right: 15px;

  font-weight: 700;
  font-size: 12px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const MobileOrder = styled.div`
  display: none;
  @media screen and (max-width: ${lengths.desktop}) {
    display: inline-block;
  }
`;

const Title = styled.div`
  flex: 8;

  text-align: left;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
  }
`;

const Date = styled.div`
  flex: 1;

  color: #797979;
  font-weight: 400;
  font-size: 12px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;

    text-align: left;
  }
`;

type PostListItemType = {
  unClickable?: boolean;
  postType?: PostType;
  topFixed?: boolean;
  id: number | string;
  title: string;
  date: string;
  hidden?: boolean;
};

function ConvertPostTypeToString(postType: PostType) {
  if (postType === PostType.Notice) {
    return "서비스";
  } else if (postType === PostType.Maintenance) {
    return "점검일정";
  }
}

const PostListItem = ({ unClickable, postType, topFixed, id, title, date }: PostListItemType) => {
  const navigator = useNavigate();

  const handleClick = (id: string | number) => {
    let newId = id;
    if (typeof id === "number") {
      newId = String(id);
    }

    navigator(getUrl("postsDetail", { params: { postId: newId }, query: postType ? { type: postType } : {} }));
  };

  const TypeString = postType && ConvertPostTypeToString(postType);

  return (
    <Container
      className={[
        topFixed && topFixed === true ? "topFixed" : "",
        unClickable && unClickable === true ? "unClickable" : "",
      ].join(" ")}
      onClick={() => handleClick(id)}
    >
      <Order>{typeof TypeString === "string" ? TypeString : id}</Order>
      <Title>
        <MobileOrder>[{typeof TypeString === "string" ? TypeString : id}]</MobileOrder> {title}
      </Title>
      <Date>{date}</Date>
    </Container>
  );
};

export default PostListItem;
