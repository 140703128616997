import React, { useCallback } from "react";
import { ReportRegisterLedger } from "@blitz-core/api-client";
import { RegisterLedgerOwnershipSummary } from "@doczip/api-client";
import { Modal, ModalProps, Backdrop as MuiBackdrop, ButtonBase } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AddressList from "./AddressList";
import { getCheckedOwner } from "@/redux/report/selectors";
import { colors } from "@/styles/values";

const Backdrop = styled(MuiBackdrop)``;

const StyledModal = styled(Modal)`
  position: relative;

  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;
  max-width: 328px;
  height: 100%;
  max-height: 340px;
  padding: 26px 20px;

  background-color: #fff;
  transform: translate(-50%, -50%);
`;

const TitleContainer = styled.div``;

const Title = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 16px;
`;

const ContentContainer = styled.div`
  height: 171px;
  margin: 16px 0 30px;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    height: 48px;

    font-weight: 700;
    font-size: 13px;

    &.close {
      width: calc(40% - 5px);

      color: #797979;

      background-color: #f2f2f2;
    }
    &.open {
      width: calc(60% - 5px);

      color: #fff;

      background-color: ${colors.main};
    }
  }
`;

interface ReportOwnerAddressSearchModalProps extends Omit<ModalProps, "children" | "onClose"> {
  onClose: () => void;
  title: string;
  onConfirm: (checkedOwner: RegisterLedgerOwnershipSummary) => void;
  registerLedger?: ReportRegisterLedger;
}

const ReportOwnerAddressSearchModal: React.FC<ReportOwnerAddressSearchModalProps> = ({
  open,
  onClose,
  title,
  onConfirm,
  registerLedger,
}) => {
  const checkedOwner = useSelector(getCheckedOwner);

  const handleConfirm = useCallback(() => {
    if (checkedOwner) {
      onConfirm(checkedOwner);
      onClose();
    }
  }, [checkedOwner]);

  return (
    <StyledModal open={open} onClose={onClose} BackdropComponent={Backdrop}>
      <Container>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>

        <ContentContainer>
          <AddressList registerLedger={registerLedger} />
        </ContentContainer>

        <ButtonContainer>
          <Button className="close" onClick={onClose}>
            닫기
          </Button>
          <Button className="open" onClick={handleConfirm}>
            다음
          </Button>
        </ButtonContainer>
      </Container>
    </StyledModal>
  );
};

export default ReportOwnerAddressSearchModal;
