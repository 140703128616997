import { SUBSCRIPTION_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getSubscriptionList,
  getSubscriptionListFetchState,
  getSubscriptionListPage,
  getSubscriptionListTotalCount,
  getSubscriptionListTotalPage,
  getCancelSubscriptionFetchState,
  getRefundSubscriptionFetchState,
  getSubscribeFetchState,
} = makeSubStateSelectorMap("subscription", SUBSCRIPTION_STATE_KEY_LIST);
