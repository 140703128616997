import React from "react";
import { useSelector } from "react-redux";
import ReportPriceInfo from "@/components/reports/ReportPriceInfo";
import { getReportFetchState, getKbPrice, getKabPrice, getTankerPrice } from "@/redux/report/selectors";

interface ReportPriceInfoContainerProps {}

const ReportPriceInfoContainer: React.FC<ReportPriceInfoContainerProps> = () => {
  const reportFetchState = useSelector(getReportFetchState);
  const kbResult = useSelector(getKbPrice);
  const kabResult = useSelector(getKabPrice);
  const tankerPriceList = useSelector(getTankerPrice);
  const chartData = { kbResult, kabResult, tankerPriceList };

  return <ReportPriceInfo chartData={chartData} reportFetchState={reportFetchState} />;
};

export default ReportPriceInfoContainer;
