export interface RegionOptionType {
  label: string;
  code: string;
}

interface getRegionLevelArrayInterface {
  regionLevel: string;
  regionNameCodeJson: Object;
  options?: {
    prevRegionCode: string;
  };
}

export function getRegionLevelArray({ regionLevel, regionNameCodeJson, options }: getRegionLevelArrayInterface) {
  const regionLevelArray = [];

  for (const [status, regionNameCode] of Object.entries(regionNameCodeJson)) {
    if (status === "존재") {
      for (const [name, code] of Object.entries(regionNameCode as Object)) {
        const sigunguCode = code.substr(2, 3);
        const eupmyeondongCode = code.substr(5, 3);
        const liCode = code.substr(8, 2);

        switch (regionLevel) {
          case "level_1":
            if (sigunguCode === "000" && eupmyeondongCode === "000" && liCode === "00") {
              regionLevelArray.push({ label: name, code: code.substr(0, 2) });
            }
            break;
          case "level_2":
            if (options && options.prevRegionCode) {
              if (
                code.substr(0, 2) === options.prevRegionCode &&
                sigunguCode !== "000" &&
                eupmyeondongCode === "000" &&
                liCode === "00"
              ) {
                regionLevelArray.push({ label: name, code: code.substr(0, 5) });
              }
            } else if (sigunguCode !== "000" && eupmyeondongCode === "000" && liCode === "00") {
              regionLevelArray.push({ label: name, code: code.substr(0, 5) });
            }
            break;
          case "level_3":
            if (sigunguCode !== "000" && eupmyeondongCode !== "000" && liCode === "00") {
              regionLevelArray.push({ label: name, code });
            } else if (sigunguCode !== "000" && eupmyeondongCode !== "000" && liCode !== "00") {
              regionLevelArray.push({ label: name, code });
            }
            break;

          default:
            break;
        }
      }
      if (regionLevel === "level_1") {
        regionLevelArray.push({ label: "세종특별자치시", code: "3611" });
      }
    }
  }

  return regionLevelArray;
}
