import { Listing } from "@vesta/api-client";
import {
  MolitResult,
  ReportBuildingLedger,
  ReportDeclaredPriceResult,
  ReportInfocareResult,
  ReportKABResult,
  ReportKBResult,
  ReportLandLedger,
  ReportLandUsingInfo,
  ReportNSDILandFeature,
  ReportRegisterLedger,
  ReportTankerPriceResult,
} from "@blitz-core/api-client";
import { ReportLogResponse, ReportLogUserRelationResponse } from "@doczip/api-client";
import axios from "axios";
import { blitzCoreCorporationApi, blitzCoreReportApi, reportApi, vestaListingApi } from "@/client";
import {
  buildingLedgerFetch,
  declaredPriceFetch,
  infocareFetch,
  kabPriceFetch,
  kbPriceFetch,
  landLedgerFetch,
  landUsingInfoFetch,
  molitContractFetch,
  nsdiFetch,
  randomReportFetch,
  registerLedgerFetch,
  createReportFetch,
  reportReRequestFetch,
  tankerPriceFetch,
  reportFetch,
  molitApartmentKindListingsFetch,
  memoFetch,
  createBulkReportFetch,
} from "@/redux/report/actions";
import {
  BulkReportRequestPayload,
  CreateReportRequestPayload,
  MemoRequestPayload,
  MolitApartmentKindListingsFetchPayload,
  ReportRequestPayload,
  ReportReRequestPayload,
} from "@/redux/report/types";
import { getReportReRequestResource } from "@/redux/report/util";
import { handleAxiosError } from "@/redux/util";
import { GenericThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";
import { WithString } from "@/utils/redux/types";

export const createReportThunk = makeFetchThunkAction<ReportLogResponse, ReportRequestPayload>(createReportFetch, {
  getResponseFunction: (_, { registeredAddress, registeredPinNumber, registeredType, isPaid }) =>
    reportApi.createReportLog({
      registered_address: registeredAddress,
      registered_pin_number: registeredPinNumber,
      registered_type: registeredType,
      is_paid: isPaid,
    }),
  useErrorSnackbar: true,
});

export const createBulkReportThunk = ({ reportRequestList }: BulkReportRequestPayload): GenericThunkAction => {
  return async (dispatch) => {
    dispatch(createBulkReportFetch.fetchRequest());

    try {
      const status = (await reportApi.bulkCreateReportLog(reportRequestList)).status;

      dispatch(createBulkReportFetch.fetchSuccess(status));
    } catch (e: unknown) {
      let status;
      let errorMessage;

      if (axios.isAxiosError(e) && e.response?.status) {
        status = e.response.status;
      }

      if (status) {
        if (status === 422) {
          errorMessage = "다중열람 요청에 실패하였습니다. 잠시 후 재시도 해주세요.";
        }
      }

      handleAxiosError(e, dispatch, reportReRequestFetch.fetchFailure, {
        useErrorSnackbar: !!status && !!errorMessage,
        errorSnackbarMessage: errorMessage,
      });
    }
  };
};

export const reportFetchThunk = makeFetchThunkAction<ReportLogResponse, CreateReportRequestPayload>(reportFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId, code }) => reportApi.getReportLog(reportId, code),
});

export const registerLedgerFetchThunk = makeFetchThunkAction<
  WithString<ReportRegisterLedger>,
  CreateReportRequestPayload
>(registerLedgerFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportRegisterLedger(reportId),
});

export const buildingLedgerFetchThunk = makeFetchThunkAction<
  WithString<ReportBuildingLedger>,
  CreateReportRequestPayload
>(buildingLedgerFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportBuildingLedger(reportId),
});

export const landLedgerFetchThunk = makeFetchThunkAction<WithString<ReportLandLedger>, CreateReportRequestPayload>(
  landLedgerFetch,
  {
    useApiPolling: true,
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportLandLedger(reportId),
  },
);

export const landUsingInfoFetchThunk = makeFetchThunkAction<
  WithString<ReportLandUsingInfo>,
  CreateReportRequestPayload
>(landUsingInfoFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportLandUsingInfo(reportId),
});

export const kabPriceFetchThunk = makeFetchThunkAction<WithString<ReportKABResult>, CreateReportRequestPayload>(
  kabPriceFetch,
  {
    useApiPolling: true,
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportKabPrice(reportId),
  },
);

export const kbPriceFetchThunk = makeFetchThunkAction<WithString<ReportKBResult>, CreateReportRequestPayload>(
  kbPriceFetch,
  {
    useApiPolling: true,
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportKbPrice(reportId),
  },
);

export const molitContractFetchThunk = makeFetchThunkAction<WithString<MolitResult>, CreateReportRequestPayload>(
  molitContractFetch,
  {
    useApiPolling: true,
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportMolitContract(reportId),
  },
);

export const molitApartmentKindListingsFetchThunk = makeFetchThunkAction<
  WithString<Listing[]>,
  MolitApartmentKindListingsFetchPayload
>(molitApartmentKindListingsFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { molitApartmentKindId, tradeType }) =>
    vestaListingApi.listingMolitApartmentKindListingsIndex(molitApartmentKindId, tradeType),
});

export const infocareFetchThunk = makeFetchThunkAction<WithString<ReportInfocareResult>, CreateReportRequestPayload>(
  infocareFetch,
  {
    useApiPolling: true,
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportInfocare(reportId),
  },
);

export const nsdiFetchThunk = makeFetchThunkAction<WithString<ReportNSDILandFeature>, CreateReportRequestPayload>(
  nsdiFetch,
  {
    useApiPolling: true,
    getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportNsdi(reportId),
  },
);

export const tankerPriceFetchThunk = makeFetchThunkAction<
  WithString<ReportTankerPriceResult[]>,
  CreateReportRequestPayload
>(tankerPriceFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportTankerPrice(reportId),
});

export const declaredPriceFetchThunk = makeFetchThunkAction<
  WithString<ReportDeclaredPriceResult>,
  CreateReportRequestPayload
>(declaredPriceFetch, {
  useApiPolling: true,
  getResponseFunction: (_, { reportId }) => blitzCoreReportApi.reportDeclaredPrice(reportId),
});

export const randomReportFetchThunk = makeFetchThunkAction<ReportLogUserRelationResponse[], void>(randomReportFetch, {
  getResponseFunction: () => reportApi.getRandomReports(),
});

export const reportReRequestFetchThunk = ({
  type,
  reportId,
  resource,
  requesterIp,
}: ReportReRequestPayload): GenericThunkAction => {
  return async (dispatch) => {
    dispatch(reportReRequestFetch.fetchRequest());

    try {
      if (type === "normal") {
        const { data } = await blitzCoreReportApi.reportReRequest(
          reportId,
          getReportReRequestResource(resource),
          requesterIp,
        );

        dispatch(reportReRequestFetch.fetchSuccess(data));
      } else {
        const { data } = await blitzCoreCorporationApi.corporationReRequest(reportId, requesterIp);

        dispatch(reportReRequestFetch.fetchSuccess(data));
      }
    } catch (e: unknown) {
      let status;
      let errorMessage;

      if (axios.isAxiosError(e) && e.response?.status) {
        status = e.response.status;
      }

      if (status) {
        if (status === 400) {
          errorMessage = "재요청에 실패하였습니다.";
        } else if (status === 429) {
          errorMessage = "재요청은 10분에 한 번만 가능합니다. 잠시 후 재시도 해주세요.";
        }
      }

      handleAxiosError(e, dispatch, reportReRequestFetch.fetchFailure, {
        useErrorSnackbar: !!status && !!errorMessage,
        errorSnackbarMessage: errorMessage,
      });
    }
  };
};

export const memoFetchThunk = makeFetchThunkAction<void, MemoRequestPayload>(memoFetch, {
  getResponseFunction: (_, { id, memo }) => reportApi.updateReportLogMemo(id, { memo }),
});
