import { TransactionType } from "@doczip/api-client";

export function formatTransactionType(transactionType: TransactionType) {
  switch (transactionType) {
    case TransactionType.Charge: {
      return "결제";
    }
    case TransactionType.Event: {
      return "증정";
    }
    case TransactionType.Refund: {
      return "환불";
    }
    case TransactionType.Payment: {
      return "사용";
    }
  }
}
