import React from "react";
import styled from "styled-components";

interface TitledWrapperContainerProps
  extends Pick<TitledWrapperProps, "containerStyle">,
    Pick<TitledWrapperProps, "backgroundStyle"> {}

const TitledWrapperContainer = styled.div<TitledWrapperContainerProps>`
  width: ${({ containerStyle }) => (containerStyle ? containerStyle.width ?? "100%" : "100%")};
  height: ${({ containerStyle }) => (containerStyle ? containerStyle.height ?? "auto" : "auto")};
  padding: ${({ backgroundStyle }) => (backgroundStyle ? backgroundStyle.padding ?? "0" : "0")};

  background-color: ${({ backgroundStyle }) =>
    backgroundStyle ? backgroundStyle.color ?? "transparent" : "transparent"};

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const TitledWrapperHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

interface TitleContainerProps extends Pick<TitledWrapperProps, "titleStyle"> {}

const TitleContainer = styled.div<TitleContainerProps>`
  flex: 1 1 0%;
  width: 50%;
  margin-bottom: ${({ titleStyle }) => (titleStyle ? titleStyle.bottomGap ?? 0 : 0)}px;

  color: ${({ titleStyle }) => (titleStyle ? titleStyle.color ?? "#000000" : "#000000")};
  font-weight: ${({ titleStyle }) => (titleStyle ? titleStyle.weight ?? "400" : "400")};
  font-size: ${({ titleStyle }) => (titleStyle ? titleStyle.size ?? 16 : 16)}px;
  letter-spacing: ${({ titleStyle }) => (titleStyle ? titleStyle.letterSpacing ?? "normal" : "normal")};
  text-align: ${({ titleStyle }) => (titleStyle ? titleStyle.align ?? "left" : "left")};
`;

const Title = styled.span``;

const HeaderNodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

const HeaderNodeList = styled.ul`
  display: flex;
  align-items: center;
`;

const HeaderNodeItem = styled.li`
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`;

const ChildrenContainer = styled.div``;

interface TitleStyle {
  align?: React.CSSProperties["textAlign"];
  weight?: React.CSSProperties["fontWeight"];
  color?: React.CSSProperties["color"];
  size?: React.CSSProperties["fontSize"];
  letterSpacing?: React.CSSProperties["letterSpacing"];
  bottomGap?: number;
}

interface BackgroundStyle {
  color?: React.CSSProperties["backgroundColor"];
  padding?: React.CSSProperties["padding"];
}

interface ContainerStyle {
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
}

export interface TitledWrapperProps {
  title: string;
  titleStyle?: TitleStyle;
  headerNodeList?: React.ReactNode[];
  containerStyle?: ContainerStyle;
  backgroundStyle?: BackgroundStyle;
}

const TitledWrapper: React.FC<TitledWrapperProps> = ({
  title,
  titleStyle,
  headerNodeList,
  containerStyle,
  backgroundStyle,
  children,
}) => {
  return (
    <TitledWrapperContainer containerStyle={containerStyle} backgroundStyle={backgroundStyle}>
      <TitledWrapperHeader>
        <TitleContainer titleStyle={titleStyle}>
          <Title>{title}</Title>
        </TitleContainer>

        {headerNodeList?.length && (
          <HeaderNodeContainer>
            <HeaderNodeList>
              {headerNodeList.map((node, index) => (
                <HeaderNodeItem key={index}>{node}</HeaderNodeItem>
              ))}
            </HeaderNodeList>
          </HeaderNodeContainer>
        )}
      </TitledWrapperHeader>

      <ChildrenContainer>{children}</ChildrenContainer>
    </TitledWrapperContainer>
  );
};

export default TitledWrapper;
