import { SaleCommentResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { SaleCommentState } from "./types";
import { makeAsyncStateResetReducer, makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState, getTypedAsyncListInitialState } from "@/utils/redux/state";

const getAsyncListInitialState = getTypedAsyncListInitialState<SaleCommentState>();
const makeAsyncReducer = makeTypedAsyncReducer<SaleCommentState>();

const initialState: SaleCommentState = {
  ...getAsyncListInitialState("saleComments"),
  createSaleCommentFetchState: "READY",
  updateSaleCommentFetchState: "READY",
  deleteSaleCommentFetchState: "READY",
  createSaleCommentReplyFetchState: "READY",
  updateSaleCommentReplyFetchState: "READY",
  deleteSaleCommentReplyFetchState: "READY",
  createSaleCommentReactionFetchState: "READY",
  deleteSaleCommentReactionFetchState: "READY",
  createSaleCommentReplyReactionFetchState: "READY",
  deleteSaleCommentReplyReactionFetchState: "READY",
};

export const SALE_COMMENT_STATE_KEY_LIST = getKeyListFromState(initialState);

export const saleCommentSlice = createSlice({
  name: "saleComment",
  initialState,
  reducers: {
    reset: () => initialState,
    ...makeAsyncReducer<"saleComments", void, SaleCommentResponse[]>("saleComments", {
      successReducer: (_state, { payload }) => {
        _state.saleComments = payload;
        _state.saleCommentsFetchState = "SUCCESS";
      },
    }),
    resetSaleCommentsState: makeAsyncStateResetReducer(initialState, "saleComments"),
    ...makeAsyncReducer("createSaleComment"),
    ...makeAsyncReducer("updateSaleComment"),
    ...makeAsyncReducer("deleteSaleComment"),
    ...makeAsyncReducer("createSaleCommentReply"),
    ...makeAsyncReducer("updateSaleCommentReply"),
    ...makeAsyncReducer("deleteSaleCommentReply"),
    ...makeAsyncReducer("createSaleCommentReaction"),
    ...makeAsyncReducer("deleteSaleCommentReaction"),
    ...makeAsyncReducer("createSaleCommentReplyReaction"),
    ...makeAsyncReducer("deleteSaleCommentReplyReaction"),
  },
});

export const saleCommentReducer = saleCommentSlice.reducer;
