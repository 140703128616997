import { amountToKorean } from "./text";

function isNumeric(str: any) {
  return /^\d+$/.test(str);
}

function getNumberWithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toNumberWithCommas(number: string | number): string {
  if (typeof number === "number" || isNumeric(number.toString())) {
    return `${getNumberWithCommas(number)}`;
  } else {
    return number.toString();
  }
}

export function toAmount(number: string | number): string {
  if (typeof number === "number" || isNumeric(number.toString())) {
    return `${amountToKorean(Number.parseInt(number.toString(), 10))}원 (₩${toNumberWithCommas(number)})`;
  } else {
    return number.toString();
  }
}
