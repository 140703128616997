import React from "react";
import {
  DocumentResponse,
  DocumentTypeEnum,
  PersonRegistrationTypeEnum,
  ResidentialManualResponse,
} from "@doczip/api-client";
import { Grid } from "@mui/material";
import AgentField from "./AgentField";
import FormLabel from "./FormLabel";
import ListField from "./ListField";
import PersonField from "./PersonField";

type Props = {
  document: DocumentResponse;
  onChange: (document: DocumentResponse) => void;
};

const CommonForm: React.FC<Props> = ({ document, onChange }) => {
  const handleChange = <KT extends keyof DocumentResponse>(key: KT, value: DocumentResponse[KT]) => {
    onChange({
      ...document,
      [key]: value,
    });
  };

  const trade =
    document.document_type === DocumentTypeEnum.TradeContract ||
    (document.document_type === DocumentTypeEnum.ResidentialManual &&
      (document.residential_manual as ResidentialManualResponse).is_trade);

  return (
    <>
      <FormLabel section>거래당사자 정보</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <ListField
              label={trade ? "매도인" : "임대인"}
              value={document.owners}
              onChange={(v) => handleChange("owners", v)}
              input={PersonField}
              initFactory={() => {
                return {
                  address_expr: "",
                  full_name: "",
                  phone_number: "",
                  registration_number: "",
                  registration_type: PersonRegistrationTypeEnum.Resident,
                };
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <ListField
              label={trade ? "매수인" : "임차인"}
              value={document.consumers}
              onChange={(v) => handleChange("consumers", v)}
              input={PersonField}
              initFactory={() => {
                return {
                  address_expr: "",
                  full_name: "",
                  phone_number: "",
                  registration_number: "",
                  registration_type: PersonRegistrationTypeEnum.Resident,
                };
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel section>중개인 정보</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <ListField
              label="중개인"
              value={document.agents}
              onChange={(v) => handleChange("agents", v)}
              input={AgentField}
              initFactory={() => {
                return {
                  address_expr: "",
                  agency_name: "",
                  agency_head_full_name: "",
                  full_name: "",
                  phone_number: "",
                  registration_number: "",
                };
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonForm;
