import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Layout from "@/components/Layout";
import LoadingCircle from "@/components/LoadingCircle";
import NotFound from "@/components/NotFound";
import StaticDocument from "@/components/StaticDocument";

type Params = {
  staticId: string;
};

type Props = {};

const StaticDocumentContainer: React.FC<Props> = () => {
  const params = useParams<Params>();
  const [content, setContent] = useState<string>();
  const [error, setError] = useState(false);

  const fetchFile = async () => {
    try {
      const response = await Axios.get(`/files/${params.staticId}.md`);
      setContent(response.data as string);
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchFile();
  }, [params.staticId]);

  const getContent = () => {
    if (error) {
      return <NotFound />;
    } else if (typeof content === "undefined") {
      return <LoadingCircle position="absolute" />;
    }

    return <StaticDocument content={content} />;
  };

  return (
    <>
      <Layout columnless />
      {getContent()}
    </>
  );
};

export default StaticDocumentContainer;
