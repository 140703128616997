import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;

  width: auto;
  height: auto;
`;

const Mark = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;

  width: 28px;
  height: 28px;

  transform: translate(-50%, -50%);
`;

type MarkEmbedProps = {
  markSrc: string;
  markWitdh?: string;
  markHeight?: string;
  children: React.ReactNode;
};

const MarkEmbed = ({ markSrc, markWitdh, markHeight, children }: MarkEmbedProps) => {
  return (
    <Container>
      {children}
      <Mark src={markSrc} style={{ width: markWitdh, height: markHeight }} />
    </Container>
  );
};

export default MarkEmbed;
