import React from "react";
import styled from "styled-components";

type PadBoxProps = {
  size: number;
  hsize: number;
};

const PadBox = styled.div<PadBoxProps>`
  width: 100%;
  padding: ${(props) => (props.size * 8).toString()}px ${(props) => (props.size * 8).toString()}px;
`;

type Props = {
  size?: number;
  hsize?: number;
};

const Pad: React.FC<Props> = (props) => {
  return (
    <PadBox size={props.size || 1} hsize={props.hsize || 0}>
      {props.children}
    </PadBox>
  );
};

export default Pad;
