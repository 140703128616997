import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router";
import { logoutThunk } from "@/redux/auth/thunk";
import { getUrl } from "@/utils/routes";

export default function LogoutContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutThunk());
  }, []);

  return <Navigate to={getUrl("root")} />;
}
