import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const MapElement = styled.div`
  z-index: 0;

  width: 100%;
  height: 290px;
`;

interface MapProps {
  latitude: string;
  longitude: string;
}

declare global {
  interface Window {
    naver?: any; // naver가 정의되지 않았을 수도 있으므로 optional로 설정
  }
}

const Map: React.FC<MapProps> = ({ latitude, longitude }) => {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const elem = mapRef.current;

    // API가 로드되었는지, naver.maps.Map가 존재하는지 확인
    if (elem && typeof window !== "undefined" && window.naver && window.naver.maps && window.naver.maps.Map) {
      const naver = window.naver;
      const map = new naver.maps.Map(elem, {
        center: new naver.maps.LatLng(parseFloat(latitude), parseFloat(longitude)),
        zoom: 10, // 기본 줌 레벨 설정
      });

      const position = new naver.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
      map.setCenter(position);

      const marker = new naver.maps.Marker({
        map,
        position,
      });

      return () => {
        marker.setMap(null); // 클린업 함수에서 마커 제거
      };
    }
  }, [latitude, longitude]); // mapRef.current는 의존성 배열에서 제거

  return <MapElement ref={mapRef} />;
};

export default Map;
