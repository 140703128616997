import React, { useState } from "react";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import classnames from "classnames";
import { decamelizeKeys } from "humps";
import styled from "styled-components";
import StyledButton from "./StyledButton";
import { colors, depths } from "@/styles/values";

const DialogShade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${depths.overlayShade};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background-color: ${colors.black}aa;
`;

const DialogBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 316px;
  max-width: 90vw;
  min-height: 100px;
  max-height: 90vh;
  max-height: calc(var(--vh, 1vh) * 90);
  padding: 26px 34px;

  word-break: keep-all;

  background-color: ${colors.white};

  &.narrow {
    max-width: min(328px, 90vw);
    padding: 26px 24px;
  }

  &.wide {
    min-width: min(480px, 90vw);
    padding: 26px 24px;
  }
`;

const IconBox = styled.div`
  margin-left: -3px;
  padding-bottom: 12px;

  color: ${colors.main};
`;

const Title = styled.div`
  width: 100%;
  padding-bottom: 16px;

  color: ${colors.black};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const Content = styled.div`
  width: 100%;

  color: ${colors.black};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-line;

  &.titleless {
    padding-top: 10px;

    font-size: 14px;
  }
`;

const TextFieldBox = styled.div`
  width: 100%;
  padding-top: 8px;

  &.titleless {
    padding-top: 32px;
  }

  & .MuiInputBase-root {
    border-radius: 0;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 36px;
`;

const ButtonBox = styled.div`
  flex: 1 0 auto;
`;

type Props = {
  title?: React.ReactNode;
  content: React.ReactNode;
  onClose: () => void;
  onConfirm?: (v?: string) => void;
  prompt?: boolean;
  placeholder?: string;
  confirmText?: string;
  closeText?: string;
  narrow?: boolean;
  wide?: boolean;
};

const Dialog: React.FC<Props> = (props) => {
  const { narrow, wide } = props;
  const classNames = classnames(decamelizeKeys({ narrow, wide }, { separator: "-" }));
  const [value, setValue] = useState(props.placeholder);

  const handleDialogClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm(value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleConfirm();
    }
  };

  const confirmText = props.confirmText || "확인";
  const closeText = props.closeText || "취소";

  return (
    <DialogShade onClick={() => props.onClose()}>
      <DialogBox className={classNames} onClick={handleDialogClick}>
        <IconBox>
          <InfoOutlinedIcon sx={{ fontSize: "33.33px" }} />
        </IconBox>
        {props.title && <Title>{props.title}</Title>}
        <Content className={props.title ? "" : "titleless"}>{props.content}</Content>
        {props.prompt && (
          <TextFieldBox className={props.title ? "" : "titleless"}>
            <TextField
              size="small"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyPress={handleKeyPress}
              fullWidth
            />
          </TextFieldBox>
        )}
        <ButtonsBox>
          <ButtonBox>
            <StyledButton bold graylined={Boolean(props.onConfirm)} onClick={() => props.onClose()} fullWidth>
              {props.onConfirm ? closeText : confirmText}
            </StyledButton>
          </ButtonBox>
          {props.onConfirm && (
            <ButtonBox>
              <StyledButton bold onClick={handleConfirm} fullWidth>
                {confirmText}
              </StyledButton>
            </ButtonBox>
          )}
        </ButtonsBox>
      </DialogBox>
    </DialogShade>
  );
};

export default Dialog;
