import React from "react";
import styled from "styled-components";
import Space from "./Space";
import type { Size } from "@/interfaces/style";

const RoundBracketCovered = styled.span`
  display: inline-block;

  &::before {
    display: inline-block;

    content: "(";
  }
  &::after {
    display: inline-block;

    content: ")";
  }
`;

const BracketCovered = styled.span`
  display: inline-block;

  &::before {
    display: inline-block;

    content: "[";
  }
  &::after {
    display: inline-block;

    content: "]";
  }
`;

type Props = {
  round?: boolean;
  size?: Size;
  minWidth?: React.CSSProperties["minWidth"];
  align?: React.ComponentProps<typeof Space>["align"];
};

const Bracket: React.FC<Props> = ({ round, size, minWidth, align, children }) => {
  const Component = round ? RoundBracketCovered : BracketCovered;
  return (
    <Component>
      <Space size={size} minWidth={minWidth} align={align}>
        {children}
      </Space>
    </Component>
  );
};

export default Bracket;
