import React from "react";
import PostLayout from "@/components/posts/common/PostLayout";
import PostListContainer from "@/containers/posts/PostListContainer";
import PostTitledContainer from "@/containers/posts/PostTitledContainer";
import PostTypeSelect from "@/containers/posts/PostTypeSelect";

const Posts = () => {
  return (
    <PostLayout>
      <PostTitledContainer />
      <PostTypeSelect />
      <PostListContainer />
    </PostLayout>
  );
};

export default Posts;
