import { USER_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getUserMe,
  getUserMeFetchState,

  getReportLogList,
  getReportLogListFetchState,
  getReportLogListPage,
  getReportLogListTotalPage,
  getReportLogListTotalCount,

  getReportLogDocument,
  getReportLogDocumentFetchState,

  getReportLogDeleteFetchState,

  getChangePhoneNumberFetchState,
  getChangePasswordFetchState,
  getEditUserFetchState,
  getVerificationCodeFetchState,

  getRealtorBrokerageList,
  getRealtorBrokerageListFetchState,

  getVerifyRealtorFetchState,
  getVerifyIdentityFetchState,
} = makeSubStateSelectorMap("user", USER_STATE_KEY_LIST);
