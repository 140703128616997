import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUserMe } from "@/redux/user/selectors";

const useUserData = () => {
  const user = useSelector(getUserMe);

  const subscribed = useMemo(() => {
    return !!user?.subscription;
  }, [user]);

  const isTanker = useMemo(() => {
    return user?.subscription?.next_iamport_account === "TANKER";
  }, [user]);

  const isAdmin = useMemo(() => {
    return user?.is_admin;
  }, [user]);

  return { user, subscribed, isTanker, isAdmin };
};

export default useUserData;
