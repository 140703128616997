import React from "react";
import { SubscriptionType, UserResponse } from "@doczip/api-client";
import { Link } from "react-router-dom";
import styled from "styled-components";
import StyledButton from "./StyledButton";
import { ReactComponent as CrownIcon } from "@/assets/icons/crown.svg";
import MobileOnly from "@/components/MobileOnly";
import { colors } from "@/styles/values";
import { getUrl } from "@/utils/routes";
// import { showMessenger } from "@/utils/channel";

const NavigationBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
`;

const HeaderBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 20px;
  width: 100%;
  padding: 17px 19px;

  border-bottom: 1px solid #f2f2f2;
`;

const ContentBox = styled.div`
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  overflow-y: overlay;
`;

const SpacedRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const ButtonRow = styled(SpacedRow)`
  > a,
  > div,
  > button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const HeaderText = styled.div`
  color: ${colors.black};
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  &.gray {
    color: #676870;
    font-weight: normal;
  }

  &.green {
    color: ${colors.green};
  }
`;

const HeaderLink = styled(HeaderText)`
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.6;
  }
`;

const UserDataBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  padding: 12px 18.5px;

  border: 1px solid #f2f2f2;
`;

const NavItemsBox = styled.div`
  padding: 8px 0;

  border-bottom: 1px solid #f2f2f2;
`;

const NavItem = styled.a`
  position: relative;

  display: block;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 48px;
  padding-right: 45px;
  padding-left: 28px;

  color: ${colors.dark};
  font-size: 14px;

  transition: all 0.2s ease;

  &.bold {
    font-weight: bold;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const NavItemIcon = styled.div`
  position: absolute;
  top: 18px;
  right: 30px;

  width: 7px;
  height: 14px;

  background-image: url("/images/chevron-right.svg");
  background-size: 100% 100%;
`;

const QuickIcon = styled.div`
  width: 28px;
  height: 24px;
  margin-top: -4px;
  margin-left: 4px;

  background-image: url("/images/quick.svg");
  background-size: 100% 100%;
`;

const FreeIcon = styled.div`
  width: 29px;
  height: 16px;
  margin-top: -4px;
  margin-left: 4px;

  background-color: ${colors.main};
  background-image: url("/images/free.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70% 70%;
  border-radius: 20px;
`;

const SubscribeBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  padding: 20px 20px;

  color: ${colors.dark};
  font-size: 11px;
  line-height: 16px;
`;

const LogoImg = styled.img``;

const LogoButton = styled(StyledButton)`
  &:hover ${LogoImg} {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(345deg) brightness(105%)
      contrast(101%);
  }
`;

// const FooterBox = styled.div`
//   padding: 30px 28px;

//   color: ${colors.dark};
//   font-weight: 700;
//   font-size: 14px;
// `;

// const FooterButtonsBox = styled.div`
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: space-between;
//   padding-top: 12px;
//   padding-bottom: 16px;
// `;

// const FooterButtonBox = styled.div`
//   flex: 0 0 auto;
//   width: calc(100% / 2 - 6px);
// `;

type Props = {
  onClick?: () => void;
  user?: UserResponse;
};

const Navigation: React.FC<Props> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  // const handleShowMessenger = () => {
  //   if (props.onClick) {
  //     props.onClick();
  //   }
  //   showMessenger();
  // };

  const headerContents = props.user ? (
    <HeaderBox>
      <SpacedRow>
        <HeaderText>{props.user.detail?.phone_number}</HeaderText>
        <HeaderLink className="gray" as={Link} to={getUrl("logout")} onClick={handleClick}>
          로그아웃
        </HeaderLink>
      </SpacedRow>
      <UserDataBox>
        {props.user?.subscription?.subscription_type === SubscriptionType.OwnersclubBasic && (
          <SpacedRow>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
                color: `${colors.main}`,
              }}
            >
              <CrownIcon />
              <HeaderText style={{ color: "inherit", fontWeight: 600 }}>오너스클럽</HeaderText>
            </div>
          </SpacedRow>
        )}
        <SpacedRow>
          <HeaderText>다운로드권</HeaderText>
          <HeaderText className="green">{props.user.ticket_amount}개</HeaderText>
        </SpacedRow>
        {props.user.free_ledger_count > 0 && (
          <SpacedRow>
            <HeaderText>무료 다운로드권</HeaderText>
            <HeaderText className="green">{props.user.free_ledger_count}개</HeaderText>
          </SpacedRow>
        )}
        <SpacedRow>
          <HeaderText>열람한 부동산</HeaderText>
          <HeaderText className="green">{props.user.report_log_count}개</HeaderText>
        </SpacedRow>
        <SpacedRow>
          <HeaderText>작성 문서</HeaderText>
          <HeaderText className="green">{props.user.document_count}개</HeaderText>
        </SpacedRow>
      </UserDataBox>
      <ButtonRow>
        <MobileOnly useContainer fullWidth>
          <StyledButton gray fontSize="13px" bold fullWidth to={getUrl("userRoot")} onClick={handleClick}>
            내 계정
          </StyledButton>
        </MobileOnly>
        <StyledButton fontSize="13px" bold fullWidth to={getUrl("userTransactionsNew")} onClick={handleClick}>
          다운로드권 결제
        </StyledButton>
      </ButtonRow>
    </HeaderBox>
  ) : (
    <HeaderBox>
      <SpacedRow>
        <HeaderText>환영합니다</HeaderText>
        <HeaderLink className="gray" as={Link} to={getUrl("login")} onClick={handleClick}>
          로그인/회원가입
        </HeaderLink>
      </SpacedRow>
    </HeaderBox>
  );

  return (
    <NavigationBox>
      {headerContents}
      <ContentBox>
        <NavItemsBox>
          <NavItem as={Link} to={getUrl("dashboard")} onClick={handleClick} className="bold">
            등기부 열람 바로하기
            <QuickIcon />
            <NavItemIcon />
          </NavItem>
          <NavItem as={Link} to={getUrl("ownersClub")} onClick={handleClick} className="bold">
            닥집 보유 등기부 검색
            <FreeIcon />
          </NavItem>
          <NavItem as={Link} to={getUrl("reportsRoot")} onClick={handleClick}>
            내 열람내역
          </NavItem>
          <NavItem as={Link} to={getUrl("documentsRoot")} onClick={handleClick}>
            내 문서함
          </NavItem>
          <NavItem as={Link} to={getUrl("salesRoot")} onClick={handleClick}>
            매물 공유 게시판
          </NavItem>
          <NavItem as={Link} to={getUrl("postsRoot")} onClick={handleClick}>
            공지사항
          </NavItem>
        </NavItemsBox>
        {!(props.user?.subscription?.subscription_type === SubscriptionType.OwnersclubBasic) && (
          <SubscribeBox>
            <span>닥집 등기부 무한공개! 선착순 회원 모집중!</span>
            <LogoButton outlined row to={getUrl("ownersClub")} fontSize="12px" bold onClick={handleClick}>
              <LogoImg src="/images/crown.svg" />
              <span>오너스클럽 가입하기</span>
            </LogoButton>
          </SubscribeBox>
        )}
      </ContentBox>
    </NavigationBox>
  );
};

export default Navigation;
