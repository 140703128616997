import React, { useCallback, useEffect, useState } from "react";
import { CreateCorporationReportLogRequest } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddressListWithSearch from "@/components/AddressListWithSearch/AddressListWithSearch";
import { FetchState } from "@/interfaces/fetch";
import { getAddressList, getAddressListFetchState } from "@/redux/address/selectors";
import { addressListFetchThunk, corporationAddressListFetchThunk } from "@/redux/address/thunk";
import { getCreateCorporationReportLog, getCreateCorporationReportLogFetchState } from "@/redux/corporation/selectors";
import { createCorporationReportThunk } from "@/redux/corporation/thunk";
import { resetCreateBulkReportState } from "@/redux/report/actions";
import {
  getCreateBulkReport,
  getCreateBulkReportFetchState,
  getCreateReport,
  getCreateReportFetchState,
} from "@/redux/report/selectors";
import { createBulkReportThunk, createReportThunk } from "@/redux/report/thunk";
import { BulkReportRequestPayload, ReportRequestPayload } from "@/redux/report/types";
import { getUserMe } from "@/redux/user/selectors";
import { fetchUserMeThunk } from "@/redux/user/thunk";
import { getUrl } from "@/utils/routes";

type AddressListWithSearchContainerProps = {
  multiSelect?: boolean;
  listForm?: "normal" | "modal";
  withDefaultPrefix?: boolean;
  withAnimation?: boolean;
  disableBackgroundColor?: boolean;
  textFieldPlaceholder?: string;
  focusedTextFieldPlaceholder?: string;
  subButtonText?: string;
  focusedSubButtonText?: string;
};

const AddressListWithSearchContainer = ({
  multiSelect,
  listForm,
  withDefaultPrefix,
  withAnimation,
  disableBackgroundColor,
  textFieldPlaceholder,
  focusedTextFieldPlaceholder,
  subButtonText,
  focusedSubButtonText,
}: AddressListWithSearchContainerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userMe = useSelector(getUserMe);
  const addressList = useSelector(getAddressList);
  const addressListFetchState = useSelector(getAddressListFetchState);

  const createReportItem = useSelector(getCreateReport);
  const createReportFetchState = useSelector(getCreateReportFetchState);
  const createBulkReportItem = useSelector(getCreateBulkReport);
  const createBulkReportFetchState = useSelector(getCreateBulkReportFetchState);

  const createCorporationReportItem = useSelector(getCreateCorporationReportLog);
  const createCorporationReportFetchState = useSelector(getCreateCorporationReportLogFetchState);

  const [reportFetchState, setReportFetchState] = useState<FetchState>("READY");
  const [isCorporationSearch, setIsCorporationSearch] = useState(false);

  useEffect(() => {
    setReportFetchState(getReportFetchState());
  }, [createReportFetchState, createBulkReportFetchState]);

  useEffect(() => {
    if (createReportItem && createReportFetchState === "SUCCESS") {
      const { id } = createReportItem;

      dispatch(fetchUserMeThunk({}));

      navigate(getUrl("reportsDetail", { params: { reportId: id }, query: { type: "normal" } }));
    }
  }, [createReportItem, createReportFetchState]);

  useEffect(() => {
    if (createCorporationReportItem && createCorporationReportFetchState === "SUCCESS") {
      const { id } = createCorporationReportItem;

      dispatch(fetchUserMeThunk({}));

      navigate(getUrl("reportsDetail", { params: { reportId: id }, query: { type: "corporation" } }));
    }
  }, [createCorporationReportItem, createCorporationReportFetchState]);

  useEffect(() => {
    if (createBulkReportItem === 202 && createBulkReportFetchState === "SUCCESS") {
      dispatch(fetchUserMeThunk({}));

      navigate(getUrl("reportsRoot"));
      dispatch(resetCreateBulkReportState());
    }
  }, [createBulkReportItem, createBulkReportFetchState]);

  const getAddressListByKeyword = useCallback(
    (keyword: string) => {
      if (!isCorporationSearch) {
        dispatch(addressListFetchThunk({ keyword }));
        return;
      }

      dispatch(corporationAddressListFetchThunk(keyword));
    },
    [isCorporationSearch],
  );

  const createReport = (payload: ReportRequestPayload) => {
    dispatch(createReportThunk(payload));
  };

  const createCorporationReport = (payload: CreateCorporationReportLogRequest) => {
    dispatch(createCorporationReportThunk(payload));
  };

  const createBulkReport = (payload: BulkReportRequestPayload) => {
    dispatch(createBulkReportThunk(payload));
  };

  const getReportFetchState = (): FetchState => {
    let fetchState: FetchState = "READY";
    switch (true) {
      case createReportFetchState === "READY" && createBulkReportFetchState === "READY":
        fetchState = "READY";
        break;
      case createReportFetchState === "FETCHING" || createBulkReportFetchState === "FETCHING":
        fetchState = "FETCHING";
        break;
      case createReportFetchState === "FAILURE" || createBulkReportFetchState === "FAILURE":
        fetchState = "FAILURE";
        break;
      case createReportFetchState === "SUCCESS" || createBulkReportFetchState === "SUCCESS":
        fetchState = "SUCCESS";
        break;
      default:
        break;
    }
    return fetchState;
  };

  return (
    <AddressListWithSearch
      userMe={userMe}
      addressList={addressList}
      addressListFetchState={addressListFetchState}
      reportFetchState={reportFetchState}
      textFieldPlaceholder={textFieldPlaceholder}
      focusedTextFieldPlaceholder={focusedTextFieldPlaceholder}
      subButtonText={subButtonText}
      focusedSubButtonText={focusedSubButtonText}
      withDefaultPrefix={withDefaultPrefix}
      withAnimation={withAnimation}
      disableBackgroundColor={disableBackgroundColor}
      multiSelect={multiSelect}
      listForm={listForm}
      getAddressListByKeyword={getAddressListByKeyword}
      createReport={createReport}
      createBulkReport={createBulkReport}
      createCorporationReport={createCorporationReport}
      isCorporationSearch={isCorporationSearch}
      setIsCorporationSearch={setIsCorporationSearch}
    />
  );
};

export default AddressListWithSearchContainer;
