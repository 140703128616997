import React, { useCallback, useEffect, useState } from "react";
import { PostLimitedResponse } from "@doczip/api-client";
import { Alert } from "@mui/material";
import styled from "styled-components";
import CardButton from "@/components/buttons/CardButton";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import PostListItem from "@/components/posts/PostListItem";
import { FetchState } from "@/interfaces/fetch";
import { toYmd } from "@/utils/datetime";

const Container = styled.div`
  width: 100%;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShowAllButton = styled(CardButton)`
  &.MuiButtonBase-root {
    margin-top: 30px;

    font-weight: 600;
  }
`;

type Props = {
  list: PostLimitedResponse[] | null;
  fetchState: FetchState;
  page?: number | null;
  totalPage?: number | null;
  onLoadMore?: () => void;
};

const PostList: React.FC<Props> = ({ list, fetchState, page, totalPage, onLoadMore }: Props) => {
  const [prevList, setPrevList] = useState<PostLimitedResponse[] | null>();
  const [currList, setCurrList] = useState(list);

  const handleLoadMore = useCallback(() => {
    if (fetchState === "FETCHING" || !onLoadMore) {
      return;
    }

    onLoadMore();
  }, [fetchState, onLoadMore]);

  useEffect(() => {
    setCurrList((prev) => {
      setPrevList(prev);
      return list;
    });
  }, [list]);

  return (
    <Container>
      {fetchState === "SUCCESS" && list && list.length === 0 && (
        <StyledAlert severity="warning">아직 작성된 글이 없습니다.</StyledAlert>
      )}
      <InfiniteScrollList
        list={list}
        renderItem={(post) => (
          <PostListItem
            key={post.id}
            postType={post.post_type}
            topFixed={post.top_fixed}
            id={post.id}
            title={post.title}
            date={toYmd(post.created_datetime)}
            hidden={post.hidden}
          />
        )}
        fetchState={fetchState}
        page={page || null}
        totalPage={totalPage || null}
        onLoadMore={onLoadMore}
        disableIntersectListner
        noResultPlaceHolder="작성된 공지사항이 없습니다."
      />
      {list && list.length > 0 && prevList?.length !== currList?.length && (
        <ButtonContainer>
          <ShowAllButton onClick={handleLoadMore}>공지사항 더보기</ShowAllButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default PostList;
