import React, { useEffect, useState } from "react";
import Dialog from "@/components/Dialog";

type DialogProps = React.ComponentProps<typeof Dialog>;
type Props = {};

const SharedDialogContainer: React.FC<Props> = () => {
  const setterKey = `DOCZIP_SHARED_DIALOG_SETTER`;
  const storeKey = `DOCZIP_SHARED_DIALOG_STORE`;
  const [forwardProps, setForwardProps] = useState<DialogProps | null>(null);

  useEffect(() => {
    const stored = (window as any)[storeKey] as DialogProps | null | undefined;
    if (typeof stored !== "undefined") {
      setForwardProps(stored);
      (window as any)[storeKey] = void 0;
    }
    (window as any)[setterKey] = (forwardedProps: DialogProps | null) => setForwardProps(forwardedProps);

    return () => {
      (window as any)[setterKey] = void 0;
    };
  }, []);

  return forwardProps ? <Dialog {...forwardProps} /> : <></>;
};

export default SharedDialogContainer;
