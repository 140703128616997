import { logsSlice } from "./slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = logsSlice.actions;

export const {
  reset: resetLogsState,
  resetSearchTypeState,
  resetReportLogListState,
  resetReportLogTrashListState,
  resetTransactionListState,
  resetRefundState,
  setReportId,
  resetLedgers,
  resetReportActionState,
  resetIssuedReportLogListState,
  resetRequestReportPermissionState,
  setRequestReportPermissionId,
  setSearchType,
  setExtraSearchType,
  setOwnersClubDialogOpen,
  setSearchCategory,
} = actions;

export const reportLogList = mergeAsyncAction(actions, "reportLogList");

export const issuedReportLogList = mergeAsyncAction(actions, "issuedReportLogList");

export const reportLogTrashList = mergeAsyncAction(actions, "reportLogTrashList");

export const deleteReportLog = mergeAsyncAction(actions, "deleteReportLog");

export const restoreReportLog = mergeAsyncAction(actions, "restoreReportLog");

export const transactionList = mergeAsyncAction(actions, "transactionList");

export const refundableAmount = mergeAsyncAction(actions, "refundableAmount");

export const refund = mergeAsyncAction(actions, "refund");

export const registerLedger = mergeAsyncAction(actions, "registerLedger");

export const buildingLedger = mergeAsyncAction(actions, "buildingLedger");

export const landUsingInfo = mergeAsyncAction(actions, "landUsingInfo");

export const requestReportPermission = mergeAsyncAction(actions, "requestReportPermission");

export const reportLogCount = mergeAsyncAction(actions, "reportLogCount");

export const issuedReportLogCount = mergeAsyncAction(actions, "issuedReportLogCount");
