import React from "react";
import { useSelector } from "react-redux";
import DesktopLanding from "@/components/landing/desktop/DesktopLanding";
import { getRandomReport, getRandomReportFetchState } from "@/redux/report/selectors";
import { getUserMe } from "@/redux/user/selectors";

interface DesktopLandingContainerProps {}

const DesktopLandingContainer: React.FC<DesktopLandingContainerProps> = () => {
  const userMe = useSelector(getUserMe);
  const randomReport = useSelector(getRandomReport);
  const randomReportFetchState = useSelector(getRandomReportFetchState);

  return <DesktopLanding userMe={userMe} randomReport={randomReport} randomReportFetchState={randomReportFetchState} />;
};

export default DesktopLandingContainer;
