import { postsSlice } from "@/redux/posts/slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = postsSlice.actions;

export const { reset: resetLimitedPostListState, resetPostState } = actions;

export const limitedPostList = mergeAsyncAction(actions, "limitedPostList");

export const post = mergeAsyncAction(actions, "post");

export const deletePost = mergeAsyncAction(actions, "deletePost");

export const createPost = mergeAsyncAction(actions, "createPost");

export const updatePost = mergeAsyncAction(actions, "updatePost");
