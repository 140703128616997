import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "@/components/Modal";
import { getReport, getReportFetchState } from "@/redux/report/selectors";
import { saveReport, getReport as getReportIdb } from "@/utils/idb";

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 20px 0;
`;

const TextContainer = styled.div``;

const Text = styled.p`
  margin: 0;

  font-size: 16px;
  line-height: 27px;
`;

const Bold = styled(Text)`
  font-weight: 700;
  font-size: 18px;
`;

type ReportNoticeModalProps = {
  reportId?: number;
};

const ReportNoticeModal = ({ reportId }: ReportNoticeModalProps) => {
  const [open, setOpen] = useState(false);
  const [reportData, setReportData] = useState<{
    report_id: string;
    hideModal: boolean;
  }>();
  const [loading, setLoading] = useState(true);
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  const registerLedger = useMemo(() => {
    if (!report || !report?.report || !report.report?.register_ledger) {
      return;
    }

    return report.report.register_ledger;
  }, [report, reportFetchState]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);
    saveReport(String(reportId), true);
  };

  useEffect(() => {
    const fetchReports = async () => {
      if (reportId) {
        setLoading(true);
        const reportData = await getReportIdb(String(reportId));
        setReportData(reportData);
        setLoading(false);
      }
    };
    fetchReports();
  }, [reportId]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (registerLedger?.register_ledger_url) {
      return;
    }

    if (reportData && reportData.hideModal) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [reportData, loading]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleAccept={handleAccept}
      className="first-view-modal"
      withBorder
      fit
      cancelButtonText="닫기"
      acceptButtonText="다시 보지 않기"
    >
      <Container>
        <TextContainer>
          <Bold>Doczip 등기부 열람 문제</Bold>
          <Text style={{ margin: "10px 0" }}>
            과다등기부 등 등기소 이슈 또는 닥집 내부 이슈로 인해 열람에 실패했습니다. <br />
            우측 하단 메신저통해 문의 주시면 연락드리도록 하겠습니다
          </Text>
        </TextContainer>
      </Container>
    </Modal>
  );
};

export default ReportNoticeModal;
