import { AUTH_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getUserExists,
  getUserExistsFetchState,
  getLoginFetchState,
  getSignUpFetchState,
  getSelfCertification,
  getSelfCertificationFetchState,
  getResetPasswordFetchState,
} = makeSubStateSelectorMap("auth", AUTH_STATE_KEY_LIST);
