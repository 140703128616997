import React from "react";
import { AgentResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Table from "@/components/paper/Table";

type Props = {
  value: AgentResponse;
};

const ManualAgentView: React.FC<Props> = ({ value }) => {
  return (
    <>
      <Table row height={55}>
        <Box td rowSpan={3} font="label">
          개업
          <br />
          공인중개사
        </Box>
        <Box td>등록번호</Box>
        <Box td hpadding="normal" align="left">
          {value.registration_number}
        </Box>
        <Box td>
          성명 <br />
          (대표자)
        </Box>
        <Box td hpadding="normal" align="left" signHere="서명 및 날인">
          {value.agency_head_full_name}
        </Box>
      </Table>
      <Table row height={55}>
        <Box td>사무소 명칭</Box>
        <Box td hpadding="normal" align="left">
          {value.agency_name}
        </Box>
        <Box td>소속공인중개사</Box>
        <Box td hpadding="normal" align="left" signHere="서명 및 날인">
          {value.full_name}
        </Box>
      </Table>
      <Table row height={55}>
        <Box td>사무소 소재지</Box>
        <Box td hpadding="normal" align="left">
          {value.address_expr}
        </Box>
        <Box td>전화번호</Box>
        <Box td hpadding="normal" align="left">
          {value.phone_number}
        </Box>
      </Table>
    </>
  );
};

export default ManualAgentView;
