import styled from "styled-components";

const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: #dfe2e7;
`;

export default Divider;
