import React from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "@/containers/Protected";
import DashboardPage from "@/pages/DashboardPage";
import DocumentEditPage from "@/pages/DocumentEditPage";
import DocumentListPage from "@/pages/DocumentListPage";
import DocumentNewPage from "@/pages/DocumentNewPage";
import LandingPage from "@/pages/LandingPage";
import LoginPage from "@/pages/LoginPage";
import LogoutPage from "@/pages/LogoutPage";
import NotFoundPage from "@/pages/NotFoundPage";
import OwnersClubLandingPage from "@/pages/OwnersClubLandingPage";
import PostListPage from "@/pages/posts/PostListPage";
import PostShowPage from "@/pages/posts/PostShowPage";
import ReportPage from "@/pages/ReportPage";
import ResetPasswordBySelfCertificationContianer from "@/pages/ResetPasswordBySelfCertificationPage";
import ResetPasswordPage from "@/pages/ResetPasswordPage";
import SaleListPage from "@/pages/sales/SaleListPage";
import SaleShowPage from "@/pages/sales/SaleShowPage";
import SignUpPage from "@/pages/SignUpPage";
import StaticPage from "@/pages/StaticPage";
import UserEditPage from "@/pages/users/UserEditPage";
import UserIssuedReportListPage from "@/pages/users/UserIssuedReportListPage";
import UserReportListPage from "@/pages/users/UserReportListPage";
import UserReportTrashListPage from "@/pages/users/UserReportTrashListPage";
import UserShowPage from "@/pages/users/UserShowPage";
import UserSubscriptionListPage from "@/pages/users/UserSubscriptionListPage";
import UserTransactionListPage from "@/pages/users/UserTransactionListPage";
import UserTransactionNewPage from "@/pages/users/UserTransactionNewPage";
import UserVerifyIdentityPage from "@/pages/users/UserVerifyIdentityPage";
import UserVerifyRealtorPage from "@/pages/users/UserVerifyRealtorPage";

const routes = {
  root: <Route path="/" element={<LandingPage />} />,
  ownersClub: <Route path="/owners-club" element={<OwnersClubLandingPage />} />,
  dashboard: <Route path="/dashboard" element={<Protected as={DashboardPage} />} />,
  login: <Route path="/login" element={<Protected anonymousOnly as={LoginPage} />} />,
  logout: <Route path="/logout" element={<Protected as={LogoutPage} />} />,
  signup: <Route path="/signup" element={<Protected anonymousOnly as={SignUpPage} />} />,
  resetPassword: <Route path="/reset-password" element={<Protected anonymousOnly as={ResetPasswordPage} />} />,
  resetPasswordBySelfCertification: (
    <Route
      path="/reset-password-by-self-certification"
      element={<Protected anonymousOnly as={ResetPasswordBySelfCertificationContianer} />}
    />
  ),
  userRoot: <Route path="/user" element={<Protected as={UserShowPage} />} />,
  userEdit: <Route path="/user/edit" element={<Protected as={UserEditPage} />} />,
  userVerifyIdentity: <Route path="/user/verify-identity" element={<Protected as={UserVerifyIdentityPage} />} />,
  userVerifyRealtor: <Route path="/user/verify-realtor" element={<Protected as={UserVerifyRealtorPage} />} />,
  userTransactionsRoot: <Route path="/user/transactions" element={<Protected as={UserTransactionListPage} />} />,
  userTransactionsNew: <Route path="/user/transactions/new" element={<Protected as={UserTransactionNewPage} />} />,
  userSubscriptions: <Route path="/user/subscriptions" element={<Protected as={UserSubscriptionListPage} />} />,
  reportsRoot: <Route path="/reports" element={<Protected as={UserReportListPage} />} />,
  reportsTrash: <Route path="/reports/trash" element={<Protected as={UserReportTrashListPage} />} />,
  reportsIssued: <Route path="/reports/issued" element={<Protected as={UserIssuedReportListPage} />} />,
  reportsDetail: <Route path="/reports/:reportId" element={<Protected as={ReportPage} />} />,
  documentsRoot: <Route path="/documents" element={<Protected as={DocumentListPage} />} />,
  documentsNewContract: <Route path="/documents/new-contract" element={<Protected as={DocumentNewPage} />} />,
  documentsNewManual: <Route path="/documents/new-manual" element={<Protected as={DocumentNewPage} />} />,
  documentsDetailEdit: <Route path="/documents/:documentId/edit" element={<Protected as={DocumentEditPage} />} />,
  postsRoot: <Route path="/posts" element={<Protected as={PostListPage} />} />,
  postsDetail: <Route path="/posts/:postId" element={<Protected as={PostShowPage} />} />,
  salesRoot: <Route path="/sales" element={<Protected as={SaleListPage} />} />,
  salesDetail: <Route path="/sales/:saleId" element={<Protected as={SaleShowPage} />} />,
  staticDetail: <Route path="/static/:staticId" element={<StaticPage />} />,
  notFound: <Route path="*" element={<NotFoundPage />} />,
} as const;

export type Routes = typeof routes;

function makeParams<Values extends string | readonly string[], ReturnType extends { [key in keyof Routes]?: Values }>(
  map: ReturnType,
) {
  return map;
}

const routeParams = makeParams({
  reportsDetail: ["reportId", "type"],
  documentsDetailEdit: "documentId",
  postsDetail: "postId",
  salesDetail: "saleId",
  staticDetail: "staticId",
});

export type RouteParams = typeof routeParams;

declare global {
  interface Window {
    DOCZIP__ROUTES: Routes;
  }
}

window.DOCZIP__ROUTES = routes;

const AppRouter = () => {
  return <Routes>{Object.entries(routes).map(([key, route]) => React.cloneElement(route, { key }))}</Routes>;
};

export default AppRouter;
