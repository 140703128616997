import React, { useEffect, useState } from "react";
import { DocumentListResponse } from "@doczip/api-client";
import styled from "styled-components";
import { documentApi } from "@/client";
import DocumentList from "@/components/documents/DocumentList";
import TitledContainer from "@/components/TitledContainer";
import UserCommonLayout from "@/components/users/UserCommonLayout";
import { alert } from "@/utils/dialog";

const StyledTitle = styled.div`
  padding: 8px 0;
`;

const pageSize = 20;

type Props = {};

const DocumentListContainer: React.FC<Props> = () => {
  const [documents, setDocuments] = useState<DocumentListResponse[]>();
  const [deletingId, setDeletingId] = useState<number>();
  const [more, setMore] = useState(true);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);

  const fetchDocuments = async (refresh?: boolean) => {
    if (busy) {
      return;
    }
    setBusy(true);
    setError(false);
    try {
      if (refresh) {
        const response = await documentApi.getDocuments(0, pageSize, "me");
        setDocuments(response.data);
        setMore(response.data.length >= pageSize);
      } else {
        const response = await documentApi.getDocuments(documents?.length || 0, pageSize, "me");
        setDocuments([...(documents || []), ...response.data]);
        setMore(response.data.length >= pageSize);
      }
    } catch (err) {
      setError(true);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleDelete = async (documentId: number) => {
    setDeletingId(documentId);
    try {
      await documentApi.deleteDocument(documentId);
    } catch (err) {
      await alert("문서를 삭제하는데 실패했습니다.");
    } finally {
      setDeletingId(void 0);
    }
  };

  return (
    <UserCommonLayout>
      <TitledContainer title={<StyledTitle>문서함</StyledTitle>} />
      <DocumentList
        busy={busy}
        error={error}
        more={more}
        deletingId={deletingId}
        documents={documents || []}
        onLoadMore={fetchDocuments}
        onDelete={handleDelete}
      />
    </UserCommonLayout>
  );
};

export default DocumentListContainer;
