import { SaleCommentResponse } from "@doczip/api-client";
import { saleCommentApi } from "@/client";
import {
  saleComments,
  createSaleComment,
  updateSaleComment,
  deleteSaleComment,
  createSaleCommentReply,
  updateSaleCommentReply,
  deleteSaleCommentReply,
  createSaleCommentReaction,
  deleteSaleCommentReaction,
  createSaleCommentReplyReaction,
  deleteSaleCommentReplyReaction,
} from "@/redux/sale-comment/actions";
import {
  CreateSaleCommentPayload,
  CreateSaleCommentReplyPayload,
  DeleteSaleCommentPayload,
  DeleteSaleCommentReactionPayload,
  DeleteSaleCommentReplyPayload,
  DeleteSaleCommentReplyReactionPayload,
  CreateSaleCommentReactionPayload,
  CreateSaleCommentReplyReactionPayload,
  UpdateSaleCommentPayload,
  UpdateSaleCommentReplyPayload,
} from "@/redux/sale-comment/types";
import { makeFetchThunkAction } from "@/utils/redux/thunk";

export const fetchSaleCommentsThunk = makeFetchThunkAction<SaleCommentResponse[], number>(saleComments, {
  getResponseFunction: (_, saleId) => saleCommentApi.getSaleComments(saleId),
});

export const createSaleCommentThunk = makeFetchThunkAction<void, CreateSaleCommentPayload>(createSaleComment, {
  getResponseFunction: (_, { saleId, content }) => saleCommentApi.createSaleComment(saleId, { content }),
  successSnackbarMessage: "댓글이 정상적으로 등록되었습니다.",
});

export const updateSaleCommentThunk = makeFetchThunkAction<void, UpdateSaleCommentPayload>(updateSaleComment, {
  getResponseFunction: (_, { commentId, content }) => saleCommentApi.updateSaleComment(commentId, { content }),
  successSnackbarMessage: "댓글이 정상적으로 수정되었습니다.",
});

export const deleteSaleCommentThunk = makeFetchThunkAction<void, DeleteSaleCommentPayload>(deleteSaleComment, {
  getResponseFunction: (_, { commentId }) => saleCommentApi.deleteSaleComment(commentId),
  successSnackbarMessage: "댓글이 정상적으로 삭제되었습니다.",
});

export const createSaleCommentReplyThunk = makeFetchThunkAction<void, CreateSaleCommentReplyPayload>(
  createSaleCommentReply,
  {
    getResponseFunction: (_, { commentId, content }) => saleCommentApi.createSaleCommentReply(commentId, { content }),
    successSnackbarMessage: "답글이 정상적으로 등록되었습니다.",
  },
);

export const updateSaleCommentReplyThunk = makeFetchThunkAction<void, UpdateSaleCommentReplyPayload>(
  updateSaleCommentReply,
  {
    getResponseFunction: (_, { replytId, content }) => saleCommentApi.updateSaleCommentReply(replytId, { content }),
    successSnackbarMessage: "답글이 정상적으로 수정되었습니다.",
  },
);

export const deleteSaleCommentReplyThunk = makeFetchThunkAction<void, DeleteSaleCommentReplyPayload>(
  deleteSaleCommentReply,
  {
    getResponseFunction: (_, { replyId }) => saleCommentApi.deleteSaleCommentReply(replyId),
    successSnackbarMessage: "답글이 정상적으로 삭제되었습니다.",
  },
);

export const createSaleCommentReactionThunk = makeFetchThunkAction<void, CreateSaleCommentReactionPayload>(
  createSaleCommentReaction,
  {
    getResponseFunction: (_, { commentId, reactionType }) =>
      saleCommentApi.createSaleCommentReaction(commentId, { reaction_type: reactionType }),
  },
);

export const deleteSaleCommentReactionThunk = makeFetchThunkAction<void, DeleteSaleCommentReactionPayload>(
  deleteSaleCommentReaction,
  {
    getResponseFunction: (_, { commentId, reactionType }) =>
      saleCommentApi.deleteSaleCommentReaction(commentId, { reaction_type: reactionType }),
  },
);

export const createSaleCommentReplyReactionThunk = makeFetchThunkAction<void, CreateSaleCommentReplyReactionPayload>(
  createSaleCommentReplyReaction,
  {
    getResponseFunction: (_, { replyId, reactionType }) =>
      saleCommentApi.createSaleCommentReplyReaction(replyId, { reaction_type: reactionType }),
  },
);

export const deleteSaleCommentReplyReactionThunk = makeFetchThunkAction<void, DeleteSaleCommentReplyReactionPayload>(
  deleteSaleCommentReplyReaction,
  {
    getResponseFunction: (_, { replyId, reactionType }) =>
      saleCommentApi.deleteSaleCommentReplyReaction(replyId, { reaction_type: reactionType }),
  },
);
