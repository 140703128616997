import { salesSlice } from "@/redux/sales/slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = salesSlice.actions;

export const { reset: resetLimitedSaleListState, resetSaleState } = actions;

export const limitedSaleList = mergeAsyncAction(actions, "limitedSaleList");

export const sale = mergeAsyncAction(actions, "sale");

export const deleteSale = mergeAsyncAction(actions, "deleteSale");

export const createSale = mergeAsyncAction(actions, "createSale");

export const updateSale = mergeAsyncAction(actions, "updateSale");
