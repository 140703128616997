import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import SubscribeModal from "../SubscribeModal";
import { ReactComponent as CrownIcon } from "@/assets/icons/crown.svg";
import CardButton, { CardPrimaryButton } from "@/components/buttons/CardButton";
import Card from "@/components/Card";
import CardButtonContainer from "@/components/CardButtonContainer";
import useUserData from "@/hooks/useUserData";
import { colors } from "@/styles/values";
import { getUrl } from "@/utils/routes";

type StyledCardProps = {
  subscribed?: boolean;
};

const StyledCard = styled(Card)<StyledCardProps>`
  word-break: keep-all;

  border: ${(props) => (props.subscribed === true ? "none" : `3px solid ${colors.main}`)};
`;

interface UserOwnersClubCardProps {
  initialModalOpen?: boolean;
}

export default function UserOwnersClubCard({ initialModalOpen }: UserOwnersClubCardProps) {
  const { subscribed, isTanker } = useUserData();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(initialModalOpen ?? false);

  const toggleModalOpen = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, [modalOpen]);

  const onSubscriptionButtonClick = () => {
    navigate(getUrl("userSubscriptions"));
  };

  return (
    <>
      {modalOpen && <SubscribeModal open={modalOpen} onClose={toggleModalOpen} />}
      <StyledCard
        subscribed={subscribed}
        leadingIcon={<CrownIcon />}
        title={isTanker ? "오너스클럽 무료업그레이드" : "오너스클럽 정기결제"}
        actions={
          <CardButtonContainer>
            {subscribed ? (
              <>
                {isTanker && <CardPrimaryButton onClick={toggleModalOpen}>업그레이드</CardPrimaryButton>}
                <CardPrimaryButton onClick={onSubscriptionButtonClick}>결제내역</CardPrimaryButton>
              </>
            ) : (
              <>
                <CardPrimaryButton onClick={toggleModalOpen}>신청하기</CardPrimaryButton>
                <CardButton onClick={onSubscriptionButtonClick}>결제내역</CardButton>
              </>
            )}
          </CardButtonContainer>
        }
      />
    </>
  );
}
