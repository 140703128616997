export const colors = {
  main: `#ff8f17`,
  dark: `#333333`,
  white: `#ffffff`,
  darkWhite: `#fafafa`,
  darkerWhite: `#f8f8f8`,
  lighterGray: `#e2e2e2`,
  lightGray: `#aaaaaa`,
  gray: `#999999`,
  darkGray: `#666666`,
  darkerGray: `#444444`,
  black: `#111111`,
  red: `#c91116`,
  green: `#00a730`,
  paperBlue: `#00f`,
  paperGray: `#ddd`,
  data: [`#1C7CED`, `#38CFB4`, `#FF5E5E`, `#AC6AFF`, `#28BFFF`, `#B926BC`],
  chart: [
    `#3366CC`,
    `#DC3912`,
    `#FF9900`,
    `#109618`,
    `#990099`,
    `#3B3EAC`,
    `#0099C6`,
    `#DD4477`,
    `#66AA00`,
    `#B82E2E`,
    `#316395`,
    `#994499`,
    `#22AA99`,
    `#AAAA11`,
    `#6633CC`,
    `#E67300`,
    `#8B0707`,
    `#329262`,
    `#5574A6`,
    `#3B3EAC`,
  ],
};

export const lengths = {
  headerHeight: `72px`,
  mobileHeaderHeight: `64px`,
  footerHeight: `68px`,
  mobileFooterHeight: `146px`,
  sideColumnWidth: `248px`,
  narrowContainerWidth: `856px`,
  containerWidth: `1280px`,
  staticWidth: `1440px`,
  documentHeaderHeight: `72px`,
  tablet: `600px`,
  desktop: `1190px`,
  fullDesktop: "1600px",
  sm: `375px`,
};

export const lengthsInNumbers = Object.entries(lengths).reduce<{ [key in keyof typeof lengths]: number }>(
  (prev, [key, value]) => ({ [key]: Number(value.replace("px", "")), ...prev }),
  {} as any,
);

export const depths = {
  header: "800",
  headerTitle: "810",
  map: "790",
  elevator: "780",
  column: "800",
  columnComponent: "805",
  columnPanel: "810",
  openHeader: "820",
  overlayShade: "850",
  overlay: "860",
  dialog: "900",
};

export const effects = {
  boxShadow: `box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.1);`,
  boldBoxShadow: `box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.25);`,
  wideBoxShadow: `box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 20px 0 rgba(0, 0, 0, 0.1);`,
  rightBoxShadow: `box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);`,
  noBoxShadow: `box-shadow: none;`,
  transition: `transition: all 0.2s ease;`,
};

export const contentFullHeight = {
  desktop: `min-height: calc(100vh - ${lengths.headerHeight} - ${lengths.footerHeight});  min-height: calc((var(--vh, 1vh) * 100) - ${lengths.headerHeight} - ${lengths.footerHeight});`,
  mobile: `min-height: calc(100vh - ${lengths.mobileHeaderHeight} - ${lengths.mobileFooterHeight}); min-height: calc((var(--vh, 1vh) * 100) - ${lengths.mobileHeaderHeight} - ${lengths.mobileFooterHeight});`,
};
