import React, { useState } from "react";
import { SaleCommentResponse, UserResponse } from "@doczip/api-client";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import SaleComment from "@/components/sales/SaleComment";
import { BasicCommentProps, SaveCommentProps, SaveCommentReactionProps } from "@/components/sales/type";

const RepliesToggleButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  color: #4b89dc;

  background-color: inherit;
  border: none;
  cursor: pointer;
`;

const RepliesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 26px;
`;

type SaleCommentItemProps = {
  user: UserResponse | null;
  comment: SaleCommentResponse;
  onSaveComment: (props: SaveCommentProps) => void;
  onDeleteComment: (props: BasicCommentProps) => void;
  onCommentReaction: (props: SaveCommentReactionProps) => void;
};

const SaleCommentItem = ({
  user,
  comment,
  onSaveComment,
  onDeleteComment,
  onCommentReaction,
}: SaleCommentItemProps) => {
  const [showReplies, setShowReplies] = useState(false);

  return (
    <div>
      <SaleComment
        user={user}
        comment={comment}
        onSaveComment={onSaveComment}
        onDeleteComment={onDeleteComment}
        onCommentReaction={onCommentReaction}
      />
      {comment.replies && comment.replies.length > 0 && (
        <div>
          <RepliesToggleButton onClick={() => setShowReplies((prev) => !prev)}>
            {showReplies ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            답글 {comment.replies.length}개
          </RepliesToggleButton>
          {showReplies && (
            <RepliesContainer>
              {comment.replies &&
                comment.replies.map((reply) => (
                  <SaleComment
                    key={reply.id}
                    user={user}
                    comment={reply}
                    onSaveComment={onSaveComment}
                    onDeleteComment={onDeleteComment}
                    onCommentReaction={onCommentReaction}
                  />
                ))}
            </RepliesContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default SaleCommentItem;
