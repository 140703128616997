import React from "react";
import { PersonRegistrationTypeEnum, PersonResponse } from "@doczip/api-client";
import { Grid } from "@mui/material";
import EnumField from "./EnumField";
import TextField from "./TextField";

type Props = {
  value: PersonResponse;
  onChange: (people: PersonResponse) => void;
};

const PersonField: React.FC<Props> = ({ value, onChange }) => {
  const handleChange = <KT extends keyof PersonResponse>(k: KT, v: PersonResponse[KT]) => {
    onChange({
      ...value,
      [k]: v,
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <EnumField
              options={[
                ["개인", PersonRegistrationTypeEnum.Resident],
                ["법인", PersonRegistrationTypeEnum.Corporation],
                ["외국인", PersonRegistrationTypeEnum.Alien],
                ["그 외", PersonRegistrationTypeEnum.Other],
              ]}
              value={value.registration_type}
              onChange={(v) => handleChange("registration_type", v)}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <TextField
              size="small"
              label="주소"
              value={value.address_expr}
              onChange={(e) => handleChange("address_expr", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="법인/주민등록번호"
              value={value.registration_number}
              onChange={(e) => handleChange("registration_number", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="전화"
              value={value.phone_number}
              onChange={(e) => handleChange("phone_number", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="성명"
              value={value.full_name}
              onChange={(e) => handleChange("full_name", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonField;
