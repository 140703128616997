import React, { useMemo } from "react";
import {
  ReportBuildingLedger,
  ReportLandLedger,
  ReportLandUsingInfo,
  ReportRegisterLedger,
} from "@blitz-core/api-client";
import { RegisteredType, ReportLogResponse } from "@doczip/api-client";
import styled from "styled-components";
import DocumentDownloadList from "@/components/DocumentDownloadList";
import LoadingCircle from "@/components/LoadingCircle";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";
import { makeUrlList } from "@/utils/list";

const ReportDownloadDocumentContainer = styled.div``;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

interface ReportDownloadDocumentProps {
  providerId: number;
  requestedResources: ReportLogResponse["requested_resources"] | null;
  registeredType?: RegisteredType;
  registerLedger?: ReportRegisterLedger;
  buildingLedger?: ReportBuildingLedger;
  landLedger?: ReportLandLedger;
  landUsingInfo?: ReportLandUsingInfo;
  reportFetchState: FetchState;
}

const ReportDownloadDocument: React.FC<ReportDownloadDocumentProps> = ({
  providerId,
  registeredType,
  requestedResources,
  registerLedger,
  buildingLedger,
  landLedger,
  landUsingInfo,
  reportFetchState,
}) => {
  const getDocumentList = useMemo(() => {
    if (reportFetchState === "FETCHING") {
      return <LoadingCircle />;
    } else {
      return (
        <>
          {requestedResources?.includes("register-ledger") && (
            <DocumentDownloadList
              reportId={providerId}
              title="등기부등본"
              fetchState={registerLedger?.register_ledger_url ? "SUCCESS" : "FAILURE"}
              urls={makeUrlList(registerLedger?.register_ledger_url)}
              resource="registerLedger"
            />
          )}
          {requestedResources?.includes("building-ledger") && (
            <>
              {registeredType === RegisteredType.Complex && buildingLedger?.index_url && (
                <DocumentDownloadList
                  reportId={providerId}
                  title="건축물대장 총괄"
                  fetchState={buildingLedger?.index_url ? "SUCCESS" : "FAILURE"}
                  urls={makeUrlList(buildingLedger?.index_url)}
                  resource="buildingLedger"
                />
              )}
              {registeredType === RegisteredType.Complex && buildingLedger?.complex_url && (
                <DocumentDownloadList
                  reportId={providerId}
                  title="건축물대장 표제부"
                  fetchState={buildingLedger?.complex_url ? "SUCCESS" : "FAILURE"}
                  urls={makeUrlList(buildingLedger?.complex_url)}
                  resource="buildingLedger"
                />
              )}
              <DocumentDownloadList
                reportId={providerId}
                title="건축물대장"
                fetchState={buildingLedger?.primary_urls || buildingLedger?.unit_url ? "SUCCESS" : "FAILURE"}
                urls={makeUrlList(buildingLedger?.primary_urls, buildingLedger?.unit_url)}
                resource="buildingLedger"
              />
            </>
          )}
          {requestedResources?.includes("land-ledger") && (
            <DocumentDownloadList
              reportId={providerId}
              title="토지대장"
              fetchState={landLedger?.land_ledger_url ? "SUCCESS" : "FAILURE"}
              urls={makeUrlList(landLedger?.land_ledger_url)}
              resource="landLedger"
            />
          )}
          {requestedResources?.includes("land-using-info") && (
            <DocumentDownloadList
              reportId={providerId}
              title="토지이용계획"
              fetchState={landUsingInfo?.land_using_info_url ? "SUCCESS" : "FAILURE"}
              urls={makeUrlList(landUsingInfo?.land_using_info_url)}
              resource="landUsingInfo"
            />
          )}
        </>
      );
    }
  }, [reportFetchState, requestedResources]);

  return (
    <TitledWrapper
      title="공문서 열람 및 다운로드"
      titleStyle={{ size: 15, weight: 700, color: "#333333", align: "center", letterSpacing: "-0.5px", bottomGap: 22 }}
      backgroundStyle={{ color: "#ffffff", padding: "36px 0 46px" }}
    >
      <ReportDownloadDocumentContainer>
        <ButtonGroupContainer>{getDocumentList}</ButtonGroupContainer>
      </ReportDownloadDocumentContainer>
    </TitledWrapper>
  );
};

export default ReportDownloadDocument;
