import { reportSlice } from "./slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = reportSlice.actions;

export const {
  reset: resetRootReportState,
  resetKbPriceState,
  resetKabPriceState,
  resetTankerPriceState,
  resetMolitApartmentKindListingsState,
  resetCreateBulkReportState,
  setCheckedOwner,
} = actions;

export const createReportFetch = mergeAsyncAction(actions, "createReport");

export const createBulkReportFetch = mergeAsyncAction(actions, "createBulkReport");

export const createCorporationReport = mergeAsyncAction(actions, "createCorporationReport");

export const reportFetch = mergeAsyncAction(actions, "report");

export const registerLedgerFetch = mergeAsyncAction(actions, "registerLedger");

export const buildingLedgerFetch = mergeAsyncAction(actions, "buildingLedger");

export const landLedgerFetch = mergeAsyncAction(actions, "landLedger");

export const landUsingInfoFetch = mergeAsyncAction(actions, "landUsingInfo");

export const kabPriceFetch = mergeAsyncAction(actions, "kabPrice");

export const kbPriceFetch = mergeAsyncAction(actions, "kbPrice");

export const molitContractFetch = mergeAsyncAction(actions, "molitContract");

export const molitApartmentKindListingsFetch = mergeAsyncAction(actions, "molitApartmentKindListings");

export const infocareFetch = mergeAsyncAction(actions, "infocare");

export const nsdiFetch = mergeAsyncAction(actions, "nsdi");

export const tankerPriceFetch = mergeAsyncAction(actions, "tankerPrice");

export const declaredPriceFetch = mergeAsyncAction(actions, "declaredPrice");

export const randomReportFetch = mergeAsyncAction(actions, "randomReport");

export const reportReRequestFetch = mergeAsyncAction(actions, "reportReRequest");

export const memoFetch = mergeAsyncAction(actions, "memo");
