import styled from "styled-components";
import Link from "@/components/Link";

export const DescriptionContainer = styled.div`
  color: #1e2432;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
`;

export const BoldText = styled.span`
  font-weight: 700;
  font-size: 14px;
`;

export const SkipLink = styled(Link)`
  color: #797979;
  text-decoration: underline;
`;
