import React from "react";
import { ReportLogResponse } from "@doczip/api-client";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingButton from "@/components/buttons/LoadingButton";
import { FetchState } from "@/interfaces/fetch";
import { lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const ReportShowFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  @media screen and (max-width: ${lengths.tablet}) {
    flex-direction: column;
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  &.MuiLoadingButton-root {
    width: calc(50% - 7px);
    height: 48px;

    color: #ff8f17;
    font-weight: 700;
    font-size: 13px;

    background-color: #fff;
    border: 1px solid #ff8f17;

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }
  }

  @media screen and (max-width: ${lengths.tablet}) {
    &.MuiButtonBase-root {
      width: 100%;

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
`;

interface ReportShowFooterProps {
  reportId: number;
  report: ReportLogResponse | null;
  reportFetchState: FetchState;
}

const ReportShowFooter: React.FC<ReportShowFooterProps> = ({ reportId, report, reportFetchState }) => {
  const navigate = useNavigate();

  const handleRedirect = (path: string) => navigate(path);

  return (
    <ReportShowFooterContainer>
      <ButtonContainer>
        <StyledLoadingButton
          onClick={() => handleRedirect(getUrl("documentsNewContract", { query: { report: reportId } }))}
        >
          부동산 계약서 작성
        </StyledLoadingButton>
        <StyledLoadingButton
          onClick={() => handleRedirect(getUrl("documentsNewManual", { query: { report: reportId } }))}
        >
          중개대상물 확인설명서 작성
        </StyledLoadingButton>
      </ButtonContainer>
    </ReportShowFooterContainer>
  );
};

export default ReportShowFooter;
