import React from "react";
import { ButtonBase, Modal as MuiModal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { getUrl } from "@/utils/routes";

const StyledModal = styled(({ ...props }) => <MuiModal {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  min-width: 316px;
  padding: 24px;

  background-color: #fff;

  > div {
    width: 100%;
  }
`;

interface AlignProps {
  align?: string;
}

const TitleContainer = styled.div<AlignProps>`
  text-align: ${({ align }) => align};
`;

const Title = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 15px;
`;

const ButtonGroupContainer = styled.div`
  margin-top: 22px;

  > button {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;

interface ButtonProps {
  deactive?: boolean;
}

const Button = styled(ButtonBase)<ButtonProps>`
  &.MuiButtonBase-root {
    width: 100%;
    height: 39px;

    color: ${({ deactive }) => (deactive ? "#797979" : "#ff8f17")};
    font-weight: 700;
    font-size: 13px;

    background-color: ${({ deactive }) => (deactive ? "#F2F2F2" : "#ffffff")};
    border: 1px solid ${({ deactive }) => (deactive ? "transparent" : "#ff8f17")};

    transition: background-color 0.2s linear, color 0.1s linear;

    &:hover {
      color: #fff;

      background-color: #ff8f17;
    }

    ${({ deactive }) =>
      deactive &&
      css`
        &:hover {
          cursor: auto;
        }
      `}
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const CloseButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: inline-block;
    width: 100%;
    padding: 10px 0;

    color: #333;
    font-weight: 700;
    font-size: 13px;
  }
`;

interface ReportDocumentPopupProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  titleAlign?: React.CSSProperties["textAlign"];
  reportLogId: number;
}

const ReportDocumentPopup: React.FC<ReportDocumentPopupProps> = ({
  open,
  handleClose,
  title,
  titleAlign,
  reportLogId,
}) => {
  const navigate = useNavigate();
  const handleRoute = (path: string) => navigate(path);

  return (
    <StyledModal open={open}>
      <ModalContainer>
        {title && (
          <TitleContainer align={titleAlign}>
            <Title>{title}</Title>
          </TitleContainer>
        )}

        <ButtonGroupContainer>
          <Button onClick={() => handleRoute(getUrl("documentsNewContract", { query: { report: reportLogId } }))}>
            표준계약서
          </Button>
          <Button onClick={() => handleRoute(getUrl("documentsNewManual", { query: { report: reportLogId } }))}>
            중개대상물 확인설명서
          </Button>
        </ButtonGroupContainer>

        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>닫기</CloseButton>
        </CloseButtonContainer>
      </ModalContainer>
    </StyledModal>
  );
};

export default ReportDocumentPopup;
