import React from "react";
import { JeonseContractResponse, RentContractResponse, TradeContractResponse } from "@doczip/api-client";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import AmountField from "./AmountField";
import DateField from "./DateField";
import DepositsField from "./DepositsField";
import FormLabel from "./FormLabel";
import TextField from "./TextField";
import { colors } from "@/styles/values";
import { isJeonseContractBody, isRentContractBody } from "@/utils/document";

type ContractResponse = TradeContractResponse | JeonseContractResponse | RentContractResponse;
type Props<T extends ContractResponse> = {
  body: T;
  onChange: (document: T) => void;
};

const ContractForm = <T extends ContractResponse>({ body, onChange }: Props<T>) => {
  const handleChange = <KT extends keyof T>(key: KT, value: T[KT]) => {
    onChange({
      ...body,
      [key]: value,
    });
  };

  const checkStyle = { "&.Mui-checked": { color: colors.main } };

  const getJeonseRentPropertyContent = () => {
    if (isJeonseContractBody(body) || isRentContractBody(body)) {
      return (
        <>
          <FormLabel>임대</FormLabel>
          <Grid item sm={12} container alignItems="center" spacing={1}>
            <Grid item sm={6}>
              <TextField
                size="small"
                label="임대할 부분"
                value={body.portion_to_lease}
                onChange={(e) =>
                  onChange({
                    ...body,
                    portion_to_lease: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                size="small"
                label="임대 면적"
                value={body.portion_to_lease_area}
                onChange={(e) =>
                  onChange({
                    ...body,
                    portion_to_lease_area: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      );
    }
    return <></>;
  };

  const getJeonseRentContractContent = () => {
    if (isJeonseContractBody(body)) {
      return (
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <DateField
              label="임대종료일"
              value={body.return_date}
              onChange={(return_date) =>
                onChange({
                  ...body,
                  return_date,
                })
              }
            />
          </Grid>
        </Grid>
      );
    } else if (isRentContractBody(body)) {
      return (
        <>
          <Grid item sm={12} container alignItems="center" spacing={1}>
            <Grid item sm={12}>
              <DateField
                label="임대종료일"
                value={body.return_date}
                onChange={(return_date) =>
                  onChange({
                    ...body,
                    return_date,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid item sm={12} container alignItems="center" spacing={1}>
            <Grid item sm={12}>
              <TextField
                size="small"
                label="차임 지급일"
                value={body.rent_payment_day_of_month}
                onChange={(e) =>
                  onChange({
                    ...body,
                    rent_payment_day_of_month: e.target.value,
                  })
                }
                fullWidth
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
          </Grid>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <FormLabel section>금액</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <AmountField
              size="small"
              label="계약금"
              value={body.deposit_amount}
              onChange={(e) => handleChange("deposit_amount", parseInt(e.target.value, 10))}
              safeNumber
              inputProps={{ step: 10000 }}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="계약금 영수인"
              value={body.deposit_recipient}
              onChange={(e) => handleChange("deposit_recipient", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.show_deposit_receipt}
                  onChange={(e) => handleChange("show_deposit_receipt", e.target.checked)}
                />
              }
              label="날인"
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <DepositsField
              amounts={body.interim_deposit_amounts}
              dates={body.interim_deposit_dates}
              onChange={(amounts, dates) =>
                onChange({
                  ...body,
                  interim_deposit_amounts: amounts,
                  interim_deposit_dates: dates,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <AmountField
              size="small"
              label="잔금"
              value={body.balance_amount}
              onChange={(e) => handleChange("balance_amount", parseInt(e.target.value, 10))}
              safeNumber
              inputProps={{ step: 10000 }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <DateField
              label="잔금일"
              value={body.balance_payment_date}
              onChange={(v) => handleChange("balance_payment_date", v)}
            />
          </Grid>
        </Grid>

        <FormLabel section>부동산 상세 정보</FormLabel>
        <FormLabel>토지</FormLabel>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={6}>
            <TextField
              size="small"
              label="지목"
              value={body.land_category}
              onChange={(e) => handleChange("land_category", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              size="small"
              label="면적"
              value={body.land_area}
              onChange={(e) => handleChange("land_area", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <FormLabel>대지권</FormLabel>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="종류"
              value={body.right_of_use_type}
              onChange={(e) => handleChange("right_of_use_type", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="비율(분자)"
              value={body.right_of_use_ratio_numerator}
              onChange={(e) => handleChange("right_of_use_ratio_numerator", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="비율(분모)"
              value={body.right_of_use_ratio_denominator}
              onChange={(e) => handleChange("right_of_use_ratio_denominator", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <FormLabel>건물</FormLabel>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="구조"
              value={body.building_structure}
              onChange={(e) => handleChange("building_structure", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="용도"
              value={body.building_purpose}
              onChange={(e) => handleChange("building_purpose", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="면적"
              value={body.building_area}
              onChange={(e) => handleChange("building_area", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        {getJeonseRentPropertyContent()}

        <FormLabel section>계약 및 입주일</FormLabel>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <DateField
              label="계약체결일"
              value={body.execution_date}
              onChange={(v) => handleChange("execution_date", v)}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <DateField
              label="설명서 교부일"
              value={body.manual_delivery_date}
              onChange={(v) => handleChange("manual_delivery_date", v)}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <DateField label="입주일" value={body.delivery_date} onChange={(v) => handleChange("delivery_date", v)} />
          </Grid>
        </Grid>
        {getJeonseRentContractContent()}

        <FormLabel section>특약사항</FormLabel>
        <Grid item sm={12} container alignItems="center" spacing={1}>
          <Grid item sm={12}>
            <TextField
              size="small"
              label="특약사항"
              value={body.special_agreements}
              onChange={(e) => handleChange("special_agreements", e.target.value)}
              multiline
              minRows={5}
              maxRows={30}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContractForm;
