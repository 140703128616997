import React from "react";
import { Size } from "@/interfaces/style";
import { colors } from "@/styles/values";

type Props = {
  size?: Size;
  minWidth?: React.CSSProperties["minWidth"];
  align?: "left" | "right" | "center";
  color?: "default" | "lighterGray";
};

const minWidthMap: Record<NonNullable<Props["size"]>, string> = {
  tiny: "0.2em",
  small: "0.5em",
  default: "1em",
  large: "3em",
  huge: "8em",
};

const Space: React.FC<Props> = ({ size, minWidth, align, color, children }) => {
  const style: React.CSSProperties = {
    display: "inline-block",
    minWidth: minWidth || minWidthMap[size || "default"],
    textAlign: align || "inherit",
    color: {
      default: "inherit",
      lighterGray: colors.lighterGray,
    }[color || "default"],
  };
  return <span style={style}>{children}</span>;
};

export default Space;
