import React from "react";
import { PersonResponse, PersonRegistrationTypeEnum } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Table from "@/components/paper/Table";
import { getDateStringFromRegistrationNumber, getNameLabel } from "@/utils/registration";

type Props = {
  title: React.ReactNode;
  value: PersonResponse;
  bmargin?: number;
};

const ContractPersonView: React.FC<Props> = ({ title, value, bmargin }) => {
  const getIdLabel = (): React.ReactNode => {
    if (value.registration_type === PersonRegistrationTypeEnum.Corporation) {
      return "법인등록번호";
    } else if (value.registration_type === PersonRegistrationTypeEnum.Alien) {
      return "외국인등록번호";
    } else if (getDateStringFromRegistrationNumber(value.registration_number) !== null) {
      return "등록번호";
    } else {
      return "주민등록번호";
    }
  };
  return (
    <Table bmargin={bmargin} columnSizes={[110, 110, 180, 80, 180, 80, 180]}>
      <Table row height={35}>
        <Box td rowSpan={2} font="label" align="center" backgroundColor="gray">
          {title}
        </Box>
        <Box td align="center" backgroundColor="gray">
          주소
        </Box>
        <Box td colSpan={5} hpadding="small" align="left">
          {value.address_expr}
        </Box>
        <Box td rowSpan={2} font="label" align="center">
          (인)
        </Box>
      </Table>
      <Table row height={35}>
        <Box td align="center" backgroundColor="gray">
          {getIdLabel()}
        </Box>
        <Box td hpadding="small" align="left">
          {value.registration_number}
        </Box>
        <Box td align="center" backgroundColor="gray">
          전화
        </Box>
        <Box td hpadding="small" align="left">
          {value.phone_number}
        </Box>
        <Box td align="center" backgroundColor="gray">
          {getNameLabel(value.registration_type)}
        </Box>
        <Box td hpadding="small" align="left" rborder="normal">
          {value.full_name}
        </Box>
      </Table>
    </Table>
  );
};

export default ContractPersonView;
