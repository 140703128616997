import React from "react";
import styled from "styled-components";
import { colors } from "@/styles/values";

export const width = 860 + 120 + 120;
export const height = 1555.7;
export const vpadding = 78.6;
export const hpadding = 115.28;
export const innerWidth = 900;
export const innerHeight = 1260;

const PaperBox = styled.div`
  display: block;
  width: ${width}px;
  height: ${height}px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  padding: ${vpadding}px ${hpadding}px;

  background-color: ${colors.white};
  border: 0;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const PaperContentBox = styled.div`
  display: block;
  width: ${innerWidth};
  margin: 0 auto;

  font-size: 14px;
`;

const Page: React.FC = ({ children }) => {
  return (
    <PaperBox className="page-container">
      <PaperContentBox>{children}</PaperContentBox>
    </PaperBox>
  );
};

export default Page;
