import React from "react";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRouter from "@/AppRouter";
import SharedScroller from "@/components/SharedScroller";
import Snackbar from "@/components/Snackbar";
import MonitorContainer from "@/containers/MonitorContainer";
import RootContainer from "@/containers/RootContainer";
import SharedDialogContainer from "@/containers/SharedDialogContainer";
import store from "@/redux/store";
import theme from "@/styles/mui";

const App = () => {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {/* Render shared components here */}
            <SharedDialogContainer />
            <SharedScroller />
            <MonitorContainer />
            <RootContainer>
              {/* Main app router */}
              <SnackbarProvider>
                <Snackbar />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <AppRouter />
                </LocalizationProvider>
              </SnackbarProvider>
              <ToastContainer />
            </RootContainer>
          </BrowserRouter>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  );
};

export default App;
