import { PostLimitedResponse, PostRequest, PostResponse } from "@doczip/api-client";
import { postApi } from "@/client";
import { createPost, deletePost, limitedPostList, post, updatePost } from "@/redux/posts/actions";
import { LimitedPostListFetchRequestPayload, UpdatePostPayload } from "@/redux/posts/types";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

const AMOUNT_PER_PAGE = 10 as const;

export const fetchMoreLimitedPostListThunk = makeFetchMoreListThunkAction<
  PostLimitedResponse,
  LimitedPostListFetchRequestPayload
>(limitedPostList, {
  pageSelectFunction: ({ posts: { limitedPostListPage, limitedPostListTotalPage } }) => ({
    page: limitedPostListPage,
    totalPage: limitedPostListTotalPage,
  }),
  getResponseListFunction: (
    { page },
    {
      offset,
      limit,
      postTypes,
      onlyDashboardPinnedPosts,
      onlyTopFixedPosts,
      onlyMaintenanceInProgressPosts,
      onlyMaintenanceUpcomingPosts,
      includeHiddenPosts,
    },
  ) =>
    postApi.getPosts(
      offset ?? page * AMOUNT_PER_PAGE,
      limit ?? AMOUNT_PER_PAGE,
      postTypes,
      onlyDashboardPinnedPosts,
      onlyTopFixedPosts,
      onlyMaintenanceInProgressPosts,
      onlyMaintenanceUpcomingPosts,
      includeHiddenPosts,
    ),
});

export const fetchPostThunk = makeFetchThunkAction<PostResponse, number>(post, {
  getResponseFunction: (_, postId) => postApi.getPost(postId),
});

export const deletePostThunk = makeFetchThunkAction<void, number>(deletePost, {
  getResponseFunction: (_, PostId) => postApi.deletePost(PostId),
  successSnackbarMessage: "공지사항 삭제가 정상적으로 완료되었습니다.",
});

export const createPostThunk = makeFetchThunkAction<void, PostRequest>(createPost, {
  getResponseFunction: (_, Post) => postApi.createPost(Post),
  successSnackbarMessage: "공지사항이 정상적으로 등록되었습니다.",
});

export const updatePostThunk = makeFetchThunkAction<void, UpdatePostPayload>(updatePost, {
  getResponseFunction: (_, { id, postRequest }) => postApi.updatePost(id, postRequest),
  successSnackbarMessage: "공지사항이 정상적으로 업데이트되었습니다.",
});
