import React from "react";
import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@/assets/icons/chat-icon.svg";
import { lengths } from "@/styles/values";

const CarouselCardContainer = styled(ButtonBase)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;

  background-color: #fff;

  @media screen and (max-width: ${lengths.desktop}) {
    background-color: #f2f2f2;
  }
`;

const DescriptionListContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: flex-start;

  color: #333;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
`;

const DescriptionContainer = styled.div`
  a {
    color: #333;
    font-weight: 700;
  }
`;

const ChatIconImage = styled.img`
  width: 16px !important;
  height: auto;
  margin-right: 8px;
`;

const ReadMoreContainer = styled.div`
  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const ReadMore = styled.span`
  color: #797979;
  font-weight: 400;
  font-size: 13px;
`;

export interface CarouselCardProp {
  descriptions?: React.ReactNode[];
  href?: string;
  onClick?: () => void;
}

const CarouselCard: React.FC<CarouselCardProp> = ({ descriptions, href, onClick }) => {
  const navigate = useNavigate();

  const handleClick = React.useMemo(() => {
    if (onClick) {
      return onClick;
    }

    if (href) {
      return () => {
        navigate(href);
      };
    }
  }, [navigate, href, onClick]);

  return (
    <CarouselCardContainer onClick={handleClick}>
      <ChatIconImage src={ChatIcon} />
      {descriptions && descriptions.length && (
        <DescriptionListContainer>
          {descriptions.map((description, i) => (
            <DescriptionContainer key={i}>{description}</DescriptionContainer>
          ))}
        </DescriptionListContainer>
      )}
      <ReadMoreContainer>
        <ReadMore>자세히 보기 {">"}</ReadMore>
      </ReadMoreContainer>
    </CarouselCardContainer>
  );
};

export default CarouselCard;
