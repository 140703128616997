import { TradeType } from "@doczip/api-client";

export function convertTradeTypeToString(tradeType: TradeType) {
  switch (tradeType) {
    case TradeType.Deal:
      return "매매";
    case TradeType.Tnant:
      return "전세";
    case TradeType.Rent:
      return "월세";
    default:
      return tradeType;
  }
}

export function numberFormat(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberToKorean(number: number) {
  const inputNumber = number * 10000;
  const unitWords = ["", "만", "억", "조", "경"];
  const splitUnit = 10000;
  const splitCount = unitWords.length;
  const resultArray = [];
  let resultString = "";

  for (let i = 0; i < splitCount; i += 1) {
    const num1 = splitUnit ** (i + 1);
    const num2 = splitUnit ** i;
    let unitResult = (inputNumber % num1) / num2;
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i += 1) {
    if (resultArray[i]) {
      resultString = String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }
  }

  return resultString;
}

export function addSubfix(mainText: string | number | null | undefined, subfix: string, isFront?: boolean) {
  if (!mainText) {
    return null;
  }

  if (isFront) {
    return subfix + mainText;
  } else {
    return mainText + subfix;
  }
}

export function removeHTMLTagInString(text: string) {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
}

export function isEmptyObj(obj: Object) {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }

  return false;
}
