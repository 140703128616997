import React from "react";
import { useSelector } from "react-redux";
import Navigation from "@/components/Navigation";
import { getUserMe } from "@/redux/user/selectors";

type Props = {
  onClick?: () => void;
};

const NavigationContainer: React.FC<Props> = (props) => {
  const user = useSelector(getUserMe);

  return <Navigation onClick={props.onClick} user={user || void 0} />;
};

export default NavigationContainer;
