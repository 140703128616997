import React from "react";
import { useSelector } from "react-redux";
import Header from "@/components/Header";
import { getUserMe } from "@/redux/user/selectors";

type Props = {
  logo?: boolean;
  fullWidth?: boolean;
  padded?: boolean;
  mobileTitle?: React.ReactNode;
  onMobileBackClick?: () => void;
};

const HeaderContainer: React.FC<Props> = (props) => {
  const user = useSelector(getUserMe);

  return <Header {...props} user={user || void 0} />;
};

export default HeaderContainer;
