import React, { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Select, SelectChangeEvent, MenuItem } from "@mui/material";
import styled from "styled-components";
import { ExtraSearchType, SearchType } from "@/redux/logs/types";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SelectContainer = styled.div``;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    min-width: 100px;

    background-color: #fff;
    border-radius: 0;
  }
  & .MuiSelect-select {
    padding: 10px 32px 10px 10px;

    font-weight: 600;
    font-size: 14px;
  }
  & .MuiSvgIcon-root {
    color: #131313;
    font-size: 16px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: #333;
    font-weight: 400;
    font-size: 13px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const Text = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 13px;
`;

export type ExtendSearchType = SearchType | ExtraSearchType;

interface Item {
  key: ExtendSearchType;
  name: string;
}

export interface SearchTypeSelectProps {
  items: Item[];
  defaultValue?: Item["key"];
  suffix?: React.ReactNode;
  withText?: boolean;
  onTypeChange?: (searchType: ExtendSearchType) => void;
}

const SearchTypeSelect: React.FC<SearchTypeSelectProps> = ({
  items,
  defaultValue,
  suffix,
  withText = false,
  onTypeChange,
}) => {
  const [searchType, setSearchType] = useState<ExtendSearchType>(defaultValue ?? "address");

  const handleTypeChange = ({ target: { value: searchType } }: SelectChangeEvent<unknown>) => {
    setSearchType(searchType as ExtendSearchType);

    if (onTypeChange) {
      onTypeChange(searchType as ExtendSearchType);
    }
  };

  return (
    <Container>
      <SelectContainer>
        <StyledSelect IconComponent={ExpandMore} value={searchType} onChange={handleTypeChange}>
          {items.map(({ key, name }, index) => (
            <StyledMenuItem key={index} value={key}>
              {name}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </SelectContainer>
      {suffix && suffix}
      {withText && (
        <TextContainer>
          <Text>로 검색하기</Text>
        </TextContainer>
      )}
    </Container>
  );
};

export default SearchTypeSelect;
