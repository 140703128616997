import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SubscriptionResponse } from "@doczip/api-client";
import commaNumber from "comma-number";
import { addDays, isAfter } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CardButton from "./buttons/CardButton";
import Card from "./Card";
import InfiniteScrollList from "./InfiniteScrollList";
import Modal from "./Modal";
import { FetchState } from "@/interfaces/fetch";
import { resetSubscriptionRefundState } from "@/redux/subscription/actions";
import { getRefundSubscriptionFetchState } from "@/redux/subscription/selectors";
import { refundSubscriptionThunk } from "@/redux/subscription/thunk";
import { fetchUserMeThunk } from "@/redux/user/thunk";
import { toKoreanDate } from "@/utils/datetime";

const CardActionContainer = styled.div`
  display: flex;
  align-items: center;

  > div,
  > button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const Amount = styled.div`
  color: #797979;
  font-weight: 500;
  font-size: 12px;
`;

interface SubscriptionProps {
  subscription: SubscriptionResponse;
}

const ModalDescriptionContainer = styled.div`
  margin-bottom: 16px;
`;

const ModalDescription = styled.div`
  text-align: center;

  &:not(:last-child()) {
    margin-bottom: 4px;
  }
`;

export default function Subscription({
  subscription: {
    id,
    subscription_type,
    created_datetime,
    cancelled_datetime,
    refunded_datetime,
    expire_datetime,
    amount,
    ...rest
  },
}: SubscriptionProps) {
  const dispatch = useDispatch();
  const subscriptionRefundFetchState = useSelector(getRefundSubscriptionFetchState);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (subscriptionRefundFetchState !== "SUCCESS") {
      return;
    }

    dispatch(fetchUserMeThunk({}));
    dispatch(resetSubscriptionRefundState());
  }, [subscriptionRefundFetchState]);

  const title = useMemo(() => {
    let subscriptionState = "";

    if (refunded_datetime) {
      subscriptionState = " (환불됨)";
    } else if (cancelled_datetime) {
      subscriptionState = " (취소됨)";
    }

    return `정기결제${subscriptionState}`;
  }, [cancelled_datetime, refunded_datetime]);

  const refundable = useMemo(() => {
    if (
      refunded_datetime ||
      (expire_datetime && !isAfter(new Date(expire_datetime), new Date())) ||
      (cancelled_datetime && isAfter(new Date(), addDays(new Date(created_datetime), 7)))
    ) {
      return false;
    }

    return true;
  }, [cancelled_datetime, created_datetime, refunded_datetime, expire_datetime]);

  const toggleConfirmModalOpen = useCallback(() => {
    setConfirmModalOpen((open) => !open);
  }, []);

  const handleRefund = useCallback(() => {
    if (subscriptionRefundFetchState === "FETCHING") {
      return;
    }

    dispatch(refundSubscriptionThunk(id));
  }, [id, subscriptionRefundFetchState]);

  return (
    <>
      <Modal
        open={confirmModalOpen}
        handleClose={toggleConfirmModalOpen}
        acceptButtonText="환불 요청"
        handleAccept={handleRefund}
        title="정기결제 구독 환불"
        titleAlign="center"
      >
        <ModalDescriptionContainer>
          <ModalDescription>
            멤버십을 해지하시면 사용하지 않은 회원용 무료 다운로드권이 모두 사라집니다.
          </ModalDescription>
          <ModalDescription>정말 해지하시겠습니까?</ModalDescription>
        </ModalDescriptionContainer>
      </Modal>
      <Card
        title={title}
        subTitle={toKoreanDate(created_datetime, { use12HourFormat: true })}
        actions={
          !refunded_datetime && (
            <CardActionContainer>
              <Amount>{commaNumber(Math.abs(amount))}원</Amount>{" "}
              {refundable && <CardButton onClick={toggleConfirmModalOpen}>환불 요청</CardButton>}
            </CardActionContainer>
          )
        }
      />
    </>
  );
}

const SubscriptionListContainer = styled.div`
  width: 100%;

  > a {
    display: block;
  }

  > div,
  > a {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
`;

interface SubscriptionListProps {
  list: SubscriptionResponse[] | null;
  fetchState: FetchState;
  page: number | null;
  totalPage: number | null;
  onLoadMore: () => void;
}

export function SubscriptionList({ list, ...props }: SubscriptionListProps) {
  return (
    <SubscriptionListContainer>
      <InfiniteScrollList
        list={list}
        renderItem={(subscription, key) => <Subscription key={key} subscription={subscription} />}
        {...props}
        noResultPlaceHolder={<Card title="아직 결제내역이 없습니다." />}
      />
    </SubscriptionListContainer>
  );
}
