import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Search from "@/components/search/Search";

const Container = styled.div`
  width: 329px;
  padding-right: 20px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const SaleSearch = () => {
  const [, setSearchParams] = useSearchParams();

  const handleSubmitClick = (keyword: string | undefined) => {
    setSearchParams(keyword ? { q: keyword } : {});
  };

  return (
    <Container>
      <Search
        hideSearchIcon
        onSubmitClick={handleSubmitClick}
        placeholder="아파트명, 주소 등을 입력해보세요"
        boxHeight={36}
      />
    </Container>
  );
};

export default SaleSearch;
