import Configs from "../configs";
import { loadScript } from "./script";

type DefaultEventIds =
  | "add_payment_info"
  | "add_to_cart"
  | "add_to_wishlist"
  | "begin_checkout"
  | "checkout_progress"
  | "generate_lead"
  | "login"
  | "purchase"
  | "refund"
  | "remove_from_cart"
  | "search"
  | "select_content"
  | "set_checkout_option"
  | "share"
  | "sign_up"
  | "view_item"
  | "view_item_list"
  | "view_promotion"
  | "view_search_results";

type EventIds =
  | "search_address"
  | "create_report"
  | "create_bluk_report"
  | "search_issued_report"
  | "request_issued_report"
  | "create_document";

type EventParams = {
  category?: string;
  label?: string;
  value?: number;
};

export async function init() {
  if (typeof Configs.DOCZIP_GA_MEASUREMENT_ID === "undefined") {
    // Show an error message when there is no key provided.
    // eslint-disable-next-line no-console
    console.error("No GA measurement ID is provided.");
  }

  if (typeof Configs.DOCZIP_GTM_ID === "undefined") {
    // Show an error message when there is no key provided.
    // eslint-disable-next-line no-console
    console.error("No GTM ID is provided.");
  }

  // Google tag (gtag.js)
  await loadScript("gtag", `https://www.googletagmanager.com/gtag/js?id=${Configs.DOCZIP_GA_MEASUREMENT_ID}`);

  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  const rawCode = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag("js", new Date());

    gtag("config", "${Configs.DOCZIP_GA_MEASUREMENT_ID}");
  `;

  scriptElement.innerHTML = rawCode;
  document.body.appendChild(scriptElement);

  // Google Tag Manager
  const noscriptElement = document.createElement("noscript");
  const iframeElement = document.createElement("iframe");

  iframeElement.src = `https://www.googletagmanager.com/ns.html?id=${Configs.DOCZIP_GTM_ID}`;
  iframeElement.height = "0";
  iframeElement.width = "0";
  iframeElement.style.display = "none";
  iframeElement.style.visibility = "hidden";
  noscriptElement.appendChild(iframeElement);

  document.body.insertBefore(noscriptElement, document.body.firstChild);

  const gtmScriptElement = document.createElement("script");
  gtmScriptElement.type = "text/javascript";
  gtmScriptElement.text = `
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "${Configs.DOCZIP_GTM_ID}");
  `;
  document.head.appendChild(gtmScriptElement);
}

export function pageview(pathname: string) {
  const gtag = (window as any).gtag;
  if (!gtag) {
    return;
  }

  gtag("set", "page_path", pathname);
  gtag("event", "page_view");
}

export function event(eventId: DefaultEventIds | EventIds, params?: EventParams) {
  const gtag = (window as any).gtag;
  if (!gtag) {
    return;
  }

  const options = params
    ? {
        ...(params?.category ? { event_category: params.category } : {}),
        ...(params?.label ? { event_label: params.label } : {}),
        ...(params?.value ? { value: params.value } : {}),
      }
    : {};

  gtag("event", eventId, options);
}

export function setUserId(userId: number | null) {
  const gtag = (window as any).gtag;
  if (!gtag) {
    return;
  }

  gtag("config", "GA_MEASUREMENT_ID", {
    user_id: userId?.toString(),
  });
}
