import React, { useMemo } from "react";
import { ReportRegisterLedger } from "@blitz-core/api-client";
import comma from "comma-number";
import styled from "styled-components";
import { getLoanTotal } from "./util";
import LoadingCircle from "@/components/LoadingCircle";
import TitledWrapper from "@/components/TitledWrapper";
import { FetchState } from "@/interfaces/fetch";

const ReportLoanInfoContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const LoanInfoContent = styled.div``;

const LoanInfoTextList = styled.ul``;

const LoanInfoTextItem = styled.li``;

const LoanInfoText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;
`;

interface ReportLoanInfoProps {
  registerLedger?: ReportRegisterLedger;
  reportFetchState: FetchState;
}

const ReportLoanInfo: React.FC<ReportLoanInfoProps> = ({ registerLedger, reportFetchState }) => {
  const loanTotal = useMemo(() => {
    if (!registerLedger || !registerLedger?.mortgage_summaries) {
      return;
    }

    return getLoanTotal(registerLedger?.mortgage_summaries);
  }, [registerLedger]);

  const loanInfo = useMemo(() => {
    if (!loanTotal || reportFetchState === "FAILURE") {
      return ["🏛 대출 정보가 없습니다"];
    }

    const { tnantCount, tnantTotal, mortageCount, mortageTotal } = loanTotal;

    const loanInfoList = [
      ...(tnantCount > 0 ? [`🏛 전세권설정 ${tnantCount}건`, <>📑 전세금 총액 {comma(tnantTotal)}원</>] : []),
      ...(mortageCount > 0
        ? [`🏛 근저당권설정 ${mortageCount}건`, <>📑 채권최고액 총액 {comma(mortageTotal)}원</>]
        : []),
    ];

    return loanInfoList.length > 0 ? loanInfoList : [`🏛 대출 기록이 없습니다.`];
  }, [loanTotal, reportFetchState]);

  return (
    <TitledWrapper
      title="대출 정보"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
    >
      <ReportLoanInfoContainer>
        {["SUCCESS", "FAILURE"].includes(reportFetchState) ? (
          <LoanInfoContent>
            <LoanInfoTextList>
              {loanInfo.map((value, index) => (
                <LoanInfoTextItem key={index}>
                  <LoanInfoText>{value}</LoanInfoText>
                </LoanInfoTextItem>
              ))}
            </LoanInfoTextList>
          </LoanInfoContent>
        ) : (
          <LoadingCircle />
        )}
      </ReportLoanInfoContainer>
    </TitledWrapper>
  );
};

export default ReportLoanInfo;
