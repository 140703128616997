import React from "react";
import IssuedReportSearch from "@/components/reports/issued/IssuedReportSearch";
import { TextFieldContainer } from "@/components/users/logs/userIssuedReportListLayout";

interface IssuedReportListSearchFieldProps {}

const IssuedReportListSearchField: React.FC<IssuedReportListSearchFieldProps> = () => {
  return (
    <TextFieldContainer>
      <IssuedReportSearch />
    </TextFieldContainer>
  );
};

export default IssuedReportListSearchField;
