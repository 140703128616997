import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingCircle from "@/components/LoadingCircle";
import ReportShowFooter from "@/components/reports/ReportShowFooter";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportShowFooterContainerProps {}

const ReportShowFooterContainer: React.FC<ReportShowFooterContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  const reportId = useMemo(() => {
    if (!report || !report?.id) {
      return;
    }

    return report.id;
  }, [report, reportFetchState]);

  if (!reportId) {
    return <LoadingCircle />;
  }

  return <ReportShowFooter reportId={reportId} report={report} reportFetchState={reportFetchState} />;
};

export default ReportShowFooterContainer;
