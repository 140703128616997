import { SALE_COMMENT_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
  getSaleComments,
  getSaleCommentsFetchState,

  getCreateSaleCommentFetchState,
  getUpdateSaleCommentFetchState,
  getDeleteSaleCommentFetchState,

  getCreateSaleCommentReplyFetchState,
  getUpdateSaleCommentReplyFetchState,
  getDeleteSaleCommentReplyFetchState,

  getCreateSaleCommentReactionFetchState,
  getDeleteSaleCommentReactionFetchState,

  getCreateSaleCommentReplyReactionFetchState,
  getDeleteSaleCommentReplyReactionFetchState,
} = makeSubStateSelectorMap("saleComment", SALE_COMMENT_STATE_KEY_LIST);
