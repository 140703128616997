export function init(setter: any, id: string) {
  (window as any)[`DOCZIP__SETTER_${id}`] = setter;
  const stored = (window as any)[`DOCZIP__STORE_${id}`];
  (window as any)[`DOCZIP__STORE_${id}`] = void 0;
  return stored;
}

export function set(item: any, id: string) {
  if ((window as any)[`DOCZIP__SETTER_${id}`]) {
    (window as any)[`DOCZIP__SETTER_${id}`](item);
    (window as any)[`DOCZIP__STORE_${id}`] = void 0;
  } else {
    (window as any)[`DOCZIP__STORE_${id}`] = item;
  }
}

export function clear(id: string) {
  (window as any)[`DOCZIP__SETTER_${id}`] = void 0;
  (window as any)[`DOCZIP__STORE_${id}`] = void 0;
}
