import React from "react";
import { DocumentResponse, ResidentialManualResponse } from "@doczip/api-client";
import Box from "@/components/paper/Box";
import Table from "@/components/paper/Table";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  document: DocumentResponse;
  body: T;
};

const LegalView = <T extends ManualResponse>({ document, body }: Props<T>) => {
  return (
    <Table bmargin={10}>
      <Table row>
        <Box td font="label" bborder="none" align="left">
          ⑨ 실제 권리관계 또는 공시되지 않은 물건의 권리 사항
        </Box>
      </Table>
      <Table row height={130}>
        <Box td align="left">
          {body.undeclared_rights}
        </Box>
      </Table>
    </Table>
  );
};

export default LegalView;
