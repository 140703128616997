import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ListFetchSuccessPayload, ListRequestSuccessPayload } from "./types";
import { AMOUNT_PER_PAGE } from "@/interfaces/pages";
import { openSuccessSnackbar } from "@/redux/snackbar/actions";
import { RootState } from "@/redux/types";
import { handleAxiosError } from "@/redux/util";

const DEFAULT_POLLING_DELAY = 3_000;

interface CommonAsyncAction {
  fetchRequest: ActionCreatorWithoutPayload<string>;
  fetchFailure: ActionCreatorWithPayload<Error, string>;
}

type PayloadValidationFunction<Payload> = (payload: Payload) => boolean;

interface CommonMakeThunkActionProps<PayloadType> {
  payloadValidationFunction?: PayloadValidationFunction<PayloadType>;
  successSnackbarMessage?: string;
  getSuccessSnackbarMessage?: (props: { state: RootState; payload: PayloadType }) => string;
  useErrorSnackbar?: boolean;
  errorSnackbarMessage?: string;
  getErrorSnackbarMessage?: (props: { state: RootState; payload: PayloadType; error: unknown }) => string;
  useApiPolling?: boolean;
  getKeepPollingFunction?: (state: RootState, payload: PayloadType) => boolean;
  minPollingInterval?: number;
}

export type GenericThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

async function makeApiCallAndFetchActions<PayloadType, ResponseType, ActionResponseType>({
  successSnackbarMessage,
  useErrorSnackbar,
  errorSnackbarMessage,
  getErrorSnackbarMessage,
  useApiPolling,
  getSuccessSnackbarMessage,
  handleApiCall,
  getKeepPollingFunction,
  getDataFromApiResponse,
  actions: { fetchSuccess, fetchFailure },
  payload,
  dispatch,
  getState,
  minPollingInterval,
}: Pick<
  CommonMakeThunkActionProps<PayloadType>,
  | "successSnackbarMessage"
  | "getSuccessSnackbarMessage"
  | "useErrorSnackbar"
  | "errorSnackbarMessage"
  | "getErrorSnackbarMessage"
  | "useApiPolling"
  | "getKeepPollingFunction"
  | "minPollingInterval"
> & {
  handleApiCall: () => Promise<AxiosResponse<ResponseType>>;
  getDataFromApiResponse: (apiResponse: AxiosResponse<ResponseType>) => ActionResponseType;
  actions: {
    fetchSuccess: ActionCreatorWithPayload<ActionResponseType, string>;
    fetchFailure: ActionCreatorWithPayload<Error, string>;
  };
  payload: PayloadType;
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
  getState: () => RootState;
}) {
  try {
    const handleCheckKeepPolling = (state: RootState) =>
      getKeepPollingFunction ? getKeepPollingFunction(state, payload!) : true;

    const response = await (useApiPolling
      ? getApiPollingResponse({
          getResponseFunction: handleApiCall,
          getKeepPollingFunction: handleCheckKeepPolling,
          getState,
          minPollingInterval,
        })
      : getApiResponse(handleApiCall));

    dispatch(fetchSuccess(getDataFromApiResponse(response)));

    if (typeof getSuccessSnackbarMessage !== "undefined" || typeof successSnackbarMessage !== "undefined") {
      const successMessage = getSuccessSnackbarMessage
        ? getSuccessSnackbarMessage({ state: getState(), payload })
        : successSnackbarMessage;

      dispatch(openSuccessSnackbar(successMessage!));
    }
  } catch (error: unknown) {
    const errorMessage = getErrorSnackbarMessage
      ? getErrorSnackbarMessage({ state: getState(), payload, error })
      : errorSnackbarMessage;

    handleAxiosError(error, dispatch, fetchFailure, { useErrorSnackbar, errorSnackbarMessage: errorMessage });
  }
}

type ResponseTypeWithPendingData<ResponseType> = ResponseType & {
  is_pending?: boolean;
};

async function getApiPollingResponse<ResponseType>({
  minPollingInterval = DEFAULT_POLLING_DELAY,
  getResponseFunction,
  getKeepPollingFunction,
  getState,
}: {
  minPollingInterval?: number;
  getResponseFunction: () => Promise<AxiosResponse<ResponseType>>;
  getKeepPollingFunction: (state: RootState) => boolean;
  getState: () => RootState;
}) {
  const pollingPage = window.location.pathname;
  let pollResponse;
  let pollFinishied = false;

  while (!pollFinishied || !pollResponse) {
    const poll = getResponseFunction();

    const pollDealy = new Promise((resolve) => setTimeout(resolve, minPollingInterval));

    pollResponse = await poll;

    const isPolling =
      pollResponse.status === 204 ||
      (typeof pollResponse.data === "object" &&
        (pollResponse.data as ResponseTypeWithPendingData<ResponseType>).is_pending);

    if (isPolling && getKeepPollingFunction(getState()) && window.location.pathname === pollingPage) {
      await pollDealy;
    } else {
      pollFinishied = true;
      break;
    }
  }

  return pollResponse;
}

async function getApiResponse<ResponseType>(getResponseFunction: () => Promise<AxiosResponse<ResponseType>>) {
  const reqPromise = getResponseFunction();

  const minimumDelay = new Promise((resolve) => setTimeout(resolve, 300));

  const [reqResponse] = await Promise.all([reqPromise, minimumDelay]);

  return reqResponse;
}

export type GetListResponseFromRestApiClientFunction<ResponseType, PayloadType> = (
  functionArguments: {
    state: RootState;
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
    page: number;
  },
  payload: PayloadType,
) => Promise<AxiosResponse<ResponseType[]>>;

interface MakeFetchMoreListThunkActionProps<ResponseType, PayloadType> extends CommonMakeThunkActionProps<PayloadType> {
  pageSelectFunction: (rootState: RootState) => { page: number | null; totalPage: number | null };
  getResponseListFunction: GetListResponseFromRestApiClientFunction<ResponseType, PayloadType>;
}

interface ListAsyncAction<ResponseType> extends CommonAsyncAction {
  fetchSuccess: ActionCreatorWithPayload<ListFetchSuccessPayload<ResponseType>, string>;
}

export function makeFetchMoreListThunkAction<ResponseType, PayloadType>(
  { fetchRequest, ...actions }: ListAsyncAction<ResponseType>,
  {
    payloadValidationFunction,
    pageSelectFunction,
    getResponseListFunction,
    ...props
  }: MakeFetchMoreListThunkActionProps<ResponseType, PayloadType>,
): (payload: PayloadType) => GenericThunkAction {
  return (payload) => async (dispatch, getState) => {
    if (payloadValidationFunction && !payloadValidationFunction(payload)) {
      return;
    }

    const rootState = getState();

    const { page, totalPage } = pageSelectFunction(rootState);
    const currentPage = page ?? 0;

    if (totalPage && totalPage + 1 < currentPage) {
      return;
    }

    dispatch(fetchRequest());

    const handleApiCall = () =>
      getResponseListFunction(
        {
          state: getState(),
          dispatch,
          page: currentPage,
        },
        payload!,
      );

    const getDataFromApiResponse = ({ headers, data }: AxiosResponse<ResponseType[]>) => {
      const totalCount = headers["x-total-count"];

      return {
        list: data,
        totalPage: Math.ceil(totalCount / AMOUNT_PER_PAGE),
        nextPage: currentPage + 1,
        totalCount,
      };
    };

    await makeApiCallAndFetchActions<PayloadType, ResponseType[], ListFetchSuccessPayload<ResponseType>>({
      actions,
      payload,
      dispatch,
      getState,
      handleApiCall,
      getDataFromApiResponse,
      ...props,
    });
  };
}

export type GetResponseFromRestApiClientFunction<ResponseType, PayloadType> = (
  functionArguments: {
    state: RootState;
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
  },
  payload: PayloadType,
) => Promise<AxiosResponse<ResponseType>>;

interface MakeFetchThunkActionProps<ResponseType, PayloadType> extends CommonMakeThunkActionProps<PayloadType> {
  getResponseFunction: GetResponseFromRestApiClientFunction<ResponseType, PayloadType>;
}

interface AsyncAction<ResponseType> extends CommonAsyncAction {
  fetchSuccess: ActionCreatorWithPayload<ResponseType, string>;
}

export function makeFetchThunkAction<ResponseType, PayloadType>(
  { fetchRequest, ...actions }: AsyncAction<ResponseType>,
  { payloadValidationFunction, getResponseFunction, ...props }: MakeFetchThunkActionProps<ResponseType, PayloadType>,
): (payload: PayloadType) => GenericThunkAction {
  return (payload) => async (dispatch, getState) => {
    if (payloadValidationFunction && !payloadValidationFunction(payload)) {
      return;
    }

    dispatch(fetchRequest());

    const handleApiCall = () =>
      getResponseFunction(
        {
          state: getState(),
          dispatch,
        },
        payload,
      );

    const getDataFromApiResponse = ({ data }: AxiosResponse<ResponseType>) => data;

    await makeApiCallAndFetchActions<PayloadType, ResponseType, ResponseType>({
      actions,
      payload,
      dispatch,
      getState,
      handleApiCall,
      getDataFromApiResponse,
      ...props,
    });
  };
}

export function getListRequestSuccessPayloadFromResponse<ItemType>(
  { data, headers }: AxiosResponse<ItemType[]>,
  page: number,
): ListRequestSuccessPayload<ItemType> {
  return {
    list: data,
    page: page + 1,
    totalPage: headers["x-total-count"],
  };
}
