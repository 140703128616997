import React, { useState } from "react";
import { SaleImageSetResponse } from "@doczip/api-client";
import styled from "styled-components";
import ImageSlideModal from "@/components/modal/ImageSlideModal";
import Slide from "@/components/slide/Slide";
import Configs from "@/configs";
import { lengths } from "@/styles/values";

const ImageSetsContainer = styled.p`
  display: flex;
  gap: 19px;

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 120px;

  border-radius: 10px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
    max-width: 480px;
    height: auto;
  }
  cursor: pointer;
`;

type ImageSlideProps = {
  imageSet: SaleImageSetResponse[];
};

const SaleImageSlide = ({ imageSet }: ImageSlideProps) => {
  const [imageNum, setImageNum] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);

  const imageSources = imageSet.map((image) => Configs.DOCZIP_REST_API_URL + image.original_image_path);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = async (imageNum: number) => {
    setImageNum(imageNum);
    setModalOpen(true);
  };

  return (
    <Slide>
      <ImageSetsContainer>
        {modalOpen && (
          <ImageSlideModal
            open={modalOpen}
            handleClose={handleModalClose}
            sources={imageSources}
            defaultSourceNum={imageNum}
          />
        )}
        {imageSet.map((image: SaleImageSetResponse, index) => (
          <Image
            key={image.id}
            src={Configs.DOCZIP_REST_API_URL + image.thumbnail_image_path}
            onClick={() => handleModalOpen(index)}
          />
        ))}
      </ImageSetsContainer>
    </Slide>
  );
};

export default SaleImageSlide;
