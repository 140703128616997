import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "core-js/features/string/repeat";
import "regenerator-runtime/runtime";
import "abortcontroller-polyfill";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom";
import App from "./App";
import Configs from "./configs";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: Configs.DOCZIP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
