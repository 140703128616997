import styled, { css } from "styled-components";
import FlatButton from "./FlatButton";
import PrimaryButton from "./PrimaryButton";

const CardButton = styled(FlatButton)<{ width?: string }>`
  &.MuiButtonBase-root {
    min-width: 60px;
    height: 36px;
    padding-right: 12px;
    padding-left: 12px;

    color: #797979;
    font-weight: 500;
    font-size: 12px;

    ${({ width }) =>
      css`
        width: ${width};
      `}

    background-color: #f2f2f2;
  }
`;

export const CardPrimaryButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    min-width: 60px;
    height: 36px;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export default CardButton;
