import { ReportRegisterLedger, ReportBuildingLedger, ReportLandUsingInfo } from "@blitz-core/api-client";
import {
  RefundableAmountResponse,
  ReportLogResponse,
  ReportLogLimitedResponse,
  TransactionResponse,
  ReportLogCountResponse,
  CountResponse,
} from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { LogsState } from "./types";
import {
  makeAsyncStateResetReducer,
  makeTypedAsyncReducer,
  makeStateResetReducer,
  setValueReducerList,
} from "@/utils/redux/reducer";
import { getTypedAsyncInitialState, getTypedAsyncListInitialState, getKeyListFromState } from "@/utils/redux/state";
import { ListFetchSuccessPayload, WithString } from "@/utils/redux/types";

const getAsyncInitialState = getTypedAsyncInitialState<LogsState>();
const getAsyncListInitialState = getTypedAsyncListInitialState<LogsState>();
const makeAsyncReducer = makeTypedAsyncReducer<LogsState>();

const initialState: LogsState = {
  ...getAsyncListInitialState("reportLogList"),
  ...getAsyncListInitialState("issuedReportLogList"),
  ...getAsyncListInitialState("reportLogTrashList"),
  ...getAsyncListInitialState("transactionList"),
  ...getAsyncInitialState("refundableAmount"),
  ...getAsyncInitialState("registerLedger"),
  ...getAsyncInitialState("buildingLedger"),
  ...getAsyncInitialState("landUsingInfo"),
  ...getAsyncInitialState("reportLogCount"),
  ...getAsyncInitialState("issuedReportLogCount"),
  deleteReportLogFetchState: "READY",
  restoreReportLogFetchState: "READY",
  refundFetchState: "READY",
  requestReportPermissionFetchState: "READY",
  requestReportPermissionId: null,
  reportId: null,
  searchType: "address",
  extraSearchType: "individual",
  ownersClubDialogOpen: false,
  searchCategory: "normal",
};

export const LOGS_STATE_KEY_LIST = getKeyListFromState(initialState);

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    reset: () => initialState,
    ...setValueReducerList(
      [
        "reportId",
        "requestReportPermissionId",
        "searchType",
        "extraSearchType",
        "ownersClubDialogOpen",
        "searchCategory",
      ],
      initialState,
    ),
    resetSearchTypeState: makeStateResetReducer(initialState, "searchType"),
    ...makeAsyncReducer<"reportLogList", void, ListFetchSuccessPayload<ReportLogResponse>>("reportLogList", {
      useListSuccessReducer: true,
    }),
    resetReportLogListState: makeAsyncStateResetReducer(initialState, "reportLogList", { isList: true }),
    ...makeAsyncReducer<"issuedReportLogList", void, ListFetchSuccessPayload<ReportLogLimitedResponse>>(
      "issuedReportLogList",
      {
        useListSuccessReducer: true,
      },
    ),
    resetRequestReportPermissionState: makeStateResetReducer(initialState, [
      "requestReportPermissionId",
      "requestReportPermissionFetchState",
    ]),
    ...makeAsyncReducer("requestReportPermission"),
    resetIssuedReportLogListState: makeAsyncStateResetReducer(initialState, "issuedReportLogList", {
      isList: true,
    }),
    ...makeAsyncReducer<"reportLogTrashList", void, ListFetchSuccessPayload<ReportLogResponse>>("reportLogTrashList", {
      useListSuccessReducer: true,
    }),

    resetReportLogTrashListState: makeAsyncStateResetReducer(initialState, "reportLogTrashList", {
      isList: true,
    }),
    ...makeAsyncReducer("deleteReportLog"),
    ...makeAsyncReducer("restoreReportLog"),
    ...makeAsyncReducer<"transactionList", void, ListFetchSuccessPayload<TransactionResponse>>("transactionList", {
      useListSuccessReducer: true,
    }),
    resetReportActionState: makeStateResetReducer(initialState, [
      "deleteReportLogFetchState",
      "restoreReportLogFetchState",
    ]),
    resetTransactionListState: makeAsyncStateResetReducer(initialState, "transactionList", { isList: true }),
    ...makeAsyncReducer<"refundableAmount", void, RefundableAmountResponse>("refundableAmount"),
    ...makeAsyncReducer("refund"),
    resetRefundState: makeAsyncStateResetReducer(initialState, "refundableAmount", {
      extraStateKey: "refundFetchState",
    }),
    ...makeAsyncReducer<"registerLedger", void, WithString<ReportRegisterLedger>>("registerLedger"),
    ...makeAsyncReducer<"buildingLedger", void, WithString<ReportBuildingLedger>>("buildingLedger"),
    ...makeAsyncReducer<"landUsingInfo", void, WithString<ReportLandUsingInfo>>("landUsingInfo"),
    resetLedgers: makeAsyncStateResetReducer(initialState, ["registerLedger", "buildingLedger", "landUsingInfo"]),
    ...makeAsyncReducer<"reportLogCount", void, ReportLogCountResponse>("reportLogCount"),
    ...makeAsyncReducer<"issuedReportLogCount", void, CountResponse>("issuedReportLogCount"),
  },
});

export const logsReducer = logsSlice.reducer;
