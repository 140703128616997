import React from "react";
import {
  HeatingFuelTypeEnum,
  ManagementTypeEnum,
  ResidentialManualResponse,
  TernaryConditionEnum,
} from "@doczip/api-client";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import AmountField from "./AmountField";
import DateField from "./DateField";
import EnumField from "./EnumField";
import FormLabel from "./FormLabel";
import InfraField from "./InfraField";
import TernaryField from "./TernaryField";
import TextField from "./TextField";
import { colors } from "@/styles/values";

type ManualResponse = ResidentialManualResponse;
type Props<T extends ManualResponse> = {
  body: T;
  onChange: (document: T) => void;
};

const ManualForm = <T extends ManualResponse>({ body, onChange }: Props<T>) => {
  const handleChange = <KT extends keyof T, DKT extends keyof T>(
    key: KT,
    value: T[KT],
    detailKey?: DKT,
    detailValue?: T[DKT],
  ) => {
    onChange({
      ...body,
      [key]: value,
      ...(detailKey && typeof detailValue !== "undefined" ? { [detailKey]: detailValue } : {}),
    });
  };

  const checkStyle = { "&.Mui-checked": { color: colors.main } };

  return (
    <>
      <FormLabel section>거래 유형</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <EnumField
              label="매매 여부"
              options={[
                ["매매·교환", true],
                ["임대", false],
              ]}
              value={body.is_trade}
              onChange={(v, d) => handleChange("is_trade", v)}
            />
          </Grid>
          <Grid item sm={6}>
            <EnumField
              label="공동주택 여부"
              options={[
                ["공동주택", true],
                ["단독주택", false],
              ]}
              value={body.is_complex_housing}
              onChange={(v, d) => handleChange("is_complex_housing", v)}
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel section>확인설명 자료</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_title_deed}
                onChange={(e) => handleChange("is_material_title_deed", e.target.checked)}
              />
            }
            label="등기권리증"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_registration}
                onChange={(e) => handleChange("is_material_registration", e.target.checked)}
              />
            }
            label="등기사항증명서"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_land_ledger}
                onChange={(e) => handleChange("is_material_land_ledger", e.target.checked)}
              />
            }
            label="토지대장"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_building_ledger}
                onChange={(e) => handleChange("is_material_building_ledger", e.target.checked)}
              />
            }
            label="건축물대장"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_cadastral_map}
                onChange={(e) => handleChange("is_material_cadastral_map", e.target.checked)}
              />
            }
            label="지적도"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_forest_and_field_map}
                onChange={(e) => handleChange("is_material_forest_and_field_map", e.target.checked)}
              />
            }
            label="임야도"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_land_use_plan}
                onChange={(e) => handleChange("is_material_land_use_plan", e.target.checked)}
              />
            }
            label="토지이용계획확인서"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_material_others}
                onChange={(e) => handleChange("is_material_others", e.target.checked)}
              />
            }
            label="그밖의 자료"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          {body.is_material_others ? (
            <Grid item sm={12}>
              <TextField
                size="small"
                label="확인설명 자료 세부내용"
                value={body.material_others_detail}
                onChange={(e) => handleChange("material_others_detail", e.target.value)}
                fullWidth
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item sm={12}>
            <TextField
              size="small"
              label="대상물건 상태 자료요구사항"
              value={body.request_for_verification}
              onChange={(e) => handleChange("request_for_verification", e.target.value)}
              multiline
              minRows={3}
              maxRows={5}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel section>대상 물건</FormLabel>
      <FormLabel>토지</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="면적"
              value={body.land_area}
              onChange={(e) => handleChange("land_area", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="지목 (공부상)"
              value={body.land_category}
              onChange={(e) => handleChange("land_category", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="지목 (실제이용 상태)"
              value={body.land_category_actual}
              onChange={(e) => handleChange("land_category_actual", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel>건축물</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              type="number"
              size="small"
              label="전용면적"
              value={body.building_exclusive_area}
              onChange={(e) => handleChange("building_exclusive_area", parseFloat(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="number"
              size="small"
              label="대지 지분"
              value={body.building_land_share}
              onChange={(e) => handleChange("building_land_share", parseFloat(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="준공년도"
              value={body.building_completion_year}
              onChange={(e) => handleChange("building_completion_year", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="구조"
              value={body.building_structure}
              onChange={(e) => handleChange("building_structure", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="용도 (대장상)"
              value={body.building_purpose}
              onChange={(e) => handleChange("building_purpose", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="용도 (실제)"
              value={body.building_purpose_actual}
              onChange={(e) => handleChange("building_purpose_actual", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="방향"
              value={body.building_direction}
              onChange={(e) => handleChange("building_direction", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_building_seismic}
                  onChange={(e) => handleChange("is_building_seismic", e.target.checked)}
                />
              }
              label="내진설계"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_building_illegal}
                  onChange={(e) => handleChange("is_building_illegal", e.target.checked)}
                />
              }
              label="위반건축물"
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="방향 기준"
              value={body.building_direction_base}
              onChange={(e) => handleChange("building_direction_base", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="내진능력"
              value={body.building_seismic_capacity}
              onChange={(e) => handleChange("building_seismic_capacity", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              label="위반건축물 내용"
              value={body.building_illegal_detail}
              onChange={(e) => handleChange("building_illegal_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel section>소유권</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="소유권 (토지)"
            value={body.land_ownership}
            onChange={(e) => handleChange("land_ownership", e.target.value)}
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="소유권 (건축물)"
            value={body.building_ownership}
            onChange={(e) => handleChange("building_ownership", e.target.value)}
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="소유권 외 권리사항 (토지)"
            value={body.land_other_rights}
            onChange={(e) => handleChange("land_other_rights", e.target.value)}
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="소유권 외 권리사항 (건축물)"
            value={body.building_other_rights}
            onChange={(e) => handleChange("building_other_rights", e.target.value)}
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
          />
        </Grid>
      </Grid>
      <FormLabel section>권리관계</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <EnumField
              label="민간임대등록여부"
              options={[
                ["해당 없음", ManagementTypeEnum.NotAvailable],
                ["장기일반민간임대주택", ManagementTypeEnum.Private],
                ["공공지원민간임대주택", ManagementTypeEnum.Public],
                ["그 외", ManagementTypeEnum.Others],
              ]}
              value={body.registered_rent_type}
              detail={body.registered_rent_type_detail}
              onChange={(v, d) => handleChange("registered_rent_type", v, "registered_rent_type_detail", d)}
            />
          </Grid>
        </Grid>
        {body.registered_rent_type !== ManagementTypeEnum.NotAvailable ? (
          <Grid item sm={12} container spacing={1}>
            <Grid item sm={6}>
              <TextField
                size="small"
                label="임대의무기간"
                value={body.registered_rent_mandatory_period}
                onChange={(e) => handleChange("registered_rent_mandatory_period", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <DateField
                label="임대개시일"
                value={body.registered_rent_start_date}
                onChange={(v) => handleChange("registered_rent_start_date", v)}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <EnumField
              label="계약갱신 청구권 행사 여부"
              options={[
                ["확인", true],
                ["미확인", false],
                ["해당 없음", void 0],
              ]}
              value={body.is_rent_renewed}
              onChange={(v) => handleChange("is_rent_renewed", v)}
            />
          </Grid>
          <Grid item sm={12}>
            <EnumField
              label="다가구주택 확인서류 제출 여부"
              options={[
                ["확인", true],
                ["미확인", false],
                ["해당 없음", void 0],
              ]}
              value={body.is_multi_family_housing}
              onChange={(v) => handleChange("is_multi_family_housing", v)}
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel section>토지이용계획</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="용도지역"
            value={body.use_area_plan}
            onChange={(e) => handleChange("use_area_plan", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="용도지구"
            value={body.use_district_plan}
            onChange={(e) => handleChange("use_district_plan", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="용도구역"
            value={body.use_zone_plan}
            onChange={(e) => handleChange("use_zone_plan", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <TextField
              size="small"
              label="도시.군계획 시설"
              value={body.city_gun_plan}
              onChange={(e) => handleChange("city_gun_plan", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              size="small"
              label="지구단위계획구역"
              value={body.district_unit_planning_area}
              onChange={(e) => handleChange("district_unit_planning_area", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="그 외의 이용제한"
            value={body.other_use_restrictions}
            onChange={(e) => handleChange("other_use_restrictions", e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <TextField
            type="number"
            size="small"
            label="건폐율 상한 (%)"
            value={body.building_ratio_limit}
            onChange={(e) =>
              handleChange("building_ratio_limit", e.target.value ? parseInt(e.target.value, 10) : void 0)
            }
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            type="number"
            size="small"
            label="용적율 상한 (%)"
            value={body.floor_area_ratio_limit}
            onChange={(e) =>
              handleChange("floor_area_ratio_limit", e.target.value ? parseInt(e.target.value, 10) : void 0)
            }
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_transaction_permit_zone}
                onChange={(e) => handleChange("is_transaction_permit_zone", e.target.checked)}
              />
            }
            label="토지거래허가구역 여부"
          />
        </Grid>
        <Grid item sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_land_speculation_area}
                onChange={(e) => handleChange("is_land_speculation_area", e.target.checked)}
              />
            }
            label="토지투기지역 여부"
          />
        </Grid>
        <Grid item sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_housing_speculation_area}
                onChange={(e) => handleChange("is_housing_speculation_area", e.target.checked)}
              />
            }
            label="주택투기지역 여부"
          />
        </Grid>
        <Grid item sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_speculation_overheated}
                onChange={(e) => handleChange("is_speculation_overheated", e.target.checked)}
              />
            }
            label="투기과열지구 여부"
          />
        </Grid>
      </Grid>
      <FormLabel section>입지조건 및 시설</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <TextField
            type="number"
            size="small"
            label="인접도로 너비 1 (m)"
            value={body.primary_road_width}
            onChange={(e) => handleChange("primary_road_width", e.target.value ? parseFloat(e.target.value) : void 0)}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            type="number"
            size="small"
            label="인접도로 너비 2 (m)"
            value={body.secondary_road_width}
            onChange={(e) => handleChange("secondary_road_width", e.target.value ? parseFloat(e.target.value) : void 0)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_road_paved}
                  onChange={(e) => handleChange("is_road_paved", e.target.checked)}
                />
              }
              label="포장도로 접함"
            />
          </Grid>
          <Grid item sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_accessible}
                  onChange={(e) => handleChange("is_accessible", e.target.checked)}
                />
              }
              label="접근성 용이함"
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="버스정류장"
            values={[body.bus_stop_name, body.bus_stop_time, body.is_bus_stop_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                bus_stop_name: n,
                bus_stop_time: t,
                is_bus_stop_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="지하철역"
            values={[body.subway_station_name, body.subway_station_time, body.is_subway_station_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                subway_station_name: n,
                subway_station_time: t,
                is_subway_station_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12}>
          <EnumField
            label="주차시설"
            options={[
              ["없음", ManagementTypeEnum.NotAvailable],
              ["전용시설", ManagementTypeEnum.Private],
              ["공동시설", ManagementTypeEnum.Public],
              ["그 외", ManagementTypeEnum.Others],
            ]}
            value={body.parking_type}
            detail={body.parking_type_detail}
            onChange={(v, d) => handleChange("parking_type", v, "parking_type_detail", d)}
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="초등학교"
            values={[body.elementary_school_name, body.elementary_school_time, body.is_elementary_school_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                elementary_school_name: n,
                elementary_school_time: t,
                is_elementary_school_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="중학교"
            values={[body.middle_school_name, body.middle_school_time, body.is_middle_school_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                middle_school_name: n,
                middle_school_time: t,
                is_middle_school_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="고등학교"
            values={[body.high_school_name, body.high_school_time, body.is_high_school_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                high_school_name: n,
                high_school_time: t,
                is_high_school_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="백화점"
            values={[body.store_name, body.store_time, body.is_store_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                store_name: n,
                store_time: t,
                is_store_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <InfraField
            label="병원"
            values={[body.hospital_name, body.hospital_time, body.is_hospital_time_by_car]}
            onChange={(n, t, i) =>
              onChange({
                ...body,
                hospital_name: n,
                hospital_time: t,
                is_hospital_time_by_car: i,
              })
            }
          />
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_undesirables}
                  onChange={(e) => handleChange("is_undesirables", e.target.checked)}
                />
              }
              label="비선호시설 있음"
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              size="small"
              label="종류 및 위치"
              value={body.undesirables_detail}
              onChange={(e) => handleChange("undesirables_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={8}>
          <EnumField
            label="관리주체"
            options={[
              ["자체", ManagementTypeEnum.Private],
              ["위탁", ManagementTypeEnum.Public],
              ["그 외", ManagementTypeEnum.Others],
            ]}
            value={body.management_type}
            onChange={(v) => handleChange("management_type", v)}
          />
        </Grid>
        <Grid item sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                sx={checkStyle}
                checked={body.is_management_office}
                onChange={(e) => handleChange("is_management_office", e.target.checked)}
              />
            }
            label="경비실 있음"
          />
        </Grid>
      </Grid>
      <FormLabel section>거래금액 및 세율</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <AmountField
              size="small"
              label="개별공시지가"
              value={body.declared_land_price}
              onChange={(e) => handleChange("declared_land_price", parseInt(e.target.value, 10))}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <AmountField
              size="small"
              label="건물(주택) 공시가격"
              value={body.declared_building_price}
              onChange={(e) => handleChange("declared_building_price", parseInt(e.target.value, 10))}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <TextField
              type="number"
              size="small"
              label="취득세율 (%)"
              value={body.acquisition_tax}
              onChange={(e) => handleChange("acquisition_tax", parseFloat(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="number"
              size="small"
              label="농어촌특별세율 (%)"
              value={body.rural_special_tax}
              onChange={(e) => handleChange("rural_special_tax", parseFloat(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="number"
              size="small"
              label="지방교육세율 (%)"
              value={body.local_education_tax}
              onChange={(e) => handleChange("local_education_tax", parseFloat(e.target.value))}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <FormLabel section>권리관계</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <TextField
            size="small"
            label="실제 권리관계 또는 공시되지 않은 물건의 권리 사항"
            value={body.undeclared_rights}
            onChange={(e) => handleChange("undeclared_rights", e.target.value)}
            multiline
            minRows={3}
            maxRows={15}
            fullWidth
          />
        </Grid>
      </Grid>
      <FormLabel section>시설물 상태</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_water_damage}
                  onChange={(e) => handleChange("is_water_damage", e.target.checked, "water_damage_detail", "")}
                />
              }
              label="수도 파손"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_water_shortage}
                  onChange={(e) => handleChange("is_water_shortage", e.target.checked, "water_shortage_detail", "")}
                />
              }
              label="용수량 부족"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_drainage_damage}
                  onChange={(e) => handleChange("is_drainage_damage", e.target.checked, "drainage_damage_detail", "")}
                />
              }
              label="배수 불량"
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_heating_damage}
                  onChange={(e) => handleChange("is_heating_damage", e.target.checked, "heating_damage_detail", "")}
                />
              }
              label="난방시설 고장"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_elevator_damage}
                  onChange={(e) => handleChange("is_elevator_damage", e.target.checked)}
                />
              }
              label="승강기 불량"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_electricity_damage}
                  onChange={(e) =>
                    handleChange("is_electricity_damage", e.target.checked, "electricity_damage_detail", "")
                  }
                />
              }
              label="전기 교체필요"
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_wall_crack}
                  onChange={(e) => handleChange("is_wall_crack", e.target.checked, "wall_crack_detail", "")}
                />
              }
              label="벽면 균열"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_wall_leakage}
                  onChange={(e) => handleChange("is_wall_leakage", e.target.checked, "wall_leakage_detail", "")}
                />
              }
              label="벽면 누수"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_lng_for_kitchen}
                  onChange={(e) => handleChange("is_lng_for_kitchen", e.target.checked)}
                />
              }
              label="취사용 도시가스"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_fire_detector}
                  onChange={(e) => handleChange("is_fire_detector", e.target.checked, "fire_detector_count", 0)}
                />
              }
              label="화재감지기"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_heating_individual}
                  onChange={(e) => handleChange("is_heating_individual", e.target.checked)}
                />
              }
              label="개별난방"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkStyle}
                  checked={body.is_elevator}
                  onChange={(e) => handleChange("is_elevator", e.target.checked)}
                />
              }
              label="승강기 있음"
            />
          </Grid>
          <Grid item sm={4}>
            <TernaryField
              value={body.wallpaper_condition}
              onChange={(v) => handleChange("wallpaper_condition", v)}
              label="도배 상태"
            />
          </Grid>
          <Grid item sm={4}>
            <TernaryField
              value={body.flooring_condition}
              onChange={(v) => handleChange("flooring_condition", v, "flooring_detail", "")}
              label="바닥면 상태"
            />
          </Grid>
          <Grid item sm={4}>
            <TernaryField
              value={body.sunlight}
              onChange={(v) => handleChange("sunlight", v, "sunlight_detail", "")}
              label="일조량"
            />
          </Grid>
          <Grid item sm={4}>
            <TernaryField value={body.noise} onChange={(v) => handleChange("noise", v)} label="소음" />
          </Grid>
          <Grid item sm={4}>
            <TernaryField value={body.vibration} onChange={(v) => handleChange("vibration", v)} label="진동" />
          </Grid>
          <Grid item sm={12}>
            <EnumField
              label="난방연료 유형"
              options={[
                ["도시가스", HeatingFuelTypeEnum.NaturalGas],
                ["기름", HeatingFuelTypeEnum.Kerosene],
                ["프로판가스", HeatingFuelTypeEnum.ProphaneGas],
                ["연탄", HeatingFuelTypeEnum.Brequet],
                ["그 외", HeatingFuelTypeEnum.Others],
              ]}
              value={body.heating_fuel_type}
              detail={body.heating_fuel_type_detail}
              onChange={(v, d) => handleChange("heating_fuel_type", v, "heating_fuel_type_detail", d)}
            />
          </Grid>
          {body.is_heating_individual ? (
            <Grid item sm={12}>
              <TextField
                type="number"
                size="small"
                label="난방 시설 사용 연한 (확인 불가시 0 기입)"
                value={body.heater_age}
                onChange={(e) => handleChange("heater_age", parseInt(e.target.value, 10))}
                fullWidth
                safeNumber
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {body.is_water_damage ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="수도 파손 위치"
              value={body.water_damage_detail}
              onChange={(e) => handleChange("water_damage_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_water_shortage ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="용수량 부족 위치"
              value={body.water_shortage_detail}
              onChange={(e) => handleChange("water_shortage_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_drainage_damage ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="배수 불량 세부내용"
              value={body.drainage_damage_detail}
              onChange={(e) => handleChange("drainage_damage_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_electricity_damage ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="전기 교체필요 위치"
              value={body.electricity_damage_detail}
              onChange={(e) => handleChange("electricity_damage_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_wall_crack ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="벽면 균열 위치"
              value={body.wall_crack_detail}
              onChange={(e) => handleChange("wall_crack_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_wall_leakage ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="벽면 누수 위치"
              value={body.wall_leakage_detail}
              onChange={(e) => handleChange("wall_leakage_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_heating_damage ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="난방시설 고장 세부내용"
              value={body.heating_damage_detail}
              onChange={(e) => handleChange("heating_damage_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.flooring_condition === TernaryConditionEnum.Bad ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="바닥면 수리 필요 위치"
              value={body.flooring_detail}
              onChange={(e) => handleChange("flooring_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.sunlight === TernaryConditionEnum.Bad ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="일조량 세부내용"
              value={body.sunlight_detail}
              onChange={(e) => handleChange("sunlight_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {!body.is_lng_for_kitchen ? (
          <Grid item sm={12}>
            <TextField
              size="small"
              label="취사용 가스 공급방식"
              value={body.kitchen_fuel_detail}
              onChange={(e) => handleChange("kitchen_fuel_detail", e.target.value)}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        {body.is_fire_detector ? (
          <Grid item sm={12}>
            <TextField
              type="number"
              size="small"
              label="화재감지기 수량"
              value={body.fire_detector_count}
              onChange={(e) => handleChange("fire_detector_count", parseInt(e.target.value, 10))}
              fullWidth
              safeNumber
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <FormLabel section>중개보수</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={6}>
            <AmountField
              size="small"
              label="중개보수"
              value={body.commission_amount}
              inputProps={{ step: 10000 }}
              onChange={(e) =>
                onChange({
                  ...body,
                  commission_amount: parseInt(e.target.value, 10),
                  total_amount: body.expense_amount + parseInt(e.target.value, 10),
                })
              }
              fullWidth
              safeNumber
            />
          </Grid>
          <Grid item sm={6}>
            <AmountField
              size="small"
              label="실비"
              value={body.expense_amount}
              inputProps={{ step: 10000 }}
              onChange={(e) =>
                onChange({
                  ...body,
                  expense_amount: parseInt(e.target.value, 10),
                  total_amount: body.commission_amount + parseInt(e.target.value, 10),
                  ...(parseInt(e.target.value, 10) <= 0
                    ? {
                        expense_detail: "",
                      }
                    : {}),
                })
              }
              fullWidth
              safeNumber
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <TextField
              size="small"
              label="지급시기"
              value={body.payment_due}
              onChange={(e) => handleChange("payment_due", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={12} container spacing={1}>
          <Grid item sm={12}>
            <TextField
              size="small"
              label="중개보수 산출내역"
              value={body.comission_detail}
              onChange={(e) => handleChange("comission_detail", e.target.value)}
              multiline
              minRows={3}
              maxRows={10}
              fullWidth
            />
          </Grid>
        </Grid>
        {body.expense_amount > 0 ? (
          <Grid item sm={12} container spacing={1}>
            <Grid item sm={12}>
              <TextField
                size="small"
                label="실비 산출내역"
                value={body.expense_detail}
                onChange={(e) => handleChange("expense_detail", e.target.value)}
                multiline
                minRows={3}
                maxRows={10}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <FormLabel section>교부일자</FormLabel>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <DateField label="교부일자" value={body.delivery_date} onChange={(v) => handleChange("delivery_date", v)} />
        </Grid>
      </Grid>
    </>
  );
};

export default ManualForm;
