import { PersonRegistrationTypeEnum } from "@doczip/api-client";

export function getDateStringFromRegistrationNumber(registrationNumber: string): string | null {
  const tokens = registrationNumber.split("-").map((token) => token.trim());
  if (tokens.length < 2) {
    return null;
  } else if (tokens[0].length !== 6) {
    return null;
  } else if (tokens[1].length < 1) {
    return null;
  } else if (!"12345678".includes(tokens[1][0])) {
    return null;
  }
  const year = `${"1256".includes(tokens[1][0]) ? "19" : "20"}${tokens[0].slice(0, 2)}`;
  const month = tokens[0].slice(2, 4);
  const day = tokens[0].slice(4);
  return `${year}-${month}-${day}`;
}

export function getRegistrationNumberLabel(registrationType?: PersonRegistrationTypeEnum): string {
  if (registrationType === PersonRegistrationTypeEnum.Resident) {
    return "주민등록번호";
  } else if (registrationType === PersonRegistrationTypeEnum.Corporation) {
    return "법인등록번호";
  } else {
    return "등록번호";
  }
}

export function getNameLabel(registrationType?: PersonRegistrationTypeEnum): string {
  if (registrationType === PersonRegistrationTypeEnum.Corporation) {
    return "법인명";
  } else {
    return "성명";
  }
}
