import React from "react";
import styled from "styled-components";
import StyledButton from "../StyledButton";
import { colors, depths, lengths } from "@/styles/values";
import { prompt } from "@/utils/dialog";

const DocumentHeaderBox = styled.div`
  position: fixed;
  top: ${lengths.headerHeight};
  left: 0;
  z-index: ${depths.header};

  width: 100vw;
  height: ${lengths.documentHeaderHeight};

  background-color: #f6f6f6;

  @media screen and (max-width: ${lengths.desktop}) {
    top: ${lengths.mobileHeaderHeight};
  }
`;

const DocumentHeaderPlaceholder = styled.div`
  width: 100%;
  height: ${lengths.documentHeaderHeight};
`;

const DocumentHeaderInnerBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 16px);
  max-width: ${lengths.containerWidth};
  height: ${lengths.documentHeaderHeight};
  margin: 0 auto;
`;

const CornerBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  align-items: center;

  @media screen and (max-width: ${lengths.tablet}) {
    &.desktop-only {
      display: none;
    }
  }
`;

const Title = styled.div`
  max-width: calc(100vw - 450px);
  margin-right: 24px;
  overflow: hidden;

  color: ${colors.dark};
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ChangeButton = styled.a`
  display: block;
  width: 64px;
  height: 30px;

  color: #797979;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  text-align: center;

  background-color: ${colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${colors.white}aa;
  }
`;

const ButtonBox = styled.div`
  width: 120px;
  margin-left: 20px;

  &.wide {
    width: 150px;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    width: 90px;

    &.wide {
      width: 90px;
    }
  }
`;

const DesktopText = styled.span`
  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

type Props = {
  title: string;
  manual?: boolean;
  saved?: boolean;
  onChangeTitle: (title: string) => void;
  onSave: () => void;
  onDownloadReport: () => void;
  onDownload: () => void;
  reportDisabled?: boolean;
};

const DocumentHeader: React.FC<Props> = (props) => {
  const handleChangeTitle = async () => {
    const title = await prompt("변경할 제목을 입력해주세요.", { title: "제목 변경", placeholder: props.title });
    if (title) {
      props.onChangeTitle(title);
    }
  };

  return (
    <>
      <DocumentHeaderPlaceholder />
      <DocumentHeaderBox>
        <DocumentHeaderInnerBox>
          <CornerBox className="desktop-only">
            <Title>{props.title}</Title>
            <ChangeButton onClick={handleChangeTitle}>제목 수정</ChangeButton>
          </CornerBox>
          <CornerBox>
            <ButtonBox>
              <StyledButton white onClick={() => props.onSave()} disabled={props.saved}>
                임시저장
              </StyledButton>
            </ButtonBox>
            <ButtonBox>
              <StyledButton white onClick={() => props.onDownloadReport()} disabled={props.reportDisabled}>
                공문서 열람
              </StyledButton>
            </ButtonBox>
            <ButtonBox className={props.manual ? "wide" : ""}>
              <StyledButton bold onClick={() => props.onDownload()}>
                <DesktopText>{props.manual ? "확인설명서" : "계약서"}</DesktopText>&nbsp;다운로드
              </StyledButton>
            </ButtonBox>
          </CornerBox>
        </DocumentHeaderInnerBox>
      </DocumentHeaderBox>
    </>
  );
};

export default DocumentHeader;
