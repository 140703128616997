import { useEffect } from "react";

type Props = {
  ref: any;
  onClick: () => void;
};

const useRefOutSideClick = ({ ref, onClick }: Props) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const target = event.target as HTMLElement;
      if (ref !== null && ref.current !== null && !ref.current.contains(target)) {
        onClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export default useRefOutSideClick;
