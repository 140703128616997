import { Report } from "@blitz-core/api-client";
import { RealEstateBrokerageResponse, ReportLogResponse, UserResponse } from "@doczip/api-client";
import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./types";
import { clearToken, removeAuthToken } from "@/client";
import { makeAsyncStateResetReducer, makeTypedAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState, getTypedAsyncInitialState, getTypedAsyncListInitialState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const makeAsyncReducer = makeTypedAsyncReducer<UserState>();
const getAsyncInitialState = getTypedAsyncInitialState<UserState>();
const getAsyncListInitialState = getTypedAsyncListInitialState<UserState>();

const initialState: UserState = {
  ...getAsyncInitialState("userMe"),
  ...getAsyncListInitialState("reportLogList"),
  ...getAsyncInitialState("reportLogDocument"),
  ...getAsyncInitialState("reportLogDelete"),
  ...getAsyncInitialState("realtorBrokerageList"),
  changePhoneNumberFetchState: "READY",
  changePasswordFetchState: "READY",
  editUserFetchState: "READY",
  verificationCodeFetchState: "READY",
  verifyRealtorFetchState: "READY",
  verifyIdentityFetchState: "READY",
};

export const USER_STATE_KEY_LIST = getKeyListFromState(initialState);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,

    resetUserMeState: makeAsyncStateResetReducer(initialState, "userMe"),
    resetReportLogList: makeAsyncStateResetReducer(initialState, "reportLogList", { isList: true }),
    resetReportLogDocuments: makeAsyncStateResetReducer(initialState, "reportLogDocument"),
    ...makeAsyncReducer<"userMe", void, UserResponse>("userMe", {
      failureReducer: (state) => {
        state.userMeFetchState = "FAILURE";
        state.userMe = null;
        clearToken();
        removeAuthToken();
      },
    }),
    ...makeAsyncReducer<"reportLogList", void, ListFetchSuccessPayload<ReportLogResponse>>("reportLogList", {
      useListSuccessReducer: true,
      successReducer: (_state, { payload: { list, nextPage, totalPage, totalCount } }) => {
        _state.reportLogList = [...(_state.reportLogList ?? []), ...list];
        _state.reportLogListFetchState = "SUCCESS";
        _state.reportLogListPage = nextPage;
        _state.reportLogListTotalPage = totalPage;
        _state.reportLogListTotalCount = totalCount;
      },
    }),
    ...makeAsyncReducer<"reportLogDocument", void, Report>("reportLogDocument"),
    ...makeAsyncReducer("reportLogDelete"),
    ...makeAsyncReducer("changePhoneNumber"),
    ...makeAsyncReducer("changePassword"),
    ...makeAsyncReducer("editUser"),
    ...makeAsyncReducer("verificationCode"),
    ...makeAsyncReducer<"realtorBrokerageList", void, RealEstateBrokerageResponse[]>("realtorBrokerageList"),
    ...makeAsyncReducer("verifyRealtor"),
    ...makeAsyncReducer("verifyIdentity"),
  },
});

export const userReducer = userSlice.reducer;
