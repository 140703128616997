import styled from "styled-components";

const UserTransactionListLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserTransactionListLayoutInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 640px;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default UserTransactionListLayout;
