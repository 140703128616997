import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingCircle from "@/components/LoadingCircle";
import Root from "@/components/Root";
import { getLoginFetchState } from "@/redux/auth/selectors";
import { getUserMeFetchState } from "@/redux/user/selectors";
import { fetchUserMeThunk } from "@/redux/user/thunk";
import { clear, init as initSetter } from "@/utils/setter";

export type Layout = {
  none?: boolean;
  columnless?: boolean;
  footerless?: boolean;
  headerContent?: React.ReactNode;
  isBorderLeftInFooter?: boolean;
  mobileTitle?: React.ReactNode;
  onMobileBackClick?: () => void;
};

type LayoutWithNone = Layout & {
  none?: boolean;
};

type Props = {};

const RootContainer: React.FC<Props> = ({ children }) => {
  // Set none as true when initialize.
  const [layout, setLayout] = useState<LayoutWithNone>({ none: true });
  const dispatch = useDispatch();
  const loginFetchState = useSelector(getLoginFetchState);
  const userMeFetchState = useSelector(getUserMeFetchState);

  useEffect(() => {
    // Subscribe to "root-layout" setter to listen layout changes.
    const stored = initSetter((layout: Layout) => setLayout(layout), "root-layout");
    if (stored) {
      setLayout(stored);
    }
    return () => {
      clear("root-layout");
    };
  }, []);

  useEffect(() => {
    if (loginFetchState !== "FETCHING" && userMeFetchState !== "FETCHING") {
      setTimeout(() => {
        setLayout((layout) => {
          // Remove none from the layout 100ms after log in status is fetched.
          // Assume that the main component is ready now.
          if (layout.none) {
            return {};
          } else {
            return layout;
          }
        });
      }, 100);
    }
  }, [loginFetchState, userMeFetchState]);

  useEffect(() => {
    // Fetch the session owner's User object when logged in.
    if (loginFetchState === "SUCCESS") {
      dispatch(fetchUserMeThunk({}));
    }
  }, [loginFetchState]);

  const loading = useMemo(() => userMeFetchState === "FETCHING", [userMeFetchState]);

  if (loading) {
    return <LoadingCircle position="absolute" />;
  }

  return (
    <Root
      footerless={layout.footerless}
      columnless={layout.columnless || layout.none}
      headerContent={layout.headerContent}
      isBorderLeftInFooter={layout.isBorderLeftInFooter}
      mobileTitle={layout.mobileTitle}
      onMobileBackClick={layout.onMobileBackClick}
    >
      {children}
    </Root>
  );
};

export default RootContainer;
