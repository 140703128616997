import { SubscriptionResponse, SubscriptionType, UserResponse, PaymentType } from "@doczip/api-client";
import { requestPayment } from "../transaction/function";
import { subscriptionList, cancelSubscription, refundSubscription, subscribe } from "./actions";
import { subscriptionApi } from "@/client";
import Configs from "@/configs";
import { AMOUNT_PER_PAGE } from "@/interfaces/pages";
import { PaymentMethod } from "@/interfaces/paymentMethod";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

export const subscriptionListFetchThunk = makeFetchMoreListThunkAction<SubscriptionResponse, void>(subscriptionList, {
  getResponseListFunction: async ({ page }) =>
    subscriptionApi.getSubscriptions(page * AMOUNT_PER_PAGE, AMOUNT_PER_PAGE, "me"),
  pageSelectFunction: ({ subscription: { subscriptionListPage, subscriptionListTotalPage } }) => ({
    page: subscriptionListPage,
    totalPage: subscriptionListTotalPage,
  }),
});

interface SubscribeThunkProps {
  subscriptionType: SubscriptionType;
  method: PaymentMethod;
  user: UserResponse;
  isUpgrade?: boolean;
}

export const subscribeThunk = makeFetchThunkAction<SubscriptionResponse, SubscribeThunkProps>(subscribe, {
  getResponseFunction: async (_state, { user, method, subscriptionType, isUpgrade }) => {
    const customerUid = `${user.id}-sub-${Date.now()}`;

    const subscribePrice = isUpgrade ? 0 : 3_900;
    const subscribePriceInDev = isUpgrade ? 0 : 300;

    const impUid = await requestPayment({
      user,
      method,
      price: Configs.DOCZIP_ENVIRONMENT === "production" ? subscribePrice : subscribePriceInDev,
      customerUid,
      paymentType: "SUBSCRIPTION",
      isUpgrade,
    });

    if (isUpgrade) {
      return await subscriptionApi.upgradeSubscription(
        subscriptionType,
        customerUid,
        method === "Card" ? PaymentType.Card : PaymentType.Phone,
      );
    } else {
      return await subscriptionApi.createSubscription(
        subscriptionType,
        impUid,
        customerUid,
        method === "Card" ? PaymentType.Card : PaymentType.Phone,
        "me",
      );
    }
  },
  useErrorSnackbar: true,
  successSnackbarMessage: "구독에 성공하였습니다.",
});

export const cancelSubscriptionThunk = makeFetchThunkAction<void, number>(cancelSubscription, {
  getResponseFunction: async (_state, subscriptionId) => subscriptionApi.cancelSubscription(subscriptionId),
  useErrorSnackbar: true,
  successSnackbarMessage: "구독이 취소되었습니다.",
});

export const refundSubscriptionThunk = makeFetchThunkAction<void, number>(refundSubscription, {
  getResponseFunction: async (_state, subscriptionId) => subscriptionApi.refundSubscription(subscriptionId),
  useErrorSnackbar: true,
  successSnackbarMessage: "환불 요청이 완료되었습니다.",
});
