import React from "react";
import { Link as ReactRouterDomLink } from "react-router-dom";
import styled from "styled-components";

interface LinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
  href: string;
  isOuterLink?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
}

export default function Link({ href, isOuterLink, children, target, ...props }: LinkProps) {
  if (isOuterLink) {
    return (
      <a href={href} target={target} {...props}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterDomLink to={href} {...props}>
      {children}
    </ReactRouterDomLink>
  );
}

export const UnderLinedLink = styled(Link)`
  font-weight: 700;
  text-decoration: underline;
`;

export const HoverUnderlineLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;
