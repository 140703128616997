import React from "react";
import { parse } from "query-string";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loadLoginState } from "@/redux/auth/functions";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";

type Props = {
  anonymousOnly?: boolean;
  as: React.ElementType;
};

const Protected = (props: Props) => {
  const isLoggedIn = loadLoginState() === "SUCCESS";
  const user = useSelector(getUserMe);
  const authenticated = Boolean(user);
  const routable = authenticated !== Boolean(props.anonymousOnly);
  const location = useLocation();
  const search = parse(location.search);

  const getFromParam = () => {
    if (typeof search.from === "string") {
      if (typeof search.with === "string") {
        return `${search.from}${search.with}`;
      } else {
        return search.from;
      }
    } else {
      return getUrl("dashboard");
    }
  };

  const Component = props.as;

  if (isLoggedIn) {
    if (props.anonymousOnly) {
      return <Navigate to={getFromParam()} />;
    }
    return <Component />;
  } else if (routable) {
    return <Component />;
  } else {
    return (
      <Navigate
        to={getUrl("login", {
          query: {
            from: location.pathname,
            with: location.search ? location.search : "",
          },
        })}
      />
    );
  }
};

export default Protected;
