import React from "react";
import { useSelector } from "react-redux";
import ReportList from "@/components/ReportList/ReportList";
import {
  getBuildingLedger,
  getBuildingLedgerFetchState,
  getLandUsingInfo,
  getLandUsingInfoFetchState,
  getRegisterLedger,
  getRegisterLedgerFetchState,
} from "@/redux/report/selectors";
import {
  getReportLogList,
  getReportLogListFetchState,
  getReportLogListPage,
  getReportLogListTotalPage,
} from "@/redux/user/selectors";

interface ReportListContainerProps {}

const ReportListContainer: React.FC<ReportListContainerProps> = () => {
  const reportLogList = useSelector(getReportLogList);
  const reportLogListFetchState = useSelector(getReportLogListFetchState);
  const reportLogListPage = useSelector(getReportLogListPage);
  const reportLogListTotalPage = useSelector(getReportLogListTotalPage);
  const registerLedger = useSelector(getRegisterLedger);
  const registerLedgerFetchState = useSelector(getRegisterLedgerFetchState);
  const buildingLedger = useSelector(getBuildingLedger);
  const buildingLedgerFetchState = useSelector(getBuildingLedgerFetchState);
  const landUsingInfo = useSelector(getLandUsingInfo);
  const landUsingInfoFetchState = useSelector(getLandUsingInfoFetchState);

  return (
    <ReportList
      reportLogList={reportLogList}
      reportLogListFetchState={reportLogListFetchState}
      reportLogListPage={reportLogListPage}
      reportLogListTotalPage={reportLogListTotalPage}
      registerLedger={registerLedger}
      registerLedgerFetchState={registerLedgerFetchState}
      buildingLedger={buildingLedger}
      buildingLedgerFetchState={buildingLedgerFetchState}
      landUsingInfo={landUsingInfo}
      landUsingInfoFetchState={landUsingInfoFetchState}
    />
  );
};

export default ReportListContainer;
