import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import CardButton from "@/components/buttons/CardButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CardButtonContainer from "@/components/CardButtonContainer";
import CenteredLayout from "@/components/CenteredLayout";
import Link from "@/components/Link";
import TitledContainer from "@/components/TitledContainer";
import ChangePassword from "@/components/users/userEdit/ChangePassword";
import ChangePhoneNumber from "@/components/users/userEdit/ChangePhoneNumber";
import UserDetailEdit from "@/components/users/userEdit/UserDetailEdit";
import UserEditLayout from "@/components/users/UserEditLayout";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";

interface UserEditContainerProps {}

export default function UserEditContainer(props: UserEditContainerProps) {
  const userMe = useSelector(getUserMe);

  const identityVerifyButton = useMemo(() => {
    if (userMe?.identity_verified) {
      return <CardButton disabled>실명인증(완료)</CardButton>;
    }

    return (
      <Link href={getUrl("userVerifyIdentity")}>
        <CardButton>실명인증</CardButton>
      </Link>
    );
  }, [userMe]);

  const realtorVerifyButton = useMemo(() => {
    if (userMe?.realtor_verified) {
      return <PrimaryButton disabled>공인중개사 인증(완료)</PrimaryButton>;
    }

    return (
      <Link href={getUrl("userVerifyRealtor")}>
        <PrimaryButton>공인중개사 인증</PrimaryButton>
      </Link>
    );
  }, [userMe]);

  return (
    <CenteredLayout title="개인정보 수정">
      <UserEditLayout>
        <TitledContainer title="추가인증">
          <CardButtonContainer>
            {identityVerifyButton}
            {realtorVerifyButton}
          </CardButtonContainer>
        </TitledContainer>
        <ChangePhoneNumber userMe={userMe} />
        <ChangePassword />
        <UserDetailEdit userMe={userMe} />
      </UserEditLayout>
    </CenteredLayout>
  );
}
