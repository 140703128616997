import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingCircle from "@/components/LoadingCircle";
import ReportDownloadDocument from "@/components/reports/ReportDownloadDocument";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportDownloadDocumentContainerProps {}

const ReportDownloadDocumentContainer: React.FC<ReportDownloadDocumentContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  const providerId = useMemo(() => {
    if (!report || !report?.provider_id) {
      return;
    }

    return report.provider_id;
  }, [report, reportFetchState]);

  const requestedResources = useMemo(() => {
    if (!report || !report?.requested_resources) {
      return;
    }

    return report.requested_resources;
  }, [report, reportFetchState]);

  const registeredType = useMemo(() => {
    if (!report || !report?.registered_type) {
      return;
    }

    return report.registered_type;
  }, [report, reportFetchState]);

  const registerLedger = useMemo(() => {
    if (!report || !report?.report || !report.report?.register_ledger) {
      return;
    }

    return report.report.register_ledger;
  }, [report, reportFetchState]);

  const buildingLedger = useMemo(() => {
    if (!report || !report?.report || !report.report?.building_ledger) {
      return;
    }

    return report.report.building_ledger;
  }, [report, reportFetchState]);

  const landLedger = useMemo(() => {
    if (!report || !report?.report || !report.report?.land_ledger) {
      return;
    }

    return report.report.land_ledger;
  }, [report, reportFetchState]);

  const landUsingInfo = useMemo(() => {
    if (!report || !report?.report || !report.report?.land_using_info) {
      return;
    }

    return report.report.land_using_info;
  }, [report, reportFetchState]);

  if (!providerId) {
    return <LoadingCircle />;
  }

  return (
    <ReportDownloadDocument
      providerId={providerId}
      requestedResources={requestedResources}
      registeredType={registeredType}
      registerLedger={registerLedger}
      buildingLedger={buildingLedger}
      landLedger={landLedger}
      landUsingInfo={landUsingInfo}
      reportFetchState={reportFetchState}
    />
  );
};

export default ReportDownloadDocumentContainer;
