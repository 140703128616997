import React from "react";
import { Grid } from "@mui/material";
import AmountField from "./AmountField";
import DateField from "./DateField";
import ListField from "./ListField";
import { toYmd } from "@/utils/datetime";

type Deposit = {
  amount: number;
  date: string;
};

type DepositFieldProps = {
  value: Deposit;
  onChange: (value: Deposit) => void;
};

const DepositField: React.FC<DepositFieldProps> = ({ value, onChange }) => {
  const handleChange = <KT extends keyof Deposit>(k: KT, v: Deposit[KT]) => {
    onChange({
      ...value,
      [k]: v,
    });
  };

  return (
    <Grid item sm={12} container spacing={1}>
      <Grid item sm={6}>
        <AmountField
          size="small"
          label="중도금"
          value={value.amount}
          onChange={(e) => handleChange("amount", parseInt(e.target.value, 10))}
          fullWidth
          safeNumber
        />
      </Grid>
      <Grid item sm={6}>
        <DateField label="지급일" value={value.date} onChange={(v) => handleChange("date", v)} />
      </Grid>
    </Grid>
  );
};

type Props = {
  amounts: number[];
  dates: string[];
  onChange: (amounts: number[], dates: string[]) => void;
};

const DepositsField: React.FC<Props> = ({ amounts, dates, onChange }) => {
  return (
    <ListField
      label="중도금"
      value={amounts.map((amount, i) => ({ amount, date: dates[i] }))}
      onChange={(ds) =>
        onChange(
          ds.map((d) => d.amount),
          ds.map((d) => d.date),
        )
      }
      input={DepositField}
      initFactory={() => {
        return {
          amount: 0,
          date: toYmd(new Date(), { useIsoFormat: true }),
        };
      }}
    />
  );
};

export default DepositsField;
