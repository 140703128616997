import styled from "styled-components";
import CardButton from "./CardButton";

const ConfirmButton = styled(CardButton)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 48px;

    font-weight: 700;
    font-size: 14px;
  }
`;

export default ConfirmButton;
