import React, { useEffect } from "react";
import { ReportBuildingLedger, ReportLandUsingInfo, ReportRegisterLedger } from "@blitz-core/api-client";
import { ButtonBase, Modal as MuiModal } from "@mui/material";
import styled from "styled-components";
import DocumentDownloadList from "@/components/DocumentDownloadList";
import { FetchState } from "@/interfaces/fetch";
import { makeUrlList } from "@/utils/list";

const StyledModal = styled(({ ...props }) => <MuiModal {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  min-width: 316px;
  padding: 24px;

  background-color: #fff;

  > div {
    width: 100%;
  }
`;

interface AlignProps {
  align?: string;
}

const TitleContainer = styled.div<AlignProps>`
  text-align: ${({ align }) => align};
`;

const Title = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 15px;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 22px;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const CloseButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    display: inline-block;
    width: 100%;
    padding: 10px 0;

    color: #333;
    font-weight: 700;
    font-size: 13px;
  }
`;

interface ReportDocumentDownloadPopupProps {
  reportId: number;
  open: boolean;
  handleClose: () => void;
  onMounted?: () => void;
  onUnMounted?: () => void;
  title?: string;
  titleAlign?: React.CSSProperties["textAlign"];
  registerLedger: ReportRegisterLedger | null;
  registerLedgerFetchState: FetchState;
  buildingLedger: ReportBuildingLedger | null;
  buildingLedgerFetchState: FetchState;
  landUsingInfo: ReportLandUsingInfo | null;
  landUsingInfoFetchState: FetchState;
}

const ReportDocumentDownloadPopup: React.FC<ReportDocumentDownloadPopupProps> = ({
  reportId,
  open,
  handleClose,
  onMounted,
  onUnMounted,
  title,
  titleAlign,
  registerLedger,
  registerLedgerFetchState,
  buildingLedger,
  buildingLedgerFetchState,
  landUsingInfo,
  landUsingInfoFetchState,
}) => {
  useEffect(() => {
    if (open && onMounted) {
      onMounted();
    }

    return () => {
      if (open && onUnMounted) {
        onUnMounted();
      }
    };
  }, [open]);

  return (
    <StyledModal open={open}>
      <ModalContainer>
        {title && (
          <TitleContainer align={titleAlign}>
            <Title>{title}</Title>
          </TitleContainer>
        )}
        <ButtonGroupContainer>
          <DocumentDownloadList
            title="등기부등본"
            urls={makeUrlList(registerLedger?.register_ledger_url)}
            fetchState={registerLedgerFetchState}
            reportId={reportId}
            resource="registerLedger"
          />
          {buildingLedger?.index_url && (
            <DocumentDownloadList
              title="건축물대장 총괄"
              urls={makeUrlList(buildingLedger?.index_url)}
              fetchState={buildingLedgerFetchState}
              reportId={reportId}
              resource="buildingLedger"
            />
          )}
          {buildingLedger?.complex_url && (
            <DocumentDownloadList
              title="건축물대장 표제부"
              urls={makeUrlList(buildingLedger?.complex_url)}
              fetchState={buildingLedgerFetchState}
              reportId={reportId}
              resource="buildingLedger"
            />
          )}
          <DocumentDownloadList
            title="건축물대장"
            fetchState={buildingLedger?.primary_urls || buildingLedger?.unit_url ? "SUCCESS" : "FAILURE"}
            urls={makeUrlList(buildingLedger?.primary_urls, buildingLedger?.unit_url)}
            reportId={reportId}
            resource="buildingLedger"
          />
          <DocumentDownloadList
            title="토지이용계획"
            urls={makeUrlList(landUsingInfo?.land_using_info_url)}
            fetchState={landUsingInfoFetchState}
            reportId={reportId}
            resource="landUsingInfo"
          />
        </ButtonGroupContainer>
        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>닫기</CloseButton>
        </CloseButtonContainer>
      </ModalContainer>
    </StyledModal>
  );
};

export default ReportDocumentDownloadPopup;
