type TempData = Record<number, Record<string, number>>;
type DataRow = Record<string, number>;

export const RegenerateChartDataByTimestamp = (data: any) => {
  const getKey = (o: DataRow) => o.timestamp;

  const result = Object.values(
    data.reduce((prev: TempData, current: DataRow) => {
      Object.assign((prev[getKey(current)] ??= {}), current);
      return prev;
    }, {}),
  );

  return result;
};
