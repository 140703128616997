import React, { useEffect } from "react";
import { UserResponse } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import LoadingCircle from "@/components/LoadingCircle";
import SaleCommentList from "@/components/sales/SaleCommentList";
import {
  BasicCommentProps,
  CommentPropsWithContent,
  SaveCommentProps,
  SaveCommentReactionProps,
} from "@/components/sales/type";
import { FetchState } from "@/interfaces/fetch";
import {
  getCreateSaleCommentFetchState,
  getCreateSaleCommentReactionFetchState,
  getCreateSaleCommentReplyFetchState,
  getCreateSaleCommentReplyReactionFetchState,
  getDeleteSaleCommentFetchState,
  getDeleteSaleCommentReactionFetchState,
  getDeleteSaleCommentReplyFetchState,
  getDeleteSaleCommentReplyReactionFetchState,
  getSaleComments,
  getSaleCommentsFetchState,
  getUpdateSaleCommentFetchState,
  getUpdateSaleCommentReplyFetchState,
} from "@/redux/sale-comment/selectors";
import {
  createSaleCommentReplyThunk,
  createSaleCommentThunk,
  deleteSaleCommentReactionThunk,
  deleteSaleCommentReplyReactionThunk,
  deleteSaleCommentReplyThunk,
  deleteSaleCommentThunk,
  fetchSaleCommentsThunk,
  createSaleCommentReactionThunk,
  createSaleCommentReplyReactionThunk,
  updateSaleCommentReplyThunk,
  updateSaleCommentThunk,
} from "@/redux/sale-comment/thunk";

type SaleCommentListContainerContainerProps = {
  saleId: number;
  user: UserResponse | null;
};

const SaleCommentListContainer = ({ saleId, user }: SaleCommentListContainerContainerProps) => {
  const saleComments = useSelector(getSaleComments);
  const saleCommentsFetchState = useSelector(getSaleCommentsFetchState);
  const dispatch = useDispatch();

  const ceateSaleCommentFetchState = useSelector(getCreateSaleCommentFetchState);
  const updateSaleCommentFetchState = useSelector(getUpdateSaleCommentFetchState);
  const deleteSaleCommentFetchState = useSelector(getDeleteSaleCommentFetchState);

  const createSaleCommentReplyFetchState = useSelector(getCreateSaleCommentReplyFetchState);
  const updateSaleCommentReplyFetchState = useSelector(getUpdateSaleCommentReplyFetchState);
  const deleteSaleCommentReplyFetchState = useSelector(getDeleteSaleCommentReplyFetchState);

  const createSaleCommentReactionFetchState = useSelector(getCreateSaleCommentReactionFetchState);
  const deleteSaleCommentReactionFetchState = useSelector(getDeleteSaleCommentReactionFetchState);
  const createSaleCommentReplyReactionFetchState = useSelector(getCreateSaleCommentReplyReactionFetchState);
  const deleteSaleCommentReplyReactionFetchState = useSelector(getDeleteSaleCommentReplyReactionFetchState);

  const fetchNewSaleComments = (fetchState: FetchState) => {
    switch (fetchState) {
      case "SUCCESS":
        dispatch(fetchSaleCommentsThunk(saleId));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchSaleCommentsThunk(saleId));
  }, []);
  useEffect(() => {
    fetchNewSaleComments(ceateSaleCommentFetchState);
  }, [ceateSaleCommentFetchState]);
  useEffect(() => {
    fetchNewSaleComments(updateSaleCommentFetchState);
  }, [updateSaleCommentFetchState]);
  useEffect(() => {
    fetchNewSaleComments(deleteSaleCommentFetchState);
  }, [deleteSaleCommentFetchState]);
  useEffect(() => {
    fetchNewSaleComments(createSaleCommentReplyFetchState);
  }, [createSaleCommentReplyFetchState]);
  useEffect(() => {
    fetchNewSaleComments(updateSaleCommentReplyFetchState);
  }, [updateSaleCommentReplyFetchState]);
  useEffect(() => {
    fetchNewSaleComments(deleteSaleCommentReplyFetchState);
  }, [deleteSaleCommentReplyFetchState]);

  useEffect(() => {
    fetchNewSaleComments(createSaleCommentReactionFetchState);
  }, [createSaleCommentReactionFetchState]);
  useEffect(() => {
    fetchNewSaleComments(deleteSaleCommentReactionFetchState);
  }, [deleteSaleCommentReactionFetchState]);
  useEffect(() => {
    fetchNewSaleComments(createSaleCommentReplyReactionFetchState);
  }, [createSaleCommentReplyReactionFetchState]);
  useEffect(() => {
    fetchNewSaleComments(deleteSaleCommentReplyReactionFetchState);
  }, [deleteSaleCommentReplyReactionFetchState]);

  const handleSaveComment = ({ actionType, commentProps }: SaveCommentProps) => {
    if (actionType === "CREATE") {
      handleCreateComment(commentProps);
    } else if (actionType === "UPDATE") {
      handleUpdateComment(commentProps);
    }
  };

  const handleCreateComment = ({ commentType, id, content }: CommentPropsWithContent) => {
    if (commentType === "COMMENT") {
      dispatch(createSaleCommentThunk({ saleId: id, content }));
    } else if (commentType === "REPLY") {
      dispatch(createSaleCommentReplyThunk({ commentId: id, content }));
    }
  };

  const handleUpdateComment = ({ commentType, id, content }: CommentPropsWithContent) => {
    if (commentType === "COMMENT") {
      dispatch(updateSaleCommentThunk({ commentId: id, content }));
    } else if (commentType === "REPLY") {
      dispatch(updateSaleCommentReplyThunk({ replytId: id, content }));
    }
  };

  const handleDeleteComment = ({ commentType, id }: BasicCommentProps) => {
    if (commentType === "COMMENT") {
      dispatch(deleteSaleCommentThunk({ commentId: id }));
    } else if (commentType === "REPLY") {
      dispatch(deleteSaleCommentReplyThunk({ replyId: id }));
    }
  };

  const handleCommentReaction = ({ commentType, id, reactionType, isDelete = false }: SaveCommentReactionProps) => {
    if (isDelete) {
      switch (commentType) {
        case "COMMENT":
          dispatch(
            deleteSaleCommentReactionThunk({
              commentId: id,
              reactionType,
            }),
          );
          break;
        case "REPLY":
          dispatch(
            deleteSaleCommentReplyReactionThunk({
              replyId: id,
              reactionType,
            }),
          );
          break;
      }
    } else {
      switch (commentType) {
        case "COMMENT":
          dispatch(
            createSaleCommentReactionThunk({
              commentId: id,
              reactionType,
            }),
          );
          break;
        case "REPLY":
          dispatch(
            createSaleCommentReplyReactionThunk({
              replyId: id,
              reactionType,
            }),
          );
          break;
      }
    }
  };

  if (saleCommentsFetchState === "READY" || saleCommentsFetchState === "FETCHING") {
    return <LoadingCircle position="absolute" />;
  }

  return (
    <>
      {saleId && saleComments && (
        <SaleCommentList
          saleId={saleId}
          user={user}
          comments={saleComments}
          onSaveComment={handleSaveComment}
          onDeleteComment={handleDeleteComment}
          onCommentReaction={handleCommentReaction}
        />
      )}
    </>
  );
};

export default SaleCommentListContainer;
