import React from "react";
import styled from "styled-components";
import { contentFullHeight, lengths } from "@/styles/values";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: #fafafa;

  ${contentFullHeight.desktop}

  @media screen and (max-width: ${lengths.desktop}) {
    ${contentFullHeight.mobile}

    background-color: #fff;
  }
`;

const InnerContainer = styled.div<{ innerContainerMaxWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${({ innerContainerMaxWidth }) => innerContainerMaxWidth ?? "640px"};
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 60px;
  padding-right: 60px;
  padding-bottom: 70px;
  padding-left: 60px;

  background-color: #fff;

  @media screen and (max-width: ${lengths.desktop}) {
    max-width: 400px;
    margin: 0;
    padding-right: 30px;
    padding-left: 30px;

    ${contentFullHeight.mobile}
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  color: #1e2432;
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const Description = styled.div`
  margin-bottom: 26px;

  color: #797979;
  font-weight: 400;
  font-size: 13px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    margin-bottom: 48px;
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
`;

const DescriptionRow = styled.div``;

interface CenteredLayoutProps {
  title: string;
  description?: string | string[] | React.ReactNode[];
  children?: React.ReactNode;
  className?: string;
  innerContainerMaxWidth?: string;
}

export default function CenteredLayout({
  title,
  description,
  children,
  className,
  innerContainerMaxWidth,
}: CenteredLayoutProps) {
  return (
    <Container>
      <InnerContainer innerContainerMaxWidth={innerContainerMaxWidth}>
        <Title>{title}</Title>
        {typeof description !== "undefined" && (
          <Description>
            {typeof description === "string"
              ? description
              : description.map((descriptionRow, index) => (
                  <DescriptionRow key={index}>{descriptionRow}</DescriptionRow>
                ))}
          </Description>
        )}
        <ChildrenContainer className={className}>{children}</ChildrenContainer>
      </InnerContainer>
    </Container>
  );
}
