import { ButtonBase } from "@mui/material";
import styled from "styled-components";
import { colors } from "@/styles/values";

const FlatButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    height: 36px;
    padding-right: 16px;
    padding-left: 16px;

    color: #797979;
    font-weight: 700;
    font-size: 12px;
    white-space: nowrap;

    background-color: #fff;

    &.Mui-disabled {
      color: #797979;

      background-color: #d6d6d6;
    }

    &.outlined {
      color: ${colors.main};

      background-color: ${colors.white};
      border: 1px solid ${colors.main};
    }
  }
`;

export default FlatButton;
