import { saleCommentSlice } from "@/redux/sale-comment/slice";
import { mergeAsyncAction } from "@/utils/redux/actions";

const actions = saleCommentSlice.actions;

export const { reset: resetSaleCommentsState } = actions;

export const saleComments = mergeAsyncAction(actions, "saleComments");

export const createSaleComment = mergeAsyncAction(actions, "createSaleComment");

export const updateSaleComment = mergeAsyncAction(actions, "updateSaleComment");

export const deleteSaleComment = mergeAsyncAction(actions, "deleteSaleComment");

export const createSaleCommentReply = mergeAsyncAction(actions, "createSaleCommentReply");

export const updateSaleCommentReply = mergeAsyncAction(actions, "updateSaleCommentReply");

export const deleteSaleCommentReply = mergeAsyncAction(actions, "deleteSaleCommentReply");

export const createSaleCommentReaction = mergeAsyncAction(actions, "createSaleCommentReaction");

export const deleteSaleCommentReaction = mergeAsyncAction(actions, "deleteSaleCommentReaction");

export const createSaleCommentReplyReaction = mergeAsyncAction(actions, "createSaleCommentReplyReaction");

export const deleteSaleCommentReplyReaction = mergeAsyncAction(actions, "deleteSaleCommentReplyReaction");
