import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionList } from "@/components/Subscription";
import SubscriptionInfoCard from "@/components/SubscriptionInfoCard";
import TitledContainer from "@/components/TitledContainer";
import UserSubscriptionListLayout, {
  UserSubscriptionListLayoutInnerContainer,
} from "@/components/users/subscription/UserSubscriptionListLayout";
import UserCommonLayout from "@/components/users/UserCommonLayout";
import { resetSubscriptionListState, resetSubscriptionRefundState } from "@/redux/subscription/actions";
import {
  getSubscriptionList,
  getSubscriptionListFetchState,
  getSubscriptionListPage,
  getSubscriptionListTotalPage,
  getRefundSubscriptionFetchState,
} from "@/redux/subscription/selectors";
import { subscriptionListFetchThunk } from "@/redux/subscription/thunk";
import { getUserMe } from "@/redux/user/selectors";

interface UserSubscriptionListContainerProps {}

export default function UserSubscriptionListContainer(props: UserSubscriptionListContainerProps) {
  const user = useSelector(getUserMe);
  const subscriptionList = useSelector(getSubscriptionList);
  const subscriptionListFetchState = useSelector(getSubscriptionListFetchState);
  const subscriptionListPage = useSelector(getSubscriptionListPage);
  const subscriptionListTotalPage = useSelector(getSubscriptionListTotalPage);
  const subscriptionRefundFetchState = useSelector(getRefundSubscriptionFetchState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSubscriptionListState());
  }, []);

  useEffect(() => {
    if (subscriptionRefundFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetSubscriptionRefundState());
    dispatch(resetSubscriptionListState());
    dispatch(subscriptionListFetchThunk());
  }, [subscriptionRefundFetchState]);

  const handleLoadMoreSubscriptionList = useCallback(() => {
    if (subscriptionListFetchState === "FETCHING") {
      return;
    }

    dispatch(subscriptionListFetchThunk());
  }, [subscriptionListFetchState]);

  return (
    <UserCommonLayout>
      <UserSubscriptionListLayout>
        <UserSubscriptionListLayoutInnerContainer>
          {user?.subscription && <SubscriptionInfoCard />}
          <TitledContainer title="정기결제 내역">
            <SubscriptionList
              list={subscriptionList}
              fetchState={subscriptionListFetchState}
              page={subscriptionListPage}
              totalPage={subscriptionListTotalPage}
              onLoadMore={handleLoadMoreSubscriptionList}
            />
          </TitledContainer>
        </UserSubscriptionListLayoutInnerContainer>
      </UserSubscriptionListLayout>
    </UserCommonLayout>
  );
}
