/* eslint-disable import/no-unresolved */
import { RequestPayResponse } from "iamport-typings";
import { loadToken, setAuthToken } from "@/client";
import Configs from "@/configs";

export async function requestSelfCertification(phoneNumber: string) {
  const { IMP } = window;

  if (!IMP) {
    throw new Error("본인인증모듈이 로드되지 않았습니다.");
  }

  IMP.init(Configs.DOCZIP_IAMPORT_UID);

  const merchantUid =
    typeof phoneNumber === "undefined" ? `cert-${Date.now()}-${Math.floor(Math.random() * 10000)}` : phoneNumber;

  let impResponse;

  try {
    impResponse = await new Promise<RequestPayResponse>((resolve, reject) => {
      (IMP as any).certification(
        {
          merchant_uid: merchantUid,
          popup: true,
        },
        (response: any) => {
          if (response.success) {
            resolve(response);
          } else {
            reject();
          }
        },
      );
    });
  } catch (e: unknown) {
    if (typeof e === "undefined") {
      throw new Error("인증이 취소되었습니다.");
    }

    throw new Error((e as RequestPayResponse)?.error_msg ?? "예상하지 못한 오류가 발생하였습니다.");
  }

  if (impResponse.imp_uid === null) {
    throw new Error("본인인증 정보를 받아오지 못하였습니다.");
  }

  return impResponse.imp_uid;
}

export function loadLoginState() {
  const token = loadToken();

  if (token === null) {
    return "READY";
  }

  setAuthToken(token);

  return "SUCCESS";
}
