import React from "react";
import { useSelector } from "react-redux";
import ReportMolitList from "@/components/reports/ReportMolitList";
import { getMolitApartmentKindListings, getReportFetchState } from "@/redux/report/selectors";

interface ReportMolitListContainerProps {}

const ReportMolitListContainer: React.FC<ReportMolitListContainerProps> = () => {
  const molitList = useSelector(getMolitApartmentKindListings);
  const reportFetchState = useSelector(getReportFetchState);

  return <ReportMolitList list={molitList} reportFetchState={reportFetchState} />;
};

export default ReportMolitListContainer;
