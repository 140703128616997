import React from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackbar } from "@/redux/snackbar/actions";
import { getSnackbarList } from "@/redux/snackbar/selectors";

let displayedKeyList: SnackbarKey[] = [];

export default function Snackbar() {
  const dispatch = useDispatch();
  const snackbarList = useSelector(getSnackbarList);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: SnackbarKey) => {
    displayedKeyList = [...displayedKeyList, key];
  };

  const removeDisplayed = (key: SnackbarKey) => {
    displayedKeyList = [...displayedKeyList.filter((snackbarKey) => snackbarKey !== key)];
  };

  React.useEffect(() => {
    snackbarList.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        return closeSnackbar(key);
      }

      if (displayedKeyList.includes(key)) {
        return;
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: () => {
          dispatch(removeSnackbar(key));
          removeDisplayed(key);
        },
      });

      storeDisplayed(key);
    });
  }, [snackbarList, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
