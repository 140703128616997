import Axios from "axios";
import Configs from "@/configs";

const tokenStorageKey = "DZ__TOKEN";

export const axios = Axios.create();

// @ts-ignore: Do not cache.
axios.defaults.headers.Pragma = "no-cache";

export function saveToken(token: string) {
  localStorage.setItem(tokenStorageKey, token);
}

export function clearToken() {
  localStorage.removeItem(tokenStorageKey);
}

export function loadToken(): string | null {
  return localStorage.getItem(tokenStorageKey);
}

export function setAuthToken(token: string) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    "X-API-KEY": Configs.BLITZ_CORE_API_KEY,
  };
}

export function removeAuthToken() {
  axios.defaults.headers.common = {};
}

export function setAccessCode(code: string) {
  axios.defaults.headers.common["X-ACCESS-CODE"] = code;
}

export function clearAccessCode() {
  if (axios.defaults.headers.common["X-ACCESS-CODE"]) {
    delete axios.defaults.headers.common["X-ACCESS-CODE"];
  }
}
